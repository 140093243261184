import {
    ERROR_EFE_LIST,
    RECEIVE_EFE_LIST,
    REQUEST_OLAS_LIST,
    RESTART_STATE,
    SET_MASK,
    SET_ORDER,
    SET_SELECTED_ROW,
    UPDATE_FIELD_FILTER
} from './types';

const initialState = {
    loadingStore: false,
    filters: {
         dVendedor: '',
         olaId: 0,
         distribuidorId: 0,
         dOla: '',
        dDistribuidor: '',
        desde: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        hasta: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
    },
    store: {
        data: [
           /* {marca:'AcDelco', nombre: 'AcDelco Select', linea: 'PVL',viscosidad: '10W30'},
        { marca:'AcDelco', nombre: 'AcDelco Select', linea: 'PVL',  viscosidad: '20W50' },
        {  marca:'AcDelco', nombre: 'AcDelco Syntetic Blend',linea: 'PVL', viscosidad: '5W30'    },
        {  marca:'AcDelco', nombre: 'AcDelco Select',linea: 'PVL', viscosidad: '20W50'    },
        {  marca:'AcDelco', nombre: 'AcDelco SupremE',linea: 'PVL', viscosidad: '10W30'    },
        {  marca:'Castrol', nombre: 'GTX',linea: 'PVL', viscosidad: '20W50'    },
        {  marca:'Castrol', nombre: 'Magnatec',linea: 'PVL', viscosidad: '10W40'    },
        {  marca:'Castrol', nombre: 'HD ',linea: 'PVL', viscosidad: '50/40'    }
        */
        ],
        selected: []
    },
    table: {
        order: 'asc',
        orderBy: '',
        columns: []
            /*
            { dataKey: 'marca', label: 'Marca', width: 275 },
            { dataKey: 'linea', label: 'Linea', width: 150 },
               { dataKey: 'nombre', label: 'Nombre', width: 275 },
            { dataKey: 'viscosidad', label: 'Viscosidad', width: 200 }
            //{ dataKey: 'tcategoria', label: 'Categoria', width: 105 },
            //{ type: 'linea', dataKey: 'linea', label: 'linea', width: 190 }
            */
        
    }
};

export const reportefectividadReducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case ERROR_EFE_LIST:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: false
                }
            };
        case RECEIVE_EFE_LIST:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: false,
                    data: action.payload,
                    selected: []
                },
                table: {
                    ...state.table,
                    columns: action.columns,
                }
            };
        case REQUEST_OLAS_LIST:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: true
                }
            };
        case RESTART_STATE:
            return {
                ...initialState
            };
        case SET_MASK:
            return {
                ...state,
                loading: action.loading
        };
        case SET_ORDER:
            return {
                ...state,
                table: {
                    ...state.table,
                    order: action.order,
                    orderBy: action.orderBy
                }
            };
        case SET_SELECTED_ROW:
            return {
                ...state,
                store: {
                    ...state.store,
                    selected: action.selected
                }
            };
        case UPDATE_FIELD_FILTER:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.key]: action.value
                }
            };
      
        default:
            return state;
    }
}