import React, { useLayoutEffect} from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import makeStyles from '@material-ui/core/styles/makeStyles';
import { topTitle , aligTitle , topSubTitle , aligSubTitle, sizeTitle, sizeSubTitle } from '../../../config';

am4core.useTheme(am4themes_animated);

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        padding: "2rem ",

    },
    wrap: {
        flex: '1 1 auto'
    }
}));

const ReporteParticipacionTotal = () => {
    const classes = useStyles();
    const data = useSelector(state => state.reporteparticipacion.data);
    const agente = useSelector(state => state.reporteparticipacion.filters.dDistribuidor);
    const ola = useSelector(state => state.reporteparticipacion.filters.dOla);
  
    data.filter(f => f.dSegmento === '' ? f.dSegmento = 'Sin Clasificación': f.dSegmento)
     
    useLayoutEffect(() => {
      
        var container = am4core.create("chartdiv", am4core.Container);
        
        container.width = am4core.percent(100);
        container.height = am4core.percent(100);
        container.layout = "vertical";

        if(data.length > 0) {
            container.exporting.menu = new am4core.ExportMenu();
            container.exporting.menu.align = "left";
            container.exporting.menu.verticalAlign = "top"
            container.exporting.menu.items = [
                {
                  "label": "...",
                  "menu": [
                    {
                      "label": "Imagen",
                      "menu": [
                        { "type": "png", "label": "PNG" },
                        { "type": "jpg", "label": "JPG" },
                        { "type": "svg", "label": "SVG" },
                        { "type": "pdf", "label": "PDF" }
                      ]
                    },  {
                      "label": "Imprimir", "type": "print"
                    }
                  ]
                }
              ]
        }

        createChart(container, 'MERCADO', getDataByLob('MERCADO'));
        return () => {
            container.dispose();
        }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const createChart = (container, titleText, data) => {
     
        am4core.useTheme(am4themes_animated);
        
        var chart = container.createChild(am4charts.PieChart);
        chart.innerRadius = am4core.percent(40);
        chart.startAngle = 5;
        chart.endAngle = 365;

        chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
        chart.data = data

        var title2 = chart.titles.create();
        title2.text =  "Total:  " + Intl.NumberFormat('es-MX').format( getSummary())+ " /Gls";
  
        title2.fontSize = sizeSubTitle;
        title2.align =  aligSubTitle;
        title2.marginTop = topSubTitle ;

        var title = chart.titles.create();
        if(agente.length >0)
        {
          title.text = "Participación Canal  /Agente: " + agente + ' - Ola: ' + ola
        }
        else 
        {
          title.text = "Participación Canal  /Agente: Todos - Ola: " + ola;
        }
        title.fontWeight = 600;
        title.fontSize = sizeTitle;
        title.align = aligTitle;
        title.marginTop = topTitle ;

        var series = chart.series.push(new am4charts.PieSeries());
        series.dataFields.value = "galones";
        series.dataFields.category = "marca";
        series.slices.template.stroke = am4core.color("#fff");
        series.slices.template.strokeWidth = 1;
        series.slices.template.strokeOpacity = 1;
        series.slices.template.states.getKey("hover").properties.shiftRadius = 0;


        
        series.labels.template.text = "{value.percent.formatNumber('#.0')}%";
        series.labels.template.radius = 10;
        series.labels.template.align= "center";
        series.labels.template.fill = am4core.color("black");

        series.legendSettings.itemValueText = "{valueY.percent}";//em
            
        chart.legend = new am4charts.Legend();
        chart.legend.position = "right";
        chart.legend.align = "right";
        chart.legend.contentAlign = "right";
        chart.legend.fontSize = 11;
        chart.legend.maxWidth = 200;
        chart.legend.scrollable = true;
  };

    const getDataByLob = () => {
        return _(data)
            .orderBy('orden')
            .groupBy('dSegmento')
            .map((item, key) => ({
                'marca': key,
                'colorHex': am4core.color(_.first(item).colorHex),
                'galones': _.sumBy(item, 'galones'),
                'galonesmercado': _.sumBy(item, 'galonesMercado'),
                'pdvmercado': _.sumBy(item, 'mercadoPDV')  , 
                'pdv': _.sumBy(item, 'cuentaPDV')
            }))
            .value()
    }

    const getSummary = () => {
      const summary = {
          total: _.sumBy(data, 'galones')
      }
      return summary.total
  }
  
    return (
      <>
         
        <div className={classes.root}>
          <div style={{ height: 450 }} id="chartdiv" />        
        </div>
      </>
    );
};

export default ReporteParticipacionTotal;