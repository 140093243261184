import React, { useEffect } from 'react';

import { Body, Footer, Page } from '@sigeco/tools';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';

const CargaMasiva = ({onClose, setTitle}) => {

    useEffect(() => {
        setTitle('Subir Archivos');
    // eslint-disable-next-line
    }, []);

    function onBtnClose() {
        onClose();
    }

    return (
        <Page>
            <Backdrop style={{ position: 'absolute', zIndex: 1200 }} open={false} transitionDuration={0}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <Body>
        </Body>
        <Footer>
            <Button 
            color="primary" 
            size="small" 
            variant="outlined" 
            onClick={() => onBtnClose()} 
            startIcon={<Icon className="fas fa-door-open"/>}
            >
            Salir
            </Button>
        </Footer>
        </Page>
    )
};

export default CargaMasiva;