import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    onSearchDistribuidorClick,
    onSearchListarPreciosClick,
    onSearchVendedorPorRolClick,
    onUpdateFieldFilter
} from "../../../store/fuerzaventas/gestionlistadeprecios/actions";


import { closeMessageBox, openMessageBox } from "../../../store/main/actions";

import { ActionField, FieldSet } from "@sigeco/tools";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FormControl from "@material-ui/core/FormControl";
import { Icon } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import {getDistribuidorDefault} from '../../../helpers/commons';


const useStyles = makeStyles(() => ({
    content: {
        padding: "0.5rem 0.75rem 0.75rem",
    },
    filterClear: {
        display: "flex",
    },
    actionField: {
        flexDirection: "row",
        position: "relative",
    },
    btnClear: {
        position: "absolute",
        bottom: "1.5",
        right: 20,
        maxWidth: "15px",
        maxHeight: "23px",
        minWidth: "15px",
        minHeight: "23px",
    },
    root: {
        flex: "0 0 auto",
        marginBottom: "0.75rem",
        "@media (min-width: 1401px)": {
            marginBottom: "1rem",
        },
    },
}));

const GestionListadePreciosFilter = () => {
    const classes = useStyles();
    const matches = useMediaQuery("(min-width:701px)");
    const filters = useSelector((state) => state.gestionlistadeprecios.filters);
    const data = useSelector((state) => state.gestionlistadeprecios.store.data )
    const dispatch = useDispatch();

    useEffect(() =>{

        getDistribuidorDefault(onUpdateFieldFilter,dispatch);
    
      },[])

    //Botones de limpiar filtros
    const [, setDistribuidor] = useState("");
    const [btn, setBtn] = useState(true);
    const [, setVendedor] = useState("");
    const [btn1, setBtn1] = useState(true);
    const [, setPrecios] = useState("");
    const [btn2, setBtn2] = useState(true);



    useEffect(() => {
        if (filters.dDistribuidor) {
            setDistribuidor(filters.dDistribuidor);
            setBtn(false);
        } else {
            setBtn(true);
        }

    }, [filters.dDistribuidor]);

    useEffect(() => {
        if (filters.dLista) {
            setPrecios(filters.dLista);
            setBtn2(false);
        } else {
            setBtn2(true);
        }

    }, [filters.dLista]);

    useEffect(() => {
        if (filters.dVendedor) {
          setVendedor(filters.dVendedor);
          setBtn1(false);
        } else {
          setBtn1(true);
        }

      }, [filters.dVendedor]);

    const handleDist = () => {
        setDistribuidor("");
        setVendedor("");
        setPrecios("");
        filters.distribuidorId = 0;
        filters.dDistribuidor = "";
        filters.listaPrecioId = 0;
        filters.dLista = "";
        filters.vendedorId = 0
        filters.dVendedor = "";
        setBtn(true);
        setBtn1(true);
        setBtn2(true);
    };

    const handlePrecio = () => {
        setPrecios("");
        filters.listaPrecioId = 0;
        filters.dLista = "";
        setBtn2(true);
    };

    const handleVendedor = () => {
        setVendedor("");
        setPrecios("");
         filters.vendedorId = 0;
         filters.dVendedor = "";
         filters.codigoLista = 0;
         filters.dLista = "";
         setBtn1(true);
         setBtn2(true);
      };


    return (
        <div className={classes.root}>
            <FieldSet iconCls="fas fa-filter" title="Filtros" defaultExpanded>
                <Grid className={classes.content} container spacing={2}>
                    <Grid item xs={12} container>
                        <Grid item xs={matches ? 4 : 6}>
                            <FormControl className={classes.filterClear}>
                                <ActionField
                                    className={classes.actionField}
                                    label="Agente"
                                    placeholder="Enter para buscar Agente"
                                    value={filters.dDistribuidor}
                                    variant={matches ? "outlined" : "standard"}
                                    onHandleAction={() =>
                                        dispatch(onSearchDistribuidorClick())
                                    }
                                />
                                <Button
                                    className={classes.btnClear}
                                    size="small"
                                    startIcon={
                                        <Icon className="fa fa-window-close" />
                                    }
                                    disabled={btn}
                                    onClick={handleDist}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12}  >
          
                    <Grid item  xs={matches ? 4 : 12}>
                        <FormControl className={classes.filterClear}>
                            <ActionField
                            label="Vendedor"
                            placeholder="Enter para buscar Vendedor"
                            value={filters.dVendedor}
                            variant={matches ? "outlined" : "standard"}
                            onHandleAction={() => dispatch(onSearchVendedorPorRolClick())}
                            />
                            <Button
                            className={classes.btnClear}
                            size="small"
                            startIcon={<Icon className="fa fa-window-close" />}
                            disabled={btn1}
                            onClick={handleVendedor}
                            />
                        </FormControl>
                    </Grid>
                    </Grid>
                    {/* <Grid item xs={12} container>
                        <Grid item xs={matches ? 4 : 6}>
                            <FormControl className={classes.filterClear}>
                                <ActionField
                                    className={classes.actionField}
                                    label="Lista de Precios"
                                    placeholder="Enter para buscar la lista de Precios"
                                    value={filters.dLista}
                                    variant={matches ? "outlined" : "standard"}
                                    onHandleAction={() =>
                                        dispatch(onSearchListarPreciosClick())
                                    }
                                />
                                <Button
                                    className={classes.btnClear}
                                    size="small"
                                    startIcon={
                                        <Icon className="fa fa-window-close" />
                                    }
                                    disabled={btn2}
                                    onClick={handlePrecio}
                                />
                            </FormControl>
                        </Grid>
                    </Grid> */}
                </Grid>
            </FieldSet>
        </div>
    );
};

export default GestionListadePreciosFilter;
