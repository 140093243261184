export const CLEAN_FORM = 'precios/productos/clean-form';
export const ERROR_OLAS_LIST = 'precios/productos/error-olas-list';
export const RECEIVE_OLAS_LIST = 'precios/productos/receive-reportepedidos-list';
export const REQUEST_OLAS_LIST = 'precios/productos/request-reportepedidos-list';
export const SET_STATE_FORM = 'precios/productos/set-state-form';
export const RESTART_STATE = 'precios/productos/restart_state';
export const SET_ENTITY = 'precios/productos/set-entity';
export const SET_ERROR = 'precios/productos/set-error';
export const SET_MASK = 'precios/productos/set-mask';
export const SET_ORDER = 'precios/productos/set-order';
export const SET_SELECTED_ROW = 'precios/productos/set-selected-row';
export const UPDATE_FIELD_FILTER = 'precios/productos/update-field-filter';
export const UPDATE_TABLE_COLUMN = 'precios/productos/set-selected-row';
export const UPDATE_FIELD_ENTITY = 'precios/productos/update-field-entity';