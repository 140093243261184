import {
    ERROR_AVANCE_LIST,
    RECEIVE_AVANCE_LIST,
    REQUEST_AVANCE_LIST,
    RESTART_STATE,
    SET_MASK,
    SET_ORDER,
    SET_SELECTED_ROW,
    UPDATE_FIELD_FILTER,
    UPDATE_TABLE_COLUMN
} from './types';

const initialState = {
    loading: false,
    filters: {
        distribuidorId: 0,
        supervisorId: '',
        ubigeoId: 0,
        vendedorId: '',
        desde: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        hasta: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        dDistribuidor: '',
        dSupervisor: '',
        dVendedor: '',
        dUbigeo: '',
        nombre: '',
        track: 'U',
        sw: false,
        meses: [
            "Ene","Feb","Mar","Abr","May","Jun","Jul","Ago","Sep","Oct","Nov","Dic"
           ]
    },
    store: {
        loading: false,
        data: [],
        selected: []
    },
    table: {
        order: 'asc',
        orderBy: '',
        columns: [
             { dataKey: 'Nombre', label: 'Nombre', width: 300 },
             { dataKey: 'DocumentoIdentidad', label: 'Documento Identidad', width: 90 },
             { dataKey: 'Marca', label: 'Marca', width: 80 },
             { dataKey: 'Meta', label: 'Meta', width: 80 ,type: 'number',  backcolor: 'black' },
             { dataKey: 'Total', label: 'Total', width: 80 ,type: 'number',  backcolor: 'black' },
             { dataKey: 'Avance', label: 'Avance', width: 80 ,  backcolor: 'black' },
             { dataKey: 'Desde', label: 'Desde', width: 80 ,type: 'date',  backcolor: 'red' },
             { dataKey: 'Hasta', label: 'Hasta', width: 80 ,type: 'date',  backcolor: 'black' },
             
        ]
    }
};

export const avanceventagalonesReducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case ERROR_AVANCE_LIST:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: false
                }
            };
        case RECEIVE_AVANCE_LIST:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: false,
                    data: action.payload,
                    selected: []
                },
                /*
                table: {
                    ...state.table,
                    columns: action.columns,
                }*/
            };
        case REQUEST_AVANCE_LIST:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: true
                }
            };
        case RESTART_STATE:
            return {
                ...initialState
            };
        case SET_MASK:
            return {
                ...state,
                loading: action.loading
        };
        case SET_ORDER:
            return {
                ...state,
                table: {
                    ...state.table,
                    order: action.order,
                    orderBy: action.orderBy
                }
            };
        case SET_SELECTED_ROW:
            return {
                ...state,
                store: {
                    ...state.store,
                    selected: action.selected
                }
            };
        case UPDATE_FIELD_FILTER:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.key]: action.value
                }
            };
        case UPDATE_TABLE_COLUMN:
            return {
                ...state,
                table: {
                    ...state.table,
                    columns: action.columns
                }
            };
        default:
            return state;
    }
}