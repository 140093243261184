import axios from 'axios';
import { IdentityUrl } from '../../../config';
import { push } from 'connected-react-router';
import format from "date-fns/format";
import { closeAdvancedSearch, closeMessageBox, openAdvancedSearch, openMessageBox } from '../../main/actions';
import {
    CLEAN_FORM,
    ERROR_DESACTIVACIONES_LIST,
    RECEIVE_DESACTIVACIONES_LIST,
    REQUEST_DESACTIVACIONES_LIST,
    RESTART_STATE,
    SET_ENTITY,
    SET_MASK,
    SET_ORDER,
    SET_PAGE,
    SET_PAGE_SIZE,
    SET_SELECTED_ROW,
    SET_STATE_FORM,
    UPDATE_FIELD_FILTER,
    UPDATE_FIELD_ENTITY
} from './types';

import { exportToSpreadsheet } from '../../../helpers/commons'

export const btnApprove = () => (dispatch) => {
    dispatch(openMessageBox({
        button: 'yesno',
        icon: 'question',
        message: '¿Esta seguro de aprobar el registro?',
        callback: btn => dispatch(onApprove(btn))
    }));
};

export const btnExport = () => async (dispatch, getState) => {
   
        if(getState().desactivacion.store.data.length === 0) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'warning', 
                message: 'No hay información para exportar.', 
                callback: () => dispatch(closeMessageBox())
            }));
            return;
        }
    try {
        dispatch({ type: SET_MASK, loading: true });

        let head = getState().desactivacion.table.columns;
        var aux= [];
        
        for(var c in head) {

            aux[c] =   Object.values(head[0])[1];//nit
            aux[1] =   Object.values(head[1])[1];//cliente
            aux[2] =   Object.values(head[2])[1];//codigo sucursal
            aux[3] =   Object.values(head[3])[1];//sucursal
            aux[4] =   Object.values(head[8])[1];//agente
            aux[5] =   Object.values(head[4])[1];//motivo
            aux[6] =   Object.values(head[5])[1];//fecha
            aux[7] =   Object.values(head[6])[1];//estado
            aux[8] =   Object.values(head[7])[1];//usuario
            
        }

        var carArray = [];
        carArray.push(aux);
    
        let json = getState().desactivacion.store.data;

        for(var i in json) {
            let x =   Object.values(json[i]);
            x.splice(0,1);
        
            carArray.push(x);
        }

        exportToSpreadsheet(carArray,  'Desactivaciones') ;

    } catch (error) {
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'error', 
            message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
            callback: () => dispatch(closeMessageBox())
        }));
    } finally {
        dispatch({ type: SET_MASK, loading: false });
    }
};

export const btnReject = () => (dispatch, getState) => {
    if(!getState().desactivacion.entity.motivoRechazo || getState().desactivacion.entity.motivoRechazo === '') {
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'error', 
            message: 'Ingrese un motivo de rechazo', 
            callback: () => dispatch(closeMessageBox())
        }));

        return;
    }

    dispatch(openMessageBox({
        button: 'yesno',
        icon: 'question',
        message: '¿Esta seguro de rechazar el registro?',
        callback: btn => dispatch(onReject(btn))
    }));
};

export const btnSearch = () => (dispatch) => {
    dispatch(setPage(0));
    dispatch(onDesactivacionList());
};

export const cleanForm = () => ({ type: CLEAN_FORM });

export const getDesactivacion = (desactivacionId) => async (dispatch, getState) => {
    try {
        dispatch({ type: SET_MASK, loading: true });

        let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/clientes/desactivaciones/${desactivacionId}`;
        let response = await axios.get(encodeURI(url));
        let payload = {
            ...response.data
        };
        
        dispatch({ type: SET_ENTITY, payload });
    } catch (error) {
        if(error.response.status === 404) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: 'No se encontro el registro solicitado.', 
                callback: () => { 
                    dispatch(closeMessageBox()); 
                    dispatch(push({
                        pathname: `/channelmap/desactivaciones`,
                        search: getState().router.location.search,
                        hash: getState().router.location.hash
                    }));
                }
            }));
        }
        else {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        }
    } finally {
        dispatch({ type: SET_MASK, loading: false });
    }
};

export const onDesactivacionList = () => async (dispatch, getState) => {

    
  if (getState().desactivacion.filters.distribuidorId  === 0) {
    dispatch(openMessageBox({
        button: 'ok',
        icon: 'warning',
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox())
    }));
return;
}
    if(getState().desactivacion.store.source) {
        getState().desactivacion.store.source.cancel();
    }

    let source = axios.CancelToken.source();
    let order = JSON.stringify([{
        property: getState().desactivacion.table.orderBy,
        direction: getState().desactivacion.table.order
    }]);
    dispatch({ type: REQUEST_DESACTIVACIONES_LIST, source });

    try {
        let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/clientes/desactivaciones?`
            + `dCliente=${getState().desactivacion.filters.dCliente}`
            + `&distribuidorId=${getState().desactivacion.filters.distribuidorId}`
            + `&estado=${getState().desactivacion.filters.estado}`
            + `&desde=${format(getState().desactivacion.filters.desde, 'yyyy-MM-dd')}`
            + `&hasta=${format(getState().desactivacion.filters.hasta, 'yyyy-MM-dd')}`
            + `&sort=${order}&pageSize=${getState().desactivacion.store.pageSize}&start=${getState().desactivacion.store.start}`;
        let response = await axios.get(encodeURI(url), { cancelToken: source.token });
        let data = response.data;
        console.log(data)

        dispatch({ type: RECEIVE_DESACTIVACIONES_LIST, total: data.total, payload: data.data });
    } catch (error) {
        dispatch({ type: ERROR_DESACTIVACIONES_LIST });
        if (!axios.isCancel(error)) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        }
    }
};

export const onApprove = (btn) => async (dispatch, getState) => {
    dispatch(closeMessageBox());

    if(btn === 'yes') {
        try {
            dispatch({ type: SET_MASK, loading: true });

            let desactivacion = {
                desactivacionId: getState().desactivacion.entity.desactivacionId
            };

            let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/clientes/desactivaciones/aprobar`;
            let response = await axios.put(url, desactivacion, {headers: {'Content-Type': 'application/json'}});
            
            if(response.data) {
                dispatch(openMessageBox({ 
                    button: 'ok', 
                    icon: 'info', 
                    message: 'Registro aprobado con exito.', 
                    callback: () => { 
                        dispatch(closeMessageBox());
                        dispatch(getDesactivacion(desactivacion.desactivacionId));
                    }
                }));
            }
        } catch (error) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        } finally {
            dispatch({ type: SET_MASK, loading: false });
        }
    }
};

export const onReject = (btn) => async (dispatch, getState) => {
    dispatch(closeMessageBox());

    if(btn === 'yes') {
        try {
            dispatch({ type: SET_MASK, loading: true });

            let desactivacion = {
                desactivacionId: getState().desactivacion.entity.desactivacionId,
                motivoRechazo: getState().desactivacion.entity.motivoRechazo
            };

            let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/clientes/desactivaciones/rechazar`;
            let response = await axios.put(url, desactivacion, {headers: {'Content-Type': 'application/json'}});
            
            if(response.data) {
                dispatch(openMessageBox({ 
                    button: 'ok', 
                    icon: 'info', 
                    message: 'Registro rechazado con exito.', 
                    callback: () => { 
                        dispatch(closeMessageBox());
                        dispatch(getDesactivacion(desactivacion.desactivacionId));
                    }
                }));
            }
        } catch (error) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        } finally {
            dispatch({ type: SET_MASK, loading: false });
        }
    }
};

export const onSearchDistribuidorClick = () => async (dispatch) => {
    let fields = [
        { 'dataKey': 'razonSocial', 'label': 'Agente', 'type': 'string', 'width': 250 }
    ];

    try {
        dispatch(openAdvancedSearch({
            autoLoad: true,
            data: [],
            fields: fields,
            paginated: true,
            remote: true,
            title: 'Selección de agente',
            getData: async (params) => {
                let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`
                let response = await axios.get(encodeURI(url), { params: params });
    
                return response.data;
            },
            callback: (btn, result) => {
                dispatch(closeAdvancedSearch());
                if(btn === 'yes') {
                    dispatch(onUpdateFieldFilter('distribuidorId', result[0].distribuidorId));
                    dispatch(onUpdateFieldFilter('dDistribuidor', `${result[0].razonSocial}`));
                    dispatch(onUpdateFieldFilter('nombre', ''));
                }
            }
        }));
    } catch (error) {
        if(error.response) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        }
    }
};

export const onUpdateColumn = (column) => (dispatch, getState) => {
    getState().desactivacion.table.columns.map(item => {
       if(item.dataKey !== column.dataKey) {
           return item;
       }else{
           return item.width =  column.width;
       }
       /*
       return {
           ...item,
           width: column.width
       }*/
   });

   //dispatch({ type: UPDATE_TABLE_COLUMN, columns });
};

//export const onUpdateFieldFilter = (key, value) => ({ type: UPDATE_FIELD_FILTER, key, value });

export const onUpdateFieldEntity = (key, value) => ({ type: UPDATE_FIELD_ENTITY, key, value });

export const restartState = () => ({ type: RESTART_STATE });

export const setFormState = (formState, id) => (dispatch, getState) => {
    switch (formState) {
        case 'search':
            dispatch(cleanForm());
            break;
        case 'view':
            if(id && id !== getState().desactivacion.entity.desactivacionId) {
                dispatch(getDesactivacion(id));
            }
            break;
        default:
            break;
    }
    dispatch({ type: SET_STATE_FORM, formState });
};

export const onUpdateFieldFilter = (key, value) => (dispatch, getState) =>{

    dispatch({ type: UPDATE_FIELD_FILTER, key, value });


    dispatch({ type: RECEIVE_DESACTIVACIONES_LIST, total: [], payload: []});

} 




export const setOrder = (order, orderBy) => ({ type: SET_ORDER, order, orderBy });

export const setPage = (page) => ({ type: SET_PAGE, page });

export const setPageSize = (pageSize) => ({ type: SET_PAGE_SIZE, pageSize });

export const setSelectedRow = (selected) => ({ type: SET_SELECTED_ROW, selected});