import axios from 'axios';
import format from "date-fns/format";
import { IdentityUrl } from '../../../config';
import { closeAdvancedSearch, closeMessageBox, openAdvancedSearch, openMessageBox } from '../../main/actions';
import {

    RECEIVE_DATA,
    RECEIVE_V_LIST, 
    SET_MASK,
    SET_ORDER,
    SET_SELECTED_ROW,
    UPDATE_FIELD_FILTER,
    HIDE_POPUP_CLIENT,
    SHOW_POPUP_CLIENT
} from './types';

import { exportToSpreadsheet,cortarArreglo } from '../../../helpers/commons'

export const btnExport = () => async (dispatch, getState) => {
    
    if (getState().reportedeincidencias.filters.distribuidorId   === 0) {
        dispatch(openMessageBox({
            button: 'ok',
            icon: 'warning',
            message: "Debe seleccionar un Agente..",
            callback: () => dispatch(closeMessageBox())
        }));
    return;
    }
    if(getState().reportedeincidencias.store.data.length === 0) {
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'warning', 
            message: 'No hay información para exportar.', 
            callback: () => dispatch(closeMessageBox())
        }));
        return;
    }
    
    try {
        dispatch({ type: SET_MASK, loading: true });

        let head = getState().reportedeincidencias.tableExport.columns;
        
        let json = getState().reportedeincidencias.store.data;
        
 
        exportToSpreadsheet(cortarArreglo(head,json),  'ReporteDeIncidencias') ;

    } catch (error) {
        console.log(error)
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'error', 
            message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
            callback: () => dispatch(closeMessageBox())
        }));
    } finally {
        setTimeout(() => {dispatch({ type: SET_MASK, loading: false })}, 1000);
    }
};

export const btnSearch = () => async (dispatch, getState) => {
    try {
        dispatch({ type: SET_MASK, loading: true });

        if (getState().reportedeincidencias.store.loading) {
            return;
        }
 
        let url = `${getState().main.resources.uriSvcSalesMobilityAPI}/api/v1/Reportes/movilidad/reportedeincidencias?`
            + `distribuidorId=${getState().reportedeincidencias.filters.distribuidorId}`
            + `&desde=${format(getState().reportedeincidencias.filters.desde, 'yyyy-MM-dd')}`
            + `&hasta=${format(getState().reportedeincidencias.filters.hasta, 'yyyy-MM-dd')}`;
        let response = await axios.get(encodeURI(url));
        let data = response.data;
        //console.log(data)

        if (data.length === 0) {
            dispatch(openMessageBox({
                button: 'ok',
                icon: 'warning',
                message: "No existe Registro  para esta consulta",
                callback: () => dispatch(closeMessageBox())
            }));
        return;
    }
      

        let columnas = getState().reportedeincidencias.table.columns;
       
        dispatch({ type: RECEIVE_V_LIST, columns: columnas, payload: data});
        
    } catch (error) {
       
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'error', 
            message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
            callback: () => dispatch(closeMessageBox())
        }));
    } finally {
        dispatch({ type: SET_MASK, loading: false });
    }

    
};

export const openDialogClient = (data) => (dispatch, getState) => {
    try {
  
    var aux = getState().reportedeincidencias.store.data
    var storeData = []    
    for(var i in aux)
    {
        if(aux[i].IncidenteId === data.IncidenteId )
        {
            storeData.push(aux[i])
            break;
        }
    }
      dispatch({ type: SHOW_POPUP_CLIENT, storeData });
    } catch (error) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "error",
          message: error,
          callback: () => dispatch(closeMessageBox()),
        })
      );
    }
};

export const Loadding = (key) => (dispatch, getState) => {
    dispatch({ type: SET_MASK, loading: key });
};

export const closeDialogClient = () => ({ type: HIDE_POPUP_CLIENT });







export const onSearchDistribuidorClick = () => async (dispatch) => {
    let fields = [
        { 'dataKey': 'razonSocial', 'label': 'Agente', 'type': 'string', 'width': 225 }
    ];

    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de agente',
        getData: async (params) => {
            let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`
            let response = await axios.get(encodeURI(url), { params: params });
            
            return response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldFilter('distribuidorId', result[0].distribuidorId));
                dispatch(onUpdateFieldFilter('dDistribuidor', `${result[0].razonSocial}`));
                
            }
        }
    }));
};





export const onUpdateColumn = (column) => (dispatch, getState) => {
    getState().reportedeincidencias.table.columns.map((item , i)=> {     
       if(item.dataKey !== column.dataKey) {
           return item;
       }else{
               item.width =  column.width;
       }
       return {
          // ...item,
         //  width: column.width
       };
      
   });
};

export const onUpdateFieldFilter = (key, value) => (dispatch, getState) => {
    dispatch({ type: UPDATE_FIELD_FILTER, key, value })
    //const  da = []

    dispatch( onUpdateFielddata());
   // dispatch({ type: RECEIVE_DATA, payload:[],  store:[] })
    dispatch({ type: RECEIVE_DATA, payload: [],storeData:[]});
    
}

export const onUpdateFielddata = (key, value) => (dispatch, getState) => {  

   // dispatch({ type: UPDATE_FIELD_DATA, key, value })

     //dispatch({ type: RECEIVE_DATA, columns: [], data: [] })
  //  dispatch({ type: RECEIVE_DATA, payload:[],  store:[] })
    //dispatch({ type: RECEIVE_DATA, payload: data, storeData });
    };





export const setOrder = (order, orderBy) => ({ type: SET_ORDER, order, orderBy });

export const setSelectedRow = (selected) => ({ type: SET_SELECTED_ROW, selected});