export const ADD_OPTION_NAV = 'main/add-option-nav';
export const ADVANCED_SEARCH_CLOSE = 'main/advanced-search-close';
export const ADVANCED_SEARCH_OPEN = 'main/advanced-search-open';
export const LOAD_FINISHED = 'main/load-finished';
export const MESSAGE_BOX_CLOSE = 'main/message-box-close';
export const MESSAGE_BOX_OPEN = 'main/message-box-open';
export const RECEIVE_OPTIONS_LIST = 'main/receive-options-list';
export const RECEIVE_RESOURCES_LIST = 'main/receive-resources-list';
export const REMOVE_OPTION_NAV = 'main/remove-option-nav';
export const SET_OPEN_MENU = 'main/set-open-menu';
export const SET_OPEN_SUBMENU = 'main/set-open-submenu';
export const SET_OPTION = 'main/set-option';