import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    onUpdateColumn,
    setOrder,
     setSelectedRow
} from '../../../store/fuerzaventas/metas/actions';

import { sort_by } from '../../../helpers/commons'
import { FieldSet, Table } from '@sigeco/tools';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column'
    }, 
    tableWrap: {
        flex: '1 1 auto'
    }
}));

const MetasList = () => {
    const classes = useStyles();
    const columns = useSelector(state => state.metas.table.columns);
    const loadingStore = useSelector(state => state.metas.store.loading);
    const order = useSelector(state => state.metas.table.order);
    const orderBy = useSelector(state => state.metas.table.orderBy);
    const store = useSelector(state => state.metas.store);
    const dispatch = useDispatch();

    const handleDoubleClick = (id) => {
        return;
    }

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        dispatch(setOrder(isAsc ? 'desc' : 'asc', property));
        let homes = store.data;
        if (property !=='dClienteSucursal'){
            homes.sort(sort_by('dClienteSucursal', isAsc, homes[0][property]));
        }
        homes.sort(sort_by(property, isAsc, homes[0][property]));
    };


    const handleResizeColumn = (column) => {
        dispatch(onUpdateColumn(column))
    };
    
    return (
        <FieldSet className={classes.root} iconCls="fas fa-th-list" title="Resultados">
            <div className={classes.tableWrap}>
                <Table
                    columns={columns}
                    count={store.total}
                    loading={loadingStore}
                    onRowClick={items => dispatch(setSelectedRow(items))}
                    onRowDoubleClick={item => handleDoubleClick(item.usuarioId)}
                    onSort={datakey => handleRequestSort(datakey)}
                    onUpdateColumn={handleResizeColumn}
                    page={store.start/store.pageSize}
                    paginate
                    rowCount={store.data.length}
                    rowGetter={index =>  store.data[index] }
                    rowsPerPage={store.pageSize}
                    selected={store.selected}
                    sortBy={orderBy}
                    sortDirection={order}
                />
            </div>
        </FieldSet>
    )
};

export default MetasList;