/* eslint-disable array-callback-return */
import axios from "axios";
import format from "date-fns/format";
import { IdentityUrl } from "../../../config";
import {
  closeAdvancedSearch,
  closeMessageBox,
  openAdvancedSearch,
  openMessageBox,
} from "../../main/actions";
import {
  ERROR_EFE_LIST,
  RECEIVE_EFE_LIST,
  RESTART_STATE,
  SET_MASK,
  SET_ORDER,
  SET_SELECTED_ROW,
  UPDATE_FIELD_FILTER,
} from "./types";

import { exportExcel } from "../../../helpers/commons";

export const btnExport = () => async (dispatch, getState) => {
  if (getState().ventaperdida.filters.distribuidorId   === 0) {
    dispatch(openMessageBox({
        button: 'ok',
        icon: 'warning',
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox())
    }));
    return;
  }
  if (getState().ventaperdida.store.data.length === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "No hay información para exportar.",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }

  try {
    dispatch({ type: SET_MASK, loading: true });

    let button = document.getElementById("table-xls-button-rpt-indicadores");

    button.click();
  } catch (error) {
    console.log(error);
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message:
          (error.response.data.eventLogId === 0
            ? ""
            : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    setTimeout(() => {dispatch({ type: SET_MASK, loading: false })}, 1000);
  }
};

export const onExport = () => async (dispatch, getState) => {
  // no se usa otra forma de exportar

  if (getState().ventaperdida.store.data.length === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "No hay información para exportar.",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }

  if (getState().ventaperdida.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }

  try {
    dispatch({ type: SET_MASK, loading: true });
    let head = getState().ventaperdida.table.columns;

    // eslint-disable-next-line no-array-constructor
    var temp = new Array();
    for (var c in head) {
      let x = Object.values(head[c])[0];
      temp.push(x);
    }
    // eslint-disable-next-line no-array-constructor
    var carArray = new Array();

    carArray.push(temp);

    let json = getState().ventaperdida.store.data;

    for (var i in json) {
      let x = Object.values(json[i]);
      carArray.push(x);
    }

    exportExcel(carArray, "listadoventasperdidas");
  } catch (error) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message:
          (error.response.data.eventLogId === 0
            ? ""
            : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    dispatch({ type: SET_MASK, loading: false });
  }
};

export const btnSearch = () => async (dispatch, getState) => {
  if (getState().ventaperdida.store.loading) {
    return;
  }

  if (getState().ventaperdida.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }

  dispatch({ type: SET_MASK, loading: true });

  try {
    let url =
      `${
        getState().main.resources.uriSvcSalesMobilityAPI
      }api/v1/reportes/listadoventasperdidas?` +
      `distribuidorId=${getState().ventaperdida.filters.distribuidorId}` +
      `&desde=${format(getState().ventaperdida.filters.desde, "yyyy-MM-dd")}` +
      `&hasta=${format(getState().ventaperdida.filters.hasta, "yyyy-MM-dd")}`;

    let response = await axios.get(encodeURI(url));
    let data = response.data;

    if (data.length === 0) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "No existe Registro  para esta consulta",
          callback: () => dispatch(closeMessageBox()),
        })
      );
      return;
    }

    if (data.data.length > 0) {
      data.columns[2]["width"] = 150;
      data.columns[3]["width"] = 300;
      data.columns[5]["width"] = 300;
    }

    dispatch({
      type: RECEIVE_EFE_LIST,
      columns: data.columns,
      payload: data.data,
    });
  } catch (error) {
    let msgError = "";
    dispatch({ type: SET_MASK, loading: false });
    dispatch({ type: ERROR_EFE_LIST });

    if (error.response) {
      if (error.response.status === 400) {
        msgError =
          error.response.data.eventLogId === 0
            ? "No existe Registro  para esta fecha "
            : `EventoId: ${error.response.data.eventLogId}. ` +
              error.response.data.message;
      } else {
        msgError =
          (error.response.data.eventLogId === 0
            ? ""
            : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message;
      }
    } else {
      msgError = "Error al conectar con el servidor";
    }

    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message: msgError,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    dispatch({ type: SET_MASK, loading: false });
  }
};
export const onUpdateColumn = (column) => (dispatch, getState) => {
  getState().ventaperdida.table.columns.map((item) => {
    if (item.dataKey !== column.dataKey) {
      return item;
    } else {
      item.width = column.width;
    }
 
  });
};

export const onSearchDistribuidorClick = () => async (dispatch) => {
  let fields = [
    { dataKey: "razonSocial", label: "Agente", type: "string", width: 225 },
  ];

  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de agente",
      getData: async (params) => {
        let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`;
        let response = await axios.get(encodeURI(url), { params: params });

        return response.data;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(onUpdateFieldFilter("distribuidorId", result[0].distribuidorId));
          dispatch(onUpdateFieldFilter("dDistribuidor", `${result[0].razonSocial}`));
          dispatch(onUpdateFieldFilter("supervisorId", ""));
          dispatch(onUpdateFieldFilter("dSupervisor", ""));
          dispatch(onUpdateFieldFilter("vendedorId", ""));
          dispatch(onUpdateFieldFilter("dVendedor", ""));
        }
      },
    })
  );
};

export const onUpdateFieldFilter = (key, value) => (dispatch, getState) => {
  dispatch({ type: UPDATE_FIELD_FILTER, key, value });

  dispatch({ type: RECEIVE_EFE_LIST, columns: [], payload: [] });
};
export const restartState = () => ({ type: RESTART_STATE });

export const setOrder = (order, orderBy) => ({
  type: SET_ORDER,
  order,
  orderBy,
});

export const setSelectedRow = (selected) => ({
  type: SET_SELECTED_ROW,
  selected,
});
