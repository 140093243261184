import axios from 'axios'//;
import { IdentityUrl } from '../../../config';
import { closeMessageBox,  openMessageBox } from '../../main/actions';

import {
    CLEAN_FORM,
    SET_STATE_FORM,
    UPDATE_FIELD_FILTER,
    ERROR_V_LIST,
    RECEIVE_V_LIST,
    REQUEST_V_LIST,
    SET_PAGE,
    SET_SELECTED_ROW,
    SET_MASK,
    SET_ORDER,
    ADVANCED_SEARCH_CLOSE,
    ADVANCED_SEARCH_OPEN,
} from './types';

import { exportToSpreadsheet } from '../../../helpers/commons'

export const setPage = (page) => ({ type: SET_PAGE, page });

export const cleanForm = () => ({ type: CLEAN_FORM });

export const setSelectedRow = (selected) => ({ type: SET_SELECTED_ROW, selected});

export const setFormState = (formState, id) => (dispatch, getState) => {
    switch (formState) {
        case 'edit':
            if(id && id !== getState().vendedor.entity.vendedorId) {
                //dispatch(getUser(id));
            }
            break;
        case 'new':
            dispatch(cleanForm());
            break;
        case 'search':
            dispatch(cleanForm());
            break;
        case 'view':
            if(id && id !== getState().vendedor.entity.vendedorId) {
                //dispatch(getUser(id));
            }
            break;
        default:
            break;
    }
    dispatch({ type: SET_STATE_FORM, formState });
};

export const btnSearch = () => (dispatch) => {
    dispatch(setPage(0));
    dispatch(onUserList());
};

export const btnExport = () => async (dispatch, getState) => {

    if (getState().vendedor.filters.distribuidorId   === 0) {
        dispatch(openMessageBox({
            button: 'ok',
            icon: 'warning',
            message: "Debe seleccionar un Agente..",
            callback: () => dispatch(closeMessageBox())
        }));
    return;
    }
    if(getState().vendedor.store.data.length === 0) {
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'warning', 
            message: 'No hay información para exportar.', 
            callback: () => dispatch(closeMessageBox())
        }));
        return;
    }

    try {
        dispatch({ type: SET_MASK, loading: true });

         //dettermina el pais
         let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Vendedores/listarpaislogin` 
         let response = await axios.get(encodeURI(url));
         let data = response.data;
         var aux = [];

        if  (data[0].Pais === 'CO'  || data[0].Pais === 'PE'  ){
            let head = getState().vendedor.table.columns;
            //console.log(head)
            for (var c in head)
                {
                  aux[c] = 'Distribuidor';
                  aux[1] = 'Codigo ';
                  aux[2] = 'Documento Identidad'; 
                  aux[3] = 'Nombre'; 
                  aux[4] = 'Correo'; 
                  aux[5] = 'Telefono'; 
                  aux[6] = 'Activo'; 
                                     
                }  
                
                let carArray = [];

                carArray.push(aux);
        
                let json = getState().vendedor.store.data;
               // console.log(json)
        
                for(var i in json) {
                    let x =   Object.values(json[i]);
                    x.splice(0,1);
                    x.splice(0,1);
                    //x.splice(1,1);    
                   // console.log(x)
                    carArray.push(x);
                }
                
                exportToSpreadsheet(carArray,  'Vendedor') ;
        }
        
        if (data[0].Pais === 'EC'){
            let carArray = [];

            let head = getState().vendedor.table.columns;
           // console.log(head)
            for (var d in head)
                {
                  aux[d] = 'Distribuidor';
                  aux[1] = 'Documento Identidad';
                  aux[2] = 'Nombre'; 
                  aux[3] = 'Provincia'; 
                  aux[4] = 'Canton'; 
                  aux[5] = 'Zona'; 
                  aux[6] = 'JefeZona'; 
                  aux[7] = 'Correo';
                  aux[8] = 'Telefono';
                  aux[9] = 'Activo';
                  aux[10] = 'Codigo';                          
                } 


                carArray.push(aux);
        
                let json = getState().vendedor.store.data;
               // console.log(json)
        
                for(let i in json) {
                    let x =   Object.values(json[i]);
        
                    x.splice(11,1);
                    x.splice(11,1);
                    carArray.push(x);
                }

                exportToSpreadsheet(carArray,  'Vendedor') ;    
        }

   } catch (error) {
        //console.log(error)
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'error', 
            message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
            callback: () => dispatch(closeMessageBox())
        }));
    } finally {
        setTimeout(()=>{dispatch({ type: SET_MASK, loading: false })}, 1000)
    }

}

export const onSearchDistribuidorClick = () => async (dispatch) => {
    let fields = [
        { 'dataKey': 'razonSocial', 'label': 'Agente', 'type': 'string', 'width': 225 }
    ];

    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de agente',
        getData: async (params) => {
            let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`
            let response = await axios.get(encodeURI(url), { params: params });

            return response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldFilter('distribuidorId', result[0].distribuidorId));
                dispatch(onUpdateFieldFilter('dDistribuidor', `${result[0].razonSocial}`));
                dispatch(onUpdateFieldFilter('supervisorId', ''));
                dispatch(onUpdateFieldFilter('dSupervisor', ''));
                dispatch(onUpdateFieldFilter('vendedorId', ''));
                dispatch(onUpdateFieldFilter('dVendedor', ''));
                dispatch(onUpdateFieldFilter('nombre', ''));
            }
        }
    }));
};

export const onUserList = () => async (dispatch, getState) => {
    if (getState().vendedor.store.loading) {
        return;
    }
  
    if (getState().vendedor.filters.distribuidorId === 0) {
            dispatch(openMessageBox({
                button: 'ok',
                icon: 'warning',
                message: "Debe seleccionar un Agente..",
                callback: () => dispatch(closeMessageBox())
            }));
        return;
    }
    dispatch({ type: SET_MASK, loading: true});
    //console.log(getState().vendedor);
    //dispatch({ type: RECEIVE_OLAS_LIST, columns: getState().vendedor.table.columns, payload: [] });
    let source = axios.CancelToken.source();
    dispatch({ type: REQUEST_V_LIST , source });

    try {
        //dettermina el pais
        let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Vendedores/listarpaislogin` 
            let response = await axios.get(encodeURI(url));
            let data = response.data;

        if  (data[0].Pais === 'CO'  || data[0].Pais === 'PE'  ){
                
            let columns =   [ 
                { dataKey: 'distribuidor', label: 'Distribuidor', width: 200 },
                { dataKey: 'documentoIdentidad', label: 'Cédula', width: 105 }, 
                { dataKey: 'nombre', label: 'Nombres', width: 275 },
                { dataKey: 'correo', label: 'Correo', width: 200 },
                { dataKey: 'telefono', label: 'Teléfono', width: 105 },
                { type: 'boolean', dataKey: 'activo', label: 'Activo', width: 90 },
                { dataKey: 'codigo', label: 'Código', width: 150 },
                ]
            let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Vendedores?` 
            + `soloActivos=true`
                + `&nombre=${getState().vendedor.filters.nombre}`
                + `&distribuidorId=${getState().vendedor.filters.distribuidorId}`
    
            let response = await axios.get(encodeURI(url));
            let data = response.data;
            
            if (data.length === 0) {
                dispatch(openMessageBox({
                    button: 'ok',
                    icon: 'warning',
                    message: "No existe Registro  para esta consulta",
                    callback: () => dispatch(closeMessageBox())
                }));
            return;
        }
            dispatch({ type: RECEIVE_V_LIST, columns: columns, payload: data.data });
        }
        if (data[0].Pais === 'EC'){

            let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Vendedores/listarvendedores/xzona?` 
                + `nombre=${getState().vendedor.filters.nombre}`
                + `&distribuidorId=${getState().vendedor.filters.distribuidorId}`

                let columns =   [ 
                    { dataKey: 'distribuidor', label: 'Distribuidor', width: 200 },
                    { dataKey: 'documentoIdentidad', label: 'Cédula', width: 105 }, 
                    { dataKey: 'nombre', label: 'Nombres', width: 275 },
                    { dataKey: 'Provincia', label: 'Provincia', width: 275 },
                    { dataKey: 'Canton', label: 'Cantón', width: 275 },
                    { dataKey: 'Zona', label: 'Zona', width: 275 },
                    { dataKey: 'jefeZona', label: 'Jefe de Zona', width: 275 },
                    { dataKey: 'correo', label: 'Correo', width: 200 },
                    { dataKey: 'telefono', label: 'Teléfono', width: 105 },
                    { type: 'boolean', dataKey: 'activo', label: 'Activo', width: 90 },
                    { dataKey: 'codigo', label: 'Código', width: 150 },
                    ]
 
            let response = await axios.get(encodeURI(url));
            let data = response.data;

            if (data.length === 0) {
                dispatch(openMessageBox({
                    button: 'ok',
                    icon: 'warning',
                    message: "No existe Registro  para esta consulta",
                    callback: () => dispatch(closeMessageBox())
                }));
            return;
        }
             data=  {'data': data, 'total': data.length}
  
            dispatch({ type: RECEIVE_V_LIST, columns: columns, payload: data.data });
        }

        
    } catch (error) {
        let msgError = '';
        dispatch({ type: ERROR_V_LIST });
        dispatch({ type: SET_MASK, loading: false });
   
        if (error.response) {
            msgError = (error.response.data.eventLogId === 0 ? 'No existe Registro  para esta consulta ' : `EventoId: ${error.response.data.eventLogId}. `+ error.response.data.message) 
        }
        else {
            msgError = 'Error al conectar con el servidor';
        }

        dispatch(openMessageBox({
            button: 'ok',
            icon: 'error',
            message: msgError,
            callback: () => dispatch(closeMessageBox())
        }));
    }finally{
        dispatch({ type: SET_MASK, loading: false });
    }
};


export const onUpdateFieldFilter = (key, value) => (dispatch, getState) =>{

    dispatch({ type: UPDATE_FIELD_FILTER, key, value });


    dispatch({ type: RECEIVE_V_LIST, columns: [], payload:[]});

} 

export const onUpdateColumn = (column) => (dispatch, getState) => {
    getState().vendedor.table.columns.map((item , i)=> {     
       if(item.dataKey !== column.dataKey) {
           return item;
       }else{
               item.width =  column.width;
       }
       return {
          // ...item,
         //  width: column.width
       };
      
   });
};

export const openAdvancedSearch = (opts) => ({ type: ADVANCED_SEARCH_OPEN, payload: opts });

export const setOrder = (order, orderBy) => ({ type: SET_ORDER, order, orderBy });

export const closeAdvancedSearch = () => ({ type: ADVANCED_SEARCH_CLOSE });
