import {
    RESTART_STATE,
    SET_MASK,
    UPDATE_FIELD_FILTER,
    RECEIVE_V_LIST,
    SET_ORDER,
} from './types';

const initialState = {
    loading: false,
    data:[],
    filters: {
        distribuidorId: 0,
        olaChannelMapId: 2,
        supervisorId: '',
        ale : 'casasasasassa',
        usuarioId: '',
        desde: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        hasta: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        dDistribuidor: '',
        dSupervisor: '',
    },
    store: {
        loading: false,
        pageSize: 50,
        start: 0,
        total: 0,
        data: [],
        selected: [],
        //source: null
    },
    table: {
        order: 'asc',
        orderBy: '',
        columns: []
    }
};

export const basedatosPreciosReducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case RESTART_STATE:
            return {
                ...initialState
            };
        case SET_MASK:
            return {
                ...state,
                loading: action.loading
        };
        case RECEIVE_V_LIST:
                return {
                    ...state,
                    store: {
                        ...state.store,
                        loading: false,
                        total: action.total,
                        data: action.payload,
                        source: null
                    },
                    table: {
                        ...state.table,
                        columns: action.columns,
                    }
                };
        case UPDATE_FIELD_FILTER:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.key]: action.value
                }
            };

            case SET_ORDER:
                return {
                    ...state,
                    table: {
                        ...state.table,
                        order: action.order,
                        orderBy: action.orderBy
                    }
                };
        default:
            return state;
    }
}