import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route } from 'react-router';
import { Switch } from 'react-router-dom';
import { 
  createInterceptor,
  getSession
} from './store/session/actions';

import AdvancedSearch from './components/search/AdvancedSearch';
import Form from './layouts/Form';
import Home from './pages/Home';
import Profile from './pages/Profile';
import Main from './layouts/Main';
import MessageBox from './components/messagebox/MessageBox';
import Loading from './components/loading/Loading';
import localRoutes from './routes/localRoutes';
import NotFound from './pages/NotFound';
import { createMyTheme } from './helpers/theme';
import { ThemeProvider } from '@material-ui/core/styles';

const App = () => {
  const [pais, setPais] = useState('');
  const [theme, setTheme] = useState(createMyTheme('mobil'));
  const dialog = useSelector(state => state.main.dialog);
  const loaded = useSelector(state => state.main.loaded);
  const search = useSelector(state => state.main.advancedSearch);
  const dispatch = useDispatch();

  useEffect(() => {
    fnLoadCountryAsync();
    dispatch(createInterceptor());
    dispatch(getSession());
  // eslint-disable-next-line
  }, []);

  const fnLoadCountryAsync = async () => {
    try {
      let url = `https://www.cloudflare.com/cdn-cgi/trace`;
      let response = await axios.get(url);
      let data = response.data.split('\n');
      let properties = {};

      data.forEach((item) => {
        var part = item.split('=');
        properties = { ...properties, [part[0]]: part[1] };
      });

      switch (properties.loc) {
        case 'PE':
          setPais('PE');
          setTheme(createMyTheme('mobil'));
          break;
        case 'EC':
          setPais('EC');
          setTheme(createMyTheme('mobil'));
          break;
        default:
          setPais('CO');
          setTheme(createMyTheme('default'));
      }
    } catch (error) {
      setPais('CO');
      setTheme(createMyTheme('default'));
    }
  };

  if(pais === '') {
    return (<div></div>);
  }

  if(!loaded) {
    return (
      <ThemeProvider theme={theme}>
        <Loading pais={pais}/>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Main pais={pais}>
        <Switch>
          {Object.keys(localRoutes).map((route, key) => {
            const paths = localRoutes[route].paths;
            const component = localRoutes[route].component;
            const viewType = localRoutes[route].viewType;

            return paths.map((path, index) => {
              return (
                <Route
                  exact
                  path={path}
                  key={`${key}-${index}`}
                  render={() => <Form component={component} path={viewType}/>}
                />
              );
            });
          })}
          <Route exact path="/" render={() => <Home pais={pais}/>}/>
          <Route exact path="/perfil" component={Profile} />
          <Route component={NotFound} />
        </Switch>
      </Main>
      {search.open && (<AdvancedSearch
        autoLoad={search.autoLoad}
        fields={search.fields}
        data={search.data}
        multiSelect={search.multiSelect}
        open={search.open}
        paginated={search.paginated}
        remote={search.remote}
        title={search.title}
        fnCallback={search.callback}
        getData={search.getData}
      />)}
      <MessageBox
        button={dialog.button}
        icon={dialog.icon}
        message={dialog.message}
        open={dialog.open}
        title='TERPEL S.A.'
        fnCallback={dialog.callback}
      />
    </ThemeProvider>
  );
};

export default App;