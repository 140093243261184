import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
//import * as am4core from "@amcharts/amcharts4/core";
//import _ from 'lodash';

export const ReporteSeguimientoMap = ({ google }) => {
    const [item, setItem] = useState({});
    const [marker, setMarker] = useState({});
    const [showingInfo, setShowingInfo] = useState(false);
    const coords = { lat: -8.116291741338895, lng: -79.03640628442541 };
    const store = useSelector(state => state.reporteseguimiento.store);  
  
    const geo =  store.data.filter(number =>  (!isNaN(number.Longitud)  && !isNaN(number.Latitud))); 

    const vgreen  = 'https://maps.google.com/mapfiles/ms/icons/green-dot.png';
    const vorange= 'https://maps.google.com/mapfiles/ms/icons/orange-dot.png'   

    const onMarkerClick = (marker, item) => {
        setItem(item);
        setMarker(marker);
        setShowingInfo(true);
    };

    // const getData = () => {
    //     return _(store.data)
    //         .orderBy('orden')
    //         .groupBy('Nombre')
    //         .map((item, key) => ({
    //             'marca': key,
    //             'colorHex': am4core.color(_.first(item).colorHex),
    //             'Nombre': _.sumBy(item, 'galones')
    //         }))
    //         .value()
    // }
     
    return (
        <Map
            initialCenter={  store.data.length > 0?   { lat: parseFloat(geo[0].Latitud), lng: parseFloat(geo[0].Longitud)}:  coords }
            google={google}
            style={{width: '100%', height: '100%', position: 'relative'}}
            zoom={8}
        >
            { store.data.length > 0 &&
            
            store.data.map((item, key) => (
                <Marker
                    key={key}
                    position={{lat: parseFloat(item.Latitud), lng: parseFloat(item.Longitud)}}
                    title={item.Cliente}
                    onClick={(props, marker, e) => onMarkerClick(marker, item)}
                    icon={ item.Prospecto ?vorange: vgreen  }
                />
            ))
            }
            <InfoWindow
                marker={marker}
                visible={showingInfo}
            >
                <div>
                    <h3>{item.DCliente}</h3>
                    <h3>V-{item.Vendedor}</h3>
                </div>
            </InfoWindow>


        </Map>
    )
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyCrwct6vhZk5TTSuht7PpA0kAVSdoWSB7s"
})(ReporteSeguimientoMap);