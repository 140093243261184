import React from 'react';
import {Card, Typography, CardContent} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

import AcumuladoNacional from './AcumuladoNacional';

const useStyles= makeStyles(()=>({
    root:{
        textAlign: 'center',
        backgroundColor: 'rgba(73,155,234,1)'
    },
    texto:{
        fontSize: 18,
        color: 'white'
    },
    titulo:{
        fontWeight: 'bold',
        fontSize: 20,
        color: 'white'
    }
    }));

function CardsAcumulado(props) {

    const classes=useStyles();
    return (
        <Card className={classes.root}>
            <CardContent>
            <Typography  className={classes.texto}>
                    {props.texto}
                </Typography>
                <AcumuladoNacional data= {props.data}/>
            </CardContent>  
        </Card>
    );
}

export default CardsAcumulado;