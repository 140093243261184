import {
    CLEAN_FORM,
    SET_STATE_FORM,
    UPDATE_FIELD_FILTER,
    SET_MASK,
    RECEIVE_DATA,
    UPDATE_TABLE_COLUMN,
    ERROR_V_LIST,
    RECEIVE_V_LIST,
    REQUEST_V_LIST,
    SET_ORDER,
    ADD_PRODUCTO,
    ADD_MARCA
} from './types';

const initialState = {
    loading: false,
    data:[],
    openPopup: false,
    filters: {
        distribuidorId: 0,
        dDistribuidor: '',
        supervisorId: '',
        codigo: '',
        desde: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        hasta: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        dSupervisor: '',
        vendedor: '',
        dProducto:'',
        dMarca:'',
        establecimiento: '',
        lobId: '',
        dLob: '',
        departamentoId: '',
        dDepartamento: '',
        municipioId: '',
        dMunicipio: '',
    },
    store: {
        loading: false,
        pageSize: 50,
        start: 0,
        total: 0,
        data: [],
        selected: [],
        //source: null
    },
    table: {
        order: 'asc',
        orderBy: '',
        columns: [
            { dataKey: 'Distribuidor', label: 'Distribuidor', width: 140 },
            { dataKey: 'Vendedor', label: 'Vendedor', width: 370 },
            { dataKey: 'RUC', label: 'RUC', width: 140 },
            { dataKey: 'Cliente', label: 'Cliente', width: 370 },
            { dataKey: 'LOB', label: 'LOB', width: 140 },
            { dataKey: 'Categoria', label: 'Categoria', width: 140 },
            { dataKey: 'Marca', label: 'Marca', width: 140 },
            { dataKey: 'DProducto', label: 'Descripción de Producto', width: 400 },
            { dataKey: 'Presentacion', label: 'Presentacion', width: 140 },
            { dataKey: 'Viscosidad', label: 'Viscosidad',  width: 140 },
            { dataKey: 'Certificado', label: 'Certificado',  width: 140 },
            { dataKey: 'DTipoEstablecimiento', label: 'Tipo de Establecimiento', width: 152, },
            { dataKey: 'TipoPrecio', label: 'Tipo de Precio', width: 140 },
            { dataKey: 'PrecioVenta', label: 'Precio de Venta', width: 140 },
            { dataKey: 'Departamento', label: 'Departamento', width: 140 },
            { dataKey: 'Provincia', label: 'Provincia', width: 140 },
            { dataKey: 'Distrito', label: 'Distrito', width: 140 },
            { dataKey: 'Fecha', label: 'Fecha', type: 'datetime' ,width: 140 },
            { dataKey: 'Usuario', label: 'Usuario', width: 140 } 
        ]
    }
};

export const reporteindicadoresReducer = (state, action) => {
    state = state || initialState;

    switch(action.type) {
        case CLEAN_FORM:
            return {
                ...state,
                entity: initialState.entity,
                errors: initialState.errors
            };
        case SET_STATE_FORM:
            return {
                ...state,
                formState: action.formState
            };
        case UPDATE_FIELD_FILTER:
            return { 
                ...state,
                filters: {
                    ...state.filters,
                    [action.key]: action.value
                }
            };
            case ERROR_V_LIST:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: false
                }
            };
            case ADD_PRODUCTO:
                console.log(action.payload)
                return {
                    ...state,
                    filters: {
                        ...state.filters,
                        dProducto: action.payload
                    }
                };
            case ADD_MARCA:
                console.log(action.payload)
                return {
                    ...state,
                    filters: {
                        ...state.filters,
                        dMarca: action.payload
                    }
                };
            case RECEIVE_V_LIST:
                return {
                    ...state,
                    store: {
                        ...state.store,
                        loading: false,
                        total: action.total,
                        data: action.payload,
                        source: null
                    }
                    // ,
                    // table: {
                    //     ...state.table,
                    //     columns: action.columns,
                    // }
                };
            case REQUEST_V_LIST:
                return {
                    ...state,
                    store: {
                        ...state.store,
                        loading: false,
                        source: action.source
                    }
                };
            case RECEIVE_DATA:
                return {
                    ...state,
                    store:{
                        ...state.store,
                        data: action.payload,
                    }
                };
            case UPDATE_TABLE_COLUMN:
                return {
                    ...state,
                    table: {
                        ...state.table,
                        columns: action.columns
                    }
                };
            case SET_MASK:
                return {
                    ...state,
                    loading: action.loading
            };
            case SET_ORDER:
                return {
                    ...state,
                    table: {
                        ...state.table,
                        order: action.order,
                        orderBy: action.orderBy
                    }
                };
        default:
            return state;
    }
};