import {React, useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
     onUpdateColumn,
     setOrder,
     setSelectedRow,
     setImage,
     onUpdateFieldFilter
} from '../../../store/fuerzaventas/cargarmiespacio/actions';
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import { sort_by} from '../../../helpers/commons'
import { FieldSet, Table } from '@sigeco/tools';
import makeStyles from '@material-ui/core/styles/makeStyles';

import {getDistribuidorDefault} from '../../../helpers/commons';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column'
    }, 
    tableWrap: {
        flex: '1 1 auto'
    }
}));

const CargarMiEspacioList = () => {
    const classes = useStyles();
    const columns = useSelector(state => state.cargarmiespacio.table.columns);
    const loadingStore = useSelector(state => state.cargarmiespacio.store.loading);
    const order = useSelector(state => state.cargarmiespacio.table.order);
    const orderBy = useSelector(state => state.cargarmiespacio.table.orderBy);
    const store = useSelector(state => state.cargarmiespacio.store);
    const entity = useSelector(state => state.cargarmiespacio.entity);
    const limpiar = useSelector(state => state.cargarmiespacio.clean);
    const dispatch = useDispatch();

    useEffect(() =>{

      getDistribuidorDefault(onUpdateFieldFilter,dispatch);
  
    },[])
    
    const [file, setFile] = useState(null);

    const [files, setFiles] = useState([]);
    const [previews, setPreviews] = useState([]);

    const [image, setImage] = useState([]);


    const handleFileChange = (event) => {
      
      const selectedFiles = event.target.files;
      const filePreviews = [];

  
      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];

        

        filePreviews.push(file.name);
      }
  
      setFiles(prevFiles => [...prevFiles, ...selectedFiles]);
      setPreviews(prevPreviews => [...prevPreviews, ...filePreviews]);
    };

    const handleImageChange = e => {
      e.preventDefault();
  
      let reader = new FileReader();
      let file = e.target.files[0];
  
      reader.onloadend = () => {
        setImage(prevImage => [...prevImage, reader.result]); 
      }
      reader.readAsDataURL(file);


      const selectedFiles = e.target.files;
      const filePreviews = [];

      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        filePreviews.push(file.name);
      }
  
      setFiles(prevFiles => [...prevFiles, ...selectedFiles]);
      setPreviews(prevPreviews => [...prevPreviews, ...filePreviews]);




    };

   // console.log("DATOS: ",image)
    
    //console.log("DATOS: ",file)
  //   const handleImageChange = e => {
  //     e.preventDefault();

  //     let reader = new FileReader();
  //     let file = e.target.files[0];

  //     reader.onloadend = () => {
  //         dispatch(setImage(file, reader.result));
  //         console.log("DATOS: ",file)
  //     }

      
  //     reader.readAsDataURL(file);
  // }

    // const [preview, setPreview] = useState(null);
    // const [previewData, setPreviewData] = useState(null);


    //    const [previewURL, setPreviewURL] = useState('');

    //   const handleFileChange = (event) => {
    //     const file = event.target.files[0];
    //     setFile(file);
    //     setPreviewURL(URL.createObjectURL(file));
    //   };

    // const handleFileChange = (event) => {
    //     const selectedFile = event.target.files[0];
    //     setFile(selectedFile);

    //     if(selectedFile !=null){
    //     if (selectedFile.type.includes('image') || selectedFile.type.includes('video')) {
    //       setPreview(URL.createObjectURL(selectedFile));
    //     }
    
    //     // if (selectedFile.name.includes('.xlsx') || selectedFile.name.includes('.xls')) {
    //     //   ExcelRenderer(selectedFile, (err, resp) => {
    //     //     if (err) {
    //     //       console.error(err);
    //     //     } else {
    //     //       const { rows } = resp;
    //     //       setPreviewData(rows);
    //     //     }
    //     //   });
    //     // }

    //     if (selectedFile.type.includes('application/pdf') ) {
    //         setPreview(URL.createObjectURL(selectedFile));
    //       }
    
    //     }
    //   };

    store.subida = image
    entity.name = previews

    if(limpiar.limpiador === true)
    {
      setPreviews([]);
      limpiar.limpiador = false;
    }
    // console.log("PASAAA", store.subida)

    //console.log("LOCAL",files)
    
      return (
        <FieldSet className={classes.root} iconCls="fas fa-th-list" title="Importar Archivos">
          <div>
          {/*.xlsx, .xls,*/}
            {/* <input type="file" accept="application/pdf, image/*, video/*" onChange={handleFileChange} />
            {preview && file && ( 
              <>
                {file.type.includes('image') && (
                  <img
                    style={{ width: 800, height: 350, maxWidth: 1080, maxHeight: 400, marginTop: 10, marginLeft: '20%' }}
                    src={preview}
                    alt="Preview"
                  />
                )}
                {file.type.includes('video') && (
                  <div>
                    <video
                      controls
                      style={{ width: 1000, height: 350, maxWidth: 1080, maxHeight: 400, marginTop: 10, marginLeft: '10%' }}
                    >
                      <source src={preview} type={file.type} />
                    </video>
                  </div>
                )}
                {file.type.includes('application/pdf') &&(
                <div style={{marginLeft:"30%"}}>   
                <embed src={preview} type="application/pdf" width="500" height="450" />
                </div> 
                )}
               
              </>
            )} */}
            <Grid item xs  style={{ marginTop: '15px'}}>
            <FormControl className={classes.filterClear}>
              <label style={{marginLeft:'20px' ,background:'#e62e2d', color: 'white', borderRadius: '5px', width:'140px', height:'30px', display:false }} disable = 'true'
                > <i className={"fa fa-upload"} style={{marginTop:'8px', width:"20px", height:"20px",marginLeft:'15px', color : 'white' , marginRight: '5px'}} />Subir Archivo
                <input type="file" style={{opacity : 0}}  accept="application/pdf, .png, .mp4" multiple onChange={handleImageChange} />
              </label>
            </FormControl>
            </Grid>
            {previews.length > 0 && (
              <div style={{ overflowX: 'auto', display: 'flex', justifyContent: 'center' }}>
              <table style={{marginLeft:100, marginTop: 15, margin: '0 auto', width:'100%'}}>
                <thead>
                  <tr>
                    <th>LISTA DE ARCHIVOS</th>
                  </tr>
                </thead>
                <tbody>
                  {previews.map((preview, index) => (
                    <tr key={index}>
                      <td>{preview}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              </div>
            )}

            
          </div>
     
          {/* {previewData && file && (
            <div style={{ overflowX: 'auto', display: 'flex', justifyContent: 'center' }}>
              <table style={{ margin: '0 auto' }}>
                <thead>
                  <tr>
                    {previewData[0].map((cell, index) => (
                      <th key={index}>{cell}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {previewData.slice(1).map((row, index) => (
                    <tr key={index}>
                      {row.map((cell, index) => (
                        <td key={index}>{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )} */}


            {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div>
                    <input type="file" onChange={handleFileChange} accept="application/pdf" />
                    {previewURL && (
                    <embed src={previewURL} type="application/pdf" width="500" height="450" />
                    )}
                </div>
            </div> */}


        </FieldSet>
    )
};

export default CargarMiEspacioList;