/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import randomColor from 'random-color'
import _ from 'lodash';

import { mapicon, conCat} from '../../../helpers/commons'

var markerObjects = [];


export const ClientesVendedorMap = ({ google }) => {

    const [item, setItem] = useState({});
    const [marker, setMarker] = useState({});
    const [showingInfo, setShowingInfo] = useState(false);
    const [, setResult] = useState([]);
    const coords = { lat: 9.8350311, lng: -75.120551 };
    const dataMap = useSelector(state => state.clientesvendedor.store.data);

    const [show, setShow] = React.useState(false);
    const [showV, setShowV] = React.useState(true);
    const [showE, setShowE] = React.useState(true);

    const getData = () => {
        return _(dataMap)
            .orderBy('orden')
            .groupBy('VendedorId')
            .map((item, key) => ({
                'Nombre': key
            }))
            .value()
    }

    const icono = conCat(getData());

    useEffect(() => {

        markerObjects = Array.from([]);
        //let dataMap = data.filter(f => (dVendedor === '' ? true : f.dVendedor === dVendedor))

       // if (icon.length === 0){return}
        // eslint-disable-next-line array-callback-return
        dataMap.map((item, i) => {
            let indice =  icono.find(element => parseInt(element.nombre) === parseInt(item.VendedorId)) // icon.indexOf( item.VendedorId ) 

            //aqui tengo todos
            if (!isNaN(parseFloat(item.Latitud)) && !isNaN(parseFloat(item.Longitud))
                && parseFloat(item.Latitud) !== 0 && parseFloat(item.Longitud) !== 0) {
                markerObjects.push({
                    "lat": parseFloat(item.Latitud), "lng": parseFloat(item.Longitud),
                    "lats": parseFloat(item.Latituds), "lngs": parseFloat(item.Longituds),
                    "EsProspecto": item.Tipo === 'CLIENTE' ? false : true ,
                    "nombre" : item.Vendedor,
                    "dClienteSucursal" : item.Sucursal,
                    "NombreComercial" : item.NombreComercial,
                    "Direccion" : item.Direccion,
                    "url": indice.url
                    //icon.indexOf( item.VendedorId ) 
                })
            } 
            
            else if (isNaN(parseFloat(item.Latitud)) && isNaN(parseFloat(item.Longitud)) && isNaN(parseFloat(item.Latituds)) && isNaN(parseFloat(item.Longituds))) {
                markerObjects.push({
                    "lat": parseFloat(coords.lat), "lng": parseFloat(coords.lng),
                    "lats": parseFloat(coords.lat), "lngs": parseFloat(coords.lng),
                    "EsProspecto": item.Tipo === 'CLIENTE' ? false : true ,
                    "nombre" : item.Vendedor,
                    "dClienteSucursal" : item.Sucursal,
                    "NombreComercial" : item.NombreComercial,
                    "Direccion" : item.Direccion,
                    "url": indice.url//icon.find(element => element.nombre === item.VendedorId)
                })
                
            } else if( (isNaN(parseFloat(item.Latitud)) && isNaN(parseFloat(item.Longitud))) || (parseFloat(item.Latitud) === 0 && parseFloat(item.Longitud) === 0)) {
                markerObjects.push({
                    "lat": parseFloat(item.Latituds), "lng": parseFloat(item.Longituds),
                    "lats": parseFloat(item.Latituds), "lngs": parseFloat(item.Longituds),
                    "EsProspecto": item.Tipo === 'CLIENTE' ? false : true ,
                    "nombre" : item.Vendedor,
                    "dClienteSucursal" : item.Sucursal,
                    "NombreComercial" : item.NombreComercial,
                    "url": indice.url//icon.find(element => element.nombre === item.VendedorId)
                })
            }         
        });
   

        setResult(dataMap.map((current, index) => {
            if (index === 0) {
                let color = randomColor();
                current.color = color.hexString();
            }
            else if (dataMap[index - 1].vendedorId === current.vendedorId) {
                current.color = dataMap[index - 1].color;
            }
            else {
                let color = randomColor();
                current.color = color.hexString();
            }

            return current;
        }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ dataMap]);

    var handleClickV = function () {
        if (showV) {
            setShowV(false);
        } else {
            setShowV(true);
        }
    };
    var handleClickE = function () {
        if (show) {
            setShow(false);
        }
        if (showE) {
            setShowE(false);
        } else {
            setShowE(true);
        }
    };

    const onMarkerClick = (marker, item) => {
        setItem(item);
        setMarker(marker);
        setShowingInfo(true);
    };


    return (   
        markerObjects.length !== 0
        && (
            <>
                <Map
                    initialCenter={markerObjects.length > 0  ? { lat: parseFloat(markerObjects[0].lat), lng: parseFloat(markerObjects[0].lng) } : coords}
                    google={google}
                    style={{ width: '100%', height: '100%', position: 'relative' }}
                    zoom={8}
                >

                    {markerObjects.map((item, key) => {
                        var posi
              
                            posi = { lat: parseFloat(item.lat), lng: parseFloat(item.lng)}

                            //proyectado no ejecutadp
                            if ( !item.conVisita  &&  item.OrdenVisita === 0)
                            {
                                 return (  
                                    <Marker
                                        key={`sin-${key}` }
                                        position={{  lat: posi.lat, lng : posi.lng }}// lat: parseFloat(item.latituds), lng: parseFloat(item.longituds) }}
                                        title={item.dClienteSucursal}
                                        onClick={(props, marker, e) => onMarkerClick(marker, item)}
                                        label={'proy'}
                                        icon={{
                                            url: mapicon[6],
                                        }}
                                    />
                                )
                            }
     
                         if (    item.EsProspecto  && showE )
                         {
                               return (
                                   <Marker
                                       key={`p-${key}`}
                                       position={{  lat: posi.lat, lng : posi.lng }} //lat: parseFloat(item.latitud), lng: parseFloat(item.longitud) }}
                                       title={'Pr- '+item.dClienteSucursal + ' /' + item.Direccion}
                                       onClick={(props, marker, e) => onMarkerClick(marker, item)}
                                       label={'Pr'}   
                                    //    icon={{
                                    //     url: `https://chart.googleapis.com/chart?chst=d_map_spin&chld=1|0|${item.color.replace('#', '')}|40|_|`,
                                    //     scaledSize: new google.maps.Size(36, 36),
                                    //     labelOrigin: new google.maps.Point(11, 10)
                                    // }} 
                                   />
                               )
                          }

                        if (    !item.EsProspecto  && showV )
                         {      
                            return (
                                <Marker
                                    key={`dv-${key}`}
                                    position={{ lat: parseFloat(item.lat), lng: parseFloat(item.lng) }}
                                    title={item.dClienteSucursal + ' /' + item.Direccion}
                                    onClick={(props, marker, e) => onMarkerClick(marker, item)}
                                    label={'PDV'}
                                    icon={{
                                        url: item.url,
                                        scaledSize: new google.maps.Size(56, 56),
                                        //'https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png',
                          
                                    }}
                                />
                            )
                          }
                    })
                    }
            
                    {showV && (
                        markerObjects.map((item, key) => { //punto fijos de losm PDV
                        
                        })
                    )
                    }
                    <InfoWindow
                        marker={marker}
                        visible={showingInfo}
                    >
                        <div style={{ color: '#FF8000' }}>
                        <h3>{item.NombreComercial} </h3> 
                            <h3>{item.dClienteSucursal} </h3> 
                            <h4> { item.EsProspecto  ?   '-(Prospecto)' : '-(Cliente)' }
                                </h4>                    
                            <h4>{item.nombre}</h4>
                            <div>
                                  
                            </div>
                        </div>
                    </InfoWindow>

                    {/* {
                        show ?
                            result.map((item, i) => {
                                return (
                         
                                );
                            })
                            : null
                    } */}
                </Map>

                {/* {dVendedor.length > 0 && (
                    <div style={{
                        background: 'gold',
                        opacity: 0.7,
                        left: '80%',
                        top: '65px',
                        right: '300px',
                        bottom: '0',
                        margintop: '65px',
                        marginright: '0.8rem',
                        position: 'absolute',
                        height: '12px',
                        width: '470px'
                    }}>
                        <input
                            type="button"
                            value={dVendedor}
                            onClick={handleClick}
                            style={{ height: '40px' }}
                        />
                    </div>
                )
                } */}

                {( 
                    <div style={{
                        background: 'gold',
                        opacity: 0.7,
                        left: '1%',
                        top: '65px',
                        right: '300px',
                        bottom: '0',
                        margintop: '65px',
                        marginright: '0.8rem',
                        position: 'absolute',
                        height: '12px',
                        width: '470px'
                    }}>
                        <input
                            type="button"
                            value={  showV ? 'Ocultar Pdv' : 'Ver Pdv'}
                            onClick={handleClickV}
                            style={{ height: '40px' }}
                        />
                    </div>
                    
                )    }

            {( 
                    <div style={{
                        background: 'gold',
                        opacity: 0.7,
                        left: '12%',
                        top: '65px',
                        right: '300px',
                        bottom: '0',
                        margintop: '65px',
                        marginright: '0.8rem',
                        position: 'absolute',
                        height: '12px',
                        width: '470px'
                    }}>
                        <input
                            type="button"
                            value={  showE ? 'Ocultar prospectos' : 'Ver Prospectos'}
                            onClick={handleClickE}
                            style={{ height: '40px' }}
                        />
                    </div>          
                )    }
                
            </>
        )
    )

};

export default GoogleApiWrapper({
    apiKey: "AIzaSyCrwct6vhZk5TTSuht7PpA0kAVSdoWSB7s"
})(ClientesVendedorMap);