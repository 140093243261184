import axios from 'axios';
import format from "date-fns/format";
import { closeMessageBox, openMessageBox } from '../../main/actions';
import {
    ERROR_OLAS_LIST,
    RECEIVE_OLAS_LIST,
    REQUEST_OLAS_LIST,
    RESTART_STATE,
    SET_MASK,
    SET_ORDER,
    SET_SELECTED_ROW,
    UPDATE_FIELD_FILTER,
    UPDATE_TABLE_COLUMN
} from './types';

export const btnExport = () => async (dispatch, getState) => {
    try {
        if(getState().olas.store.data.length === 0) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'warning', 
                message: 'No hay información para exportar.', 
                callback: () => dispatch(closeMessageBox())
            }));
            return;
        }

        dispatch({ type: SET_MASK, loading: true });

        let url = `${getState().main.resources.uriSvcSalesMobilityAPI}/api/v1/olaschannelmap/exportar?`
            + `desde=${format(getState().olas.filters.desde, 'yyyy-MM-dd')}`
            + `&hasta=${format(getState().olas.filters.hasta, 'yyyy-MM-dd')}`;
        let response = await axios.get(encodeURI(url), { responseType: 'arraybuffer' });
        let csvHref = window.URL.createObjectURL(new Blob([response.data]));
        let link = document.createElement('a');

        link.href = csvHref;
        link.setAttribute('download', 'olaschannelmap.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'error', 
            message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
            callback: () => dispatch(closeMessageBox())
        }));
    } finally {
        dispatch({ type: SET_MASK, loading: false });
    }
};

export const btnSearch = () => async (dispatch, getState) => {
    if (getState().olas.store.loading) {
        return;
    }

    dispatch({ type: REQUEST_OLAS_LIST });

    try {
        let url = `${getState().main.resources.uriSvcSalesMobilityAPI}/api/v1/olaschannelmap/reportar?`
            + `&desde=${format(getState().olas.filters.desde, 'yyyy-MM-dd')}`
            + `&hasta=${format(getState().olas.filters.hasta, 'yyyy-MM-dd')}`;
        let response = await axios.get(encodeURI(url));
        let data = response.data;
        
        if (data.length === 0) {
            dispatch(openMessageBox({
                button: 'ok',
                icon: 'warning',
                message: "No existe Registro  para esta consulta",
                callback: () => dispatch(closeMessageBox())
            }));
        return;
    }

        dispatch({ type: RECEIVE_OLAS_LIST, columns: data.columns, payload: data.data });
    } catch (error) {
        let msgError = '';
        dispatch({ type: ERROR_OLAS_LIST });

        if (error.response) {
            msgError = (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message;
        }
        else {
            msgError = 'Error al conectar con el servidor';
        }

        dispatch(openMessageBox({
            button: 'ok',
            icon: 'error',
            message: msgError,
            callback: () => dispatch(closeMessageBox())
        }));
    }
};

export const onUpdateColumn = (column) => (dispatch, getState) => {
    let columns = getState().olas.table.columns.map(item => {
        console.log(item);
        console.log(column)
        if(item.dataKey !== column.dataKey) {
            return item;
        }

        return {
            ...item,
            width: column.width
        };
    });

    dispatch({ type: UPDATE_TABLE_COLUMN, columns });
};

//export const onUpdateFieldFilter = (key, value) => ({ type: UPDATE_FIELD_FILTER, key, value });

export const onUpdateFieldFilter = (key, value) => (dispatch, getState) =>{

    dispatch({ type: UPDATE_FIELD_FILTER, key, value });


    dispatch({ type: RECEIVE_OLAS_LIST, columns: [], payload:[]});

} 








export const restartState = () => ({ type: RESTART_STATE });

export const setOrder = (order, orderBy) => ({ type: SET_ORDER, order, orderBy });

export const setSelectedRow = (selected) => ({ type: SET_SELECTED_ROW, selected});