import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { onUpdateFieldFilter} from "../../store/user/actions";

import {FieldSet } from "@sigeco/tools";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FormControl from "@material-ui/core/FormControl";
import { Icon } from "@material-ui/core";
import Button from "@material-ui/core/Button";


const useStyles = makeStyles(() => ({
  content: {
    padding: "0.5rem 0.75rem 0.75rem",
  },
  filterClear: {
    display: "flex",
  },
  actionField: {
    flexDirection: "row",
    position: "relative",
  },
  btnClear: {
    position: "absolute",
    bottom: "1.5",
    right: 20,
    maxWidth: "15px",
    maxHeight: "23px",
    minWidth: "15px",
    minHeight: "23px",
  },
  root: {
    flex: "0 0 auto",
    marginBottom: "0.75rem",
    "@media (min-width: 1401px)": {
      marginBottom: "1rem",
    },
  },
}));

const UserFilter = () => {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:701px)");
  const filters = useSelector((state) => state.user.filters);
  const dispatch = useDispatch();
const [, setNombre] = useState("");
const [btn, setBtn] = useState(true);
  const [, setDistribuidor] = useState("");
  const [, setBtn1] = useState(true);

  function onFieldChange(event) {
    filters.dDistribuidor = "";
    setDistribuidor("");
    setBtn1(true);
    dispatch(onUpdateFieldFilter(event.target.name, event.target.value));
  }

  useEffect(() => {
   
    if (filters.dDistribuidor) {
     // onChange(parseInt(filters.distribuidorId))
      setDistribuidor(filters.dDistribuidor);
      setBtn1(false);
    } else {
      setBtn1(true);
    }
    
  }, [filters.dDistribuidor]);
  
  useEffect(() => {
    if (filters.nombre) {
      setNombre(filters.nombre);
      setBtn(false);
    } else {
      setBtn(true);
    }
    
  }, [filters.nombre]);

  const handleNombre = () => {
    setNombre("");
    setDistribuidor("");
    filters.nombre = "";
    filters.dDistribuidor = "";
    setBtn(true);
    setBtn1(true);
  };

   
 
  return (
    <div className={classes.root}>
      <FieldSet iconCls="fas fa-filter" title="Filtros" defaultExpanded>
        <Grid className={classes.content} container spacing={2}>
          <Grid item xs={12} container>
            <Grid item xs={matches ? 4 : 12}>
              <FormControl className={classes.filterClear}>
                <TextField
                  className={classes.actionField}
                  autoComplete="off"
                  fullWidth
                  label="Nombres o usuario"
                  name="nombre"
                  value={filters.nombre}
                  variant={matches ? "outlined" : "standard"}
                  onChange={onFieldChange}
                />
                <Button
                  style={{ right: 0 }}
                  className={classes.btnClear}
                  size="small"
                  startIcon={<Icon className="fa fa-window-close" />}
                  disabled={btn}
                  onClick={handleNombre}
                />
                
              </FormControl>
             
            </Grid>
          </Grid>  
         
        </Grid>
      </FieldSet>
    </div>
  );
};

export default UserFilter;
