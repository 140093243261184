import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    btnExport,
    restartState
  } from '../../store/movilidad/detallespedido/actions';

import { Body, Footer, Page } from '@sigeco/tools';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';

import DetallesPedidoFilter from '../../components/movilidad/detallespedido/DetallesPedidoFilter';

const DetallesPedido = ({onClose, setTitle}) => {
    const loading = useSelector(state => state.detallespedido.loading);
    const dispatch = useDispatch();
//prueba para que suba
    useEffect(() => {
        setTitle('Descargar');
    // eslint-disable-next-line
    }, []);

    function onBtnClose() {
        dispatch(restartState());
        onClose();
    }

    return (
        <Page>
            <Backdrop style={{ position: 'absolute', zIndex: 1200 }} open={loading} transitionDuration={0}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <Body>
            <DetallesPedidoFilter />
        </Body>
        <Footer>
            <Button
                color="primary"
                size="small"
                variant="outlined"
                onClick={() => dispatch(btnExport())}
                startIcon={<Icon className="fas fa-file-excel"/>}
            >
                Exportar
            </Button>
            <Button 
                color="primary" 
                size="small" 
                variant="outlined" 
                onClick={() => onBtnClose()} 
                startIcon={<Icon className="fas fa-door-open"/>}
            >
                Salir
            </Button>
        </Footer>
        </Page>
    )
};

export default DetallesPedido;