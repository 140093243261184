import React, { useEffect} from 'react';//, useState 
import { useDispatch, useSelector } from 'react-redux';//, useSelector
//import { closeMessageBox,  openMessageBox } from  '../../store/main/actions';

import {
    btnSearchPasePedido,
    onExport,
    btnSearchDescuento,
    btnSearchAprobarCobranza,
} from '../../store/movilidad/bandejaprobacion/actions';

import { Body, Footer, Page } from '@sigeco/tools';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

import  BandejAprobacionFilter from '../../components/movilidad/bandejaprobacion/BandejAprobacionFilter';
import BandejAprobacionPanel from '../../components/movilidad/bandejaprobacion/BandejAprobacionPanel';

const BandejAprobacion = ({onClose, setTitle}) => {
    const dispatch = useDispatch();
    const bandeja = useSelector((state) => state.bandejaaprobacion.panel);
 
    useEffect(() => {
        setTitle('Bandeja Aprobación');
    // eslint-disable-next-line
    }, []);

    function onBtnClose() {
        onClose();
    }

    const handleSearch = () =>{
        console.log(bandeja)
        bandeja === 0 ? dispatch(btnSearchPasePedido()): bandeja === 1 ? dispatch(btnSearchDescuento()): dispatch(btnSearchAprobarCobranza())
    }

    return (
        <Page>
            {/* <Backdrop style={{ position: 'absolute', zIndex: 1200 }} open={false} transitionDuration={0}>
            <CircularProgress color="inherit" />
            </Backdrop> */}
        <Body>
            <BandejAprobacionFilter />
            <BandejAprobacionPanel />
        </Body>
        <Footer>
            <Button
                color="primary"
                size="small"
                variant="outlined"
                onClick={() => dispatch(onExport())}
                startIcon={<Icon className="fas fa-file-excel"/>}
            >
                Exportar
            </Button>

            <Button
                color="primary"
                size="small"
                variant="outlined"
                onClick={ handleSearch }
                startIcon={<Icon className="fas fa-search"/>}
            >
                Buscar
            </Button>
            <Button 
                color="primary" 
                size="small" 
                variant="outlined" 
                onClick={() => onBtnClose()} 
                startIcon={<Icon className="fas fa-door-open"/>}
            >
                Salir
            </Button>
        </Footer>
        </Page>
    )
};

export default BandejAprobacion;