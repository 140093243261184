import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { useLocation, useRouteMatch } from "react-router-dom";


import {
    btnSearch,
    btnSave,
    onExport ,
    restartState,
    setFormState
} from '../../store/precios/precios/actions';

import { Body, Footer, Page } from '@sigeco/tools';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import Icon from '@material-ui/core/Icon';

import PreciosList from '../../components/precios/precios/PreciosList';
import PreciosFilter from '../../components/precios/precios/PreciosFilter';
import PreciosForm from '../../components/precios/precios/PreciosForm';
import PreciosTable from '../../components/precios/precios/PreciosTable';

const Precios = ({onClose, setTitle}) => {
    const location = useLocation();
    const match = useRouteMatch();

    const formState = useSelector(state => state.precios.formState);
    const loading = useSelector(state => state.precios.loading);
    const dtoId = useSelector(state => state.precios.entity.productoId);
    //const store = useSelector(state => state.productos.store);

    const dispatch = useDispatch();

    useEffect(() => {
        switch(match.path) {
          case '/precios/precios/crear':
            setTitle('Crear Precios');
            if(formState !== 'new') {
                dispatch(setFormState('new'));
            }
            break;
            case '/precios/productos/:id':
                dispatch(setFormState('view', match.params['id']));
                setTitle('Visualizar Producto');
                break;
      
          case '/precios/productos/:id/editar':
            dispatch(setFormState('edit', match.params['id']));
            setTitle('Editar Producto');
            break;
          default:
            dispatch(setFormState('search'));
            setTitle('Búsqueda');
            break;
        }
      // eslint-disable-next-line
      }, [match.path]);


    function onBtnClose() {
        dispatch(restartState());
        onClose();
      }

    return (
        
        <Page>
        <Backdrop style={{ position: 'absolute', zIndex: 1200 }} open={loading} transitionDuration={0}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Body>
       
          {formState === 'search' &&  <PreciosFilter />}
          {formState === 'search' &&  <PreciosList />}
          {formState !== 'search' &&  <PreciosForm/>}
          {formState !== 'search' &&  <PreciosTable/>}

        </Body>
        <Footer>
        
          {formState === 'search' && <Button
            color="primary" 
            size="small" 
            variant="outlined" 
            onClick={() => dispatch(onExport())}
            startIcon={<Icon className="fas fa-file-excel"/>}
          >
            Exportar
          </Button>}
          {formState === 'search' && <Button
            color="primary" 
            size="small" 
            variant="outlined"
            onClick={() => dispatch(btnSearch())}
            startIcon={<Icon className="fas fa-search"/>}
          >
            Buscar
          </Button>}
          {(formState === 'search' || formState === 'view') && <LinkContainer to={{ pathname: '/precios/precios/crear', search: location.search, hash: location.hash }}>
            <Button
              color="primary" 
              size="small" 
              variant="outlined"
              startIcon={<Icon className="fas fa-file"/>}
            >
              Crear
            </Button>
          </LinkContainer>}
         
          {formState === 'view' && <Button 
            color="primary" 
            size="small" 
            variant="outlined" 
           // onClick={() => dispatch(btnDelete())}
            startIcon={<Icon className="fas fa-trash"/>}
          >
            Eliminar
          </Button>}
          {formState !== 'search' && formState !== 'view' && <Button
            color="primary" 
            size="small" 
            variant="outlined" 
            onClick={() => dispatch(btnSave())}
            startIcon={<Icon className="fas fa-save"/>}
          >
            Guardar
          </Button>}
          {formState !== 'search' && <LinkContainer to={{ pathname: '/precios/precios', search: location.search, hash: location.hash }}>
            <Button
              color="primary" 
              size="small" 
              variant="outlined"
              startIcon={<Icon className="fas fa-times-circle"/>}
            >
              Cancelar
            </Button>
          </LinkContainer>}
          <Button 
            color="primary" 
            size="small" 
            variant="outlined" 
            onClick={() => onBtnClose()} 
            startIcon={<Icon className="fas fa-door-open"/>}
          >
            Salir
          </Button>
        </Footer>
      </Page>
    );

};

export default Precios;