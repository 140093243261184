import React, { useEffect} from 'react';
import { useDispatch, useSelector} from 'react-redux';
import {
    btnSearch,
    closeDialogClient,
    btnExport 
} from '../../store/movilidad/reportedeincidencias/actions';

import { Body, Footer, Page } from '@sigeco/tools';
import AppBar from '@material-ui/core/AppBar'; 
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { Button, Icon, makeStyles } from "@material-ui/core";

import ReporteDeIncidenciasList from '../../components/movilidad/reportedeincidencias/ReporteDeIncidenciasList';
import ReporteDeIncidenciasFilter from '../../components/movilidad/reportedeincidencias/ReporteDeIncidenciasFilter';
import ReporteDeIncidenciasListDetail from '../../components/movilidad/reportedeincidencias/ReporteDeIndidenciasListDetail';

const useStyles = makeStyles(() => ({
    content: {
        padding: '0.5rem 0.75rem 0.75rem'
    },
    root: {
        flex: '0 0 auto',
        marginBottom: '0.75rem',
        '@media (min-width: 1401px)': {
            marginBottom: '1rem',
        }
    }
}));

const ReporteDeIncidencias= ({onClose, setTitle}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    //const loading = useSelector(state => state.reportedeincidencias.loading);
    const openPopup = useSelector(state => state.reportedeincidencias.openPopup);

    
    useEffect(() => {
        setTitle('Búsqueda');
    // eslint-disable-next-line
    }, []);

    function onBtnClose() {
        onClose();
    }

    // const hanldeData = (item) => {
    //     console.log(item.data)
    // } 

    return (
        <Page className={classes.root}>
            
            <Body>
                <ReporteDeIncidenciasFilter />
                <ReporteDeIncidenciasList />
            </Body>

            <Footer>
                <Button
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={() => dispatch(btnExport())}
                    startIcon={<Icon className="fas fa-file-excel"/>}
                  >
                    Exportar
                </Button>
              
                <Button
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={() => dispatch(btnSearch())}
                    startIcon={<Icon className="fas fa-search"/>}
                >
                    Buscar
                </Button>
                <Button 
                    color="primary" 
                    size="small" 
                    variant="outlined" 
                    onClick={() => onBtnClose()} 
                    startIcon={<Icon className="fas fa-door-open"/>}
                >
                    Salir
                </Button>
            </Footer>

            <Dialog fullScreen open={openPopup}  style={{overflow: 'none'}} onClose={() => dispatch(closeDialogClient()) }>
                <AppBar position="relative" style={{overflow: 'none'}}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => dispatch(closeDialogClient())} aria-label="close">
                            <Icon className="fas fa-times" />
                        </IconButton>
                        <Typography variant="h6">
                            Fotos de Incidencias
                        </Typography>
                    </Toolbar>
                </AppBar>
                <ReporteDeIncidenciasListDetail />
            </Dialog>


        </Page>
    )
};

export default ReporteDeIncidencias;