import axios from 'axios';
import format from "date-fns/format";
import { IdentityUrl } from '../../../config';
import { closeAdvancedSearch, closeMessageBox, openAdvancedSearch, openMessageBox } from '../../main/actions';
import {
    FILTER_MAP_VENDEDOR,
    HIDE_POPUP_CLIENT,
    RECEIVE_DATA,
    RECEIVE_V_LIST, 
    SET_MASK,
    SET_ORDER,
    SET_SELECTED_ROW,
    SHOW_POPUP_CLIENT,
    UPDATE_FIELD_FILTER,
    UPDATE_DIST_FIELD_FILTER,
} from './types';

import {  exportExcel,tipos, aGondola } from '../../../helpers/commons'

export const btnExport = () => async (dispatch, getState) => {
    
    if (getState().reporteidentificacionmercado.filters.distribuidorId   === 0) {
        dispatch(openMessageBox({
            button: 'ok',
            icon: 'warning',
            message: "Debe seleccionar un Agente..",
            callback: () => dispatch(closeMessageBox())
        }));
    return;
    }
    if(getState().reporteidentificacionmercado.store.data.length === 0) {
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'warning', 
            message: 'No hay información para exportar.', 
            callback: () => dispatch(closeMessageBox())
        }));
        return;
    }
    
    try {
        dispatch({ type: SET_MASK, loading: true });

        let head = getState().reporteidentificacionmercado.table.columns;
        var aux = [];

        //console.log(head)
            for (var c in head)
                {
                  aux[c] = 'fecha'
                  aux[1] = 'Distribuidor';//nit
                  aux[2] =  'Vendedor'; //Object.values(head[2])[1];//nombre
                  aux[3] =  'Ruc'; //Object.values(head[3])[1];//direccion
                  aux[4] = 'Cliente'; //Object.values(head[3])[1];//segmento
                  aux[5] =  'Segmento'; //Object.values(head[4])[1];//marca 1
                  aux[6] =  'Fachada '; //Object.values(head[5])[1];//marca 2
                  aux[7] = 'Pared'; // Object.values(head[6])[1];//marca 3
                  aux[8] = 'Pared2'; // Object.values(head[3])[1];//tipo
                  aux[9] = 'Pared3'; // Object.values(head[8])[1];//observacion
                  aux[10] = 'Gondola'; 
                  aux[11] = 'Visibilidad';
                  aux[12] =  'Departamento'; //Object.values(head[10])[1];//provincia
                  aux[13] = 'Provincia' ; //Object.values(head[11])[1];//distrito
                  aux[14] = 'Distrito'; //Object.values(head[7])[1];//gondola
                  aux[15] = 'Dirección'; //Object.values(head[7])[1];//gondola
                  aux[16] = 'Observación'; // //Object.values(head[7])[1];//gondola


                    //aux.push(x);
                }

        var carArray = [];

        carArray.push(aux);

        let json = getState().reporteidentificacionmercado.store.data;
        //console.log(json)

        for(var i in json) {
            let x =   Object.values(json[i]);
            x.splice(0,1);
            x.splice(21,1);
            x.splice(20,1);
            x.splice(19,1);
            x.splice(18,1);
            x.splice(17,1);
            

            //x.splice(12,1);
            //x.splice(14,1);
          
            console.log(x)
            carArray.push(x);
          }
          //console.log(carArray)
        
          exportExcel(carArray,  'ReporteIdentificacion') ;

    } catch (error) {
        dispatch({ type: SET_MASK, loading: false })
        console.log(error)
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'error', 
            message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
            callback: () => dispatch(closeMessageBox())
        }));
    } finally {
        setTimeout(() => {dispatch({ type: SET_MASK, loading: false })}, 1000);
    }
};

export const btnSearch = () => async (dispatch, getState) => {
    try {
        dispatch({ type: SET_MASK, loading: true });

        if (getState().reporteidentificacionmercado.store.loading) {
            return;
        }

        if (getState().reporteidentificacionmercado.filters.distribuidorId   === 0) {
            dispatch(openMessageBox({
                button: 'ok',
                icon: 'warning',
                message: "Debe seleccionar un Agente..",
                callback: () => dispatch(closeMessageBox())
            }));
        return;
        }
        let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/reportes/marketing/reporteidentificacion?`
            + `distribuidorId=${getState().reporteidentificacionmercado.filters.distribuidorId}`
            + `&departamentoId=${getState().reporteidentificacionmercado.filters.departamentoId}`
            + `&provinciaId=${getState().reporteidentificacionmercado.filters.provinciaId}`
            + `&distritoId=${getState().reporteidentificacionmercado.filters.distritoId}`
            + `&marcaidentificacionId=${getState().reporteidentificacionmercado.filters.marcaidentificacionId}`
            + `&dTipo=${getState().reporteidentificacionmercado.filters.dTipo.substring(0,1)}`
            + `&gondola=${getState().reporteidentificacionmercado.filters.gondola}`
            + `&nombre=${getState().reporteidentificacionmercado.filters.nombre}`
            + `&desde=${format(getState().reporteidentificacionmercado.filters.desde, 'yyyy-MM-dd')}`
            + `&hasta=${format(getState().reporteidentificacionmercado.filters.hasta, 'yyyy-MM-dd')}`;
        let response = await axios.get(encodeURI(url));
        let data = response.data;
        
        if (data.length === 0) {
            dispatch(openMessageBox({
                button: 'ok',
                icon: 'warning',
                message: "No existe Registro  para esta consulta",
                callback: () => dispatch(closeMessageBox())
            }));
        return;
    }
      
        //  let storeData = [
        //     {  fecha: '21/07/2022', ruc: '12345678', dCliente: 'Cliente 1', fachada: 'Mobil', marca: 'chevron', tipo:'Gondola',tipo2:'tipo 2', tipo3: 'tipo 3', dObservacion:'local con promotaria' },
        //     {  fecha: '21/07/2022', ruc: '12345678', dCliente: 'Cliente 2', fachada: 'Mobil', marca: 'chevron', tipo:'Gondola',tipo2:'tipo 2', tipo3: 'tipo 3', dObservacion:'local con promotaria' },
        //     {  fecha: '21/07/2022', ruc: '12345678', dCliente: 'Cliente 3', fachada: 'Mobil', marca: 'chevron', tipo:'Gondola',tipo2:'tipo 2', tipo3: 'tipo 3', dObservacion:'local con promotaria' },
        //     {  fecha: '21/07/2022', ruc: '12345678', dCliente: 'Cliente 4', fachada: 'Mobil', marca: 'chevron', tipo:'Gondola',tipo2:'tipo 2', tipo3: 'tipo 3',  dObservacion:'local con promotaria' },
        //     {  fecha: '21/07/2022', ruc: '12345678', dCliente: 'Cliente 5', fachada: 'Mobil', marca: 'chevron', tipo:'Gondola',tipo2:'tipo 2', tipo3: 'tipo 3', dObservacion:'local con promotaria' }
        // ];
        
        //console.log(getState().reporteidentificacionmercado.filters.UriFoto01)
        //console.log(data.['UriFoto01'])
        let columnas = getState().reporteidentificacionmercado.table.columns;
       
        dispatch({ type: RECEIVE_V_LIST, columns: columnas, payload: data});
        
    } catch (error) {
       
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'error', 
            message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
            callback: () => dispatch(closeMessageBox())
        }));
    } finally {
        dispatch({ type: SET_MASK, loading: false });
    }

    
};

export const onSearchDepartaClick = () => async (dispatch, getState) => {
    let fields = [
        { 'dataKey': 'nombre', 'label': 'Nombre', 'type': 'string', 'width': 225 }
    ];

    if (getState().reporteidentificacionmercado.filters.distribuidorId   === 0) {
        dispatch(openMessageBox({
            button: 'ok',
            icon: 'warning',
            message: "Debe seleccionar un Agente..",
            callback: () => dispatch(closeMessageBox())
        }));
    return;
    }
    try {
        dispatch(openAdvancedSearch({
            autoLoad: true,
            data: [],
            fields: fields,
            paginated: true,
            remote: true,
            title: 'Seleccione un Departamento',
            getData: async (params) => {
                
                let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/agentes/departamentos`;
                let response = await axios.get(encodeURI(url), { params: params });
                //console.log(response)
                const  aux = {
                    data: response.data,
                    pageSize: 50,
                    start: 0,
                    total: response.data.length
                }
                return aux;  
            },
            callback: (btn, result) => {
                dispatch(closeAdvancedSearch());
                if(btn === 'yes') {
                    dispatch(onUpdateFieldFilter('departamentoId', result[0].codigo));
                    dispatch(onUpdateFieldFilter('dDepartamento', `${result[0].nombre}`));
                    dispatch(onUpdateFieldFilter('dProvincia', ''));
                    dispatch(onUpdateFieldFilter('provinciaId', '00'));
                    dispatch(onUpdateFieldFilter('dDistrito', ''));
                    dispatch(onUpdateFieldFilter('distritoId', '00'));
                }
            }
        }));
    } catch (error) {
        if(error.response) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        }
    }
};

export const onSearcProvinciaClick = () => async (dispatch, getState) => {
    let fields = [
        { 'dataKey': 'nombre', 'label': 'Nombre', 'type': 'string', 'width': 225 }
    ];
    if (getState().reporteidentificacionmercado.filters.distribuidorId   === 0) {
        dispatch(openMessageBox({
            button: 'ok',
            icon: 'warning',
            message: "Debe seleccionar un Departamento..",
            callback: () => dispatch(closeMessageBox())
        }));
    return;
    }
    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Seleccióne una Provincia',
        getData: async (params) => {
            
            let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/agentes/provincias?`
            + `codigodepartamento=${getState().reporteidentificacionmercado.filters.departamentoId}`
            let response = await axios.get(encodeURI(url), { params: params });
    
            const  aux = {
                data: response.data  ,
                pageSize: 50,
                start: 0,
                total: response.data.length
            }
            return aux;  
           
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldFilter('provinciaId', result[0].codigo));
                dispatch(onUpdateFieldFilter('dProvincia', `${result[0].nombre}`));
                dispatch(onUpdateFieldFilter('dDistrito', ''));
                dispatch(onUpdateFieldFilter('distritoId', '00'));

            }
        }
    }));
};

export const onSearcDistritoClick = () => async (dispatch, getState) => {
    let fields = [
        { 'dataKey': 'nombre', 'label': 'Nombre', 'type': 'string', 'width': 225 }
    ];
    if (getState().reporteidentificacionmercado.filters.distribuidorId   === 0) {
        dispatch(openMessageBox({
            button: 'ok',
            icon: 'warning',
            message: "Debe seleccionar un Provincia..",
            callback: () => dispatch(closeMessageBox())
        }));
    return;
    }
    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Seleccióne un Distrito',
        getData: async (params) => {
            
            let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/agentes/distritos?`
            + `codigodepartamento=${getState().reporteidentificacionmercado.filters.departamentoId}`
            + `&codigoprovincia=${getState().reporteidentificacionmercado.filters.provinciaId}`
            let response = await axios.get(encodeURI(url), { params: params });
    
            const  aux = {
                data: response.data  ,
                pageSize: 50,
                start: 0,
                total: response.data.length
            }
            return aux;  
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldFilter('distritoId', result[0].codigo));
                dispatch(onUpdateFieldFilter('dDistrito', `${result[0].nombre}`));

            }
        }
    }));
};

export const onSearchMarcasIdentificacionClick = () => async (dispatch, getState) => {
    let fields = [
        { 'dataKey': 'nombre', 'label': 'Nombre', 'type': 'string', 'width': 225 }
    ];

    if (getState().reporteidentificacionmercado.filters.distribuidorId   === 0) {
        dispatch(openMessageBox({
            button: 'ok',
            icon: 'warning',
            message: "Debe seleccionar un Agente..",
            callback: () => dispatch(closeMessageBox())
        }));
    return;
    }
    try {
        dispatch(openAdvancedSearch({
            autoLoad: true,
            data: [],
            fields: fields,
            paginated: true,
            remote: true,
            title: 'Seleccione una Marca',
            getData: async (params) => {
            
                let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Marcas/paraidentificacion?`
                + `nombre=`;
                let response = await axios.get(encodeURI(url), { params: params });
                console.log(url)
                const  aux = {
                    data: response.data  ,
                    pageSize: 50,
                    start: 0,
                    total: response.data.length
                }
                return aux;  
            },
            callback: (btn, result) => {
                dispatch(closeAdvancedSearch());
                if(btn === 'yes') {
                    dispatch(onUpdateFieldFilter('marcaidentificacionId', result[0].marcaId ));
                    dispatch(onUpdateFieldFilter('dMarcaIdentificacion', `${result[0].nombre}`));
                    //dispatch(onUpdateFieldFilter('dMarcaIdentificacion', ''));
                }
            }
        }));
    } catch (error) {
        if(error.response) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        }
    }
};

export const onSearchTipoClick = () => async (dispatch, getState) => {
    let fields = [
        { 'dataKey': 'dTipo', 'label': 'Tipo', 'type': 'string', 'width': 250 }
    ];
    if (getState().reporteidentificacionmercado.filters.distribuidorId   === 0) {
        dispatch(openMessageBox({
            button: 'ok',
            icon: 'warning',
            message: "Debe seleccionar un Agente..",
            callback: () => dispatch(closeMessageBox())
        }));
    return;
    }
    let data = tipos;

    try {
        dispatch(openAdvancedSearch({
            autoLoad: true,
            data: data,
            fields: fields,
            paginated: false,
            remote: false,
            title: 'Seleccione un Tipo',
            callback: (btn, result) => {
                dispatch(closeAdvancedSearch());
                if(btn === 'yes') {
                    dispatch(onUpdateFieldFilter('tipoId', result[0].tipoId));
                    dispatch(onUpdateFieldFilter('dTipo', result[0].dTipo));
                   // dispatch(onUpdateFieldFilter('dTipo', ''));
                }
            }
        }));
    } catch (error) {
        if(error.response) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        }
    }
};

export const onSearchGondolaClick = () => async (dispatch, getState) => {
    let fields = [
      { dataKey: "dGondola", label: "Góndola", type: "string", width: 250 },
    ];
    if (getState().reporteidentificacionmercado.filters.distribuidorId === 0) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "Debe seleccionar un Agente..",
          callback: () => dispatch(closeMessageBox()),
        })
      );
      return;
    }
  
    let data = aGondola;
  
    try {
      dispatch(
        openAdvancedSearch({
          autoLoad: true,
          data: data,
          fields: fields,
          paginated: false,
          remote: false,
          title: "Seleccione Góndola",
          callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if (btn === "yes") {
              dispatch(
                onUpdateFieldFilter("gondola",result[0].gondola)
              );
              dispatch(
                onUpdateFieldFilter("dGondola", result[0].dGondola)
              );
              // dispatch(onUpdateFielddata('data', []));
            }
          },
        })
      );
    } catch (error) {
      if (error.response) {
        dispatch(
          openMessageBox({
            button: "ok",
            icon: "error",
            message:
              (error.response.data.eventLogId === 0
                ? ""
                : `EventoId: ${error.response.data.eventLogId}. `) +
              error.response.data.message,
            callback: () => dispatch(closeMessageBox()),
          })
        );
      }
    }
  };

export const closeDialogClient = () => ({ type: HIDE_POPUP_CLIENT });

export const filterMapByVendedor = (dVendedor) => ({ type: FILTER_MAP_VENDEDOR, dVendedor });

export const onSearchDistribuidorClick = () => async (dispatch) => {
    let fields = [
        { 'dataKey': 'razonSocial', 'label': 'Agente', 'type': 'string', 'width': 225 }
    ];

    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de agente',
        getData: async (params) => {
            let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`
            let response = await axios.get(encodeURI(url), { params: params });
            //console.log(response.data)
            return response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldFilter('distribuidorId', result[0].distribuidorId));
                dispatch(onUpdateFieldFilter('dDistribuidor', `${result[0].razonSocial}`));
                dispatch(onUpdateFieldFilter('departamentoId', '00'));
                dispatch(onUpdateFieldFilter('dDepartamento', ''));
                dispatch(onUpdateFieldFilter('provinciaId', '00'));
                dispatch(onUpdateFieldFilter('dProvincia', ''));
                dispatch(onUpdateFieldFilter('distritoId', '00'));
                dispatch(onUpdateFieldFilter('dDistrito', ''));
                dispatch(onUpdateFieldFilter('dMarcaIdentificacion', ''));
                dispatch(onUpdateFieldFilter('marcaidentificacionId', 0));
                dispatch(onUpdateFieldFilter('dTipo', ''));
                dispatch(onUpdateFieldFilter('tipoId', 0));
                dispatch(onUpdateFieldFilter('nombre', ''));
                dispatch(onUpdateFieldFilter('dGondola', ''));
                dispatch(onUpdateFieldFilter('gondola', '99'));
                //dispatch(onUpdateDistFieldFilter);
            }
        }
    }));
};

export const onSearchSupervisorClick = () => async (dispatch, getState) => {
    let fields = [
        { 'dataKey': 'nombre', 'label': 'Nombre', 'type': 'string', 'search': true, 'width': 225 },
        { 'dataKey': 'nombreUsuario', 'label': 'Usuario', 'type': 'string', 'width': 115 }
    ];

    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de supervisor',
        getData: async (params) => {
            let url = `${IdentityUrl}/api/v1/usuarios?rol=Supervisor`
            let response = await axios.get(encodeURI(url), { params: params });
            let distribuidorId = getState().reportevisitas.filters.distribuidorId;

            if(distribuidorId > 0) {
                response.data.data = response.data.data.filter(f => f.distribuidorId === distribuidorId);
            }

            return response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldFilter('supervisorId', result[0].usuarioId));
                dispatch(onUpdateFieldFilter('dSupervisor', `${result[0].nombre}`));
                dispatch(onUpdateFieldFilter('vendedorId', ''));
                dispatch(onUpdateFieldFilter('dVendedor', ''));
            }
        }
    }));
};



export const onUpdateColumn = (column) => (dispatch, getState) => {
    getState().reporteidentificacionmercado.table.columns.map((item , i)=> {     
       if(item.dataKey !== column.dataKey) {
           return item;
       }else{
               item.width =  column.width;
       }
       return {
          // ...item,
         //  width: column.width
       };
      
   });
};

export const onUpdateFieldFilter = (key, value) => (dispatch, getState) => {
    dispatch({ type: UPDATE_FIELD_FILTER, key, value })
    //const  da = []

    dispatch( onUpdateFielddata());
   // dispatch({ type: RECEIVE_DATA, payload:[],  store:[] })
    dispatch({ type: RECEIVE_DATA, payload: [],storeData:[]});
    
}

export const onUpdateFielddata = (key, value) => (dispatch, getState) => {  

   // dispatch({ type: UPDATE_FIELD_DATA, key, value })

     //dispatch({ type: RECEIVE_DATA, columns: [], data: [] })
  //  dispatch({ type: RECEIVE_DATA, payload:[],  store:[] })
    //dispatch({ type: RECEIVE_DATA, payload: data, storeData });
    };


export const openDialogClient = (data) => (dispatch, getState) => {
    try {
        var storeData = getState().reportevisitas.data.filter(f => f.dVendedor === data.dVendedor);
        
        dispatch({ type: SHOW_POPUP_CLIENT, storeData });
    } catch (error) {
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'error', 
            message: error, 
            callback: () => dispatch(closeMessageBox())
        }));
    }
};

export const onUpdateDistFieldFilter = (dispatch, getState) => {
    dispatch({ type: UPDATE_DIST_FIELD_FILTER });
  };

export const setOrder = (order, orderBy) => ({ type: SET_ORDER, order, orderBy });

export const setSelectedRow = (selected) => ({ type: SET_SELECTED_ROW, selected});