import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { FieldSet, Table } from "@sigeco/tools";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  actualiza,
  detallePedido,
  setOrder,
  onUpdateColumn,
  btnApprove,
  HandleShows
} from "../../../store/movilidad/aprobarpedido/actions";
import { sort_by} from '../../../helpers/commons'
//import {openMessageBox} from '../../../store/main/actions'

import TextField from "@material-ui/core/TextField";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { formatoPeru} from "../../../helpers/commons";

const state = {
  eload: false,
  name: "",
  modalInputName: "",
};
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
  },
  tableWrap: {
    flex: "1 1 auto",
  },
  modal: {
    top: "50%",
    left: "50%",
    transform: 'translate(-50%, -50%)',
    position: "absolute",
    height: "100%",
    maxHeight: 500,
    maxWidth: 800,
    width: "80%",
    display: "block",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1,0, 1, 5),
    alignItems: "center",
    justifyContent: "center",
    fontSize: "max(1 vw, 5px)",
    borderRadius: "15px",
  },
  bot: {
    marginLeft: "10px",
    marginTop:"5px",
  },
   modalbody: {
    top: "10%",
    left: "10%",
    height: "100%",
    display: "block",
  },
  a:{
    maxwidth: "900px",
    width: "150px",
    fontSize: "15px",
  },
  b:{
    maxwidth: "900px",
    width: "250px",
    fontSize: "15px",
  },
  c:{
    width: "90px", 
    fontSize: "15px",
  },
  d:{
    width: "280px", fontSize: "15px", 
  },
   resul:{
    fontSize: "10px !important",
    background: "white",
    textAlign: "Left !important",
  },
  resuld:{
    fontSize: "10px !important",
    textAlign: "Right !important", 
    background: "white",
  },
  cuadrin:{
    background: "white", //width:"400%",
    marginLeft: "90px",
    fontSize: "max(1vw, 5px)",
    "@media (max-width: 866px)": {
      width: "80%",
      height:"17%",
      marginBottom:"30px",
      fontSize: "max(1vw, 2px)",
      left: "160%",
    },

    "@media (max-width: 780px)": {
      width: "50%",
      height:"17%",
      marginBottom:"30px",
      fontSize: "max(1vw, 5px)",
      right: "30%",
    },
    "@media (max-width: 769px)": {
      width: "100%",
      height:"17%",
      marginLeft: "-20px",
      overflowY: "hidden",
      marginBottom:"25px"
    },
    "@media (max-width: 768px)": {
      width: "100%",
      height:"18%",
      marginLeft: "-20px",
      overflowY: "hidden",
      marginBottom:"15px"
    },
    "@media (max-width: 510px)": {
      width: "50%",
      fontSize: "max(1vw, 5px)",
      right: "30%",
    },
    "@media (max-width: 507px)": {
      width: "-20%",
      height: "20%",
      fontSize: "max(1vw, 5px)",
      marginRight: "30%",
    },
    "@media (max-width: 506px)": {
      width: "-20%",
      height: "20%",
      fontSize: "max(1vw, 5px)",
      marginRight: "30%",
    },
    "@media (max-width: 505px)": {
      width: "-20%",
      height: "20%",
      fontSize: "max(1vw, 5px)",
      marginRight: "30%",
    },
    "@media (max-width: 453px-356px)": {
      width: "-20%",
      height: "10%",
      fontSize: "max(1vw, 5px)",
      marginRight: "30%",
    },
    "@media (min-width: 426px)": {
      width: "100%",
      marginLeft: "-20px",
      overflowY: "hidden",
      paddingBottom:"30px"
    },
    "@media (max-width: 425px)": {
      width: "100%",
      marginLeft: "-20px",
      overflowY: "hidden",
      height: "28%",
      marginBottom:"25px"
    },
    "@media (max-width: 375px)": {
      width: "100%",
      marginLeft: "-20px",
      overflowY: "hidden",
      marginBottom:"18px"
    },
    "@media (max-width: 336px)": {
      width: "100%",
      marginLeft: "-20px",
      overflowY: "hidden",
      //paddingBottom:"30px",
      marginBottom:"20px"
    },

  },
  cuadro: {
    height: "auto",
    paddingLeft: "20px",
    paddingRight: "30px",
    Size: "max(3vw, 5px)",
    width: "690px",
    marginTop: "-35px",
    "@media (max-width: 900px)": {
      width:"100%",
      height: "auto",
      marginLeft: "-40px"
    },
    "@media (max-width: 815px)": {
      width: "100%",
      fontSize: "max(1vw, 5px)",
    },
    "@media (max-width: 867px)": {
      width: "100%",
      height:"30%",
      fontSize: "max(1vw, 5px)",
      paddingLeft: "38px",
      paddingRight: "80px",
    },
    "@media (min-width: 900px)": {
      width:"100%",
      height: "auto",
      marginLeft: "-40px"
    },
    "@media (max-width: 768px)": {
      marginTop : "-25px",
      width: "90%",
      fontSize: "max(1vw, 5px)",
      paddingLeft:"10%",
      overflowX: "hidden",
    },
    "@media (max-width: 682px)": {
      width: "86%",
      fontSize: "max(1vw, 4px)",
      marginLeft: "-30px",
      marginTop : "-20px",
    },
    "@media (max-width: 597px)": {
      width: "86%",
      fontSize: "max(1vw, 5px)",
      marginLeft: "-30px",
      marginTop : "-20px",
      overflowX: "hidden",
    },
    "@media (max-width: 561px)": {
      width: "86%",
      fontSize: "max(1vw, 5px)",
      marginLeft: "-40px !important",
      marginTop : "-20px",
    },
    "@media (max-width: 473px)": {
      width: "90%",
      fontSize: "max(1vw, 5px)",
      marginLeft: "-40px !important",
    },
    "@media (max-width: 472px)": {
      width: "70%",
      marginLeft: "-20px !important",
      overflowX: "hidden",

    },
    "@media (max-width: 425px)": {
      width: "80%",
      marginLeft: "-30px !important",
      overflowX: "hidden",

    },
    "@media (max-width: 393px)": {
      width: "78%",
      marginLeft: "-60px",
      overflowX: "hidden",

   },
    "@media (max-width: 375px)": {
      overflowX: "hidden",
       width: "75%",
       marginLeft: "-37px !important",

    }, 
    "@media (max-width: 336px)": {
      width:"70%",
      overflowX: "hidden",
      marginLeft: "-48px !important",
    },
    "@media (max-width: 320px)": {
      overflowX: "hidden",
      width: "75%",
      marginLeft: "-48px !important",
    }
  },
  pp:{
    width:"800px",
  },
}));


const updateInputValue = (evt) => {
  state.modalInputName = evt.target.value;
};

function AprobarPedidoList(props) {
  const classes = useStyles();
  const [modalInfo, setModalInfo] = useState([]);
  const [, setShowModal] = useState(false);
  const [show, setShow] = useState(false);
  const HandleClose = () => setShow(false);
  const HandleShow = () => setShow(true);
  const [, setmotivoRechazo] = useState("");

  /*----------Estados que Abren los distitos Dialogs----------------------------------*/
  const [ropen, setrOpen] = useState(false);
  const [tip, setTip] = useState("P");

  /*----------Manipulación de oredenamiento de la Table-----------------*/
  const columns = useSelector((state) => state.aprobarpedido.table.columns);
  const loading = useSelector((state) => state.aprobarpedido.loading);
  const order = useSelector((state) => state.aprobarpedido.table.order);
  const orderBy = useSelector((state) => state.aprobarpedido.table.orderBy);
  const store = useSelector((state) => state.aprobarpedido.store);
  const show2 = useSelector((state) => state.aprobarpedido.show)
  const dispatch = useDispatch();
  const [rechazo, ] = useState();

  useEffect(()=>{
    setShow(show2)
  },[show2])
  
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    dispatch(setOrder(isAsc ? "desc" : "asc", property));
    let homes = store.data;
    homes.sort(sort_by(property, isAsc, homes[0][property]));
  };


  //---------Envia La Fila de la Tabla--
  const Clicke = (item) => {
    let items= []
    items.push(item)
    console.log(items)
    dispatch(detallePedido(items[0])); //mllama al detalle
    setModalInfo(items[0]);
    setShowModal(HandleShow);
    dispatch(HandleShows(true));
  };
  //--------Elasticidad de las columnas--
  const handleResizeColumn = (column) => {
    dispatch(onUpdateColumn(column));
  };

  const handleDialogOpen = (tipo) => () => {
    //abre dialogo
    setTip(tipo);
    if (tipo === "R") {
   
      state.modalInputName = "";
      setmotivoRechazo("");
      setrOpen(true);
    }
  };

  //--------Cierre de los Dialogs
  const handleClose = (tipo) => () => {
    //cierra dialog

    if (tipo === 4) {
      setrOpen(false);
    }
   
  };

  //-----Funcion que Despacha la acción y función estado que controla todo
  const handleSave = () => {
    if (state.modalInputName === "") {
      return;
    }
   
    setmotivoRechazo(state.modalInputName);

    dispatch(actualiza(modalInfo.PedidoId, tip, state.modalInputName)); //cambia estado
    setrOpen(false);
    HandleClose();
  };

  const ModalContent = () => {
    const datad = useSelector((state) => state.aprobarpedido.store.detalle);
    //console.log(datad, 'Data datad')
    let total = 0;
 
    return (
      <div >
        <Modal
          fullscreen={"xxl"}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={show}
          onClose={HandleClose}
        >
          <div style={{ display: 'flex', flex: '1', maxHeight: '370px' }}>

            <div className={classes.modal} style={{ height: (datad.length === 1 || datad.length === 2 || datad.length === 3) ? '290px' : (datad.length === 3 || datad.length === 4) ? '320px' : '340px' }}>
              <h2 style={{ textAlign: "center", fontSize: "22px" }}>
                Detalle de Pedido
              </h2>

              <div className={classes.cuadrin} >
                <table className={classes.pp}>
                  <tbody >
                    <tr>
                      
                      <td style={{ width: "85px", backgroundColor: "#BFD9FF", fontSize: "10px", fontWeight: "bold" }} >CODIGO PEDIDO:</td>
                      <td colSpan="2" style={{ width: "85px", fontSize: "10px" }}> <b>{modalInfo.Pedido} </b></td>
                      <td style={{ width: "85px", backgroundColor: "#BFD9FF", fontSize: "10px", fontWeight: "bold" }} >NIT:</td>
                      <td colSpan="2" style={{ width: "85px", fontSize: "10px" }}> <b>{modalInfo['NIT/RUC']} </b></td>
                      <td style={{ width: "15px", backgroundColor: "#BFD9FF", fontSize: "10px", fontWeight: "bold" }}>ESTADO:</td>
                      <td style={{ width: "60px", backgroundColor: "lightcyan", fontSize: "10px" }}>{modalInfo.Estado === 'P' ? 'PENDIENTE' : modalInfo.Estado === 'R'
                        ? 'RECHAZADO' : modalInfo.Estado === 'A' ? 'APROBADO' : 'REVISADO'}</td>
                      <td style={{ width: "50px", backgroundColor: "#BFD9FF", fontSize: "10px", fontWeight: "bold" }}>FECHA:</td>
                      <td style={{ fontSize: "10px", width: "65px" }}>{modalInfo.Fecha}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "85px", backgroundColor: "#BFD9FF", fontSize: "10px", fontWeight: "bold" }}> CLIENTE:</td>
                      <td colSpan="3" style={{ width: "185px", fontSize: "10px" }}>{modalInfo.Cliente}</td>
                      <td style={{ width: "35px", backgroundColor: "#BFD9FF", fontSize: "10px", fontWeight: "bold" }}>VENDEDOR:</td>
                      <td colSpan="5" style={{ width: "85px", fontSize: "10px" }}>{modalInfo.Nombre}</td>
                    </tr>

                    <tr>
                      <td style={{ width: "85px", backgroundColor: "#BFD9FF", fontSize: "10px", fontWeight: "bold" }}> OBSERVACION:</td>
                      <td colSpan="9" style={{ width: "85px", fontSize: "10px" }}><b> {modalInfo.Observacion} </b></td>

                    </tr>
                  </tbody>
                </table>
              </div>
              <br></br>
              <div className={classes.cuadro} style={{ marginBottom: '100px' }}>
                <div style={{ display: "none", border: 'none' }}>
                  {!loading &&
                    datad.map(
                      (ite) => (total = total + ite.cantidad * ite.precioFinal)
                    )}
                </div>
                <div className={classes.cuadron} style={{ width: '100%', height: datad.length >= 7 ? "145px " : "180px", overflowX: datad.length >= 4 ? "auto" : "hidden", marginTop: "-10px" }}>
                  <table
                    className="aprobacion"
                    id="tableMod"
                    style={{ borderCollapse: "collapse" }}
                  >
                    <thead >
                      <tr>
                        <th className={classes.a} >
                          Código
                        </th>
                        <th className={classes.b}>
                          Descripción
                        </th>
                        <th className={classes.c}>
                          Cantidad
                        </th>
                        <th className={classes.d}>
                          Precio
                        </th>
                      </tr>
                    </thead>
                    <tbody style={{ border: "1px solid #BFD2E9" }}>
                      {!loading &&
                        datad.map((item, index) => (
                          <tr
                            style={{ backgroundColor: "Gainsboro" }}
                            key={index}
                          >
                            <td className={classes.resul}>
                              {item.codigoProducto}
                            </td>
                            <td className={classes.resul}>
                              {item.dProducto}
                            </td>
                            <td className={classes.resuld}>
                              {item.cantidad}
                            </td>
                            <td className={classes.resuld}>
                              {formatoPeru('en-US', 'USD', item.precioFinal)}
                            </td>
                          </tr>
                        ))}
                      {datad.length > 0 && !loading && (
                        <tr key={1000} style={{ backgroundColor: "white", border: 'none' }} >
                          <td className={classes.resuld} colSpan="5"  >
                            <b style={{ fontSize: "11px" }}>Total: {formatoPeru('en-US', 'USD', total)}</b>
                          </td>
                        </tr>
                      )}
                      {datad.length === 0 && (
                        <tr key={1}>   {/*style={{backgroundColor: 'Gainsboro'}}*/}
                          <td className={classes.resul} colSpan="5"  >No existe Registro</td> {/*style={{fontSize:'12px', background:'white'}} */}
                        </tr>
                      )}
                    </tbody>
                    {loading && (
                      <tbody>
                        <tr >
                          <td colSpan="4">
                            <img
                              alt="log"
                              src="https://app.iess.gob.ec/iess-fondo-reserva-afiliado-web/javax.faces.resource/images/load.gif.jsf;jsessionid=8949b76cfea43d283add3bd3dd2d?ln=sentinel-layout"
                              width="40"
                              height="40"
                            />
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>

                </div>
              </div>
              <br></br>
              <br></br>
              <br></br>
              <br></br>

              <div style={{ backgroundcolor: 'red',
                         position: 'absolute',
                         bottom: 5,
                         width: '100%',
                         height: '40px',
                         color: 'white' }}>
                {!loading && modalInfo.Estado === "P" ? (
                    <div className="container"   style= {{ position: 'relative', float: 'right',  liststyletype: 'none',  right: '8%', display:'inline-block'}}>

                    <Button
                      variant="contained"
                      onClick={() =>{dispatch(btnApprove())}}
                      className={classes.bot}
                      color="primary"
                    >
                      Aprobar
                    </Button>
                    <Button
                      variant="contained"
                      style={{ color: "white" }}
                      onClick={handleDialogOpen("R")}
                      className={classes.bot}
                      color="primary"
                    >
                      Rechazar
                    </Button>
                    <Button
                      variant="contained"
                      style={{ color: "white" }}
                      onClick={() => dispatch(HandleShows(false))}
                      className={classes.bot}
                      color="primary"
                    >
                      Cerrar
                    </Button>
                    
                  </div>
                ):(
                  <div className="container"   style= {{ position: 'relative', float: 'right',  liststyletype: 'none',  right: '8%', display:'inline-block'}}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={HandleClose}
                      className={classes.bot}
                    >
                      Cerrar
                    </Button>

                  </div>
                )}
              
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }; /* modal content */

   const Rechazo = () => {
    // ESTE ES EL MODAL DE RECHAZO DE PEDIDO
    return (
      <Dialog
        open={ropen}
        fullWidth
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Rechazar</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Motivo rechazo"
            multiline
            fullWidth
            value={rechazo}
            onChange={(evt) => updateInputValue(evt)}
            inputProps={{
              maxLength: 200,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={handleClose(4)}>
            Cancelar
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={(e) => handleSave(e)}
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }; /* dialog Rechazo */

  return (
    <FieldSet
      className={classes.root}
      iconCls="fas fa-th-list"
      title="Resultados"
    >
      <div style={{ display: "none" }}>
        <ReactHTMLTableToExcel
          id="table-xls-button-rpt-pedidos"
          className=""
          table="table-to-xls"
          filename="Rpt_pedidos"
          sheet="Pedidos"
          buttonText=""
        />
      </div>

      <div className={classes.tableWrap}>
        <Table
          columns={columns}
          count={store.total}
          loading={loading}
          onRowClick={(items) => {}}
         onRowDoubleClick={(items) => Clicke(items)}
          onSort={(datakey) => handleRequestSort(datakey)}
          onUpdateColumn={handleResizeColumn}
          rowCount={store.data.length}
          rowGetter={(index) => store.data[index]}
          selected={store.selected}
          sortBy={orderBy}
          sortDirection={order}
          noRowsRenderer={() => <div></div>}
        />
      </div>

      <div className="App">{show ? <ModalContent /> : null}</div>
      <div>{<Rechazo />}</div>
    </FieldSet>
  );
}

export default AprobarPedidoList;

