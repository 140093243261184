import {
    FILTER_MAP_VENDEDOR,
    HIDE_POPUP_CLIENT,
    RECEIVE_DATA,
    RECEIVE_V_LIST,
    RECEIVE_OLAS_LIST,
    SET_MASK,
    SET_ORDER,
    SET_SELECTED_ROW,
    SHOW_POPUP_CLIENT,
    UPDATE_FIELD_FILTER,
    UPDATE_TABLE_COLUMN,
    UPDATE_DIST_FIELD_FILTER
} from './types';

const initialState = {
    loading: false,
    data: [],
    dVendedor: '',
    openPopup: false,
    filters: {
        distribuidorId: 0,
        supervisorId: '',
        ubigeoId: 0,
        marcaidentificacionId: 0,
        tipoId:0,
        gondola:99,
        dGondola:'',
        nombre:'',
        departamentoId:'00',
        provinciaId:'00',
        distritoId:'00',
        dProvincia:'',
        dDistrito:'',
        dTipo:'',
        dMarcaIdentificacion:'',
        clienteId:0,
        dCliente:'',
        dDepartamento: '',
        vendedorId: '',
        desde: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        hasta: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        dDistribuidor: '',
        dSupervisor: '',
        dVendedor: '',
        dUbigeo: '',
        UriFoto01: '',
        UriFoto02: '',
        UriFoto03: '',
        sw: false
    },
    store: {
        loading: false,
        data: [],
        selected: [],
    },
    storePed: {
        data: [],
        selected: [],
    },
    storeClient: {
        data: [],
        selected: []
    },
    table: {
        order: 'asc',
        orderBy: '',
        columns: [
            { dataKey: 'Fecha', label: 'Fecha', width: 150, textAlign: 'right', format: 'yyyy-MM-dd', type:'date' },
            { dataKey: 'Nit', label: 'RUC', width: 100 },
            { dataKey: 'NombreComercial', label: 'Cliente', width: 300 , textAlign: 'right'},       
            //{ dataKey: 'Direccion', label: 'Dirección', width: 150, textAlign: 'right' },
            //{ dataKey: 'Segmento', label: 'Segmento', width: 150, textAlign: 'right' },
            { dataKey: 'Fachada', label: 'Fachada', width: 150, textAlign: 'right' },
            { dataKey: 'Marca1', label: 'Pared 1', width: 150 },
            { dataKey: 'Marca2', label: 'Pared 2', width: 150 },
            { dataKey: 'Marca3', label: 'Pared 3', width: 150},
            { dataKey: 'Gondola', label: 'Gondola', width: 100},
            { dataKey: 'Observacion', label: 'Observación', width: 250 },
            /*
            { dataKey: 'Departamento', label: 'Departamento', width: 150 },
            { dataKey: 'Provincia', label: 'Provincia', width: 150 },
            { dataKey: 'Distrito', label: 'Distrito', width: 150 },
            { dataKey: 'Vendedor', label: 'Vendedor', width: 150 },
            { dataKey: 'Dsitribuidor', label: 'Dsitribuidor', width: 150 }
            */
        ]
    },
    tableClient: {
        order: 'asc',
        orderBy: '',
        columns: [
            { dataKey: 'dClienteSucursal', label: 'Punto de Venta', width: 350 },
            { dataKey: 'montoPedido', label: 'Monto Pedido', type: 'number', width: 100 },
            { dataKey: 'Ejecutado', label: 'Visita', type: 'boolean', width: 75 },
            { dataKey: 'conPedido', label: 'Fuera de Ruta', type: 'boolean', width: 175, backcolor: 'black' },
            { dataKey: 'motivoVisita', label: 'Motivo', width: 235 },
            { dataKey: 'motivoNoCompra', label: 'M. No Compra', width: 235 },
            { dataKey: 'EsProspecto', label: 'Prospecto', type: 'boolean', width: 75 },
            { dataKey: 'channelMap', label: 'Channel', type: 'boolean', width: 75 },
            { dataKey: 'fechaVisita', label: 'Fecha', width: 90 },
            { dataKey: 'horaVisita', label: 'Hora', width: 90 },  
            
        ]
    },

};

export const reporteidentificacionmercadoReducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case FILTER_MAP_VENDEDOR:
            return {
                ...state,
                dVendedor: action.dVendedor || ''
            };
        case HIDE_POPUP_CLIENT:
            return {
                ...state,
                openPopup: false
            };
        case RECEIVE_DATA:
            return {
                ...state,
                data: action.payload,
                store: {
                    ...state.store,
                    data: action.storeData
                }
            };
            case RECEIVE_V_LIST:
                return {
                    ...state,
                    store: {
                        ...state.store,
                        loading: false,
                        total: action.total,
                        data: action.payload,
                        source: null
                    }
                    // ,
                    // table: {
                    //     ...state.table,
                    //     columns: action.columns,
                    // }
                };    

            case RECEIVE_OLAS_LIST:

                return {
                    ...state,
                    data: action.pay,
                    storePed: {
                        ...state.store,
                        data: action.storePed
                    }
                };
        case SET_MASK:
            return {
                ...state,
                loading: action.loading
            };
        case SET_ORDER:
            return {
                ...state,
                table: {
                    ...state.table,
                    order: action.order,
                    orderBy: action.orderBy
                }
            };
        case SET_SELECTED_ROW:
            return {
                ...state,
                store: {
                    ...state.store,
                    selected: action.selected
                }
            };
        case UPDATE_FIELD_FILTER:
            return { 
                ...state,
                filters: {
                    ...state.filters,
                    [action.key]: action.value
                }
            };
        case UPDATE_DIST_FIELD_FILTER:
            return {
                ...state,
                filters: {
                ...state.filters,
                dMarcaIdentificacion:"",
                },
            };

        case SHOW_POPUP_CLIENT:
            return {
                ...state,
                openPopup: true,
                storeClient: {
                    ...state.store,
                    data: action.storeData
                }
            };
        case UPDATE_TABLE_COLUMN:
            return {
                ...state,
                table: {
                    ...state.table,
                    columns: action.columns
                }
            };
        default:
            return state;
    }
}