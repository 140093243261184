import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_material from "@amcharts/amcharts4/themes/material";

//import Grid from "@material-ui/core/Grid";
import makeStyles from '@material-ui/core/styles/makeStyles';
import { aligSubTitle,  sizeSubTitle } from '../../../config';


am4core.useTheme(am4themes_material);
am4core.useTheme(am4themes_animated);
const useStyles = makeStyles(() => ({
  root: {
      display: 'flex',
      flex: '1 1 auto',
      flexDirection: 'row',
      padding: "1.5rem ",

  }, 
  wrap: {
      flex: '1 1 auto'
  }
}));
const ReporteConstruccionDash = () => {
  const classes = useStyles();

  const data = useSelector((state) => state.reporteconstruccion.data);
  const summary = useSelector((state) => state.reporteconstruccion.summary);
  const agente = useSelector(state => state.reporteconstruccion.filters.dDistribuidor);
  const ola = useSelector(state => state.reporteconstruccion.filters.dOla);

  const [, setPresup] = useState([]);

  useEffect(() => {
    if (data.length === 0) {return; }

    if (summary.cuentaPDV === 0 && summary.mercadoPDV === 0) {return; }

    setPresup(summary);

    var container = am4core.create("chartdiv", am4core.Container);
    //var chart = am4core.create("chartdiv2", am4charts.PieChart);
    container.width = am4core.percent(100);
    container.height = am4core.percent(100);
    container.layout = "vertical";
    container.marginBottom = 300;

    //var chart = am4core.create("chartdiv2", am4charts.PieChart);
    // var chart3 = container.createChild(am4charts.PieChart);
    // chart3.height = 30;
    // chart3.marginBottom = 10;
    // chart3.Right = 100; 

    

    // var titulo = chart3.titles.create();
    // if(agente.length >0)
    // {
    //   titulo.text = "Dashboard /Agente: " + agente + ' - Ola: ' + ola
    // }
    // else 
    // {
    //   titulo.text = "Dashboard /Agente: Todos - Ola: " + ola;
    // }
    // titulo.fontWeight = 600;
    // titulo.fontSize = 22;
    // titulo.align = "center";
    // titulo.marginRight = 700;
    // titulo.marginBottom = 30;
    

    if (data.length > 0) {
      container.exporting.menu = new am4core.ExportMenu();
      container.exporting.menu.align = "left";
      container.exporting.menu.verticalAlign = "top";
      container.exporting.menu.items = [
        {
          label: "...",
          menu: [
            {
              label: "Imagen",
              menu: [
                { type: "png", label: "PNG" },
                { type: "jpg", label: "JPG" },
                { type: "svg", label: "SVG" },
                { type: "pdf", label: "PDF" },
              ],
            },
            {
              label: "Imprimir",
              type: "print",
            },
          ],
        },
      ];
    }

   
    // Themes begin

    createChart(container, "COBERTURA", summary);

    return () => {
      container.dispose();
    };
    // eslint-disable-next-line
  }, [data, summary]);

  const createChart = (container, titleText, x) => {
    am4core.useTheme(am4themes_animated);
    // Themes end

    // create chart
    var chart = container.createChild(am4charts.GaugeChart);
    var title = chart.titles.create();

    var titulo1 = chart.titles.create();
    if(agente.length >0)
    {
      titulo1.text = "Dashboard   /";
    }
    else 
    {
      titulo1.text = "Dashboard   /"; 
    }
    titulo1.fontWeight = 600;
    titulo1.fontSize = 22;
    titulo1.align = "center";
    titulo1.marginRight = 750;
    titulo1.marginTop = -15;

    title.text =  "Cobertura: " +  parseFloat((summary.cuentaPDV * 100) / summary.mercadoPDV).toFixed(2) + " %"; //"Puntos Mercado:  " + Math.round(summary.mercadoPDV).toString() + " PDV";
    title.fontWeight = 600;
    title.fontSize = sizeSubTitle;
    title.align =  aligSubTitle;
    title.marginTop = 20 ;
    title.paddingLeft = -290;
    title.marginBottom = 20;

    chart.width = 480;

    chart.hiddenState.properties.opacity = 0;

    var axis = chart.xAxes.push(new am4charts.ValueAxis());
    axis.min = 0;
    axis.max = parseFloat(summary.cuentaPDV); //summary.mercadoPDV;   //e
    axis.strictMinMax = true;
    axis.renderer.inside = true;
    axis.renderer.radius = am4core.percent(97);
    axis.renderer.line.strokeOpacity = 1;
    axis.renderer.line.strokeWidth = 5;
    axis.renderer.line.stroke = chart.colors.getIndex(0);
    axis.renderer.ticks.template.disabled = false;
    axis.renderer.ticks.template.stroke = chart.colors.getIndex(0);
    axis.renderer.labels.template.radius = 35;
    axis.renderer.ticks.template.strokeOpacity = 1;
    axis.renderer.grid.template.disabled = true;
    axis.renderer.ticks.template.length = 10;
    axis.hiddenState.properties.opacity = 1;
    axis.hiddenState.properties.visible = true;
    axis.setStateOnChildren = true;
    axis.renderer.hiddenState.properties.endAngle = 180;

    var axis2 = chart.xAxes.push(new am4charts.ValueAxis());
    axis2.min = 0;
    axis2.max = summary.mercadoPDV.length === 1 ? summary.mercadoPDV+ 5 : summary.mercadoPDV.length === 2 ?  summary.mercadoPDV+ 10 : summary.mercadoPDV+ 10  ; //e ma xvalue
    axis2.strictMinMax = true;

    axis2.renderer.line.strokeOpacity = 1;
    axis2.renderer.line.strokeWidth = 5;
    axis2.renderer.line.stroke = chart.colors.getIndex(3);
    axis2.renderer.ticks.template.stroke = chart.colors.getIndex(3);

    axis2.renderer.ticks.template.disabled = false;
    axis2.renderer.ticks.template.strokeOpacity = 1;
    axis2.renderer.grid.template.disabled = true;
    axis2.renderer.ticks.template.length = 10;
    axis2.hiddenState.properties.opacity = 1;
    axis2.hiddenState.properties.visible = true;
    axis2.setStateOnChildren = true;
    axis2.renderer.hiddenState.properties.endAngle = 180;

    var hand = chart.hands.push(new am4charts.ClockHand());
    hand.fill = axis.renderer.line.stroke;
    hand.stroke = axis.renderer.line.stroke;
    hand.axis = axis;
    hand.pin.radius = 14;
    hand.startWidth = 8;

    var hand2 = chart.hands.push(new am4charts.ClockHand());
    hand2.fill = axis2.renderer.line.stroke;
    hand2.stroke = axis2.renderer.line.stroke;
    hand2.axis = axis2;
    hand2.pin.radius = 10;
    hand2.startWidth = 10;
 
    var legend = new am4charts.Legend();
    legend.isMeasured = false;
    legend.y = am4core.percent(100);
    legend.verticalCenter = "bottom";
    legend.parent = chart.chartContainer;

    legend.data = [
      {
        name: "Pdv Actual",
        fill: chart.colors.getIndex(0),
      },
      {
        name: "Pdv Mercado",
        fill: chart.colors.getIndex(3),
      },
    ];
    legend.paddingBottom = -25

    legend.itemContainers.template.events.on("hit", function (ev) {
      var index = ev.target.dataItem.index;

      if (!ev.target.isActive) {
        chart.hands.getIndex(index).hide();
        chart.xAxes.getIndex(index).hide();
        labelList.getIndex(index).hide();
      } else {
        chart.hands.getIndex(index).show();
        chart.xAxes.getIndex(index).show();
        labelList.getIndex(index).show();
      }
    });

    var labelList = new am4core.ListTemplate(new am4core.Label());
    labelList.template.isMeasured = false;
    labelList.template.background.strokeWidth = 2;
    labelList.template.fontSize = 18;
    labelList.template.padding(10, 20, 10, 20);
    labelList.template.y = am4core.percent(50);
    labelList.template.horizontalCenter = "middle";

    var label = labelList.create();
    label.parent = chart.chartContainer;
    label.x = am4core.percent(40);
    label.background.stroke = chart.colors.getIndex(0);
    label.fill = chart.colors.getIndex(0);
    label.text = "0";

    var label2 = labelList.create();
    label2.parent = chart.chartContainer;
    label2.x = am4core.percent(60);
    label2.background.stroke = chart.colors.getIndex(3);
    label2.fill = chart.colors.getIndex(3);
    label2.text = "0";

    hand.showValue(summary.cuentaPDV);
    label.text = Math.round(hand.value).toString() + "P";
    hand2.showValue(parseFloat( summary.mercadoPDV)); //hand2.showValue(parseFloat((summary.cuentaPDV * 100) / summary.mercadoPDV));
    label2.text = Math.round(hand2.value).toString() + "PM";

    /******************************************************************************************************************* */
    // Create chart instance
    var chart2 = am4core.create("chartdiv2", am4charts.PieChart);
    
    var title2 = chart2.titles.create();
    title2.text =  "Participación :  " + Math.round((summary.glnpdv * 100) / summary.glnpdvmercado).toString()+ " %";
    title2.fontWeight = 600;
    // title2.fontSize = 18;
    // title2.align = "right";
    // title2.marginTop = 10;
    //title2.fontWeight = 600;
    title2.fontSize = sizeSubTitle;
    title2.align =  aligSubTitle;
    title2.marginTop = 20 ;



   // titulo.marginBottom = -3;
   console.log(summary)

    // Add data
    chart2.data = [
      { sector: "Gal Potenciales", size: (summary.glnpdvmercado - summary.glnpdv)},
      { sector: "Gal Actual", size: summary.glnpdv },
    ];
    if (data.length > 0) {
      chart2.exporting.menu = new am4core.ExportMenu();
      chart2.exporting.menu.align = "rigth";
      chart2.exporting.menu.verticalAlign = "top";
      chart2.exporting.menu.items = [
        {
          label: "...",
          menu: [
            {
              label: "Imagen",
              menu: [
                { type: "png", label: "PNG" },
                { type: "jpg", label: "JPG" },
                { type: "svg", label: "SVG" },
                { type: "pdf", label: "PDF" },
              ],
            },
            {
              label: "Imprimir",
              type: "print",
            },
          ],
        },
      ];
    }

    
    var titulo = chart2.titles.create();
    if(agente.length >0)
    {
      titulo.text = "/Agente: " + agente + ' - Ola: ' + ola
    }
    else 
    {
      titulo.text = "/Agente: Todos - Ola: " + ola;
    }

    titulo.fontWeight = 600;
    titulo.fontSize = 22;
    titulo.align = "center";
    titulo.paddingLeft = -280;
    titulo.marginBottom = 10;

    // Add label
    chart2.innerRadius = 110;
    var label3 = chart2.seriesContainer.createChild(am4core.Label);
    label3.text =
      summary.glnpdv === 0
        ? 0.0 + "%"
        : Math.round(
            (summary.glnpdv * 100) / summary.glnpdvmercado
          ).toString() + "% ";
    label3.horizontalCenter = "middle";
    label3.verticalCenter = "middle";
    label3.fontSize = 40;

    // Add and configure Series
    var pieSeries = chart2.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "size";
    pieSeries.dataFields.category = "sector";
    pieSeries.colors.list = [
      new am4core.color("#0288D1"),
      new am4core.color("#8E24AA"),
      new am4core.color("#388E3C"),
    ];

    pieSeries.legend = new am4charts.Legend();
    pieSeries.legend.position = "right";
    pieSeries.legend.align = "right";
    pieSeries.legend.contentAlign = "right";
    pieSeries.legend.fontSize = 11;
    pieSeries.legend.maxWidth = 200;

  };


  return (
    <>
      {/* <Grid container spacing={2}>
        <>
          <Grid item xs={6} md={6}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0.5rem 1.5rem",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  marginTop: "30px",
                  width: 450,
                  display: "flex",
                  justifyContent: "right",
                  height: 20, fontSize: '1.3rem', fontWeight: 600

                }}
              >
                Puntos Mercado :{Math.round(summary.mercadoPDV).toString() + " PDV"}
              </div>
            </div>
            <div style={{ height: 300 }} id="chartdiv" />
          </Grid>

          <Grid item xs={6} md={6}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0.5rem 1.5rem",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  width: 500,
                  justifyContent: "right",
                  height: 40, fontSize: '1.3rem', fontWeight: 600
                }}
              >
                
                Participación :{Math.round((summary.glnpdv * 100) / summary.glnpdvmercado).toString() + "%"}
              </div>
            </div>
            <div style={{ height: 300 }} id="chartdiv2"></div>
          </Grid>
        </>
      </Grid> */}
      <div className={classes.root}>

        <div style={{ height: 330 , width: 480}} id="chartdiv"  className={classes.wrap}/>

        <div style={{ height: 330 , width: 510}} id="chartdiv2" className={classes.wrap}/> 
      </div>
    </>
  );
};

export default ReporteConstruccionDash;
