import {
  ERROR_OLAS_LIST,
  RECEIVE_OLAS_LIST,
  REQUEST_OLAS_LIST,
  RESTART_STATE,
  SET_MASK,
  SET_ORDER,
  SET_SELECTED_ROW,
  UPDATE_FIELD_FILTER,
  RECEIVE_OLAS_DET,
  UPDATE_FIELD_ENTITYS,
  HANDLE_SHOW
  //UPDATE_TABLE_COLUMN
} from "./types";

const initialState = {
  loading: false,
  edo: "",
  motivoRechazo: "",
  show: false,
  filters: {
    dVendedor: "",
    desde: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    hasta: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    ),
    vendedorId: "",
    estado: "T",
    distribuidorId: 0,
    dDistribuidor: "",
  },
  store: {
    loading: false,
    data: [],
    selected: [],
    detalle: [],
  },
  table: {
    order: "asc",
    orderBy: "",
    columns: [],
  },
};

export const aprobarpedidoReducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case ERROR_OLAS_LIST:
      return {
        ...state,
        store: {
          ...state.store,
          loading: false,
        },
      };
    case RECEIVE_OLAS_LIST:
      return {
        ...state,
        store: {
          ...state.store,
          loading: false,
          data: action.payload,
          selected: [],
        },
        table: {
          ...state.table,
          columns: action.columns,
        },
      };
    case RECEIVE_OLAS_DET:
      return {
        ...state,
        store: {
          ...state.store,
          loading: false,
          detalle: action.payload,
        },
      };
    case REQUEST_OLAS_LIST:
      return {
        ...state,
        store: {
          ...state.store,
          loading: true,
        },
      };

    case RESTART_STATE:
      return {
        ...initialState,
      };
    case SET_MASK:
      return {
        ...state,
        loading: action.loading,
      };
    case SET_ORDER:
      return {
        ...state,
        table: {
          ...state.table,
          order: action.order,
          orderBy: action.orderBy,
        },
      };
    case SET_SELECTED_ROW:
      return {
        ...state,
        store: {
          ...state.store,
          selected: action.selected,
        },
      };
    case UPDATE_FIELD_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.key]: action.value,
        },
      };
    case UPDATE_FIELD_ENTITYS:
      return {
        ...state,
        motivoRechazo: action.value,
      };
    case HANDLE_SHOW:
      return {
        ...state,
        show : action.payload,
      };
    default:
      return state;
  }
};
