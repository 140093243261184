import React, { useState } from 'react';
import {  useSelector } from 'react-redux';

import Icon from '@material-ui/core/Icon';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import CostosProductosList from './CostosProductosList';
import CostosProductosImportar from './CostosProductosImportar';

const useStyles = makeStyles(theme => ({
    paper: {
        flex: 1,
        
    },
    tabsHorizontal: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        minWidth: 380, // a number of your choice
        width: 380, // a number of your choice
    },
    tabsVertical: {
        borderRight: `1px solid ${theme.palette.divider}`,
        minWidth: 180, // a number of your choice
        width: 180, // a number of your choice
    },
    root: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        overflowX: 'auto '
    },
    a: {
        position: 'sticky',
        top: '0px',
    }
}));

const CostoProductosPanel = () => {
    const classes = useStyles();
    const big = useMediaQuery('(min-width:1201px)');
    const store = useSelector(state => state.costosproductos.filters);
    
   
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        
        setValue(newValue);

    };

// ilegalidad
    if(value === 0)
    {
        store.band = true
    }
    else {
        store.band = false
    }

   

    return (
        
        <Paper className={classes.paper}>
             <div style={{ display: 'flex', flexDirection: big ? "row" : "column", height: '100%' }} > {/*, overflowX: "initial !important" */}
                <Tabs
                    orientation={big ? "vertical" : "horizontal"}
                    variant="fullWidth"
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="icon label tabs example"
                    className={big ? classes.tabsVertical : classes.tabsHorizontal}
                >
                    <Tab icon={<Icon className="fas fa-barcode" />} label="Resumen" style={{ textTransform: "none",  backgroundColor: value === 0 ? "#C9C9C9" : "", width:'100%' }} />
                    <Tab icon={<Icon className="fas fa-project-diagram" />} label="Cargar Productos" style={{ textTransform: "none",  backgroundColor: value === 1 ? "#C9C9C9" : "" }}/>
   
               </Tabs>
                 {value === 0  && (<CostosProductosList /> )}
                 {value === 1  && (<CostosProductosImportar />)}
            </div>
        </Paper>
        
    );
}

export default CostoProductosPanel;