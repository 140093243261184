import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NativeSelect from "@material-ui/core/NativeSelect";
import {
  onSearchVendedorClick,
  onUpdateFieldFilter,
  onSearchDistribuidorClick,
} from "../../../store/movilidad/aprobarpedido/actions";

import { ActionField, FieldSet } from "@sigeco/tools";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import { Icon } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import {getDistribuidorDefault} from '../../../helpers/commons';

const useStyles = makeStyles(() => ({
  content: {
    padding: "0.5rem 0.75rem 0.75rem",
  },
  filterClear: {
    display: "flex",
  },
  actionField: {
    flexDirection: "row",
    position: "relative",
  },
  btnClear: {
    position: "absolute",
    bottom: "1.5",
    right:20,
    maxWidth: "15px",
    maxHeight: "23px",
    minWidth: "15px",
    minHeight: "23px",
  },
  root: {
    flex: "0 0 auto",
    marginBottom: "0.75rem",
    "@media (min-width: 1401px)": {
      marginBottom: "1rem",
    },
  },
  mime: {
    fontSize: 8,
    "& .MuiFormLabel-root": {
      color: "red",
    },
  },
}));

const AprobarPedidoFilter = () => {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:701px)");
  const filters = useSelector((state) => state.aprobarpedido.filters);
  const dispatch = useDispatch();
  const [, setEstado] = useState("");

  useEffect(() =>{

    getDistribuidorDefault(onUpdateFieldFilter,dispatch);

  },[])

 //Botones de limpiar filtros
 const [, setDistribuidor] = useState("");
 const [btn, setBtn] = useState(true);
 const [, setVendedor] = useState("");
 const [btn1, setBtn1] = useState(true);

 useEffect(() => {
   if (filters.dDistribuidor) {
     setDistribuidor(filters.dDistribuidor);
     setBtn(false);
   } else {
     setBtn(true);
   }
 }, [filters.dDistribuidor]);

 useEffect(() => {
   if (filters.dVendedor) {
     setVendedor(filters.dVendedor);
     setBtn1(false);
   } else {
     setBtn1(true);
   }
 }, [filters.dVendedor]);

 const handleDist = () => {
   setDistribuidor("");
   setVendedor("");
   filters.distribuidorId = 0;
   filters.dDistribuidor = "";
   filters.vendedorId = '';
    filters.dVendedor = "";
   setBtn(true);
   setBtn1(true);
 };

 const handleVendedor = () => {
   setVendedor("");
   filters.vendedorId = '';
    filters.dVendedor = "";
   setBtn1(true);
 };


  function onChange(event) {
    setEstado(event.target.value);
    dispatch(onUpdateFieldFilter("estado", event.target.value));
  }
  const handleEstado = (e) => {
    setEstado(e);
  };
  return (
    <div className={classes.root}>
      <FieldSet
        iconCls="fas fa-filter"
        title="Filtros"
        defaultExpanded
        expandable
      >
        <Grid className={classes.content} container spacing={2}>
          <Grid item xs={12} container>
            <Grid item xs={matches ? 4 : 12}>
            <FormControl className={classes.filterClear}>
                <ActionField
                  className={classes.actionField}
                label="Agente"
                placeholder="Enter para buscar Agente"
                value={filters.dDistribuidor}
                variant={matches ? "outlined" : "standard"}
                onHandleAction={() => dispatch(onSearchDistribuidorClick())}
              />
              <Button
                  className={classes.btnClear}
                  size="small"
                  startIcon={<Icon className="fa fa-window-close" />}
                  disabled={btn}
                  onClick={handleDist}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={matches ? 4 : 12}>
              <FormControl className={classes.filterClear}>
                <ActionField
                  label="Vendedor"
                  placeholder="Enter para buscar Vendedor"
                  value={filters.dVendedor}
                  variant={matches ? "outlined" : "standard"}
                  onHandleAction={() => dispatch(onSearchVendedorClick())}
                />
                <Button
                  className={classes.btnClear}
                  size="small"
                  startIcon={<Icon className="fa fa-window-close" />}
                  disabled={btn1}
                  onClick={handleVendedor}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container item xs={8} spacing={1}>
            <Grid item>
              <KeyboardDatePicker
                autoOk
                disableToolbar
                format="dd/MM/yyyy"
                label="Desde"
                value={filters.desde}
                inputvariant={matches ? "outlined" : "standard"}
                variant={matches ? "inline" : "dialog"}
                onChange={(date) =>
                  dispatch(onUpdateFieldFilter("desde", date))
                }
                disableFuture
              />
            </Grid >
            <Grid item >
              <KeyboardDatePicker
                autoOk
                disableToolbar
                format="dd/MM/yyyy"
                label="Hasta"
                value={filters.hasta}
                inputvariant={matches ? "outlined" : "standard"}
                variant={matches ? "inline" : "dialog"}
                onChange={(date) =>
                  dispatch(onUpdateFieldFilter("hasta", date))
                }
                disableFuture
              />
            </Grid>
            <Grid item xs={matches ? 6 : 12}>
              <Box
                inputvariant={matches ? "outlined" : "standard"}
                component="div"
                fontSize={8}
                color="grey"
                style={{ marginLeft: "0%", marginTop: "-10px", width: "30%" }}
              >
              </Box>
              <div style={{ paddingLeft: "4px ", marginTop: "21px",width: "24%" , borderRadius: '5px'}}>
                <NativeSelect id="lang" onChange={onChange} style={{width: "100%", borderStyle:'none'}}>
                  <option value="T">Todos</option>
                  <option value="P" onClick={(e) => handleEstado(e.target.value)}>Pendiente</option>
                  <option value="A">Aprobado</option>
                  <option value="R">Rechazado</option>
                </NativeSelect>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </FieldSet>
    </div>
  );
};
export default AprobarPedidoFilter;
