import {
    FILTER_MAP_VENDEDOR,
    HIDE_POPUP_CLIENT,
    RECEIVE_DATA,
    SET_MASK,
    SET_ORDER,
    SET_SELECTED_ROW,
    SHOW_POPUP_CLIENT,
    UPDATE_FIELD_FILTER,
    UPDATE_TABLE_COLUMN
} from './types';

const initialState = {
    loading: false,
    data: [],
    dVendedor: '',
    openPopup: false,
    filters: {
        distribuidorId: 0,
        supervisorId: '',
        ubigeoId: 0,
        vendedorId: '',
        desde: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        hasta: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        dDistribuidor: '',
        dSupervisor: '',
        dVendedor: '',
        dUbigeo: '',
        estado: 'P',
        soloprospecto: 0,
        todos: true
    },
    store: {
        data: [],
        selected: [],
    },
    table: {
        order: 'asc',
        orderBy: '',
        columns: [
            // { dataKey: 'Nombre' ,  label: 'Vendedor',   width: 200 },
            // { dataKey: 'ProspectoId', label: 'ClienteId', width: 90 },
            // { dataKey: 'NIT', label: 'NIT/RUC', width: 100 },
            // { dataKey: 'NombreComercial', label: 'Nombre Comercial', width: 250 },
            // { dataKey: 'DireccionFiscal', label: 'Direccion Fiscal', width: 350 },
            // { dataKey: 'NombreSucursal', label: 'Nombre Sucursal', width: 250 },
            // { dataKey: 'DireccionSucursal', label: 'Direccion Sucursal', width: 350 },
            // { dataKey: 'FechaRegistro', label: 'Fecha Registro', width: 50 },
            // { dataKey: 'Motivo', label: 'Motivo', width: 250 },
            // { dataKey: 'correo', label: 'Correo', width: 200 },
            // { dataKey: 'Observacion', label: 'Observacion', width: 200 },
            // { dataKey: 'Latitud', label: 'Latitud', width: 200 },
            // { dataKey: 'Longitud', label: 'Longuitud', width: 200 },
            // { dataKey: 'Estado' ,  label: 'Estado',   width: 10 },
            // { dataKey: 'Anulado' ,  label: 'Anulado',  type: 'boolean',  width: 100 }
        ]
    },
};

export const listarprospectosReducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case FILTER_MAP_VENDEDOR:
            return {
                ...state,
                dVendedor: action.dVendedor || ''
            };
        case HIDE_POPUP_CLIENT:
            return {
                ...state,
                openPopup: false
            };
        case RECEIVE_DATA:
                return {
                    ...state,
                    store: {
                        ...state.store,
                        loading: false,
                        data: action.payload,
                        selected: []
                    },
                    table: {
                        ...state.table,
                        columns: action.columns,
                    }
                };    
        case SET_MASK:
            return {
                ...state,
                loading: action.loading
            };
        case SET_ORDER:
            return {
                ...state,
                table: {
                    ...state.table,
                    order: action.order,
                    orderBy: action.orderBy
                }
            };
        case SET_SELECTED_ROW:
            return {
                ...state,
                store: {
                    ...state.store,
                    selected: action.selected
                }
            };
        case UPDATE_FIELD_FILTER:
            return { 
                ...state,
                filters: {
                    ...state.filters,
                    [action.key]: action.value
                }
            };
        case SHOW_POPUP_CLIENT:
            return {
                ...state,
                openPopup: true,
                storeClient: {
                    ...state.store,
                    data: action.storeData
                }
            };
        case UPDATE_TABLE_COLUMN:
            return {
                ...state,
                table: {
                    ...state.table,
                    columns: action.columns
                }
            };
        default:
            return state;
    }
}