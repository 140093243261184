import React from 'react';
import {useSelector } from "react-redux";
import makeStyles from '@material-ui/core/styles/makeStyles';
import { formatoPeru} from "../../../helpers/commons";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";


const useStyles = makeStyles(() => ({
  cuadron: {
    marginLeft:'5px',
  },
  resul:{
    fontSize: "10px !important",
    background: "white",
    textAlign: "Left !important",
  },
  resuld: {
    fontSize: "10px !important",
    textAlign: "Right !important", 
    background: "white",
  },
  porfa: {
    marginTop:-10,
    "@media (max-width: 640px)": {
      height: "305px",
      width: "480px",
      marginLeft: "5px",
      fontSize:'6px',
      marginTop:'-10px',
      zIndex : 100,
    },
  },
  a:{   
    fontSize: "12px",
  },
  table: {
  highlight: {
    backgroundColor: 'red',
  },
  background: 'rgba(0,102,255,.25)',
  textalign: 'center',
    fontSize: "max(1 vw, 5px)",
    margin: "auto",
    '& th' : {
      border: '1px solid rgba(16,76,167,.25)',
      background: 'rgba(0,102,255,.25)',
      textalign: 'center',
    },
    '& td' : {
      border: '1px solid rgba(16,76,167,.25)',
    }
  },
}));

const DetalleAprobarCobranza = () => {
    const classes = useStyles();
    const loading = useSelector((state) => state.bandejaaprobacion.loading);
    const datad = useSelector((state) => state.bandejaaprobacion.store.detalleaprobarcobranza);
    const t = useSelector((state) => state.bandejaaprobacion.store.selectedAprobarCobranza);

    const modalInfo = t[0]

    let total = 0;
   
    
    return (  
        <>
       {t.length > 0  && datad.length > 0  &&  (

        <div className={classes.porfa}  >          
          <h2 style={{ textAlign: "center", fontSize: "18px" }}>
            Detalle de Cobranza           Número : {datad[0].NroCobranza}
          </h2>
    
     
          <div className={classes.cuadron} style= {{ width:'95%', marginLeft:'1px'}} >
            <table   align="center" style= {{ width: '104%', marginTop: -10}} >
            <tbody > 
                  <tr>  
                    <td style={{ width: "15px" , backgroundColor: "#BFD9FF",fontSize: "10px",fontWeight: "bold" }}>ESTADO:</td>
                    <td colSpan="2" style={{ width: "50px" ,fontSize: "10px", color : modalInfo.estado === 'P' ? 'blue' : modalInfo.estado ===  'R' ? 'red' :'green' , textAlign:'center', fontWeight: 'bold'}}>{modalInfo.estado === 'P' ? 'PENDIENTE' :  modalInfo.estado === 'R' 
                    ? 'RECHAZADO' :  modalInfo.estado === 'A' ? 'APROBADO' : 'REVISADO' }</td>
                    
                    <td colSpan="1" style={{ width: "50px" , backgroundColor: "#BFD9FF" ,fontSize: "10px", fontWeight: "bold"}}>FECHA COBRANZA:</td> 
                    <td colSpan="1" style={{fontSize: "10px", width: "95px", fontWeight: "bold" , textAlign:'center' }}>{modalInfo.fechaCobranza}</td>
                    <td  colSpan="2" style={{ width: "85px",fontSize: "10px"  }}>
                    {datad[0].Foto01 === null ? <Button startIcon={<Icon className="fa fa-ban" />} color="primary" size="small" /> : datad[0].Foto01 === '' ? <Button startIcon={<Icon className="fa fa-ban" />} color="primary" size="small" /> : <a href={[datad[0].Foto01]} target={'_blank'} rel="noreferrer"><Button startIcon={<Icon className="fa fa-camera" />} color="primary" size="small" /> </a>}
                    {datad[0].Foto02 === null ? <Button startIcon={<Icon className="fa fa-ban" />} color="primary" size="small" /> : datad[0].Foto02 === '' ? <Button startIcon={<Icon className="fa fa-ban" />} color="primary" size="small" /> : <a href={[datad[0].Foto02]} target={'_blank'} rel="noreferrer"><Button startIcon={<Icon className="fa fa-camera" />} color="primary" size="small" /> </a>}

                      </td>  
                      {/* <td colSpan="1" style={{fontSize: "10px", width: "95px", fontWeight: "bold" , textAlign:'center' }}>Número {datad[0].Numero}</td> */}
                  </tr>

                  <tr>
                  <td style={{ width: "85px" , backgroundColor: "#BFD9FF",fontSize: "10px" ,  fontWeight: "bold"}} >NIT:</td> 
                    <td colSpan="1" style={{ width: "20%",fontSize: "10px", textAlign: 'center'  }}> <b>{modalInfo.nit} </b></td>
                    <td style={{ width: "85px" , backgroundColor: "#BFD9FF",fontSize: "10px",fontWeight: "bold" }}> CLIENTE:</td>
                    <td colSpan="1" style={{ width: "185px",fontSize: "10px" }}>{modalInfo.cliente.toUpperCase()}</td>
                    <td style={{ width: "12%" , backgroundColor: "#BFD9FF",fontSize: "10px",fontWeight: "bold" }}>RESPONSABLE:</td>
                    <td  colSpan="3" style={{ width: "85px",fontSize: "10px"  }}>{modalInfo.responsable.toUpperCase()}</td>  
                  </tr>
                  {/* <tr></tr>  */}
                  <tr> 
                    <td style={{ width: "85px" , backgroundColor: "#BFD9FF",fontSize: "10px" , fontWeight: "bold" }}> OBSERVACIÓN:</td>
                    <td colSpan="7" style={{ width: "85px",fontSize: "10px" }}><b> {modalInfo.observacion.toUpperCase()} </b></td>
                  </tr>
                  <tr style={{ height: "5px" , backgroundColor: "white" , backColor: "white"}} ></tr>
                  <tr>
                    <td style={{ width: "85px" , backgroundColor: "#EBE5B7",fontSize: "10px",fontWeight: "bold" }}> BANCO:</td>
                    <td colSpan="1" style={{ width: "185px",fontSize: "10px", textAlign:'center'  }}>{modalInfo.banco.toUpperCase()}</td>
                    <td style={{ width: "75px" , backgroundColor: "#EBE5B7",fontSize: "10px",fontWeight: "bold" }}>NRO. CUENTA:</td>
                    <td  colSpan="1" style={{ width: "125px",fontSize: "10px" , textAlign:'center'  }}>{modalInfo.numeroCuenta}</td>  
                    <td style={{ width: "95px" , backgroundColor: "#EBE5B7",fontSize: "10px",fontWeight: "bold" }}>NRO. OPERACIÓN:</td>
                    <td  colSpan="2" style={{ width: "85px",fontSize: "10px", textAlign:'center'   }}>{modalInfo.nroDocumento}</td>  
                   
                  </tr>
                  <tr>
                  <td colSpan="1" style={{ width: "100px" , backgroundColor: "#EBE5B7" ,fontSize: "10px", fontWeight: "bold"}}>FECHA OPERACIÓN:</td>
                    <td colSpan="1" style={{fontSize: "10px", width: "95px", fontWeight: "bold", textAlign:'center'  }}>{modalInfo.fechaDeposito}</td>
                    <td colSpan="1" style={{ width: "100px" ,fontSize: "10px",backgroundColor: "#EBE5B7" , fontWeight: "bold"}}>TIPO COBRANZA</td>
                    <td colSpan="1" style={{fontSize: "10px", width: "95px", textAlign:'center'  }}>{modalInfo.tipoCobranza}</td>
                  <td colSpan="1" style={{ width: "135px" , backgroundColor: "#EBE5B7",fontSize: "10px",fontWeight: "bold" }}>TOTAL COBRANZA:</td>
                  <td colSpan="2" style={{ width: "85px",fontSize: "12px",fontWeight: "bold", textAlign:'right'  }}>{ formatoPeru('en-US', 'USD', modalInfo.monto )}</td>
                </tr>
                <tr>
                  <td colSpan="1" style={{ width: "100px" , backgroundColor: "#EBE5B7" ,fontSize: "10px", fontWeight: "bold"}}>OBSERVACIÓN:</td>
                    <td colSpan="5" style={{fontSize: "10px", width: "95px", fontWeight: "bold", textAlign:'center'  }}>{modalInfo.observacion}</td>
                </tr>
             </tbody>
            </table> 
          </div>
          <br></br>
          <br></br>
          <div style={{display:'flex', flex: '1'}}> 
            <div style={{ display: "none" ,border:'none'}}>
              {!loading &&  datad.map( (ite) => (total = total + ite.Monto ))}
            </div>
              <div style={{ display: 'inlineBlock', zoom: 1, width: '100%' }}>
                <div className={classes.cuadron} style={{ width: '98%', height: "100px", overflowX: datad.length >= 1 ? "auto" : "hidden", marginTop: "-25px" }}>
                  {<table align="center" className="aprobacion" id="tableMod" style={{ borderCollapse: "collapse", width: "100%", marginTop: '7px' }}  >
                    <thead >
                      <tr>
                        <th>Nro. Documento</th>    
                        <th>Doc. Fecha </th>
                        <th> Fecha Vence</th>
                        {/* <th>Foto 1 </th>
                        <th>Foto 2</th>
                        <th>Foto 3</th> */}
                        <th>Saldo</th>
                        <th>Monto</th>
                        <th>Retención</th>
                        <th>Descuento</th>
                        <th>Valor</th>
                      </tr>
                    </thead>
                    <tbody style={{ border: "1px solid #A1C8E8" }}>

                      {!loading &&
                        datad.map((item, index) => (
                          <tr
                            style={{ color: item.estado === "P" && 'red' }}
                            key={index}
                          >
                            <td className={classes.resul}>
                              {item.NroDocumento}
                            </td>
                            <td style={{ fontSize: "10px" }}>
                              {item.Fecha}
                            </td>
                            <td className={classes.resuld}>
                              {item.FechaVence}
                            </td>

                            {/* <td style={{ fontSize: "10px", width: "10px" }}>
                              {item.Foto01 === null ? <Button startIcon={<Icon className="fa fa-ban" />} color="primary" size="small" /> : item.Foto01 === '' ? <Button startIcon={<Icon className="fa fa-ban" />} color="primary" size="small" /> : <a href={[item.Foto01]} target={'_blank'} rel="noreferrer"><Button startIcon={<Icon className="fa fa-camera" />} color="primary" size="small" /> </a>}

                            </td>
                            <td style={{ fontSize: "10px" }}>
                              {item.Foto02 === null ? <Button startIcon={<Icon className="fa fa-ban" />} color="primary" size="small" /> : item.Foto02 === '' ? <Button startIcon={<Icon className="fa fa-ban" />} color="primary" size="small" /> : <a href={[item.Foto02]} target={'_blank'} rel="noreferrer"><Button startIcon={<Icon className="fa fa-camera" />} color="primary" size="small" /> </a>}
                            </td>
                            <td style={{ fontSize: "10px" }}>
                              {item.Foto03 === null ? <Button startIcon={<Icon className="fa fa-ban" />} color="primary" size="small" /> : item.Foto03 === '' ? <Button startIcon={<Icon className="fa fa-ban" />} color="primary" size="small" /> : <a href={[item.Foto02]} target={'_blank'} rel="noreferrer"><Button startIcon={<Icon className="fa fa-camera" />} color="primary" size="small" /> </a>}
                            </td> */}
                            <td className={classes.resuld}  >
                              {/* {item.Saldo } */}
                              {formatoPeru('en-US', 'USD', item.Saldo)}
                            </td>
                            <td className={classes.resuld}>
                              {formatoPeru('en-US', 'USD', item.Monto)}
                              {/* {parseFloat(item.Mpnto).toFixed(2)} */}
                            </td>
                            <td style={{ fontSize: "10px" }}>
                            {formatoPeru('en-US', 'USD', item.Retencion)}
                            </td>
                            <td style={{ fontSize: "10px" }}>
                            {formatoPeru('en-US', 'USD', item.Descuento)}
                            </td>
                            <td style={{ fontSize: "10px" }}>
                            {/* {let x = item.Retención + item.Descuento;} */}
                            {/* {formatoPeru('en-US', 'USD', item.Monto -(x))} */}
                            {/* { isNaN(item.Retención +item.Descuento)? 10.000 : 1.000} */}
                            {formatoPeru('en-US', 'USD', isNaN(item.Retención +item.Descuento)?  item.Monto :
                            item.Monto -(item.Retención + item.Descuento))}
                            </td>
                          </tr>
                        ))}

                    </tbody>
                    {loading && (
                      <tbody>
                        <tr >
                          <td colSpan="9">
                            <img
                              alt="log"
                              src="https://app.iess.gob.ec/iess-fondo-reserva-afiliado-web/javax.faces.resource/images/load.gif.jsf;jsessionid=8949b76cfea43d283add3bd3dd2d?ln=sentinel-layout"
                              width="40"
                              height="40"
                            />
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>}

                </div>
                {datad.length > 0 && !loading && (
                  <div style={{ borderCollapse: "collapse", width: "98%", fontSize: '12px', textAlign: 'right', marginBottom:'20px' }}>

                    <b style={{ fontSize: "14px" }}> Total: {formatoPeru('en-US', 'USD', total)}</b>

                  </div>
                )}
              </div>     
          </div>
          
          {/* <br></br>

          { datad.length === 0  && ( <div >  No existe Registro </div> )} 
          <br></br> */}
        </div>
       )}
    </>
  );
}

export default DetalleAprobarCobranza;


