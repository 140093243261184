import axios from 'axios';
import format from "date-fns/format";
import { IdentityUrl } from '../../../config';
import { closeAdvancedSearch, closeMessageBox, openAdvancedSearch, openMessageBox } from '../../main/actions';
import {
    FILTER_MAP_VENDEDOR,
    HIDE_POPUP_CLIENT,
    RECEIVE_DATA,
    RECEIVE_V_LIST,
    SET_MASK,
    SET_ORDER,
    SET_SELECTED_ROW,
    SHOW_POPUP_CLIENT,
    UPDATE_FIELD_FILTER,
    UPDATE_TABLE_COLUMN
} from './types';

import {  tipos, marcas, lugar } from '../../../helpers/commons'

export const btnExport = () => async (dispatch, getState) => {
  
        if (getState().avanceidentificacionmercado.filters.distribuidorId   === 0) {
            dispatch(openMessageBox({
                button: 'ok',
                icon: 'warning',
                message: "Debe seleccionar un Agente..",
                callback: () => dispatch(closeMessageBox())
            }));
        return;
        }
        if(getState().avanceidentificacionmercado.store.data.length === 0) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'warning', 
                message: 'No hay información para exportar.', 
                callback: () => dispatch(closeMessageBox())
            }));
            return;
        }
    try{
        dispatch({ type: SET_MASK, loading: true });

        let button = document.getElementById("table-xls-button-rpt-avance");
        button.click();

    } catch (error) {
        dispatch({ type: SET_MASK, loading: false })
        console.log(error);
        dispatch(
          openMessageBox({
            button: "ok",
            icon: "error",
            message:
              (error.response.data.eventLogId === 0
                ? ""
                : `EventoId: ${error.response.data.eventLogId}. `) +
              error.response.data.message,
            callback: () => dispatch(closeMessageBox()),
          })
        );
      } finally {
        setTimeout(() => {dispatch({ type: SET_MASK, loading: false })}, 1000);
      }  
};

export const btnSearch = () => async (dispatch, getState) => {
    try {
        dispatch({ type: SET_MASK, loading: true });

        if (getState().avanceidentificacionmercado.filters.distribuidorId   === 0) {
            dispatch(openMessageBox({
                button: 'ok',
                icon: 'warning',
                message: "Debe seleccionar un Agente..",
                callback: () => dispatch(closeMessageBox())
            }));
        return;
        }
        let url = `${getState().main.resources.uriSvcSalesMobilityAPI}/api/v1/Reportes/marketing/reporteresumenidentificacion?`
            + `distribuidorId=${getState().avanceidentificacionmercado.filters.distribuidorId}`
            + `&desde=${format(getState().avanceidentificacionmercado.filters.desde, 'yyyy-MM-dd')}`
            + `&hasta=${format(getState().avanceidentificacionmercado.filters.hasta, 'yyyy-MM-dd')}`;
        let response = await axios.get(encodeURI(url));
        let data = response.data;
        //console.log(data)

       // console.log(data.filter((f => f.dVendedor === "Fabian Marcillo"  && f.conPedido === 1)))
        
       //dVendedor: "Fabian Marcillo"
        //  let storeData = [
        //     {  dVendedor: 'Vendedor 1', cuentaPuntoVentaP: 19, cuentaPuntoVentaE: 19, avance: '100%', ejecutado: '60%' },
        //     {  dVendedor: 'Vendedor 2', cuentaPuntoVentaP: 24, cuentaPuntoVentaE: 12,  avance: '50%', ejecutado: '60%' },
        //     {  dVendedor: 'Vendedor 3', cuentaPuntoVentaP: 39, cuentaPuntoVentaE: 30, avance: '60%', ejecutado: false },
        //     {  dVendedor: 'Vendedor 4', cuentaPuntoVentaP: 40, cuentaPuntoVentaE: 33,  avance: '75%', ejecutado: true },
        //     {  dVendedor: 'Vendedor 5', cuentaPuntoVentaP: 50, cuentaPuntoVentaE: 49,  avance: '99%', ejecutado: true },
            

        // ];
        
        //let url2 = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/ListarProspecto?`
    /*
        var i ;
        var cont = 0;
          for(i=0; i < data.length; i++){
            
            if (data[i].conPedido === 1 || data[i].montoPedido > 0){
                cont= cont+1
            }
          }

        let sumarizado = _(data)
            .groupBy('dVendedor')
            .map((item, key) => ({
                'dVendedor': key,
                'proyectados': _.countBy(item, i => i.Proyectado >= 1 ), //=== true
                'ejecutados': _.countBy(item, i => i.Ejecutado >= 1  ), // === true
                'fueraRuta': _.countBy(item, i => i.conVisita >= 1 ),
                //=== true && i.proyectado === '20%' 
                'fuerar': _.countBy(item, i => i.conPedido >= 1 ), 
                'pedido': _.countBy(item, i => i.conPedido >= 1 ),
                'prospecto': _.countBy(item, i => i.EsProspecto >= 1 )
            }))
            .value();
         
            
        let storeData = _.map(sumarizado, (item) => {
            return {
                dVendedor: item.dVendedor,
                cuentaPuntoVentaE: item.ejecutados['true'] ?? 0,
                cuentaPuntoVentaP: item.proyectados['true'] ?? 0,
                avance: parseInt((item.ejecutados['true'] ?? 0)/(item.proyectados['true'] ?? 1)*100) + '%',
                cuentaFueraRuta: item.fueraRuta['true'] ?? 0,
                cuentaFuera: item.fuerar['true'] ?? 0,
                cuentaPedido: item.pedido['true'] ?? 0,
                supavance: item.fueraRuta['true'] > 0  &&  item.ejecutados['true'] > 0 ?
                 parseInt(( (item.ejecutados['true']+ item.fueraRuta['true']) ?? 0)/(item.proyectados['true']  + item.fueraRuta['true'] ?? 1)*100) + '%'
                 :  parseInt(( ( item.fueraRuta['true']) ?? 0)/((item.proyectados['true']   ) ?? 1)*100) + '%',
                 cuentaProspecto: item.prospecto['true'] ?? 0,
            };
        });

        */
       // console.log(storeData)
       //let data = [0,0,0,0 ,0]

       let columnas = getState().avanceidentificacionmercado.table.columns; 

        dispatch({ type: RECEIVE_V_LIST, columns: columnas, payload: data });

    } catch (error) {
       
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'error', 
            message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
            callback: () => dispatch(closeMessageBox())
        }));
    } finally {
        dispatch({ type: SET_MASK, loading: false });
    }


/* ----------------------------------------------------------------------------------*/
/*
try {
        
    let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/reportes/reportepedidos?` 
    + `&desde=${format(getState().avanceidentificacionmercado.filters.desde, 'yyyy-MM-dd')}`
    + `&hasta=${format(getState().avanceidentificacionmercado.filters.hasta, 'yyyy-MM-dd')}`
        + `&vendedorid=${getState().reportepedidos.filters.vendedorId}`
    
    let response = await axios.get(encodeURI(url));
    let datap = response.data;

    let cuenta = _(datap.data)
    .groupBy('Vendedor')
    .map((item, key) => ({
        'Vendedor': key,
        'proyectados': _.countBy(item, i => i.PedidoId > 0 ),
         }))
    .value();


let storePed = _.map(cuenta, (item) => {
    return {
        Vendedor: item.Vendedor,
        cuentaPuntoVentaE: item.proyectados['true'] ?? 0
    };
});

        dispatch({ type: RECEIVE_OLAS_LIST, pay: datap, storePed });

    } catch (error) {
        let msgError = '';
        dispatch({ type: SET_MASK, loading: false });

        if (error.response) {
        
         if(error.response.status === 400) {
                msgError = (error.response.data.eventLogId === 0 ? 'No existe Registro  para esta fecha ' : `EventoId: ${error.response.data.eventLogId}. `+ error.response.data.message) 
         }else{
            msgError = (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message;
         }
        }
        else {
            msgError = 'Error al conectar con el servidor';
        }

        dispatch(openMessageBox({
            button: 'ok',
            icon: 'error',
            message: msgError,
            callback: () => dispatch(closeMessageBox())
        }));
    }finally{
        dispatch({ type: SET_MASK, loading: false });
    }

    */
    
};

export const closeDialogClient = () => ({ type: HIDE_POPUP_CLIENT });

export const filterMapByVendedor = (dVendedor) => ({ type: FILTER_MAP_VENDEDOR, dVendedor });

export const onSearchDistribuidorClick = () => async (dispatch) => {
    let fields = [
        { 'dataKey': 'razonSocial', 'label': 'Agente', 'type': 'string', 'width': 225 }
    ];

    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de agente',
        getData: async (params) => {
            let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`
            let response = await axios.get(encodeURI(url), { params: params });
            //console.log(response.data)
            return response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldFilter('distribuidorId', result[0].distribuidorId));
                dispatch(onUpdateFieldFilter('dDistribuidor', `${result[0].razonSocial}`));
                dispatch(onUpdateFieldFilter('supervisorId', ''));
                dispatch(onUpdateFieldFilter('dSupervisor', ''));
                dispatch(onUpdateFieldFilter('vendedorId', ''));
                dispatch(onUpdateFieldFilter('dVendedor', ''));
            }
        }
    }));
};

export const onSearchSupervisorClick = () => async (dispatch, getState) => {
    let fields = [
        { 'dataKey': 'nombre', 'label': 'Nombre', 'type': 'string', 'search': true, 'width': 225 },
        { 'dataKey': 'nombreUsuario', 'label': 'Usuario', 'type': 'string', 'width': 115 }
    ];

    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de supervisor',
        getData: async (params) => {
            let url = `${IdentityUrl}/api/v1/usuarios?rol=Supervisor`
            let response = await axios.get(encodeURI(url), { params: params });
            let distribuidorId = getState().avanceidentificacionmercado.filters.distribuidorId;

            if(distribuidorId > 0) {
                response.data.data = response.data.data.filter(f => f.distribuidorId === distribuidorId);
            }

            return response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldFilter('supervisorId', result[0].usuarioId));
                dispatch(onUpdateFieldFilter('dSupervisor', `${result[0].nombre}`));
                dispatch(onUpdateFieldFilter('vendedorId', ''));
                dispatch(onUpdateFieldFilter('dVendedor', ''));
            }
        }
    }));
};

export const onSearchUbigeoClick = () => async (dispatch, getState) => {
    let fields = [
        { 'dataKey': 'codigo', 'label': 'Código', 'type': 'string', 'width': 115 },
        { 'dataKey': 'dUbigeo', 'label': 'Ubigeo', 'type': 'string', 'search': true, 'width': 225 }
    ];

    dispatch(openAdvancedSearch({
        autoLoad: false,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de ubigeo',
        getData: async (params) => {
            let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/agentes/ubigeos`;
            let response = await axios.get(encodeURI(url), { params: params });

            return response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldFilter('ubigeoId', result[0].ubigeoId));
                dispatch(onUpdateFieldFilter('dUbigeo', `${result[0].dUbigeoId}`));
            }
        }
    }));
};

export const onSearchVendedorClick = () => async (dispatch, getState) => {
    let fields = [
        { 'dataKey': 'nombre', 'label': 'Nombre', 'type': 'string', 'search': true, 'width': 225 },
        { 'dataKey': 'nombreUsuario', 'label': 'Usuario', 'type': 'string', 'width': 115 }
    ];

    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de vendedor',
        getData: async (params) => {
            let url = `${IdentityUrl}/api/v1/usuarios/porjerarquia?usuarioId=${getState().avanceidentificacionmercado.filters.supervisorId}`
            let response = await axios.get(encodeURI(url), { params: params });
            let distribuidorId = getState().avanceidentificacionmercado.filters.distribuidorId;

            if(distribuidorId > 0) {
                response.data.data = response.data.data.filter(f => f.distribuidorId === distribuidorId);
            }

            return response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldFilter('vendedorId', result[0].usuarioId));
                dispatch(onUpdateFieldFilter('dVendedor', `${result[0].nombre}`));
            }
        }
    }));
};

export const onUpdateColumn = (column) => (dispatch, getState) => {
    if(!column) return;

    let columns = getState().avanceidentificacionmercado.table.columns.map(item => {
        if(item.dataKey !== column.dataKey) {
            return item;
        }

        return {
            ...item,
            width: column.width
        }
    });

    dispatch({ type: UPDATE_TABLE_COLUMN, columns });
};

export const onUpdateFieldFilter = (key, value) => (dispatch, getState) => {
    dispatch({ type: UPDATE_FIELD_FILTER, key, value })
    //const  da = []

    dispatch( onUpdateFielddata());
   // dispatch({ type: RECEIVE_DATA, payload:[],  store:[] })
    dispatch({ type: RECEIVE_DATA, payload: [],storeData:[]});
    
}

export const onUpdateFielddata = (key, value) => (dispatch, getState) => {  

   // dispatch({ type: UPDATE_FIELD_DATA, key, value })

     //dispatch({ type: RECEIVE_DATA, columns: [], data: [] })
  //  dispatch({ type: RECEIVE_DATA, payload:[],  store:[] })
    //dispatch({ type: RECEIVE_DATA, payload: data, storeData });
};

export const openDialogClient = (data) => (dispatch, getState) => {
    try {
        var storeData = getState().avanceidentificacionmercado.data.filter(f => f.dVendedor === data.dVendedor);
        
        dispatch({ type: SHOW_POPUP_CLIENT, storeData });
    } catch (error) {
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'error', 
            message: error, 
            callback: () => dispatch(closeMessageBox())
        }));
    }
};

export const setOrder = (order, orderBy) => ({ type: SET_ORDER, order, orderBy });

export const setSelectedRow = (selected) => ({ type: SET_SELECTED_ROW, selected});

export const onSearchTipoClick = () => async (dispatch, getState) => {
    let fields = [
        { 'dataKey': 'dTipo', 'label': 'Tipo', 'type': 'string', 'width': 250 }
    ];

    let data = tipos;

    try {
        dispatch(openAdvancedSearch({
            autoLoad: true,
            data: data,
            fields: fields,
            paginated: false,
            remote: false,
            title: 'Seleccione un Tipo',
            callback: (btn, result) => {
                dispatch(closeAdvancedSearch());
                if(btn === 'yes') {
                    dispatch(onUpdateFieldFilter('tipoId', result[0].tipoId));
                    dispatch(onUpdateFieldFilter('dTipo', result[0].dTipo));
                    //dispatch(onUpdateFieldFilter('dTipo', ''));
                }
            }
        }));
    } catch (error) {
        if(error.response) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        }
    }
};

export const onSearchMarcasClick = () => async (dispatch, getState) => {
    let fields = [
        { 'dataKey': 'dMarcas', 'label': 'Marcas', 'type': 'string', 'width': 250 }
    ];

    let data = marcas;

    try {
        dispatch(openAdvancedSearch({
            autoLoad: true,
            data: data,
            fields: fields,
            paginated: false,
            remote: false,
            title: 'Selección de Marcas',
            callback: (btn, result) => {
                dispatch(closeAdvancedSearch());
                if(btn === 'yes') {
                    dispatch(onUpdateFieldFilter('marcasId', result[0].marcasId));
                    dispatch(onUpdateFieldFilter('dMarcas', result[0].dMarcas));
                   // dispatch(onUpdateFieldFilter('dMarcas', ''));
                }
            }
        }));
    } catch (error) {
        if(error.response) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        }
    }
};

export const onSearchLugarClick = () => async (dispatch, getState) => {
    let fields = [
        { 'dataKey': 'dLugar', 'label': 'Ubicación', 'type': 'string', 'width': 250 }
    ];

    let data = lugar;

    try {
        dispatch(openAdvancedSearch({
            autoLoad: true,
            data: data,
            fields: fields,
            paginated: false,
            remote: false,
            title: 'Seleccione una Ubicación',
            callback: (btn, result) => {
                dispatch(closeAdvancedSearch());
                if(btn === 'yes') {
                    dispatch(onUpdateFieldFilter('lugarId', result[0].lugarId));
                    dispatch(onUpdateFieldFilter('dLugar', result[0].dLugar));
                   // dispatch(onUpdateFieldFilter('dLugar', ''));
                }
            }
        }));
    } catch (error) {
        if(error.response) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        }
    }
};
