import {
    CLEAN_FORM,
    SET_STATE_FORM,
    UPDATE_FIELD_FILTER,
    SET_MASK,
    RECEIVE_DATA,
    UPDATE_TABLE_COLUMN,
    ERROR_V_LIST,
    RECEIVE_V_LIST,
    REQUEST_V_LIST,
    UPDATE_FIELD_ENTITY,
    SET_ERROR,
    SET_ORDER,
    ADD_OLA,
    ADD_DISTRIBUIDOR,
} from './types';

const initialState = {
    formState: 'search',
    loading: false,
    entity: {
        clientesId: 0,
        nombre: ''
    },
    errors: {
        codigo: '',
        usuario: '',
        documentoIdentidad: ''
    },
    filters: {
        nombre: '',
        distribuidorId: "",
        dDistribuidor: '',
        dVendedor: '',
        vendedorId : 0,
        rol: '',
        entorno: '',
        rolId: 0,
        entornoId: 0,
        olaId: "",
        razonSocial:"",
    },
    store: {
        loading: false,
        pageSize: 50,
        start: 0,
        total: 0,
        data: [],
        selected: [],
        chartAcumulado : [{
            "country": "Agentes Distrib Mobil",
            "litres":  139.9,
            "color": "#140c64"  //em
          },{
            "country": "Agentes Distrib Terpel",
            "litres": 165.8,
            "color": "red"
          }, {
            "country": "Otros",
            "litres":501.9,
            "color": "grey"
          }]
        //source: null
    },
    table: {
        order: 'asc',
        orderBy: '',
        columns: []
    }
};

export const gestionrolesReducer = (state, action) => {
    state = state || initialState;

    switch(action.type) {
        case CLEAN_FORM:
            return {
                ...state,
                entity: initialState.entity,
                errors: initialState.errors
            };
        case SET_STATE_FORM:
            return {
                ...state,
                formState: action.formState
            };
        case SET_ERROR:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.key]: action.error
                }
            };
        case UPDATE_FIELD_FILTER:
            return { 
                ...state,
                filters: {
                    ...state.filters,
                    [action.key]: action.value
                }
            };
            case ERROR_V_LIST:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: false
                }
            };
            case UPDATE_FIELD_ENTITY:
            return { 
                ...state,
                entity: {
                    ...state.entity,
                    [action.key]: action.value
                }
            };
            case RECEIVE_V_LIST:
                return {
                    ...state,
                    store: {
                        ...state.store,
                        loading: false,
                        total: action.total,
                        data: action.payload,
                        source: null
                    }
                };
            case REQUEST_V_LIST:
                return {
                    ...state,
                    store: {
                        ...state.store,
                        loading: false,
                        source: action.source
                    }
                };
            case RECEIVE_DATA:
                return {
                    ...state,
                    data: action.payload,
                    store: {
                        ...state.store,
                        data: action.storeData
                    }
                };
                case UPDATE_TABLE_COLUMN:
                    return {
                        ...state,
                        table: {
                            ...state.table,
                            columns: action.columns
                        }
                    };
            case SET_MASK:
                return {
                    ...state,
                    loading: action.loading
            };
            case SET_ORDER:
                return {
                    ...state,
                    table: {
                        ...state.table,
                        order: action.order,
                        orderBy: action.orderBy
                    }
                };
            case ADD_OLA:
                console.log(action.payload)
                return {
                    ...state,
                    filters: {
                        ...state.filters,
                        olaId: action.payload
                    }
                };

            case ADD_DISTRIBUIDOR:
                    console.log(action.payload)
                    return {
                        ...state,
                        filters: {
                            ...state.filters,
                            distribuidorId: action.payload
                        }
                    };
            
        default:
            return state;
    }
};