/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Map, InfoWindow, Marker, GoogleApiWrapper,  Polyline  } from "google-maps-react";
import randomColor from 'random-color'

import {mye} from '../../../helpers/commons'

const useStyles = makeStyles(() => ({
    imagen: {
        marginRight: 8,
        maxWidth: 175,
    }
}));
var markerObjects = [];
var orden = [];

function MapDirectionsRenderer(props) {
    return null;
    // class MapDirectionsRenderer extends React.Component {

    // const [error, setError] = useState(null);
    // //const path = new props.google.maps.MVCArray();   
    // const { pos } = props;

    // const { places } = props;
    // const { map } = props;
    // const { desde } = props;
    // const { hasta } = props;

    // //const [error, setError] = useState(null);

    // //componentDidMount() {
    
    // var service_callback = function (response, status) {
    //     if (status !== 'OK') {
    //         console.log('Directions request failed due to ' + status);
    //         return;
    //     }
    //     var pOptions = {
    //         strokeColor: '#FF0000',
    //         strokeOpacity: 0.9,
    //         strokeWeight: 6
    //     };
    //     const renderer = new props.google.maps.DirectionsRenderer();
    //     renderer.setMap(map);
    //     renderer.setOptions({ suppressMarkers: true, preserveViewport: true, polylineOptions: pOptions });
    //     renderer.setDirections(response);
    // };

    // useEffect(() => {
    //     if (pos <= (places.length - 2)) { return }

    //     if (map === 'undefined') { return; }
    //     if (places === 'undefined') { return; }
    //     if (desde !== hasta) { return; }

    //     // Divide route to several parts because max stations limit is 25 (23 waypoints + 1 origin + 1 destination)
        
    //     for (var l = 0, parts = [], max = 25 - 1; l < places.length; l = l + max) {
    //         parts.push(places.slice(l, l + max + 1));
    //     }
    //     //handleShow(parts);

    //     const service = new props.google.maps.DirectionsService();
    //     // Send requests to service to get route (for stations count <= 25 only one request will be sent)
    //     for (var i = 0; i < parts.length; i++) {
    //         // Waypoints does not include first station (origin) and last station (destination)
    //         var waypoints = [];
    //         for (var j = 1; j < parts[i].length - 1; j++)
    //             waypoints.push({ location: parts[i][j], stopover: false });
    //         // Service options
    //         var service_options = {
    //             origin: parts[i][0],
    //             destination: parts[i][parts[i].length - 1],
    //             waypoints: waypoints,
    //             travelMode: 'DRIVING'
    //         };
    //         // Send request

    //         service.route(service_options, service_callback);
            
    //     }

    // });

    // if (error === null) {
    //     return <div></div>;
    // }

    // return (directions && <DirectionsRenderer directions={directions} />)
    // return (<Marker  position={coords} />)
}

export const ReporteVisitasMap = ({ google }) => {
    const classes = useStyles();
    const [item, setItem] = useState({});
    const [marker, setMarker] = useState({});
    const [showingInfo, setShowingInfo] = useState(false);
    const [result, setResult] = useState([]);
    const coords = { lat: 9.8350311, lng: -75.120551 };
    const data = useSelector(state => state.reportevisitas.data);
    const dVendedor = useSelector(state => state.reportevisitas.dVendedor);
    const desde = useSelector(state => state.reportevisitas.filters.desde);
    const hasta = useSelector(state => state.reportevisitas.filters.hasta);

    const [show, setShow] = React.useState(false);
    const [showV, setShowV] = React.useState(true);
    const [showE, setShowE] = React.useState(true);

    var handleClick = function () {
        if (show) {
            setShow(false);
        } else {
            setShow(true);
        }
    };
    var handleClickV = function () {
        if (showV) {
            setShowV(false);
        } else {
            setShowV(true);
        }
    };
    var handleClickE = function () {
        if (show) {
            setShow(false);
        }
        if (showE) {
            setShowE(false);
        } else {
            setShowE(true);
        }
    };
    
    useEffect(() => {

        markerObjects = Array.from([]);
        let dataMap = data.filter(f => (dVendedor === '' ? true : f.dVendedor === dVendedor))
        orden = Array.from([]);

        //console.log("Data: "+JSON.stringify(dataMap))

        // eslint-disable-next-line array-callback-return
        dataMap.map((item, i) => {
            //aqui tengo todos
            if (!isNaN(parseFloat(item.latitud)) && !isNaN(parseFloat(item.longitud))
                && parseFloat(item.latitud) !== 0 && parseFloat(item.longitud) !== 0) {
                markerObjects.push({
                    "lat": parseFloat(item.latitud), "lng": parseFloat(item.longitud),
                    "lats": parseFloat(item.latituds), "lngs": parseFloat(item.longituds)
                })
                
                orden.push({
                    "lat": parseFloat(item.latitud), "lng": parseFloat(item.longitud),
                    "lats": parseFloat(item.latituds), "lngs": parseFloat(item.longituds)
                     ,
                     "OrdenVisita" : item.OrdenVisita
                })
            } 
            
            else if (isNaN(parseFloat(item.latitud)) && isNaN(parseFloat(item.longitud)) && isNaN(parseFloat(item.latituds)) && isNaN(parseFloat(item.longituds))) {
                markerObjects.push({
                    "lat": parseFloat(coords.lat), "lng": parseFloat(coords.lng),
                    "lats": parseFloat(coords.lat), "lngs": parseFloat(coords.lng)
                })

                orden.push({
                    "lat": parseFloat(coords.lat), "lng": parseFloat(coords.lng),
                    "lats": parseFloat(coords.lat), "lngs": parseFloat(coords.lng)     ,
                    "OrdenVisita" : item.OrdenVisita
                })
                
            } else if( (isNaN(parseFloat(item.latitud)) && isNaN(parseFloat(item.longitud))) || (parseFloat(item.latitud) === 0 && parseFloat(item.longitud) === 0)) {
                markerObjects.push({
                    "lat": parseFloat(item.latituds), "lng": parseFloat(item.longituds),
                    "lats": parseFloat(item.latituds), "lngs": parseFloat(item.longituds)
                })
                orden.push({
                    "lat": parseFloat(item.latitud), "lng": parseFloat(item.longitud),
                    "lats": parseFloat(item.latituds), "lngs": parseFloat(item.longituds)  ,
                     "OrdenVisita" : item.OrdenVisita
                })
            }  
                  
        });

        orden = orden.filter((f) => f.OrdenVisita !== 0)
        orden.sort((a, b) => {
            return a.OrdenVisita - b.OrdenVisita;
        });

        setResult(dataMap.map((current, index) => {
            if (index === 0) {
                let color = randomColor();
                current.color = color.hexString();
            }
            else if (dataMap[index - 1].vendedorId === current.vendedorId) {
                current.color = dataMap[index - 1].color;
            }
            else {
                let color = randomColor();
                current.color = color.hexString();
            }

            return current;
        }));

        // eslint-disable-next-line
    }, [data, dVendedor]);


    const onMarkerClick = (marker, item) => {
        setItem(item);
        setMarker(marker);
        setShowingInfo(true);
    };

    return (   
        markerObjects.length !== 0
        && (
            <>
                <Map
                    initialCenter={markerObjects.length > 0  ? { lat: parseFloat(markerObjects[0].lat), lng: parseFloat(markerObjects[0].lng) } : coords}
                    google={google}
                    style={{ width: '100%', height: '100%', position: 'relative' }}
                    zoom={12}
                >

                        { show  &&   //fil==='T'  &&   vendedorId !=='' &&
                                <Polyline
                                      path= {orden}                             
                                                label={"Test"}
                                                clickable
                                                visible
                                                strokeColor=  '#FF0000'
                                                strokeOpacity = {1.0}
                                                strokeWeight =  {2}
                                                geodesic= {true}
                                                fillOpacity= {0.35}
                                                stroke="#FFFFFF"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                icons= {[{
                                                    icon: {path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW},
                                                    offset: '100%',
                                                    repeat: '20px'
                                                }]}
                                        /> 
                            } 
                    {result.map((item, key) => {
                        var posi
                        if (item.latitud === "" &&   item.latitud === ""){
                                posi = { lat: parseFloat(item.latituds), lng: parseFloat(item.longituds)}
                        }else{
                            posi = { lat: parseFloat(item.latitud), lng: parseFloat(item.longitud)}
                        }
                       
                        if ( item.conVisita    )
                        {               }
                    
                        // if ( !item.Ejecutado  && !item.EsProspecto    )
                        // {
                   
                        //     return (
                        //             <Marker
                        //                 key={`row-${key}`}
                        //                 position={{ lat: parseFloat(item.latitud), lng: parseFloat(item.longitud) }}
                        //                 title={item.dClienteSucursal}
                        //                 onClick={(props, marker, e) => onMarkerClick(marker, item)}
                        //                 /*
                        //                 label={item.Proyectado && item.Ejecutado ? 'Eje' : ( item.Proyectado ? ' P' : 'F' )}
                        //                 icon={{
                        //                     url: item.Proyectado && item.Ejecutado ? `https://chart.googleapis.com/chart?chst=d_map_spin&chld=1|0|${item.color.replace('#', '')}|40|_|` : 
                        //                     `http://maps.google.com/mapfiles/ms/icons/orange.png`,
                        //                     scaledSize:   item.Proyectado && item.Ejecutado ?   new google.maps.Size(22, 36):  new google.maps.Size(36, 36) ,
                        //                     labelOrigin: new google.maps.Point(11,10)
                        //                 }}
                        //                 */

                        //                 label={item.Ejecutado ? 'Eje' : 'Proy'}
                        //                 icon={{
                        //                     url: `https://chart.googleapis.com/chart?chst=d_map_spin&chld=1|0|${item.color.replace('#', '')}|40|_|`,
                        //                     scaledSize: new google.maps.Size(36, 36),
                        //                     labelOrigin: new google.maps.Point(11, 10)
                        //                 }}
                        //             />
                        //         )
                        // }   

                            //proyectado no ejecutadp
                            if ( !item.conVisita  &&  item.OrdenVisita === 0)
                            {
                                 return (  
                                    <Marker
                                        key={`sin-${key}` }
                                        position={{  lat: posi.lat, lng : posi.lng }}// lat: parseFloat(item.latituds), lng: parseFloat(item.longituds) }}
                                        title={item.dClienteSucursal}
                                        onClick={(props, marker, e) => onMarkerClick(marker, item)}
                                        label={'proy'}
                                        // icon={{
                                        //     url: mapicon[0],
                                        // }}
                                    />
                                )
                            }
                        
                        if (   ( (item.Proyectado && item.Ejecutado ) || item.conVisita)   &&  showE)
                        {
                              return (  
                                  <Marker
                                      key={`name-${key}` }
                                      position={{  lat: posi.lat, lng : posi.lng  }}
                                      title={ item.conVisita ? item.dClienteSucursal+' -FR' : item.dClienteSucursal }
                                      onClick={(props, marker, e) => onMarkerClick(marker, item)}
                                      label={ item.conVisita ?  'F'+(item.OrdenVisita ).toString() :  (item.OrdenVisita ).toString()}                             
                                      icon={{
                                      // url: mapicon[1],
                                      url: mye,
                                        scaledSize: new google.maps.Size(46, 46),
                                        anchor: new google.maps.Point(14, 43),
                                        labelOrigin: new google.maps.Point(21.5, 15) 
                                     }}

                                    //    icon={ customIcon({  //FUNCTION
                                    //         fillColor: '#F1F40E',
                                    //         scaledSize: new google.maps.Size(46, 46)
                                    //       })
                                   // }
                                  />
                              )
                         } 
     
                         if (    item.EsProspecto   )
                         {
                               return (
                                   <Marker
                                       key={`p-${key}`}
                                       position={{  lat: posi.lat, lng : posi.lng }} //lat: parseFloat(item.latitud), lng: parseFloat(item.longitud) }}
                                       title={item.dClienteSucursal+' -Prosp' }
                                       onClick={(props, marker, e) => onMarkerClick(marker, item)}
                                       label={'Pr'+(item.OrdenVisita ).toString()}   
                                       icon={{
                                        url: `https://chart.googleapis.com/chart?chst=d_map_spin&chld=1|0|${item.color.replace('#', '')}|40|_|`,
                                        scaledSize: new google.maps.Size(36, 36),
                                        labelOrigin: new google.maps.Point(11, 10)
                                    }} 
                                   />
                               )
                          } 
                    })
                    }
            
                    {showV && (
                        result.map((item, key) => { //punto fijos de losm PDV
                            return (
                                <Marker
                                    key={`dv-${key}`}
                                    position={{ lat: parseFloat(item.latituds), lng: parseFloat(item.longituds) }}
                                    title={item.dClienteSucursal + ' /' + item.Direccion}
                                    //onClick={(props, marker, e) => onMarkerClick(marker, item)}
                                    label={item.Proyectado ? 'PDV' : (item.Proyectado ? ' P' : 'F')}
                                    icon={{
                                        url: 'https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png',
                          
                                    }}
                                />
                            )
                        })
                    )
                    }
                    <InfoWindow
                        marker={marker}
                        visible={showingInfo}
                    >
                        <div style={item.Proyectado && item.Ejecutado ? { color: '#FF000' } : { color: '#FF8000' }}>
                            <h3>{item.dClienteSucursal}
                                {item.Proyectado && item.Ejecutado  ? '   -(Ejecutado)' :    !item.Ejecutado  && !item.EsProspecto  ?   '   -(Proyectado)'   :    
                                       item.EsProspecto  &&  item.conVisita ?   '   -(Prospecto-Ejecut)' : '   -(Prospecto)' }
                                </h3>                    
                            <h4>{item.dVendedor}</h4>
                            <div>
                                {item.foto01 && item.foto01 !== '' && (<img className={classes.imagen} style={{ height: 200, width: 200 }} src={item.foto01} alt="foto01" />)}
                                {item.foto02 && item.foto02 !== '' && (<img className={classes.imagen} style={{ height: 200, width: 200 }} src={item.foto02} alt="foto02" />)}
                                {item.foto03 && item.foto03 !== '' && (<img className={classes.imagen} style={{ height: 200, width: 200 }} src={item.foto03} alt="foto03" />)}
                                {item.foto04 && item.foto04 !== '' && (<img className={classes.imagen} style={{ height: 200, width: 200 }} src={item.foto04} alt="foto04" />)}
                            </div>
                        </div>
                    </InfoWindow>

                    {
                        show ?
                            result.map((item, i) => {
                                return (
                                    <MapDirectionsRenderer
                                        places={markerObjects}
                                        travelMode={google.maps.TravelMode.DRIVING}
                                        pos={i}
                                        google={google}
                                        desde={desde}
                                        hasta={hasta}
                                    /> 
                                );
                            })
                            : null
                    }
                </Map>

                {dVendedor.length > 0 && (
                    <div style={{
                        background: 'gold',
                        opacity: 0.7,
                        left: '80%',
                        top: '65px',
                        right: '300px',
                        bottom: '0',
                        margintop: '65px',
                        marginright: '0.8rem',
                        position: 'absolute',
                        height: '12px',
                        width: '470px'
                    }}>
                        <input
                            type="button"
                            value={dVendedor}
                            onClick={handleClick}
                            style={{ height: '40px' }}
                        />
                    </div>
                )
                }

                {( 
                    <div style={{
                        background: 'gold',
                        opacity: 0.7,
                        left: '1%',
                        top: '65px',
                        right: '300px',
                        bottom: '0',
                        margintop: '65px',
                        marginright: '0.8rem',
                        position: 'absolute',
                        height: '12px',
                        width: '470px'
                    }}>
                        <input
                            type="button"
                            value={  showV ? 'Ocultar Pdv' : 'Ver Pdv'}
                            onClick={handleClickV}
                            style={{ height: '40px' }}
                        />
                    </div>
                    
                )    }

            {( 
                    <div style={{
                        background: 'gold',
                        opacity: 0.7,
                        left: '12%',
                        top: '65px',
                        right: '300px',
                        bottom: '0',
                        margintop: '65px',
                        marginright: '0.8rem',
                        position: 'absolute',
                        height: '12px',
                        width: '470px'
                    }}>
                        <input
                            type="button"
                            value={  showE ? 'Ocultar Visitas' : 'Ver Visitas'}
                            onClick={handleClickE}
                            style={{ height: '40px' }}
                        />
                    </div>          
                )    }
                
            </>
        )
    )

};

export default GoogleApiWrapper({
    apiKey: "AIzaSyCrwct6vhZk5TTSuht7PpA0kAVSdoWSB7s"
})(ReporteVisitasMap);