import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  btnSearchCanalClick, 
  btnSearchLineaClick,
  btnSearchMarcaClick,
  onUpdateFieldFilter, //from '../../../store/fuerzaventas/vendedor/actions';
} from "../../../store/precios/precios/actions";

import { ActionField,FieldSet } from "@sigeco/tools";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { KeyboardDatePicker } from "@material-ui/pickers";
import FormControl from "@material-ui/core/FormControl";
import { Icon } from "@material-ui/core";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(() => ({
  content: {
    padding: "0.5rem 0.75rem 0.75rem",
  },
  filterClear: {
    display: "flex",
  },
  actionField: {
    flexDirection: "row",
    position: "relative",
  },
  btnClear: {
    position: "absolute",
    bottom: "1.5",
    right: 20,
    maxWidth: "15px",
    maxHeight: "23px",
    minWidth: "15px",
    minHeight: "23px",
  },
  root: {
    flex: "0 0 auto",
    marginBottom: "0.75rem",
    "@media (min-width: 1401px)": {
      marginBottom: "1rem",
    },
  },
}));

const PreciosFilter = () => {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:701px)");
  const filters = useSelector((state) => state.precios.filters);
  const dispatch = useDispatch();


  function onFieldChange(event) {
    dispatch(onUpdateFieldFilter(event.target.name, event.target.value));
  }



  return (
    <div className={classes.root}>
      <FieldSet iconCls="fas fa-filter" title="Filtros" defaultExpanded>
        <Grid className={classes.content} container spacing={2}>

                <Grid item>
                    <KeyboardDatePicker
                            autoOk
                            disableToolbar
                            format="dd/MM/yyyy"
                            label="Fecha"
                            value={filters.fecha}
                            inputVariant={matches ? "outlined" : "standard"}
                            variant={matches ? "inline" : "dialog"}
                            onChange={date => dispatch(onUpdateFieldFilter('fecha', date))}
                        />
                 </Grid>

        </Grid>
      </FieldSet>
    </div>
  );
};

export default PreciosFilter;
