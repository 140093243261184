import axios from 'axios';
import format from "date-fns/format";
import { IdentityUrl } from '../../../config';
import { closeAdvancedSearch, closeMessageBox, openAdvancedSearch, openMessageBox } from '../../main/actions';
import {
    RESTART_STATE,
    SET_MASK,
    UPDATE_FIELD_FILTER
} from './types';

//import * as XLSX from "xlsx";
import { exportToSpreadsheet } from '../../../helpers/commons'

export const btnExport = () => async (dispatch, getState) => {

    if (getState().basedatosmarketing.filters.distribuidorId   === 0) {
        dispatch(openMessageBox({
            button: 'ok',
            icon: 'warning',
            message: "Debe seleccionar un Agente..",
            callback: () => dispatch(closeMessageBox())
        }));
    return;
    }       
    try {
        dispatch({ type: SET_MASK, loading: true });

        let url = `${getState().main.resources.uriSvcSalesMobilityAPI}/api/v1/Reportes/basedatos/exportaridentificacion?`
            + `distribuidorId=${getState().basedatosmarketing.filters.distribuidorId}`
            + `&desde=${format(getState().basedatosmarketing.filters.desde, 'yyyy-MM-dd')}`
            + `&hasta=${format(getState().basedatosmarketing.filters.hasta, 'yyyy-MM-dd')}`;
        let response = await axios.get(encodeURI(url)); 
       
       let json = response.data.split('\n'); 
    
        const JSONData = [];
        var i= 0
        json.forEach(function(item){
           ;
            JSONData.push(item.split(','));
            if (!isNaN(parseFloat(JSONData[i][17] ))){
                        JSONData[i][17] = parseFloat(JSONData[i][17] )
            }          
            i++           
        });
       
        exportToSpreadsheet(JSONData, 'basedatosmarketing') ;
 
    } catch (error) {
        let msgError = '';
        dispatch({ type: SET_MASK, loading: false });

        if (error.response) {
        
            if(error.response.status === 400) {
                   msgError = (error.response.data.eventLogId === 0 ? 'No existe Registro  para esta fecha ' : `EventoId: ${error.response.data.eventLogId}. `+ error.response.data.message) 
            }else{
               msgError = (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message;
            }
           } else {
               // eslint-disable-next-line no-unused-vars
               msgError = 'Error al conectar con el servidor';
           }
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'error', 
            //message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
            message: msgError,
            callback: () => dispatch(closeMessageBox())
        }));
    } finally {
        dispatch({ type: SET_MASK, loading: false });
    }
};

export const onSearchDistribuidorClick = () => async (dispatch) => {
    let fields = [
        { 'dataKey': 'razonSocial', 'label': 'Agente', 'type': 'string', 'width': 225 }
    ];

    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de agente',
        getData: async (params) => {
            let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`
            let response = await axios.get(encodeURI(url), { params: params });

            return response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldFilter('distribuidorId', result[0].distribuidorId));
                dispatch(onUpdateFieldFilter('dDistribuidor', `${result[0].razonSocial}`));
            }
        }
    }));
};

export const onUpdateColumn = (column) => (dispatch, getState) => {
    // let columns = getState().resumen.table.columns.map(item => {
    //     if (item.dataKey !== column.dataKey) {
    //         return item;
    //     }

    //     return {
    //         ...item,
    //         width: column.width
    //     }
    // });

    // dispatch({ type: UPDATE_TABLE_COLUMN, columns });
};
export const onUpdateFieldFilter = (key, value) => ({ type: UPDATE_FIELD_FILTER, key, value });

export const restartState = () => ({ type: RESTART_STATE });