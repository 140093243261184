import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from "lodash";
import { FieldSet } from '@sigeco/tools';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(() => ({
    content: {
        padding: '0.5rem 0.75rem 0.75rem'
    },
    root: {
        flex: '0 0 auto',
        marginBottom: '0.75rem',
        '@media (min-width: 1401px)': {
            marginBottom: '1rem',
        }
    }
}));

const ReporteVisitasSummary = () => {
    const classes = useStyles();
    const matches = useMediaQuery('(min-width:701px)');
    const data = useSelector(state => state.reportevisitas.data);
    const storeData = useSelector(state => state.reportevisitas.store.data);
    const [nroVendedores, setNroVendedores] = useState(0);
    const [nroPuntosVenta, setNroPuntosVenta] = useState(0);
    const [nroPuntosEjecutados, setNroPuntosEjecutados] = useState(0);
    const [nroFueraRuta, setNroFueraRuta] = useState(0);
    const [nroConLlamada, setNroConLLamada] = useState(0);
    const [nroConprospecto, setNroConprospecto] = useState(0);

    useEffect(() => {
        setNroVendedores(storeData.length);
        setNroPuntosVenta(sumariza(storeData));
        setNroFueraRuta(storeData.reduce(function(prev, cur) {
            return prev + cur.cuentaFueraRuta;}, 0));
        setNroPuntosEjecutados(storeData.reduce(function(prev, cur) {
            return prev + cur.cuentaPuntoVentaE;}, 0));
        setNroConprospecto(storeData.reduce(function(pre, cur) {
            return pre + cur.cuentaProspecto; }, 0));
        setNroConLLamada(storeData.reduce(function(pre, cur) {
            return pre + cur.cuentaConLlamada; }, 0));
    // eslint-disable-next-line
    }, [data]);
   
    const sumariza = (data) => {
        let total = 0;
       _.map(data, (item) => {
        total += item.cuentaPuntoVentaP
      })
      return total;
    };

    return (
        <div className={classes.root}>
            <FieldSet iconCls="far fa-clipboard" title="Resúmen" defaultExpanded expandable>
                <Grid className={classes.content} container spacing={2}>
                    <Grid item xs={12} container>
                        <Grid item xs={matches ? 2 : 12}>
                            <span>Vendedores: </span>
                            <span>{nroVendedores}</span>
                        </Grid>
                        <Grid item xs={matches ? 1 : 12}>
                            <span>PDV: </span>
                            <span>{nroPuntosVenta}</span>
                        </Grid>
                        <Grid item xs={matches ? 2 : 12}>
                            <span>PDV Ejecutado: </span>
                            <span>{nroPuntosEjecutados}</span>
                        </Grid>
                        <Grid item xs={matches ? 2 : 12}>
                            <span>Fuera Ruta: </span>
                            <span>{nroFueraRuta}</span>
                        </Grid>
                        <Grid item xs={matches ? 2 : 12}>
                            <span>Con LLamadas: </span>
                            <span>{nroConLlamada}</span>
                        </Grid>
                        <Grid item xs={matches ? 2 : 12}>
                            <span>Prospecto: </span>
                            <span>{nroConprospecto}</span>
                        </Grid>
                        <Grid item xs={matches ? 1: 12}>
                            <span>Total: </span>
                            <span>{nroPuntosEjecutados+ nroFueraRuta+ nroConprospecto+nroConLlamada}</span>
                        </Grid>
                    </Grid>
                </Grid>
            </FieldSet>
        </div>
        
    )
};

export default ReporteVisitasSummary;