import {
  RECEIVE_DATA,
  RECEIVE_ANT,
  RESTART_STATE,
  SET_MASK,
  SET_ORDER,
  SET_SELECTED_ROW,
  UPDATE_FIELD_FILTER,
  UPDATE_OLA_FIELD_FILTER,
  UPDATE_FIELD_DATA,
  ACTUALIZA_TIPO_CLIENTE,
  SHOW_POPUP_CLIENT,
  HIDE_POPUP_CLIENT,
} from "./types";

const initialState = {
  loading: false,
  data:[],
  openPopup: false,
  filters: {
    olaId: 0,
    distribuidorId: 0,
    vendedorId: '',
    dDistribuidor: "",
    dVendedor:  '',
    dUsuario: "",
    dOla: "",
    tipo: 0,
  },
  store: {
    data: [],
    resumen: [],
    selected: [],
    loading: false,
    hola: 0,
    //dhola: "",
    nombre:"",
    olaChannelMapId:0,
    columns:  [
      { dataKey: 'vendedor', label: 'Nombre Vendedor', width: 200 },
      { dataKey: 'nit', label: 'NIT', width: 90 },
      { dataKey: 'nombre', label: 'Nombre Comercial', width: 250 },
      { dataKey: 'direccion', label: 'Dirección', width: 300 },
      { dataKey: 'estadoAc' ,  label: 'Estado',  width: 100 },
      { dataKey: 'tipo', label: 'Tipo', width: 100 },
      { dataKey: 'fecha', label: 'Fecha de Toma', width: 150 },
      { dataKey: 'latitud' ,  label: 'Latitud',   width: 110 },
      { dataKey: 'longitud' ,  label: 'Longitud',   width: 110 }, 
      { dataKey: 'observacion' ,  label: 'Observación',  width: 220 },
      { dataKey: 'departamento' ,  label: 'Departamento',  width: 220 },
      { dataKey: 'codigo', label: 'Código Sucursal', width: 100 },
      
  ],
  },
  table: {
    order: "asc",
    orderBy: "",
    
  },
};

export const reporteSeguimientoVendedorReducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case RECEIVE_DATA:
      return {
        ...state,
        store: {
          ...state.store,
          loading: false,
          data: action.payload,
          selected: [],
        },
        table: {
          ...state.table,
          columns: action.columns,
        },
      };

    case RECEIVE_ANT:
      return {
        ...state,
        store: {
          ...state.store,
          // loading: false,
         resumen: action.payload,
          //selected: []
        },
      };

    case RESTART_STATE:
      return {
        ...initialState,
      };
    case SET_MASK:
      return {
        ...state,
        loading: action.loading,
      };
    case SET_ORDER:
      return {
        ...state,
        table: {
          ...state.table,
          order: action.order,
          orderBy: action.orderBy,
        },
      };
    case SET_SELECTED_ROW:
      return {
        ...state,
        store: {
          ...state.store,
          selected: action.selected,
        },
      };
    case UPDATE_FIELD_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.key]: action.value,
        },
      };

    case UPDATE_OLA_FIELD_FILTER:
        return {
          ...state,
          filters: {
            ...state.filters,
            dDistribuidor: "",
            dVendedor: "",
            dUsuario: "",
            tipo: 0
          },
      };  
    case UPDATE_FIELD_DATA:
      return {
        ...state,
        store: {
          ...state.store,
          [action.key]: action.value,
        },
      };
      case ACTUALIZA_TIPO_CLIENTE:
        return {
            ...state,
            filters: {
                ...state.filters,
                tipo: action.payload === 'CLIENTE' ? 1 : action.payload === 'PROSPECTO'? 2 : 0
            }
        };

      case HIDE_POPUP_CLIENT:
            return {
                ...state,
                openPopup: false
      };  
      
      case SHOW_POPUP_CLIENT:
          return {
              ...state,
              openPopup: true,
              storeDetalle: {
                  ...state.store,
                  data: action.storeData
              }
      };
    default:
      return state;
  }
};
