import axios from 'axios';
import format from "date-fns/format";
import { IdentityUrl } from '../../../config';
import { closeAdvancedSearch, closeMessageBox, openAdvancedSearch, openMessageBox } from '../../main/actions';
import {
    ERROR_SEGUIMIENTO_LIST,
    RECEIVE_SEGUIMIENTO_LIST,
    REQUEST_SEGUIMIENTO_LIST,
    RESTART_STATE,
    SET_ORDER,
    SET_SELECTED_ROW,
    UPDATE_FIELD_FILTER,
    UPDATE_FIELD_DATA,
    SET_MASK
} from './types';

import { exportToSpreadsheet } from '../../../helpers/commons'

export const btnExport = () => async (dispatch, getState) => {
   
    if (getState().reporteseguimiento.filters.distribuidorId   === 0) {
        dispatch(openMessageBox({
            button: 'ok',
            icon: 'warning',
            message: "Debe seleccionar un Agente..",
            callback: () => dispatch(closeMessageBox())
        }));
    return;
    }
    if(getState().reporteseguimiento.store.data.length === 0) {
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'warning', 
            message: 'No hay información para exportar.', 
            callback: () => dispatch(closeMessageBox())
        }));
        return;
    }

    try {
        dispatch({ type: SET_MASK, loading: true });

        let head = getState().reporteseguimiento.table.columns;
        var aux = [];

        console.log(head)
            for (var c in head)
                {
                  aux[c] = 'Cliente'
                  aux[1] = 'Prospecto';//nit
                  aux[2] =  'Fecha'; //Object.values(head[2])[1];//nombre
                  aux[3] =  'Latitud'; //Object.values(head[3])[1];//direccion
                  aux[4] = 'Longitud'; //Object.values(head[3])[1];//segmento
                  aux[5] =  'Observacion'; //Object.values(head[4])[1];//marca 1
                  aux[6] =  'Vendedor'; //Object.values(head[5])[1];//marca 2
                  aux[7] = 'ID'; // Object.values(head[6])[1];//marca 3
                  
                    //aux.push(x);
                }

        var carArray = [];

        carArray.push(aux);

        let json = getState().reporteseguimiento.store.data;
        console.log(json)

        for(var i in json) {
            let x =   Object.values(json[i]);
            // x.splice(0,1);
            // x.splice(21,1);
            // x.splice(20,1);
            // x.splice(19,1);
            // x.splice(18,1);
            // x.splice(17,1);
            

            //x.splice(12,1);
            //x.splice(14,1);
          
            console.log(x)
            carArray.push(x);
          }
        
        
          exportToSpreadsheet(carArray,  'ReporteSeguimiento') ;

    } catch (error) {
        console.log(error)
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'error', 
            message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
            callback: () => dispatch(closeMessageBox())
        }));
    } finally {
        dispatch({ type: SET_MASK, loading: false });
    }
};


export const btnSearch = () => async (dispatch, getState) => {
    if (getState().reporteseguimiento.store.loading) {
        return;
    }

    dispatch({ type: REQUEST_SEGUIMIENTO_LIST });

    try {
        let url = `${getState().main.resources.uriSvcSalesMobilityAPI}/api/v1/reportes/channelmap/seguimiento?`
        + `distribuidorId=${getState().reporteseguimiento.filters.distribuidorId}`
            + `&desde=${format(getState().reporteseguimiento.filters.desde, 'yyyy-MM-dd')}`
            + `&hasta=${format(getState().reporteseguimiento.filters.hasta, 'yyyy-MM-dd')}`;
        let response = await axios.get(encodeURI(url));
        let data = response.data;
        data.columns[0]['width'] = 400;
        data.columns[5]['width'] = 400;
        data.columns[6]['label'] = 'Vendedor';
        data.columns[6]['width'] = 400;
        dispatch({ type: RECEIVE_SEGUIMIENTO_LIST, columns: data.columns, payload: data.data });

    } catch (error) {
        let msgError = '';
        dispatch({ type: ERROR_SEGUIMIENTO_LIST });
/*
        if (error.response) {
            msgError = (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message;
        }
        else {
            msgError = 'Error al conectar con el servidor';
        }

        dispatch(openMessageBox({
            button: 'ok',
            icon: 'error',
            message: msgError,
            callback: () => dispatch(closeMessageBox())
        }));
        */

        if (error.response) {
        
            if(error.response.status === 400) {
                    msgError = (error.response.data.eventLogId === 0 ? 'No existe Registro  para esta fecha ' : `EventoId: ${error.response.data.eventLogId}. `+ error.response.data.message) 
            }else{
                msgError = (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message;
            }
            dispatch(openMessageBox({
                button: 'ok',
                icon: 'error',
                message: msgError,
                callback: () => dispatch(closeMessageBox())
            }));
        }
    }
};

export const onSearchDistribuidorClick = () => async (dispatch) => {
    let fields = [
        { 'dataKey': 'razonSocial', 'label': 'Agente', 'type': 'string', 'width': 225 }
    ];

    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de agente',
        getData: async (params) => {
            let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`
            let response = await axios.get(encodeURI(url), { params: params });
     
            response.data.data.push({
                distribuidorId: 0,
                ruc: "",
                razonSocial: "TODOS",
                activo: true})
                response.data.data.sort((a, b) => (a.distribuidorId > b.distribuidorId) ? 1 : -1)
            return response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldFilter('distribuidorId', result[0].distribuidorId));
                dispatch(onUpdateFieldFilter('dDistribuidor', `${result[0].razonSocial}`));
                dispatch(onUpdateFieldFilter('supervisorId', ''));
                dispatch(onUpdateFieldFilter('dSupervisor', ''));
                dispatch(onUpdateFieldFilter('vendedorId', ''));
                dispatch(onUpdateFieldFilter('dVendedor', ''));
            }
        }
    }));
};

export const onUpdateColumn = (column) => (dispatch, getState) => {
    // let columns = getState().reporteseguimiento.table.columns.map(item => {
       
    //     if(item.dataKey !== column.dataKey) {
    //         return item;
    //     }else{
    //         item.width =  column.width;
    //     }
    //     /*
    //     return {
    //         ...item,
    //         width: column.width
    //     };*/
    // });

    //dispatch({ type: UPDATE_TABLE_COLUMN, columns });
};


export const onUpdateFieldFilter = (key, value) => (dispatch, getState) => {
    dispatch({ type: UPDATE_FIELD_FILTER, key, value })
    dispatch({ type: RECEIVE_SEGUIMIENTO_LIST, columns:[], payload: [] })
};


export const onUpdateFielddata = (key, value) => (dispatch, getState) => {
    dispatch({ type: UPDATE_FIELD_DATA, key, value })
    };

export const restartState = () => ({ type: RESTART_STATE });

export const setOrder = (order, orderBy) => ({ type: SET_ORDER, order, orderBy });

export const setSelectedRow = (selected) => ({ type: SET_SELECTED_ROW, selected});