import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  onSearchDistribuidorClick,
  onUpdateFieldFilter,
} from "../../../store/fuerzaventas/preciostock/actions";

import { ActionField, FieldSet } from "@sigeco/tools";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FormControl from "@material-ui/core/FormControl";
import { Icon } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import {getDistribuidorDefault} from '../../../helpers/commons';

const useStyles = makeStyles(() => ({
  content: {
    padding: "0.5rem 0.75rem 0.75rem",
  },
  filterClear: {
    display: "flex",
  },
  actionField: {
    flexDirection: "row",
    position: "relative",
  },
  btnClear: {
    position: "absolute",
    bottom: "1.5",
    right:20,
    maxWidth: "15px",
    maxHeight: "23px",
    minWidth: "15px",
    minHeight: "23px",
  },
  root: {
    flex: "0 0 auto",
    marginBottom: "0.75rem",
    "@media (min-width: 1401px)": {
      marginBottom: "1rem",
    },
  },
}));

const PrecioStockFilter = () => {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:701px)");
  const filters = useSelector((state) => state.preciostock.filters);
  const dispatch = useDispatch();

  useEffect(() =>{

    getDistribuidorDefault(onUpdateFieldFilter,dispatch);

  },[])

  //Botones de limpiar filtros
  const [, setDistribuidor] = useState("");
  const [btn, setBtn] = useState(true);
  const [, setName] = useState("");
  const [btn1, setBtn1] = useState(true);

  useEffect(() => {
    if (filters.dDistribuidor) {
      setDistribuidor(filters.dDistribuidor);
      setBtn(false);
    } else {
      setBtn(true);
    }
  }, [filters.dDistribuidor]);

  useEffect(() => {
    if (filters.nombre) {
      setName(filters.nombre);
      setBtn1(false);
    } else {
      setBtn1(true);
    }
  }, [filters.nombre]);

  const handleDist = () => {
    setDistribuidor("");
    setName("");
    filters.distribuidorId = 0;
    filters.dDistribuidor = "";
    filters.nombre= '';
    setBtn(true);
    setBtn1(true);
  };
  const handleName = () => {
    setName("");
    filters.nombre= '';
    setBtn1(true);
  };

  function onFieldChange(event) {
    dispatch(onUpdateFieldFilter(event.target.name, event.target.value));
  }

  return (
    <div className={classes.root}>
      <FieldSet iconCls="fas fa-filter" title="Filtros" defaultExpanded>
        <Grid className={classes.content} container spacing={2}>
          <Grid item xs={12} container>
            <Grid item xs={matches ? 4 : 6}>
              <FormControl className={classes.filterClear}>
                <ActionField
                  className={classes.actionField}
                  label="Agente"
                  placeholder="Enter para buscar Agente"
                  value={filters.dDistribuidor}
                  variant={matches ? "outlined" : "standard"}
                  onHandleAction={() => dispatch(onSearchDistribuidorClick())}
                />
                <Button
                  className={classes.btnClear}
                  size="small"
                  startIcon={<Icon className="fa fa-window-close" />}
                  disabled={btn}
                  onClick={handleDist}
                />
              </FormControl>
            </Grid>
            <Grid item xs={matches ? 3 : 4} container spacing={2}></Grid>
          </Grid>

          <Grid item xs>
            <FormControl className={classes.filterClear}>
              <TextField
                autoComplete="off"
                fullWidth
                label="Nombre de Producto"
                name="nombre"
                value={ filters.nombre}
                variant={matches ? "outlined" : "standard"}
                onChange={onFieldChange}
              />
              <Button
                style={{right:0}}
                className={classes.btnClear}
                size="small"
                startIcon={<Icon className="fa fa-window-close" />}
                disabled={btn1}
                onClick={handleName}
              />
            </FormControl>
          </Grid>
        </Grid>
      </FieldSet>
    </div>
  );
};

export default PrecioStockFilter;
