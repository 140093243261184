import { ClientId, IdentityUrl } from '../../config';
import React from 'react';
import { useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    boton: {
        color: '#666',
        fontSize: '0.55rem',
        '@media (min-width: 1401px)': {
            fontSize: '0.6875rem'
        }
    },
    botonera: {
        backgroundColor: '#f5f5f5',
        borderTop: '1px solid #ccc'
    },
    contenido: {
        padding: 0, 
        width: '16rem',
        '@media (min-width: 1401px)': {
            width: '20rem'
        }
    },
    foto: {
        backgroundColor: theme.palette.primary.main, 
        fontSize: '1.6rem',
        marginRight: '0.8rem',
        height: '3.6rem', 
        width: '3.6rem',
        '@media (min-width: 1401px)': {
            fontSize: '2rem',
            marginRight: '1rem',
            height: '4.5rem', 
            width: '4.5rem'
        }
    },
    grow: {
        flexGrow: 1
    },
    margen: {
        display: 'flex', 
        margin: '0.8rem',
        '@media (min-width: 1401px)': {
            margin: '1rem'
        }
    },
    text: {
        fontSize: '0.65rem', 
        marginTop: '0.1rem', 
        padding: 1,
        '@media (min-width: 1401px)': {
            fontSize: '0.8125rem', 
            marginTop: '0.125rem'
        }
    },
    title: {
        fontSize: '0.9rem', 
        fontWeight: 700,
        '@media (min-width: 1401px)': {
            fontSize: '1.125rem', 
        }
    }
}));

const UserCard = ({ onHandleClose}) => {
    const classes = useStyles();
    const avatar = useSelector(state => state.session.user.avatar);
    const nombre = useSelector(state => state.session.user.nombre);
    const search = useSelector(state => state.router.location.search);
    const usuario = useSelector(state => state.session.user.usuario);

    function handleSignOut() {
        window.location.href = `${IdentityUrl}/logout?client_id=${ClientId}&continue=${encodeURIComponent(window.location.href)}`;
    }
    
    return (
        <Card>
            <CardContent className={classes.contenido}>
                <div className={classes.margen}>
                    <div>
                        <Avatar src={avatar ? `data:image/jpeg;base64,${avatar}` : ''} className={classes.foto}>
                            {nombre.charAt(0).toUpperCase() + (nombre.split(' ').length > 1 ? nombre.split(' ')[1].charAt(0).toUpperCase() : '') }
                        </Avatar>
                    </div>
                    <div>
                        <div className={classes.title}>{nombre}</div>
                        <div className={classes.text}>{usuario}</div>
                        <div className={classes.text}>
                            <LinkContainer to={`/perfil${search}`}>
                                <Link style={{ cursor: 'pointer' }} onClick={() => onHandleClose(null)}>
                                    Administra tu cuenta
                                </Link>
                            </LinkContainer>
                        </div>
                    </div>
                </div>
            </CardContent>
            <CardActions className={classes.botonera}>
                <div className={classes.grow}></div>
                <Button variant='outlined' className={classes.boton} onClick={handleSignOut} >Salir</Button>
            </CardActions>
        </Card>
    )
};

export default UserCard;