import {
    RESTART_STATE,
    SET_MASK,
    UPDATE_FIELD_FILTER
} from './types';

const initialState = {
    loading: false,
    filters: {
        distribuidorId: 0,
        olaId: 0,
        dOla:'',
        usuarioId: '',
        desde: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        hasta: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        dDistribuidor: ''
    }
};

export const basedatosReducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case RESTART_STATE:
            return {
                ...initialState
            };
        case SET_MASK:
            return {
                ...state,
                loading: action.loading
        };
        case UPDATE_FIELD_FILTER:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.key]: action.value
                }
            };
        default:
            return state;
    }
}