import {
    CLEAN_FORM,
    SET_STATE_FORM,
    UPDATE_FIELD_FILTER,
    SET_MASK,
    RECEIVE_DATA,
    UPDATE_TABLE_COLUMN,
    ERROR_V_LIST,
    RECEIVE_V_LIST,
    REQUEST_V_LIST,
    UPDATE_FIELD_ENTITY,
    SET_ERROR,
    SET_ORDER,
} from './types';

const initialState = {
    formState: 'search',
    loading: false,
    entity: {
        clientesId: 0,
        nombre: ''
    },
    errors: {
        codigo: '',
        usuario: '',
        documentoIdentidad: ''
    },
    filters: {
        nombre: '',
        distribuidorId: 0,
        dDistribuidor: '',
        dVendedor: '',
        vendedorId : 0,
    },
    store: {
        loading: false,
        pageSize: 50,
        start: 0,
        total: 0,
        data: [],
        selected: [],
        //source: null
    },
    table: {
        order: 'asc',
        orderBy: '',
        columns: [
            { dataKey: 'ClienteId', label: 'Id Cliente', width: 150 },
            { dataKey: 'NIT', label: 'NIT', width: 105 },
            { dataKey: 'DCliente', label: 'Nombre Cliente', width: 275 },      
            { dataKey: 'Direccion', label: 'Dirección', width: 375 },
            { dataKey: "", label: 'Código de Sucursal', width: 108 },
            { dataKey: 'Vendedor', label: 'Vendedor', width: 105 },
            //{ type: 'boolean', dataKey: 'Activo', label: 'Activo', width: 90 }
        ]
    }
};

export const clientesReducer = (state, action) => {
    state = state || initialState;

    switch(action.type) {
        case CLEAN_FORM:
            return {
                ...state,
                entity: initialState.entity,
                errors: initialState.errors
            };
        case SET_STATE_FORM:
            return {
                ...state,
                formState: action.formState
            };
        case SET_ERROR:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.key]: action.error
                }
            };
        case UPDATE_FIELD_FILTER:
            return { 
                ...state,
                filters: {
                    ...state.filters,
                    [action.key]: action.value
                }
            };
            case ERROR_V_LIST:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: false
                }
            };
            case UPDATE_FIELD_ENTITY:
            return { 
                ...state,
                entity: {
                    ...state.entity,
                    [action.key]: action.value
                }
            };
            case RECEIVE_V_LIST:
                return {
                    ...state,
                    store: {
                        ...state.store,
                        loading: false,
                        total: action.total,
                        data: action.payload,
                        source: null
                    }
                };
            case REQUEST_V_LIST:
                return {
                    ...state,
                    store: {
                        ...state.store,
                        loading: false,
                        source: action.source
                    }
                };
            case RECEIVE_DATA:
                return {
                    ...state,
                    data: action.payload,
                    store: {
                        ...state.store,
                        data: action.storeData
                    }
                };
                case UPDATE_TABLE_COLUMN:
                    return {
                        ...state,
                        table: {
                            ...state.table,
                            columns: action.columns
                        }
                    };
            case SET_MASK:
                return {
                    ...state,
                    loading: action.loading
            };
            case SET_ORDER:
                return {
                    ...state,
                    table: {
                        ...state.table,
                        order: action.order,
                        orderBy: action.orderBy
                    }
                };
        default:
            return state;
    }
};