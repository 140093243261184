import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    onUpdateFieldEntity,
    onSearchMarcaClick,
    onSearchLineaClick,
    onSearchProductoClick,
    onSearchEstablecimientoClick,
    onSearchDepartaClick,
    onSearcProvinciaClick,
    onSearcDistritoClick
} from '../../../store/precios/precios/actions';

import FormControl from "@material-ui/core/FormControl";
import { ActionField, FieldSet } from '@sigeco/tools';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery';


const useStyles = makeStyles((theme) => ({
    checkbox: {
        color: "#9c9c9c",
        padding: 0
    },
    details: {
        display: 'flex',
        padding: '0.5rem 0.75rem 0.75rem'
    },
    label: {
        alignItems: 'center',
        color: 'rgba(0, 0, 0, 0.54)', 
        display: 'flex', 
        fontSize: '0.8125rem', 
        width: 90
    },
    paper: {
        marginBottom: '0.75rem',
        '@media (min-width: 1401px)': {
            marginBottom: '1rem'
        }
    }
}));

const ProductosForm = () => {
    const classes = useStyles();
    const matches = useMediaQuery('(min-width:701px)');
    const entity = useSelector(state => state.precios.entity);
    const errors = useSelector(state => state.precios.errors);
    const formState = useSelector(state => state.precios.formState);
    const dispatch = useDispatch();
    const isView = formState === 'view';

    function onFieldChange(event) {
        dispatch(onUpdateFieldEntity(event.target.name, event.target.value));
    }

    return (
        <div>
            <FieldSet className={classes.paper} iconCls="fas fa-file-alt" title="Información">
                <div className={classes.details}>
                    <Grid container spacing={1}>
                       
                        <Grid item xs={12} container>
                            <Grid item xs={matches ? 4 : 12}>
                                <ActionField
                                    error={errors.lineaId != 0}
                                    helperText={errors.lineaId}
                                    hiddenAction={isView}
                                    label="LOB"
                                    placeholder="Enter para buscar línea"
                                    value={entity.dLinea}
                                    variant={matches ? "outlined" : "standard"}
                                     onHandleAction={() => dispatch( onSearchLineaClick ())}
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} container>
                            <Grid item xs={matches ? 4 : 12}>
                                <ActionField
                                    error={errors.tipoEstablecimientoId != 0}
                                    helperText={errors.tipoEstablecimientoId}
                                    hiddenAction={isView}
                                    label="Establecimiento"
                                    placeholder="Enter para buscar un Establecimiento"
                                    value={entity.dTipoEstablecimiento}
                                    variant={matches ? "outlined" : "standard"}
                                    onHandleAction={() => dispatch( onSearchEstablecimientoClick ())}
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} container>
                            <Grid item xs={matches ? 4 : 12}>
                                <ActionField
                                    error={errors.departamentoId != 0}
                                    helperText={errors.departamentoId}
                                    hiddenAction={isView}
                                    label="Departamento"
                                    placeholder="Enter para buscar un Departamento"
                                    value={entity.dDepartamento}
                                    variant={matches ? "outlined" : "standard"}
                                    onHandleAction={() => dispatch( onSearchDepartaClick ())}
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} container>
                            <Grid item xs={matches ? 4 : 12}>
                                <ActionField
                                    error={errors.provinciaId != 0}
                                    helperText={errors.provinciaId}
                                    hiddenAction={isView}
                                    label="Provincia"
                                    placeholder="Enter para buscar una Provincia"
                                    value={entity.dProvincia}
                                    variant={matches ? "outlined" : "standard"}
                                    onHandleAction={() => dispatch( onSearcProvinciaClick ())}
                                />
                            </Grid>
                        </Grid>


                        {/* <Grid item xs={12} container>
                            <Grid item xs={matches ? 4 : 12}>
                                <ActionField
                                    error={errors.distritoId !== 0}
                                    helperText={errors.distritoId}
                                    hiddenAction={isView}
                                    label="Distrito"
                                    placeholder="Enter para buscar una Distrito"
                                    value={entity.dDistrito}
                                    variant={matches ? "outlined" : "standard"}
                                    onHandleAction={() => dispatch( onSearcDistritoClick ())}
                                />
                            </Grid>
                        </Grid> */}

                        <Grid item xs={12} container>
                            <Grid item xs={matches ? 4 : 12}>
                                <ActionField
                                    error={ errors.marcaId != 0}
                                    helperText={errors.marcaId}
                                    hiddenAction={isView}
                                    label="Marca"
                                    placeholder="Enter para buscar Marca"
                                    value={entity.dMarca}
                                    variant={matches ? "outlined" : "standard"}
                                    onHandleAction={() => dispatch(onSearchMarcaClick())}
                                />
                            </Grid>
                        </Grid>


                        <Grid item xs={12} container>
                            <Grid item xs={matches ? 4 : 12}>
                                <ActionField
                                    error={ errors.productoId != 0}
                                    helperText={errors.productoId}
                                    hiddenAction={isView}
                                    //autoComplete="off"
                                    label="Producto"
                                    placeholder="Enter para buscar Producto"
                                    value={entity.dProducto}
                                    variant={matches ? "outlined" : "standard"}
                                    onHandleAction={() => dispatch(onSearchProductoClick())}

                                // className={classes.actionField}
                                // autoComplete="off"
                                // fullWidth
                                // label="Producto"
                                // name="dProducto"
                                // value={entity.dProducto}
                                // variant={matches ? "outlined" : "standard"}
                                // onHandleAction={() => dispatch(onSearchProductoClick())}
                               
                               
                               
                               />
                                {/* <Button
                                className={classes.btnClear}
                                size="small"
                                startIcon={<Icon className="fa fa-window-close" />}
                                disabled={btn2}
                                onClick={handleProducto}
                                /> */}
                             </Grid>
                        </Grid>
                
                                  
                    </Grid>
                </div>
                <br></br>
                <br></br>
            </FieldSet>
      
        </div>
    )
};

export default ProductosForm;