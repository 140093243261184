import {
    ADD_OPTION_NAV,
    ADVANCED_SEARCH_CLOSE,
    ADVANCED_SEARCH_OPEN,
    LOAD_FINISHED,
    MESSAGE_BOX_CLOSE,
    MESSAGE_BOX_OPEN,
    RECEIVE_OPTIONS_LIST,
    RECEIVE_RESOURCES_LIST,
    REMOVE_OPTION_NAV,
    SET_OPEN_MENU,
    SET_OPEN_SUBMENU,
    SET_OPTION
} from './types';

const initialState = {
    loaded: false,
    navs: [],
    openMenu: true,
    openSubmenu: false,
    options: [],
    path: '',
    resources: {},
    advancedSearch: {
        autoLoad: false,
        data: [],
        fields: [],
        multiSelect: false,
        open: false,
        paginated: false,
        remote: false,
        title: ''
    },
    dialog: {
        button: 'ok',
        icon: 'info',
        message: '',
        open: false
    }
};

export const mainReducer = (state, action) => {
    state = state || initialState;

    switch(action.type) {
        case ADD_OPTION_NAV:
            return {
                ...state,
                navs: [...state.navs, action.option]
            };
        case ADVANCED_SEARCH_CLOSE:
            return {
                ...state,
                advancedSearch: {
                    ...state.advancedSearch,
                    open: false
                }
            };
        case ADVANCED_SEARCH_OPEN:
            return {
                ...state,
                advancedSearch: {
                    ...state.advancedSearch,
                    autoLoad: action.payload.autoLoad || false,
                    data: action.payload.data || initialState.advancedSearch.data,
                    fields: action.payload.fields || initialState.advancedSearch.fields,
                    multiSelect: action.payload.multiSelect || initialState.advancedSearch.multiSelect,
                    open: true,
                    paginated: action.payload.paginated || initialState.advancedSearch.paginated,
                    remote: action.payload.remote || false,
                    title: action.payload.title || initialState.advancedSearch.title,
                    callback: action.payload.callback,
                    getData: action.payload.getData
                }
            };
        case LOAD_FINISHED:
            return {
                ...state,
                loaded: true
            };
        case MESSAGE_BOX_CLOSE:
            return {
                ...state,
                dialog: {
                    ...state.dialog,
                    open: false
                }
            };
        case MESSAGE_BOX_OPEN:
            return {
                ...state,
                dialog: {
                    ...initialState.dialog,
                    button: action.payload.button,
                    icon: action.payload.icon,
                    message: action.payload.message,
                    open: true,
                    callback: action.payload.callback
                }
            };
        case RECEIVE_OPTIONS_LIST:
            return {
                ...state,
                options: action.options
            };
        case RECEIVE_RESOURCES_LIST:
            return {
                ...state,
                resources: action.payload
            }
        case REMOVE_OPTION_NAV:
            return {
                ...state,
                navs: state.navs.filter(f => f.path !== action.path)
            };
        case SET_OPEN_MENU:
            return {
                ...state,
                openMenu: action.open
            };
        case SET_OPEN_SUBMENU:
            return {
                ...state,
                openSubmenu: action.open
            };
        case SET_OPTION:
            return {
                ...state,
                path: action.path
            };
        default:
            return state;
    }
}