import React, { useLayoutEffect,useRef  } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import Paper from '@material-ui/core/Paper';

import makeStyles from "@material-ui/core/styles/makeStyles";

am4core.useTheme(am4themes_material);
am4core.useTheme(am4themes_animated);

const useStyles = makeStyles(() => ({
  paper: {
    flex: 1
  },
  root: {
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
    padding: "1.5rem",
  },
  
}));

const ReporteIndicadoresGraph = () => {
  const classes = useStyles();
  const data = useSelector((state) => state.reporteindicadores.store.data);
  const agente = useSelector(state => state.reporteindicadores.filters.dDistribuidor);
  const chartRef = useRef(null);


  var mayor = []
   data.map(f => {
    return(
      mayor.push(f.puntosCartera) 
    )
    })
    
  useLayoutEffect(() => {
    if (data.length === 0) return;

    var container = am4core.create("chartdiv", am4core.Container);

    container.width = am4core.percent(100);
    container.height = am4core.percent(100);
    container.layout = "vertical";

    if (data.length > 0) {
      container.exporting.menu = new am4core.ExportMenu();
      container.exporting.menu.align = "left";
      container.exporting.menu.verticalAlign = "top";
      container.exporting.menu.items = [
        {
          label: "...",
          menu: [
            {
              label: "Imagen",
              menu: [
                { type: "png", label: "PNG" },
                { type: "jpg", label: "JPG" },
                { type: "svg", label: "SVG" },
                { type: "pdf", label: "PDF" },
              ],
            },
            {
              label: "Imprimir",
              type: "print",
            },
          ],
        },
      ];
    }

    console.log(getDataByLob(), 'que devuelve la funcion lob')
    
    const chart = createChart(container, "RESUMEN", getDataByLob("MCO"));

    chartRef.current = chart;

    return () => {
      container.dispose();
    };
  }, [data]);

  const createChart = (container, titleText, data) => {
    am4core.useTheme(am4themes_animated);
    // Themes end

    // Create chart instance
    var chart = am4core.create("chartdiv", am4charts.XYChart);

    if(chart.logo)
      chart.logo.disabled = true

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "zoomXY";
   
    chart.data = getDataByLob();
   
    
    var titulo = chart.titles.create();
    if(agente.length > 0){

    titulo.text = "Agente: " + agente;
    
    }else {

    titulo.text = "Agente: Todos";

    }
    titulo.fontWeight = 600;
    titulo.fontSize = 22;
    titulo.align = "center";
    
    // Create axes
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.title.fontWeight = "bold"
    categoryAxis.title.text = "Marcas de Acumulado de Precios";
    categoryAxis.dataFields.category = "category";
    categoryAxis.calculateTotals = true;
    categoryAxis.fontSize = 12;
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.rotation = 0; // -90 lo coloca vertical

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.fontWeight = "bold"
    valueAxis.title.text = "Promedio Acumulado de Precios";
    valueAxis.calculateTotals = true;
    //valueAxis.height = 600;
    valueAxis.min = 0;
    valueAxis.max = Math.max(...mayor)+10;
    valueAxis.strictMinMax = false;
    valueAxis.renderer.labels.template.adapter.add("text", function (text) {
      return text; 
    });


    // Create series
    var series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "precio";
    series.dataFields.categoryX = "category";
    series.name = "Precios";
    series.clustered = true;
    series.columns.template.tooltipText = "Precios: [bold]{valueY}[/] Acumulado";
    series.columns.template.fillOpacity = 0.9;
    series.columns.template.width = 80;
    series.stroke = 0
    series.fill = am4core.color("#26C6DA");
    series.stacked = true;

  
    chart.scrollbarX = new am4core.Scrollbar();
    chart.scrollbarY = new am4core.Scrollbar();

    
    // Add cursor
    chart.cursor = new am4charts.XYCursor();

    return chart
  };
    //Agrupa los datos por marca y tipo de presentación utilizando lodash
    var groupedData =  data.map((item) => ({
      ...item,
      DProducto: `${item.DProducto}-${item.Presentacion}`
    }));
    //console.log(groupedData)
    


    const getDataByLob = () => {
      
      // return _(groupedData)
      //   .groupBy("DProducto")
      //   .map((item, key) => ({
      //     category: key,
      //     precio: _.sumBy(item , "PrecioVenta")/item.length,
      //   }))
      //   .value();

      const groupedData1 = _(groupedData)
      .groupBy("DProducto")
      .map((item, key) => ({
        category: key,
        precio: _.sumBy(item, "PrecioVenta"),
        cantidad: item.length // Contar la cantidad de elementos en el grupo
      }))
      .value();

      // Calcular el promedio para cada grupo y agrega a la propiedad "precio"
      groupedData1.forEach((item) => {
        item.precio = item.precio / item.cantidad;
        delete item.cantidad; // Elimino la propiedad "cantidad" para resultado final
      });

    return groupedData1;

      
    };

  return (
    <Paper className={classes.paper}>
      <div className={classes.root}>
        <div style={{ height: 470 }} id="chartdiv" />
      </div>
    </Paper>
   
  );
};

export default ReporteIndicadoresGraph;
