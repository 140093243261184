export const CLEAN_FORM = 'channelmap/desactivacion/clean-form';
export const ERROR_DESACTIVACIONES_LIST = 'channelmap/desactivacion/error-desactivaciones-list';
export const RECEIVE_DESACTIVACIONES_LIST = 'channelmap/desactivacion/receive-desactivaciones-list';
export const REQUEST_DESACTIVACIONES_LIST = 'channelmap/desactivacion/request-desactivaciones-list';
export const RESTART_STATE = 'channelmap/desactivacion/restart-state';
export const SET_ENTITY = 'channelmap/desactivacion/set-entity';
export const SET_MASK = 'channelmap/desactivacion/set-mask';
export const SET_ORDER = 'channelmap/desactivacion/set-order';
export const SET_PAGE = 'channelmap/desactivacion/set-page';
export const SET_PAGE_SIZE = 'channelmap/desactivacion/set-page-size';
export const SET_SELECTED_ROW = 'channelmap/desactivacion/set-selected-row';
export const SET_STATE_FORM = 'channelmap/desactivacion/set-state-form';
export const UPDATE_FIELD_FILTER = 'channelmap/desactivacion/update-field-filter';
export const UPDATE_FIELD_ENTITY = 'channelmap/desactivacion/update-field-entity';
export const UPDATE_TABLE_COLUMN = 'channelmap/desactivacion/update-table-column';