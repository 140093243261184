import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    onSearchDistribuidorClick,
    onUpdateFieldFilter
} from '../../../store/marketing/basemarketing/actions';

import { ActionField, FieldSet } from '@sigeco/tools';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { KeyboardDatePicker } from '@material-ui/pickers';
import FormControl from "@material-ui/core/FormControl";
import { Icon } from "@material-ui/core";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(() => ({
    content: {
        padding: '0.5rem 0.75rem 0.75rem'
    },
    filterClear: {
        display: "flex",
      },
      actionField: {
        flexDirection: "row",
        position: "relative",
      },
      btnClear:{
        position: "absolute",
        bottom: "1.5",
        right:20,
        maxWidth: "15px",
    maxHeight: "23px",
    minWidth: "15px",
    minHeight: "23px",
      },
    root: {
        flex: '0 0 auto',
        marginBottom: '0.75rem',
        '@media (min-width: 1401px)': {
            marginBottom: '1rem',
        }
    }
}));

const BaseDatosMarketingFilter = () => {
    const classes = useStyles();
    const matches = useMediaQuery('(min-width:701px)');
    const big = useMediaQuery("(min-width:1201px)");
    const middle = useMediaQuery("(min-width:701px)");
    const filters = useSelector(state => state.basedatosmarketing.filters);
     const dispatch = useDispatch();

       //Botón de limpiar filtro
  const [, setDistribuidor] = useState("");
  const [btn, setBtn] = useState(true);

  useEffect(() => {
    if (filters.dDistribuidor) {
      setDistribuidor(filters.dDistribuidor);
      setBtn(false);
    } else {
      setBtn(true);
    }
  }, [filters.dDistribuidor]);

  const handleDist = () => {
    setDistribuidor("");
    filters.distribuidorId = 0;
    filters.dDistribuidor = "";
    setBtn(true);
  };

    return (
        <div className={classes.root}>
            <FieldSet iconCls="fas fa-filter" title="Filtros" defaultExpanded expandable>
                <Grid className={classes.content} container spacing={2}>
                    <Grid item container spacing={1} xs={10}>
                        <Grid item xs={big ? 4 : middle ? 4 : 12}>
                        <FormControl className={classes.filterClear}>
                            <ActionField
                                autoFocus
                                label="Agente"
                                placeholder="Enter para buscar agente"
                                value={filters.dDistribuidor}
                                variant={matches ? "outlined" : "standard"}
                                onHandleAction={() => dispatch(onSearchDistribuidorClick())}
                            />
                             <Button
                  className={classes.btnClear}
                               size="small"
                  startIcon={<Icon className="fa fa-window-close"  />}
                  disabled={btn}
                  onClick={handleDist}
                  />
              </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={1}>
                        <Grid item>
                            <KeyboardDatePicker
                                autoOk
                                disableToolbar
                                format="dd/MM/yyyy"
                                label="Desde"
                                value={filters.desde}
                                inputVariant={matches ? "outlined" : "standard"}
                                variant={matches ? "inline" : "dialog"}
                                onChange={date => dispatch(onUpdateFieldFilter('desde', date))}
                            />
                        </Grid>
                        <Grid item>
                            <KeyboardDatePicker
                                autoOk
                                disableToolbar
                                format="dd/MM/yyyy"
                                label="Hasta"
                                value={filters.hasta}
                                inputVariant={matches ? "outlined" : "standard"}
                                variant={matches ? "inline" : "dialog"}
                                onChange={date => dispatch(onUpdateFieldFilter('hasta', date))}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </FieldSet>
            
        
        <FieldSet className={classes.root} iconCls="fas fa-th-list" title="Resultados">
            <div className={classes.tableWrap}>
            </div>
        </FieldSet>
        
        </div>
        
    )

    
};

export default BaseDatosMarketingFilter;