import React, { useEffect} from 'react';//, useState 
import { useDispatch } from 'react-redux';//, useSelector
//import  { useReactToPrint } from 'react-to-print';//ReactToPrint,

import {
    btnSearch,
    //onBtnPdf
} from '../../store/gigantes/resumen/actions';

import { Body, Footer, Page } from '@sigeco/tools';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';

import ResumenFilter from '../../components/gigantes/resumen/ResumenFilter';
import ResumenList from '../../components/gigantes/resumen/ResumenList';

//import { PDFDownloadLink} from '@react-pdf/renderer';
//import PdfDocument from '../../components/gigantes/resumen/PdfDocument';

const Resumen = ({onClose, setTitle}) => {

    const dispatch = useDispatch();

    useEffect(() => {
        setTitle('Gigantes');
    // eslint-disable-next-line
    }, []);

    function onBtnClose() {
        onClose();
    }
 
/*
 //  const ref = React.createRef();   
const handlePrint = useReactToPrint({
        content: () =>   ref.current, 
    });
    */

    return (
        <Page>
            <Backdrop style={{ position: 'absolute', zIndex: 1200 }} open={false} transitionDuration={0}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <Body>
            <ResumenFilter />
            <ResumenList />
        </Body>
        <Footer>
            <Button
                color="primary"
                size="small"
                variant="outlined"
                onClick={() => dispatch(btnSearch())}
                    startIcon={<Icon className="fas fa-search"/>}
                >
                Buscar
            </Button>
            <Button 
                color="primary" 
                size="small" 
                variant="outlined" 
               // onClick={() => dispatch(onBtnPdf())} 
                startIcon={<Icon className="fas fa fa-file-pdf"/>}
            >
                 Enviar correos
            </Button>
            <Button 
                color="primary" 
                size="small" 
                variant="outlined" 
                onClick={() => onBtnClose()} 
                startIcon={<Icon className="fas fa-door-open"/>}
            >
                Salir
            </Button>
        </Footer>
        </Page>
    )
};

export default Resumen;