import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import {
    onUpdateColumn,
    onUserList,
    setOrder,
    setPage,
    setPageSize,
    setSelectedRow
} from '../../store/user/actions';

import { FieldSet, Table } from '@sigeco/tools';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column'
    }, 
    tableWrap: {
        flex: '1 1 auto'
    }
}));

const UserList = () => {
    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();
    const columns = useSelector(state => state.user.table.columns);
    const loadingStore = useSelector(state => state.user.loadingStore);
    const order = useSelector(state => state.user.table.order);
    const orderBy = useSelector(state => state.user.table.orderBy);
    const store = useSelector(state => state.user.store);
   // const modal1 = useSelector(state => state.user.store.data1);

    const dispatch = useDispatch();

    const handleDoubleClick = (id) => {
        history.push({
            hash: location.hash,
            pathname: `/usuarios/${id}`,
            search: location.search
        });
    }

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        dispatch(setOrder(isAsc ? 'desc' : 'asc', property));
        dispatch(onUserList());
    };

    function handleChangePage(newPage) {
        dispatch(setPage(newPage));
        dispatch(onUserList());
    }
    
    function handleChangeRowsPerPage(event) {
        dispatch(setPageSize(event.target.value));
        dispatch(setPage(0));
    }
    // Determinar numero de repeticiones para traer data total
    return (
        <FieldSet className={classes.root} iconCls="fas fa-th-list" title="Resultados">
            <div className={classes.tableWrap}>
                <Table
                    columns={columns}
                    count={store.total}
                    loading={loadingStore}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    onRowClick={items => dispatch(setSelectedRow(items))}
                    onRowDoubleClick={item => handleDoubleClick(item.usuarioId)}
                    onSort={datakey => handleRequestSort(datakey)}
                    onUpdateColumn={onUpdateColumn}
                    page={store.start/store.pageSize}
                    paginate
                    rowCount={store.data1.length}
                    rowGetter={index =>  store.data1[index] }
                    rowsPerPage={store.pageSize}
                    selected={store.selected}
                    sortBy={orderBy}
                    sortDirection={order}
                />
            </div>
        </FieldSet>
    )
};

export default UserList;