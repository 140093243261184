import clsx from 'clsx';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    setOpenMenu,
    setOpenSubmenu
} from '../../store/main/actions';
import UserCard from './UserCard';

import Avatar from '@material-ui/core/Avatar';
import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Popover from '@material-ui/core/Popover';
import Toolbar from '@material-ui/core/Toolbar';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(theme => ({
    appBar: {
        backgroundColor: '#fff',
        boxShadow: '0 1px 15px rgba(0,0,0,.04), 0 1px 6px rgba(0,0,0,.04)',
        width: '100%',
        zIndex: theme.zIndex.drawer + 1
    },
    botonIcon: {
        fontSize: '1rem'
    },
    logo: {
        height: '3rem'
    },
    logoMobil: {
        height: '2rem'
    },
    navbarLeft: {
        flexBasis: '40%'
    },
    navbarLogo: {
        display: 'flex',
        height: '3rem',
        left: 0,
        margin: '0 auto',
        position: 'absolute',
        right: 0,
        width: '12rem'
    },
    navbarLogoMobil: {
        display: 'flex',
        height: '2rem',
        left: 0,
        margin: '0 auto',
        position: 'absolute',
        right: 0,
        width: '6rem'
    },
    navbarRight: {
        flexBasis: '40%',
        textAlign: 'right'
    },
    toggle: {
        border: 'none',
        color: '#8f8f8f',
        padding: 0,
        width: '3.75rem',
        '&:hover': {
            backgroundColor: '#fff'
        },
        '@media (min-width: 751px)': {
            width: '5.625rem'
        },
        '@media (min-width: 1201px)': {
            width: '6.25rem'
        },
        '@media (min-width: 1451px)': {
            width: '6.875rem'
        }
    },
    toolbar: {
        boxSizing: 'border-box',
        justifyContent: 'space-between',
        minHeight: '3.125rem',
        padding: '0 0',
        '@media (min-width: 751px)': {
            minHeight: '3.75rem',
            padding: '.5rem 0',
        },
        '@media (min-width: 1201px)': {
            minHeight: '4.375rem',
            padding: '.625rem 0'
        },
        '@media (min-width: 1451px)': {
            minHeight: '5rem',
            padding: '1rem 0'
        }
    },
    tools: {
        display: 'inline-block',
        marginRight: 0,
        '@media (min-width: 751px)': {
            marginRight: '1rem',
        }
    },
    toolButton: {
        color: '#8f8f8f',
        minWidth: 0,
        padding: 12,
        '&:hover': {
            backgroundColor: '#fff',
            color: theme.palette.primary.main
        }
    },
    toolButtonDesktop: {
        color: '#8f8f8f',
        display: 'none',
        minWidth: 0,
        padding: 12,
        '&:hover': {
            backgroundColor: '#fff',
            color: theme.palette.primary.main
        },
        '@media (min-width: 751px)': {
            display: 'inline-flex'
        }
    },
    user: {
        display: 'inline-block',
        marginRight: '.875rem',
        '@media (min-width: 751px)': {
            marginRight: '1.25rem',
        },
        '@media (min-width: 1201px)': {
            marginRight: '1.875rem',
        },
        '@media (min-width: 1451px)': {
            marginRight: '2.5rem',
        }
    },
    userButton: {
        color: '#7d7d7d',
        fontSize: '0',
        fontWeight: 400,
        minWidth: 0,
        padding: 0,
        '&:hover': {
            backgroundColor: '#fff'
        },
        '@media (min-width: 1001px)': {
            fontSize: '0.8rem'
        }
    },
    userIcon: {
        fontSize: '1.5rem !important',
        width: 'auto',
        '@media (min-width: 751px)': {
            fontSize: '2rem !important'
        }
    }
}));

const TopBar = ({ pais }) => {
    const classes = useStyles();
    const [tarjetaEl, setTarjetaEl] = useState(null);
    const matches = useMediaQuery('(min-width:751px)');
    const avatar = useSelector(state => state.session.user.avatar);
    const openMenu = useSelector(state => state.main.openMenu);
    const nombre = useSelector(state => state.session.user.nombre);
    const dispatch = useDispatch();

    const handleToggle = () => {
        dispatch(setOpenSubmenu(false));

        if(!openMenu && !matches) {
            setTimeout(() => dispatch(setOpenMenu(true)), 100);
        }
        else {
            dispatch(setOpenMenu(!openMenu));
        }
    };

    function handleTarjetaClick(event) {
        setTarjetaEl(event.currentTarget);
    }

    function handleTarjetaClose() {
        setTarjetaEl(null);
    }

    return (
        <AppBar className={classes.appBar} position='fixed' >
            <Toolbar className={classes.toolbar} >
                <div className={classes.navbarLeft}>
                    <Button className={classes.toggle} onClick={handleToggle} disableRipple>
                        <Icon className={clsx(classes.botonIcon, "fas fa-bars")}/>
                    </Button>
                </div>
                <div className={pais === 'CO' ?  classes.navbarLogo : classes.navbarLogoMobil}>
                    <img alt='logo' className={pais === 'CO' ? classes.logo : classes.logoMobil} src={pais === 'CO' ? './images/logo-cobranding.png' : './images/Mobil-TM.png'}/>
                </div>
                <div className={classes.navbarRight}>
                    <div className={classes.tools}>
                        <Button className={classes.toolButton}>
                            <Badge classes={{badge: classes.badge}}  color="primary" overlap="rectangular" >
                                <Icon className={clsx(classes.botonIcon, "fas fa-bell")}/>
                            </Badge>
                        </Button>
                    </div>
                    <div className={classes.user}>
                        <Button className={classes.userButton} onClick={handleTarjetaClick}
                            endIcon={
                               avatar ?  <Avatar src={`data:image/jpeg;base64,${avatar}`} className={classes.avatar}>U</Avatar>: <Icon className={clsx(classes.userIcon, "fas fa-user-circle")}/>
                            }
                        >
                            {nombre}
                        </Button>
                        <Popover
                            id="tarjeta"
                            anchorEl={tarjetaEl}
                            keepMounted
                            open={Boolean(tarjetaEl)}
                            onClose={handleTarjetaClose}
                            anchorOrigin={{
                                horizontal: 'center',
                                vertical: 'bottom'
                            }}
                            transformOrigin={{
                                horizontal: 'center',
                                vertical: 'top'
                            }}
                        >
                            <UserCard onHandleClose={handleTarjetaClose}/>
                        </Popover>
                    </div>
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default TopBar;