import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    setOrder,
    setSelectedRow
} from '../../../store/channelmap/olas/actions';

import { FieldSet, Table } from '@sigeco/tools';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column'
    }, 
    tableWrap: {
        flex: '1 1 auto'
    }
}));

const OlasList = () => {
    const classes = useStyles();
    const columns = useSelector(state => state.olas.table.columns);
    const loading = useSelector(state => state.olas.store.loading);
    const order = useSelector(state => state.olas.table.order);
    const orderBy = useSelector(state => state.olas.table.orderBy);
    const store = useSelector(state => state.olas.store);
    const dispatch = useDispatch();

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        dispatch(setOrder(isAsc ? 'desc' : 'asc', property));
    };
    
    return (
        <FieldSet className={classes.root} iconCls="fas fa-th-list" title="Resultados">
            <div className={classes.tableWrap}>
                <Table
                    columns={columns}
                    count={store.total}
                    loading={loading}
                    onRowClick={items => dispatch(setSelectedRow(items))}
                    onSort={datakey => handleRequestSort(datakey)}
                    rowCount={store.data.length}
                    rowGetter={index => store.data[index]}
                    selected={store.selected}
                    sortBy={orderBy}
                    sortDirection={order}
                />
            </div>
        </FieldSet>
    )
};

export default OlasList;