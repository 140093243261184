export const CLEAN_FORM = 'clientes/clientessinrutas/clean-form';
export const ADVANCED_SEARCH_CLOSE = 'main/advanced-search-close';
export const ADVANCED_SEARCH_OPEN = 'main/advanced-search-open';
export const SET_STATE_FORM = 'clientes/clientessinrutas/set-state-form';
export const UPDATE_FIELD_FILTER = 'clientes/clientessinrutas/update-field-filter';
export const RECEIVE_V_LIST = 'clientes/clientessinrutas/receive-v-list';
export const REQUEST_V_LIST = 'clientes/clientessinrutas/request-v-list';
export const ERROR_V_LIST = 'clientes/clientessinrutas/error-v-list';
export const SET_MASK = 'clientes/clientessinrutas/set-mask';
export const SET_ORDER = 'clientes/clientessinrutas/set-order';
export const RECEIVE_DATA= 'clientes/clientessinrutas/receibe-data';
export const UPDATE_TABLE_COLUMN= 'clientes/clientessinrutas/update-table-column';
export const SET_PAGE = 'clientes/clientessinrutas/set-page';
export const SET_SELECTED_ROW = 'clientes/clientessinrutas/set-selected-row';