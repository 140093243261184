import axios from 'axios';
import { push } from 'connected-react-router';
import { IdentityUrl } from '../../config';
import { generateRandomCode } from '../../helpers/commons';
import { closeAdvancedSearch, closeMessageBox, openAdvancedSearch, openMessageBox } from '../main/actions'
import {
    CLEAN_FORM,
    DROP_APPLICATION,
    DROP_DISTRIBUIDOR,
    ERROR_USERS_LIST,
    RECEIVE_USERS_LIST,
    RECEIVE_USERS_LIST2,
    REQUEST_USERS_LIST,
    RESTART_STATE,
    SELECT_APPLICATION,
    SELECT_DISTRIBUIDOR,
    SET_ENTITY,
    SET_ERROR,
    SET_MASK,
    SET_ORDER,
    SET_PAGE,
    SET_PAGE_SIZE,
    SET_PASSWORD,
    SET_SELECTED_ROW,
    SET_STATE_FORM,
    UPDATE_FIELD_ENTITY,
    UPDATE_FIELD_FILTER,
    UPDATE_TABLE_COLUMN,
    ACTUALIZAR_SUB_MODAL
} from './types';

import { Arol} from "../../../src/helpers/commons" 
//       ../../../helpers/commons";

export const cleanForm = () => ({ type: CLEAN_FORM });

export const getUser = (usuarioId) => async (dispatch, getState) => {
    try {
        dispatch({ type: SET_MASK, loading: true });

        let url = `${IdentityUrl}/api/v1/usuarios/${usuarioId}`;
        let response = await axios.get(encodeURI(url));
        let payload = {
            ...response.data,
            aplicaciones: response.data.aplicaciones.map(item => { 
                return {
                    aplicacionClienteId: item.aplicacionClienteId,
                    nombre: item.nombre
                }
            }),
            distribuidores: response.data.distribuidores.map(item => { 
                return {
                    distribuidorId: item.distribuidorId,
                    razonSocial: item.razonSocial
                }
            })
        };
        
        dispatch({ type: SET_ENTITY, payload });
    } catch (error) {
        if(error.response.status === 404) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: 'No se encontro el registro solicitado.', 
                callback: () => { 
                    dispatch(closeMessageBox()); 
                    dispatch(push({
                        pathname: `/usuarios`,
                        search: getState().router.location.search,
                        hash: getState().router.location.hash
                    }));
                }
            }));
        }
        else {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        }
    } finally {
        dispatch({ type: SET_MASK, loading: false });
    }
};

export const onDropApplication  = (aplicacionClienteId) => ({ type: DROP_APPLICATION, aplicacionClienteId });

export const onDropDistribuidor  = (distribuidorId) => ({ type: DROP_DISTRIBUIDOR, distribuidorId });

export const onExport = () => async (dispatch, getState) => {
    try {
        if(getState().user.store.data.length === 0) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'warning', 
                message: 'No hay información para exportar.', 
                callback: () => dispatch(closeMessageBox())
            }));
            return;
        }

        dispatch({ type: SET_MASK, loading: true });

        let url = `${IdentityUrl}/api/v1/usuarios/csv?`
            + `nombreUsuario=${getState().user.filters.usuario}`
            + `&nombre=${getState().user.filters.nombre}`;
        let response = await axios.get(encodeURI(url), { responseType: 'arraybuffer' });
        let csvHref = window.URL.createObjectURL(new Blob([response.data]));
        let link = document.createElement('a');

        link.href = csvHref;
        link.setAttribute('download', 'usuarios.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'error', 
            message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
            callback: () => dispatch(closeMessageBox())
        }));
    } finally {
        dispatch({ type: SET_MASK, loading: false });
    }
};

export const onSearchApplicationClick = () => async (dispatch, getState) => {

    let fields = [
        { 'dataKey': 'nombre', 'label': 'Aplicación', 'type': 'string', 'width': 300 }
    ];

    try {
        let data = [
            { aplicacionClienteId: 'SalesMobilityApp', nombre: 'Sales Mobility App' },
            { aplicacionClienteId: 'SalesMobilityWeb', nombre: 'Sales Mobility Web' }
        ];

        dispatch(openAdvancedSearch({
            data: data,
            fields: fields,
            multiSelect: true,
            title: 'Aplicaciones Cliente',
            callback: (btn, result) => {
                dispatch(closeAdvancedSearch());
                if(btn === 'yes') {

                    let aplicaciones = getState().user.entity.aplicaciones.map(item => ({ ...item}));

                    result.forEach(element => {
                        if(aplicaciones.filter(f => f.aplicacionClienteId === element.aplicacionClienteId).length === 0) {
                            aplicaciones.push({ aplicacionClienteId: element.aplicacionClienteId, nombre: element.nombre });
                        }
                    });

                    dispatch({ type: SELECT_APPLICATION, payload: aplicaciones });
                }
            }
        }));
    } catch (error) {
        if(error.response) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        }
    }
};

export const onSearchDistribuidorClick = () => async (dispatch, getState) => {
    let fields = [
        { 'dataKey': 'razonSocial', 'label': 'Agente', 'type': 'string', 'width': 225 }
    ];


    // if (getState().user.store.data.length === 0) {
    //     dispatch(
    //       openMessageBox({
    //         button: "ok",
    //         icon: "warning",
    //         message: "Debe presionar el botón buscar primero..",
    //         callback: () => dispatch(closeMessageBox()),
    //       })
    //     );
    //     return;
    //   }

    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de agente',
        getData: async (params) => {
            let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`
            let response = await axios.get(encodeURI(url), { params: params });

            return response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldEntity('distribuidorId', result[0].distribuidorId));
                dispatch(onUpdateFieldEntity('dDistribuidor', `${result[0].razonSocial}`));
                let distribuidores=[{'distribuidorId': result[0].distribuidorId, 'razonSocial':result[0].razonSocial}];
                dispatch({ type: SELECT_DISTRIBUIDOR, payload: distribuidores });
            }
        }
    }));
};

export const onSearchDistribuidoresClick = () => async (dispatch, getState) => {

    let fields = [
        { 'dataKey': 'razonSocial', 'label': 'Agente', 'type': 'string', 'width': 220 }
    ];

    try {

        dispatch(openAdvancedSearch({
            autoLoad: true,
            data: [],
            fields: fields,
            multiSelect: true,
            paginated: true,
            remote: true,
            title: 'Aplicaciones Cliente',
            getData: async (params) => {
                let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`
                let response = await axios.get(encodeURI(url), { params: params });
    
                return response.data;
            },
            callback: (btn, result) => {
                dispatch(closeAdvancedSearch());
                if(btn === 'yes') {

                    let distribuidores = getState().user.entity.distribuidores.map(item => ({ ...item}));
                 
                    result.forEach(element => {
                        if(distribuidores.filter(f => f.distribuidorId === element.distribuidorId).length === 0) {
                            distribuidores.push({ distribuidorId: element.distribuidorId, razonSocial: element.razonSocial });
                        }
                    });

                    dispatch({ type: SELECT_DISTRIBUIDOR, payload: distribuidores });
                }
            }
        }));
    } catch (error) {
        if(error.response) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        }
    }
};

export const onSearchRolClick = () => async (dispatch, getState) => {
    let fields = [
        { 'dataKey': 'nombre', 'label': 'Rol', 'search': true, 'type': 'string', 'width': 175 }
    ];

    let data = Arol; //'Planeación Colombia'

    dispatch(openAdvancedSearch({
        data: data,
        fields: fields,
        title: 'Selección de Rol',
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                if(getState().user.entity.rol !== result[0].nombre) {
                    dispatch(onUpdateFieldEntity('rol', result[0].nombre));
                    dispatch(onUpdateFieldEntity('jerarquiaId', ''));
                    dispatch(onUpdateFieldEntity('dJerarquia', ''));
                }
                
            }
        }
    }));
};

export const onSearchUsuarioClick = () => async (dispatch, getState) => {
    let rol = '';

   // console.log(getState().user.entity);
   // console.log(getState().user.entity.rol);

    switch (getState().user.entity.rol) {
        case 'Vendedor':
            rol = 'Supervisor';
            break;
        case 'Supervisor' :
            rol = 'Agente';
            break;
        case 'Agente'  :
            rol = 'Jefe zona';
            break;
        case 'Agente Peru'  :
                rol = 'Jefe zona';
                break;
        case 'Jefe zona':
            rol = 'Director general';
            break;
        case 'Planeación Colombia':
            rol = 'Director general';
                break;
        case 'Precios Colombia':
            rol = 'Planeación Colombia';
            break;
        case 'Asistente de Ventas':
            rol = 'Agente';
             break;
        case 'Cobranzas':
                rol = 'Agente';
                 break;
        case 'Facturación':
            rol = 'Agente';
             break;
        case 'Mercadeo, Ctrol Int':
            rol = 'Agente';
            break;
    //---------------------------------------------------------------
        case 'EC Vendedor':
                rol = 'EC Supervisor';
                break;
        case  'EC Supervisor':
                rol = 'EC Agente';
                break;
        case  'EC Agente' :
                rol = 'EC Jefe zona';
            break;
        case  'EC Jefe zona' :
                rol = 'EC Director general';
            break;
        case  'EC Consultor Ventas' :
            rol = 'EC Supervisor';
            break;
        default:
            break;
    }

    if(rol === '') {
        return;
    }

    let fields = [
        { 'dataKey': 'nombreUsuario', 'label': 'Usuario', 'type': 'string', 'width': 110 },
        { 'dataKey': 'nombre', 'label': 'Nombres', 'search': true, 'type': 'string', 'width': 175 },
        { 'dataKey': 'correo', 'label': 'Correo', 'type': 'string', 'width': 120 },
    ];

    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de usuario',
        getData: async (params) => {
            let url = `${IdentityUrl}/api/v1/usuarios/?rol=${rol}`
            let response = await axios.get(encodeURI(url), { params: params });
         
            return response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldEntity('jerarquiaId', result[0].usuarioId));
                dispatch(onUpdateFieldEntity('dJerarquia', `${result[0].nombreUsuario} - ${result[0].nombre}`));
            }
        }
    }));
};

export const onUpdateFieldEntity = (key, value) => (dispatch) => {
    dispatch({ type: UPDATE_FIELD_ENTITY, key, value });

    if(key === 'nombre' || key === 'nombreUsuario' || key === 'correo' || key === 'distribuidor' || key === 'rol') {
        dispatch({ type: SET_ERROR, key, error: '' });
    }
};

export const onUpdateFieldFilter = (key, value) => ({ type: UPDATE_FIELD_FILTER, key, value  });

export const onUserList = () => async (dispatch, getState) => {
    if(getState().user.store.source) {
        getState().user.store.source.cancel();
    }

    let source = axios.CancelToken.source();
    let order = JSON.stringify([{
        property: getState().user.table.orderBy,
        direction: getState().user.table.order
    }]);
    dispatch({ type: REQUEST_USERS_LIST, source });
    
    try {
        // let url3 = `${IdentityUrl}/api/v1/usuarios`;
        // let response3 = await axios.get(encodeURI(url3));
        // console.log(response3,'que traes ')
      
         let url = `${IdentityUrl}/api/v1/usuarios?`
        + `nombreUsuario=${getState().user.filters.usuario}`
        + `&nombre=${getState().user.filters.nombre}`
        + `&sort=${order}&pageSize=${getState().user.store.pageSize}&start=${getState().user.store.start}`;
        let response = await axios.get(encodeURI(url), { cancelToken: source.token });
        let data = response.data;
       // console.log('DATA',)
        //console.log('DATA ORDER', order, getState().user.store.pageSize, getState().user.store.start)
        dispatch({ type: RECEIVE_USERS_LIST, total: data.total, payload: data.data });
        dispatch({ type: RECEIVE_USERS_LIST2, total: data.total, payload: data.data });
        //dispatch({ type: RECEIVE_USERS_LIST, total: data.total, payload: data });
        
        
    //    var repeticiones =  Math.trunc(data.total / 50) +1
    //    //Math.trunc(getState().user.store.total/getState().user.store.pageSize) + 1
    // //    if(repeticiones<9) repeticiones = 11 ;
    // //    console.log(repeticiones) 
    //    if(repeticiones > 0){
    //         var datatotal = []
    //        // var vacio = ''
    //         let count = 0 
    //         for(var x = 0 ; x < repeticiones ; x++){
    //             let url = `${IdentityUrl}/api/v1/usuarios?`
    //             + `nombreUsuario=${getState().user.filters.usuario}`
    //             + `&nombre=${getState().user.filters.nombre}`
    //             + `&sort=${order}&pageSize=${getState().user.store.pageSize}&start= ${count}`;
    //             let response = await axios.get(encodeURI(url), { cancelToken: source.token });
    //             let data = response.data;
    //            // console.log('DATA', data, count)
    //             count = count + 50
    //            datatotal =[...datatotal, data.data]
    //         }
    //     }
    //     let data1 = datatotal.flat()
       // console.log('data1 ', data1)
       // console.log('data ', data.data)

        //dispatch({ type: RECEIVE_USERS_LIST2, total: data.total, payload: data1 });

    } catch (error) {
        dispatch({ type: ERROR_USERS_LIST });
        if (!axios.isCancel(error)) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        }
    }
};

export const restartState = () => ({ type: RESTART_STATE });

export const setFormState = (formState, id) => (dispatch, getState) => {
    switch (formState) {
        case 'edit':
            if(id && id !== getState().user.entity.usuarioId) {
                dispatch(getUser(id));
            }
            break;
        case 'new':
            dispatch(cleanForm());
            dispatch(setPassword(generateRandomCode(8)));
            break;
        case 'restart':
            if(id && id !== getState().user.entity.usuarioId) {
                dispatch(getUser(id));
            }
            dispatch(setPassword(generateRandomCode(8)));
            break;
        case 'search':
            dispatch(cleanForm());
            break;
        case 'view':
            if(id && id !== getState().user.entity.usuarioId) {
                dispatch(getUser(id));
            }
            break;
        default:
            break;
    }
    dispatch({ type: SET_STATE_FORM, formState });
}

export const setOrder = (order, orderBy) => ({ type: SET_ORDER, order, orderBy });

export const setPage = (page) => ({ type: SET_PAGE, page });

export const setPageSize = (pageSize) => ({ type: SET_PAGE_SIZE, pageSize });

export const setPassword = (password) => ({ type: SET_PASSWORD, password });

export const setSelectedRow = (selected) => ({ type: SET_SELECTED_ROW, selected});

export const btnDelete = () => (dispatch) => {
    dispatch(openMessageBox({
        button: 'yesno',
        icon: 'question',
        message: 'Se elimanara el registro permanentemente. ¿Desea continuar?',
        callback: btn => dispatch(onDelete(btn))
    }));
};

export const btnSave = () => (dispatch, getState) => {
    if(getState().user.entity.nombre === '') {
        dispatch({ type: SET_ERROR, key: 'nombre', error: 'Este campo es requerido' })
    }

    if(getState().user.entity.nombreUsuario === '') {
        dispatch({ type: SET_ERROR, key: 'nombreUsuario', error: 'Este campo es requerido' })
    }

    if(getState().user.entity.correo === '') {
        dispatch({ type: SET_ERROR, key: 'correo', error: 'Este campo es requerido' })
    }

    if(getState().user.entity.dDistribuidor === '') {
        dispatch({ type: SET_ERROR, key: 'distribuidor', error: 'Este campo es requerido' })
    }

    if(getState().user.entity.rol === '') {
        dispatch({ type: SET_ERROR, key: 'rol', error: 'Este campo es requerido' })
    }

    if(getState().user.errors.nombre !== '' || getState().user.errors.nombreUsuario !== '' || getState().user.errors.correo !== ''
        || getState().user.errors.distribuidor !== '' || getState().user.errors.rol !== '') {
        return;
    }

    dispatch(openMessageBox({
        button: 'yesno',
        icon: 'question',
        message: '¿Desea guardar la información?',
        callback: btn => {
            if(getState().user.entity.usuarioId === '') {
                dispatch(onSave(btn));
            }
            else {
                dispatch(onUpdate(btn));
            }

        }
    }));
};

export const btnSearch = () => (dispatch) => {
    dispatch(setPage(0));
    dispatch(onUserList());
};

export const onDelete = (btn) => async (dispatch, getState) => {
    dispatch(closeMessageBox());

    if(btn === 'yes') {
        try {
            dispatch({ type: SET_MASK, loading: true });

            let usuarioId = getState().user.entity.usuarioId;
            let url = `${IdentityUrl}/api/v1/usuarios/${usuarioId}`;
            await axios.delete(encodeURI(url));

            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'info', 
                message: 'El registro se elimino correctamente.', 
                callback: () => { 
                    dispatch(closeMessageBox());
                    dispatch(push({
                        pathname: '/usuarios',
                        search: getState().router.location.search,
                        hash: getState().router.location.hash
                    }));
                    dispatch(onUserList());
                }
            }));
        } catch (error) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        } finally {
            dispatch({ type: SET_MASK, loading: false });
        }
    }
};

export const onSave = (btn) => async (dispatch, getState) => {
    dispatch(closeMessageBox());

    if(btn === 'yes') {
        try {
            dispatch({ type: SET_MASK, loading: true });

            let usuario = {
                ...getState().user.entity,
                aplicaciones: getState().user.entity.aplicaciones.map(item => {
                    return {
                        aplicacionClienteId: item.aplicacionClienteId
                    };
                }),
                distribuidores: getState().user.entity.distribuidores.map(item => {
                    return {
                        distribuidorId: item.distribuidorId
                    };
                })
            };

            let url = `${IdentityUrl}/api/v1/usuarios`;
            let response = await axios.post(url, usuario, {headers: {'Content-Type': 'application/json'}});
            let data = response.data;

            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'info', 
                message: 'Registro realizado con exito.', 
                callback: () => { 
                    dispatch(closeMessageBox()); 
                    dispatch(push({
                        pathname: `/usuarios/${data}`,
                        search: getState().router.location.search,
                        hash: getState().router.location.hash
                    }));
                }
            }));
        } catch (error) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        } finally {
            dispatch({ type: SET_MASK, loading: false });
        }
    }
};

export const onUpdate = (btn) => async (dispatch, getState) => {
    dispatch(closeMessageBox());

    if(btn === 'yes') {
        try {
            dispatch({ type: SET_MASK, loading: true });
            //console.log(getState().user.entity);
            let usuario = {
                ...getState().user.entity,
                aplicaciones: getState().user.entity.aplicaciones.map(item => {
                    return {
                        aplicacionClienteId: item.aplicacionClienteId
                    };
                }),
                distribuidores: getState().user.entity.distribuidores.map(item => {
                    return {
                        distribuidorId: item.distribuidorId
                    };
                })
            };

            if(getState().user.entity.clave !== '') {
                usuario.ReiniciarClave = true;
            }

            let url = `${IdentityUrl}/api/v1/usuarios`;
            let response = await axios.put(url, usuario, {headers: {'Content-Type': 'application/json'}});
            let data = response.data;
            console.log(data,'que traes ')

            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'info', 
                message: 'Modificación realizado con exito.', 
                callback: () => { 
                    dispatch(closeMessageBox());
                    dispatch(push({
                        pathname: `/usuarios/${data}`,
                        search: getState().router.location.search,
                        hash: getState().router.location.hash
                    }));
                    dispatch(getUser(data));
                }
            }));
        } catch (error) {
            if(error.response.status === 404) {
                dispatch(openMessageBox({ 
                    button: 'ok', 
                    icon: 'error', 
                    message: 'No se encontro el registro solicitado.', 
                    callback: () => { 
                        dispatch(closeMessageBox()); 
                        dispatch(push({
                            pathname: `/usuarios`,
                            search: getState().router.location.search,
                            hash: getState().router.location.hash
                        }));
                    }
                }));
            }
            else {
                dispatch(openMessageBox({ 
                    button: 'ok', 
                    icon: 'error', 
                    message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                    callback: () => dispatch(closeMessageBox())
                }));
            }
        } finally {
            dispatch({ type: SET_MASK, loading: false });
        }
    }
};

export const onUpdateColumn = (column) => (dispatch, getState) => {
    let columns = getState().user.table.columns.map(item => {
        if(item.dataKey !== column.dataKey) {
            return item;
        }

        return {
            ...item,
            width: column.width
        }
    });

    dispatch({ type: UPDATE_TABLE_COLUMN, columns });
};

export const actualizaSubModal = (detalle) => (dispatch, getState) =>{
    dispatch ({ type: ACTUALIZAR_SUB_MODAL, payload:detalle })
  };