/* eslint-disable react/jsx-pascal-case */
import React, { useEffect , useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { FieldSet, Table } from '@sigeco/tools';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
    setOrder,
    onUpdateColumn,
} from '../../../store/gigantes/resumen/actions';
import { sort_by} from '../../../helpers/commons'

import ResumenPresupuesto from './ResumenPresupuesto';
import ResumenPresupuesto2022 from './2022/ResumenPresupuesto2022';
import ResumenEstMonogrado from './ResumenEstMonogrado';
import LineaEstrategica2022 from './2022/LineaEstrategica2022';

import ResumenClientesNuevos from './ResumenClientesNuevos';
import ResumenCrecimiento2022 from './2022/ResumenCrecimiento2022';

import ResumenEstSuper from './ResumenEstSuper';
import ResumenCliNuevNueve from './ResumenCliNuevNueve';
import ResumenCliNuevSeisOcho from './ResumenCliNuevSeisOcho';
import PdfComponent from './PdfComponent'; 

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';


const useStyles = makeStyles(theme => ({
    // const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column'
    }, 
    tableWrap: {
        flex: '1 1 auto'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'absolute',
        width: '90%',
        fontSize :  'max(3vw, 5px)',
        '@media (min-width: 780px)' : {
            width: '50%',
            fontSize :  'max(1vw, 5px)',
        },
        
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: '20px',
    },
    bot:{
        fontSize :  'max(3vw, 5px)',
        '@media (min-width: 480px)' : {
            fontSize :  '12px',
        },
    },
    
}));

//const AprobarPedidoList = () => {
function ResumenList(props) {
    
//    class AprobarPedidoList extends Component {

    const classes = useStyles();

    const columns = useSelector(state => state.resumen.table.columns);
    const loading = useSelector(state => state.resumen.loading);
    const order = useSelector(state => state.resumen.table.order);
    const orderBy = useSelector(state => state.resumen.table.orderBy);
    const store = useSelector(state => state.resumen.store);
    const mes =   useSelector(state => state.resumen.filters.desde.getFullYear());  

    //const filters = useSelector(state => state.resumen.filters);
    const dispatch = useDispatch();
 
    const [data, setData] = useState([])

    useEffect(() => {
        setData(store.presupuesto)
        //if (store.presupuesto.length > 0 ) {setData(store.presupuesto) }    
    }, [data, store.presupuesto])

    const handleRequestSort = (property) => {
      const isAsc = orderBy === property && order === 'asc';
      dispatch(setOrder(isAsc ? 'desc' : 'asc', property));
      let homes = store.data;
      homes.sort(sort_by(property, isAsc, homes[0][property]));
  };

    const handleDoubleClick = (id) => {
        /*
        history.push({
            hash: location.hash,
            pathname: `/activacion/${id}`,
            search: location.search
        });
      ;  */
    };
//--------------------------------------------------------------------------------------------------------------------------------
    const Clicke =  (item) => {
      //  console.log("item "+item);
      //dispatch(detallePedido(item[0]));  //mllama al detalle
      //setTimeout(() => {  console.log("World!"); toggletrueFalse();  }, 1500);
 
       ///activa
    };
//--------------------------------------------------------
    return (
        <>
        <FieldSet className={classes.root} iconCls="fas fa-th-list" title="Resultados">
               <div style={{ display: 'none' }}>
                <ReactHTMLTableToExcel
                    id="table-xls-button-rpt-pedidos"
                    className=""
                    table="table-to-xls"
                    filename="Rpt_pedidos"
                    sheet="Pedidos"
                    buttonText=""
                />
                </div> 
                
                <div   style={{display: loading ? 'block': 'none' }}>
                    <img alt='uno' style={{ display: 'block',  margin: '10px auto'}}
                            src="https://app.iess.gob.ec/iess-fondo-reserva-afiliado-web/javax.faces.resource/images/load.gif.jsf;jsessionid=8949b76cfea43d283add3bd3dd2d?ln=sentinel-layout"  width="40" height="40"/>

                </div>  

                <div className={classes.tableWrap} style={{display: store.presupuesto.length > 0 ? 'none': 'block'}}> 
                 
                                     <Table 
                                         columns={columns}
                                         count={store.total}
                                         loading={loading}
                                         onRowClick={items => Clicke(items)}
                                         onRowDoubleClick={item => handleDoubleClick(item.activacionId)}
                                         onSort={datakey => handleRequestSort(datakey)}
                                         onUpdateColumn={() => dispatch(onUpdateColumn())}
                                         rowCount={store.data.length}
                                         rowGetter={index => store.data[index]}
                                         selected={store.selected}
                                         sortBy={orderBy}
                                         sortDirection={order}
                                         noRowsRenderer={() => (<div>hola</div>)}
                                     />
         </div>           
        </FieldSet> 
    
    <div   style={{display: store.presupuesto.length > 0  && !loading ? 'block': 'none'}}>     
   
        <Grid container spacing={0}>
                    <Grid item xs={12} md={12}>   
                                {mes <= 2021  ?  <ResumenPresupuesto/> : <ResumenPresupuesto2022/>} 
                                {mes <= 2020  ?  <PdfComponent/> :  null} 
                    </Grid>
        </Grid>

        <Grid container spacing={2}   >
           {mes <= 2021  &&
                   <>        
                    <Grid item xs={12} md={6}>  
                             <ResumenEstMonogrado/>  
                    </Grid>

                    <Grid item xs={12} md={6}>|
                            <ResumenEstSuper/>
                    </Grid>
                 </>  
            }
 
             {mes === 2022  &&     
                    <Grid item xs={12} md={12}>  
                               <LineaEstrategica2022/>
                    </Grid>
            }
       </Grid>
      
       <Grid container spacing={2}>       
            {mes <= 2021  &&
            <>
              <Grid item xs={12} md={6}>
                         <ResumenClientesNuevos/> 
              </Grid>
              <Grid item xs={12} md={6}>
                         <ResumenCliNuevSeisOcho/>                          
              </Grid> 
             </> 
            }  

            {mes === 2022  &&     
                    <Grid item xs={12} md={12}>  
                               <ResumenCrecimiento2022/>
                               {/* no :  <ResumenCliNuevSeisOcho2022/> }
                               */}
                    </Grid>
            }                   
            
       </Grid>
       
       <Grid item xs={12} md={12}>
                        {mes <= 2021 ?       <ResumenCliNuevNueve/> : null }
      </Grid>
            
            <Grid item xs={12} md={12}  > 
                        <div className="container mt-5">
                        {store.presupuesto.length > 0  && <PdfComponent/> }                       
                        </div>       
            </Grid>  
        
    </div>             

                <Box sx={{ flexGrow: 1 }}>
                </Box>
    </>
    )
}; 

export default ResumenList;
