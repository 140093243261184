import React, { useState,  useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { FieldSet, Table } from "@sigeco/tools";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  actualiza,
  detallePedido,
  setOrder,
  onUpdateColumnPase,
  setSelectedRow,
  btnSearchHistorico,
  btnSearchPorCobrar ,
  HandleShows,
  btnApprove,
  onUpdateInitial
} from "../../../store/movilidad/bandejaprobacion/actions";
import {
  RECEIVE_OLAS_PORCOBRAR,
  RECEIVE_HISTORICO_DETAIL
} from "../../../store/movilidad/bandejaprobacion/types"
import { sort_by} from '../../../helpers/commons'
import DetallePedido from './DetallePedido';
import HistoricoCobranza from './HistoricoCobranza';
import EstadodeCuenta from './EstadodeCuenta';

import TextField from "@material-ui/core/TextField";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import { Footer, Page } from '@sigeco/tools';
import Icon from '@material-ui/core/Icon';


import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Box from '@material-ui/core/Box';

const state = {
  eload: false,
  name: "",
  modalInputName: "",
  currentTab: 0,
};
const tabHeight = '20px'

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
  },
  tableWrap: {
    flex: "1 1 auto",
  },
  tabsRoot: {
    minHeight: tabHeight,
    height: tabHeight
  },
  tabRoot: {
    minHeight: tabHeight,
    height: tabHeight

  },
  modal: {
    top: "50%",
    left: "50%",
    transform: 'translate(-50%, -50%)',
    position: "absolute",
    maxHeight: 800,
    maxWidth: 900,
    width: "80%",
    display: "block",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1,0, 1, 2),
    alignItems: "center",
    justifyContent: "center",
    fontSize: "max(1vw, 5px)",
    borderRadius: "15px",
  },
  bot: {
    marginLeft: "10px",
    marginTop:"5px",
     },
  boxcito: {
     overflow:"hidden",
    "@media (max-width: 599px)": {
      overflow: "auto",
      marginLeft: "-20px"
    },
  }
}));

const updateInputValue = (evt) => {
  state.modalInputName = evt.target.value;
};

function BandejAprobacionPasePedidoList(props) {
  const classes = useStyles();
  const [modalInfo, setModalInfo] = useState([]);
  const [, setShowModal] = useState(false); //e
  const [show, setShow] = useState(false);  //e
  const HandleClose = () => setShow(false);
  const HandleShow = () => setShow(true);
  const [, setmotivoRechazo] = useState("");

  /*------Estados que Abren los distitos Dialogs-----------*/
  const [ropen, setrOpen] = useState(false);
  const [tip, setTip] = useState("T");

  /*----Manipulación de oredenamiento de la Table------------*/
  const columns = useSelector((state) => state.bandejaaprobacion.tablePase.columns);
  const loading = useSelector((state) => state.bandejaaprobacion.loading);
  const order = useSelector((state) => state.bandejaaprobacion.tablePase.order);
  const orderBy = useSelector((state) => state.bandejaaprobacion.tablePase.orderBy);
  const store = useSelector((state) => state.bandejaaprobacion.store);
  const show2 = useSelector((state) => state.bandejaaprobacion.show);
  const color = useSelector((state)=> state.bandejaaprobacion.filters.color)

  const dispatch = useDispatch();

  const [rechazo, ] = useState();
  const [value, setValue] = useState(0);


  /*---Manipulacion del tamaño del Modal segun el Tab Elegido * */

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    dispatch(setOrder(isAsc ? "desc" : "asc", property,'tablePase'));
    let homes = store.dataPase;
    homes.sort(sort_by(property, isAsc, homes[0][property]));
  };

  useEffect(()=>{
    setShow(show2)
  },[show2])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleBuscar = () => {
   console.log(store.selected)
   dispatch(btnSearchHistorico(store.selectedPase[0]))
};

const handleBuscarPorCobrar = () => {
  console.log(store.selected)
  dispatch(btnSearchPorCobrar (store.selectedPase[0]))
  
};

  //---------Envia La Fila de la Tabla--
  const Clicke = (item) => {
   // dispatch(onUpdateFieldFilter("clienteId", `${item.ClienteId}`))
   // console.log(item)
    let items= []
    items.push(item)
    dispatch(onUpdateInitial);
    dispatch(detallePedido(items[0]));
    setModalInfo(items[0]);
    setShowModal(HandleShow);
    dispatch(setSelectedRow(items, 'selectedPase'));
    dispatch({type:  RECEIVE_OLAS_PORCOBRAR,  columns: [],   payload: [] });
    dispatch({type:  RECEIVE_HISTORICO_DETAIL,  columns:  [], payload: [],  });
    dispatch(HandleShows(true));

  };
  //--------Elasticidad de las columnas--
  const handleResizeColumnPase = (column) => {
    dispatch(onUpdateColumnPase(column));
  };

  const handleDialogOpen = (tipo) => () => {
    //abre dialogo
    setTip(tipo);
    if (tipo === "R") {
      state.modalInputName = "";
      setmotivoRechazo("");
      setrOpen(true);
    }
  };

  //--------Cierre de los Dialogs
  const handleClose = (tipo) => () => {
    
    if (tipo === 4) {
      setrOpen(false);
    }
  };

  //-----Funcion que Despacha la acción y función estado que controla todo
  
  const handleSave = () => {
    if (state.modalInputName === "") {
      return;
    }
    setmotivoRechazo(state.modalInputName);

    dispatch(actualiza(modalInfo.PedidoId, tip, state.modalInputName, 'PED')); //cambia estado
    setrOpen(false);
    HandleClose();
  };

  const BottonDetalle = () => {
   return (
    <Page>
      {modalInfo.Estado === "P" ? (
        <Footer>
          <Button
             //variant="outlined"
             variant="contained"
             size="small"
             onClick={() =>{dispatch(btnApprove())}}
             startIcon={<Icon className ="fa fa-check"/>}
            //className={classes.bot}
            color="primary"
          >
            Aprobar
          </Button>
          <Button
            //variant="outlined"
            variant="contained"
            size="small"
            onClick={handleDialogOpen("R")}
            color="primary"
            startIcon={<Icon className ="fas fa-ban"/>}
          >
            Rechazar
          </Button>
          <Button
            //variant="outlined"
            variant="contained"
            size="small"
            onClick={()=>dispatch(HandleShows(false))}
            color="primary"
            startIcon={<Icon className="fas fa-door-open"/>}
          >
            Cerrar
          </Button>
       
</Footer>
      ) : (
        <Footer>
        <Button
          variant="contained"
          //variant="outlined"
          color="primary"
          size="small"
          onClick={()=>dispatch(HandleShows(false))}
          startIcon={<Icon className="fas fa-door-open"/>}
        >
          Cerrar
        </Button>
        </Footer>
      )}
    </Page>
  );
  
  }; /* boton */

  // ********MANEJAR TAMANO DE MODAL ********************
  const datad = useSelector((state) => state.bandejaaprobacion.store.detalle);
  const dataCobranza = useSelector((state) => state.bandejaaprobacion.store.detallecobranza);
  const dataPorCobrar = useSelector((state) => state.bandejaaprobacion.store.porcobrar);
  const [dataModalTamaño , setDataModalTamaño] = useState(0);

  useEffect(() => {
    setDataModalTamaño(datad.length)
  }, [datad,dataModalTamaño])

useEffect(() => {
  if(value === 1){
    setDataModalTamaño(dataCobranza.length)
  }else{
    setDataModalTamaño(dataPorCobrar.length)
  }
}, [dataCobranza, dataPorCobrar, value,dataModalTamaño])

  const ModalContent = () => {
    return (
      <div>
       <Modal
          fullscreen={"md"}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={show}
          onClose={HandleClose}
        >
        <div  style={{display: 'flex', flex: '1', maxHeight: '370px' }}>
          <div   className={classes.modal} style={{ height: dataModalTamaño.length === 1 ? '150px': dataModalTamaño.length === 2 || dataModalTamaño.length === 3 ? '250px': dataModalTamaño.length === 5 ? '250px':'360px'}}>

            <Tabs  classes={{ root: classes.tabsRoot   }} style={{fontSize: 24}}
                    value={value}
                    onChange={handleChange}
            >
              <Tab  label={`Detalle `} classes={{ root: classes.tabRoot }}
              style={{ textTransform: "none",  backgroundColor: value === 0 ? "#C9C9C9" : "" }}/>
              <Tab label= {`Histórico`} classes={{ root: classes.tabRoot }}
              style={{ textTransform: "none",  backgroundColor: value === 1 ? "#C9C9C9" : "" }}/>
              <Tab label= {`Estado de Cuenta`} classes={{ root: classes.tabRoot }}
              style={{ textTransform: "none",  backgroundColor: value === 2 ? "#C9C9C9" : "" }}/>
             
            </Tabs>
            <Box sx={{ margin: 1,  height:'96%'}} className={classes.boxcito}>
              {value === 0 &&  ( <DetallePedido />  )} 
              {value === 1 && ( <HistoricoCobranza/>    )} 
              {value === 2 && ( <EstadodeCuenta/>  )} 
            
            <footer  style={{
                  backgroundcolor: 'red',
                  position: 'absolute',
                  bottom: 5,
                  width: '100%',
                  height: '40px',
                  color: 'white'  }}  >
              <div className="container"   style= {{ position: 'relative', float: 'right', liststyletype: 'none',  right: '6%', display: 'inline-block'}}>
                  {!loading && value === 0 &&  (
                 <BottonDetalle/>
                  )}
          
                  {!loading && value === 1 &&  (
                    <Page>
                      <Footer>
                      <Button 
                        variant="contained"
                        onClick={handleBuscar}
                        className={classes.bot}
                        color="primary"
                        size="small"
                        startIcon={<Icon className="fas fa-search"/>}
                      >
                      Buscar
                      </Button>
                      <Button 
                        variant="contained"
                        //onClick={HandleClose}
                        onClick={()=>dispatch(HandleShows(false))}
                        className={classes.bot}
                        color="primary"
                        size="small"
                        startIcon={<Icon className="fas fa-door-open"/>}

                      >
                        Cerrar
                      </Button>
                      </Footer>
                    </Page>
                  )}
                  {!loading && value === 2 &&  (
                    <Page>
                      <Footer>
                      <Button 
                        variant="contained"
                        onClick={handleBuscarPorCobrar}
                        className={classes.bot}
                        color="primary"
                        size="small"
                        startIcon={<Icon className="fas fa-search"/>}
                      >
                      Buscar
                      </Button>
                      <Button 
                        variant="contained"
                        //onClick={HandleClose}
                        onClick={()=>dispatch(HandleShows(false))}
                        className={classes.bot}
                        color="primary"
                        size="small"
                        startIcon={<Icon className="fas fa-door-open"/>}

                      >
                        Cerrar
                      </Button>
                      </Footer>
                    </Page>
                  )}
              </div>
            </footer>                     
            </Box>

          </div>
        </div>

      </Modal>
       </div>
    );
  }; /* modal content */


  const Rechazo = () => {
    // ESTE ES EL MODAL DE RECHAZO DE PEDIDO
    return (
      <Dialog
        open={ropen}
        fullWidth
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Rechazar</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Motivo rechazo"
            multiline
            fullWidth
            value={rechazo}
            onChange={(evt) => updateInputValue(evt)}
            inputProps={{
              maxLength: 200,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={handleClose(4)} size="small">
            Cancelar
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={(e) => handleSave(e)}
            size="small"
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }; /* dialog Rechazo */

  return (
    <FieldSet
      className={classes.root}
      iconCls="fas fa-th-list"
      title="Resultados"
    >
      <div style={{ display: "none" }}>
        <ReactHTMLTableToExcel
          id="table-xls-button-rpt-pedidos"
          className=""
          table="table-to-xls"
          filename="Rpt_pedidos"
          sheet="Pedidos"
          buttonText=""
        />
      </div>

      <div className={classes.tableWrap}>
        <Table
          columns={columns}
          count={store.total}
          loading={loading}
          onRowClick={(items) =>{}}
          onRowDoubleClick={(items) => Clicke(items)}
          onSort={(datakey) => handleRequestSort(datakey)}
          onUpdateColumn={handleResizeColumnPase}
          rowCount={store.dataPase.length}
          rowGetter={(index) => store.dataPase[index]}
          selected={store.selectedPase}
          sortBy={orderBy}
          sortDirection={order}
          rowColor = {color}
          sx = {{backgroundColor: 'yellow'}}
          noRowsRenderer={() => <div style= {{background : 'red'}}>'Alu'</div>}
        />
      </div>
      <div className="App" >{show ? <ModalContent /> : null}</div>
      <div>{<Rechazo />}</div>
    </FieldSet>
  );
}

export default BandejAprobacionPasePedidoList;
