import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    onExport,
    btnSearch,
} from '../../store/clientes/clientesvendedor/actions';

import { Body, Footer, Page } from '@sigeco/tools';
import AppBar from '@material-ui/core/AppBar';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import ClientesVendedorFilter from '../../components/clientes/clientesvendedor/ClientesVendedorFilter';
import ClientesVendedorList from '../../components/clientes/clientesvendedor/ClientesVendedorList';
import ClientesVendedorMap from '../../components/clientes/clientesvendedor/ClientesVendedorMap';

const ClientesVendedor = ({onClose, setTitle}) => {
    const formState = useSelector(state => state.rutas.formState);
    const loading = useSelector(state => state.rutas.loading);
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);

    function onBtnClose() {
        onClose();
    }

    function onMapOpenClick(dVendedor) {
        setOpen(true);
    }

    return (
        <Page>
            <Backdrop style={{ position: 'absolute', zIndex: 1200 }} open={loading} transitionDuration={0}>
                 <CircularProgress color="inherit" />
            </Backdrop>
        <Body>
            {formState === 'search' && <ClientesVendedorFilter />}
            {formState === 'search' && <ClientesVendedorList />}
        </Body>
        <Footer>
            {formState === 'search' && <Button
                color="primary" 
                size="small" 
                variant="outlined" 
                onClick={() => dispatch(onExport())}
                startIcon={<Icon className="fas fa-file-excel"/>}
            >
                Exportar
            </Button>}
            {formState === 'search' && <Button
                color="primary" 
                size="small" 
                variant="outlined"
                onClick={() => dispatch(btnSearch())}
                startIcon={<Icon className="fas fa-search"/>}
            >
                Buscar
            </Button>}
            <Button
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={() => onMapOpenClick('')}
                    startIcon={<Icon className="fas fa-map-marked-alt"/>}
                >
                    Ver Mapa
                </Button>
            <Button 
                color="primary" 
                size="small" 
                variant="outlined" 
                onClick={() => onBtnClose()} 
                startIcon={<Icon className="fas fa-door-open"/>}
            >
                Salir
            </Button>
        </Footer>
        <Dialog fullScreen open={open} onClose={() => setOpen(false)}>
                <AppBar>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => setOpen(false)} aria-label="close">
                            <Icon className="fas fa-times" />
                        </IconButton>
                        <Typography variant="h6">
                            Localización de visitas 
                        </Typography>
                    </Toolbar>
                </AppBar>
                <ClientesVendedorMap />
            </Dialog>
        </Page>
    )
};

export default ClientesVendedor;