import axios from 'axios';
import { IdentityUrl } from '../config';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";

import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Error from '../pages/Error';
import Header from '../components/main/Header';
import Unauthorized from '../pages/Unauthorized';
import { addOptionNav, setOption } from '../store/main/actions';

const useStyles = makeStyles(theme => ({
    form: {
        backgroundColor: '#EAEAEA', 
        boxSizing: 'border-box',
        flexDirection: 'column',
        display: 'flex',
        height: '100%',
        position: 'relative'
    },
    header: {
        padding: '.8125rem .8125rem 0',
        '@media (min-width: 751px)': {
            padding: '1.5rem 1.5rem 0'
        }
    },
    root: {
        alignItems: 'center',
        color: theme.palette.primary.main,
        display: 'flex',
        justifyContent: 'center',
        height: 'calc(100% - 4px)'
    },
    separator: {
        borderBottom: '1px solid #d7d7d7',
        marginBottom: '.625rem',
        '@media (min-width: 751px)': {
            marginBottom: '.875rem'
        }
    },
    title: {
        color: '#3a3a3a', 
        fontSize: '1.25rem',
        fontWeight: 500,
        marginBottom: '.5rem',
        '@media (min-width: 751px)': {
            fontSize: '1.5rem',
            marginBottom: '.75rem',
        }
    }
}));

const Form = ({ component, path }) => {
    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const navs = useSelector(state => state.main.navs);
    const [access, setAccess] = useState({});
    const [authorized, setAuthorized] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [title, setTitle] = useState('');
    const Component = component;

    useEffect(() => {
        checkAccess();
    // eslint-disable-next-line
    }, []);

    async function checkAccess() {
        try {
            if(navs.filter(n => n.path === path)[0]) {
                let accesos = {};

                navs.filter(n => n.path === path)[0].access.forEach(e => {
                    accesos[e.Nombre] = true;
                });

                setAccess(accesos);
                setAuthorized(true);
                setLoading(false);
                dispatch(setOption(path));
            }
            else {
                let url = `${IdentityUrl}/api/v1/account/opciones/acceso?ruta=${path}`;
                let response = await axios.get(url);
                let data = response.data;

                if(data.status === 400) {
                    setLoading(false);
                    setError('No se encontro la opción solicitada, comuniquese con el administrador.');
                }
                else if(data.status === 401) {
                    setLoading(false);
                }
                else {
                    let accesos = {}, icon = data.option.icon || ' ';
                    
                    data.option.access = [];
                    data.option.access.forEach(e => {
                        accesos[e.Nombre] = true;
                    });

                    dispatch(addOptionNav(data.option.optionId, data.option.name, path, icon, data.option.access));
                    setAccess(accesos);
                    setAuthorized(true);
                    setLoading(false);
                }
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
            setError('No se pudo verificar los accesos.');
        }
    }

    function handleClose() {
        history.push({
            hash: location.hash,
            pathname: `/`,
            search: location.search
        });
    }

    if(loading) {
        return(
            <div className={classes.root} >
                <div style={{ textAlign: 'center' }}>
                    <CircularProgress color="primary"/>
                    <p>Cargando formulario, espere por favor.</p>
                </div>
            </div>
        )
    }

    if(error !== '') {
        return <Error error={error} />
    }

    if(!authorized) {
        return <Unauthorized />
    }

    return (
        <div className={classes.form}>
            <Header
                iconCls={navs.filter(n => n.path === path)[0].icon} 
                subtitle={navs.filter(n => n.path === path)[0].name} 
                title={title}
            />
            <Component access={access} onClose={handleClose} setTitle={setTitle}/>
        </div>
    );
}

export default Form;