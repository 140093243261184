import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    btnSearch,
    onExport,
    onImport,
} from "../../store/fuerzaventas/cargarmiespacio/actions";

import { Body, Footer, Page } from "@sigeco/tools";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Icon from "@material-ui/core/Icon";

import CargaMiEspacioFilter from "../../components/fuerzaventas/cargarmiespacio/CargarMiEspacioFilter";
import CargaMiEspacioList from "../../components/fuerzaventas/cargarmiespacio/CargarMiEspacioList";

const CargarMiEspacio = ({ onClose, setTitle }) => {
    const loading = useSelector((state) => state.cargarmiespacio.loading);
    const store = useSelector( (state) => state.cargarmiespacio.filters.band);
    const dispatch = useDispatch();

    useEffect(() => {
        setTitle("Cargar Archivos");
        // eslint-disable-next-line
    }, []);

    function onBtnClose() {
        onClose();
    }

    return (
        <Page>
            <Backdrop
                style={{ position: "absolute", zIndex: 1200 }}
                open={loading}
                transitionDuration={0}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Body>
                <CargaMiEspacioFilter />
                <CargaMiEspacioList />
               
            </Body>
            <Footer>
                {store === true && (
                    <Button
                        color="primary"
                        size="small"
                        variant="outlined"
                        onClick={() => dispatch(onExport())}
                        startIcon={<Icon className="fas fa-file-excel" />}
                    >
                        Exportar
                    </Button>
                )}
                {store === true && (
                    <Button
                        color="primary"
                        size="small"
                        variant="outlined"
                        onClick={() => dispatch(btnSearch())}
                        startIcon={<Icon className="fas fa-search" />}
                    >
                        Buscar
                    </Button>
                )}
                {store === false && (
                    <Button
                        color="primary"
                        size="small"
                        variant="outlined"
                        onClick={() => dispatch(onImport())}
                        startIcon={<Icon className="fas fa-file-excel" />}
                    >
                        Importar 
                    </Button>
                )}
                <Button
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={() => onBtnClose()}
                    startIcon={<Icon className="fas fa-door-open" />}
                >
                    Salir
                </Button>
            </Footer>
        </Page>
    );
};

export default CargarMiEspacio;
