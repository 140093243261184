import React, { useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import makeStyles from "@material-ui/core/styles/makeStyles";

import { topTitle , aligTitle , topSubTitle , aligSubTitle, sizeTitle, sizeSubTitle } from '../../../config';


am4core.useTheme(am4themes_material);
am4core.useTheme(am4themes_animated);

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
    padding: "1.5rem ",
  },
  wrap: {
    flex: '1 1 auto',
    "@media (max-width: 320px)": {
      width:"290% !important",
      overflowY:"hidden",
      marginLeft: "20px"
    },
    "@media (max-width: 375px)": {
      width:"290% !important",
      overflowY:"hidden",
      marginLeft: "20px"
    }
}
}));

const ReporteConstruccionLob = () => {
  const classes = useStyles();
  const data = useSelector((state) => state.reporteconstruccion.data);
  const agente = useSelector(state => state.reporteconstruccion.filters.dDistribuidor);
  const ola = useSelector(state => state.reporteconstruccion.filters.dOla);
  const cuentaPdv = useSelector(state => state.reporteconstruccion.summary.cuentaPDV);
  
  const data3 = [...new Set(data)];

    useLayoutEffect(() => {
    var container = am4core.create("chartdiv", am4core.Container);
    var chart = container.createChild(am4charts.PieChart);
    chart.height = 30;

    container.width = am4core.percent(100);
    container.height = am4core.percent(100);
    container.layout = "vertical";

    
    
    var titulo = chart.titles.create();
    var title = chart.titles.create();
    
    if(agente.length > 0)
    {
      title.text = "Cobertura LOB /Agente: " + agente + ' - Ola: ' + ola
      titulo.text =  "Total Puntos Actuales:  " + Intl.NumberFormat('es-MX').format(cuentaPdv)+ " Pdv";
    }
    else 
    {
      //title.text =  "Total Puntos Actuales:  " + Intl.NumberFormat('es-MX').format(cuentaPdv)+ " Pdv";
      titulo.text = "Cobertura LOB /Agente: Todos - Ola: " + ola;

    }

    titulo.fontWeight = 600;
    titulo.fontSize = 22;
    titulo.align = "center";

    title.fontWeight = 600;
    title.fontSize = sizeTitle;
    title.align = aligTitle;
    title.marginTop = topTitle ;


    
    if (data.length > 0) {
      container.exporting.menu = new am4core.ExportMenu();
      container.exporting.menu.align = "left";
      container.exporting.menu.verticalAlign = "top";
      container.exporting.menu.items = [
        {
          label: "...",
          menu: [
            {
              label: "Imagen",
              menu: [
                { type: "png", label: "PNG" },
                { type: "jpg", label: "JPG" },
                { type: "svg", label: "SVG" },
                { type: "pdf", label: "PDF" },
              ],
            },
            {
              label: "Imprimir",
              type: "print",
            },
          ],
        },
      ];
    }

     _(data)
    .orderBy("dLob")
    .groupBy("dLob")
    .map((item, key) =>    {
      let dataLob = getDataByLob(key)
      //console.log(dataLob[0].pdv)
      return dataLob[0].pdv && createChart(container, key, getDataByLob(key)) //lo crea de form dinamica
    })
    .value();
       
    return () => {
      container.dispose();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const createChart = (container, titleText, data) => {
    if (data.length === 0) {return; }
    
    const summary = {
      galones: _.sumBy(data, "pdv"),
    };

    am4core.useTheme(am4themes_animated);
    var chart = container.createChild(am4charts.PieChart);
    chart.height = 400;
    
    var title2 = chart.titles.create();
    title2.text = "Puntos Actuales:  " + Intl.NumberFormat("es-MX").format(summary.galones) + " Pdv";
    title2.fontWeight = 600;
    title2.fontSize = sizeSubTitle;
    title2.align =  aligSubTitle;
    title2.marginTop = topSubTitle ;
    title2.color ="rgb(" + Math.floor(Math.random() * 156) + "," + Math.floor(Math.random() * 130) + "," + Math.floor(Math.random() * 256) +")";

    var title = chart.titles.create();
    title.text = titleText;
    title.fontWeight = 600;
    title.fontSize = sizeTitle;
    title.align = aligTitle;
    title.marginTop = topTitle * 3 ;
   
    //title.marginTop = -30;

    chart.data = data.length > 0 ? data.sort((a, b) => b.pdv - a.pdv) : [];
    chart.innerRadius = am4core.percent(40);
    // chart.startAngle = 0;
    // chart.endAngle = 270;
    // chart.marginBottom = 40;

    var series = chart.series.push(new am4charts.PieSeries());
    series.dataFields.value = "pdv";
    series.dataFields.category = "marca";
    series.slices.template.stroke = am4core.color("#fff");
    series.slices.template.strokeWidth = 2;
    series.slices.template.strokeOpacity = 1;
    series.slices.template.states.getKey("hover").properties.shiftRadius = 0;
    series.slices.template.states.getKey("hover").properties.scale = 1.1;
    series.slices.template.propertyFields.fill = "colorHex";
    series.legendSettings.itemValueText = "{valueY.percent}";//em
    
    
    series.labels.template.text = "{value.percent.formatNumber('#.0')}%";
    series.labels.template.radius = 10;
    series.labels.template.align= "center";
    series.labels.template.fill = am4core.color("black");

    series.legendSettings.itemValueText = "{valueY.percent}";//em
    



    // Add a legend
      chart.legend = new am4charts.Legend();
      chart.legend.position = "right";
      chart.legend.align = "right";
      chart.legend.contentAlign = "right";
      chart.legend.fontSize = 11;
      chart.legend.maxWidth = 200;
      chart.legend.scrollable = true;
  };

  const getDataByLob = (lob) => {
    return _(data.filter((f) => f.dLob === lob))
      .orderBy("orden")
      .groupBy("dSegmento")
      .map((item, key) => ({
        marca: key,
        pdv: _.sumBy(item, "cuentaPDV"),
        galonesmercado: _.sumBy(item, "galonesMercado"),
        galones: _.sumBy(item, "galones"),
      }))
      .value();
  };

  return (
      <div className={classes.root}>
        {/* <div style={{overflowY: data3.length >=4 ?"hidden" : "none" }}>  */}
        <div style={{ height: data3.length * 820, width: 1000}} className={classes.wrap} id="chartdiv">
        </div>  
        {/* </div> */}
      </div>
  );
};

export default ReporteConstruccionLob;
