import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  onSearchDistribuidorClick,
  onUpdateFieldFilter,
  onSearchListaClick,
  onSearchTipoFormatoClick,
  onUpdateFieldEntity
} from "../../../store/fuerzaventas/cargarmiespacio/actions";

import { ActionField, FieldSet } from "@sigeco/tools";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FormControl from "@material-ui/core/FormControl";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Checkbox from '@material-ui/core/Checkbox';
import { Icon } from "@material-ui/core";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(() => ({
  content: {
    padding: "0.5rem 0.75rem 0.75rem",
  },
  filterClear: {
    display: "flex",
  },
  actionField: {
    flexDirection: "row",
    position: "relative",
  },
  btnClear: {
    position: "absolute",
    bottom: "1.5",
    right:20,
    maxWidth: "15px",
    maxHeight: "23px",
    minWidth: "15px",
    minHeight: "23px",
  },
  root: {
    flex: "0 0 auto",
    marginBottom: "0.75rem",
    "@media (min-width: 1401px)": {
      marginBottom: "1rem",
    },
  },
}));

const CargarMiEspacioFilter = () => {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:701px)");
  const filters = useSelector((state) => state.cargarmiespacio.filters);
  const entity = useSelector((state) => state.cargarmiespacio.entity);
  const dispatch = useDispatch();
  const label = 'Fecha Limite'

  //console.log("fecha: ",typeof filters.hasta)

  //Botones de limpiar filtros
  const [, setDistribuidor] = useState("");
  const [btn, setBtn] = useState(true);

  const [, setCategoria] = useState("");
  const [btn1, setBtn1] = useState(true);

  const [, setName] = useState("");
  const [btn2, setBtn2] = useState(true);

  const [, setTipoFormato] = useState("");
  const [btn3, setBtn3] = useState(true);

  useEffect(() => {
    if (filters.dDistribuidor) {
      setDistribuidor(filters.dDistribuidor);
      setBtn(false);
    } else {
      setBtn(true);
    }
  }, [filters.dDistribuidor]);

  useEffect(() => {
    if (filters.dLista) {
      setCategoria(filters.dLista);
      setBtn1(false);
    } else {
      setBtn1(true);
    }
  }, [filters.dLista]);

  useEffect(() => {
    if (filters.nombre) {
      setName(filters.nombre);
      setBtn2(false);
    } else {
      setBtn2(true);
    }
  }, [filters.nombre]);

  useEffect(() => {
    if (filters.dTipo) {
      setTipoFormato(filters.dTipo);
      setBtn3(false);
    } else {
      setBtn3(true);
    }
  }, [filters.dTipo]);

  const handleDist = () => {
    setDistribuidor("");
    setName("");
    filters.distribuidorId = 0;
    filters.dDistribuidor = "";

    filters.listaId = 0;
    filters.dLista = '';

    filters.tipoId = 0;
    filters.dTipo = '';

    filters.nombre= '';
    
    setBtn(true);
    setBtn1(true);
    setBtn2(true);
    setBtn3(true);

  };

  const handleCategoriaArchivo = () => {
    setCategoria("");
    filters.dLista = '';
    filters.listaId = 0;
    setBtn1(true);
  };

  const handleName = () => {
    setName("");
    filters.nombre= '';
    setBtn2(true);
  };

  const handleTipoFormato = () => {
    setTipoFormato("");
    filters.dTipo = '';
    filters.tipoId = 0;
    setBtn3(true);
  };



  function onFieldChange(event) {
    dispatch(onUpdateFieldFilter(event.target.name, event.target.value));
  }

  function onFieldChange2(event) {
    dispatch(onUpdateFieldEntity(event.target.name, event.target.value));
  }
  const onCheckBoxChange = event => {
    dispatch(onUpdateFieldFilter(event.target.name, event.target.checked));
  }

  //console.log(filters.promocion)
  return (
    <div className={classes.root}>
      <FieldSet iconCls="fas fa-file-alt" title="Información" defaultExpanded>
        <Grid className={classes.content} container spacing={2}>
         <Grid container item xs={12} spacing={2}>
            <Grid item xs={7} sm={2} md={3} lg={3}>
              <FormControl className={classes.filterClear}>
                <ActionField
                  className={classes.actionField}
                  label="Agente"
                  placeholder="Enter para buscar un Agente"
                  value={filters.dDistribuidor}
                  variant={matches ? "outlined" : "standard"}
                  onHandleAction={() => dispatch(onSearchDistribuidorClick())}
                />
                <Button
                  className={classes.btnClear}
                  size="small"
                  startIcon={<Icon className="fa fa-window-close" />}
                  disabled={btn}
                  onClick={handleDist}
                />
              </FormControl>
            </Grid>

            <Grid item xs={7} sm={2} md={3} lg={2}>
              <FormControl className={classes.filterClear}>
                <ActionField
                  className={classes.actionField}
                  label="Lista"
                  placeholder="Enter para selecionar una Categoria"
                  value={filters.dLista}
                  variant={matches ? "outlined" : "standard"}
                  onHandleAction={() => dispatch(onSearchListaClick())}
                />
                <Button
                  className={classes.btnClear}
                  size="small"
                  startIcon={<Icon className="fa fa-window-close" />}
                  disabled={btn1}
                  onClick={handleCategoriaArchivo}
                />
              </FormControl>
            </Grid>

          <Grid item xs={7} sm={3} md={3} lg={7}>
            <Grid item xs={matches ? 4 : 4} container justifyContent={matches ? "flex-end" : "center"}>
              <FormControl fullWidth sx={{ m: 1 }}>
                <TextField
                  fullWidth
                  id="outlined-multiline-static"
                  label="Tag"
                  name="tag"
                  onChange={onFieldChange2}
                  required
                  //multiline
                  //minRows={7}
                  value={entity.tag}
                  variant={matches ? "outlined" : "standard"}
                />
              </FormControl>
            </Grid>
        </Grid>

            {/* <Grid item xs={5} sm={4} md={3} lg={3}>
              <FormControl className={classes.filterClear}>
                <ActionField
                  className={classes.actionField}
                  label="Tipo de Formato"
                  placeholder="Enter para seleccionar el tipo de formato"
                  value={filters.dTipo}
                  variant={matches ? "outlined" : "standard"}
                  onHandleAction={() => dispatch(onSearchTipoFormatoClick())}
                />
                <Button
                  className={classes.btnClear}
                  size="small"
                  startIcon={<Icon className="fa fa-window-close" />}
                  disabled={btn3}
                  onClick={handleTipoFormato}
                />
              </FormControl>
            </Grid> */}



            {/* <Grid item xs={matches ? 3 : 4} container spacing={2}></Grid> */}
          </Grid>

          {/* <Grid item xs={12} container>
            <Grid item xs={matches ? 4 : 6}>
              <FormControl className={classes.filterClear}>
                <ActionField
                  className={classes.actionField}
                  label="Lista"
                  placeholder="Enter para buscar"
                  value={filters.dLista}
                  variant={matches ? "outlined" : "standard"}
                  onHandleAction={() => dispatch(onSearchListaClick())}
                />
                <Button
                  className={classes.btnClear}
                  size="small"
                  startIcon={<Icon className="fa fa-window-close" />}
                  disabled={btn1}
                  onClick={handleCategoriaArchivo}
                />
              </FormControl>
            </Grid>
            <Grid item xs={matches ? 3 : 4} container spacing={2}></Grid>
          </Grid> */}

          {/* <Grid item xs={12} container>
            <Grid item xs={matches ? 4 : 6}>
              <FormControl className={classes.filterClear}>
                <ActionField
                  className={classes.actionField}
                  label="Tipo de Formato"
                  placeholder="Enter para seleccionar el tipo de formato"
                  value={filters.dTipo}
                  variant={matches ? "outlined" : "standard"}
                  onHandleAction={() => dispatch(onSearchTipoFormatoClick())}
                />
                <Button
                  className={classes.btnClear}
                  size="small"
                  startIcon={<Icon className="fa fa-window-close" />}
                  disabled={btn3}
                  onClick={handleTipoFormato}
                />
              </FormControl>
            </Grid>
            <Grid item xs={matches ? 3 : 4} container spacing={2}></Grid>
          </Grid> */}
        

        

<div style={{width:'90%', marginLeft:'7px', marginRight:'46px' }}>
      <Grid item xs={12} container >
          <Grid item xs={matches ? 8 : 12} container justifyContent={matches ? "flex-end" : "center"} >
            <FormControl fullWidth sx={{ m: 1 }} >
           
              <TextField 
                fullWidth
                id="outlined-multiline-static"
                label="Comentario"
                name="comentario"
                onChange={onFieldChange2}
                required
                multiline
                minRows={7}
                value={entity.comentario}
                variant={matches ? "outlined" : "standard"}
                inputProps={{
                  maxLength: 650,
                }}
              />

            </FormControl>
          </Grid>
        </Grid>
        </div>
          
        <label    fontSize={8} style={{fontSize: '11px'}}> 
          <Checkbox
              name="promocion"
              label={label}                  
              className={classes.checkbox} 
              checked={filters.promocion} 
              color="primary" 
              disableRipple
              onChange={onCheckBoxChange}
          />
             {label}
        </label>
        
        <label    fontSize={8} style={{fontSize: '11px'}}> 
          <Checkbox
              name="compartir"
              label="Compartir"                    
              className={classes.checkbox} 
              checked={filters.compartir} 
              color="primary" 
              disableRipple
              onChange={onCheckBoxChange}
          />
            Descargar
          </label>

          {   

          filters.promocion === true && (

          <Grid container item xs={12} spacing={1}>
            <Grid item xs={matches ? 2 : 12}>
              <KeyboardDatePicker
                autoOk
                disableToolbar
                format="dd/MM/yyyy"
                label="Hasta"
                value={filters.hasta}
                inputvariant={matches ? "outlined" : "standard"}
                variant={matches ? "inline" : "dialog"}
                onChange={(date) =>
                  dispatch(onUpdateFieldFilter("hasta", date))
                }
              />
            </Grid>

          </Grid>
          )
          }

      

          {/* <Grid item xs>
            <FormControl className={classes.filterClear}>
              <TextField
                autoComplete="off"
                fullWidth
                label="Nombre de Producto"
                name="nombre"
                value={ filters.nombre}
                variant={matches ? "outlined" : "standard"}
                onChange={onFieldChange}
              />
              <Button
                style={{right:0}}
                className={classes.btnClear}
                size="small"
                startIcon={<Icon className="fa fa-window-close" />}
                disabled={btn2}
                onClick={handleName}
              />
            </FormControl>
          </Grid> */}
        </Grid>
      </FieldSet>
    </div>
  );
};

export default CargarMiEspacioFilter;
