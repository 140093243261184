import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { useLocation, useRouteMatch } from "react-router-dom";
import {
    btnApprove,
    btnExport,
    btnReject,
    btnSearch,
    onUpdateFieldEntity,
    restartState,
    setFormState
} from '../../store/channelmap/desactivacion/actions';

import { Body, Footer, Page } from '@sigeco/tools';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';

import DesactivacionFilter from '../../components/channelmap/desactivacion/DesactivacionFilter';
import DesactivacionForm from '../../components/channelmap/desactivacion/DesactivacionForm';
import DesactivacionList from '../../components/channelmap/desactivacion/DesactivacionList';

const Desactivacion = ({onClose, setTitle}) => {
    const location = useLocation();
    const match = useRouteMatch();
    const [open, setOpen] = useState(false);
    const formState = useSelector(state => state.desactivacion.formState);
    const loading = useSelector(state => state.desactivacion.loading);
    const estado = useSelector(state => state.desactivacion.entity.estado);
    const motivoRechazo = useSelector(state => state.desactivacion.entity.motivoRechazo);
    const dispatch = useDispatch();

    useEffect(() => {
        switch(match.path) {
          case '/channelmap/desactivaciones/:id':
            dispatch(setFormState('view', match.params['id']));
            setTitle('Visualizar Desactivación');
            break;
          default:
            dispatch(setFormState('search'));
            setTitle('Lista de Desactivaciones');
            break;
        }
      // eslint-disable-next-line
    }, [match.path]);

    const handleClickOpen = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    function onBtnClose() {
        dispatch(restartState());
        onClose();
    }

    return (
        <Page>
            <Backdrop style={{ position: 'absolute', zIndex: 1200 }} open={loading} transitionDuration={0}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Body>
                {console.log("formstate  "+formState)}
                {formState === 'search' && <DesactivacionFilter />}
                {formState === 'search' && <DesactivacionList />}
                {formState !== 'search' && <DesactivacionForm />}
            </Body>
            <Footer>
                {formState === 'search' && <Button
                    color="primary" 
                    size="small" 
                    variant="outlined" 
                    onClick={() => dispatch(btnExport())}
                    startIcon={<Icon className="fas fa-file-excel"/>}
                >
                    Exportar
                </Button>}
                {formState === 'search' && <Button
                    color="primary" 
                    size="small" 
                    variant="outlined"
                    onClick={() => dispatch(btnSearch())}
                    startIcon={<Icon className="fas fa-search"/>}
                >
                    Buscar
                </Button>}
                {formState !== 'search' && estado === 'P' && <Button
                    color="primary" 
                    size="small" 
                    variant="outlined"
                    onClick={() => dispatch(btnApprove())}
                    startIcon={<Icon className="fas fa-thumbs-up"/>}
                >
                    Aprobar
                </Button>}
                {formState !== 'search' && estado === 'P' && <Button
                    color="primary" 
                    size="small" 
                    variant="outlined"
                    onClick={handleClickOpen}
                    startIcon={<Icon className="fas fa-thumbs-down"/>}
                >
                    Rechazar
                </Button>}
                {formState !== 'search' && <LinkContainer to={{ pathname: '/channelmap/desactivaciones', search: location.search, hash: location.hash }}>
                    <Button
                        color="primary" 
                        size="small" 
                        variant="outlined"
                        startIcon={<Icon className="fas fa-times-circle"/>}
                    >
                        Cancelar
                    </Button>
                </LinkContainer>}
                <Button 
                    color="primary" 
                    size="small" 
                    variant="outlined" 
                    onClick={() => onBtnClose()} 
                    startIcon={<Icon className="fas fa-door-open"/>}
                >
                    Salir
                </Button>
            </Footer>
            <Dialog fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Rechazar</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Motivo rechazo"
                        multiline
                        rows={2}
                        fullWidth
                        value={motivoRechazo}
                        onChange={event => dispatch(onUpdateFieldEntity('motivoRechazo', event.target.value))}
                        inputProps={{
                            maxLength: 200
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}
                    color="primary">
                        Cancelar
                    </Button>
                    <Button color="primary" onClick={() => {
                        handleClose();
                        dispatch(btnReject())
                    }}>
                        Guardar
                    </Button>
                </DialogActions>
            </Dialog>
        </Page>
    );
};

export default Desactivacion;