import axios from 'axios';
import format from "date-fns/format";
import { IdentityUrl } from '../../../config';
import _ from "lodash";
import { closeAdvancedSearch, closeMessageBox, openAdvancedSearch, openMessageBox } from '../../main/actions';
import {

    RECEIVE_DATA,
    RECEIVE_V_LIST, 
    SET_MASK,
    SET_ORDER,
    SET_SELECTED_ROW,
    UPDATE_FIELD_FILTER,
    ADD_PRODUCTO,
    ADD_MARCA
} from './types';

import { exportToSpreadsheet, alob2, aTipoEstablecimiento,aProducto } from '../../../helpers/commons'

export const btnExport = () => async (dispatch, getState) => {
    
    if (getState().reporteindicadores.filters.distribuidorId   === 0) {
        dispatch(openMessageBox({
            button: 'ok',
            icon: 'warning',
            message: "Debe seleccionar un Agente..",
            callback: () => dispatch(closeMessageBox())
        }));
    return;
    }
    if(getState().reporteindicadores.store.data.length === 0) {
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'warning', 
            message: 'No hay información para exportar.', 
            callback: () => dispatch(closeMessageBox())
        }));
        return;
    }
    
    try {
        dispatch({ type: SET_MASK, loading: true });

        let head = getState().reporteindicadores.table.columns;
        var aux = [];
        //console.log(head)
         // eslint-disable-next-line no-redeclare, no-unused-vars
            for (var c in head)
            // eslint-disable-next-line no-new-object
                {
                  aux[0] = 'Distribuidor';
                  aux[1] = 'Vendedor';
                  aux[2] = 'Descripcion';
                  aux[3] = 'Precio Compra'; 
                  aux[4] = 'Precio Venta'; 
                  aux[5] = 'Establecimiento'; 
                  aux[6] = 'Lob'; 
                  aux[7] = 'Marca'; 
                  aux[8] = 'Tipo'; 
                  aux[9] = 'Departamento'; 
                  aux[10] = 'Provincia'; 
                  aux[11] = 'Presentación';
                  aux[12] = 'Fecha'; 
                  aux[13] = 'Usuario';  
                }

        var carArray = [];

        carArray.push(aux);

        //console.log("DDD: ",carArray)

        //return 0;
        let json = getState().reporteindicadores.store.data;
        //console.log(json)

        for(var i in json) {
            let x =   Object.values(json[i]);
          
        //console.log(x)
            
            carArray.push(x);
          }
        
        //console.log(carArray)
        
        exportToSpreadsheet(carArray,  'ReporteIndicadoresPrecios') ;

    } catch (error) {
        console.log(error)
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'error', 
            message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
            callback: () => dispatch(closeMessageBox())
        }));
    } finally {
        setTimeout(() => {dispatch({ type: SET_MASK, loading: false })}, 1000);
    }
};

export const btnSearch = () => async (dispatch, getState) => {

    // if (getState().reporteseguimientoprecios.filters.distribuidorId   === 0) {
    //     dispatch(openMessageBox({
    //         button: 'ok',
    //         icon: 'warning',
    //         message: "Debe seleccionar un Agente..",
    //         callback: () => dispatch(closeMessageBox())
    //     }));
    // return;
    // }  

    try {
       
        if (getState().reporteindicadores.store.loading) {
            return;
        }

        dispatch({ type: SET_MASK, loading: true });

                //dettermina el pais
                let uri = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Vendedores/listarpaislogin` 
                let responses = await axios.get(encodeURI(uri));
               let datas = responses.data;

               let columns = getState().reporteindicadores.table.columns;

              if  (datas[0].Pais === 'CO' ){
                  columns.splice(2, 1); // Elimina el elemento en la posición encontrada
                  columns.splice(2, 1); // Elimina el elemento en la posición encontrada
               }

        //console.log(getState().reporteindicadores.filters.dProducto)
// ******************************Json Producto**************************************

        let obj = getState().reporteindicadores.filters.dProducto === ""
        ? ""
        : JSON.stringify(getState().reporteindicadores.filters.dProducto);

// ******************************Json Marca**************************************
        let marca = getState().reporteindicadores.filters.dMarca === ""
        ? ""
        : JSON.stringify(getState().reporteindicadores.filters.dMarca);

        let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Tomas/basedatos/listarprecioindicadores?`
            + `distribuidorId=${getState().reporteindicadores.filters.distribuidorId}`
            + `&desde=${format(getState().reporteindicadores.filters.desde, 'yyyy-MM-dd')}`
            + `&hasta=${format(getState().reporteindicadores.filters.hasta, 'yyyy-MM-dd')}`
            + `&usuarioId=""`
            + `&producto=${obj}`
            + `&establecimiento=${getState().reporteindicadores.filters.establecimiento}`
            + `&lob=${getState().reporteindicadores.filters.dLob}`
            + `&marca=${marca}`
            + `&departamento=${getState().reporteindicadores.filters.dDepartamento}`
            + `&ciudad=${getState().reporteindicadores.filters.dMunicipio}`;
        let response = await axios.get(encodeURI(url));
        let data = response.data;
        //console.log(data)
 
        if (data.length === 0) {
            dispatch(openMessageBox({
                button: 'ok',
                icon: 'warning',
                message: "No existe Registro  para esta consulta",
                callback: () => dispatch(closeMessageBox())
            }));
        return;
    }

        dispatch({ type: RECEIVE_V_LIST, columns: columns, payload: data});
        
    } catch (error) {
       
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'error', 
            message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
            callback: () => dispatch(closeMessageBox())
        }));
    } finally {
        dispatch({ type: SET_MASK, loading: false });
    }

    
};



export const onSearchDistribuidorClick = () => async (dispatch) => {
    let fields = [
        { 'dataKey': 'razonSocial', 'label': 'Agente', 'type': 'string', 'width': 225 }
    ];

    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de agente',
        getData: async (params) => {
            let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`
            let response = await axios.get(encodeURI(url), { params: params });
            
            return response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldFilter('distribuidorId', result[0].distribuidorId));
                dispatch(onUpdateFieldFilter('dDistribuidor', `${result[0].razonSocial}`));
                dispatch(onUpdateFieldFilter('dProducto', ''));
                dispatch(onUpdateFieldFilter('establecimiento', ''));
                dispatch(onUpdateFieldFilter('lobId', ''));
                dispatch(onUpdateFieldFilter('dLob', ''));
                dispatch(onUpdateFieldFilter('dMarca', ''));
                dispatch(onUpdateFieldFilter("departamentoId", ''));
                dispatch(onUpdateFieldFilter('dDepartamento', ''));
                dispatch(onUpdateFieldFilter('municipioId', ''));
                dispatch(onUpdateFieldFilter("dMunicipio", ''));
            }
        }
    }));
};

// export const onSearchSupervisorClick = () => async (dispatch, getState) => {
//     let fields = [
//         { 'dataKey': 'nombre', 'label': 'Nombre', 'type': 'string', 'search': true, 'width': 225 },
//         { 'dataKey': 'nombreUsuario', 'label': 'Usuario', 'type': 'string', 'width': 115 }
//     ];
    
//     if (getState().reporteindicadores.filters.distribuidorId === 0) {
//         dispatch(
//           openMessageBox({
//             button: "ok",
//             icon: "warning",
//             message: "Debe seleccionar un Agente..",
//             callback: () => dispatch(closeMessageBox()),
//           })
//         );
//         return;
//       }

//     dispatch(openAdvancedSearch({
//         autoLoad: true,
//         data: [],
//         fields: fields,
//         paginated: true,
//         remote: true,
//         title: 'Selección de supervisor',
//         getData: async (params) => {
//             let url = `${IdentityUrl}/api/v1/usuarios?rol=Supervisor`
//             let response = await axios.get(encodeURI(url), { params: params });
//             let distribuidorId = getState().reporteindicadores.filters.distribuidorId;

//             if(distribuidorId > 0) {
//                 response.data.data = response.data.data.filter(f => f.distribuidorId === distribuidorId);
//             }

//             return response.data;
//         },
//         callback: (btn, result) => {
//             dispatch(closeAdvancedSearch());
//             if(btn === 'yes') {
//                 dispatch(onUpdateFieldFilter('supervisorId', result[0].usuarioId));
//                 dispatch(onUpdateFieldFilter('dSupervisor', `${result[0].nombre}`));
//             }
//         }
//     }));
// };



export const onUpdateColumn = (column) => (dispatch, getState) => {
    getState().reporteindicadores.table.columns.map((item , i)=> {     
       if(item.dataKey !== column.dataKey) {
           return item;
       }else{
               item.width =  column.width;
       }
       return {
          // ...item,
         //  width: column.width
       };
      
   });
};


  export const onSearchProductoClick = () => async (dispatch, getState) => {
    let fields = [ {dataKey: "nombre",label: "Nombre",type: "string",search: true,width: 225,}];
    //let data = aProducto;
    
    if (getState().reporteindicadores.filters.distribuidorId === 0) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "Debe seleccionar una Agente..",
          callback: () => dispatch(closeMessageBox()),
        })
      );
      return;
    }
  
    dispatch(
      openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        multiSelect: true,
        paginated: true,
        remote: true,
        title: "Selección de Producto",
        getData: async (params) => {
          let input2 = JSON.stringify(params);
          let input = JSON.parse(input2);
          let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Productos/listarProductoPais` 
         
          let response1 = await axios.get(encodeURI(url), { params: params });
          //console.log(response1);
          let response = {
            data: {
              start: 0,
              pageSize: 50,
              total: response1.data.length,
              data: response1.data,
            },
          };
  
          if (input.nombre) {
            let final = response.data.data.filter((e) =>
              e.nombre.toLowerCase().includes(input.nombre.toLowerCase())
            );
            response.data.data = final;
            response.data.total = final.length;
          }
          return response.data;
        },
        callback: (btn, result) => {
          console.log(result);
          dispatch(closeAdvancedSearch());
          if (btn === "yes") {
            dispatch({
              type: ADD_PRODUCTO,
               payload: result.map((item) => {
                 return { 
                  dProducto: item.nombre, 
                }; 
              }),
            });
            dispatch(onUpdateFieldFilter('establecimiento', ''));
            dispatch(onUpdateFieldFilter('lobId', ''));
            dispatch(onUpdateFieldFilter('dLob', ''));
            dispatch(onUpdateFieldFilter('dMarca', ''));
           

          }
        },
      })
    );
  };


  export const onSearchLobClick = () => async (dispatch, getState) => {
    let fields = [
      { dataKey: "dLob", label: "Nombre", type: "string", width: 250 },
    ];
  
    let data = alob2;
    //console.log(data);
  
    if (getState().reporteindicadores.filters.distribuidorId === 0) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "Debe seleccionar un Distribuidor.",
          callback: () => dispatch(closeMessageBox()),
        })
      );
      return;
    }
    try {
      dispatch(
        openAdvancedSearch({
          autoLoad: true,
          data: data,
          fields: fields,
          paginated: false,
          remote: false,
          title: "Selección de Lob",
          callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if (btn === "yes") {
              //console.log(result[0]);
              dispatch(onUpdateFieldFilter("lobId", result[0].dLob));
              dispatch(onUpdateFieldFilter("dLob", result[0].dLob));
              dispatch(onUpdateFieldFilter('dMarca', ''));
             
            }
          },
        })
      );
    } catch (error) {
      if (error.response) {
        dispatch(
          openMessageBox({
            button: "ok",
            icon: "error",
            message:
              (error.response.data.eventLogId === 0
                ? ""
                : `EventoId: ${error.response.data.eventLogId}. `) +
              error.response.data.message,
            callback: () => dispatch(closeMessageBox()),
          })
        );
      }
    }
  };

  export const onSearchEstablecimientoClick = () => async (dispatch, getState) => {
    let fields = [
      { dataKey: "dTipoEstablecimiento", label: "Nombre", type: "string", width: 250 },
    ];
  
    try {
      dispatch(
        openAdvancedSearch({
            autoLoad: true,
            data: [],
            fields: fields,
            paginated: true,
            remote: true,
            title: "Selección de un Establacimiento",
            getData: async (params) => {
                let url = `${getState().main.resources.uriSvcSalesMobilityAPI}/api/v1/Tomas/tipoestablecimiento`
                let response = await axios.get(encodeURI(url), { params: params });
                const aux = {
                    data: response.data,
                    pageSize: 50,
                    start: 0,
                    total: response.data.length,
                  };
                return aux;
            },
          callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if (btn === "yes") {
            dispatch(onUpdateFieldFilter('establecimiento', `${result[0].dTipoEstablecimiento}`)); 
            dispatch(onUpdateFieldFilter("lobId", ''));
              dispatch(onUpdateFieldFilter("dLob", ''));

            }
          },
        })
      );
    } catch (error) {
      if (error.response) {
        dispatch(
          openMessageBox({
            button: "ok",
            icon: "error",
            message:
              (error.response.data.eventLogId === 0
                ? ""
                : `EventoId: ${error.response.data.eventLogId}. `) +
              error.response.data.message,
            callback: () => dispatch(closeMessageBox()),
          })
        );
      }
    }
  };

  export const onSearchMarcaClick = () => async (dispatch, getState) => {
    let fields = [ {dataKey: "nombre",label: "Nombre",type: "string",search: true,width: 225,}];
    //let data = aProducto;
    
    if (getState().reporteindicadores.filters.distribuidorId === 0) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "Debe seleccionar una Agente..",
          callback: () => dispatch(closeMessageBox()),
        })
      );
      return;
    }
  
    dispatch(
      openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        multiSelect: true,
        paginated: true,
        remote: true,
        title: "Selección de Marca",
        getData: async (params) => {
          let input2 = JSON.stringify(params);
          let input = JSON.parse(input2);
          let url =
            `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Marcas?` +
            `&distribuidorId=${getState().reporteindicadores.filters.distribuidorId}`;
          let response1 = await axios.get(encodeURI(url), { params: params });
          //console.log(response1);
          let response = {
            data: {
              start: 0,
              pageSize: 50,
              total: response1.data.length,
              data: response1.data,
            },
          };
  
          if (input.nombre) {
            let final = response.data.data.filter((e) =>
              e.nombre.toLowerCase().includes(input.nombre.toLowerCase())
            );
            response.data.data = final;
            response.data.total = final.length;
          }
          return response.data;
        },
        callback: (btn, result) => {
          console.log(result);
          dispatch(closeAdvancedSearch());
          if (btn === "yes") {
            dispatch({
              type: ADD_MARCA, 
              payload: result.map((item) => {
                return {
                  dMarca: item.nombre
                };
              }),
            });
            }
          }
        },
      )
    );
  };

  export const onSearchDepartaClick = () => async (dispatch, getState) => {
    let fields = [
      { dataKey: "nombre", label: "Nombre", type: "string", width: 225 },
    ];
    if (getState().reporteindicadores.filters.distribuidorId === 0) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "Debe seleccionar una Agente..",
          callback: () => dispatch(closeMessageBox()),
        })
      );
      return;
    }
  
    dispatch(
      openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: "Selección de Departamento",
        getData: async (params) => {
          let url = `${
            getState().main.resources.uriSvcSalesMobilityAPI
          }api/v1/agentes/departamentos`;
          let response = await axios.get(encodeURI(url), { params: params });
          //console.log(url);
  
          const aux = {
            data: response.data,
            pageSize: 50,
            start: 0,
            total: response.data.length,
          };
  
          return aux; 
        },
        callback: (btn, result) => {
          dispatch(closeAdvancedSearch());
          if (btn === "yes") {
            dispatch(onUpdateFieldFilter("departamentoId", result[0].codigo));
            dispatch(onUpdateFieldFilter("dDepartamento", `${result[0].nombre}`));
            dispatch(onUpdateFieldFilter("municipioId", ""));
            dispatch(onUpdateFieldFilter("dMunicipio", ""));
          }
        },
      })
    );
  };
  

export const onSearcMunicipioClick = () => async (dispatch, getState) => {
  let fields = [
    { dataKey: "nombre", label: "Nombre", type: "string", width: 225 },
  ];
  if (getState().reporteindicadores.filters.dDepartamento === "") {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar una Departamento...",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }
  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de Municipio",
      getData: async (params) => {
        //let url = `${IdentityUrl}/api/v1/agentes/departamentos`;
        let url =
          `${
            getState().main.resources.uriSvcSalesMobilityAPI
          }api/v1/agentes/provincias?` +
          `codigodepartamento=${
            getState().reporteindicadores.filters.departamentoId
          }`;
        let response = await axios.get(encodeURI(url), { params: params });
        //console.log(url);

        const aux = {
          data: response.data,
          pageSize: 50,
          start: 0,
          total: response.data.length,
        };

        //console.log(aux);
        //console.log(response);
        //console.log(params);
        return aux; //response.data;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          //console.log(result);
          //console.log(result[0]);
          dispatch(onUpdateFieldFilter("municipioId", result[0].nombre)); //codigo
          dispatch(onUpdateFieldFilter("dMunicipio", `${result[0].nombre}`));
        }
      },
    })
  );
};


export const onUpdateFieldFilter = (key, value) => (dispatch, getState) => {
    dispatch({ type: UPDATE_FIELD_FILTER, key, value })
    dispatch({ type: RECEIVE_DATA, payload: [],storeData:[]});
    //const  da = []

    dispatch( onUpdateFielddata());
   // dispatch({ type: RECEIVE_DATA, payload:[],  store:[] })
    
}

export const onUpdateFielddata = (key, value) => (dispatch, getState) => {  

   // dispatch({ type: UPDATE_FIELD_DATA, key, value })

     //dispatch({ type: RECEIVE_DATA, columns: [], data: [] })
  //  dispatch({ type: RECEIVE_DATA, payload:[],  store:[] })
    //dispatch({ type: RECEIVE_DATA, payload: data, storeData });
    };

export const setOrder = (order, orderBy) => ({ type: SET_ORDER, order, orderBy });

export const setSelectedRow = (selected) => ({ type: SET_SELECTED_ROW, selected});