import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    onUpdateFieldEntity,
    onSearchMarcaClick,
    onSearchUsoAceiteClick,
    onSearchCategoriaClick,
    onSearchCanalClick, 
    onSearchLineaClick
} from '../../../store/precios/productos/actions';

import { ActionField, FieldSet } from '@sigeco/tools';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery';


const useStyles = makeStyles((theme) => ({
    checkbox: {
        color: "#9c9c9c",
        padding: 0
    },
    details: {
        display: 'flex',
        padding: '0.5rem 0.75rem 0.75rem'
    },
    label: {
        alignItems: 'center',
        color: 'rgba(0, 0, 0, 0.54)', 
        display: 'flex', 
        fontSize: '0.8125rem', 
        width: 90
    },
    paper: {
        marginBottom: '0.75rem',
        '@media (min-width: 1401px)': {
            marginBottom: '1rem'
        }
    }
}));

const ProductosForm = () => {
    const classes = useStyles();
    const matches = useMediaQuery('(min-width:701px)');
    const entity = useSelector(state => state.productos.entity);
    const errors = useSelector(state => state.productos.errors);
    const formState = useSelector(state => state.productos.formState);
    const dispatch = useDispatch();
    const isView = formState === 'view';

    function onFieldChange(event) {
        dispatch(onUpdateFieldEntity(event.target.name, event.target.value));
    }

    return (
        <div>
            <FieldSet className={classes.paper} iconCls="fas fa-file-alt" title="Información">
                <div className={classes.details}>
                    <Grid container spacing={1}>
                        {entity.productoId !== '' && (<Grid item xs={12} container>
                            <Grid item xs={matches ? 3 : 12}>
                                <TextField
                                    label="Identificador"
                                    value={entity.productoId}
                                    variant={matches ? "outlined" : "standard"}
                                    fullWidth
                                    inputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>
                        </Grid>)}

                        <Grid item xs={12} container>
                            <Grid item xs={matches ? 4 : 12}>
                                <ActionField
                                    error={errors.canalId !== ''}
                                    helperText={errors.canalId}
                                    hiddenAction={isView}
                                    label="Canal"
                                    placeholder="Enter para buscar Canal"
                                    value={entity.dCanal}
                                    variant={matches ? "outlined" : "standard"}
                                     onHandleAction={() => dispatch( onSearchCanalClick ())}
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} container>
                            <Grid item xs={matches ? 4 : 12}>
                                <ActionField
                                    error={errors.lineaId !== ''}
                                    helperText={errors.lineaId}
                                    hiddenAction={isView}
                                    label="línea"
                                    placeholder="Enter para buscar línea"
                                    value={entity.dLinea}
                                    variant={matches ? "outlined" : "standard"}
                                     onHandleAction={() => dispatch( onSearchLineaClick ())}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container>
                            <Grid item xs={matches ? 4 : 12}>
                                <ActionField
                                    error={ 
                                    errors.marcaId !== ''}
                                    helperText={errors.marcaId}
                                    hiddenAction={isView}
                                    label="Marca"
                                    placeholder="Enter para buscar Marca"
                                    value={entity.dMarca}
                                    variant={matches ? "outlined" : "standard"}
                                    onHandleAction={() => dispatch(onSearchMarcaClick())}
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} container>
                            <Grid item xs={matches ? 4 : 12}>
                                <ActionField
                                    error={ 
                                    errors.tipoAceiteId !== ''}
                                    helperText={errors.tipoAceiteId}
                                    hiddenAction={isView}
                                    label="Categoría"
                                    placeholder="Enter para buscar categoria"
                                    value={entity.dTipoAceite}
                                    variant={matches ? "outlined" : "standard"}
                                    onHandleAction={() => dispatch(onSearchCategoriaClick())}
                                />
                            </Grid>
                        </Grid>
     
                        <Grid item xs={12} container>
                            <Grid item xs={matches ? 6 : 12}>
                                <TextField
                                    autoComplete="off"
                                    error={errors.nombre !== ''}
                                    fullWidth
                                    helperText={errors.nombre}
                                    label="Nombres"
                                    name="nombre"
                                    onChange={onFieldChange}
                                    required
                                    value={entity.nombre}
                                    variant={matches ? "outlined" : "standard"}
                                    inputProps={{
                                        maxLength: 50,
                                        readOnly: isView || formState === 'restart'
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container>
                            <Grid item xs={matches ? 4 : 12}>
                                <TextField
                                    autoComplete="off"
                                    error={errors.viscosidad !== ''}
                                    fullWidth
                                    helperText={errors.viscosidad}
                                    label="Viscosidad:"
                                    name="viscosidad"
                                    onChange={onFieldChange}
                                    required
                                    value={entity.viscosidad}
                                    variant={matches ? "outlined" : "standard"}
                                    inputProps={{
                                        maxLength: 50,
                                        readOnly: isView || formState === 'restart'
                                    }}
                                />
                            </Grid>
                        </Grid>

                        {/* <Grid item xs={12} container>
                            <Grid item xs={matches ? 4 : 12}>
                                <TextField
                                    autoComplete="off"
                                    error={errors.certificado !== ''}
                                    fullWidth
                                    helperText={errors.certificado}
                                    label="Certificado:"
                                    name="certificado"
                                    onChange={onFieldChange}
                                    required
                                    value={entity.certificado}
                                    variant={matches ? "outlined" : "standard"}
                                    inputProps={{
                                        maxLength: 50,
                                        readOnly: isView || formState === 'restart'
                                    }}
                                />
                            </Grid>
                        </Grid>
                     
             */}
                        <Grid item xs={12} container>
                            <Grid item xs={matches ? 4 : 12}>
                                <ActionField
                                    error={ 
                                    errors.usoAceiteId !== ''}
                                    helperText={errors.usoAceiteId}
                                    hiddenAction={isView}
                                    label="Uso Aceite"
                                    placeholder="Enter para buscar Tipo Aceites"
                                    value={entity.dUsoAceite}
                                    variant={matches ? "outlined" : "standard"}
                                    onHandleAction={() => dispatch(onSearchUsoAceiteClick())}
                                />
                            </Grid>
                        </Grid>
 
                        {/*
                            <Grid item xs={12} container>
                            <Grid item xs={12} container>
                                <div className={classes.label}>Activo:</div>
                                <Checkbox
                                    name="activo"
                                    className={classes.checkbox} 
                                    checked={entity.activo} 
                                    color="primary" 
                                    disabled={isView} 
                                    disableRipple
                                    onChange={onCheckBoxChange}
                                    inputProps={{
                                        readOnly: isView
                                    }}
                                />
                            </Grid>
                        </Grid>

                       */ }
                  
                                  
                    </Grid>
                </div>
            </FieldSet>
      
        </div>
    )
};

export default ProductosForm;