import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  onSearchDistribuidorClick, 
  onUpdateFieldFilter, 
  onSearchProductoClick,
  onSearchEstablecimientoClick,
  onSearchLobClick,
  onSearchDepartaClick,
  onSearcMunicipioClick,
  onSearchMarcaClick
} from "../../../store/precios/reporteindicadores/actions";

import { ActionField, FieldSet } from "@sigeco/tools";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { KeyboardDatePicker } from "@material-ui/pickers";
import FormControl from "@material-ui/core/FormControl";
import { Icon } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import {getDistribuidorDefault} from '../../../helpers/commons';

const useStyles = makeStyles(() => ({
  content: {
    padding: "0.5rem 0.75rem 0.75rem",
  },
  filterClear: {
    display: "flex",
  },
  actionField: {
    flexDirection: "row",
    position: "relative",
  },
  btnClear: {
    position: "absolute",
    bottom: "1.5",
    right: 20,
    maxWidth: "15px",
    maxHeight: "23px",
    minWidth: "15px",
    minHeight: "23px",
  },
  root: {
    flex: "0 0 auto",
    marginBottom: "0.75rem",
    "@media (min-width: 1401px)": {
      marginBottom: "1rem",
    },
  },
}));

const ReporteIndicadoresFilter = () => {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:701px)");
  const filters = useSelector((state) => state.reporteindicadores.filters);
  const dispatch = useDispatch();

  useEffect(() =>{

    getDistribuidorDefault(onUpdateFieldFilter,dispatch);

  },[])


  //Botón de limpiar filtro
  const [, setDistribuidor] = useState("");
  const [btn, setBtn] = useState(true);

  const [, setEstablecimiento] = useState("");
  const [btn1, setBtn1] = useState(true)
  const [dProducto, setProducto] = useState("");
  const [btn2, setBtn2] = useState(true);
  const [btn3, setBtn3] = useState(true);
  const [, setLob] = useState("");

  const [btn4, setBtn4] = useState(true);
  const [, setDepartamento] = useState("");
  const [dMarca, setMarca] = useState("");
  const [btn7, setBtn7] = useState(true);
  const [, setMunicipio] = useState("");
  const [btn5, setBtn5] = useState(true);

  useEffect(() => {
    if (filters.dDistribuidor) {
      setDistribuidor(filters.dDistribuidor);
      setBtn(false);
    } else {
      setBtn(true);
    }
  }, [filters.dDistribuidor]);
  

  useEffect(() => {
    //console.log(filters.dProducto.length)
    if (filters.dProducto.length !== 0) {
      setProducto("Filtro Seleccionado");
      setBtn2(false);
    } else {
      setBtn2(true);
    }
  }, [filters.dProducto]);

  useEffect(() => {
    if (filters.establecimiento) {
      setEstablecimiento(filters.establecimiento);
      setBtn1(false);
    } else {
      setBtn1(true);
    }
  }, [filters.establecimiento]);

  useEffect(() => {
    if (filters.dLob) {
      setLob(filters.dLob);
      setBtn3(false);
    } else {
      setBtn3(true);
    }
  }, [filters.dLob]);

  useEffect(() => {
    //console.log(filters.dProducto.length)
    if (filters.dMarca.length !== 0) {
      setMarca("Filtro Seleccionado");
      setBtn7(false);
    } else {
      setBtn7(true);
    }
  }, [filters.dMarca]);

  useEffect(() => {
    if (filters.dDepartamento) {
      setDepartamento(filters.dDepartamento);
      setBtn4(false);
    } else {
      setBtn4(true);
    }
  }, [filters.dDepartamento]);

  useEffect(() => {
    if (filters.dMunicipio) {
      setMunicipio(filters.dMunicipio);
      setBtn5(false);
    } else {
      setBtn5(true);
    }
  }, [filters.dMunicipio]);


  
  const handleDist = () => {
    setDistribuidor("");
    setProducto('');
    setLob("");
    setMarca("");
    setDepartamento("");
    setMunicipio("");
    filters.distribuidorId = 0;
    filters.dDistribuidor = "";
    filters.dProducto = "";
    filters.establecimiento = "";
    filters.lobId = "";
    filters.dLob = "";
    filters.dMarca = "";
    filters.dDepartamento = "";
    filters.dMunicipio = "";
    setBtn(true);
    setBtn1(true);
    setBtn2(true);
    setBtn3(true);
    setBtn4(true);
    setBtn5(true);
    setBtn7(true);
  };

 
  const  handleProducto = () => {
    setProducto("");
    filters.dProducto = '';
    filters.establecimiento = "";
    filters.lobId = "";
    filters.dLob = "";
    filters.dMarca = "";
    setBtn1(true);
    setBtn2(true);
    setBtn3(true);
    setBtn7(true);
  };

  const  handleEstablecimiento = () => {
    setEstablecimiento("");
    filters.establecimiento = '';
    filters.lobId = "";
    filters.dLob = "";
    filters.dMarca = "";
    setBtn1(true);
    setBtn3(true);
    setBtn7(true);
  };

  const handleLob = () => {
    setLob("");
    filters.dLob = "";
    filters.lobId = "";
    filters.dMarca = "";
    setBtn3(true);
    setBtn7(true);
  };

const  handleMarca = () => {
    setMarca("");
    filters.dMarca = '';
    setBtn7(true);
  
  };
  const handleDepartamento = () => {
    setDepartamento("");
    setMunicipio("");
    filters.dDepartamento = "";
    filters.dMunicipio = "";
    setBtn4(true);
    setBtn5(true);
  };

  const handleMunicipio = () => {
    setMunicipio("");
    filters.dMunicipio = "";
    setBtn5(true);
  };

 
  return (
    <div className={classes.root}>
      <FieldSet
        iconCls="fas fa-filter"
        title="Filtros" 
        defaultExpanded
        expandable
      >
        <Grid className={classes.content} container spacing={2}>
          <Grid item xs={12} spacing={2} container>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <FormControl className={classes.filterClear}>
                <ActionField
                  className={classes.actionField}
                  label="Agente"
                  placeholder="Enter para buscar agente"
                  value={filters.dDistribuidor}
                  variant={matches ? "outlined" : "standard"}
                  onHandleAction={() => dispatch(onSearchDistribuidorClick())}
                />
                <Button
                  className={classes.btnClear}
                  size="small"
                  startIcon={<Icon className="fa fa-window-close" />}
                  disabled={btn}
                  onClick={handleDist}
                />
              </FormControl>
            </Grid>

            <Grid item xs={6} sm={3} md={3} lg={3}>
              <FormControl className={classes.filterClear}>
                <ActionField
                className={classes.actionField}
                autoComplete="off"
                fullWidth
                  label="Producto"
                  name="dProducto"
                  value={dProducto}
                  variant={matches ? "outlined" : "standard"}
                  onHandleAction={() => dispatch(onSearchProductoClick())}
                />
                <Button
                  className={classes.btnClear}
                  size="small"
                  startIcon={<Icon className="fa fa-window-close" />}
                  disabled={btn2}
                  onClick={handleProducto}
                />
              </FormControl>
            </Grid>

            <Grid item xs={6} sm={3} md={3} lg={3}>
              <FormControl className={classes.filterClear}>
                <ActionField
                  className={classes.actionField}
                  label="Establecimiento"
                  placeholder="Enter para buscar Establecimiento"
                   value={filters.establecimiento}
                  variant={matches ? "outlined" : "standard"}
                  onHandleAction={() => dispatch(onSearchEstablecimientoClick())}
                />
                <Button
                  className={classes.btnClear}
                  size="small"
                  startIcon={<Icon className="fa fa-window-close" />}
                  disabled={btn1}
                  onClick={handleEstablecimiento}
                />
              </FormControl>
            </Grid>


            <Grid item xs={6} sm={3} md={3} lg={2}>
              <FormControl className={classes.filterClear}>
                <ActionField
                  className={classes.actionField}
                  label="Lob"
                  placeholder="Enter para buscar Lob"
                  value={filters.dLob}
                  variant={matches ? "outlined" : "standard"}
                  onHandleAction={() => dispatch(onSearchLobClick())}
                  onChange={handleLob}
                />
                <Button
                  className={classes.btnClear}
                  size="small"
                  startIcon={<Icon className="fa fa-window-close" />}
                  disabled={btn3}
                  onClick={handleLob}
                />
              </FormControl>
            </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <FormControl className={classes.filterClear}>
                <ActionField
                className={classes.actionField}
                autoComplete="off"
                fullWidth
                  label="Marca"
                  name="dMarca"
                  value={dMarca}
                  variant={matches ? "outlined" : "standard"}
                  onHandleAction={() => dispatch(onSearchMarcaClick())}
                />
                <Button
                  className={classes.btnClear}
                  size="small"
                  startIcon={<Icon className="fa fa-window-close" />}
                  disabled={btn7}
                  onClick={handleMarca}
                />
              </FormControl>
            </Grid>
              <Grid item xs={6} sm={3} md={3} lg={3}>
                <FormControl className={classes.filterClear}>
                  <ActionField
                    className={classes.actionField}
                    label="Departamento"
                    placeholder="Enter para buscar Departamento"
                    value={filters.dDepartamento}
                    variant={matches ? "outlined" : "standard"}
                    onHandleAction={() => dispatch(onSearchDepartaClick())}
                  />
                  <Button
                    className={classes.btnClear}
                    size="small"
                    startIcon={<Icon className="fa fa-window-close" />}
                    disabled={btn4}
                    onClick={handleDepartamento}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={3} md={3} lg={3}>
                <FormControl className={classes.filterClear}>
                  <ActionField
                    className={classes.actionField}
                    label="Provincia"
                    placeholder="Enter para buscar Provincia"
                    value={filters.dMunicipio}
                    variant={matches ? "outlined" : "standard"}
                    onHandleAction={() => dispatch(onSearcMunicipioClick())}
                  />
                  <Button
                    className={classes.btnClear}
                    size="small"
                    startIcon={<Icon className="fa fa-window-close" />}
                    disabled={btn5}
                    onClick={handleMunicipio}
                  />
                </FormControl>
              </Grid>

              <Grid item>
              <KeyboardDatePicker
                autoOk
                disableToolbar
                format="dd/MM/yyyy"
                label="Desde"
                value={filters.desde}
                inputVariant={matches ? "outlined" : "standard"}
                variant={matches ? "inline" : "dialog"}
                onChange={(date) =>
                  dispatch(onUpdateFieldFilter("desde", date))
                }
                disableFuture
              />
            </Grid>
            <Grid item>
              <KeyboardDatePicker
                autoOk
                disableToolbar
                format="dd/MM/yyyy"
                label="Hasta"
                value={filters.hasta}
                inputVariant={matches ? "outlined" : "standard"}
                variant={matches ? "inline" : "dialog"}
                onChange={(date) =>
                  dispatch(onUpdateFieldFilter("hasta", date))
                }
                disableFuture
              />
            </Grid>


          </Grid>

        </Grid>
      </FieldSet>
    </div>
  );
};

export default ReporteIndicadoresFilter;
