import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    RECEIVE_V_LIST,
    RECEIVE_W_LIST
  } from "../../../store/fuerzaventas/cargamasivarutas/types";
//import './table.css'

import { FieldSet} from '@sigeco/tools';
import makeStyles from '@material-ui/core/styles/makeStyles';
import * as XLSX from "xlsx";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
//import { onUpdateFieldUpdate } from '../../../store/fuerzaventas/cargamasivarutas/actions';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        width: '120%'
    },
    progress: {
        height: '2rem',
        width: '2rem',
        '@media (min-width: 701px)': {
            height: '2.5rem',
            width: '2.5rem',
        },
    },
        content: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: 'calc(50% - 20px)',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    tableWrap: {
        flex: '1 1 auto'
    }
}));

const CargaMasivaRutasListRutas = () => {
    const classes = useStyles();
    const loading = useSelector(state => state.cargamasivarutas.loading);
    const store = useSelector(state => state.cargamasivarutas.store);

    const dispatch = useDispatch();
      const [data, setData] = useState([]);
      const [, setFile] = useState([]);
      const [head, setHead] = useState([]);

      useEffect(() => {
          setData( store.data)
         //onUpdateFieldUpdate(data, [])
         if (store.data2.length > 0 ){
            dispatch({ type: RECEIVE_W_LIST, columns: [], payload: [] }); 
        }
          return () => {
           // ChatAPI.unsubscribeFromFriendStatus(props.friend.id, handleStatusChange);
               setData( [])
          };
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [ store.data]);

      function  readExcel (par)  { //=>
     
            if (par === '' || par === undefined){
            setFile('')
            return
            }
       
              //const ext = file.split('.').pop();
            const allowedExtensions = /(.xls|.xlsx)$/i;
            if(!allowedExtensions.test(par.name)){
                   alert('USE EL FORMATO CORRECTO PARA LA CARGA');
                   return
            }else{

                const promise = new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.readAsArrayBuffer(par);

                    fileReader.onload = (e) => {
                    const bufferArray = e.target.result;

                    const wb = XLSX.read(bufferArray, { type: "buffer" });

                    const wsname = wb.SheetNames[0];
                    
                    const ws = wb.Sheets[wsname];
                    // console.log(ws)

                    const data = XLSX.utils.sheet_to_json(ws);
                    
                    // let userStr = JSON.parse(JSON.stringify(data[0])); //DATA Y KEY EN POS 0
                    // console.log(userStr);
                    // KEYS
                    //console.log( Object.keys(data[0]))

                    var local = [];

                    setHead (head.push(Object.keys(data[0])));
                    let leng = head.length -1
 
                    
                    if(head.length > 1){
                        let head2 = []
                        head2.push(head[leng])
                    
                        head2.map((item, i) => {
                            for (var i = 0; i < item.length; i++) {
                                //Para obtener el objeto de tu lista
                                var hotel2 = item[i];
                                local.push(hotel2);
                            }
                        })
                          
                    }else{

                        // eslint-disable-next-line array-callback-return
                        head.map((item, j)=> {
                            for (var i=0; i< item.length; i++)
                            {
                                //Para obtener el objeto de tu lista
                                var hotel = item[i];
                                local.push(hotel);
                            }
                        })
                    }

                    setHead(local);

                    resolve(data);
                    setFile(data);
                    };

                    fileReader.onerror = (error) => {
                    reject(error);
                    };
                });
            
                promise.then((d) => {
                //    if (d.length > 0  || d){
                    dispatch({ type: RECEIVE_V_LIST, columns: Object.keys(d[0]), payload: d });
                // }
                // dispatch({ type: SET_MASK, loading: false });
                });
            }    
        };

        // const addHead = (par) => {
        //     setHead([...head, par])
        // }

        function HeadTable() {
            if (head.length > 0 ){

               return head.map((key, index) => {
                    return <th key={index} style={{ backgroundColor: 'lightsteelblue', width: '40px', height:'100%', textAlign: 'center'  }}>{key} </th>
                })
            } else{
                return null
            }
        };

        // function BodyTable() {
        //     if (head.length > 0 ){
                
        //         return data.map((rowData,id) => (
        //             <tr key={id}>aa
        //                {/* <th>{id}</th>  */}
        //                        {/* < HeadBodyDetails rowData = {rowData} /> */}      
        //                  {// eslint-disable-next-line array-callback-return
        //                  }
        //                  {/* {   head.map((key, index) => {
        //                       return <th key={index} style={{  width: '20%', height:'100%', textAlign: 'center'  }}> 
        //                      {rowData[key]}
        //                      </th>
        //                  })  } */}
                        
        //            </tr>
        //          ))
        //     } else{
        //         return null
        //     }
        // };

        // function HeadBodyDetails(props) { //no borrar
        //     const {rowData}  = props;

        //     if (head.length > 0 ){
        //          console.log(rowData)
        //          console.log(head)
        //                   // eslint-disable-next-line array-callback-return
        //                   return head.map((key, index) => {
        //                        <th key={index} style={{  width: '20%', height:'100%', textAlign: 'center'  }}> {key}
        //                       {rowData[key]}aaa
        //                       </th>
        //                   })
        //     } else{
        //                     return null
        //                 }
        // };

    return (
        <>
         <div  style={{backgroundColor: 'white', width: '120%'}} >
            <span style={{fontSize: '10px' , marginTop: '25px',  marginLeft: '10px'}}> Resultados Rutas</span>
                < div   style={{display: loading ? 'block': 'none' }}>
                    <div className={classes.content}>
                        <CircularProgress size='' className={classes.progress} color='secondary' />
                    </div>
                </div>

            <Grid item xs  style={{ marginTop: '15px'}}>
            <FormControl className={classes.filterClear}>
                <label style={{marginLeft:'20px' ,background:'#FF1100', color: 'white', borderRadius: '5px', width:'140px', height:'30px', display:false }} disable = 'true'
                    > <i className={"fa fa-upload"} style={{marginTop:'8px', width:"20px", height:"20px",marginLeft:'15px', color : 'white' , marginRight: '5px'}} />Subir Archivo
                    <input type="file"
                        style={{opacity : 0}}
                        onChange={(e) => {
                            const archivo = e.target.files[0];
                            readExcel(archivo);
                        }}
                    />
                </label>
            </FormControl>
          </Grid>
          <span style={{fontSize: '10px' , marginTop: '20px',  marginLeft: '10px'}}> </span>
                    <table >
                    <thead>
                        <tr>
                            <th>-</th>
                            < HeadTable/>
                        </tr>
                    </thead>
                        <tbody>
                        {
                               data.map((rowData,id) => (
                                       <tr key={id}>
                                           <th>{id+1}</th>
                                         {
                                             head.map((key, index) => {
                                                 return <th key={index} style={{  width: 400, height:'100%', textAlign: 'center'  }}>
                                                 {rowData[key]}
                                                 </th>
                                             })
                                         }
                                       </tr>
                                     ))
                            //   < BodyTable/>  {/* no borrar*/}
                        }
                        </tbody>
                    </table>

        </div>

        <FieldSet className={classes.root} style={{height: '13px'}} >
            <div className={classes.tableWrap}>

            </div>
        </FieldSet>

    </>
    )
};

export default CargaMasivaRutasListRutas;