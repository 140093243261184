import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";

export const BaseClientesMap = ({ google }) => {
    const [item, setItem] = useState({});
    const [marker, setMarker] = useState({});
    const [showingInfo, setShowingInfo] = useState(false);
    const coords = { lat: -8.116291741338895, lng: -79.03640628442541 };
    const store = useSelector(state => state.baseclientes.store);
   
    var geo =  store.data.filter(number =>  number.Longitud !== "" && number.Latitud !== "");  
    geo = geo.filter(number =>  (!isNaN(number.Longitud)  && !isNaN(number.Latitud))); 
    const markerObjects=  []; 
    // eslint-disable-next-line array-callback-return
    store.data.map((item, i) =>{
                let dir = 'http://maps.google.com/mapfiles/ms/icons/red-dot.png';
                if (item.Distribuidor === 'NEOLUB') { dir = 'http://maps.google.com/mapfiles/ms/icons/yellow-dot.png' }
                if (item.Distribuidor === 'SMARTOIL') { dir = 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png' }
                if (item.Distribuidor === 'MARZAN') { dir = 'http://maps.google.com/mapfiles/ms/icons/red-dot.png' }
                if (item.Distribuidor === 'COMDIELSA') { dir = 'http://maps.google.com/mapfiles/ms/icons/green-dot.png' }
                if (item.Distribuidor === 'MINERDIESEL') { dir = 'http://maps.google.com/mapfiles/ms/icons/pink-dot.png' }
                if (item.Distribuidor === 'DISKMAR') { dir = 'http://maps.google.com/mapfiles/ms/icons/orange-dot.png' }
                if (item.Distribuidor === 'ITALCAUCHOS') { dir = 'http://maps.google.com/mapfiles/ms/icons/purple-dot.png' }

               markerObjects.push({"lat":parseFloat(item.Latitud),"lng": parseFloat(item.Longitud), 
               url : dir})
            }
        );       

    const onMarkerClick = (marker, item) => {
        setItem(item);
        setMarker(marker);
        setShowingInfo(true);
    };
    
    return (
        <Map
            initialCenter={geo.lemgth > 0 ? { lat: parseFloat(geo[0].Latitud), lng: parseFloat(geo[0].Longitud)}:  coords }
                            google={google}
            style={{width: '100%', height: '100%', position: 'relative'}}
            zoom={12}
        >
            {store.data.map((item, key) => (
                <Marker
                    key={key}
                    position={{lat: parseFloat(item.Latitud), lng: parseFloat(item.Longitud)}}
                    title={item.Cliente}
                    onClick={(props, marker, e) => onMarkerClick(marker, item)}
                    icon={{
                        url: markerObjects[key].url
                    }}
                />
            ))}
            <InfoWindow
                marker={marker}
                visible={showingInfo}
            >
                <div>
                   {item.NIT> 0  &&     <h3>Ruc:  {item.NIT}</h3>  }
                    {item.RUC> 0  &&     <h3>Ruc:  {item.RUC}</h3>  }
                   
                    <h3>{item.Cliente}</h3>
                </div>
                <div>
                    {item.Agente &&     <h3> Agente:  {item.Agente}</h3>  }
                    {item.Distribuidor  &&     <h3>Distribuidor:  <b> {item.Distribuidor}</b></h3>  }
                </div>
                <div>
                    { <h3> Vendedor: {item.Vendedor}</h3>  }
                </div>
            </InfoWindow>
        </Map>
    )
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyCrwct6vhZk5TTSuht7PpA0kAVSdoWSB7s"
})(BaseClientesMap);