import React, {useEffect} from "react";
import {useDispatch } from "react-redux";
import {
    btnSearch,
    btnExport
} from '../../store/marketing/reportepromociones/actions';

import { Body, Footer, Page } from '@sigeco/tools';
import { Button, Icon, makeStyles } from "@material-ui/core";
import ReportePromocionesFilter from "../../components/marketing/reportepromociones/ReportePromocionesFilter";
import ReportePromocionesPanel from "../../components/marketing/reportepromociones/ReportePromocionesPanel";
// import Backdrop from '@material-ui/core/Backdrop';


const useStyles = makeStyles(() => ({
    content: {
        padding: '0.5rem 0.75rem 0.75rem'
    },
    root: {
        flex: '0 0 auto',
        marginBottom: '0.75rem',
        '@media (min-width: 1401px)': {
            marginBottom: '1rem',
        }
    }
}));

/*const handleClose = () => {
    setOpen(false);
};*/




const ReportePromociones = ({onClose, setTitle}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
   // const [open, setOpen] = useState(false);

    useEffect(() => {
        setTitle('Total');
    // eslint-disable-next-line
    }, []);

    function onBtnClose() {
        onClose();
    }
    return(
        <Page className={classes.root}>
            <Body>
            <ReportePromocionesFilter/>
            <ReportePromocionesPanel/>


            </Body>
            <Footer>

                <Button
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={() => dispatch(btnExport())}
                    startIcon={<Icon className="fas fa-file-excel"/>}
                >
                    Exportar
                </Button>

                <Button
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={() => dispatch(btnSearch())}
                    startIcon={<Icon className="fas fa-search"/>}
                >
                    Buscar
                </Button>
                
                <Button 
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={() => onBtnClose()}
                    startIcon={<Icon className="fas fa-door-open"/>}
                >
                    Salir
                </Button>
            </Footer>

          {/*  <Dialog fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Rechazar</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Motivo rechazo"
                        multiline
                        rows={2}
                        fullWidth
                        value={motivoRechazo}
                        onChange={event => dispatch(onUpdateFieldEntity('motivoRechazo', event.target.value))}
                        inputProps={{
                            maxLength: 200
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button color="primary" onClick={() => {
                        handleClose();
                        dispatch(btnReject())
                    }}>
                        Guardar
                    </Button>
                </DialogActions>
                </Dialog>*/}
        </Page>
    )
}

export default ReportePromociones;