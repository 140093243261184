import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import {
    onUpdateColumn,
    setOrder,
    setSelectedRow
} from '../../../store/precios/productos/actions';
import { sort_by} from '../../../helpers/commons'

import { FieldSet, Table } from '@sigeco/tools';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column'
    }, 
    tableWrap: {
        flex: '1 1 auto'
    }
}));

const ProductosList = () => {
    //amarra al reducers
    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();
    const columns = useSelector(state => state.productos.table.columns);
    const loading = useSelector(state => state.productos.store.loading);
    const order = useSelector(state => state.productos.table.order);
    const orderBy = useSelector(state => state.productos.table.orderBy);
    const store = useSelector(state => state.productos.store);
    
    const dispatch = useDispatch();

    const handleDoubleClick = (id) => {
        history.push({
            hash: location.hash,
            pathname: `/precios/productos/${id}`,
            search: location.search
        });
    }

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        dispatch(setOrder(isAsc ? 'desc' : 'asc', property));
        let homes = store.data;
        homes.sort(sort_by(property, isAsc, homes[0][property]));
    };

    const handleResizeColumn = (column) => {
        dispatch(onUpdateColumn(column))
    };

    //function handleChangePage(newPage) {
       // dispatch(setPage(newPage));
        //dispatch(onproductosList());
    //}
    
    //function handleChangeRowsPerPage(event) {
       // dispatch(setPageSize(event.target.value));
        //dispatch(setPage(0));
    //}
    
    return (
        <FieldSet className={classes.root} iconCls="fas fa-th-list" title="Resultados">
            <div className={classes.tableWrap}>
                <Table
                    columns={columns}
                count={store.total}
                loading={loading}
                onRowClick={items => dispatch(setSelectedRow(items))}
                onRowDoubleClick={item => handleDoubleClick(item.productoId)}
                onSort={datakey => handleRequestSort(datakey)}
                onUpdateColumn={handleResizeColumn}
                rowCount={store.data.length}
                rowGetter={index => store.data[index]}
                selected={store.selected}
                sortBy={orderBy}
                sortDirection={order}
                />
            </div>
        </FieldSet>
    )
};

export default ProductosList;