import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  onSearchDistribuidorClick,
  onSearchOlaChannelMapClick,
  onSearchVendedorClick,
  onSearchClienteClick,
  onSearchDepartaClick,
  onSearcMunicipioClick,
  onSearchMarcaClick,
  onSearchLobClick,
  onSearchCanalClick,
  onUpdateOlaFieldFilter,
  onUpdateDistFieldFilter
} from "../../../store/channelmap/reporteconstruccion/actions";

import { ActionField, FieldSet } from "@sigeco/tools";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FormControl from "@material-ui/core/FormControl";
import { Icon } from "@material-ui/core";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(() => ({
  content: {
    padding: "0.5rem 0.75rem 0.75rem",
  },
  filterClear: {
    display: "flex",
  },
  actionField: {
    flexDirection: "row",
    position: "relative",
  },
  btnClear: {
    position: "absolute",
    bottom: "1.5",
    right:20,
    maxWidth: "15px",
    maxHeight: "23px",
    minWidth: "15px",
    minHeight: "23px",
  },
  root: {
    flex: "0 0 auto",
    marginBottom: "0.75rem",
    "@media (min-width: 1401px)": {
      marginBottom: "1rem",
    },
  },
}));

const ReporteConstruccionFilter = () => {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:701px)");
  const filters = useSelector((state) => state.reporteconstruccion.filters);
  const dispatch = useDispatch();

  //Botón de limpiar filtro
  const [, setOla] = useState("");
  const [btn, setBtn] = useState(true);
  const [, setDistribuidor] = useState("");
  const [btn1, setBtn1] = useState(true);
  const [, setCliente] = useState("");
  const [btnc, setBtnc] = useState(true);
  const [vendedor, setVendedor] = useState("");
  const [btn2, setBtn2] = useState(true);
  const [, setDepartamento] = useState("");
  const [btn3, setBtn3] = useState(true);
  const [, setMunicipio] = useState("");
  const [btn4, setBtn4] = useState(true);
  const [, setMarca] = useState("");
  const [btn5, setBtn5] = useState(true);
  const [, setLob] = useState("");
  const [btn6, setBtn6] = useState(true);
  const [, setCanal] = useState("");
  const [btn7, setBtn7] = useState(true);

  useEffect(() => {
    if (filters.dOla) {
      setOla(filters.dOla);
      setBtn(false);
      setVendedor("");
      setBtn2(true);
    } else {
      setBtn(true);
    }
  }, [filters.dOla]);

  useEffect(() => {
    if (filters.dDistribuidor) {
      setDistribuidor(filters.dDistribuidor);
      setBtn1(false);
      setVendedor("");
      setBtn2(true);
    } else {
      setBtn1(true);
    }
  }, [filters.dDistribuidor]);

  useEffect(() => {
    if (filters.dCliente) {
      setCliente(filters.dCliente);
      setBtnc(false);
    } else {
      setBtnc(true);
    }
  }, [filters.dCliente]);

  useEffect(() => {
    console.log(filters.vendedor.length)
    if (filters.vendedor.length !== 0) {
      setVendedor("Filtro Seleccionado");
      setBtn2(false);
    } else {
      setBtn2(true);
    }
  }, [filters.vendedor]);

  useEffect(() => {
    if (filters.dDepartamento) {
      setDepartamento(filters.dDepartamento);
      setBtn3(false);
    } else {
      setBtn3(true);
    }
  }, [filters.dDepartamento]);

  useEffect(() => {
    if (filters.dMunicipio) {
      setMunicipio(filters.dMunicipio);
      setBtn4(false);
    } else {
      setBtn4(true);
    }
  }, [filters.dMunicipio]);

  useEffect(() => {
    if (filters.dMarca) {
      setMarca(filters.dMarca);
      setBtn5(false);
    } else {
      setBtn5(true);
    }
  }, [filters.dMarca]);

  useEffect(() => {
    if (filters.dLob) {
      setLob(filters.dLob);
      setBtn6(false);
    } else {
      setBtn6(true);
    }
  }, [filters.dLob]);

  useEffect(() => {
    if (filters.dCanal) {
      setCanal(filters.dCanal);
      setBtn7(false);
    } else {
      setBtn7(true);
    }
  }, [filters.dCanal]);
  
  const handleOla = () => {
    setOla("");
    setCliente("");
    setVendedor("");
    setDepartamento("");
    setMunicipio("");
    setLob("");
    setMarca("");
    setCanal("");
    filters.olaId = 0;
    filters.dOla = "";
    dispatch(onUpdateOlaFieldFilter);
    // filters.nit = "";
    //  filters.dCliente = "";
    // filters.vendedor = "";
    // filters.dDepartamento = "";
    // filters.dMunicipio = "";
    // filters.dLob = "";
    // filters.dMarca = "";
    // filters.dCanal = "";
    setBtn(true);
    setBtnc(true);
    setBtn2(true);
    setBtn3(true);
    setBtn4(true);
    setBtn5(true);
    setBtn6(true);
    setBtn7(true);
  };

  const handleDist = () => {
    
    setDistribuidor("");
    setCliente("");
    setVendedor('');
    setDepartamento("");
    setMunicipio("");
    setLob("");
    setMarca("");
    setCanal("");
    filters.distribuidorId = 0;
    filters.dDistribuidor = "";
    dispatch(onUpdateDistFieldFilter)
    // filters.nit = "";
    // filters.dCliente = "";
    // filters.vendedor = "";
    // filters.dDepartamento = "";
    // filters.dMunicipio = "";
    // filters.dLob = "";
    // filters.dMarca = "";
    // filters.dCanal = "";
    setBtnc(true);
    setBtn1(true);
    setBtn2(true);
    setBtn3(true);
    setBtn4(true);
    setBtn5(true);
    setBtn6(true);
    setBtn7(true);
  };
  
  const handleCliente= () => {
    setDepartamento("");
    setMunicipio("");
    setCliente("");
    filters.nit = "";
    filters.dCliente = "";
    filters.dDepartamento = "";
    filters.dMunicipio = "";
    setBtnc(true);
    // setBtn2(true);
    setBtn3(true);
    setBtn4(true);
  };

  const  handleVendedor = () => {
    setVendedor("");
    setDepartamento("");
    setMunicipio("");
    filters.vendedor = "";
    filters.dDepartamento = "";
    filters.dMunicipio = "";
    setBtn2(true);
    setBtn3(true);
    setBtn4(true);
  };

  const handleDepartamento = () => {
    setDepartamento("");
    setMunicipio("");
    filters.dDepartamento = "";
    filters.dMunicipio = "";
    setBtn3(true);
    setBtn4(true);
  };

  const handleMunicipio = () => {
    setMunicipio("");
    filters.dMunicipio = "";
    setBtn4(true);
  };

  const handleMarca = () => {
    setMarca("");
    filters.dMarca = "";
    setBtn5(true);
  };

  const handleLob = () => {
    setLob("");
    filters.dLob = "";
    setBtn6(true);
  };

  const handleCanal = () => {
    setCanal("");
    filters.dCanal = "";
    setBtn7(true);
  };

  return (
    <div className={classes.root}>
      <FieldSet
        iconCls="fas fa-filter"
        title="Filtros"
        defaultExpanded
        expandable
      >
        <Grid className={classes.content} container spacing={2}>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={3} sm={3} md={3} lg={3}>
              <FormControl className={classes.filterClear}>
                <ActionField
                  className={classes.actionField}
                  label="Ola Channel Map"
                  placeholder="Enter para buscar ola channel map"
                  value={filters.dOla}
                  variant={matches ? "outlined" : "standard"}
                  onHandleAction={() => dispatch(onSearchOlaChannelMapClick())}
                />
                <Button
                  className={classes.btnClear}
                  size="small"
                  startIcon={<Icon className="fa fa-window-close" />}
                  disabled={btn}
                  onClick={handleOla}
                />
              </FormControl>
            </Grid>

            <Grid item xs={6} sm={3} md={3} lg={3}>
              <FormControl className={classes.filterClear}>
                <ActionField
                  className={classes.actionField}
                  label="Agente"
                  placeholder="Enter para buscar Agente"
                  value={filters.dDistribuidor}
                  variant={matches ? "outlined" : "standard"}
                  onHandleAction={() => dispatch(onSearchDistribuidorClick())}
                />
                <Button
                  className={classes.btnClear}
                  size="small"
                  startIcon={<Icon className="fa fa-window-close" />}
                  disabled={btn1}
                  onClick={handleDist}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <FormControl className={classes.filterClear}>
                <ActionField
                className={classes.actionField}
                autoComplete="off"
                fullWidth
                  label="Vendedor"
                  name="vendedor"
                  value={vendedor}
                  variant={matches ? "outlined" : "standard"}
                  onHandleAction={() => dispatch(onSearchVendedorClick())}
                />
                <Button
                  className={classes.btnClear}
                  size="small"
                  startIcon={<Icon className="fa fa-window-close" />}
                  disabled={btn2}
                  onClick={handleVendedor}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3}>
              <FormControl className={classes.filterClear}>
                <ActionField
                  className={classes.actionField}
                  label="Cliente"
                  placeholder="Enter para buscar Cliente"
                  value={filters.dCliente}
                  variant={matches ? "outlined" : "standard"}
                  onHandleAction={() => dispatch(onSearchClienteClick())}
                />
                <Button
                  className={classes.btnClear}
                  size="small"
                  startIcon={<Icon className="fa fa-window-close" />}
                  disabled={btnc}
                  onClick={handleCliente}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container item xs={12} spacing={2}>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <FormControl className={classes.filterClear}>
                <ActionField
                  className={classes.actionField}
                  label="Departamento"
                  placeholder="Enter para buscar Departamento"
                  value={filters.dDepartamento}
                  variant={matches ? "outlined" : "standard"}
                  onHandleAction={() => dispatch(onSearchDepartaClick())}
                />
                <Button
                  className={classes.btnClear}
                  size="small"
                  startIcon={<Icon className="fa fa-window-close" />}
                  disabled={btn3}
                  onClick={handleDepartamento}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <FormControl className={classes.filterClear}>
                <ActionField
                  className={classes.actionField}
                  label="Provincia"
                  placeholder="Enter para buscar Provincia"
                  value={filters.dMunicipio}
                  variant={matches ? "outlined" : "standard"}
                  onHandleAction={() => dispatch(onSearcMunicipioClick())}
                />
                <Button
                  className={classes.btnClear}
                  size="small"
                  startIcon={<Icon className="fa fa-window-close" />}
                  disabled={btn4}
                  onClick={handleMunicipio}
                />
              </FormControl>
            </Grid>

            <Grid item xs={6} sm={3} md={3} lg={2}>
              <FormControl className={classes.filterClear}>
                <ActionField
                  className={classes.actionField}
                  label="Marca"
                  placeholder="Enter para buscar Marca"
                  value={filters.dMarca}
                  variant={matches ? "outlined" : "standard"}
                  onHandleAction={() => dispatch(onSearchMarcaClick())}
                />
                <Button
                  className={classes.btnClear}
                  size="small"
                  startIcon={<Icon className="fa fa-window-close" />}
                  disabled={btn5}
                  onClick={handleMarca}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={2}>
              <FormControl className={classes.filterClear}>
                <ActionField
                  className={classes.actionField}
                  label="Lob"
                  placeholder="Enter para buscar Lob"
                  value={filters.dLob}
                  variant={matches ? "outlined" : "standard"}
                  onHandleAction={() => dispatch(onSearchLobClick())}
                  onChange={handleLob}
                />
                <Button
                  className={classes.btnClear}
                  size="small"
                  startIcon={<Icon className="fa fa-window-close" />}
                  disabled={btn6}
                  onClick={handleLob}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={2}>
              <FormControl className={classes.filterClear}>
                <ActionField
                  className={classes.actionField}
                  label='Canal'
                  placeholder="Enter para buscar Canal" 
                  value={filters.dCanal}
                  variant={matches ? "outlined" : "standard"}
                  onHandleAction={() => dispatch(onSearchCanalClick())}
                />
                <Button
                  className={classes.btnClear}
                  size="small"
                  startIcon={<Icon className="fa fa-window-close" />}
                  disabled={btn7}
                  onClick={handleCanal}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </FieldSet>
    </div>
  );
};

export default ReporteConstruccionFilter;
