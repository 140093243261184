import {
    CLEAN_FORM,
    SET_STATE_FORM,
    UPDATE_FIELD_FILTER,
    SET_MASK,
    RECEIVE_DATA,
    UPDATE_TABLE_COLUMN,
    ERROR_V_LIST,
    RECEIVE_V_LIST,
    REQUEST_V_LIST,
    SET_ORDER,
    SET_SELECTED_MULTI,
    SET_PAGE_SIZE,
    CLEAR_SELECT,
    RESTART_STATE,
    ADD_VISITA,
    UPDATE_FIELD_ENTITY,
    SET_ENTITY
} from './types';

const initialState = {
    formState: 'search',
    loading: false,
    data:[],
    openPopup: false,
    entity: {
        distribuidorId: 0,
        dDistribuidor: "",
        codigo: '',
        desde: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        dVendedor: "",
        vendedorId: "", // esta variable se usa como usuarioId
        vId: 0,
        RutaPlanificadaId: 0,
        Dcliente: '',
        direccion: ''
    },
    filters: {
        distribuidorId: 0,
        dDistribuidor: "",
        codigo: '',
        desde: new Date(new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()),
        dVendedor: "",
        vendedorId: "", // esta variable se usa como usuarioId
        vId: 0
    },
    store: {
        loading: false,
        pantalla: false,
        pageSize: 50,
        start: 0,
        total: 0,
        data: [],
        datax:[],
        aux:[],
        selected: [],
        selectmulti:[]
    },
    arreglo:{
        dataVisita:[]
    },
    aux: {

        agregar:false,        
        index: 0,
    },
    table: {
        order: 'asc',
        orderBy: '',
        columns: [
            { dataKey: 'SucursalId', label: 'Sucursal', width: 80 },
            { dataKey: 'ProspectoId', label: 'Prospecto', width: 80 },
            { dataKey: 'DCliente', label: 'Nombre Sucursal', width: 370 },
            { dataKey: 'Direccion', label: 'Dirección', width: 460 },
            { dataKey: 'Estado', label: 'Estado', width: 140 },
            { dataKey: 'IPT', label: 'IPT', width: 352 },   
           // { dataKey: 'UsuarioRegistro', label: 'UsuarioRegistro', width: 352}    
        ]
    }
};

export const planificarvisitaReducer = (state, action) => {
    state = state || initialState;

    switch(action.type) {
  
        case CLEAN_FORM:
            return {
                ...state,
                entity: initialState.entity,
                filers: initialState.filers,
                errors: initialState.errors
            };
        case SET_STATE_FORM:
            return {
                ...state,
                formState: action.formState
            };
        case UPDATE_FIELD_FILTER:
            return { 
                ...state,
                filters: {
                    ...state.filters,
                    [action.key]: action.value
                }
            };
        case ERROR_V_LIST:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: false
                }
            };
        case RECEIVE_V_LIST:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: false,
                    total: action.total,
                    data: action.payload,
                    source: null
                } 
            };
        case REQUEST_V_LIST:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: false,
                    source: action.source
                }
            };
        case RECEIVE_DATA:
            return {
                ...state,
                data: action.payload,
                store: {
                    ...state.store,
                    data: action.storeData
                }
            };

        case UPDATE_TABLE_COLUMN:

            return {
                ...state,
                table: {
                    ...state.table,
                    columns: action.columns
                }
            };

        case ADD_VISITA:
            
            return {
                ...state,
                store: {
                    ...state.store,
                    datax: action.payload
                    }
            };  
        
        
        case SET_ENTITY:

            return {
                ...state,
                entity: {
                    ...state.entity,                  
                    Dcliente: action.payload.DCliente,
                    direccion: action.payload.Direccion,
                    desde: action.payload.Fecha,
                    RutaPlanificadaId: action.payload.RutaPlanificadaId
                }
            };

    
        case UPDATE_FIELD_ENTITY:
            
            return {
                ...state,
                entity: {
                    ...state.entity,
                    [action.key]: action.value
                }
        };            

        case SET_MASK:
            return {
                ...state,
                loading: action.loading
        };
        case SET_ORDER:
            return {
                ...state,
                table: {
                    ...state.table,
                    order: action.order,
                    orderBy: action.orderBy
                }
            };
        case SET_SELECTED_MULTI:
            return {
                ...state,
                store: {
                    ...state.store,
                    selected: action.payload
                }
            };
        case SET_PAGE_SIZE:
        return {
            ...state,
            store: {
                ...state.store,
                pageSize: action.pageSize
            }
        };
        case CLEAR_SELECT: 
        return {
            ...state,
            store: {
                ...state.store,
                [action.key] : []
            }
        };
        case RESTART_STATE:
            return {
                ...initialState
        };
    default:
            return state;
    }
};