import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    onSearchDistribuidorClick,
    onSearchRolClick,
    onSearchUsuarioClick,
    onUpdateFieldEntity
} from '../../store/user/actions';

import Checkbox from '@material-ui/core/Checkbox';
import { ActionField, FieldSet } from '@sigeco/tools';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import useMediaQuery from '@material-ui/core/useMediaQuery';


const useStyles = makeStyles((theme) => ({
    checkbox: {
        color: "#9c9c9c",
        padding: 0
    },
    details: {
        display: 'flex',
        padding: '0.5rem 0.75rem 0.75rem'
    },
    label: {
        alignItems: 'center',
        color: 'rgba(0, 0, 0, 0.54)', 
        display: 'flex', 
        fontSize: '0.8125rem', 
        width: 90
    },
    paper: {
        marginBottom: '0.75rem',
        '@media (min-width: 1401px)': {
            marginBottom: '1rem'
        }
    }
}));

const UserForm = () => {
    const classes = useStyles();
    const matches = useMediaQuery('(min-width:701px)');
    const entity = useSelector(state => state.user.entity);
    const errors = useSelector(state => state.user.errors);
    const formState = useSelector(state => state.user.formState);
    const dispatch = useDispatch();
    const isView = formState === 'view';

    function onFieldChange(event) {
        dispatch(onUpdateFieldEntity(event.target.name, event.target.value));
    }

    function onCheckBoxChange(event) {
        dispatch(onUpdateFieldEntity(event.target.name, event.target.checked));
    }

    function onCopyClick() {
        var copyText = document.getElementById("txtClave");

        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
    }

    return (
        <div>
            <FieldSet className={classes.paper} iconCls="fas fa-address-card" title="Cuenta">
                <div className={classes.details}>
                    <Grid container spacing={1}>
                        {entity.usuarioId !== '' && (<Grid item xs={12} container>
                            <Grid item xs={matches ? 6 : 12}>
                                <TextField
                                    label="Identificador"
                                    value={entity.usuarioId}
                                    variant={matches ? "outlined" : "standard"}
                                    fullWidth
                                    inputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>
                        </Grid>)}
                        <Grid item xs={12} container>
                            <Grid item xs={matches ? 6 : 12}>
                                <TextField
                                    autoComplete="off"
                                    error={errors.nombre !== ''}
                                    fullWidth
                                    helperText={errors.nombre}
                                    label="Nombres"
                                    name="nombre"
                                    onChange={onFieldChange}
                                    required
                                    value={entity.nombre}
                                    variant={matches ? "outlined" : "standard"}
                                    inputProps={{
                                        maxLength: 50,
                                        readOnly: isView || formState === 'restart'
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container>
                            <Grid item xs={matches ? 4 : 12}>
                                <TextField
                                    autoComplete="off"
                                    error={errors.nombreUsuario !== ''}
                                    fullWidth
                                    helperText={errors.nombreUsuario}
                                    name="nombreUsuario"
                                    label="Usuario"
                                    value={entity.nombreUsuario}
                                    variant={matches ? "outlined" : "standard"}
                                    onChange={onFieldChange}
                                    required
                                    inputProps={{
                                        maxLength: 128,
                                        readOnly: isView || entity.usuarioId !== ''
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container>
                            <Grid item xs={matches ? 4 : 12}>
                                <TextField
                                    autoComplete="off"
                                    error={errors.correo !== ''}
                                    fullWidth
                                    helperText={errors.correo}
                                    name="correo"
                                    label="Correo"
                                    value={entity.correo}
                                    variant={matches ? "outlined" : "standard"}
                                    onChange={onFieldChange}
                                    required
                                    inputProps={{
                                        maxLength: 256,
                                        readOnly: isView || formState === 'restart'
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container>
                            <Grid item xs={matches ? 4 : 12}>
                                <ActionField
                                    error={errors.rol !== ''}
                                    helperText={errors.rol}
                                    hiddenAction={isView}
                                    label="Rol"
                                    placeholder="Enter para buscar rol"
                                    value={entity.rol}
                                    variant={matches ? "outlined" : "standard"}
                                    onHandleAction={() => dispatch(onSearchRolClick())}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container>
                            <Grid item xs={matches ? 4 : 12}>
                                <ActionField
                                    hiddenAction={isView}
                                    label="Reporta a"
                                    placeholder="Enter para buscar usuario"
                                    value={entity.dJerarquia}
                                    variant={matches ? "outlined" : "standard"}
                                    onHandleAction={() => dispatch(onSearchUsuarioClick())}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container>
                            <Grid item xs={matches ? 4 : 12}>
                                <ActionField
                                    error={errors.distribuidor !== ''}
                                    helperText={errors.distribuidor}
                                    hiddenAction={isView}
                                    label="Agente"
                                    placeholder="Enter para buscar agente"
                                    value={entity.dDistribuidor}
                                    variant={matches ? "outlined" : "standard"}
                                    onHandleAction={() => dispatch(onSearchDistribuidorClick())}
                                />
                            </Grid>
                        </Grid>
                        {entity.rol === 'Vendedor' && (<Grid item xs={12} container>
                            <Grid item xs={matches ? 4 : 12}>
                                <TextField
                                    name="codigoVendedor"
                                    autoComplete="off"
                                    label="Código Vendedor"
                                    value={entity.codigoVendedor}
                                    variant={matches ? "outlined" : "standard"}
                                    onChange={onFieldChange}
                                    fullWidth
                                    inputProps={{
                                        maxLength: 20,
                                        readOnly: isView || formState === 'restart'
                                    }}
                                />
                            </Grid>
                        </Grid>)}
                        <Grid item xs={12} container>
                            <Grid item xs={matches ? 4 : 12}>
                                <TextField
                                    name="documentoIdentidad"
                                    autoComplete="off"
                                    label="Documento Identidad"
                                    value={entity.documentoIdentidad}
                                    variant={matches ? "outlined" : "standard"}
                                    onChange={onFieldChange}
                                    fullWidth
                                    inputProps={{
                                        maxLength: 20,
                                        readOnly: isView || formState === 'restart'
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container>
                            <Grid item xs={matches ? 4 : 12}>
                                <TextField
                                    name="telefono"
                                    autoComplete="off"
                                    label="Teléfono"
                                    value={entity.telefono}
                                    variant={matches ? "outlined" : "standard"}
                                    onChange={onFieldChange}
                                    fullWidth
                                    inputProps={{
                                        maxLength: 16,
                                        readOnly: isView || formState === 'restart'
                                    }}
                                />
                            </Grid>
                        </Grid>
                        {(entity.usuarioId === '' || formState === 'restart') && (<Grid item xs={12} container>
                            <Grid item xs={matches ? 4 : 12}>
                                <TextField
                                    label="Clave"
                                    value={entity.clave}
                                    variant={matches ? "outlined" : "standard"}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Copiar al portapapeles" placement="top-start" arrow>
                                                    <IconButton className="fas fa-copy" onClick={onCopyClick} style={{ fontSize: '0.875rem' }}/>
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                        id: 'txtClave',
                                        readOnly: true
                                    }}
                                />
                            </Grid>
                        </Grid>)}
                    </Grid>
                </div>
            </FieldSet>
            <FieldSet className={classes.paper} iconCls="fas fa-cog" title="Configuración">
                <div className={classes.details}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} container>
                            <div className={classes.label}>Expira Clave:</div>
                            <Checkbox 
                                name="expiraClaveHabilitado"
                                className={classes.checkbox} 
                                checked={entity.expiraClaveHabilitado} 
                                color="primary" 
                                disabled={isView || formState === 'restart'} 
                                disableRipple
                                onChange={onCheckBoxChange}
                                label="Expira Clave"
                            />
                        </Grid>
                        <Grid item xs={12} container>
                            <div className={classes.label}>Bloqueo:</div>
                            <Checkbox 
                                name="bloqueoHabilitado"
                                className={classes.checkbox} 
                                checked={entity.bloqueoHabilitado} 
                                color="primary" 
                                disabled={isView || formState === 'restart'} 
                                disableRipple
                                onChange={onCheckBoxChange}
                                label="Bloqueo"
                            />
                        </Grid>
                        <Grid item xs={12} container>
                            <div className={classes.label}>Activo:</div>
                            <Checkbox
                                name="activo"
                                className={classes.checkbox} 
                                checked={entity.activo} 
                                color="primary" 
                                disabled={isView || formState === 'restart'} 
                                disableRipple
                                onChange={onCheckBoxChange}
                                inputProps={{
                                    readOnly: isView
                                }}
                            />
                        </Grid>
                    </Grid>
                </div>
            </FieldSet>
        </div>
    )
};

export default UserForm;