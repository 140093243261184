import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    RECEIVE_V_LIST2,
    SET_MASK,
  } from '../../../store/fuerzaventas/gestionpreciosespeciales/types'
 
 
import './table.css'

import makeStyles from '@material-ui/core/styles/makeStyles';
import * as XLSX from "xlsx";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import { closeMessageBox, openMessageBox } from "../../../store/main/actions";
import {cabecera}from '../../../store/fuerzaventas/gestionpreciosespeciales/actions'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        width: '120%'
    },
    progress: {
        height: '2rem',
        width: '2rem',
        '@media (min-width: 701px)': {
            height: '2.5rem',
            width: '2.5rem',
        },
    },
        content: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: 'calc(50% - 20px)',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    tableWrap: {
        flex: '1 1 auto'
    }
}));

const GestionPreciosEspecialesImportar = () => {
    const classes = useStyles();
    const loading = useSelector(state => state.gestionpreciosespeciales.loading);
    const store = useSelector(state => state.gestionpreciosespeciales.stored);
    const col = useSelector(state => state.gestionpreciosespeciales.store.col);

    const dispatch = useDispatch();
      const [data, setData] = useState([]);
      const [, setFile] = useState([]);
      const [head, setHead] = useState([]);

      useEffect(() => {
        setData( store.data)
       if (store.data.length > 0 ){
      }
        return () => {
             setData( [])
        };
    }, [ store.data]);

      function  readExcel (par)  {

            if (par === '' || par === undefined){
                    setFile('')
                    return
            }
       
            dispatch({ type: RECEIVE_V_LIST2, columns: [], payload: [] });
            const allowedExtensions = /(.xls|.xlsx)$/i;
            if(!allowedExtensions.test(par.name)){
                   alert('USE EL FORMATO CORRECTO PARA LA CARGA');
                   return
            }else{

                const promise = new Promise((resolve, reject) => {
                    const fileReader = new FileReader();
                    fileReader.readAsArrayBuffer(par);

                    fileReader.onload = (e) => {
                    const bufferArray = e.target.result;

                    const wb = XLSX.read(bufferArray, { type: "buffer" });

                    const wsname = wb.SheetNames[0];
                    
                    const ws = wb.Sheets[wsname];
                  

                    const data = XLSX.utils.sheet_to_json(ws);
                  

  //*********************Validación si Carga un Archivo Vacio con Formato Correcto**************** */
                if(data.length === 0){
                                    
                    dispatch(
                    openMessageBox({
                        button: "ok",
                        icon: "warning",
                        message: "El Archivo no puede estar vacio..",
                        callback: () => dispatch(closeMessageBox()),
                    })
                    );
                    dispatch({ type: SET_MASK, loading: false });

                    return;
                }else{
//************************************************************************************************ */                  

                        var local = [];

                        setHead (head.push(Object.keys(data[0])));
                        let leng = head.length -1
                            // eslint-disable-next-line array-callback-return
                            if(head.length > 1){
                                let head2 = []
                                head2.push(head[leng])
                                if(JSON.stringify(col) === JSON.stringify(head2[0])=== true ){
                                    head2.map((item, i) => {
                                        for (var i = 0; i < item.length; i++) {
                                            var hotel2 = item[i];
                                            local.push(hotel2);
                                        }
                                    })
                                    setHead(local);
                                    resolve(data);
                                    setFile(data);
                                }else{

                                    dispatch(
                                        openMessageBox({
                                        button: "ok",
                                        icon: "warning",
                                        message: 'Formato de Columnas no Válido',
                                        callback: () => dispatch(closeMessageBox()),
                                        })
                                    );
                                    setHead([])
                                    dispatch({ type: SET_MASK, loading: false });
                                    return; 
                                }
                            }else{ 
                                if(JSON.stringify(col) === JSON.stringify(head[0])=== true ){
                                   //head.map( item => { return  item.map((el,i) => { return ( hotel = el[i], local.push(hotel)) puedes reemplazarlo pero no lo he probado

                                    head.map((item, j)=> {
                                        for (var i=0; i< item.length; i++)
                                        {
                                            var hotel = item[i];
                                            local.push(hotel);
                                        }
                                    })
                                    setHead(local);

                                    resolve(data);
                                    setFile(data);
                                }else{
                                    dispatch(
                                        openMessageBox({
                                        button: "ok",
                                        icon: "warning",
                                        message: 'Formato de Columnas no Válido',
                                        callback: () => dispatch(closeMessageBox()),
                                        })
                                    );
                                    setHead([])
                                    dispatch({ type: SET_MASK, loading: false });
                                    return; 
                                }
                            }//Termina el if de carga archivo por segunda vez
                        }//fin del if de formato no puede estar vacio
                    }; // fin del if de Formato de Carga

                    fileReader.onerror = (error) => {
                                            reject(error);
                    };
                });
            
                promise.then((d) => {
                  
                    dispatch({ type: RECEIVE_V_LIST2, columns: Object.keys(d[0]), payload: d });
                    //dispatch(cabecera());
                    setTimeout(() => {
                      }, 2000);
                });
            }    
        };

    
        function HeadTable() {
            if (head.length > 0 ){

               return head.map((key, index) => {
                    return <th key={index} style={{ backgroundColor: 'lightsteelblue',  height:'100%', textAlign: 'center' , width: '1000px', 
                    wordwrap: 'break-word' }}>{key} </th>
                })
            } else{
                return null
            }
        };

        // function checkErrorField(valueField) {
        //     //setChangeBackgroundColor(value.includes("Error:") ? 'orange': 'white')
    
        //     const valueResponde = JSON.parse(window.localStorage.getItem('respondeUploadFile'))
    
        //     if (valueResponde !== undefined && valueResponde !== null && valueResponde === true)
        //         return typeof valueField === "string" && valueField.includes("No") ? 'orange' : 'white'
        //     else
        //         return 'white'
        // }
    

    return (
        <>
         <div  style={{backgroundColor: 'transparent', width: '100%'}} >
            <span style={{fontSize: '12px' , marginTop: '25px',  marginLeft: '10px'}}> Importar Precios Especiales</span>
                < div   style={{display: loading ? 'block': 'none' }}>
                    <div className={classes.content}>
                    </div>
                </div>

            <Grid item xs  style={{ marginTop: '15px'}}>
                <FormControl className={classes.filterClear}>
                    <label style={{marginLeft:'20px' ,background:'#e62e2d', color: 'white', borderRadius: '5px', width:'140px', height:'30px', display:false }} disable = 'true'
                    > <i className={"fa fa-upload"} style={{marginTop:'8px', width:"20px", height:"20px",marginLeft:'15px', color : 'white' , marginRight: '5px'}} />Subir Archivo
                        <input type="file"
                            style={{opacity : 0}}
                            onChange={(e) => {
                                const archivo = e.target.files[0];
                                readExcel(archivo);
                            }}
                        />
                    </label>
                </FormControl>
          </Grid>
          <span style={{fontSize: '10px' , marginTop: '20px',  marginLeft: '10px'}}> </span>
                    <table >
                    <thead>
                        <tr>
                            <th>-</th>
                              { data.length > 0 &&   < HeadTable/>}
                        </tr>
                    </thead>
                        <tbody>
                        {
                           store.data.length > 0 &&   data.map((rowData,id) => (
                                       <tr key={id}>
                                           <th>{id+1}</th>
                                         {
                                           store.data.length > 0 &&  head.map((key, index) => {
                                                 return <th key={index} style={{  width: index=== 155 ?  '30000000px' : '',  background:  index  === 154 ?   'lightsteelblue' : 'white' /*checkErrorField(rowData[key]) */,   height:'100%',  wordwrap: 'break-word', textAlign: 'center'  }}>
                                                 {rowData[key]}
                                                 </th>
                                             })
                                         }
                                       </tr>
                                     ))
                        }
                        </tbody>
                    </table>
        </div>

    </>
    )
};

export default GestionPreciosEspecialesImportar;