import {
    CLEAN_FORM,
    SET_STATE_FORM,
    UPDATE_FIELD_FILTER,
    SET_MASK,
    RECEIVE_DATA,
    UPDATE_TABLE_COLUMN,
    ERROR_V_LIST,
    RECEIVE_V_LIST,
    REQUEST_V_LIST,
    RECEIVE_V_LIST2
} from './types';

const initialState = {
    render: 'search',
    loading: false,
    entity: {
        vendedorId: 0,
        nombre: ''
    },
    errors: {
        codigo: '',
        usuario: '',
        documentoIdentidad: ''
    },
    filters: {
        nombre: '',
        distribuidorId: 0,
        dDistribuidor: '',
        codigo: '',
        band: false,

    },
    store: {
        loading: false,
        pageSize: 50,
        start: 0,
        total: 0,
        data: [],
        selected: [],
        //source: null
    },

    stored: {
        loading: false,
        pageSize: 50,
        start: 0,
        total: 0,
        data: [],
        selected: [],
        //source: null
    },
    table: {
        order: 'asc',
        orderBy: '',
        columns: [
            { dataKey: 'Codigo', label: 'Codigo', width: 90 },
            { dataKey: 'CodigoFabrica', label: 'Codigo de Fabrica', width: 100 },
            { dataKey: 'DProducto', label: 'Descripción del Producto', width: 440 },
            { dataKey: 'Costo', label: 'Costo', width: 440 },
            { dataKey: 'Fecha', label: 'Fecha',  width: 185 },
            { dataKey: 'UnidadMedida', label: 'Unidad de medida', width: 200 },
            { dataKey: 'Activo', label: 'Activo', type: 'boolean', width: 85 },
            { dataKey: 'FechaRegistro', label: 'FechaRegistro',  width: 185 }
            
        ]
    },
    tablexport: {
        order: 'asc',
        orderBy: '',
        columns: [
            { dataKey: 'Codigo', label: 'Codigo', width: 90 },
            { dataKey: 'DProducto', label: 'Descripcion', width: 440 },
            { dataKey: 'Costo', label: 'Costo', width: 440 },
            
        ]
    }
};

export const costosproductosReducer = (state, action) => {
    state = state || initialState;

    switch(action.type) {
        case CLEAN_FORM:
            return {
                ...state,
                entity: initialState.entity,
                errors: initialState.errors
            };
        case SET_STATE_FORM:
            return {
                ...state,
                formState: action.formState
            };
        case UPDATE_FIELD_FILTER:
            return { 
                ...state,
                filters: {
                    ...state.filters,
                    [action.key]: action.value
                }
            };
            case ERROR_V_LIST:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: false
                }
            };
            case RECEIVE_V_LIST:
                return {
                    ...state,
                    store: {
                        ...state.store,
                        loading: false,
                        total: action.total,
                        data: action.payload,
                        source: null
                    }
                };

            case RECEIVE_V_LIST2:
                return {
                    ...state,
                    stored: {
                        ...state.stored,
                        loading: false,
                        total: action.total,
                        data: action.payload,
                        source: null
                    }
                };    


            case REQUEST_V_LIST:
                return {
                    ...state,
                    store: {
                        ...state.store,
                        loading: false,
                        source: action.source
                    }
                };
            case RECEIVE_DATA:
                return {
                    ...state,
                    data: action.payload,
                    store: {
                        ...state.store,
                        data: action.storeData
                    }
                };
                case UPDATE_TABLE_COLUMN:
                    return {
                        ...state,
                        table: {
                            ...state.table,
                            columns: action.columns
                        }
                    };
            case SET_MASK:
                return {
                    ...state,
                    loading: action.loading
            };
        default:
            return state;
    }
};