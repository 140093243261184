export const CLEAN_FORM = 'fuerzaventas/cargamasivarutas/clean-form';
export const ADVANCED_SEARCH_CLOSE = 'main/advanced-search-close';
export const ADVANCED_SEARCH_OPEN = 'main/advanced-search-open';
export const SET_STATE_FORM = 'fuerzaventas/cargamasivarutas/set-state-form';
export const UPDATE_FIELD_FILTER = 'fuerzaventas/cargamasivarutas/update-field-filter';
export const RECEIVE_V_LIST = 'fuerzaventas/cargamasivarutas/receive-v-list';
export const RECEIVE_X_LIST = 'fuerzaventas/cargamasivarutas/receive-x-list';
export const RECEIVE_W_LIST = 'fuerzaventas/cargamasivarutas/receive-w-list';
export const REQUEST_V_LIST = 'fuerzaventas/cargamasivarutas/request-v-list';
export const ERROR_V_LIST = 'fuerzaventas/cargamasivarutas/error-v-list';
export const SET_MASK = 'fuerzaventas/cargamasivarutas/set-mask';
export const SET_ORDER = 'fuerzaventas/cargamasivarutas/set-order';
export const RECEIVE_DATA= 'fuerzaventas/cargamasivarutas/receibe-data';
export const UPDATE_TABLE_COLUMN= 'fuerzaventas/cargamasivarutas/update-table-column';
export const SET_PAGE = 'fuerzaventas/cargamasivarutas/set-page';
export const SET_SELECTED_ROW = 'fuerzaventas/cargamasivarutas/set-selected-row';
export const ON_SUCURSAL = "fuerzaventas/cargamasivarutas/on-sucursal";
export const REQUEST_X_LIST = "fuerzaventas/cargamasivarutas/request-x-list";
export const SET_PAGE_SIZE = "fuerzaventas/cargamasivarutas/ser-page-size"