import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    btnExport,
    btnExport1,
    btnSearch,
    closeDialogClient,
    filterMapByVendedor
} from '../../store/movilidad/reportevisitas/actions';

import { Body, Footer, Page } from '@sigeco/tools';
import AppBar from '@material-ui/core/AppBar';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import ReporteVisitasFilter from '../../components/movilidad/reportevisitas/ReporteVisitasFilter';
import ReporteVisitasList from '../../components/movilidad/reportevisitas/ReporteVisitasList';
import ReporteVisitasListDetail from '../../components/movilidad/reportevisitas/ReporteVisitasListDetail';
import ReporteVisitasMap from '../../components/movilidad/reportevisitas/ReporteVisitasMap';
import ReporteVisitasSummary from '../../components/movilidad/reportevisitas/ReporteVisitasSummary';

//import ReporteVisitasPedidoList from '../../components/movilidad/reportevisitas/ReporteVisitasPedidoList';
//import ReporteVisitasGrap from '../../components/movilidad/reportevisitas/ReporteVisitasGrap';

const ReporteVisitas = ({onClose, setTitle}) => {

    const loading = useSelector(state => state.reportevisitas.loading);
    const openPopup = useSelector(state => state.reportevisitas.openPopup);

    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
  

    useEffect(() => {
        setTitle('Gestión de Visitas');
    // eslint-disable-next-line
    }, []);

    function onBtnClose() {
        onClose();
    }

    function onMapOpenClick(dVendedor) {
        dispatch(filterMapByVendedor(dVendedor));
        setOpen(true);
    }

    return (
        <Page>
            <Backdrop style={{ position: 'absolute', zIndex: 1200 }} open={loading} transitionDuration={0}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Body>
                <ReporteVisitasFilter />
                <ReporteVisitasSummary />    
                <ReporteVisitasList onMapOpen={onMapOpenClick} />
            </Body>
            <Footer>

                <Button
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={() => dispatch(btnExport1())}
                    startIcon={<Icon className="fas fa-file-excel"/>}
                >
                    Exportar Resumen
                </Button>       

                <Button
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={() => dispatch(btnExport())}
                    startIcon={<Icon className="fas fa-file-excel"/>}
                >
                    Exportar Detalle
                </Button>

               
                <Button
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={() => dispatch(btnSearch())}
                    startIcon={<Icon className="fas fa-search"/>}
                >
                    Buscar
                </Button>
                <Button
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={() => onMapOpenClick('')}
                    startIcon={<Icon className="fas fa-map-marked-alt"/>}
                >
                    Ver Mapa
                </Button>
                <Button 
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={() => onBtnClose()}
                    startIcon={<Icon className="fas fa-door-open"/>}
                >
                    Salir
                </Button>
            </Footer>
            <Dialog fullScreen open={open} onClose={() => setOpen(false)}>
                <AppBar>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => setOpen(false)} aria-label="close">
                            <Icon className="fas fa-times" />
                        </IconButton>
                        <Typography variant="h6">
                            Localización de visitas 
                        </Typography>
                    </Toolbar>
                </AppBar>
                <ReporteVisitasMap />
            </Dialog>
            <Dialog fullScreen open={openPopup}  style={{overflow: 'none'}} onClose={() => dispatch(closeDialogClient()) }>
                <AppBar position="relative" style={{overflow: 'none'}}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => dispatch(closeDialogClient())} aria-label="close">
                            <Icon className="fas fa-times" />
                        </IconButton>
                        <Typography variant="h6">
                            Lista de clientes
                        </Typography>
                    </Toolbar>
                </AppBar>
                <ReporteVisitasListDetail />
            </Dialog>
        </Page>
    )
};

export default ReporteVisitas;