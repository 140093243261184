import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import {
     setOrder,
     onUpdateColumn,
     setSelectedMulti,setPageSize
} from "../../../store/movilidad/planificarvisita/actions";
import { sort_by} from '../../../helpers/commons'

import { FieldSet, Table } from '@sigeco/tools';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column'
    }, 
    tableWrap: {
        flex: '1 1 auto',
        //overflowY: 'auto',
        padding: '1rem 0'
    }
}));

const PlanificarVisitaList = ({onMapOpen}) => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const columns = useSelector(state => state.planificarvisita.table.columns);
    const loading = useSelector(state => state.planificarvisita.loading);
    const order = useSelector(state => state.planificarvisita.table.order);
    const orderBy = useSelector(state => state.planificarvisita.table.orderBy);
    const store = useSelector(state => state.planificarvisita.store);
    const formState = useSelector(state => state.planificarvisita.formState);

    const dispatch = useDispatch();

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        dispatch(setOrder(isAsc ? 'desc' : 'asc', property));
        let homes = store.data;
        homes.sort(sort_by(property, isAsc, homes[0][property]));
    };

    const handleResizeColumn = (column) => {
        dispatch(onUpdateColumn(column))
    }
   
    const handleDoubleClick = (item) => {
        //console.log(item)
       dispatch(saveVisita(item));
        history.push({
            hash: location.hash,
            pathname: `/movilidad/planificarvisita/${item}`,
            search: location.search
        });
    }

    const saveVisita = (item)=> (dispatch, getState) => {
        getState().planificarvisita.store.aux = item
        //console.log(getState().comentarioTipo.store.aux)
     }

    function handleChangeRowsPerPage(event) {
        dispatch(setPageSize(event.target.value));
       
    }
 
    return ( 
        <div className={classes.root}>
        <FieldSet className={classes.root} iconCls="fas fa-th-list" title="Resultados"   >
            <div className={classes.tableWrap} >
                <Table 
                    // columns={columns}
                    // count={store.total}
                    // loading={loading}
                    // multiSelect ={formState === 'new'? false : true}  
                    // onChangeRowsPerPage={handleChangeRowsPerPage}
                    // onRowClick={item => dispatch(setSelectedMulti(item)) }
                    columns={columns}
                    count={store.total}
                    loading={loading}
                    //onChangePage={handleChangePage}
                  //  onChangeRowsPerPage={handleChangeRowsPerPage}
                    onRowClick={() => {}}
                    onRowDoubleClick={items => handleDoubleClick(items) }
                    // onSort={datakey => handleRequestSort(datakey)}
                    // onUpdateColumn={handleResizeColumn}
                    // rowCount={store.data.length}
                    // rowGetter={index => store.data[index]}
                    // selected={store.selected}
                    // rowsPerPage={store.pageSize}
                    // sortBy={orderBy}
                    // sortDirection={order}
                    onSort={datakey => handleRequestSort(datakey)}
                    onUpdateColumn={handleResizeColumn}
                   // page={store.start/store.pageSize}
                   // paginate
                    rowCount={store.data.length}
                    rowGetter={index =>  store.data[index] }
                    rowsPerPage={store.pageSize}
                    selected={store.selected}
                    sortBy={orderBy}
                    sortDirection={order}
                />
            </div>
        </FieldSet>        
       </div>
    )
};

export default PlanificarVisitaList;