import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//import { LinkContainer } from 'react-router-bootstrap';
//import { useLocation, useRouteMatch } from "react-router-dom";
import {
    btnSearch,
    onExport
} from '../../store/fuerzaventas/metas/actions';

import { Body, Footer, Page } from '@sigeco/tools';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';

import MetasFilter from '../../components/fuerzaventas/metas/MetasFilter';
import MetasList from '../../components/fuerzaventas/metas/MetasList';

const Metas = ({onClose, setTitle}) => {

    //const location = useLocation();
    //const match = useRouteMatch();
    const formState = useSelector(state => state.metas.formState);
    const loading = useSelector(state => state.metas.loading);
    //const vendedorId = useSelector(state => state.metas.entity.vendedorId);
    const dispatch = useDispatch();

    useEffect(() => {
        setTitle('Búsqueda');
    // eslint-disable-next-line
    }, []);

    function onBtnClose() {
        onClose();
    }

    return (
        <Page>
            <Backdrop style={{ position: 'absolute', zIndex: 1200 }} open={loading} transitionDuration={0}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <Body>
            {formState === 'search' && <MetasFilter />}
            {formState === 'search' && <MetasList />}
        </Body>
        <Footer>
        {formState === 'search' && <Button
                color="primary" 
                size="small" 
                variant="outlined" 
                onClick={() => dispatch(onExport())}
                startIcon={<Icon className="fas fa-file-excel"/>}
            >
                Exportar
            </Button>}
            {formState === 'search' && <Button
                color="primary" 
                size="small" 
                variant="outlined"
                onClick={() => dispatch(btnSearch())}
                startIcon={<Icon className="fas fa-search"/>}
            >
                Buscar
            </Button>}
            <Button 
            color="primary" 
            size="small" 
            variant="outlined" 
            onClick={() => onBtnClose()} 
            startIcon={<Icon className="fas fa-door-open"/>}
            >
            Salir
            </Button>
        </Footer>
        </Page>
    )
};

export default Metas;