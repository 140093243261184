import {
  ERROR_SEGUIMIENTO_LIST,
  RECEIVE_SEGUIMIENTO_LIST,
  REQUEST_SEGUIMIENTO_LIST,
  RESTART_STATE,
  SET_MASK,
  SET_ORDER,
  SET_SELECTED_ROW,
  UPDATE_FIELD_FILTER,
  UPDATE_TABLE_COLUMN,
  UPDATE_FIELD_DATA,
} from "./types";

const initialState = {
  loading: false,
  filters: {
    nombre: "",
    distribuidorId: 0,
    dDistribuidor: "",
    usuarioId: "",
    dVendedor: "",
    vendedorId : '',
    desde: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    hasta: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    ),
  },
  store: {
    loading: false,
    data: [],
    selected: [],
  },
  table: {
    order: "asc",
    orderBy: "",
    columns: [],
  },
};

export const carteravendedorReducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case ERROR_SEGUIMIENTO_LIST:
      return {
        ...state,
        store: {
          ...state.store,
          loading: false,
        },
      };
    case RECEIVE_SEGUIMIENTO_LIST:
      return {
        ...state,
        store: {
          ...state.store,
          loading: false,
          data: action.payload,
          selected: [],
        },
        table: {
          ...state.table,
          columns: action.columns,
        },
      };
    case REQUEST_SEGUIMIENTO_LIST:
      return {
        ...state,
        store: {
          ...state.store,
          //loading: true,
        },
      };
    case RESTART_STATE:
      return {
        ...initialState,
      };
    case SET_MASK:
      return {
        ...state,
        loading: action.loading,
      };
    case SET_ORDER:
      return {
        ...state,
        table: {
          ...state.table,
          order: action.order,
          orderBy: action.orderBy,
        },
      };
    case SET_SELECTED_ROW:
      return {
        ...state,
        store: {
          ...state.store,
          selected: action.selected,
        },
      };
    case UPDATE_FIELD_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.key]: action.value,
        },
      };
    case UPDATE_TABLE_COLUMN:
      return {
        ...state,
        table: {
          ...state.table,
          columns: action.columns,
        },
      };
    case UPDATE_FIELD_DATA:
      return {
        ...state,
        store: {
          ...state.store,
          [action.key]: action.value,
        },
      };
    default:
      return state;
  }
};
