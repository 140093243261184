import {
    CLEAN_FORM,
    SET_STATE_FORM,
    UPDATE_FIELD_FILTER,
    SET_MASK,
    SET_ORDER,
    RECEIVE_DATA,
    UPDATE_TABLE_COLUMN,
    ERROR_V_LIST,
    RECEIVE_V_LIST,
    REQUEST_V_LIST,
    RECEIVE_V_LIST2,
    CLEAN_DATA2
} from './types';

const initialState = {
    formState: 'search',
    loading: false,
    entity: {
        vendedorId: 0,
        nombre: ''
    },
    errors: {
        codigo: '',
        usuario: '',
        documentoIdentidad: ''
    },
    filters: {
        nombre: '',
        distribuidorId: 0,
        dDistribuidor: '',
        codigo: '',
        dLista: '',
        codigoLista: 0,
        band: false,
        indexPago: [],
        indexCodigo: [],
        valor: '',
    },
    store: {
        loading: false,
        pageSize: 50,
        start: 0,
        total: 0,
        data: [],
        selected: [],
        col: ['Codigo', 'Descripcion', 'UnidadMedida', 'PrecioEstandar', 'PrecioMinimo', 'Descuento', 'Cantidad', 'TipoDePago']
        //source: null
    },
    stored: {
        loading: false,
        pageSize: 50,
        start: 0,
        total: 0,
        data: [],
        selected: [],
        //source: null
    },
    table: {
        order: 'asc',
        orderBy: '',
        columns: [
            { dataKey: 'codigo', label: 'Código', width: 90 },
            { dataKey: 'dProducto', label: 'Descripción', width: 200 },
            { dataKey: 'unidadMedida', label: 'Unidad de Medida', width: 90 },
            { dataKey: 'precioEstandar', label: 'Precio Estandar', width: 90 },
            { dataKey: 'precioVariable', label: 'Precio Mínimo',  width: 90 },
            { dataKey: 'descuento', label: 'Descuento',  width: 90 },
            { dataKey: 'cantidad', label: 'Cantidad',  width: 80 },
            { dataKey: 'desde', label: 'Desde', width: 100 },
            { dataKey: 'hasta', label: 'Hasta', width: 100 },
            { dataKey: 'tipoDePago', label: 'Tipo de Pago',  width: 80 },
            { dataKey: 'segmento', label: 'Segmento',  width: 80 },
            { dataKey: 'fechaModificacion', label: 'Fecha Modificación',  width: 100 }
        ],
        // columns2: [
        //     { dataKey: 'codigo', label: 'Código', width: 90 },
        //     { dataKey: 'dProducto', label: 'Descripción', width: 300 },
        //     { dataKey: 'unidadMedida', label: 'Unidad de Medida', width: 90 },
        //     { dataKey: 'precioEstandar', label: 'Precio Estandar', width: 90 },
        //     { dataKey: 'precioVariable', label: 'Precio Mínimo',  width: 90 },
        //     { dataKey: 'descuento', label: 'Descuento',  width: 90 },
        //     { dataKey: 'cantidad', label: 'Cantidad',  width: 90 },
        //     { dataKey: 'desde', label: 'Desde', width: 100 },
        //     { dataKey: 'hasta', label: 'Hasta', width: 100 },
        //     { dataKey: 'tipoDePago', label: 'Tipo de Pago',  width: 90 },
        //     { dataKey: 'fechaModificacion', label: 'Fecha Modificación',  width: 120 }
        // ]
    },
    tablexport: {
        order: 'asc',
        orderBy: '',
        columns: [
            { dataKey: 'codigo', label: 'Codigo', width: 90 },
            { dataKey: 'dProducto', label: 'Descripcion', width: 400 },
            { dataKey: 'unidadMedida', label: 'UnidadMedida', width: 90 },
            { dataKey: 'precioEstandar', label: 'PrecioEstandar', width: 90 },
            { dataKey: 'precioVariable', label: 'PrecioMinimo',  width: 90 },
            { dataKey: 'descuento', label: 'Descuento',  width: 90 },
            { dataKey: 'cantidad', label: 'Cantidad',  width: 90 },
            { dataKey: 'tipoDePago', label: 'TipoDePago',  width: 90 },
            { dataKey: 'segmento', label: 'Segmento',  width: 90 }
        ]
    }
};

export const gestionpreciosReducer = (state, action) => {
    state = state || initialState;

    switch(action.type) {
        case CLEAN_FORM:
            return {
                ...state,
                entity: initialState.entity,
                errors: initialState.errors
            };
        case SET_STATE_FORM:
            return {
                ...state,
                formState: action.formState
            };
        case UPDATE_FIELD_FILTER:
            return { 
                ...state,
                filters: {
                    ...state.filters,
                    [action.key]: action.value
                }
            };
            case ERROR_V_LIST:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: false
                }
            };
            case RECEIVE_V_LIST:
                return {
                    ...state,
                    store: {
                        ...state.store,
                        loading: false,
                        total: action.total,
                        data: action.payload,
                        source: null
                    }
                };
            
            case RECEIVE_V_LIST2:
                return {
                    ...state,
                    stored: {
                        ...state.store,
                        loading: false,
                        total: action.total,
                        data: action.payload,
                        source: null
                    }
                };    

            case REQUEST_V_LIST:
                return {
                    ...state,
                    store: {
                        ...state.store,
                        loading: false,
                        source: action.source
                    }
                };
            case RECEIVE_DATA:
                return {
                    ...state,
                    data: action.payload,
                    store: {
                        ...state.store,
                        data: action.storeData
                    }
                };
                case UPDATE_TABLE_COLUMN:
                    return {
                        ...state,
                        table: {
                            ...state.table,
                            columns: action.columns
                        }
                    };
            case SET_MASK:
                return {
                    ...state,
                    loading: action.loading
            };

            case SET_ORDER:
                return {
                    ...state,
                    table: {
                    ...state.table,
                    order: action.order,
                    orderBy: action.orderBy,
                    },
                };
            case CLEAN_DATA2:
                return {
                    ...state,
                    stored: {
                        ...state.stored,
                        data: []
                    }
                };
        default:
            return state;
    }
};