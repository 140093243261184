import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";

export const ListarProspectosMap = ({ google }) => {
    const [item, setItem] = useState({});
    const [marker, setMarker] = useState({});
    const [showingInfo, setShowingInfo] = useState(false);
    const coords = { lat: -8.116291741338895, lng: -79.03640628442541 };
    const store = useSelector(state => state.listarprospectos.store);    
    const vred = 'https://maps.google.com/mapfiles/ms/icons/orange-dot.png';
    const vgreen = 'https://maps.google.com/mapfiles/ms/icons/green-dot.png'

    const Dta =  store.data.filter(number =>  number.Longitud !== "" && number.Latitud !== "" &&  number.Longitud !== 0 && number.Latitud !== 0
    && number.Longitud !== '0.0' && number.Latitud !== 0
    );  
    
    const markerObjects=  []; 

   Dta.map((item, i) =>
      markerObjects.push({"lat": parseFloat(item.Latitud),"lng": parseFloat(item.Longitud)} )
      );

    const onMarkerClick = (marker, item) => {
        setItem(item);
        setMarker(marker);
        setShowingInfo(true);
    };
    
    return (
        <Map
            initialCenter={Dta.length > 0 ?  markerObjects[0] :  coords}
            google={google}
            style={{width: '100%', height: '100%', position: 'relative'}}
            zoom={12}
        >
            {store.data.map((item, key) => (
                <Marker
                    key={key}
                    position={{lat: parseFloat(item.Latitud), lng: parseFloat(item.Longitud)}}
                    title={item.NombreSucursal}
                    onClick={(props, marker, e) => onMarkerClick(marker, item)}
                    icon={ item.Anulado === 1 ?  vgreen  :vred}
                />
            ))}
            <InfoWindow
                marker={marker}
                visible={showingInfo}
            >
                <div>
                    <h3>{item.NombreComercial}</h3>
                    <h3>V-{item.Nombre}</h3>
                </div>
            </InfoWindow>
        </Map>
    )
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyCrwct6vhZk5TTSuht7PpA0kAVSdoWSB7s"
})(ListarProspectosMap);