export const CLEAN_FORM = 'fuerzaventas/preciostock/clean-form';
export const ADVANCED_SEARCH_CLOSE = 'main/advanced-search-close';
export const ADVANCED_SEARCH_OPEN = 'main/advanced-search-open';
export const SET_STATE_FORM = 'fuerzaventas/preciostock/set-state-form';
export const UPDATE_FIELD_FILTER = 'fuerzaventas/preciostock/update-field-filter';
export const RECEIVE_V_LIST = 'fuerzaventas/preciostock/receive-v-list';
export const REQUEST_V_LIST = 'fuerzaventas/preciostock/request-v-list';
export const ERROR_V_LIST = 'fuerzaventas/preciostock/error-v-list';
export const SET_MASK = 'fuerzaventas/preciostock/set-mask';
export const SET_ORDER = 'fuerzaventas/preciostock/set-order';
export const RECEIVE_DATA= 'fuerzaventas/preciostock/receibe-data';
export const UPDATE_TABLE_COLUMN= 'fuerzaventas/preciostock/update-table-column';
export const SET_PAGE = 'fuerzaventas/preciostock/set-page';
export const SET_SELECTED_ROW = 'fuerzaventas/preciostock/set-selected-row';