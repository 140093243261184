import React, { useState } from 'react';

import Icon from '@material-ui/core/Icon';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ListarProspectosList from './ListarProspectosList';
import ListarProspectosGraph from './ListarProspectosGraph';

const useStyles = makeStyles(theme => ({
    paper: {
        flex: 1
    },
    tabsHorizontal: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    tabsVertical: {
        borderRight: `1px solid ${theme.palette.divider}`,
    }
}));

const ReporteSeguiminetoPanel = () => {
    const classes = useStyles();
    const big = useMediaQuery('(min-width:1201px)');
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Paper className={classes.paper}>
            <div style={{ display: 'flex', flexDirection: big ? "row" : "column", height: '100%' }}>
                <Tabs
                    orientation={big ? "vertical" : "horizontal"}
                    variant="fullWidth"
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="icon label tabs example"
                    className={big ? classes.tabsVertical : classes.tabsHorizontal}
                >
                    <Tab icon={<Icon className="fas fa-chart-pie" />} label="Listado" style={{ textTransform: "none",  backgroundColor: value === 0 ? "#C9C9C9" : "" }} />
                    <Tab icon={<Icon className="fas fa-chart-area" />} label="Gráfico" style={{ textTransform: "none",  backgroundColor: value === 1 ? "#C9C9C9" : "" }} />
                </Tabs>
         
                {value === 0 && ( <ListarProspectosList />   )}      
               
                {value === 1 && (<ListarProspectosGraph />)}
              
            </div>
        </Paper>
    );
}

export default ReporteSeguiminetoPanel ;