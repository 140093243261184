import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { FieldSet, Table } from "@sigeco/tools";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  detallePedido,
  setOrder,
  onUpdateColumn,
} from "../../../store/movilidad/reportepedidos/actions";
import { useState } from "react";
import { sort_by } from "../../../helpers/commons";
import Modal from "@material-ui/core/Modal";
import { formatoPeru } from "../../../helpers/commons";
import Button from "@material-ui/core/Button";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
  },
  tableWrap: {
    flex: "1 1 auto",
  },
  modal: {
    top: "50%",
    left: "50%",
    transform: 'translate(-50%, -50%)',
    position: "absolute",
    height: "100%",
    maxHeight: 500,
    maxWidth: 800,
    width: "80%",
    display: "block",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1,0, 1, 5),
    alignItems: "center",
    justifyContent: "center",
    fontSize: "max(1 vw, 5px)",
    borderRadius: "15px",
  },
  bot: {
    marginLeft: "10px",
    marginTop:"5px",
  },
  
  a: {
    maxwidth: "900px",
    width: "150px",
    fontSize: "15px",
  },
  b: {
    maxwidth: "900px",
    width: "250px",
    fontSize: "15px",
  },
  c: {
    width: "90px",
    fontSize: "15px",
  },
  d: {
    width: "140px",
    fontSize: "15px",
  },
  resul: {
    fontSize: "10px !important",
    background: "white",
    textAlign: "Left !important",
  },
  resuld: {
    fontSize: "10px !important",
    textAlign: "Right !important",
    background: "white",
  },
   cuadrin:{
    marginLeft: "-22px",
    "@media (max-width: 495px)": {
      width: "100%",
      marginLeft: "-20px",
      overflowY: "hidden",
      height: "28%",
      //marginBottom:"10px"
    },
    // "@media (max-width: 375px)": {
    //   width: "100%",
    //   marginLeft: "-20px",
    //   overflowY: "hidden",
    //   marginBottom:"18px"
    // },
    // "@media (max-width: 336px)": {
    //   width: "100%",
    //   marginLeft: "-20px",
    //   overflowY: "hidden",
    //   //paddingBottom:"30px",
    //   marginBottom:"20px"
    // },

  },
 
}));

function ReportePedidosList() {
  const classes = useStyles();
  const [modalInfo, setModalInfo] = React.useState([]);
  const columns = useSelector((state) => state.reportepedidos.table.columns);
  const loading = useSelector((state) => state.reportepedidos.loading);
  const order = useSelector((state) => state.reportepedidos.table.order);
  const orderBy = useSelector((state) => state.reportepedidos.table.orderBy);
  const store = useSelector((state) => state.reportepedidos.store);
  const dispatch = useDispatch();
  const [, setShowModal] = useState(false);
  const [show, setShow] = useState(false);
  const HandleClose = () => setShow(false);
  const HandleShow = () => setShow(true);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    dispatch(setOrder(isAsc ? "desc" : "asc", property));
    let homes = store.data;
    homes.sort(sort_by(property, isAsc, homes[0][property]));
  };

    //--------------------------------------------------------------
  const Clicke = (item) => {
    let items= []
    items.push(item)
    dispatch(detallePedido(items[0]));
    setModalInfo(items[0]);
    console.log()
    setShowModal(HandleShow);
  };

  const handleResizeColumn = (column) => {
    dispatch(onUpdateColumn(column));
  };

  const ModalContent = () => {
    const datad = useSelector((state) => state.reportepedidos.store.detalle);
    let total = 0;
    return (
      <div>
         <Modal
          fullscreen={"xxl"}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={show}
          onClose={HandleClose}
        >
          <div style={{ display: 'flex', flex: '1', maxHeight: '370px' }}>

            <div className={classes.modal} style={{ height: (datad.length === 1 || datad.length === 2 || datad.length === 3) ? '290px' : (datad.length === 3 || datad.length === 4) ? '320px' : '340px' }}>
              <h2 style={{ textAlign: "center", fontSize: "18px" ,"@media (max-width: 450px)": { fontSize: "10px"}}}>
 
                Detalle de Pedido
              </h2>

              <div  className={classes.cuadrin}  style={{display: 'flex', flex: '1', marginLefth:'-20px'}} >
                <table style={{width:"800px",}}>
                  <tbody >
                    <tr>

                      <td style={{ width: "85px", backgroundColor: "#BFD9FF", fontSize: "10px", fontWeight: "bold" }} >CODIGO PEDIDO:</td>
                      <td colSpan="2" style={{ width: "85px", fontSize: "10px" }}> <b>{modalInfo.Pedido} </b></td>
                     
                      <td style={{ width: "85px", backgroundColor: "#BFD9FF", fontSize: "10px", fontWeight: "bold" }} >NIT:</td>
                      <td colSpan="2" style={{ width: "85px", fontSize: "10px" }}> <b>{modalInfo["NIT/RUC"]} </b></td>
                      <td style={{ width: "15px", backgroundColor: "#BFD9FF", fontSize: "10px", fontWeight: "bold" }}>ESTADO:</td>
                      <td style={{ width: "60px", color : modalInfo.Estado === 'P' ? 'blue' : modalInfo.Estado ===  'R' ? 'red' :'green' , fontSize: "10px" }}>{modalInfo.Estado === 'P' ? 'PENDIENTE' : modalInfo.Estado === 'R'
                        ? 'RECHAZADO' : modalInfo.Estado === 'A' ? 'APROBADO' : 'REVISADO'}</td>
                      <td style={{ width: "50px", backgroundColor: "#BFD9FF", fontSize: "10px", fontWeight: "bold" }}>FECHA:</td>
                      <td style={{ fontSize: "10px", width: "65px" }}>{modalInfo.Fecha}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "85px", backgroundColor: "#BFD9FF", fontSize: "10px", fontWeight: "bold" }}> CLIENTE:</td>
                      <td colSpan="3" style={{ width: "185px", fontSize: "10px" }}>{modalInfo.Cliente}</td>
                      <td style={{ width: "35px", backgroundColor: "#BFD9FF", fontSize: "10px", fontWeight: "bold" }}>VENDEDOR:</td>
                      <td colSpan="5" style={{ width: "85px", fontSize: "10px" }}>{modalInfo.Nombre}</td>
                    </tr>
                   
                    <tr>
                      <td style={{ width: "85px", backgroundColor: "#BFD9FF", fontSize: "10px", fontWeight: "bold" }}> OBSERVACIÓN:</td>
                      <td colSpan="9" style={{ width: "85px", fontSize: "10px" }}><b> {modalInfo.Observacion} </b></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <br></br>
              <div className={classes.cuadro} style={{ marginLeft: '-20px',  width:'100%' }}>
                <div style={{ display: "none", border: 'none' }}>
                  {!loading &&
                    datad.map(
                      (ite) => (total = total + ite.cantidad * ite.precioFinal)
                    )}
                </div>
                <div  style={{ width: '100%', height: datad.length >= 7 ? "145px " : "180px", overflowX: datad.length >= 4 ? "auto" : "hidden", marginTop: "-10px" }}>
                  <table
                    className="aprobacion"
                    id="tableMod"
                    style={{ borderCollapse: "collapse", width: '100%' }}
                  >
                    <thead >
                      <tr>
                        <th className={classes.a} >
                          Código
                        </th>
                        <th className={classes.b}>
                          Descripción
                        </th>
                        <th className={classes.c}>
                          Cantidad
                        </th>
                        <th className={classes.d}>
                          Precio
                        </th>
                      </tr>
                    </thead>
                    <tbody style={{ border: "1px solid #BFD2E9" }}>
                      {!loading &&
                        datad.map((item, index) => (
                          <tr
                            style={{ backgroundColor: "Gainsboro" }}
                            key={index}
                          >
                            <td className={classes.resul}>
                              {item.codigoProducto}
                            </td>
                            <td className={classes.resul}>
                              {item.dProducto}
                            </td>
                            <td className={classes.resuld}>
                              {item.cantidad}
                            </td>
                            <td className={classes.resuld}>
                              {formatoPeru('en-US', 'USD', item.precioFinal)}
                            </td>
                          </tr>
                        ))}
                      {datad.length > 0 && !loading && (
                        <tr key={1000} style={{ backgroundColor: "white", border: 'none' }} >
                          <td className={classes.resuld} colSpan="5"  >
                            <b style={{ fontSize: "11px" }}>Total: {formatoPeru('en-US', 'USD', total)}</b>
                          </td>
                        </tr>
                      )}
                      {datad.length === 0 && (
                        <tr key={1}>   {/*style={{backgroundColor: 'Gainsboro'}}*/}
                          <td className={classes.resul} colSpan="5"  >No existe Registro</td> {/*style={{fontSize:'12px', background:'white'}} */}

                        </tr>
                      )}
                    </tbody>
                    {loading && (
                      <tbody>
                        <tr >
                          <td colSpan="4">
                            <img
                              alt="log"
                              src="https://app.iess.gob.ec/iess-fondo-reserva-afiliado-web/javax.faces.resource/images/load.gif.jsf;jsessionid=8949b76cfea43d283add3bd3dd2d?ln=sentinel-layout"
                              width="40"
                              height="40"
                            />
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>

                </div>
              </div>
              <br></br>
              <br></br>
              <br></br>
              <br></br>

              <div style={{ backgroundcolor: 'red',
                         position: 'absolute',
                         bottom: 5,
                         width: '100%',
                         height: '40px',
                         color: 'white' }}>
                
                  <div className="container"   style= {{ position: 'relative', float: 'right',  liststyletype: 'none',  right: '8%', display:'inline-block'}}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={HandleClose}
                      className={classes.bot}
                      style={{}}
                    >
                      Cerrar
                    </Button>
                  </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  };

  return (
    <FieldSet
      className={classes.root}
      iconCls="fas fa-th-list"
      title="Resultados"
    >
      <div style={{ display: "none" }}>
        <ReactHTMLTableToExcel
          id="table-xls-button-rpt-pedidos"
          className=""
          table="table-to-xls"
          filename="Rpt_pedidos"
          sheet="Pedidos"
          buttonText=""
        />
      </div>

      <div className={classes.tableWrap}>
        <Table
          columns={columns}
          count={store.total}
          loading={loading}
          onRowClick={(items) => {}}
          onRowDoubleClick={(items) => Clicke(items)}
          onSort={(datakey) => handleRequestSort(datakey)}
          onUpdateColumn={handleResizeColumn}
          rowCount={store.data.length}
          rowGetter={(index) => store.data[index]}
          selected={store.selected}
          sortBy={orderBy}
          sortDirection={order}
          noRowsRenderer={() => <div>hola</div>}
        />
      </div>
      <div className="App">{show ? <ModalContent /> : null}</div>
    </FieldSet>
  );
} 

export default ReportePedidosList;
 