import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    //  setOrder,
    //  onUpdateColumn,
    RECEIVE_V_LIST,
    RECEIVE_W_LIST,
  } from "../../../store/fuerzaventas/cargamasivarutas/types";
import './table.css'

import makeStyles from '@material-ui/core/styles/makeStyles';
import * as XLSX from "xlsx";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
//import { Table } from "@sigeco/tools";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        width: '120%'
    },
    progress: {
        height: '2rem',
        width: '2rem',
        '@media (min-width: 701px)': {
            height: '2.5rem',
            width: '2.5rem',
        },
    },
        content: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: 'calc(50% - 20px)',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    tableWrap: {
        flex: '1 1 auto'
    }
}));

const CargaMasivaListRutasClientes = () => {
  
    const classes = useStyles();
   // const columns = useSelector((state) => state.cargamasivarutas.table.columns);
    const loading = useSelector(state => state.cargamasivarutas.loading);

    const store = useSelector(state => state.cargamasivarutas.store);


    const dispatch = useDispatch();

      const [data, setData] = useState([]);
      const [, setFile] = useState([]);
      const [head, setHead] = useState([]);

      useEffect(() => {
          setData( store.data2) 
            if (store.data.length > 0 ){
                dispatch({ type: RECEIVE_V_LIST, columns: [], payload: [] });
            }
            return () => {
                // ChatAPI.unsubscribeFromFriendStatus(props.friend.id, handleStatusChange);
                    setData( [])
               };
    // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [store.data2, data]);

      function isNumeric(value) {
        return Number.isInteger((value))
      } 
      
      function  readExcel (par)  { //=>
            if (par === '' || par === undefined){
            setFile('')
            return
            }

            const allowedExtensions = /(.xls|.xlsx)$/i;
            if(!allowedExtensions.test(par.name)){
                   alert('USE EL FORMATO CORRECTO PARA LA CARGA');
                   return
            }else{
                    const promise = new Promise((resolve, reject) => {
                    const fileReader = new FileReader();
                    fileReader.readAsArrayBuffer(par);

                        fileReader.onload = (e) => {
                        const bufferArray = e.target.result;

                        const wb = XLSX.read(bufferArray, { type: "buffer" });

                        const wsname = wb.SheetNames[1];

                        const ws = wb.Sheets[wsname];

                        const data = XLSX.utils.sheet_to_json(ws);

                        var local = [];

                        setHead (head.push(Object.keys(data[0])));

                        //console.log((Object.keys(data[0])))
                        //console.log((data[0]))
                      

                            // eslint-disable-next-line array-callback-return
                            head.map((item, j)=> {
                                for (var i=0; i< item.length; i++)
                                {
                                    //Para obtener el objeto de tu lista
                                    var hotel = item[i];
                                    local.push(hotel);
                                }
                            })
                            local.push('resultado')
                            setHead(local);

                        resolve(data);
                        setFile(data);
                        };

                        fileReader.onerror = (error) => {
                        reject(error);
                        };
                    });

                    promise.then((d) => {    

                        if (d.length > 0  || d){
                            d.map( e =>  isNumeric(e.NITCliente ) ?  e.NITCliente  =  (e.NITCliente.toString()): null);
                            dispatch({ type: RECEIVE_W_LIST, columns: Object.keys(d[0]), payload: d });
                        }
                    // dispatch({ type: SET_MASK, loading: false });
                    });
                }    
        };

        function HeadTable() {
            if (head.length > 0 ){
               return head.map((key, index) => {
                    return <th key={index} style={{ backgroundColor: '#5F9EFC', height:'100%', textAlign: 'center'  }}>{key} </th>
                })
            } else{
                return null
            }
        };

    return (
        <>
         <div  style={{backgroundColor: 'white', width: '120%'}} >
            <span style={{fontSize: '10px' , marginTop: '25px',  marginLeft: '10px'}}> Resultados Cientes</span>
                < div   style={{display: loading ? 'block': 'none' }}>
                    <div className={classes.content}>
                        <CircularProgress size='' className={classes.progress} color='secondary' />
                    </div>
                </div>

            <Grid item xs  style={{ marginTop: '15px'}}>
            <FormControl className={classes.filterClear}>
                <label style={{marginLeft:'20px' ,background:'#FF1100', color: 'white', borderRadius: '5px', width:'140px', height:'30px', display:false }} disable = 'true'
                > <i className={"fa fa-upload"} style={{marginTop:'8px', width:"20px", height:"20px",marginLeft:'15px', color : 'white' , marginRight: '5px'}} />Subir Archivo
                    <input type="file"
                        style={{opacity : 0}}
                        onChange={(e) => {
                            const archivo = e.target.files[0];
                            readExcel(archivo);
                        }}
                    />
                </label>
            </FormControl>
          </Grid>
          <span style={{fontSize: '10px' , marginTop: '20px',  marginLeft: '10px'}}> </span>
                <table  style={{display : data && data.length > 0 ? 'block' : 'none'}}>
                    <thead>
                        <tr>
                            <th>-</th>
                            < HeadTable/>
                        </tr>
                    </thead>
                        <tbody>
                        { data && data.length > 0 &&
                               data.map((rowData,id) => (
                                       <tr key={id}>
                                           <th>{id+1}</th>
                                         {
                                             head.map((key, index) => {
                                                 return <th key={index} style={{  height:'100%', textAlign: 'center'  }}>
                                                 {rowData[key]}
                                                 </th>
                                             })
                                         }
                                       </tr>
                                     ))
                            //   < BodyTable/>  {/* no borrar*/}
                        }
                        </tbody>
                    </table>
        </div>
    </>
    )
};

export default CargaMasivaListRutasClientes;