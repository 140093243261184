import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Icon from "@material-ui/core/Icon";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {onUpdateFieldFilter}from'../../../store/fuerzaventas/gestionbonificacion/actions'

import GestionBonificacionList from "./GestionBonificacionList";
import GestionBonificacionImportar from "./GestionBonificacionImportar";

const useStyles = makeStyles((theme) => ({
    paper: {
        flex: 1,
    },
    tabsHorizontal: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        minWidth: 450, // a number of your choice
        width: 380, // a number of your choice
    },
    tabsVertical: {
        borderRight: `1px solid ${theme.palette.divider}`,
        minWidth: 180, // a number of your choice
        width: 180, // a number of your choice
    },
    root: {
        display: "flex",
        flex: "1 1 auto",
        flexDirection: "column",
        overflowX: "auto ",
    },
    a: {
        position: "sticky",
        top: "0px",
    },
}));

const GestionBonificacionPanel = () => {
    const classes = useStyles();
    const big = useMediaQuery("(min-width:1201px)");
    const store = useSelector((state) => state.gestionbonificacion.filters);
    const dispatch = useDispatch();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue === 2) {
            const link = document.createElement("a");
            link.href = "./formatos/FORMATO-CARGA-BONIFICACIONES.xlsx";
            link.download = "FORMATO-CARGA-BONIFICACIONES.xlsx";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    useEffect(() => {
        dispatch(onUpdateFieldFilter("valor", value))
    }, [dispatch, value]);

    // ilegalidad
    if (value === 0) {
        store.band = true;
    } else {
        store.band = false;
    }

    return (
        <Paper className={classes.paper}>
            <div
                style={{
                    display: "flex",
                    flexDirection: big ? "row" : "column",
                    height: "100%",
                }}
            >
                <Tabs
                    orientation={big ? "vertical" : "horizontal"}
                    variant="fullWidth"
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="icon label tabs example"
                    className={
                        big ? classes.tabsVertical : classes.tabsHorizontal
                    }
                >
                    <Tab icon={<Icon className="fas fa-barcode" />} label="Resumen" style={{ textTransform: "none", backgroundColor: value === 0 ? "#C9C9C9" : "", width: "100%" }}/>
                    <Tab icon={<Icon className="fas fa-project-diagram" />} label="Cargar Bonificaciones" style={{ textTransform: "none", backgroundColor: value === 1 ? "#C9C9C9" : ""}}/>
                    <Tab icon={<Icon className="fas fa-download" />} label="Bajar Formato " style={{ textTransform: "none", backgroundColor: value === 2 ? "#C9C9C9" : "" }} /> 
                    
                </Tabs>

                {value === 0 && <GestionBonificacionList />}
                {value === 1 && <GestionBonificacionImportar />}
                {value === 2 && <GestionBonificacionImportar />}

            </div>
        </Paper>
    );
};

export default GestionBonificacionPanel;
