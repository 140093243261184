import React, { useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_material from "@amcharts/amcharts4/themes/material";

import makeStyles from "@material-ui/core/styles/makeStyles";

am4core.useTheme(am4themes_material);
am4core.useTheme(am4themes_animated);

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
    padding: "1.5rem",
  },
  
}));

const ReporteSeguimientoVendedorLob = () => {
  const classes = useStyles();
  const data = useSelector((state) => state.reporteseguimientovendedor.store.resumen);
  const agente = useSelector(state => state.reporteseguimientovendedor.filters.dDistribuidor);
  const ola = useSelector(state => state.reporteseguimientovendedor.filters.dOla);

  console.log(data)
  var mayor = []
   data.map(f => {
    return(
      mayor.push(f.puntosCartera) 
    )
    })
 
  useLayoutEffect(() => {
    var container = am4core.create("chartdiv", am4core.Container);

    container.width = am4core.percent(100);
    container.height = am4core.percent(100);
    container.layout = "vertical";

    if (data.length > 0) {
      container.exporting.menu = new am4core.ExportMenu();
      container.exporting.menu.align = "left";
      container.exporting.menu.verticalAlign = "top";
      container.exporting.menu.items = [
        {
          label: "...",
          menu: [
            {
              label: "Imagen",
              menu: [
                { type: "png", label: "PNG" },
                { type: "jpg", label: "JPG" },
                { type: "svg", label: "SVG" },
                { type: "pdf", label: "PDF" },
              ],
            },
            {
              label: "Imprimir",
              type: "print",
            },
          ],
        },
      ];
    }

    createChart(container, "RESUMEN", getDataByLob("MCO"));

    return () => {
      container.dispose();
    };
    // eslint-disable-next-line
  }, [data]);

  const createChart = (container, titleText, data) => {
    am4core.useTheme(am4themes_animated);
    // Themes end

    // Create chart instance
    var chart = am4core.create("chartdiv", am4charts.XYChart);
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "zoomXY";
   
    chart.data = getDataByLob();
   

    var titulo = chart.titles.create();
    titulo.text = "Agente: " + agente+' - Ola: '+ola;
    titulo.fontWeight = 600;
    titulo.fontSize = 22;
    titulo.align = "center";
    

    // Create axes
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.calculateTotals = true;
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Toma de Channel map";
    valueAxis.calculateTotals = true;
    //valueAxis.height = 600;
    valueAxis.min = 0;
    valueAxis.max = Math.max(...mayor)+10;
    valueAxis.strictMinMax = true;
    valueAxis.renderer.labels.template.adapter.add("text", function (text) {
      return text; 
    });


    // Create series
    var series = chart.series.push(new am4charts.ColumnSeries3D());
    series.dataFields.valueY = "ejecutado";
    series.dataFields.categoryX = "category";
    series.name = "Ejecutados";
    series.clustered = false;
    series.columns.template.tooltipText = "Ejecutados: [bold]{valueY}[/] Pdv";
    series.columns.template.fillOpacity = 0.9;
    series.fill = am4core.color("#26C6DA");
    series.stacked = true;

    var series2 = chart.series.push(new am4charts.ColumnSeries3D());
    series2.dataFields.valueY = "pendiente";
    series2.dataFields.categoryX = "category";
    series2.name = "Pendientes";
    series2.clustered = false;
    series2.columns.template.tooltipText = "Pendientes: [bold]{valueY}[/] Pdv";
    series2.fill = am4core.color("#5C6BC0");
    series2.stacked = true;


    if (chart.data.length < 12) {
      // Scrollbars
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarY = new am4core.Scrollbar();
    }else{
      // Add scrollbar
      chart.scrollbarX = new am4charts.XYChartScrollbar();
      chart.scrollbarX.series.push(series2);
      chart.scrollbarX.scrollbarChart.series.getIndex(0).xAxis.startLocation = 0.5;
      chart.scrollbarX.scrollbarChart.series.getIndex(0).xAxis.endLocation = 0.5;

      // Pre-zoom the chart
      categoryAxis.start = 0;
      categoryAxis.end = 10 / chart.data.length;
      categoryAxis.keepSelection = true;
    }

    // Add cursor
    chart.cursor = new am4charts.XYCursor();
  };

  const getDataByLob = (lob) => {
    if (data.length === 0) return;
    return _(data)
      .groupBy("vendedor")
      .map((item, key) => ({
        category: key.substring(0, key.split(" ")[0].length+2),
        ejecutado: _.sumBy(item, "conchannel"),
        pendiente: _.sumBy(item, "sinchannel"), 
      }))
      .value();

  };

  return (
    <div className={classes.root}>
     <div style={{ height: 470 }} id="chartdiv" />
    </div>
  );
};

export default ReporteSeguimientoVendedorLob;
