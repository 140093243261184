import React from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import {
    onUpdateFieldEntity,
    onbtnDistribuidorClick,
    removeImage,
    setImage
} from '../../../store/channelmap/asignarmarcadistribuidor/actions';


import Checkbox from '@material-ui/core/Checkbox';
import { ActionField, FieldSet } from '@sigeco/tools';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Link from '@material-ui/core/Link';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import FormControl from "@material-ui/core/FormControl";
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
    content: {
        padding: "0.5rem 0.75rem 0.75rem",
    },
    root: {
        flex: "0 0 auto",
        marginBottom: "0.75rem",
        "@media (min-width: 1401px)": {
          marginBottom: "1rem",
        },
    },
    checkbox: {
        color: "#9c9c9c",
        padding: 0
    },
    details: {
        display: 'flex',
        padding: '0.5rem 0.75rem 0.75rem'
    },
    helperText: {
        fontSize: '0.625rem',
        fontWeight: 300,
        marginBottom: '1rem',
        marginTop: '0.5rem'
    },
    image: {
        backgroundImage: 'url(https://storageterpel.blob.core.windows.net/salesmobility/images/imagen_no_disponible.jpg)',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat', 
        backgroundSize: 'contain',
        border: '3px solid ' + theme.palette.primary.main,
        borderRadius: 3,
        boxShadow: '0 0 13px 0 rgba(0,0,0,.1)',
        color: theme.palette.primary.main,
        fontSize: '6rem',
        height: '6rem',
        textAlign: 'center',
        width: '6rem',
        '@media (min-width: 701px)': {
            fontSize: '7.5rem',
            height: '7.5rem',
            width: '7.5rem'
        }
    },
    imageButton: {
        cursor: 'pointer', 
        display: 'flex', 
        '-webkit-box-align': 'center', 
        alignItems: 'center',
        position: 'absolute', 
        right: -10, 
        top: -10, 
        width: 30, 
        height: 30, 
        borderRadius: '50%', 
        backgroundColor: theme.palette.primary.main, 
        justifyContent: 'center'
    },
    imageClose: {
        cursor: 'pointer', 
        display: 'flex', 
        '-webkit-box-align': 'center', 
        alignItems: 'center',
        position: 'absolute', 
        right: -10, 
        bottom: -10, 
        width: 30, 
        height: 30, 
        borderRadius: '50%', 
        backgroundColor: '#fff', 
        justifyContent: 'center'
    },
    imageIcon: {
        color: theme.palette.primary.contrastText,
        fontSize: '0.9rem'
    },
    imageInput: {
        height: 0, 
        opacity: 0,
        overflow: 'hidden',
        width: 0
    },
    imageWrap: {
        display: 'inline-block',
        position: 'relative'
    },
    label: {
        alignItems: 'center',
        color: 'rgba(0, 0, 0, 0.54)', 
        display: 'flex', 
        fontSize: '0.8125rem', 
        width: 90
    },
    link: {
        cursor: 'pointer', 
        fontSize: '0.75rem'
    },
    paper: {
        marginBottom: '0.75rem',
        '@media (min-width: 1401px)': {
            marginBottom: '1rem'
        }
    }
}));

const AsignarMarcaDistribuidorForm = () => {
    const classes = useStyles();
    const matches = useMediaQuery('(min-width:701px)');
    const entity = useSelector(state => state.asignarmarcadistribuidor.entity);
    const filters = useSelector(state => state.asignarmarcadistribuidor.filters);
    const errors = useSelector(state => state.asignarmarcadistribuidor.errors);
    const form = useSelector(state => state.asignarmarcadistribuidor.form);
    const formState = useSelector(state => state.asignarmarcadistribuidor.formState);
    const dispatch = useDispatch();
    const isView = formState === 'view';

    

    return (
        <div>
            <FieldSet className={classes.paper}  iconCls="fas fa-info-circle" title="Eliminar">
            
            <div className={classes.details}>
            <Grid container spacing={1}>
                <Grid item xs={12} container>
                    <Grid item xs={matches ? 4 : 12}>
                        <ActionField
                            hiddenAction={isView}
                            label="Agente"
                            value={filters.dDistribuidor}
                            variant={matches ? "outlined" : "standard"}
                            />
                    </Grid>
                </Grid>

                <Grid item xs={12} container>
                    <Grid item xs={matches ? 4 : 12}>
                        <ActionField              
                            hiddenAction={isView}
                            label="Marca"
                            value={entity.nombre}
                            variant={matches ? "outlined" : "standard"}
                            />
                    </Grid>
                </Grid>

                <Grid item xs={12} container>
                    <Grid item xs={matches ? 4 : 12}>
                    <img className={classes.image} 
                    alt={entity.imagen} 
                    src={entity.imagen || './images/imagendefecto.png'} />
                    </Grid>
                </Grid>

            </Grid>
                        </div>
                        <br></br>
                        <br></br>

            </FieldSet>
            

        </div>
    );
};

export default AsignarMarcaDistribuidorForm;