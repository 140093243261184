import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { useLocation, useRouteMatch } from "react-router-dom";
import {
    btnDelete,
    btnSave,
    btnSearch,
    onExport,
    restartState,
    setFormState
} from '../store/user/actions';

import { Body, Footer, Page } from '@sigeco/tools';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';

import UserApplication from '../components/user/UserApplication';
import UserDistribuidor from '../components/user/UserDistribuidor';
import UserFilter from '../components/user/UserFilter';
import UserForm from '../components/user/UserForm';
import UserList from '../components/user/UserList';

const User = ({onClose, setTitle}) => {
  const location = useLocation();
  const match = useRouteMatch();
  const formState = useSelector(state => state.user.formState);
  const loading = useSelector(state => state.user.loading);
  const usuarioId = useSelector(state => state.user.entity.usuarioId);
  const dispatch = useDispatch();

  useEffect(() => {
    switch(match.path) {
      case '/usuarios/crear':
        setTitle('Crear Usuario');
        if(formState !== 'new') {
            dispatch(setFormState('new'));
        }
        break;
      case '/usuarios/:id':
        dispatch(setFormState('view', match.params['id']));
        setTitle('Visualizar Usuario');
        break;
      case '/usuarios/:id/editar':
        dispatch(setFormState('edit', match.params['id']));
        setTitle('Editar Usuario');
        break;
      case '/usuarios/:id/resetear':
        dispatch(setFormState('restart', match.params['id']));
        setTitle('Restablecer contraseña');
        break;
      default:
        dispatch(setFormState('search'));
        setTitle('Búsqueda');
        break;
    }
  // eslint-disable-next-line
  }, [match.path]);

  function onBtnClose() {
    dispatch(restartState());
    onClose();
  }

  return (
    <Page>
      <Backdrop style={{ position: 'absolute', zIndex: 1200 }} open={loading} transitionDuration={0}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Body>
        {formState === 'search' && <UserFilter />}
        {formState === 'search' && <UserList />}
        {formState !== 'search' &&  <UserForm />}
        {formState !== 'search' &&  <UserApplication />}
        {formState !== 'search' &&  <UserDistribuidor />}
      </Body>
      <Footer>
        {formState === 'view' && <LinkContainer to={{ pathname: `/usuarios/${usuarioId}/resetear`, search: location.search, hash: location.hash }}>
          <Button 
            color="primary" 
            size="small" 
            variant="outlined"
            startIcon={<Icon className="fas fa-key"/>}
          >
            Recuperar contraseña
          </Button>
        </LinkContainer>}
        {formState === 'search' && <Button
          color="primary" 
          size="small" 
          variant="outlined" 
          onClick={() => dispatch(onExport())}
          startIcon={<Icon className="fas fa-file-excel"/>}
        >
          Exportar
        </Button>}
        {formState === 'search' && <Button
          color="primary" 
          size="small" 
          variant="outlined"
          onClick={() => dispatch(btnSearch())}
          startIcon={<Icon className="fas fa-search"/>}
        >
          Buscar
        </Button>}
        {(formState === 'search' || formState === 'view') && <LinkContainer to={{ pathname: '/usuarios/crear', search: location.search, hash: location.hash }}>
          <Button
            color="primary" 
            size="small" 
            variant="outlined"
            startIcon={<Icon className="fas fa-file"/>}
          >
            Crear
          </Button>
        </LinkContainer>}
        {formState === 'view' && <LinkContainer to={{ pathname: `/usuarios/${usuarioId}/editar`, search: location.search, hash: location.hash }}>
          <Button
            color="primary" 
            size="small" 
            variant="outlined"
            startIcon={<Icon className="fas fa-edit"/>}
          >
            Modificar
          </Button>
        </LinkContainer>}
        {formState === 'view' && <Button 
          color="primary" 
          size="small" 
          variant="outlined" 
          onClick={() => dispatch(btnDelete())}
          startIcon={<Icon className="fas fa-trash"/>}
        >
          Eliminar
        </Button>}
        {formState !== 'search' && formState !== 'view' && <Button
          color="primary" 
          size="small" 
          variant="outlined" 
          onClick={() => dispatch(btnSave())}
          startIcon={<Icon className="fas fa-save"/>}
        >
          Guardar
        </Button>}
        {formState !== 'search' && <LinkContainer to={{ pathname: '/usuarios', search: location.search, hash: location.hash }}>
          <Button
            color="primary" 
            size="small" 
            variant="outlined"
            startIcon={<Icon className="fas fa-times-circle"/>}
          >
            Cancelar
          </Button>
        </LinkContainer>}
        <Button 
          color="primary" 
          size="small" 
          variant="outlined" 
          onClick={() => onBtnClose()} 
          startIcon={<Icon className="fas fa-door-open"/>}
        >
          Salir
        </Button>
      </Footer>
    </Page>
  );
};

export default User;