import React, { useState } from 'react';

import Icon from '@material-ui/core/Icon';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ReporteSeguimientoList from './ReporteSeguimientoList';
import ReporteSeguimientoDash from './ReporteSeguimientoDash';
/*
import ReporteConstruccionLob from './ReporteConstruccionLob';
import ReporteConstruccionPDV  from './ReporteConstruccionPDV';
import ReporteConstruccionTotal from './ReporteConstruccionTotal';
*/
//import ReporteConstruccionChart from './ReporteConstruccionChart';

const useStyles = makeStyles(theme => ({
    paper: {
        flex: 1
    },
    tabsHorizontal: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    tabsVertical: {
        borderRight: `1px solid ${theme.palette.divider}`,
    }
}));

const ReporteSeguiminetoPanel = () => {
    const classes = useStyles();
    const big = useMediaQuery('(min-width:1201px)');
    const [value, setValue] = useState(1);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Paper className={classes.paper}>
            <div style={{ display: 'flex', flexDirection: big ? "row" : "column", height: '100%' }}>
                <Tabs
                    orientation={big ? "vertical" : "horizontal"}
                    variant="fullWidth"
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="icon label tabs example"
                    className={big ? classes.tabsVertical : classes.tabsHorizontal}
                >
                    <Tab icon={<Icon className="fas fa-chart-pie" />} label="Dashboard" />
                    <Tab icon={<Icon className="fas fa-chart-area" />} label="Detalle" />
                    {/*     
                    <Tab icon={<Icon className="fas fa-signal" />} label="Detalle" />
                    <Tab icon={<Icon className="fas fa-chart-line" />} label="Cobertura LOB" />
                    <Tab icon={<Icon className="fas fa-chart-bar" />} label="Cobertura PDV" />
                     */}

                </Tabs>
         
                {value === 0 && ( <ReporteSeguimientoDash />                
                )}
    
                {value === 1 && (<ReporteSeguimientoList />)}
                {/*     
                 
                {value === 2 && (<ReporteConstruccionList />)}
                {value === 3 && ( <ReporteConstruccionLob />)}
                {value === 4 && ( <ReporteConstruccionPDV  />)}
                */}
            </div>
        </Paper>
    );
}

export default ReporteSeguiminetoPanel ;