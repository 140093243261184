export const FILTER_MAP_VENDEDOR = 'movilidad/reportepromociones/filter-map-vendedor';
export const HIDE_POPUP_CLIENT = 'movilidad/reportepromociones/hide-popup-client';
export const RECEIVE_DATA = 'movilidad/reportepromociones/receive_data';
export const RECEIVE_V_LIST = 'movilidad/reportepromociones/receive-v-list';
export const SET_MASK = 'movilidad/reportepromociones/set-mask';
export const SET_ORDER = 'movilidad/reportepromociones/set-order';
export const SET_SELECTED_ROW = 'movilidad/reportepromociones/set-selected-row';
export const SHOW_POPUP_CLIENT = 'movilidad/reportepromociones/show-popup-client';
export const UPDATE_FIELD_FILTER = 'movilidad/reportepromociones/update-field-filter';
export const UPDATE_TABLE_COLUMN = 'movilidad/reportepromociones/update-table-column';
export const RECEIVE_OLAS_LIST = 'movilidad/reportepromociones/receive_olas_list';