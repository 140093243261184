import {
    CLEAN_FORM,
    SET_STATE_FORM,
    UPDATE_FIELD_FILTER,
    SET_MASK,
    RECEIVE_DATA,
    UPDATE_TABLE_COLUMN,
    ERROR_V_LIST,
    RECEIVE_V_LIST,
    REQUEST_V_LIST,
} from './types';

const initialState = {
    formState: 'search',
    loading: false,
    entity: {
        vendedorId: 0,
        nombre: ''
    },
    errors: {
        codigo: '',
        usuario: '',
        documentoIdentidad: ''
    },
    filters: {
        nombre: '',
        distribuidorId: 0,
        dDistribuidor: '',
        codigo: ''
    },
    store: {
        loading: false,
        pageSize: 50,
        start: 0,
        total: 0,
        data: [],
        selected: [],
        //source: null
    },
    table: {
        order: 'asc',
        orderBy: '',
        columns: [
            
            { dataKey: 'Codigo', label: 'Codigo', width: 90 },         
            { dataKey: 'NIT/RUC', label: 'NIT', width: 105 },
            { dataKey: 'NombreComercial', label: 'Nombre Cliente', width: 275 },          
            { dataKey: 'Direccion', label: 'Direccion', width: 200 },
            { dataKey: 'Canal', label: 'Canal', width: 105 },
            { dataKey: 'Segmento', label: 'DistribuidorId', width: 105 },
            //{ type: 'boolean', dataKey: 'activo', label: 'Activo', width: 90 },
            { dataKey: 'Categoria', label: 'Categoria', width: 100 },
            { dataKey: 'Credito', label: 'Credito', width: 440 },
            { dataKey: 'DiasCredito', label: 'DiasCredito', width: 440 },
            // { dataKey: 'Codigosucursal', label: 'Fecha',  width: 185 },
            // { dataKey: 'UnidadMedida', label: 'Unidad de medida', width: 200 },
            // { dataKey: 'Activo', label: 'Activo', type: 'boolean', width: 85 },
            // { dataKey: 'FechaRegistro', label: 'FechaRegistro',  width: 185 }    
        ]
    }
};

export const cargamasivaclientesReducer = (state, action) => {
    state = state || initialState;

    switch(action.type) {
        case CLEAN_FORM:
            return {
                ...state,
                entity: initialState.entity,
                errors: initialState.errors
            };
        case SET_STATE_FORM:
            return {
                ...state,
                formState: action.formState
            };
        case UPDATE_FIELD_FILTER:
            return { 
                ...state,
                filters: {
                    ...state.filters,
                    [action.key]: action.value
                }
            };
            case ERROR_V_LIST:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: false
                }
            };
            case RECEIVE_V_LIST:
                return {
                    ...state,
                    store: {
                        ...state.store,
                        loading: false,
                        total: action.total,
                        data: action.payload,
                        source: null
                    }
                    // ,
                    // table: {
                    //     ...state.table,
                    //     columns: action.columns,
                    // }
                };
            case REQUEST_V_LIST:
                return {
                    ...state,
                    store: {
                        ...state.store,
                        loading: false,
                        source: action.source
                    }
                };
            case RECEIVE_DATA:
                return {
                    ...state,
                    data: action.payload,
                    store: {
                        ...state.store,
                        data: action.storeData
                    }
                };
                case UPDATE_TABLE_COLUMN:
                    return {
                        ...state,
                        table: {
                            ...state.table,
                            columns: action.columns
                        }
                    };
            case SET_MASK:
                return {
                    ...state,
                    loading: action.loading
            };
        default:
            return state;
    }
};