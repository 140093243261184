import React, { useLayoutEffect } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import makeStyles from '@material-ui/core/styles/makeStyles';

am4core.useTheme(am4themes_animated);

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column'
    }, 
    tableWrap: {
        flex: '1 1 auto'
    }
}));

const ReporteVisitasPedidoList = ({ store}) => {
    const classes = useStyles();
  
    useLayoutEffect(() => {
        let chart = am4core.create("chartdiv", am4charts.XYChart);
        chart.hiddenState.properties.opacity = 0;

        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        
        categoryAxis.dataFields.category = "x";
        chart.yAxes.push(new am4charts.ValueAxis());

        var series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.categoryX = "x";
        series.dataFields.valueY = "y";
        series.dataFields.value = "value";
        series.strokeOpacity = 0;
        series.sequencedInterpolation = true;

        var bullet = series.bullets.push(new am4core.Circle());
        bullet.fill = am4core.color("#ff0000");
        bullet.propertyFields.fill = "color";
        bullet.strokeOpacity = 0;
        bullet.radius = 5;
        bullet.strokeWidth = 2;
        bullet.fillOpacity = 0.7;
        bullet.stroke = am4core.color("#ffffff");

        bullet.tooltipText = "[bold]{title}[/]\nPedidos {participacion}";

        var hoverState = bullet.states.create("hover");
        hoverState.properties.fillOpacity = 1;
        hoverState.properties.strokeOpacity = 1;

        series.heatRules.push({ target: bullet, min: 10, max: 25, property: "radius" });

        bullet.adapter.add("tooltipY", function (tooltipY, target) {
            return -target.radius;
        })

        chart.cursor = new am4charts.XYCursor();
        chart.cursor.behavior = "zoomXY";
        let dta = store

    if (dta.length < 12) {
            // Scrollbars
        chart.scrollbarX = new am4core.Scrollbar();
        chart.scrollbarY = new am4core.Scrollbar();
    }else{
        // Add scrollbar
      chart.scrollbarX = new am4charts.XYChartScrollbar();
      chart.scrollbarX.series.push(series);
      chart.scrollbarX.scrollbarChart.series.getIndex(0).xAxis.startLocation = 0.5;
      chart.scrollbarX.scrollbarChart.series.getIndex(0).xAxis.endLocation = 0.5;

      // Pre-zoom the chart
      categoryAxis.start = 0;
      categoryAxis.end = 9/ dta.length;
      categoryAxis.keepSelection = true;
    }
       
        if (  dta &&  dta.length > 0){
             let doubled = dta.map((item, key) => ({
                "title": item.cuentaPedido,
                "id": key,
                "color":   'rgb(' + (Math.floor(Math.random() * 256)) + ',' + (Math.floor(Math.random() * 256)) + ',' + (Math.floor(Math.random() * 256)) + ')',
                "avance": item.avance,
                "x": item.dVendedor.substring(0, item.dVendedor.split(" ")[0].length+2),
                "y": item.cuentaPedido,
                "value": item.cuentaPedido,   
            }));
            chart.data = doubled;
        }

        return () => {
            chart.dispose();
        };
    }, [store]);

    return (
        <div className={classes.root}>
            <div id="chartdiv" className={classes.tableWrap} style={{ height: 400 }}> 
            </div>
        </div>
    );
};

export default ReporteVisitasPedidoList;