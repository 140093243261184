import axios from 'axios';
import { push } from 'connected-react-router';
import { IdentityUrl } from '../../../config';
import format from "date-fns/format";
import { closeAdvancedSearch, closeMessageBox, openAdvancedSearch, openMessageBox } from '../../main/actions';
import {
    CLEAN_FORM,
    ERROR_OLAS_LIST,
    RECEIVE_OLAS_LIST,
    SET_STATE_FORM,
    RESTART_STATE,
    SET_ENTITY,
    SET_ERROR,
    UPDATE_FIELD_ENTITY,
    SET_MASK,
    SET_ORDER,
    SET_SELECTED_ROW,
    UPDATE_FIELD_FILTER,
    RECEIVE_V_LIST,
    ADD_PRESTACION,
    SET_ARREGLO
} from './types';

import { exportToSpreadsheet, cortarArreglo  } from '../../../helpers/commons'

export const cleanForm = () => ({ type: CLEAN_FORM });

export const getUser = (productoId) => async (dispatch, getState) => {
    try {
        dispatch({ type: SET_MASK, loading: true });

        //let url = `${IdentityUrl}/api/v1/productos/${productoId}`;
        let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Tomas/producto/listarid?` 
        + `productoId=${productoId}`
       // + `productoId`
        
        let response = await axios.get(encodeURI(url));
   
        let payload = {
           ...response.data[0]
           // ...response.data    
            /*,
            aplicaciones: response.data.aplicaciones.map(item => { 
                return {
                    aplicacionClienteId: item.aplicacionClienteId, 
                    nombre: item.nombre
                }
            }),
            distribuidores: response.data.distribuidores.map(item => { 
                return {
                    distribuidorId: item.distribuidorId,
                    razonSocial: item.razonSocial
                }
            })
            */
        };
      
        
        dispatch({ type: SET_ENTITY,  payload });
    } catch (error) {
        if(error.response.status === 404) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: 'No se encontro el registro solicitado.', 
                callback: () => { 
                    dispatch(closeMessageBox()); 
                    dispatch(push({
                        pathname: `/precios/productos`,
                        search: getState().router.location.search,
                        hash: getState().router.location.hash
                    }));
                }
            }));
        }
        else {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        }
    } finally {
        dispatch({ type: SET_MASK, loading: false });
    }
};

export const onExport = () => async (dispatch, getState) => {
    try {
    
        if(getState().precios.store.data.length === 0) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'warning', 
                message: 'No hay información para exportar.', 
                callback: () => dispatch(closeMessageBox())
            }));
            return;
        }

        dispatch({ type: SET_MASK, loading: true });
        
        let head = getState().precios.table.columns;

        console.log(head)
        
    //carArray.push(aux);

        let json = getState().precios.store.data;
        console.log(json)       
    // eslint-disable-next-line no-redeclare
    
   

    exportToSpreadsheet(cortarArreglo(head,json), 'Mi Lista Precios') ;
   
    } catch (error) {
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'error', 
            message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
            callback: () => dispatch(closeMessageBox())
        }));
    } finally {
        setTimeout(() => {dispatch({ type: SET_MASK, loading: false })}, 1000);
    }
};

export const btnSearch = () => async (dispatch, getState) => {
    if (getState().precios.store.loading) {
        return;
    }

    dispatch({ type: SET_MASK, loading: true });
    //console.log(getState().tracking);
    //dispatch({ type: RECEIVE_OLAS_LIST, columns: getState().reportepedidos.table.columns, payload: [] });
    //dispatch({ type: REQUEST_OLAS_LIST });
   
    try {

    let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/tomas/registro/listar?` 
        + `fecha=${format(getState().precios.filters.fecha, 'yyyy-MM-dd')}`;

    let response = await axios.get(encodeURI(url));
    let data = response.data;
   
       

        if (data.length === 0) {
            dispatch(openMessageBox({
                button: 'ok',
                icon: 'warning',
                message: "No existe Registro  para esta consulta",
                callback: () => dispatch(closeMessageBox())
            }));
        return;
        }
      
        let columnas = getState().precios.table.columns;

        dispatch({ type: RECEIVE_V_LIST, columns: columnas, payload: data});

    } catch (error) {
        let msgError = '';
        dispatch({ type: SET_MASK, loading: false });
        dispatch({ type: ERROR_OLAS_LIST });

        if (error.response) {
            msgError = (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message;
        }
        else {
            msgError = 'Error al conectar con el servidor';
        }

        dispatch(openMessageBox({
            button: 'ok',
            icon: 'error',
            message: msgError,
            callback: () => dispatch(closeMessageBox())
        }));
    }finally{
        dispatch({ type: SET_MASK, loading: false });
    }
};

export const onUpdateColumn = (column) => (dispatch, getState) => {
    getState().precios.table.columns.map((item , i)=> {     
       if(item.dataKey !== column.dataKey) {
           return item;
       }else{
               item.width =  column.width;
       }
       return {
          // ...item,
         //  width: column.width
       };
      
   });
};

export const onSearchCanalClick = () => async (dispatch, getState) => {
    let fields = [
        { 'dataKey': 'canalId', 'label': 'canalId', 'type': 'string', 'search': true, 'width': 115 },
        { 'dataKey': 'nombre', 'label': 'Canal', 'type': 'string', 'width': 225 }
    ];

    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de Canal',
        getData: async (params) => {
 
            let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Clientes/canales` 
        
            let response = await axios.get(encodeURI(url), { params: params });
    
            const  aux = {
                data: response.data  ,
                pageSize: 50,
                start: 0,
                total: response.data.length
            }
            return aux;  //response.data;
        },
        callback: (btn, result) => {    
            dispatch(closeAdvancedSearch());
       
            if(btn === 'yes') {
                dispatch(onUpdateFieldEntity('canalId', result[0].canalId));
                dispatch(onUpdateFieldEntity('dCanal', `${result[0].nombre}`));
                dispatch(onUpdateFieldEntity('lineaId', 0));
                dispatch(onUpdateFieldEntity('dLinea', ''));
               // dispatch({ type: RECEIVE_OLAS_LIST, columns: getState().reportepedidos.table.columns, payload: [] });
            }
        }
    }));
};

export const btnSearchCanalClick = () => async (dispatch, getState) => {
    let fields = [
        { 'dataKey': 'canalId', 'label': 'canalId', 'type': 'string', 'search': true, 'width': 115 },
        { 'dataKey': 'nombre', 'label': 'Canal', 'type': 'string', 'width': 225 }
    ];

    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de Canal',
        getData: async (params) => {
 
            let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Clientes/canales` 
        
            let response = await axios.get(encodeURI(url), { params: params });
    
            const  aux = {
                data: response.data  ,
                pageSize: 50,
                start: 0,
                total: response.data.length
            }
            return aux;  //response.data;
        },
        callback: (btn, result) => {    
            dispatch(closeAdvancedSearch());
       
            if(btn === 'yes') {
                dispatch(onUpdateFieldFilter('canalId', result[0].canalId));
                dispatch(onUpdateFieldFilter('dCanal', `${result[0].nombre}`));
                dispatch(onUpdateFieldFilter('lineaId', 0));
                dispatch(onUpdateFieldFilter('dLinea', ''));
               // dispatch({ type: RECEIVE_OLAS_LIST, columns: getState().reportepedidos.table.columns, payload: [] });
            }
        }
    }));
};

export const onSearchLineaClick = () => async (dispatch, getState) => {
    let fields = [
        { 'dataKey': 'lineaId', 'label': 'Id', 'type': 'string', 'width': 115 },
        { 'dataKey': 'nombre', 'label': 'Nombre', 'Lob': 'string', 'width': 225 }
    ];


    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de Linea',
        getData: async (params) => {
   
            let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Tomas/producto/linea?` 
                + `canalId=1`
            let response = await axios.get(encodeURI(url), { params: params });
    
            const  aux = {
                data: response.data  ,
                pageSize: 50,
                start: 0,
                total: response.data.length
            }
            return aux;  //response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldEntity('lineaId', result[0].lineaId));
                dispatch(onUpdateFieldEntity('dLinea', result[0].nombre));
                dispatch(onUpdateFieldEntity('marcaId', 0));
                dispatch(onUpdateFieldEntity('dMarca', ''));
                dispatch(onUpdateFieldEntity('dProducto',''))
                dispatch(onUpdateFieldEntity('productoId',0))
                dispatch(onUpdateFieldEntity('usoAceiteId',0))
            }
        }
    }));
};

export const onSearchEstablecimientoClick = () => async (dispatch, getState) => {
    let fields = [
        { 'dataKey': 'tipoEstablecimientoId', 'label': 'Id', 'type': 'string', 'width': 115 },
        { 'dataKey': 'dTipoEstablecimiento', 'label': 'Nombre', 'Lob': 'string', 'width': 225 }
    ];


    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Seleccione un Establecimiento',
        getData: async (params) => {
   
            let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Tomas/tipoestablecimiento?` 
                
            let response = await axios.get(encodeURI(url), { params: params });
    
            const  aux = {
                data: response.data  ,
                pageSize: 50,
                start: 0,
                total: response.data.length
            }
            return aux;  //response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldEntity('dTipoEstablecimiento', result[0].dTipoEstablecimiento));
                dispatch(onUpdateFieldEntity('tipoEstablecimientoId', result[0].tipoEstablecimientoId));
            }
        }
    }));
};

export const onSearchDepartaClick = () => async (dispatch, getState) => {
    let fields = [
      { dataKey: "nombre", label: "Nombre", type: "string", width: 225 },
    ];
  
   
    try {
      dispatch(
        openAdvancedSearch({
          autoLoad: true,
          data: [],
          fields: fields,
          paginated: true,
          remote: true,
          title: "Seleccione un Departamento..",
          getData: async (params) => {
            let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/agentes/departamentos`;
            let response = await axios.get(encodeURI(url), { params: params });
  
            const aux = {
              data: response.data,
              pageSize: 50,
              start: 0,
              total: response.data.length,
            };
            return aux;
          },
          callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if (btn === "yes") {
              dispatch(onUpdateFieldEntity("departamentoId", result[0].codigo));
              dispatch(onUpdateFieldEntity("dDepartamento", `${result[0].nombre}`));
              dispatch(onUpdateFieldEntity("dProvincia", ""));
              dispatch(onUpdateFieldEntity("provinciaId", "00"));
              dispatch(onUpdateFieldEntity("dDistrito", ""));
              dispatch(onUpdateFieldEntity("distritoId", "00"));
            }
          },
        })
      );
    } catch (error) {
      if (error.response) {
        dispatch(
          openMessageBox({
            button: "ok",
            icon: "error",
            message:
              (error.response.data.eventLogId === 0
                ? ""
                : `EventoId: ${error.response.data.eventLogId}. `) +
              error.response.data.message,
            callback: () => dispatch(closeMessageBox()),
          })
        );
      }
    }
  
};
  
export const onSearcProvinciaClick = () => async (dispatch, getState) => {
    let fields = [
      { dataKey: "nombre", label: "Nombre", type: "string", width: 225 },
    ];
  
    dispatch(
      openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: "Seleccióne una Provincia..",
        getData: async (params) => {
          let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/agentes/provincias?` 
                      +`codigodepartamento=${getState().precios.entity.departamentoId}`;
          
          let response = await axios.get(encodeURI(url), { params: params });
  
          const aux = {
            data: response.data,
            pageSize: 50,
            start: 0,
            total: response.data.length,
          };
          return aux;
        },
        callback: (btn, result) => {
          dispatch(closeAdvancedSearch());
          if (btn === "yes") {
            dispatch(onUpdateFieldEntity("provinciaId", result[0].codigo));
            dispatch(onUpdateFieldEntity("dProvincia", `${result[0].nombre}`));
            dispatch(onUpdateFieldEntity("ubigeoId", result[0].ubigeoId));
            dispatch(onUpdateFieldEntity("dDistrito", ""));
            dispatch(onUpdateFieldEntity("distritoId", "00"));
          }
        },
      })
    );
};

export const onSearcDistritoClick = () => async (dispatch, getState) => {
    let fields = [
      { dataKey: "nombre", label: "Nombre", type: "string", width: 225 },
    ];
  
    dispatch(
      openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: "Seleccióne un Distrito.",
        getData: async (params) => {
          let url =`${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/agentes/distritos?`
          + `codigodepartamento=${getState().precios.entity.departamentoId}` +
          `&codigoprovincia=${getState().precios.entity.provinciaId}`;
          let response = await axios.get(encodeURI(url), { params: params });
  
          const aux = {
            data: response.data,
            pageSize: 50,
            start: 0,
            total: response.data.length,
          };
          return aux;
        },
        callback: (btn, result) => {
          dispatch(closeAdvancedSearch());
          if (btn === "yes") {
            dispatch(onUpdateFieldEntity("distritoId", result[0].codigo));
            dispatch(onUpdateFieldEntity("dDistrito", `${result[0].nombre}`));
          }
        },
      })
    );
};

export const onSearchMarcaClick = () => async (dispatch, getState) => {
    let fields = [
        { 'dataKey': 'dMarca', 'label': 'Nombre', 'type': 'string', 'search': true, 'width': 225 },
        { 'dataKey': 'marcaId', 'label': 'Marca Id', 'type': 'string', 'width': 115 }

    ];

    if(getState().precios.entity.lineaId === 0  ) {
        dispatch(openMessageBox({
            button: 'ok',
            icon: 'warning',
            message: "Debe seleccionar un LOB.",
            callback: () => dispatch(closeMessageBox())
        }));
        return;
    }

    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de Marcas',
        getData: async (params) => {
            let input2 = JSON.stringify(params);
            let input = JSON.parse(input2);
            let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Tomas/lineaMarca?lineaId=${getState().precios.entity.lineaId}` 
            let response = await axios.get(encodeURI(url), { params: params });
      
        if (input.dMarca) {
            response.data =  response.data.filter((e) =>                   
              e.dMarca.toLowerCase() === input.dMarca.toLowerCase()
            );
            
           // response.total = final.length;
        }    
           const  aux = {
            data: response.data  ,
            pageSize: 50,
            start: 0,
            total: response.data.length
        }

        return aux;  //response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldEntity('marcaId', result[0].marcaId));
                dispatch(onUpdateFieldEntity('dMarca', `${result[0].dMarca}`));
                dispatch(onUpdateFieldEntity('dProducto',''))
                dispatch(onUpdateFieldEntity('productoId',0))
                dispatch(onUpdateFieldEntity('usoAceiteId',0))
            }
        }
    }));
};

export const btnSearchMarcaClick = () => async (dispatch, getState) => {
    let fields = [
        { 'dataKey': 'nombre', 'label': 'Nombre', 'type': 'string', 'search': true, 'width': 225 },
        { 'dataKey': 'marcaId', 'label': 'Marca Id', 'type': 'string', 'width': 115 }
    ];

    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de Marcas',
        getData: async (params) => {
            let input2 = JSON.stringify(params);
            let input = JSON.parse(input2);
            let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/marcas?` 
            let response = await axios.get(encodeURI(url), { params: params });
            console.log(response);
         const  aux = {
            data: response.data  ,
            pageSize: 50,
            start: 0,
            total: response.data.length
        }

        if (input.nombre) {

            let final = response.data.filter((e) =>
              e.nombre.toLowerCase().includes(input.nombre.toLowerCase())
            );
            response.data.data = final;
            response.data.total = final.length;
          }
        return aux;  //response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldFilter('marcaId', result[0].marcaId));
                dispatch(onUpdateFieldFilter('dMarca', `${result[0].nombre}`));
            }
        }
    }));
};

export const onSearchCategoriaClick = () => async (dispatch, getState) => {
    let fields = [
        { 'dataKey': 'Nombre', 'label': 'Nombre', 'type': 'string', 'search': true, 'width': 225 },
        { 'dataKey': 'TipoAceiteId', 'label': ' Id', 'type': 'string', 'width': 115 }
    ];

    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de Categorias',
        getData: async (params) => {
            let input2 = JSON.stringify(params);
            let input = JSON.parse(input2);                                                     
            let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Tomas/producto/categorias?` 
            let response1 = await axios.get(encodeURI(url), { params: params });
        let response = {
          data: {
            start: 0,
            pageSize: 50,
            total: response1.data.length,
            data: response1.data,
          },
        };
    
        return response.data;  //response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldEntity('tipoAceiteId', result[0].TipoAceiteId));
                dispatch(onUpdateFieldEntity('dTipoAceite', `${result[0].Nombre}`));
            }
        }
    }));
};

export const onSearchProductoClick = () => async (dispatch, getState) => {
    let fields = [ {'dataKey': "nombre",'label': "Nombre",'type': "string",'search': true,'width': 225},
    { 'dataKey': 'viscosidad', 'label': 'Viscosidad', 'type': 'string', 'width': 65 },
    { 'dataKey': 'dUsoAceite', 'label': 'Uso Aceite', 'type': 'string', 'width': 115 },
    { 'dataKey': 'dTipoAceite', 'label': 'Tipo Aceite', 'type': 'string', 'width': 135 }
    //let data = aProducto;
    ];
  
    if (getState().precios.entity.lineaId === 0) {
        dispatch(
          openMessageBox({
            button: "ok",
            icon: "warning",
            message: "Debe seleccionar un PRODUCTO.",
            callback: () => dispatch(closeMessageBox()),
          })
        );
        return;
    }

    if (getState().precios.entity.marcaId === 0) {
        dispatch(
          openMessageBox({
            button: "ok",
            icon: "warning",
            message: "Debe seleccionar una Marca.",
            callback: () => dispatch(closeMessageBox()),
          })
        );
        return;
    }


    dispatch(
      openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: "Selección de Productos",
        getData: async (params) => {
          let input2 = JSON.stringify(params);
          let input = JSON.parse(input2);
          let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Tomas/producto/listar?`
                    + `nombre=`+`&Linea_Id=${getState().precios.entity.lineaId}`
                    +`&Marca_Id=${getState().precios.entity.marcaId}`
         
          let response1 = await axios.get(encodeURI(url), { params: params });
          //return 0
          if (input.nombre) {
            response1.data =  response1.data.filter((e) =>                   
              e.nombre.toLowerCase() === input.nombre.toLowerCase()
            );
            
           // response.total = final.length;
        }    
           const  aux = {
            data: response1.data  ,
            pageSize: 50,
            start: 0,
            total: response1.data.length
        }

        return aux;  //response.data;
       
        },
        
        callback: (btn, result) => {
          //console.log(result);
          dispatch(closeAdvancedSearch());
          if (btn === "yes") {
            // dispatch({
            //   type: ADD_PRODUCTO,
            //    payload: result.map((item) => {
            //      return { 
            //       dProducto: item.nombre, 
            //     }; 
            //   }),
            // });
           // dispatch(onUpdateFieldFilter('establecimiento', ''));
            //dispatch(onUpdateFieldFilter('lobId', ''));
            //dispatch(onUpdateFieldFilter('dLob', ''));
            //dispatch(onUpdateFieldFilter('dMarca', ''));
            console.log('entro222222')
            dispatch(onUpdateFieldEntity('dProducto',`${result[0].nombre}`))
            dispatch(onUpdateFieldEntity('productoId',result[0].productoId))
            dispatch(onUpdateFieldEntity('usoAceiteId',result[0].usoAceiteId))


          }
        },
      })
    );
};

export const restartState = () => ({ type: RESTART_STATE });

export const setFormState = (formState, id) => (dispatch, getState) => {
    switch (formState) {
        case 'edit':
            if(id && id !== getState().productos.entity.productoId) {
                dispatch(getUser(id));
            }
            break;
        case 'new':
            dispatch(cleanForm());
           // dispatch(setPassword(generateRandomCode(8)));
            break;
        case 'restart':
            if(id && id !== getState().productos.entity.productoId) {
                dispatch(getUser(id));
            }
          //  dispatch(setPassword(generateRandomCode(8)));productoId
            break;
        case 'search':
            dispatch(cleanForm());
            break;
        case 'view':
            if(id && id !== getState().productos.entity.productoId) {
               dispatch(getUser(id));
            }
            break;
        default:
            break;
    }
    dispatch({ type: SET_STATE_FORM, formState });
}

export const btnDelete = () => (dispatch) => {
    dispatch(openMessageBox({
        button: 'yesno',
        icon: 'question',
        message: 'Se elimanara el registro permanentemente. ¿Desea continuar?',
        callback: btn => dispatch(onDelete(btn))
    }));
};

export const btnSave = () => (dispatch, getState) => {
    

    for (let i = 0; i < getState().precios.arreglo.dataPresentacion.length; i++) {
        const item = getState().precios.arreglo.dataPresentacion[i];
        //console.log(item)
        if (

            
            item.precioCompra === '',
            item.precioVenta === ''
            

        ) {
          dispatch(
            openMessageBox({
              button: "ok",
              icon: "error",
              message:
                "Debe completar todo los campos antes de enviar ",
              callback: () => dispatch(closeMessageBox())
            })
          );
          return; // Retorna aquí para detener la ejecución de la función
        }

    }

    //return 0 
    dispatch(openMessageBox({
        button: 'yesno',
        icon: 'question',
        message: '¿Desea guardar la información?',
        callback: btn => {
            if(getState().precios.entity.productoId != 0) {
               dispatch(onSave(btn));
            }
            
        }
    }));
    
};

export const onDelete = (btn) => async (dispatch, getState) => {
    dispatch(closeMessageBox());

    if(btn === 'yes') {
        try {
            dispatch({ type: SET_MASK, loading: true });

            let usuarioId = getState().productos.entity.productoId;
            let url = `${IdentityUrl}/api/v1/usuarios/${usuarioId}`;
            await axios.delete(encodeURI(url));

            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'info', 
                message: 'El registro se elimino correctamente.', 
                callback: () => { 
                    dispatch(closeMessageBox());
                    dispatch(push({
                        pathname: '/usuarios',
                        search: getState().router.location.search,
                        hash: getState().router.location.hash
                    }));
                    dispatch(btnSearch())
                }
            }));
        } catch (error) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        } finally {
            dispatch({ type: SET_MASK, loading: false });
        }
    }
};

export const onSave = (btn) => async (dispatch, getState) => {

    dispatch(closeMessageBox());

    if(btn === 'yes') {
        try {
            dispatch({ type: SET_MASK, loading: true });

            let presentacionesX = getState().precios.arreglo.dataPresentacion;

            presentacionesX.forEach(function(presentacioneX) {
                presentacioneX.precioCompra = parseInt(presentacioneX.precioCompra);
                presentacioneX.precioVenta = parseInt(presentacioneX.precioVenta);
                presentacioneX.productoId = getState().precios.entity.productoId
            });
            
            //console.log("DATAA1:", presentacionesX)


            let registro = {
                tipoEstablecimientoId: getState().precios.entity.tipoEstablecimientoId,
                ubigeoId: getState().precios.entity.ubigeoId,
                clienteId: 0,
                tipoPrecio: 0,
                detalles:presentacionesX,
                

            };
           // console.log(registro)

           // return 0;

            let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Tomas/precios`;
            
            let response = await axios.post(url, registro, {headers: {'Content-Type': 'application/json'}});
            //let data = response.data;

            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'info', 
                message: 'Registro realizado con exito.', 
                callback: () => { 
                    dispatch(closeMessageBox()); 
                    dispatch({type: SET_ARREGLO, clearArreglo: true});
                    //dispatch({ type: SET_MASK, loading: true });
                    //getState().precios.clearArreglo = true;
                    // dispatch(push({
                    //     pathname: `/precios/precios`,
                    //     search: getState().router.location.search,
                    //     hash: getState().router.location.hash
                    // }));
                }
            }));
        } catch (error) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        } finally {
            dispatch({ type: SET_MASK, loading: false });
        }
    }
};

// export const onUpdate = (btn) => async (dispatch, getState) => {  
//     dispatch(closeMessageBox());

//     if(btn === 'yes') {
//         try {
//             dispatch({ type: SET_MASK, loading: true });

//             let registro = {
//                 ...getState().productos.entity,
//             };
//             let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Tomas/actualizarproducto` 
//             let response = await axios.put(url, registro, {headers: {'Content-Type': 'application/json'}});
//             let data = response.data;

//             dispatch(openMessageBox({ 
//                 button: 'ok', 
//                 icon: 'info', 
//                 message: 'Modificación realizado con exito.', 
//                 callback: () => { 
//                     dispatch(closeMessageBox());
//                     dispatch(push({
//                         pathname: `/precios/productos/${getState().productos.entity.productoId}`,
//                         //precios/productos/:id
//                         search: getState().router.location.search,
//                         hash: getState().router.location.hash
//                     }));
//                     dispatch(getUser(data));
//                 }
//             }));
//         } catch (error) {
//             if(error.response.status === 404) {
//                 dispatch(openMessageBox({ 
//                     button: 'ok', 
//                     icon: 'error', 
//                     message: 'No se encontro el registro solicitado.', 
//                     callback: () => { 
//                         dispatch(closeMessageBox()); 
//                         dispatch(push({
//                             pathname: `/usuarios`,
//                             search: getState().router.location.search,
//                             hash: getState().router.location.hash
//                         }));
//                     }
//                 }));
//             }
//             else {
//                 dispatch(openMessageBox({ 
//                     button: 'ok', 
//                     icon: 'error', 
//                     message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
//                     callback: () => dispatch(closeMessageBox())
//                 }));
//             }
//         } finally {
//             dispatch({ type: SET_MASK, loading: false });
//         }
//     }
// };

export const onUpdateFieldFilter = (key, value) => (dispatch, getState) => {
    dispatch({ type: UPDATE_FIELD_FILTER, key, value })
    //dispatch({ type: RECEIVE_OLAS_LIST, columns:[], payload: [] })
};

export const onUpdateFieldEntity = (key, value) => (dispatch) => {
        dispatch({ type: UPDATE_FIELD_ENTITY, key, value });
    
        if(key === 'tipoEstablecimientoId' || key === 'departamentoId' || key === 'provinciaId' || key === 'lineaId' 
           || key === 'productoId' || key === 'marcaId') {
            dispatch({ type: SET_ERROR, key, error: '' });
        }
};

export const setOrder = (order, orderBy) => ({ type: SET_ORDER, order, orderBy });

export const setSelectedRow = (selected) => ({ type: SET_SELECTED_ROW, selected});


export const btnListaPresentaciones = () => async (dispatch,getState) => {

    if(getState().precios.entity.lineaId === 0) {
        dispatch({ type: SET_ERROR, key: 'lineaId', error: 'Este campo es requerido' });
    }

    if(getState().precios.entity.tipoEstablecimientoId === 0) {
        dispatch({ type: SET_ERROR, key: 'tipoEstablecimientoId', error: 'Este campo es requerido' });
    }

    if(getState().precios.entity.departamentoId === '00') {
        dispatch({ type: SET_ERROR, key: 'departamentoId', error: 'Este campo es requerido' });
    }

    if(getState().precios.entity.provinciaId === 0) {
        dispatch({ type: SET_ERROR, key: 'provinciaId', error: 'Este campo es requerido' });
    }

    if(getState().precios.entity.marcaId === 0) {
        dispatch({ type: SET_ERROR, key: 'marcaId', error: 'Este campo es requerido' });
    }

    if(getState().precios.entity.productoId === 0) {
        dispatch({ type: SET_ERROR, key: 'productoId', error: 'Este campo es requerido' });
    }

    // if(getState().precios.entity.provinciaId === '') {
    //     dispatch({ type: SET_ERROR, key: 'provinciaId', error: 'Este campo es requerido' });
    // }
    console.log(getState().precios.errors)

    if(
        getState().precios.errors.lineaId != 'Este campo es requerido' &&
        getState().precios.errors.tipoEstablecimientoId != 'Este campo es requerido' &&
        getState().precios.errors.departamentoId != 'Este campo es requerido' &&
        getState().precios.errors.provinciaId != 'Este campo es requerido' &&
        getState().precios.errors.marcaId != 'Este campo es requerido' &&
        getState().precios.errors.productoId != 'Este campo es requerido'
        
        )
    {
      
     getState().precios.store.pantalla = true;   
        
    let fields = [
        { 'dataKey': 'dPresentacion', 'label': 'Presentacion', 'type': 'string', search: true, 'width': 300 }
    ];

    try {
    
        dispatch(
            openAdvancedSearch({
              autoLoad: true,
              data: [],
              fields: fields,
              multiSelect: true,
              paginated: true,
              remote: true,
              title: "Selección de Prestacion(es).",
              getData: async (params) => {
                let input2 = JSON.stringify(params);
                let input = JSON.parse(input2);
                let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Tomas/producto?`
                + `lineaId=${getState().precios.entity.lineaId}` + `&usoAceiteId=0`+`&tipoAceiteId=0`
                + `&marcaId=${getState().precios.entity.marcaId}` + `&productoid=${getState().precios.entity.productoId}` ;
                let response1 = await axios.get(encodeURI(url), { params: params });

                let response = {
                  data: {
                    start: 0,
                    pageSize: 50,
                    total: response1.data[0].presentaciones.length,
                    data: response1.data[0].presentaciones,
                  },
                };
                        
                if (input.dPresentacion) {
                  let final = response.data.data.filter((e) =>
                    e.dPresentacion.toLowerCase().includes(input.dPresentacion.toLowerCase())
                  );
                  response.data.data = final;
                  response.data.total = final.length;
                }
                return response.data;
                
              },
              callback: (btn, result) => {
                console.log(result);
                dispatch(closeAdvancedSearch());
                if (btn === "yes") {
                  dispatch({
                    type: ADD_PRESTACION,
                     payload: result.map((item) => {
                       return { 
                        presentacionId: item.presentacionId, 
                        dPresentacion: item.dPresentacion,
                        dPresentacion: item.dPresentacion,
                        precioCompra: item.precioCompra,
                        detalle: item.detalle
                      }; 
                    }),
                  });
                }
              },
            })
          );
        }
    finally {
        dispatch({ type: SET_MASK, loading: false });
      }

    }else {

        getState().precios.store.pantalla  = false; 

        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'warning', 
            message: 'Debes selecionar completar todos los campos.', 
            callback: () => dispatch(closeMessageBox())
        }));

    }



}