import { createTheme } from '@material-ui/core/styles';

const themes = {
    default: createTheme({
        palette: {
            background: {
                default: '#EAEAEA'
            },
            primary: {
                main: '#FF1E0A',
                contrastText: '#fff',
                light: '#e3f2fd'
            },
            secondary: {
                main: '#ef482e'
            }
        },
        overrides: {
            MuiAccordionDetails: {
                root: {
                    padding: 0
                }
            },
            MuiAccordionSummary: {
                content: {
                    margin: 0,
                    "&.Mui-expanded": {
                        margin: 0
                    }
                },
                root: {
                    minHeight: '1.75rem',
                    padding: '0 0.75rem 0 0.75rem',
                    "&.Mui-expanded": {
                        minHeight: '1.75rem'
                    }
                }
            },
            MuiButton: {
                outlinedSizeSmall: {
                    fontSize: 0,
                    padding: '.125rem .5rem .0625rem',
                    '@media (min-width: 701px)': {
                        fontSize: '.8125rem'
                    }
                },
                iconSizeSmall: {
                    '& > *:first-child': {
                        fontSize: '.875rem',
                        width: 'auto'
                    }
                },
                root: {
                    minHeight: '1.5625rem',
                    minWidth: '3rem',
                    textTransform: 'none',
                    '@media (min-width: 701px)': {
                        minWidth: '4rem'
                    }
                },
                startIcon: {
                    marginRight: 0,
                    '&.MuiButton-iconSizeSmall': {
                        marginLeft: 0
                    },
                    '@media (min-width: 701px)': {
                        marginRight: '.5rem',
                        '&.MuiButton-iconSizeSmall': {
                            marginLeft: '-.125rem'
                        }
                    }
                }
            },
            MuiChip: {
                deleteIcon: {
                    height: '1rem',
                    width: '1rem'
                },
                root: {
                    fontSize: '.75rem',
                    height: '1.75rem',
                    '& .MuiChip-avatar': {
                        height: '1.375rem',
                        width: '1.375rem'
                    }
                }
            },
            MuiFab: {
                sizeSmall: {
                    height: '2.25rem',
                    width: '2.25rem'
                }
            },
            MuiFormHelperText: {
                contained: {
                    marginLeft: '0.5rem',
                    marginRight: '0.5rem',
                    marginTop: 0
                },
                root: {
                    fontSize: '0.6875rem'
                }
            },
            MuiFormLabel: {
                root: {
                    fontSize: '0.8125rem'
                }
            },
            MuiGrid: {
                'spacing-xs-1': {
                    '& > .MuiGrid-item': {
                        padding: 5
                    }
                }
            },
            MuiIconButton: {
                root: {
                    padding: 4
                }
            },
            MuiInput: {
                formControl: {
                    "label + &": {
                        marginTop: "12px"
                    }
                }
            },
            MuiInputBase:{
                input: {
                    height: 'auto',
                    padding: '0.3125rem 0 0.3125rem'
                },
                root: {
                    fontSize: '0.75rem'
                }
            },
            MuiInputLabel: {
                outlined: {
                    transform: 'translate(0.375rem, 0.3125rem) scale(1)',
                    '&.MuiInputLabel-shrink': {
                        transform: 'translate(0.875rem, -0.375rem) scale(0.7)'
                    }
                },
                formControl: {
                    transform: 'translate(0, 1.25rem) scale(1)'
                }
            },
            MuiMenuItem: {
                root: {
                    fontSize: '0.875rem'
                }
            },
            MuiOutlinedInput: {
                adornedEnd: {
                    paddingRight: 4
                },
                input: {
                    padding: '0.3125rem 0.375rem'
                },
                multiline: {
                    padding: '0.3125rem 0.375rem'
                },
                root: {
                    backgroundColor: '#fff'
                }
            },
            MuiSvgIcon: {
                root: {
                    fontSize: '1.125rem'
                }
            },
            MuiTab: {
                labelIcon: {
                    minHeight: '4rem',
                    '& .MuiTab-wrapper > *:first-child': {
                        fontSize: '1.125rem',
                        marginBottom: '0.25rem',
                        width: 'auto',
                        '@media (min-width: 701px)': {
                            fontSize: '1.25rem'
                        }
                    }
                },
                root: {
                    fontSize: '0.75rem',
                    textTransform: 'initial'
                }
            },
            MuiTableCell: {
                head: {
                    fontSize: '0.75rem',
                    fontWeight: 400
                },
                root: {
                    fontSize: '0.6875rem',
                    "& < .Mui-selected": {
                        color: '#fff'
                    }
                },
                sizeSmall: {
                    "&.MuiTableCell-paddingCheckbox": {
                        padding: '0px 12px 0 12px'
                    }
                },
                stickyHeader: {
                    backgroundColor: '#fff'
                }
            },
            MuiTablePagination: {
                toolbar: {
                    fontSize: '0.75rem',
                    minHeight: '2.25rem',
                    paddingLeft: 0
                }
            },
            MuiTableRow: {
                root: {
                    "&.Mui-selected": {
                        backgroundColor: '#006bb6'
                    },
                    "&.MuiTableRow-hover:hover": {
                        backgroundColor: '#f00'
                    },
                    "&.Mui-selected > td.MuiTableCell-root": {
                        color: 'inherit'
                    }
                }
            },
            MuiTypography: {
                body1: {
                    fontSize: '0.75rem'
                }
            }
        }
    }),
    mobil: createTheme({
        palette: {
            background: {
                default: '#0C479D'
            },
            primary: {
                main: '#0C479D',
                contrastText: '#fff',
                light: '#e3f2fd'
            },
            secondary: {
                main: '#ef482e'
            }
        },
        overrides: {
            MuiAccordionDetails: {
                root: {
                    padding: 0
                }
            },
            MuiAccordionSummary: {
                content: {
                    margin: 0,
                    "&.Mui-expanded": {
                        margin: 0
                    }
                },
                root: {
                    minHeight: '1.75rem',
                    padding: '0 0.75rem 0 0.75rem',
                    "&.Mui-expanded": {
                        minHeight: '1.75rem'
                    }
                }
            },
            MuiButton: {
                outlinedSizeSmall: {
                    fontSize: 0,
                    padding: '.125rem .5rem .0625rem',
                    '@media (min-width: 701px)': {
                        fontSize: '.8125rem'
                    }
                },
                iconSizeSmall: {
                    '& > *:first-child': {
                        fontSize: '.875rem',
                        width: 'auto'
                    }
                },
                root: {
                    minHeight: '1.5625rem',
                    minWidth: '3rem',
                    textTransform: 'none',
                    '@media (min-width: 701px)': {
                        minWidth: '4rem'
                    }
                },
                startIcon: {
                    marginRight: 0,
                    '&.MuiButton-iconSizeSmall': {
                        marginLeft: 0
                    },
                    '@media (min-width: 701px)': {
                        marginRight: '.5rem',
                        '&.MuiButton-iconSizeSmall': {
                            marginLeft: '-.125rem'
                        }
                    }
                }
            },
            MuiChip: {
                deleteIcon: {
                    height: '1rem',
                    width: '1rem'
                },
                root: {
                    fontSize: '.75rem',
                    height: '1.75rem',
                    '& .MuiChip-avatar': {
                        height: '1.375rem',
                        width: '1.375rem'
                    }
                }
            },
            MuiFab: {
                sizeSmall: {
                    height: '2.25rem',
                    width: '2.25rem'
                }
            },
            MuiFormHelperText: {
                contained: {
                    marginLeft: '0.5rem',
                    marginRight: '0.5rem',
                    marginTop: 0
                },
                root: {
                    fontSize: '0.6875rem'
                }
            },
            MuiFormLabel: {
                root: {
                    fontSize: '0.8125rem'
                }
            },
            MuiGrid: {
                'spacing-xs-1': {
                    '& > .MuiGrid-item': {
                        padding: 5
                    }
                }
            },
            MuiIconButton: {
                root: {
                    padding: 4
                }
            },
            MuiInput: {
                formControl: {
                    "label + &": {
                        marginTop: "12px"
                    }
                }
            },
            MuiInputBase:{
                input: {
                    height: 'auto',
                    padding: '0.3125rem 0 0.3125rem'
                },
                root: {
                    fontSize: '0.75rem'
                }
            },
            MuiInputLabel: {
                outlined: {
                    transform: 'translate(0.375rem, 0.3125rem) scale(1)',
                    '&.MuiInputLabel-shrink': {
                        transform: 'translate(0.875rem, -0.375rem) scale(0.7)'
                    }
                },
                formControl: {
                    transform: 'translate(0, 1.25rem) scale(1)'
                }
            },
            MuiMenuItem: {
                root: {
                    fontSize: '0.875rem'
                }
            },
            MuiOutlinedInput: {
                adornedEnd: {
                    paddingRight: 4
                },
                input: {
                    padding: '0.3125rem 0.375rem'
                },
                multiline: {
                    padding: '0.3125rem 0.375rem'
                },
                root: {
                    backgroundColor: '#fff'
                }
            },
            MuiSvgIcon: {
                root: {
                    fontSize: '1.125rem'
                }
            },
            MuiTab: {
                labelIcon: {
                    minHeight: '4rem',
                    '& .MuiTab-wrapper > *:first-child': {
                        fontSize: '1.125rem',
                        marginBottom: '0.25rem',
                        width: 'auto',
                        '@media (min-width: 701px)': {
                            fontSize: '1.25rem'
                        }
                    }
                },
                root: {
                    fontSize: '0.75rem',
                    textTransform: 'initial'
                }
            },
            MuiTableCell: {
                head: {
                    fontSize: '0.75rem',
                    fontWeight: 400
                },
                root: {
                    fontSize: '0.6875rem',
                    "& < .Mui-selected": {
                        color: '#fff'
                    }
                },
                sizeSmall: {
                    "&.MuiTableCell-paddingCheckbox": {
                        padding: '0px 12px 0 12px'
                    }
                },
                stickyHeader: {
                    backgroundColor: '#fff'
                }
            },
            MuiTablePagination: {
                toolbar: {
                    fontSize: '0.75rem',
                    minHeight: '2.25rem',
                    paddingLeft: 0
                }
            },
            MuiTableRow: {
                root: {
                    "&.Mui-selected": {
                        backgroundColor: '#006bb6'
                    },
                    "&.MuiTableRow-hover:hover": {
                        backgroundColor: '#f00'
                    },
                    "&.Mui-selected > td.MuiTableCell-root": {
                        color: 'inherit'
                    }
                }
            },
            MuiTypography: {
                body1: {
                    fontSize: '0.75rem'
                }
            }
        }
    })
};

export const createMyTheme = (color) => {
    switch (color) {
        case 'mobil':
            return themes.mobil;
        default:
            return themes.default;
    }
}