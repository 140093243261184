import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import useMediaQuery from '@material-ui/core/useMediaQuery';


const useStyles = makeStyles(theme => ({
    paper: {
        flex: 1
    },
    tabsHorizontal: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    tabsVertical: {
        borderRight: `1px solid ${theme.palette.divider}`,
    }
}));

const ReporteCoberturaPanel = () => {
    const classes = useStyles();
    const big = useMediaQuery('(min-width:1201px)');
    

    return (
        <Paper className={classes.paper}>
            <div style={{ display: 'flex', flexDirection: big ? "row" : "column", height: '100%' }}>
                 {/* 
                <Tabs
                    orientation={big ? "vertical" : "horizontal"}
                    variant="fullWidth"
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="icon label tabs example"
                    className={big ? classes.tabsVertical : classes.tabsHorizontal}
                >
                    <Tab icon={<Icon className="fas fa-chart-pie" />} label="Tamaño Mercado" />
                    <Tab icon={<Icon className="fas fa-chart-pie" />} label="Participación LOB" />
                    <Tab icon={<Icon className="fas fa-chart-line" />} label="Participación PDV" />
                </Tabs>
                 
                {value === 0 && (<ReporteCoberturaTotal />)}
                {value === 1 && (<ReporteCoberturaLob />)}
                {value === 2 && (<ReporteCoberturaPDV />)}
                {value === 2 && (<ReporteCoberturaChart />)}

                */ }
               
            </div>
        </Paper>
    );
}

export default ReporteCoberturaPanel;