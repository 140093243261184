import axios from "axios"; 
import { IdentityUrl } from "../../../config";
import { push } from 'connected-react-router';
import { closeMessageBox, openMessageBox } from "../../main/actions";

import {
  CLEAN_FORM, 
  UPDATE_FIELD_FILTER,
  ERROR_V_LIST,
  RECEIVE_V_LIST,
  REQUEST_V_LIST,
  SET_PAGE,
  SET_SELECTED_ROW,
  SET_MASK,
  SET_ORDER,
  ADVANCED_SEARCH_CLOSE,
  ADVANCED_SEARCH_OPEN,
  RECEIVE_V_LIST2
} from "./types";

import { exportToSpreadsheet, cortarArreglo } from "../../../helpers/commons";

export const setPage = (page) => ({ type: SET_PAGE, page });

export const cleanForm = () => ({ type: CLEAN_FORM });

export const setSelectedRow = (selected) => ({
  type: SET_SELECTED_ROW,
  selected,
});


//======================
export const onExport = () => async (dispatch, getState) => {
  // no se usa otra forma de exportar

  if (getState().gestionpreciosespeciales.filters.distribuidorId   === 0) {
    dispatch(openMessageBox({
        button: 'ok',
        icon: 'warning',
        message: "Debe seleccionar un Agente.",
        callback: () => dispatch(closeMessageBox())
    }));
    return;
  }


  if (getState().gestionpreciosespeciales.store.data.length === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "No hay información para exportar.",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }


  try {
    dispatch({ type: SET_MASK, loading: true });
    let head = getState().gestionpreciosespeciales.tablexport.columns;
    let json = getState().gestionpreciosespeciales.store.data;


    exportToSpreadsheet(cortarArreglo(head,json), "Gestion Precios Especiales");
  } catch (error) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message:
          (error.response.data.eventLogId === 0
            ? ""
            : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    setTimeout(()=>{dispatch({ type: SET_MASK, loading: false })}, 1000)
  }
};
//=====================



export const onSearchDistribuidorClick = () => async (dispatch) => {
  let fields = [{ dataKey: "razonSocial", label: "Agente", type: "string", width: 225 }];

  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de agente",
      getData: async (params) => {
        let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`;
        let response = await axios.get(encodeURI(url), { params: params });

        return response.data;
      },
      callback: (btn, result) => {
        
        dispatch(closeAdvancedSearch());
        
        if (btn === "yes") {

          dispatch(onUpdateFieldFilter("distribuidorId", result[0].distribuidorId));
          dispatch(onUpdateFieldFilter("dDistribuidor", `${result[0].razonSocial}`));
          dispatch(onUpdateFieldFilter("nombre", ""));

        }
      },
    })
  );
};

export const btnSearch = () => async (dispatch, getState) => {
  if (getState().gestionpreciosespeciales.store.loading) {
    return;
  }

  if (getState().gestionpreciosespeciales.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }


  dispatch({ type: SET_MASK, loading: true });
  
  let source = axios.CancelToken.source();
  dispatch({ type: REQUEST_V_LIST, source });

  try {
    let url =  `${getState().main.resources.uriSvcSalesMobilityAPI}/api/v1/Reportes/reporteprecioscliente?` +
      `distribuidorId=${getState().gestionpreciosespeciales.filters.distribuidorId}` +
      `&nombre=${getState().gestionpreciosespeciales.filters.nombre}`;


    let response = await axios.get(encodeURI(url));
    let data = response.data;
    
    if (data.length === 0) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "No existe Registro  para esta consulta",
          callback: () => dispatch(closeMessageBox()),
        })
      );
      return;
    }

    let columnas = getState().gestionpreciosespeciales.table.columns;
    
    dispatch({ type: RECEIVE_V_LIST, columns: columnas, payload: data });
  } catch (error) {
    let msgError = "";
    dispatch({ type: ERROR_V_LIST });
    dispatch({ type: SET_MASK, loading: false });

    if (error.response) {
      msgError =
        error.response.data.eventLogId === 0
          ? "No existe Registro  para esta consulta "
          : `EventoId: ${error.response.data.eventLogId}. ` +
            error.response.data.message;
    } else {
      msgError = "Error al conectar con el servidor";
    }

    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message: msgError,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    dispatch({ type: SET_MASK, loading: false });
  }
};

export const onImport = () => async (dispatch, getState) => {

  dispatch({ type: SET_MASK, loading: true });
  
  if (getState().gestionpreciosespeciales.filters.distribuidorId === 0) {
    dispatch(openMessageBox({
      button: 'ok',
      icon: 'warning',
      message: "Debe seleccionar un Distribuidor.",
      callback: () => dispatch(closeMessageBox())
    }));
    dispatch({ type: SET_MASK, loading: false });
    return;
  }



  if (getState().gestionpreciosespeciales.stored.data.length === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "No hay información para importar.",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    dispatch({ type: SET_MASK, loading: false });
    return;
  }

      let aux = getState().gestionpreciosespeciales.stored.data;
   
      var recordset = {
        'distribuidorId': getState().gestionpreciosespeciales.filters.distribuidorId,
        'data': []
      };

      for (var c in aux) {
        let json = {

          "nit": aux[c].Nit_Ruc.toString(),
          "codigoCliente": aux[c].CodigoCliente.toString(),
          "codigoProducto": aux[c].CodigoProducto.toString(),
          "nombreProducto": aux[c].Producto.toString(),
          "precioEspecial": aux[c].PrecioEspecial.toString(),
          "precioMinimo": aux[c].PrecioMinimo.toString(),
          "descuento": aux[c].Descuento.toString(),
          "cantidad": aux[c].Cantidad.toString(),
      };
     
        recordset.data.push(json);

     }

     let data
  try {
     
  
    let url = `${getState().main.resources.uriSvcSalesMobilityAPI}/api/v1/Tomas/cargaprecioscliente`;
    let response = await axios.put(url, recordset, { headers: { 'Content-Type': 'application/json' } });

     data = response.data;

    //console.log("DATA: ", response)

    dispatch(openMessageBox({ 
      button: 'ok', 
      icon: 'info', 
      message: 'Registro realizado con exito.', 
      callback: () => { 
          dispatch(closeMessageBox());
          dispatch(btnSearch())
          dispatch(push({
              pathname: `/fuerzaventas/gestionpreciosespeciales`,
              search: getState().router.location.search,
              hash: getState().router.location.hash
          }));
         // dispatch(getUser(data));
      }
  }));

    // if(data.length === 0)
    // { 
    //     dispatch(openMessageBox({ 
    //         button: 'ok', 
    //         icon: 'info', 
    //         message: 'Registro realizado con exito.', 
    //         callback: () => { 
    //           dispatch(closeMessageBox());
    //           dispatch(btnSearch())
    //           dispatch(push({
    //               pathname: `/fuerzaventas/gestionpreciosespeciales`,
    //               search: getState().router.location.search,
    //               hash: getState().router.location.hash
    //           }));
    //         }
    //       }));

    //   }else{
    //     dispatch(openMessageBox({ 
    //       button: 'ok', 
    //       icon: 'info', 
    //       message: 'Registro NO realizado con exito.', 
    //       callback: () => { 
    //           dispatch({ type: SET_MASK, loading: false });
    //         dispatch(closeMessageBox()); 
    //       }
    //     }));
    //   }
  


  } catch (error) {
    dispatch({ type: SET_MASK, loading: false });
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    

        dispatch(push({
          pathname: `/fuerzaventas/gestionpreciosespeciales`,
          search: getState().router.location.search,
          hash: getState().router.location.hash
      }));

  // dispatch({ type: RECEIVE_V_LIST, column: [], payload: [] });
  //   return true

  dispatch({ type: SET_MASK, loading: false });
  //dispatch({ type: ON_CLEAR_CARGA_ARCHIVO, });
  // const col = ["estatus"]
  
  // if(data !== undefined && data !== null && data.length > 0){

  //   const dataStore = getState().gestionpreciosespeciales.stored.data;
  //   dataStore.forEach((element, index) => {

  //     if(data[index].estatus === 'No existe NIT')
  //     {

  //       element.Nit_Ruc = data[index].estatus;

  //     }else if(data[index].estatus === 'No existe CodigoCliente')
  //     {

  //       element.CodigoCliente = data[index].estatus;

  //     }else if (data[index].estatus === 'No existe NIT y CodigoCliente')
  //     {
    

  //       element.Nit_Ruc = 'No existe RUC/NIT'
  //       element.CodigoCliente = 'No existe CodigoCliente';

  //     }
  //     // console.log("element: ", element)
  //     // console.log( "data enviado", data.data[index].enviado)
  //     //  element.Status = data.data[index].enviado;
  //   });

  //   dispatch({ type: RECEIVE_V_LIST2, columns: col, payload: dataStore });
  //   //dispatch({ type: RECEIVE_V_LIST, columns: col, payload: dataStore });


  //   dispatch(
  //     openMessageBox({
  //       button: "ok",
  //       icon: "error",
  //       message:
  //         "Ocurrio un error al procesar los datos.",
  //       callback: () => dispatch(closeMessageBox()),
  //     })
  //   );
  // }
  
  // window.localStorage.setItem('respondeUploadFile', true)
  
  ////window.localStorage.setItem('disableButtonLoadFile', false) 
  return true;
  }
};

export const cabecera = () =>(dispatch,getState)=>{

  var head = [
 
   "Nit_Ruc","CodigoCliente","Cliente","CodigoProducto",
   "Producto","PrecioEspecial","PrecioMinimo","Descuento","Cantidad"
   ]
   
  var arreglo = getState().gestionpreciosespeciales.stored.data
 
     arreglo.forEach((obj) => {
       head.forEach((key) => {
         if (!obj.hasOwnProperty(key)) {
           obj[key] = 0;
         }
       });
     });
 
 }


export const onUpdateColumn = (column) => (dispatch, getState) => {
  getState().gestionpreciosespeciales.table.columns.map((item, i) => {
    if (item.dataKey !== column.dataKey) {
      return item;
    } else {
      item.width = column.width;
    }
    return {
      // ...item,
      //  width: column.width
    };
  });
};

export const onUpdateFieldFilter = (key, value) => (dispatch, getState) => {
  dispatch({ type: UPDATE_FIELD_FILTER, key, value });
  
  if (key !== 'nombre') {
        dispatch({ type: RECEIVE_V_LIST, column: [], payload: [] });
  }
};

export const openAdvancedSearch = (opts) => ({
  type: ADVANCED_SEARCH_OPEN,
  payload: opts,
});

export const setOrder = (order, orderBy) => ({
  type: SET_ORDER,
  order,
  orderBy,
});

export const closeAdvancedSearch = () => ({ type: ADVANCED_SEARCH_CLOSE });
