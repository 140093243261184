import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    onSearchDistribuidorClick,
    onUpdateFieldFilter
} from '../../../store/channelmap/reportefectividad/actions';

import { ActionField, FieldSet } from '@sigeco/tools';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { KeyboardDatePicker } from '@material-ui/pickers';

const useStyles = makeStyles(() => ({
    content: {
        padding: '0.5rem 0.75rem 0.75rem'
    },
    root: {
        flex: '0 0 auto',
        marginBottom: '0.75rem',
        '@media (min-width: 1401px)': {
            marginBottom: '1rem',
        }
    }
}));

const ReportEfectividadFilter = () => {
    const matches = useMediaQuery('(min-width:701px)');
    const classes = useStyles();
    const big = useMediaQuery('(min-width:1201px)');
    const middle = useMediaQuery('(min-width:701px)');
    const filters = useSelector(state => state.reportefectividad.filters);
    const dispatch = useDispatch();
    return (
        <div className={classes.root}>
            <FieldSet iconCls="fas fa-filter" title="Filtros" defaultExpanded expandable>
                <Grid className={classes.content} container spacing={2}>
                    
                    <Grid container item spacing={1} xs={12}>
                        <Grid item xs={big ? 4 : (middle ? 4 : 12)}>
                            <ActionField
                                label="Agente"
                                placeholder="Enter para buscar agente"
                                value={filters.dDistribuidor}
                                variant={middle ? "outlined" : "standard"}
                                onHandleAction={() => dispatch(onSearchDistribuidorClick())}
                            />
                        </Grid>
                    </Grid>

                <Grid className={classes.content} container spacing={2}>
                    <Grid container item xs={12} spacing={1}>
                        <Grid item>
                            <KeyboardDatePicker
                                autoOk
                                disableToolbar
                                format="dd/MM/yyyy"
                                label="Desde"
                                value={filters.desde}
                                inputVariant={matches ? "outlined" : "standard"}
                                variant={matches ? "inline" : "dialog"}
                                onChange={date => dispatch(onUpdateFieldFilter('desde', date))}
                            />
                        </Grid>
                        <Grid item>
                            <KeyboardDatePicker
                                autoOk
                                disableToolbar
                                format="dd/MM/yyyy"
                                label="Hasta"
                                value={filters.hasta}
                                inputVariant={matches ? "outlined" : "standard"}
                                variant={matches ? "inline" : "dialog"}
                                onChange={date => dispatch(onUpdateFieldFilter('hasta', date))}
                            />
                        </Grid>
                    </Grid>
                </Grid>
           
       
                </Grid>
            </FieldSet>
        </div>
    )
};

export default ReportEfectividadFilter;