import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    btnSearch,
    btnExport,
    restartState
  } from '../../store/movilidad/carteravendedor/actions';/*'../../store/channelmap/reporteseguimiento/actions';*/

import { Body, Footer, Page } from '@sigeco/tools';
import AppBar from '@material-ui/core/AppBar';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';


import CarteraVendedorFilter from '../../components/movilidad/carteravendedor/CarteraVendedorFilter';
import CarteraVendedorList from '../../components/movilidad/carteravendedor/CarteraVendedorList';



const CarteraVendedor = ({onClose, setTitle}) => {
    const loading = useSelector(state => state.reporteseguimiento.loading);
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setTitle('Listado');
    // eslint-disable-next-line
    }, []);

    function onBtnClose() {
        dispatch(restartState());
        onClose();
    }

    return (
        <Page>
            <Backdrop style={{ position: 'absolute', zIndex: 1200 }} open={loading} transitionDuration={0}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <Body>
            <CarteraVendedorFilter />
            <CarteraVendedorList /> 
          
        </Body>
        <Footer>
                <Button
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={() => dispatch(btnExport())}
                    startIcon={<Icon className="fas fa-file-excel"/>}
                >
                    Exportar
                </Button>
            <Button
                color="primary"
                size="small"
                variant="outlined"
                onClick={() => dispatch(btnSearch())}
                startIcon={<Icon className="fas fa-search"/>}
            >
                Buscar
            </Button>
      
            <Button 
                color="primary" 
                size="small" 
                variant="outlined" 
                onClick={() => onBtnClose()} 
                startIcon={<Icon className="fas fa-door-open"/>}
            >
                Salir
            </Button>
        </Footer>
        <Dialog fullScreen open={open} onClose={() => setOpen(false)}>
            <AppBar>
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={() => setOpen(false)} aria-label="close">
                <Icon className="fas fa-times" />
                </IconButton>
                <Typography variant="h6">
                Localización de registros
                </Typography>
            </Toolbar>
            </AppBar>
            
        </Dialog>
        </Page>
    )
};

export default CarteraVendedor;