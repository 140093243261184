import React from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import {
    onUpdateFieldEntity,
    removeImage,
    setImage
} from '../../../store/channelmap/marca/actions';

import Checkbox from '@material-ui/core/Checkbox';
import { FieldSet } from '@sigeco/tools';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Link from '@material-ui/core/Link';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
    checkbox: {
        color: "#9c9c9c",
        padding: 0
    },
    details: {
        display: 'flex',
        padding: '0.5rem 0.75rem 0.75rem'
    },
    helperText: {
        fontSize: '0.625rem',
        fontWeight: 300,
        marginBottom: '1rem',
        marginTop: '0.5rem'
    },
    image: {
        backgroundImage: 'url(https://storageterpel.blob.core.windows.net/salesmobility/images/imagen_no_disponible.jpg)',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat', 
        backgroundSize: 'contain',
        border: '3px solid ' + theme.palette.primary.main,
        borderRadius: 3,
        boxShadow: '0 0 13px 0 rgba(0,0,0,.1)',
        color: theme.palette.primary.main,
        fontSize: '6rem',
        height: '6rem',
        textAlign: 'center',
        width: '6rem',
        '@media (min-width: 701px)': {
            fontSize: '7.5rem',
            height: '7.5rem',
            width: '7.5rem'
        }
    },
    imageButton: {
        cursor: 'pointer', 
        display: 'flex', 
        '-webkit-box-align': 'center', 
        alignItems: 'center',
        position: 'absolute', 
        right: -10, 
        top: -10, 
        width: 30, 
        height: 30, 
        borderRadius: '50%', 
        backgroundColor: theme.palette.primary.main, 
        justifyContent: 'center'
    },
    imageClose: {
        cursor: 'pointer', 
        display: 'flex', 
        '-webkit-box-align': 'center', 
        alignItems: 'center',
        position: 'absolute', 
        right: -10, 
        bottom: -10, 
        width: 30, 
        height: 30, 
        borderRadius: '50%', 
        backgroundColor: '#fff', 
        justifyContent: 'center'
    },
    imageIcon: {
        color: theme.palette.primary.contrastText,
        fontSize: '0.9rem'
    },
    imageInput: {
        height: 0, 
        opacity: 0,
        overflow: 'hidden',
        width: 0
    },
    imageWrap: {
        display: 'inline-block',
        position: 'relative'
    },
    label: {
        alignItems: 'center',
        color: 'rgba(0, 0, 0, 0.54)', 
        display: 'flex', 
        fontSize: '0.8125rem', 
        width: 90
    },
    link: {
        cursor: 'pointer', 
        fontSize: '0.75rem'
    },
    paper: {
        marginBottom: '0.75rem',
        '@media (min-width: 1401px)': {
            marginBottom: '1rem'
        }
    }
}));

const MarcaForm = () => {
    const classes = useStyles();
    const matches = useMediaQuery('(min-width:701px)');
    const entity = useSelector(state => state.marca.entity);
    const errors = useSelector(state => state.marca.errors);
    const form = useSelector(state => state.marca.form);
    const formState = useSelector(state => state.marca.formState);
    const dispatch = useDispatch();
    const isView = formState === 'view';

    const handleImageChange = e => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            dispatch(setImage(file, reader.result));
        }

        reader.readAsDataURL(file);
    }

    const onFieldChange = event => {
        dispatch(onUpdateFieldEntity(event.target.name, event.target.value));
    }

    const onCheckBoxChange = event => {
        dispatch(onUpdateFieldEntity(event.target.name, event.target.checked));
    }

    return (
        <div>
            <FieldSet className={classes.paper} iconCls="fas fa-info-circle" title="Información">
                <div className={classes.details}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <div style={{ textAlign: matches ? 'left' : 'center' }}>
                                <div className={classes.imageWrap}>
                                    {!form.imagen || form.imagen === '' ? (<div className={classes.image}/>) : 
                                        (<div className={classes.image} style={{ backgroundImage: `url(${form.imagen})`}}/>)}
                                    {!isView && (<label className={classes.imageButton}>
                                        <Icon className={clsx("fas fa-pen", classes.imageIcon)} />
                                        <input className={classes.imageInput} type="file" name="profile_avatar" accept=".png, .jpg, .jpeg, .svg" onChange={handleImageChange} />
                                    </label>)}
                                </div>
                                {!isView && form.imagen !== '' && (<div>
                                    <Link className={classes.link} onClick={() => dispatch(removeImage())}>
                                        Quitar
                                    </Link>
                                </div>)}
                                {!isView && (<div className={classes.helperText}>Seleccione una imagen (tamaño máximo de 500 KB).</div>)}
                            </div>
                        </Grid>
                        <Grid item xs={12} container>
                            <Grid item xs={matches ? 6 : 12}>
                                <TextField
                                    autoComplete="off"
                                    error={errors.nombre !== ''}
                                    fullWidth
                                    helperText={errors.nombre}
                                    label="Nombre"
                                    name="nombre"
                                    onChange={onFieldChange}
                                    required
                                    value={entity.nombre}
                                    variant={matches ? "outlined" : "standard"}
                                    inputProps={{
                                        maxLength: 50,
                                        readOnly: isView
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container>
                            <Grid item xs={12} container>
                                <div className={classes.label}>Activo:</div>
                                <Checkbox
                                    name="activo"
                                    className={classes.checkbox} 
                                    checked={entity.activo} 
                                    color="primary" 
                                    disabled={isView} 
                                    disableRipple
                                    onChange={onCheckBoxChange}
                                    inputProps={{
                                        readOnly: isView
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </FieldSet>
        </div>
    );
};

export default MarcaForm;