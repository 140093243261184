import React, { useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from "react-router-dom";
import { LinkContainer } from 'react-router-bootstrap';
import {
    btnSearch,
    btnExport ,
    onClear,
    setFormState2,
    setFormState,
    onbtnSave,
    onbtnDelete,
    clearSelect,
    restartState,
    onbtnUpdate,
    btnDelete
} from "../../store/movilidad/planificarvisita/actions";
import { closeMessageBox, openMessageBox} from '../../store/main/actions';

import { Body, Footer, Page } from '@sigeco/tools';

import { Button, Icon, makeStyles } from "@material-ui/core";

import PlanificarVisitaFilter from '../../components/movilidad/planificarvisita/PlanificarVisitaFilter';
import PlanificarVisitaList from '../../components/movilidad/planificarvisita/PlanificarVisitaList';
import PlanificarVisitaCreate from '../../components/movilidad/planificarvisita/PlanificarVisitaCreate';
import PlanificarVisitaTable from '../../components/movilidad/planificarvisita/PlanificarVisitaTable';
import PlanificarVisitaModificar from '../../components/movilidad/planificarvisita/PlanificarVisitaModificar';

const useStyles = makeStyles(() => ({
    content: {
        padding: '0.5rem 0.75rem 0.75rem'
    },
    root: {
        flex: '0 0 auto',
        marginBottom: '0.75rem',
        '@media (min-width: 1401px)': {
            marginBottom: '1rem',
        }
    }
}));


const PlanificarVisita= ({onClose, setTitle}) => {
    const location = useLocation();
    const formState = useSelector(state => state.planificarvisita.formState);
    const store = useSelector(state => state.planificarvisita.store);
    const loading = useSelector(state => state.planificarvisita.loading);
    const classes = useStyles();
    const match = useRouteMatch();
    const dispatch = useDispatch();
    
    useEffect(() => {
        switch(match.path) {
          case '/movilidad/planificarvisita/crear':
            setTitle('Planificar Visita');
            dispatch(setFormState2('new'))
            if(formState !== 'new') {
                dispatch(setFormState2('new'));
            }
            break;
          case '/movilidad/planificarvisita/:id':
            dispatch(setFormState2('view', match.params['id']));
            setTitle('Modificar Visita');
            break;
          case '/movilidad/planificarvisita/:id/editar':
            dispatch(setFormState2('edit', match.params['id']));
            setTitle('Modificar Visita');
            break;
          default:
            dispatch(setFormState2('search'));
            setTitle('Búsqueda');
            break;
        }
      // eslint-disable-next-line
    }, [match.path]);

    function onBtnClose() {
        //dispatch(onClear());
        dispatch(restartState());
        onClose();
    }

    function btnSave() {
        dispatch(onbtnSave());
    
    }
    function btnModificar() {
     
        // if (store.selected.length !== 0) {
        //     dispatch(
        //       openMessageBox({
        //         button: "ok",
        //         icon: "warning",
        //         message: "Tiene elemeno(s) seleccionado(s) para eliminar, presione Eliminar",
        //         callback: () => dispatch(closeMessageBox()),
        //       })
        //     );
        //     return;
        //   }


        // dispatch(setFormState('new'));
    }
    
    function btnEliminar() {
        // dispatch(onbtnDelete());
    }

    function btnState(par) {
        // dispatch(setFormState(par));
        // dispatch(clearSelect('data'))

    }


    return (
        <Page className={classes.root} style={{ overflowY:'none !important',}}>
            
            <Body>
                {formState === 'search' &&<PlanificarVisitaFilter />}
                {formState === 'search' &&<PlanificarVisitaList />}
                {formState !== 'search' && formState!=='view' && <PlanificarVisitaCreate/>}
                {formState !== 'search' && formState!=='view' &&<PlanificarVisitaTable/>}
                {formState === 'view'  && <PlanificarVisitaModificar/>}
            </Body>

            <Footer>
                {/*store.selected.length !== 0*/}
            { formState === 'view'  &&
                <Button
                    color="primary" 
                    size="small" 
                    variant="outlined"
                    onClick={() => dispatch(btnDelete())}
                    startIcon={<Icon className="fas fa-trash"/>}
                >
                    Eliminar
                </Button>
            }
            {formState === 'search' &&
                <Button
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={() => dispatch(btnExport())}
                    startIcon={<Icon className="fas fa-file-excel"/>}
                  >
                    Exportar
                </Button>}
                {formState === 'search' &&
                <Button
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={() => dispatch(btnSearch())}
                    startIcon={<Icon className="fas fa-search"/>}
                >
                    Buscar
                </Button>}
                {/* {(formState === 'search' && store.selected.length === 0) &&
                <Button
                    color="primary" 
                    size="small" 
                    variant="outlined"
                    onClick={() => btnModificar()}
                    startIcon={<Icon className="fas fa-edit"/>}
                >
                    Modificar
                </Button>
                } */}

                { formState === 'view'  &&
                    <Button
                        color="primary" 
                        size="small" 
                        variant="outlined"
                        onClick={() => dispatch(onbtnUpdate())}
                        startIcon={<Icon className="fas fa-edit"/>}
                    >
                        Modificar
                    </Button>
                }

                {(formState === 'search') && <LinkContainer to={{ pathname: '/movilidad/planificarvisita/crear', search: location.search, hash: location.hash }}>
                    <Button
                        color="primary" 
                        size="small" 
                        variant="outlined"
                        startIcon={<Icon className="fas fa-file"/>}
                    >
                        Asignar
                    </Button>
                </LinkContainer>}

            {formState !== 'search' && formState !== 'view' && <Button
                color="primary" 
                size="small" 
                variant="outlined" 
                onClick={() => dispatch(onbtnSave())}
                startIcon={<Icon className="fas fa-save"/>}
            >
                Guardar
            </Button>}



                {/* {formState !== 'search' && formState !== 'view' && 
                <Button
                    color="primary" 
                    size="small" 
                    variant="outlined" 
                    onClick={() => btnSave()}
                    startIcon={<Icon className="fas fa-save"/>}
                >
                    Guardar
                </Button>} */}

            {formState !== 'search' && <LinkContainer to={{ pathname: '/movilidad/planificarvisita', search: location.search, hash: location.hash }}>
                <Button
                    color="primary" 
                    size="small" 
                    variant="outlined"
                    startIcon={<Icon className="fas fa-times-circle"/>}
                >
                    Cancelar
                </Button>
            </LinkContainer>}
            
                <Button 
                    color="primary" 
                    size="small" 
                    variant="outlined" 
                    onClick={() => onBtnClose()} 
                    startIcon={<Icon className="fas fa-door-open"/>}
                >
                    Salir
                </Button>
            </Footer>
        </Page>
    )
};

export default PlanificarVisita;