import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { v4 as uuidv4 } from 'uuid';
import { ClientId, IdentityUrl, RedirectPath } from '../../config';
import { getOptions } from '../main/actions'
import {
    RECEIVE_SESSION,
    SET_AVATAR,
    SET_DATA_USER
} from './types';

import { generateRandomCode } from '../../helpers/commons';

export const createInterceptor = () => (dispatch, getState) => {
    axios.interceptors.request.use(
        config => {
            config.headers.Authorization = `${getState().session.tokenType} ${getState().session.accessToken}`;

            if(config.method === 'post' || config.method === 'put' || config.method === 'delete') {
                config.headers['x-requestid'] = uuidv4();
            }
            
            return config;
        },
        error => {
            Promise.reject(error);
        }
    );

    axios.interceptors.response.use(
        response => response,
        error => {
            if(error.response && error.response.status === 401) {
                dispatch(getSession());
                error.response.data = {
                    eventLogId: 0,
                    message: 'Su token a caducado, reintente por favor.'
                };
            }
            return Promise.reject(error);
        }
    );
};

export const getSession = () => async (dispatch, getState) => {
    let nonce = generateRandomCode(10, true);
    let state = generateRandomCode(10, true);
    let providerUri = `${IdentityUrl}/authorize`
        + `?client_id=${ClientId}`
        + `&response_type=id_token token`
        + `&scope=openid`
        + `&nonce=${nonce}`
        + `&state=${state}`
        + `&redirect_uri=${window.location.protocol}//${window.location.host}${RedirectPath}`;
    let iframeId = 'silent-authorize-iframe';
    let old_iframe = document.getElementById(iframeId);

    if (old_iframe){
        document.body.removeChild(old_iframe);
    }
    //crear iframe
    const iframe = document.createElement('iframe');
    iframe.id = iframeId;
    iframe.className = 'silent-iframe';
    iframe.title = 'silent-iframe';
    iframe.src = providerUri;
    document.body.appendChild(iframe);

    let listener = event => {
        let data = event.data;
        
        if (data.token_type) {
            if(data.state !== state) {
                console.log('Error: El parametro "state" no coincide');
            }

            try {
                let jwt = jwt_decode(data.id_token);
                dispatch({ type: SET_DATA_USER, usuario: jwt.username, nombre: jwt.name });
            } catch (error) {
                console.log(error);
                return;
            }

            dispatch({ 
                type: RECEIVE_SESSION, 
                accessToken: data.access_token, 
                expiresIn: data.expires_in,
                idToken: data.id_token,
                tokenType: data.token_type 
            });

            setTimeout(() => {
                dispatch(getSession());
            }, (parseInt(data.expires_in, 10) - 10)*1000);
            window.removeEventListener('message', listener);

            if (document.body.contains(iframe)) {
                document.body.removeChild(iframe);
            }

            if(!getState().main.loaded) {
                dispatch(getOptions());
            }
        }
        else {
            if(data.error) {
                console.log(data.error);
                if(data.status === 401) {
                    window.location.href = `${IdentityUrl}/oauth/v2/?client_id=${ClientId}&continue=${encodeURIComponent(window.location.href)}`;
                }
            }
        }
    }
    window.addEventListener('message', listener);
};

export const setAvatar = (avatar) => ({ type: SET_AVATAR, avatar });