import axios from 'axios';
//import format from "date-fns/format";
import { IdentityUrl } from '../../../config';
import { closeAdvancedSearch, closeMessageBox, openAdvancedSearch, openMessageBox } from '../../main/actions';
import {
    ERROR_R_LIST,
    RECEIVE_R_LIST,
    //REQUEST_R_LIST,
    RESTART_STATE,
    SET_MASK,
    SET_ORDER,
    SET_SELECTED_ROW,
    UPDATE_FIELD_FILTER,
    UPDATE_TABLE_COLUMN,
   // RECEIVE_R_DET,
    UPDATE_FIELD_DATA
} from './types';

import { exportToSpreadsheet } from '../../../helpers/commons'
//import {  Document, Page, Text, View, StyleSheet} from '@react-pdf/renderer';  

//import { pdf , BlobProvider, PDFDownloadLink , ReactPDF  , PDFViewer } from '@react-pdf/renderer';

//mport PdfDocument from '../../../components/gigantes/resumen/PdfDocument';//
//import PdfComponent from '../../../components/gigantes/resumen/PdfComponent';  //E*

//import { saveAs } from 'file-saver';

// Create styles
/*
const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#C0C0C0'
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1
    }
  });
*/

  /*
 const  renderUrl = async() => new Promise((resolve, reject) => {
    const blob = ReactPDF.pdf(<MyDoc />).toBlob()
    console.log(blob)
    const url = URL.createObjectURL(blob)
    if(url && url.length>0){
        resolve(url)
      }
    }
    ).then(res => res)
    .catch(err => console.log(err)
    )

*/

  export const onBtnPdf = () => async (dispatch, getState) => {
   //  PdfComponent()   //ESTE DEBE ACTIVARSE E*
     
     
/*
  return (
    <div>
                <PdfComponent/>
    </div>
  );
*/
     
    /*
    <PDFViewer style={{ flex: 1 }}>
        <MyDoc/>
      </PDFViewer>
      */
               // generatePdfDocument('ganadores');
              //  console.log('getting pdf   .....')
                /*
            <div>
				<PDFDownloadLink document={<MyPdf />} fileName="somename.pdf">
			  		{({ loading }) => (loading ? 'Loading document...' : 'Download now!')}
				</PDFDownloadLink>
		      	</div>

                  */
                    /*
                  return (
                    <>
                        <PDFDownloadLink document={<MyDoc />} fileName="fee_acceptance.pdf">
                            {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
                        </PDFDownloadLink>
                    </>
                  );
                  * /
                /*
                  <PDFDownloadLink document={<MyDoc />} fileName="fee_acceptance.pdf">
                            {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
                </PDFDownloadLink>
                */
 }


export const onExport = () => async (dispatch, getState) => {
   
        if(getState().resumen.store.data.length === 0) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'warning', 
                message: 'No hay información para exportar.', 
                callback: () => dispatch(closeMessageBox())
            }));
            return;
        }
    try {
        dispatch({ type: SET_MASK, loading: true });
    
    //let response = await axios.get(encodeURI(url)); //, { responseType: 'arraybuffer' });
    
    let head = getState().resumen.table.columns;
 
    // eslint-disable-next-line no-array-constructor
    var temp = new Array(); 
    for(var c in head) {
        let x =   Object.values(head[c])[0];
        temp.push(x);
      }    
      // eslint-disable-next-line no-array-constructor
      var carArray = new Array(); 
    
    carArray.push(temp);
   
    let json=   getState().resumen.store.data;

    for(var i in json) {
        let x =   Object.values(json[i]);
        carArray.push(x);
      }

    exportToSpreadsheet(carArray,  'aprobarPedidos') ;

    } catch (error) {
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'error', 
            message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
            callback: () => dispatch(closeMessageBox())
        }));
    } finally {
        dispatch({ type: SET_MASK, loading: false });
    }
};

export const btnSearch = () => async (dispatch, getState) => {

    
    const mes = getState().resumen.filters.desde.getMonth()+1;
    const anio = getState().resumen.filters.desde.getFullYear();
    if (getState().resumen.store.loading) {
        return;
    }
    
    if ( anio >= new Date().getFullYear()+1 || mes >= (new Date().getMonth()+ 1)){
        /*
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'warning', 
            message: 'Los datos de busqueda sobrepasan los registrados..', 
            callback: () => dispatch(closeMessageBox())
        }));
        return;
        */
    }

    // if (getState().resumen.filters.vendedorId    === undefined  ||  getState().resumen.filters.vendedorId === '' ) {
    //     getState().resumen.filters.vendedorId  = 0;
    // }
dispatch({ type: SET_MASK,  loading: true });

    //console.log(getState().tracking);
    //dispatch({ type: RECEIVE_R_LIST, columns: getState().tracking.table.columns, payload: [] });
    //dispatch({ type: REQUEST_R_LIST });
    //const punt = '';
    try {
     
            const punt =  anio <= 2021? `api/v1/Reportes/programa/incentivos?` : 
          `api/v1/Reportes/programa/incentivos2022?` 
      
            //let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Reportes/programa/incentivos?` 
            let url = `${getState().main.resources.uriSvcSalesMobilityAPI}`+punt 
            + `Mes=${mes}`
        + `&Anio=${anio}`
    
        let response = await axios.get(encodeURI(url));
        let data = response.data;

        console.log(response.data)
        dispatch(onUpdateFielddata('presupuesto',  data.presupuesto)); //  
       if (anio <= 2021){
        dispatch(onUpdateFielddata('estmonogrado', data.estrategicoMonogrado)); //    usuarioId));
        dispatch(onUpdateFielddata('estsuper', data.estrategicoSuper)); //    usuarioId));
        dispatch(onUpdateFielddata('clientesnuevos', data.cobertura1)); //    usuarioId));
        dispatch(onUpdateFielddata('clinuevseisocho', data.cobertura2)); //    usuarioId));
        dispatch(onUpdateFielddata('clinuevnueve', data.cobertura3)); //    usuarioId));
        dispatch(onUpdateFielddata('show', true)); //    usuarioId));
       }
       if (anio === 2022){
        dispatch(onUpdateFielddata('estmonogrado', data.estrategico)); //    usuarioId));
        dispatch(onUpdateFielddata('clientesnuevos', data.cobertura)); //    usuarioId));
        dispatch(onUpdateFielddata('clinuevseisocho', data.cobertura2)); 
        dispatch(onUpdateFielddata('clinuevnueve', data.presupuesto2)); //    usuarioId));
       }
        
    let x = data.data.filter(e  => e.Enviado === getState().resumen.filters.estado)
    //.map(filteredPerson => (
     //       console.log(filteredPerson)
    //  ))

    //Codigo: "0000025"
    //let  dataMap = data.filter(f => (dVendedor === '' ? true : f.dVendedor === dVendedor))
    data.columns[10]['width'] = 160;
    data.columns[10]['type'] ='hidden';
    data.columns[0]['type'] ='text';

        dispatch({ type: RECEIVE_R_LIST, columns: data.columns, payload: x });
        
    } catch (error) {
        let msgError = '';
        dispatch({ type: SET_MASK, loading: false });
        dispatch({ type: ERROR_R_LIST });

        if (error.response) {
        
            if(error.response.status === 400) {
                    msgError = (error.response.data.eventLogId === 0 ? 'No existe Registro  para esta fecha ' : `EventoId: ${error.response.data.eventLogId}. `+ error.response.data.message) 
            }else{
                msgError = (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message;
            }
            dispatch(openMessageBox({
                button: 'ok',
                icon: 'error',
                message: msgError,
                callback: () => dispatch(closeMessageBox())
            }));
        }

       
    }finally{
        dispatch({ type: SET_MASK, loading: false });
    }
};
export const onUpdateColumn = (column) => (dispatch, getState) => {
   
    console.log(column)
    // eslint-disable-next-line array-callback-return
    if(!column) return;
    let columns = getState().resumen.table.columns.map(item => {
        console.log(item);
        if(item.dataKey !== column.dataKey) {
            return item;
        }

        return {
            ...item,
            width: column.width
        };
    });

    dispatch({ type: UPDATE_TABLE_COLUMN, columns });
};


export const onSearchDistribuidorClick = () => async (dispatch) => {
    let fields = [
        { 'dataKey': 'razonSocial', 'label': 'Agente', 'type': 'string', 'width': 225 }
    ];

    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de agente',
        getData: async (params) => {
            let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`
            let response = await axios.get(encodeURI(url), { params: params });

            return response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldFilter('distribuidorId', result[0].distribuidorId));
                dispatch(onUpdateFieldFilter('dDistribuidor', `${result[0].razonSocial}`));
                dispatch(onUpdateFieldFilter('supervisorId', ''));
                dispatch(onUpdateFieldFilter('dSupervisor', ''));
                dispatch(onUpdateFieldFilter('vendedorId', ''));
                dispatch(onUpdateFieldFilter('dVendedor', ''));
            }
        }
    }));
};

export const onSearchSupervisorClick = () => async (dispatch, getState) => {
    let fields = [
        { 'dataKey': 'nombre', 'label': 'Nombre', 'type': 'string', 'search': true, 'width': 225 },
        { 'dataKey': 'nombreUsuario', 'label': 'Usuario', 'type': 'string', 'width': 115 }
    ];

    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de supervisor',
        getData: async (params) => {
            let url = `${IdentityUrl}/api/v1/usuarios?rol=Supervisor`
            let response = await axios.get(encodeURI(url), { params: params });
            let distribuidorId = getState().resumen.filters.distribuidorId;

            if(distribuidorId > 0) {
                response.data.data = response.data.data.filter(f => f.distribuidorId === distribuidorId);
            }

            return response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldFilter('supervisorId', result[0].usuarioId));
                dispatch(onUpdateFieldFilter('dSupervisor', `${result[0].nombre}`));
                dispatch(onUpdateFieldFilter('vendedorId', ''));
                dispatch(onUpdateFieldFilter('dVendedor', ''));
            }
        }
    }));
};


export const onSearchVendedorClick = () => async (dispatch, getState) => {
    let fields = [
        { 'dataKey': 'nombre', 'label': 'Nombre', 'type': 'string', 'search': true, 'width': 225 },
        { 'dataKey': 'nombreUsuario', 'label': 'Usuario', 'type': 'string', 'width': 115 }
    ];
    //console.log(getState());
  
    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de vendedor',
        getData: async (params) => {
            //let url = `${IdentityUrl}/api/v1/usuarios/porjerarquia?usuarioId=${getState().resumen.filters.supervisorId}`
            //console.log(getState().main)
            let url = `${getState().main.resources.uriSvcSalesMobilityAPI}/api/v1/Vendedores?soloActivos=true&nombre=` 
        
            let response = await axios.get(encodeURI(url), { params: params });
            let distribuidorId = getState().resumen.filters.distribuidorId;    

            if(distribuidorId > 0) {
                response.data.data = response.data.data.filter(f => f.distribuidorId === distribuidorId);
            }

            return response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {

                dispatch(onUpdateFieldFilter('vendedorId', result[0].vendedorId)); //    usuarioId));
                dispatch(onUpdateFieldFilter('dVendedor', `${result[0].nombre}`));
                dispatch({ type: RECEIVE_R_LIST, columns: getState().resumen.table.columns, payload: [] });
            }
        }
    }));
};

/*
export const openDialogClient = (data) => (dispatch, getState) => {
    try {
        var storeData = getState().reportevisitas.data.filter(f => f.dVendedor === data.dVendedor);
        
        dispatch({ type: SHOW_POPUP_CLIENT, storeData });
    } catch (error) {
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'error', 
            message: error, 
            callback: () => dispatch(closeMessageBox())
        }));
    }
};
*/

export const onUpdateFieldFilter = (key, value) => (dispatch, getState) => {
    //({ type: UPDATE_FIELD_FILTER, key, value });
    dispatch({ type: UPDATE_FIELD_FILTER, key, value })
    dispatch({ type: RECEIVE_R_LIST, columns:[], payload: [] })
    dispatch(onUpdateFielddata('estmonogrado', [])); //    usuarioId));
    dispatch(onUpdateFielddata('clientesnuevos', [])); //    usuarioId));
    dispatch(onUpdateFielddata('presupuesto',  [])); //  
     dispatch(onUpdateFielddata('estsuper', [])); //    usuarioId));
     dispatch(onUpdateFielddata('clinuevseisocho',[])); //    usuarioId));
    dispatch(onUpdateFielddata('clinuevnueve', [])); //    usuarioId));
    dispatch(onUpdateFielddata('show', false)); //    usuarioId));
    };

export const onUpdateFielddata = (key, value) => (dispatch, getState) => {
        dispatch({ type: UPDATE_FIELD_DATA, key, value })
       // dispatch({ type: RECEIVE_R_LIST, columns:[], payload: [] })
        };

export const restartState = () => ({ type: RESTART_STATE });

export const setOrder = (order, orderBy) => ({ type: SET_ORDER, order, orderBy });

export const setSelectedRow = (selected) => ({ type: SET_SELECTED_ROW, selected});
