import React, { } from 'react';
import { useDispatch, useSelector } from "react-redux";

import makeStyles from '@material-ui/core/styles/makeStyles';

import useMediaQuery from '@material-ui/core/useMediaQuery';
//
import Grid from "@material-ui/core/Grid";
import { KeyboardDatePicker } from "@material-ui/pickers";

//   de otro compomenete
import {onUpdateFieldFilterH} from "../../../store/movilidad/bandejaprobacion/actions";
import { formatoPeru} from "../../../helpers/commons";

const useStyles = makeStyles(() => ({

  cuadron: {
    marginLeft:'5px',
  },
  resul: {
    fontSize: "10px !important",
    background: "white",
    textAlign: "Left !important",
  },
  resuld: {
    fontSize: "10px !important",
    textAlign: "Right !important",
    background: "white",
  },
  porfa: {
    "@media (max-width: 767px)": {
      height: "315px",
      width: "570px",
      marginLeft: "5px",
      fontSize:'6px',
    },
  },
  a: {
    fontSize: "12px",
  },
  table: {

    highlight: {
      backgroundColor: 'red',
    },
    background: 'rgba(0,102,255,.25)',
    textalign: 'center',
    fontSize: "max(1 vw, 5px)",
    margin: "auto",
    '& th': {
      border: '1px solid rgba(16,76,167,.25)',
      background: 'rgba(0,102,255,.25)',
      textalign: 'center',
    },
    '& td': {
      border: '1px solid rgba(16,76,167,.25)',
    }
  },
}));
const HistoricoCobranza = () => {

    const classes = useStyles();
    const matches = useMediaQuery("(min-width:701px)");
    var d = new Date()
    console.log(d.getMonth(), 'el mes')

    //const store = useSelector((state) => state.bandejaaprobacion.store);
    const loading = useSelector((state) => state.bandejaaprobacion.loading);
    const datadetail = useSelector((state) => state.bandejaaprobacion.store.detallecobranza);
    const t = useSelector((state) => state.bandejaaprobacion.store.selectedPase);
    const modalInfo = t[0]
    const filters = useSelector((state) => state.bandejaaprobacion.filtersH);
    const Promedio = useSelector((state) => state.bandejaaprobacion.filters.promedio);
    const pesos = useSelector((state) => state.bandejaaprobacion.filters.pesos);
    

    console.log(Promedio[0]['Noviembre']  )
    console.log(Promedio)
    console.log(pesos)

    const dispatch = useDispatch();

    let total = 0;

    var ax = [];

    for (var i = 0; i < Promedio.length; i++) {
      var objA = Promedio[i];
  
      Object.keys(objA).forEach(function(mes) {
          var objB = pesos.find(function(item) {
              return item.Mes === mes;
          });
  
          if (objB) {
              var objAx = {};
              objAx[mes] = objA[mes];
              objAx.Promedio = objB.Promedio;
              objAx.Mes = objB.Mes; 
              ax.push(objAx);
          }
      });
  }
  for (let i = 0; i < ax.length; i++) {
    const obj = ax[i];
    const newValue = obj[Object.keys(obj)[0]]; // Obtiene el valor de la clave dinámica
    ax[i] = { Valor: newValue, ...obj }; // Crea un nuevo objeto con la clave renombrada y las demás claves
}


  console.log("genialidad: ",ax)

    
    return (
    <>
      {t.length > 0  &&  (
        <div className={classes.porfa} >          
          <h2 style={{ textAlign: "center", fontSize: "12px" }}>Detalle Histórico del Cliente</h2>
                
          <div className={classes.cuadrin} style= {{ width: '95%', marginLeft:'1px'}} >
            <table  align="center" style= {{ width: '104%'}} >
              <tbody > 
                <tr>
                  {/* pendiente areglar el dato segun ObjectKey para el nombre del mes */}
                  <td style={{ width: "85px" , backgroundColor: "#BFD9FF",fontSize: "10px" ,  fontWeight: "bold"}} >CODIGO PEDIDO:</td> 
                  <td colSpan="1" style={{ width: "20%",fontSize: "10px"  }}> <b>{modalInfo["Codigo"]} </b></td>
                  <td style={{ width: "85px" , backgroundColor: "#BFD9FF",fontSize: "10px" ,  fontWeight: "bold"}} >NIT:</td> 
                  <td colSpan="2" style={{ width: "20%",fontSize: "10px"  }}> <b>{modalInfo["NIT/RUC"]} </b></td>
                  <td style={{ width: "75px" , backgroundColor: "#BFD9FF",fontSize: "10px",fontWeight: "bold" }}> CLIENTE:</td>
                  <td colSpan="2" style={{ width: "90%",fontSize: "10px" }}>{modalInfo.Cliente.toUpperCase()}</td>
                  <td style={{ width: "65px" , backgroundColor: "#BFD9FF",fontSize: "10px",fontWeight: "bold" }}> L.CREDITO:</td>
                  <td colSpan="4" style={{ width: "10%",fontSize: "10px" }}>{modalInfo.LineaCredito}</td>
                 
                </tr>
                <tr>
                  
                </tr>
                <tr> 
                  <td colSpan="4" style={{ width: "60px" , fontSize: "10px" , backgroundColor: "#BFD9FF",  fontWeight: "bold", textAlign: 'center'}} >PROMEDIO SEMESTRAL</td>
                
                  <td colSpan="6" rowSpan="3"style={{ width: "40px" , fontSize: "10px" ,  fontWeight: "bold", textAlign: 'center'}} >
                     <td colSpan="1" style={{ width: "20%",fontSize: "10px" , backgroundColor: "#EBE5B7"}}> <b> {ax[0]['Mes']}</b>
                        <td  rowSpan="1" style={{ width: "15%",fontSize: "10px"  }}> <b>{parseFloat(ax[0]['Valor']).toFixed(2)+'gls'}</b></td>
                        <td  rowSpan="1" style={{ width: "15%",fontSize: "10px"  }}> <b>{formatoPeru('en-US', 'USD',parseFloat(ax[0]['Promedio']))}</b></td>
                    </td>
                    <td colSpan="1" style={{ width: "20%",fontSize: "10px"  , backgroundColor: "#EBE5B7"  }}> <b>{ax[1]['Mes']}</b>
                        <td  rowSpan="1" style={{ width: "10%",fontSize: "10px"  }}> <b>{parseFloat(ax[1]['Valor']).toFixed(2)+'gls'}</b></td>
                        <td  rowSpan="1" style={{ width: "15%",fontSize: "10px"  }}> <b>{formatoPeru('en-US', 'USD',parseFloat(ax[1]['Promedio']))}</b></td>
                    </td>
                    <td colSpan="1" style={{ width: "20%",fontSize: "10px"  , backgroundColor: "#EBE5B7"  }}> <b>{ax[2]['Mes']}</b>
                        <td  rowSpan="1" style={{ width: "10%",fontSize: "10px"  }}> <b>{parseFloat(ax[2]['Valor']).toFixed(2)+'gls'}</b></td>
                        <td  rowSpan="1" style={{ width: "15%",fontSize: "10px"  }}> <b>{formatoPeru('en-US', 'USD',parseFloat(ax[2]['Promedio']))}</b></td>
                    </td>
                    <td colSpan="1" style={{ width: "20%",fontSize: "10px"  , backgroundColor: "#EBE5B7"  }}> <b>{ax[3]['Mes']}</b>
                        <td  rowSpan="1" style={{ width: "10%",fontSize: "10px"  }}> <b>{parseFloat(ax[3]['Valor']).toFixed(2)+'gls'}</b></td>
                        <td  rowSpan="1" style={{ width: "15%",fontSize: "10px"  }}> <b>{formatoPeru('en-US', 'USD',parseFloat(ax[3]['Promedio']))}</b></td>
                    </td>
                    <td colSpan="1" style={{ width: "20%",fontSize: "10px"  , backgroundColor: "#EBE5B7"  }}> <b>{ax[4]['Mes']}</b>
                        <td  rowSpan="1" style={{ width: "10%",fontSize: "10px"  }}> <b>{parseFloat(ax[4]['Valor']).toFixed(2)+'gls'}</b></td>
                        <td  rowSpan="1" style={{ width: "15%",fontSize: "10px"  }}> <b>{formatoPeru('en-US', 'USD',parseFloat(ax[4]['Promedio']))}</b></td>
                    </td>
                    {/* <td colSpan="1" style={{ width: "20%",fontSize: "10px"  , backgroundColor: "#EBE5B7"  }}> <b>{ax[5]['Mes']}</b>
                        <td  rowSpan="1" style={{ width: "20%",fontSize: "10px"  }}> <b>{parseFloat(Promedio[0]['Valor']).toFixed(2)+'gls'}  </b></td>
                        <td  rowSpan="1" style={{ width: "15%",fontSize: "10px"  }}> <b>{formatoPeru('en-US', 'USD', parseFloat(ax[5]['Promedio']))}</b></td>
                    </td>  */}
                  </td>
                  {/* <tr  style={{display:'inline-block', width: '100%'}}>
                  {ax.map((prm, i) => (
                    
                       <td colSpan="6" rowSpan="3"style={{ display:'flex', flexDirection:'column', width: "100%" , fontSize: "10px" ,  fontWeight: "bold", textAlign: 'center'}} >
                        <td colSpan="1" style={{ width: "100%", fontSize: "10px", backgroundColor: "#EBE5B7" }}>
                            <b>{prm.Mes}</b>
                        </td>
                        <td rowSpan="1" style={{ display:'flex', flexDirection:'column',width: "100%", fontSize: "10px" }}>
                            <b>{parseFloat(prm.Valor).toFixed(2) + 'gls'}</b>
                        </td>
                        <td rowSpan="1" style={{display:'flex', flexDirection:'column', width: "100%", fontSize: "10px" }}>
                            <b>{formatoPeru('en-US', 'USD', parseFloat(prm.Promedio))}</b>
                        </td>
                        </td>
                    
                ))}
                </tr> */}

                 </tr> 
              </tbody>
            </table> 
          </div>
          <div className={classes.cuadrin} style= {{ width: '95%'}} >
            <table  align="center" style= {{ width: '100%', border: '0'}} >
              <tbody style={{border: '0'}} > 
                <tr>    
                  <td style={{border: '0'}}>  
                        <Grid container item xs={12} spacing={1}>
                          <Grid  item xs={6}>
                              <KeyboardDatePicker
                                autoOk
                                disableToolbar
                                format="dd/MM/yyyy"
                                label="Desde"
                                value={filters.desdeHistorico}
                                inputVariant={matches ? "outlined" : "standard"}
                                variant={matches ? "inline" : "dialog"}
                                disableFuture
                                onChange={(date) => 
                                dispatch(onUpdateFieldFilterH("desdeHistorico", date))}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <KeyboardDatePicker
                                autoOk
                                disableToolbar
                                format="dd/MM/yyyy"
                                label="Hasta"
                                value={filters.hastaHistorico}
                                inputVariant={matches ? "outlined" : "standard"}
                                variant={matches ? "inline" : "dialog"}
                                disableFuture
                                onChange={(date) => dispatch(onUpdateFieldFilterH("hastaHistorico", date))}
                              />
                            </Grid>
                        </Grid>  
                  </td>
                </tr> 

              </tbody>
            </table> 
          </div>
          <br></br>
          <div style={{display:'flex', flex: '1'}}>
            <div style={{ display: "none",border:'none' }}>
              {!loading && datadetail.map((ite) => (total = total + ite.monto))}
            </div>
            <div style={{display: 'inlineBlock',  zoom:1}}>

                <div className={classes.cuadron} style={{width:'98%',  height: datadetail.length >= 5 ? "95px " : "85px", overflowX: datadetail.length >= 3 ? "auto" : "hidden", marginTop:"-17px"}}>
              
                  <table  align="center" className="aprobacion" id="tableMod" style={{borderCollapse:"collapse", width: "100%"}}  >
                    <thead>
                      {!loading && datadetail.length > 0 && <tr>
                        <th style={{ width: "290px", fontSize: "11px" }} > Nro. Factura</th>
                        <th style={{ width: "290px", fontSize: "11px" }}> Fecha </th>
                        <th style={{ width: "490px", fontSize: "11px" }}>Vendedor</th>
                        <th style={{ width: "490px", fontSize: "11px" }}>Volumen</th>
                        <th style={{ width: "280px", fontSize: "11px" }}> Monto</th>
                      </tr>}
                    </thead>
                    <tbody  style={{border: "1px solid #BFD9FF"}}>
                      {!loading && datadetail.length > 0 && datadetail.map((item, index) => (
                          <tr key={index}>
                            <td className={classes.resul}> {item.nroDocumento} </td>
                            <td className={classes.resul}> {item.fecha}</td>
                      
                            <td className={classes.resul}> {t[0].Nombre} </td>
                            <td className={classes.resuld}> {item.volumen} </td>
                            <td  className={classes.resuld}> {formatoPeru('en-US', 'USD', item.monto)}</td>
                            {/* <td  className={classes.resuld}> {parseFloat(item.Monto).toFixed(2)} </td> */}
                          </tr>
                      ))}  
                    
                      {datadetail.length === 0 && (
                        <tr  key={1}>
                          <span colSpan="5"  >No existe Registro</span>
                        </tr>
                      )}   
                    </tbody>
                  
                    {loading && (  
                    <tbody>
                      <tr  >
                        <td colSpan="5">
                          <img
                            alt="log"
                            src="https://app.iess.gob.ec/iess-fondo-reserva-afiliado-web/javax.faces.resource/images/load.gif.jsf;jsessionid=8949b76cfea43d283add3bd3dd2d?ln=sentinel-layout"
                            width="40"
                            height="40"
                          />
                        </td>
                      </tr>
                    </tbody>
                    )}
                  </table>
                </div>
                {datadetail.length > 0   &&  !loading &&(
                  <div  style={{borderCollapse:"collapse", width: "96%", fontSize: '12px', textAlign:'right', marginTop: '4px'}} >
                    {/* <td  className={classes.resuld} colSpan="6"   style={{textAlign: 'right', fontSize: '120%'}}> */}
                    <b>Total: {formatoPeru('en-US', 'USD', total)}</b>
                    {/* </td>  */}

                  </div>
                  )} 
            </div>

          </div>
        </div>
      )}
    </>
  );
}

export default HistoricoCobranza ;

