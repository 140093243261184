import {
    CLEAN_FORM,
    SET_STATE_FORM,
    UPDATE_FIELD_FILTER,
    UPDATE_FIELD_ENTITY,
    SET_MASK,
    SET_ENTITY,
    SET_ERROR,
    SET_ORDER,
    RECEIVE_DATA,
    UPDATE_TABLE_COLUMN,
    ERROR_V_LIST,
    RECEIVE_V_LIST,
    REQUEST_V_LIST,
    RECEIVE_V_LIST2,
    RECEIVE_SUB_DETALLE,
    CLEAN_DATA2
} from "./types";

const initialState = {
    formState: "search",
    loading: false,
    entity: {
        Nit: "",
        codigoSucursal: "",
        bonificacion: "",
        bonificacionId: 0,
        codigoProducto: "",
        dBonificacionId: 0,
        desde: "",
        distribuidorId: 0,
        hasta: "",
        producto: "",
        productoId: 0,
        tipo: "",
        sucursalId: 0,
        sucursales: [],
    },
    errors: {
        nit: "",
        codigoSucursal: "",
        producto: "",
        bonificacion: "",
        tipo: "",
        desde: "",
        hasta: "",
    },
    filters: {
        nombre: "",
        desde: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        hasta: new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()),
        dDistribuidor: "",
        distribuidorId: 0,
        valor: '',
        band: false,
    },
    store: {
        loading: false,
        pageSize: 50,
        start: 0,
        total: 0,
        data: [],
        aux: [],
        selected: [],
        subdetalle: [],
        col:['codigoSucursal','codigo','nombre','tipo','codigoProductoCompra','cantidadCompra','cantidadCompraS','codigoProductoRegala','cantidadRegala','cantidadRegalaS' , 'fechaHasta']
    },
    stored: {
        loading: false,
        pageSize: 50,
        start: 0,
        total: 0,
        data: [],
        selected: [],
        //source: null
    },

    table: {
        order: "asc",
        orderBy: "",
        columns: [
            //{ dataKey: "NIT", label: "NIT", width: 100 },
            // { dataKey: "CodigoSucursal", label: "Codigo Sucursal", width: 100 },
            { dataKey: "CodigoProducto", label: "Codigo Producto", width: 100 },
            { dataKey: "Producto", label: "Producto", width: 350 },
            { dataKey: "Bonificacion", label: "Bonificación", width: 350 },
            { dataKey: "Tipo", label: "Tipo de Bonificación", width: 100 },
            { dataKey: "Desde", label: "Desde", width: 80 },
            { dataKey: "Hasta", label: "Hasta", width: 80 },
            { dataKey: "BonificacionId", label: "BonificacionId", width: 250 },
        ],
    },
    tablexport: {
        order: "asc",
        orderBy: "",
        columns: [
            //{ dataKey: "NIT", label: "NIT", width: 100 },
            // { dataKey: "CodigoSucursal", label: "Codigo Sucursal", width: 100 },
            { dataKey: "CodigoProducto", label: "Codigo Producto", width: 100 },
            { dataKey: "Producto", label: "Producto", width: 350 },
            { dataKey: "Bonificacion", label: "Bonificación", width: 350 },
            { dataKey: "Tipo", label: "Tipo de Bonificación", width: 100 },
            { dataKey: "Desde", label: "Desde", width: 120 },
            { dataKey: "Hasta", label: "Hasta", width: 120 },
        ],
    },
};

export const gestionbonificacionReducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case CLEAN_FORM:
            return {
                ...state,
                entity: initialState.entity,
                errors: initialState.errors,
            };
        case SET_STATE_FORM:
            return {
                ...state,
                formState: action.formState,
            };
        case UPDATE_FIELD_FILTER:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.key]: action.value,
                },
            };
        case UPDATE_FIELD_ENTITY:
            return {
                ...state,
                entity: {
                    ...state.entity,
                    [action.key]: action.value,
                },
            };
        case ERROR_V_LIST:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: false,
                },
            };
        case RECEIVE_V_LIST:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: false,
                    total: action.total,
                    data: action.payload,
                    source: null,
                },
            };

        case RECEIVE_V_LIST2:
            return {
                ...state,
                stored: {
                    ...state.store,
                    loading: false,
                    total: action.total,
                    data: action.payload,
                    source: null,
                },
            };
        case RECEIVE_SUB_DETALLE:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: false,
                    subdetalle: action.payload,
                    selected: [],
                }
            };

        case REQUEST_V_LIST:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: false,
                    source: action.source,
                },
            };
        case RECEIVE_DATA:
            return {
                ...state,
                data: action.payload,
                store: {
                    ...state.store,
                    data: action.storeData,
                },
            };
        case UPDATE_TABLE_COLUMN:
            return {
                ...state,
                table: {
                    ...state.table,
                    columns: action.columns,
                },
            };
        case SET_MASK:
            return {
                ...state,
                loading: action.loading,
            };
        case SET_ENTITY:
            //console.log("antes: ", action.payload);
            return {
                ...state,
                entity: {
                    ...state.entity,
                    nit: action.payload.NIT,
                    codigoSucursal: action.payload.CodigoSucursal,
                    sucursalId: action.payload.SucursalId,
                    bonificacion: action.payload.Bonificacion,
                    bonificacionId: action.payload.BonificacionId,
                    codigoProducto: action.payload.CodigoProducto,
                    dBonificacionId: action.payload.DBonificacionId,
                    desde: action.payload.Desde,
                    distribuidorId: action.payload.DistribuidorId,
                    hasta: action.payload.Hasta,
                    producto: action.payload.Producto,
                    productoId: action.payload.ProductoId,
                    tipo: action.payload.Tipo,
                },
            };
        case SET_ERROR:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.key]: action.error,
                },
            };
        case SET_ORDER:
            return {
                ...state,
                table: {
                    ...state.table,
                    order: action.order,
                    orderBy: action.orderBy,
                },
            };

        case CLEAN_DATA2:
            return {
                ...state,
                stored: {
                    ...state.storeD,
                    data: [],}
            };

        default:
            return state;
    }
};
