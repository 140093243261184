import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { addOptionNav } from '../store/main/actions';

const useStyles = makeStyles(() => ({
    image: {
      width: '17rem',
      '@media (min-width: 701px)': {
        width: '20rem'
      },
      '@media (min-width: 1401px)': {
        width: '23.75rem'
      }
    },
    imageMobil: {
      width: '14rem',
      '@media (min-width: 701px)': {
        width: '16rem'
      },
      '@media (min-width: 1401px)': {
        width: '20rem'
      }
    },
    root: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      height: '100%'
    }
  })
);

const Home = ({ pais }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(addOptionNav());
    // eslint-disable-next-line
    }, [])

    return (
        <div className={classes.root}>
            <img alt='logo-mobil' className={pais === 'CO' ?  classes.image : classes.imageMobil} src={pais === 'CO' ? './images/logo-cobranding.png' : './images/Mobil-TM.png' }></img>
        </div>
    );
}

export default Home;