import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setOrder,
     onUpdateColumn
} from '../../../store/clientes/clientessinrutas/actions';
import { sort_by} from '../../../helpers/commons'

import { FieldSet, Table } from '@sigeco/tools';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column'
    }, 
    tableWrap: {
        flex: '1 1 auto'
    }
}));

const ClientesSinRutasList = ({onMapOpen}) => {
    const classes = useStyles();
    const columns = useSelector(state => state.clientessinrutas.table.columns);
    const loading = useSelector(state => state.clientessinrutas.loading);
    const order = useSelector(state => state.clientessinrutas.table.order);
    const orderBy = useSelector(state => state.clientessinrutas.table.orderBy);
    const store = useSelector(state => state.clientessinrutas.store);
    const dispatch = useDispatch();

    console.log(store)
    
    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        dispatch(setOrder(isAsc ? 'desc' : 'asc', property));
        let homes = store.data;
        homes.sort(sort_by(property, isAsc, homes[0][property]));
    };

    const handleResizeColumn = (column) => {
        dispatch(onUpdateColumn(column))
    }
    
    return ( 
        
        <div className={classes.root}>
        <FieldSet className={classes.root} iconCls="fas fa-th-list" title="Resultados"   >
            <div className={classes.tableWrap} >
                <Table                      
                    columns={columns}
                    count={store.total}
                    loading={loading}
                    onRowClick={() => {}}
                    onRowDoubleClick={() => {}}
                    onSort={datakey => handleRequestSort(datakey)}
                    onUpdateColumn={handleResizeColumn}
                    rowCount={store.data.length}
                    rowGetter={index => store.data[index]}
                    selected={store.selected}
                    sortBy={orderBy}
                    sortDirection={order}
                />
            </div>
        </FieldSet>        
       </div>
    )
};

export default ClientesSinRutasList;