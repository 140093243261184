import axios from "axios"; 
import { IdentityUrl } from "../../../config";
import { push } from 'connected-react-router';
import { closeMessageBox, openMessageBox } from "../../main/actions";

import {
  CLEAN_FORM,
  SET_STATE_FORM,
  UPDATE_FIELD_FILTER,
  ERROR_V_LIST,
  RECEIVE_V_LIST,
  REQUEST_V_LIST,
  SET_PAGE,
  SET_SELECTED_ROW,
  SET_MASK,
  SET_ORDER,
  ADVANCED_SEARCH_CLOSE,
  ADVANCED_SEARCH_OPEN,
} from "./types";

import { exportToSpreadsheet, cortarArreglo } from "../../../helpers/commons";

export const setPage = (page) => ({ type: SET_PAGE, page });

export const cleanForm = () => ({ type: CLEAN_FORM });

export const setSelectedRow = (selected) => ({
  type: SET_SELECTED_ROW,
  selected,
});

export const setFormState = (formState, id) => (dispatch, getState) => {
  switch (formState) {
    case "edit":
      if (id && id !== getState().listarproductos.entity.vendedorId) {
        //dispatch(getUser(id));
      }
      break;
    case "new":
      dispatch(cleanForm());
      break;
    case "search":
      dispatch(cleanForm());
      break;
    case "view":
      if (id && id !== getState().listarproductos.entity.vendedorId) {
        //dispatch(getUser(id));
      }
      break;
    default:
      break;
  }
  dispatch({ type: SET_STATE_FORM, formState });
};

//======================
export const onExport = () => async (dispatch, getState) => {
  // no se usa otra forma de exportar

  if (getState().costosproductos.filters.distribuidorId   === 0) {
    dispatch(openMessageBox({
        button: 'ok',
        icon: 'warning',
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox())
    }));
    return;
  }

  if (getState().costosproductos.store.data.length === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "No hay información para exportar.",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }

  if (getState().costosproductos.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }

  try {
    dispatch({ type: SET_MASK, loading: true });
    // let head = getState().costosproductos.table.columns;

    // var aux = [];


    // for (var c in head) {
    //   aux[0] = 'Código de Fabrica'; //'Object.values(head[1])[1];
    //   aux[1] = 'Descripcion' ; //Object.values(head[2])[1];
    //   aux[2] = 'Costo'; //'Object.values(head[0])[1];
    //   aux[3] = 'Fecha'; //Object.values(head[3])[1];
    //   aux[4] = 'Producto Id'; //Object.values(head[4])[1];
    // }

    // var carArray = [];
    // carArray.push(aux);
    // let json = getState().costosproductos.store.data;

    // for (var i in json) {

    //   let x = Object.values(json[i]);

    //   let aux = [];
    //   aux[0] = x[3];
    //   aux[1] = x[5];
    //   aux[2] = x[0];
    //   aux[3] = x[1];
    //   aux[4] = x[2];
    //   carArray.push(aux);
    // }

    let head = getState().costosproductos.tablexport.columns;
    let json = getState().costosproductos.store.data;

    exportToSpreadsheet(cortarArreglo(head,json), "ListarCostosProductos");
  } catch (error) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message:
          (error.response.data.eventLogId === 0
            ? ""
            : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    setTimeout(()=>{dispatch({ type: SET_MASK, loading: false })}, 1000)
  }
};
//=====================

export const btnSearch = () => (dispatch) => {
  dispatch(setPage(0));
  dispatch(onList());
};

export const onSearchDistribuidorClick = () => async (dispatch) => {
  let fields = [
    { dataKey: "razonSocial", label: "Agente", type: "string", width: 225 },
  ];

  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de agente",
      getData: async (params) => {
        let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`;
        let response = await axios.get(encodeURI(url), { params: params });

        return response.data;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(
            onUpdateFieldFilter("distribuidorId", result[0].distribuidorId)
          );
          dispatch(
            onUpdateFieldFilter("dDistribuidor", `${result[0].razonSocial}`)
          );
          dispatch(onUpdateFieldFilter("nombre", ""));
        }
      },
    })
  );
};

export const onList = () => async (dispatch, getState) => {
  if (getState().costosproductos.store.loading) {
    return;
  }

  if (getState().costosproductos.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }
  dispatch({ type: SET_MASK, loading: true });
  //console.log(getState().vendedor);
  //dispatch({ type: RECEIVE_OLAS_LIST, columns: getState().vendedor.table.columns, payload: [] });
  let source = axios.CancelToken.source();
  dispatch({ type: REQUEST_V_LIST, source });

  try {
    let url =  `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Productos/costosproductoslistar?` +
      `distribuidorId=${getState().costosproductos.filters.distribuidorId}` +
      `&nombre=${getState().costosproductos.filters.nombre}`;

    let response = await axios.get(encodeURI(url));
    let data = response.data;
    
    if (data.length === 0) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "No existe Registro  para esta consulta",
          callback: () => dispatch(closeMessageBox()),
        })
      );
      return;
    }

    let columnas = getState().costosproductos.table.columns;
    
    dispatch({ type: RECEIVE_V_LIST, columns: columnas, payload: data });
  } catch (error) {
    let msgError = "";
    dispatch({ type: ERROR_V_LIST });
    dispatch({ type: SET_MASK, loading: false });

    if (error.response) {
      msgError =
        error.response.data.eventLogId === 0
          ? "No existe Registro  para esta consulta "
          : `EventoId: ${error.response.data.eventLogId}. ` +
            error.response.data.message;
    } else {
      msgError = "Error al conectar con el servidor";
    }

    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message: msgError,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    dispatch({ type: SET_MASK, loading: false });
  }
};

export const onImport = () => async (dispatch, getState) => {
  

  dispatch({ type: SET_MASK, loading: true });
  
  if (getState().costosproductos.filters.distribuidorId === 0) {
    dispatch(openMessageBox({
      button: 'ok',
      icon: 'warning',
      message: "Debe seleccionar un Agente.",
      callback: () => dispatch(closeMessageBox())
    }));
    dispatch({ type: SET_MASK, loading: false });
    return;
  }


  if (getState().costosproductos.stored.data.length === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "No hay información para importar.",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    dispatch({ type: SET_MASK, loading: false });
    return;
  }

      let aux = getState().costosproductos.stored.data;
      //console.log(aux);

      
      var recordset = {
        'data': []
      };

      for (var c in aux) {

       if(Object.keys(aux[c]).length === 3) // valida el nuúmero de columan si no no deja pasar ese registor y evita que se cuelgue
       {
     
        let json = {
            
            "codigo": aux[c].Codigo.toString(),
            "descripcion": aux[c].Descripcion,          
            "costo": aux[c].Costo.toString(),

      };
      
      recordset.data.push(json);
    }
  
    }

  try {
     
  
    let url = `${getState().main.resources.uriSvcSalesMobilityAPI}/api/v1/Productos/cargacostos`;
    let response = await axios.put(url, recordset, { headers: { 'Content-Type': 'application/json' } });
     //console.log(response) 
    let data = response.data;
     console.log("aa",data)

    dispatch(openMessageBox({ 
      button: 'ok', 
      icon: 'info', 
      message: 'Registro realizado con exito.', 
      callback: () => { 
          dispatch(closeMessageBox());
          dispatch(onList())
          dispatch(push({
              pathname: `/fuerzaventas/costosproductos`,
              search: getState().router.location.search,
              hash: getState().router.location.hash
          }));
         // dispatch(getUser(data));
      }
  }));


  } catch (error) {
    dispatch({ type: SET_MASK, loading: false });
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message:
          (error.response.data.eventLogId === 0
            ? ""
            : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    //

        dispatch(push({
          pathname: `/fuerzaventas/costosproductos`,
          search: getState().router.location.search,
          hash: getState().router.location.hash
      }));

  // dispatch({ type: RECEIVE_V_LIST, column: [], payload: [] });
    return true
  }
};

export const onUpdateCosto = (id, newUnitPrice) => async (dispatch, getState) => {
  if (getState().costosproductos.store.loading) {
    return;
  }

  try {

  dispatch({ type: SET_MASK, loading: true });
  //console.log(getState().vendedor);
  //dispatch({ type: RECEIVE_OLAS_LIST, columns: getState().vendedor.table.columns, payload: [] });
  let source = axios.CancelToken.source();
  dispatch({ type: REQUEST_V_LIST, source });

          dispatch({ type: SET_MASK, loading: true });
   
            let recordset = {  productoId: id, costo : newUnitPrice, fecha: new Date()};

            let url = `${ getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Productos/crearcosto`;
            let response = await axios.post(url, recordset,  {headers: { "Content-Type": "application/json" }, });

            let data = response.data;

            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'info', 
                message: 'Registro realizado con exito.', 
                callback: () => { 
                    dispatch(closeMessageBox()); 
                    
                    dispatch(push({
                        pathname: `/fuerzaventas/costosproductos`,
                        search: getState().router.location.search,
                        hash: getState().router.location.hash
                    }));
                   
                }
            }));
        } catch (error) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
            return false
        } finally {
            dispatch({ type: SET_MASK, loading: false });
            dispatch(onList());
            return true
        }
};

export const onUpdateColumn = (column) => (dispatch, getState) => {
  getState().costosproductos.table.columns.map((item, i) => {
    if (item.dataKey !== column.dataKey) {
      return item;
    } else {
      item.width = column.width;
    }
    return {
      // ...item,
      //  width: column.width
    };
  });
};

export const onUpdateFieldFilter = (key, value) => (dispatch, getState) => {
  dispatch({ type: UPDATE_FIELD_FILTER, key, value });
  
  if (key !== 'nombre') {
        dispatch({ type: RECEIVE_V_LIST, column: [], payload: [] });
  }
};

export const openAdvancedSearch = (opts) => ({
  type: ADVANCED_SEARCH_OPEN,
  payload: opts,
});

export const setOrder = (order, orderBy) => ({
  type: SET_ORDER,
  order,
  orderBy,
});

export const closeAdvancedSearch = () => ({ type: ADVANCED_SEARCH_CLOSE });
