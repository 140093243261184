import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    onUpdateColumn,
    setOrder
} from '../../../store/fuerzaventas/gestionroles/actions';
import { sort_by} from '../../../helpers/commons'


import CardsHeader from './CardsHeader';
import Cards from './Cards';
import CardsG from './CardsG';
import CardsE from './CardsE';
import Graphics from './Graphics';
import CardsAcumulado from './CardsAcumulado';


import { FieldSet, Table } from '@sigeco/tools';
import Grid from '@material-ui/core/Grid';
import {Icon, makeStyles } from "@material-ui/core";
import CardsP from './CardsP';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
     
        marginBottom: '0.75rem',
        '@media (min-width: 1401px)': {
            marginBottom: '1rem',
        }
    }, 
    tableWrap: {
        flex: '1 1 auto'
    },
    grid: {
        boxshadow: '5px 5px 15px gray'
    }

}));

const GestionRolesList = ({ onMapOpen }) => {
    const classes = useStyles();
    const columns = useSelector(state => state.gestionroles.table.columns);
    const loading = useSelector(state => state.gestionroles.loading);
    const order = useSelector(state => state.gestionroles.table.order);
    const orderBy = useSelector(state => state.gestionroles.table.orderBy);
    const store = useSelector(state => state.gestionroles.store);
    const ChartAcumulado = useSelector(state => state.gestionroles.store.chartAcumulado)
    const dispatch = useDispatch();


    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        dispatch(setOrder(isAsc ? 'desc' : 'asc', property));
        let homes = store.data;
        homes.sort(sort_by(property, isAsc, homes[0][property]));
    };

    const handleResizeColumn = (column) => {
        dispatch(onUpdateColumn(column))
    };
    
    return ( 
        <>
        <div className={classes.root}>
        <FieldSet className={classes.root} iconCls="fas fa-th-list" title="Resultados"   >
            <div className={classes.tableWrap} >
                <Table                      
                    columns={columns}
                    count={store.total}
                    loading={loading}
                    //onRowClick={items => dispatch(setSelectedRow(items))}
                    //onRowDoubleClick={() => {}}
                    onSort={datakey => handleRequestSort(datakey)}
                    onUpdateColumn={handleResizeColumn}
                    rowCount={store.data.length}
                    rowGetter={index => store.data[index]}
                    selected={store.selected}
                    sortBy={orderBy}
                    sortDirection={order}
                />
            </div>
        </FieldSet>        
       </div>

        <div className={classes.root}>
        <Grid container spacing={2}>

             <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <CardsHeader icono={<Icon className="fas fa-umbrella" />} titulo="CANAL" texto="B2C" color="rgba(248,80,50,1)" font="white"/>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <CardsHeader icono={<Icon className="fas fa-cash-register" />} titulo="PAÍS" texto="Colombia" color="rgba(248,80,50,1)" font="white"/>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <CardsHeader icono={<Icon className="fas fa-umbrella" />} titulo="Objetivo" texto="40%" color="rgba(248,80,50,1)" font="white"/>
                
            </Grid>

            <Grid container spacing={1} className={classes.container} xs={12} sm={12} md={6} lg={12} xl={1}>
                <Grid item xs={12} sm={6} md={6} lg={2} xl={6}>
                    <Cards titulo="COBERTURA" texto="0"/>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4} xl={6}>
                    {/* <Cards titulo="PARTICIPACION" texto="00"/>  */}
                    <CardsAcumulado texto="Acumulado Nacional" data = {ChartAcumulado} /> 
                </Grid>

                {/* <Grid item xs={12} sm={6} md={6} lg={2} xl={6}>
                    <Cards titulo="CLASIFICACION    " texto="000"/>
                </Grid> */}
                <Grid item xs={12} sm={12} md={5} lg={6} xl={5} style= {{backgroundColor : 'floralwhite'}}  className={classes.grid}>
                         <CardsG  texto="Efectividad" /> 
                </Grid>
                
                {/* ----------------------------------------------------------------------------------------------------------- */}
                <Grid item xs={12} sm={12} md={5} lg={6} xl={5} style= {{backgroundColor : 'floralwhite'}}>
                         <CardsE texto="Galones" /> 
                        {/* <GraphicsLine/> */}
                </Grid>

                <Grid item xs={12} sm={12} md={5} lg={6} xl={5} style= {{backgroundColor : 'floralwhite'}}>
                         <CardsP texto="Participación Mercado" /> 
                </Grid>
               {/* ----------------------------------------------------------------------------------------------------------- */}

               <Grid item xs={12} sm={12} md={5} lg={6} xl={5} style= {{backgroundColor : 'floralwhite'}}>
                         <CardsE texto="Galones" />  
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={2} xl={6}>
                    <Cards titulo="COBERTURA" texto="0"/>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={2} xl={6}>
                    <Cards titulo="PARTICIPACION" texto="01"/>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={2} xl={6}>
                    <Cards titulo="CLASIFICACION    " texto="001"/>
                </Grid>

            </Grid> 

            <Grid container spacing={1} className={classes.container} xs={12} sm={12} md={6} lg={6} xl={6}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Cards titulo="COBERTURA" texto="692"/>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Cards titulo="PARTICIPACION" texto="111,092"/>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Cards titulo="CLASIFICACION    " texto="2,504 horas"/>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Cards titulo="PORCENTAJE IMPRESIONES" texto="14.2%"/>
                </Grid>
            </Grid> 
                <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>

                <Grid item xs={12} sm={12} md={5} lg={5} xl={5} className={classes.containerGrafica} style= {{backgroundColor : 'red'}}>
                         <Graphics /> 
                </Grid>
        </Grid>
        </div>
       </> 
    )
};

export default GestionRolesList;