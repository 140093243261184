import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    onUpdateColumn,
    setOrder,
    setSelectedRow
} from '../../../store/channelmap/avanceventasgalones/actions';

//from '../../store/movilidad/reporteavanceventasgalones/actions';

import { FieldSet, Table } from '@sigeco/tools';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column'
    }, 
    tableWrap: {
        flex: '1 1 auto'
    }
}));

const AvanceVentasGalonesList = () => {
    const classes = useStyles();
    const avance = useSelector(state => state.avanceventagalones);
    const columns = useSelector(state => state.avanceventagalones.table.columns);
    const loading = useSelector(state => state.avanceventagalones.loading);
    const order = useSelector(state => state.avanceventagalones.table.order);
    const orderBy = useSelector(state => state.avanceventagalones.table.orderBy);
    const store = useSelector(state => state.avanceventagalones.store);
    const dispatch = useDispatch();
    //debbuger(store);
    
    const handleDoubleClick = (id) => {
        return;
        /*
        history.push({
            hash: location.hash,
            pathname: `/fuerzaventas/metas/${id}`,
            search: location.search
        });
        */
    }

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        dispatch(setOrder(isAsc ? 'desc' : 'asc', property));
    };

    const handleResizeColumn = (column) => {
        dispatch(onUpdateColumn(column))
 };
    
    return (
        <FieldSet className={classes.root} iconCls="fas fa-th-list" title="Resultados">
        <div className={classes.tableWrap}>
            <Table
                columns={columns}
                count={store.total}
                loading={loading}
                onRowClick={items => dispatch(setSelectedRow(items))}
                onRowDoubleClick={item => handleDoubleClick(item.usuarioId)}
                onSort={datakey => handleRequestSort(datakey)}
                onUpdateColumn={handleResizeColumn}
                rowCount={store.data.length}
                rowGetter={index => store.data[index]}
                selected={store.selected}
                sortBy={orderBy}
                sortDirection={order}
            />
        </div>
    </FieldSet>
    )
};

export default AvanceVentasGalonesList;