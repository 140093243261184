import {
    ERROR_R_LIST,
    RECEIVE_R_LIST,
    REQUEST_R_LIST,
    RESTART_STATE,
    SET_MASK,
    SET_ORDER,
    SET_SELECTED_ROW,
    UPDATE_FIELD_FILTER,
    RECEIVE_R_DET,
    UPDATE_FIELD_DATA
    //UPDATE_TABLE_COLUMN
} from './types';

const initialState = {
    loading: false,
    filters: {
         dVendedor: '',
         desde: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
         hasta: new Date(new Date().getFullYear(), new Date().getMonth()-1, new Date().getDate()),
         vendedorId : 0,
         estado: false
    },
    store: {
        loading: false,
        data: [],
        selected: [],
        detalle: [],
       
        presupuesto: [],
         estsuper: [],
        estmonogrado: [],
        clientesnuevos: [],
        clinuevseisocho: [],
        clinuevnueve: [],
        ref: null,
        show: false,
      
       meses: [
        "Ene","Feb","Mar","Abr","May","Jun","Jul","Ago","Sep","Oct","Nov","Dic"
       ]
    },
    table: {
        order: 'asc',
        orderBy: '',
        columns: [

        ]
    }
};

export const resumenReducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case ERROR_R_LIST:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: false
                }
            };
            case RECEIVE_R_LIST:
                return {
                    ...state,
                    store: {
                        ...state.store,
                        loading: false,
                        data: action.payload,
                        selected: []
                    },
                    table: {
                        ...state.table,
                        columns: action.columns,
                    }
                };
            case REQUEST_R_LIST:
                return {
                    ...state,
                    store: {
                        ...state.store,
                        loading: true
                    }
                };
            case RECEIVE_R_DET:
                return {
                    ...state,
                    store: {
                        ...state.store,
                        loading: false,
                        detalle: action.payload
                    }
                };    
        case RESTART_STATE:
            return {
                ...initialState
            };
        case SET_MASK:
            return {
                ...state,
                loading: action.loading
        };
        case SET_ORDER:
            return {
                ...state,
                table: {
                    ...state.table,
                    order: action.order,
                    orderBy: action.orderBy
                }
            };
        case SET_SELECTED_ROW:
            return {
                ...state,
                store: {
                    ...state.store,
                    selected: action.selected
                }
            };
        case UPDATE_FIELD_FILTER:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.key]: action.value
                }
            };
        case UPDATE_FIELD_DATA:
                return { 
                    ...state,
                    store: {
                        ...state.store,
                        [action.key]: action.value
                    }
                };
      
        default:
            return state;
    }
}