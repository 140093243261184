import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    onDropRutaCliente,
    onSearchDiaRutaClienteClick,
    onSearchSucursalClick,
    onUpdateRutaCliente,
    onUpdateFieldEntity
} from '../../../store/fuerzaventas/rutas/actions';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Chip from '@material-ui/core/Chip';
import Fab from '@material-ui/core/Fab';
import { FieldSet } from '@sigeco/tools';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(theme => ({
    chip: {
        margin: '0.375rem 0.375rem 0 0'
    },
    root: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        paddingTop: '20px',
        minHeight: '30rem',
       //overflowY: 'auto',
        '@media (min-width: 1401px)': {
            marginTop: '1rem',
        }
    }, 
    heading: {
        fontSize: theme.typography.pxToRem(12),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(12),
        color: theme.palette.text.secondary,
    },
    tertiaryHeading: {
        fontSize: theme.typography.pxToRem(12),
        flexBasis: '10%',
        flexShrink: 0,
    },
    label: {
        alignItems: 'center',
        display: 'flex',
        fontSize: '0.8125rem'
    },
    tableWrap: {
        flex: '1 1 auto',
        minHeight: '10rem',
        overflowY: 'auto',
        padding: '0.5rem 0.75rem 0.75rem',
        
    }
}));

const RutasCliente = () => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const dias = useSelector(state => state.rutas.dias);
    const entity = useSelector(state => state.rutas.entity);
    const formState = useSelector(state => state.rutas.formState);
    const dispatch = useDispatch();

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    function onCheckBoxChange(event) {  
        dispatch(onUpdateFieldEntity(event.target.name, event.target.checked));
    }

    const handleDelete = (clienteSucursalId, key) => dispatch(onUpdateRutaCliente(clienteSucursalId, key, false));

    return (
        <FieldSet className={classes.root} iconCls="fas fa-user-tag" title="Asignación de clientes">
            <div className={classes.tableWrap}  >
                {(formState === 'edit' || formState === 'new') && (<div style={{ marginBottom: '1rem' }}>
 
                    <Grid item md={8} container style= {{marginLeft:180, marginTop:-40, position:'fixed', zIndex:1}}> 
                            <Fab aria-label="agregar_clientes" color="primary" onClick={() => dispatch(onSearchSucursalClick())} size="small">
                            <Icon className="fas fa-plus" style={{ fontSize: '0.875rem'}}/>
                             </Fab>

                       <div>&nbsp;&nbsp; </div><div className={classes.label}>Mostrar Todas las sucursales:</div>
                                <Checkbox
                                    name="todos"
                                    className={classes.checkbox} 
                                    checked={entity.todos} 
                                    color="primary" 
                                    disableRipple
                                    onChange={onCheckBoxChange}
                                  
                                />
                      </Grid>

                </div>)}
     
                {
                    entity.rutasCliente.length === 0 && 
                    <AccordionDetails>
                                <div style={{ padding: '0.5rem 0.75rem 0.75rem' }}>
                                No se encontraron registros
                                </div>
                            </AccordionDetails>

                }
                { entity.rutasCliente  &&
                entity.rutasCliente.map((item, index) => {
                    return (
                        <Accordion key={index} expanded={expanded === item.clienteSucursalId} onChange={handleChange(item.clienteSucursalId)}>
                            <AccordionSummary
                                expandIcon={<Icon className="fas fa-angle-down"/>}
                            >
                                <Typography className={classes.heading}>{item.codigoCliente + ' - ' +item.dCliente}</Typography>
                                
                                <Typography className={classes.tertiaryHeading}>{item.nit}</Typography>
                                <Typography className={classes.tertiaryHeading}>{item.codigoSucursal}</Typography>
                                <Typography className={classes.secondaryHeading}>{item.dSucursal}</Typography>
                                <div style={{ flex: 1 }}></div>
                                {(formState === 'edit' || formState === 'new') && (<IconButton 
                                    className="fas fa-trash"
                                    onClick={() => dispatch(onDropRutaCliente(item.clienteSucursalId))}
                                    style={{ fontSize: '0.75rem' }}
                                />)}
                            </AccordionSummary>
                            <AccordionDetails>
                                <div style={{ padding: '0.5rem 0.75rem 0.75rem' }}>
                                    {(formState === 'edit' || formState === 'new') && (<div style={{ marginBottom: '1rem' }}>
                                        <Fab aria-label="agregar_dias" color="primary" onClick={() => dispatch(onSearchDiaRutaClienteClick(item.clienteSucursalId))} size="small">
                                            <Icon className="fas fa-plus" style={{ fontSize: '0.875rem'}}/>
                                        </Fab>
                                    </div>)}
                                    {dias.filter(f => item[f.key]).map((dia, index) => {
                                        return (
                                            <div key={index} style={{ display: 'inline-flex' }}>
                                                <Chip
                                                    className={classes.chip}
                                                    color="primary"
                                                    deleteIcon={<Icon className="fas fa-times-circle" style={{ fontSize: '1rem'}} />}
                                                    label={dia.label}
                                                    onDelete={ (formState === 'edit' || formState === 'new') ? () => handleDelete(item.clienteSucursalId, dia.key) : null}
                                                    variant="outlined"
                                                    
                                                />
                                            </div>
                                        )
                                    })}
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </div>
        </FieldSet>
    );

};

export default RutasCliente;