import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";

import { FieldSet } from '@sigeco/tools';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    columnImage: {
        flex: 1,
        fontSize: '.8125rem',
    },
    columnIndex: {
        fontSize: '.8125rem',
        width: '3.5rem'
    },
    columnText: {
        fontSize: '.8125rem',
        width: '12rem'
    },
    empty: {
        marginTop: '1rem',
        textAlign: 'center'
    },
    image: {
        backgroundColor: '#fff',
        border: 'solid 1px #d3d3d3',
        borderRadius: '50%',
        objectFit: 'contain',
        height: '3.25rem',
        width: '3.25rem'
    },
    root: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        overflowY: 'hidden'
    },
    row: {
        alignItems: 'center',
        borderBottom: 'solid 1px #d3d3d3',
        display: 'flex',
        padding: '.5rem 2rem',
        '&:hover' : {
            backgroundColor : '#f3f3f3'
        },
        '&:first-child': {
            borderTop: 'solid 1px #d3d3d3'
        },
        '&:focus': {
            border: 'solid 1px #006bb6',
            outline: 'none'
        }
    },
    tableWrap: {
        flex: '1 1 auto',
        overflowY: 'auto',
        padding: '1rem 0'
    }
}));

const MarcaList = () => {
    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();
    const store = useSelector(state => state.marca.store);

    const handleDoubleClick = (id) => {
        history.push({
            hash: location.hash,
            pathname: `/channelmap/marca/${id}`,
            search: location.search
        });
    }

    return (
        <FieldSet className={classes.root} iconCls="fas fa-th-list" title="Resultados">
            <div className={classes.tableWrap}>
                <div className={classes.row}>
                    <div style={{ fontWeight: 500 }} className={classes.columnIndex}>#</div>
                    <div style={{ fontWeight: 500 }} className={classes.columnText}>Nombre</div>
                    <div style={{ fontWeight: 500 }} className={classes.columnIndex}>Activo</div>
                    <div style={{ fontWeight: 500 }} className={classes.columnImage}>Imagen</div>
                </div>
                {store.loading && (<div className={classes.empty}>
                    <CircularProgress />
                </div>)}
                {!store.loading && store.data.map((item, index) => (
                    <div key={item.marcaId} tabIndex={-1} className={classes.row} onDoubleClick={() => handleDoubleClick(item.marcaId)}>
                        <div className={classes.columnIndex}>{index + 1}</div>
                        <div className={classes.columnText}>{item.nombre}</div>
                        <div className={classes.columnIndex}>{item.activo}</div>
                        <div className={classes.columnImage}>
                            <img className={classes.image} alt={item.nombre} src={item.imagen || 'https://storageterpel.blob.core.windows.net/salesmobility/images/imagen_no_disponible.jpg'} />
                        </div>
                    </div>
                ))}
            </div>
        </FieldSet>
    );
};

export default MarcaList;