import {
    CLEAN_FORM,
    ERROR_OLAS_LIST,
    RECEIVE_OLAS_LIST,
    REQUEST_OLAS_LIST,
    SET_ENTITY,
    SET_MASK,
    SET_ORDER,
    SET_ERROR,
    SET_SELECTED_ROW,
    UPDATE_FIELD_FILTER,
    UPDATE_TABLE_COLUMN,
    SET_STATE_FORM,
    RESTART_STATE,
    UPDATE_FIELD_ENTITY,
} from './types';

const initialState = {
    formState: 'search',
    loading: false,
    data: [],
    openPopup: false,
    entity: {
        productoId: 0,
        lobId: 1,
        marcaId: 0,
        usoAceiteId: 0,
        tipoAceiteId : 0,
        nombre: '',
        viscosidad: '',
        lineaId: 0,
        certificado: '',
        dCanal: "",
        dMarca: '',
        dTipoAceite: "",
        dUsoAceite: "",
        dLinea: '',
        activo: true,
    },
    errors: {
        nombre: '', 
        viscosidad: '',
        //lobId: '',
        marcaId: '',
        tipoAceiteId: '',
        usoAceiteId: '',
        certificado: '',
        lineaId: '',
        canalId: '',
        sw: '',
        dCanal: ''
        //categoriaId : '',    
    },
    filters: {
         nombre: '',
         dCanal: "",
         canalId:0,
         dLinea: "",
         lineaId:0,
         dMarca: "",
         marcaId:0,

    },
    store: {
        data: [],
        selected: [],
        loading: false
    },
    table: {
        order: 'asc',
        orderBy: '',
        columns: []
    }
};

export const productosReducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case CLEAN_FORM:
            return {
                ...state,
                entity: initialState.entity,
                errors: initialState.errors
            };
        case ERROR_OLAS_LIST:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: false
                }
            };
        case RECEIVE_OLAS_LIST:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: false,
                    data: action.payload,
                    selected: []
                },
                table: {
                    ...state.table,
                    columns: action.columns,
                }
            };
        case REQUEST_OLAS_LIST:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: true
                }
            };
        case SET_MASK:
            return {
                ...state,
                loading: action.loading
        };
        case SET_ORDER:
            return {
                ...state,
                table: {
                    ...state.table,
                    order: action.order,
                    orderBy: action.orderBy
                }
            };
            case SET_ENTITY:
                return {
                    ...state,
                    entity: {
                        ...state.entity,
                        productoId: action.payload.productoId,
                        canalId: action.payload.canalId,
                        lineaId:    action.payload.lineaId,
                        lobId: 1,
                        marcaId: action.payload.marcaId,
                        usoAceiteId: action.payload.usoAceiteId ,
                        tipoAceiteId : action.payload.tipoAceiteId ,
                        nombre: action.payload.nombre ,
                        viscosidad: action.payload.viscosidad ,              
                        certificado: action.payload.certificado || '',
                        dCanal:  action.payload.dCanal,
                        dLinea: action.payload.dLinea ,
                        dMarca: action.payload.dMarca ,
                        dTipoAceite: action.payload.dTipoAceite ,
                        dUsoAceite: action.payload.dUsoAceite ,
                        //dCategoria: action.payload.dTipoAceite ,
                        //dLinea: action.payload.
                        activo: action.payload.activo,
                    }
                };
        case SET_ERROR:
                    return {
                        ...state,
                        errors: {
                            ...state.errors,
                            [action.key]: action.error
                        }
                    }
        case SET_SELECTED_ROW:
            return {
                ...state,
                store: {
                    ...state.store,
                    selected: action.selected
                }
            };
        case UPDATE_FIELD_FILTER:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.key]: action.value
                }
            };
        case UPDATE_TABLE_COLUMN:
            return {
                ...state,
                table: {
                    ...state.table,
                    columns: action.columns
                }
            };
            case SET_STATE_FORM:
                return {
                    ...state,
                    formState: action.formState
                };
            case RESTART_STATE:
                    return {
                        ...initialState
                    };
            case UPDATE_FIELD_ENTITY:
                        return { 
                            ...state,
                            entity: {
                                ...state.entity,
                                [action.key]: action.value
                            }
                        };
        default:
            return state;
    }
}