import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { onUpdateFieldFilter } from "../../../store/channelmap/marca/actions";

import { FieldSet } from "@sigeco/tools";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FormControl from "@material-ui/core/FormControl";
import { Icon } from "@material-ui/core";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(() => ({
  content: {
    padding: "0.5rem 0.75rem 0.75rem",
  },
  filterClear: {
    display: "flex",
  },
  actionField: {
    flexDirection: "row",
    position: "relative",
  },
  btnClear: {
    position: "absolute",
    bottom: "1.5",
    right:20,
    maxWidth: "15px",
    maxHeight: "23px",
    minWidth: "15px",
    minHeight: "23px",
  },
  root: {
    flex: "0 0 auto",
    marginBottom: "0.75rem",
    "@media (min-width: 1401px)": {
      marginBottom: "1rem",
    },
  },
}));

const MarcaFilter = () => {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:701px)");
  const filters = useSelector((state) => state.marca.filters);
  const dispatch = useDispatch();

  //Botones de limpiar filtros
  const [, setName] = useState("");
  const [btn1, setBtn1] = useState(true);

  useEffect(() => {
    if (filters.nombre) {
      setName(filters.nombre);
      setBtn1(false);
    } else {
      setBtn1(true);
    }
  }, [filters.nombre]);

  const handleName = () => {
    setName("");
    filters.nombre = "";
    setBtn1(true);
  };

  function onFieldChange(event) {
    dispatch(onUpdateFieldFilter(event.target.name, event.target.value));
  }

  return (
    <div className={classes.root}>
      <FieldSet iconCls="fas fa-filter" title="Filtros" defaultExpanded>
        <Grid className={classes.content} container spacing={2}>
          <Grid item xs>
            <FormControl className={classes.filterClear}>
              <TextField
                className={classes.actionField}
                autoComplete="off"
                fullWidth
                label="Nombre"
                name="nombre"
                value={ filters.nombre}
                variant={matches ? "outlined" : "standard"}
                onChange={onFieldChange}
              />
              <Button
                style={{right:0}}
                className={classes.btnClear}
                size="small"
                startIcon={<Icon className="fa fa-window-close" />}
                disabled={btn1}
                onClick={handleName}
              />
            </FormControl>
          </Grid>
        </Grid>
      </FieldSet>
    </div>
  );
};

export default MarcaFilter;
