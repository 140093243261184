import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    onUpdateColumn,
    setOrder
} from '../../../store/marketing/reportepromociones/actions';
import { sort_by} from '../../../helpers/commons'

import { FieldSet, Table } from '@sigeco/tools';
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
     
        marginBottom: '0.75rem',
        '@media (min-width: 1401px)': {
            marginBottom: '1rem',
        }
    }, 
    tableWrap: {
        flex: '1 1 auto'
    }
}));

const ReportePromocionesList = ({ onMapOpen }) => {
    const classes = useStyles();
    const columns = useSelector(state => state.reportepromociones.table.columns);
    const loading = useSelector(state => state.reportepromociones.loading);
    const order = useSelector(state => state.reportepromociones.table.order);
    const orderBy = useSelector(state => state.reportepromociones.table.orderBy);
    const store = useSelector(state => state.reportepromociones.store);
    const dispatch = useDispatch();


    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        dispatch(setOrder(isAsc ? 'desc' : 'asc', property));
        let homes = store.data;
        homes.sort(sort_by(property, isAsc, homes[0][property]));
    };

    const handleResizeColumn = (column) => {
        dispatch(onUpdateColumn(column))
    };
    
    return ( 
        
        <div className={classes.root}>
        <FieldSet className={classes.root} iconCls="fas fa-th-list" title="Resultados"   >
            <div className={classes.tableWrap} >
                <Table                      
                    columns={columns}
                    count={store.total}
                    loading={loading}
                    onRowClick={() =>  {}}
                    onRowDoubleClick={() => {}}
                    onSort={datakey => handleRequestSort(datakey)}
                    onUpdateColumn={handleResizeColumn}
                    rowCount={store.data.length}
                    rowGetter={index => store.data[index]}
                    selected={store.selected}
                    sortBy={orderBy}
                    sortDirection={order}
                />
            </div>
        </FieldSet>        
       </div>
       
        
    )
};

export default ReportePromocionesList;