import React, { useState } from 'react';
import { useSelector } from 'react-redux';
// import _ from 'lodash';

import Icon from '@material-ui/core/Icon';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ReporteSeguimientoVendedorLob from './ReporteSeguimientoVendedorLob';
import ReporteSeguimientoVendedorPDV from './ReporteSeguimientoVendedorPDV';
import ReporteSeguimientoVendedorTotal from './ReporteSeguimientoVendedorTotal';
import ReporteSeguimientoVendedorResumen from './ReporteSeguimientoVendedorResumen';

const useStyles = makeStyles(theme => ({
    paper: {
        flex: 1
    },
    tabsHorizontal: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    tabsVertical: {
        borderRight: `1px solid ${theme.palette.divider}`,
    }
}));

const ReporteSeguimientoVendedorPanel = () => {
    const classes = useStyles();
    const big = useMediaQuery('(min-width:1201px)');
    const data = useSelector(state => state.reporteseguimientovendedor.store);
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Paper className={classes.paper}>
            <div style={{ display: 'flex', flexDirection: big ? "row" : "column", height: '100%' }}>
                <Tabs
                    orientation={big ? "vertical" : "horizontal"}
                    variant="fullWidth"
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="icon label tabs example"
                    className={big ? classes.tabsVertical : classes.tabsHorizontal}
                >
                    <Tab icon={<Icon className="fas fa-graduation-cap" />} label="General" style={{ textTransform: "none",  backgroundColor: value === 0 ? "#C9C9C9" : "" }} />
                    <Tab icon={<Icon className="fas fa-barcode" />} label="Resúmen" style={{ textTransform: "none",  backgroundColor: value === 1 ? "#C9C9C9" : "" }}/>
                    <Tab icon={<Icon className="fas fa-user-graduate" />} label="Vendedores" style={{ textTransform: "none",  backgroundColor: value === 2 ? "#C9C9C9" : "" }}/>
                    <Tab icon={<Icon className="fas fa-satellite" />} label="Detalle" style={{ textTransform: "none",  backgroundColor: value === 3 ? "#C9C9C9" : "" }}/>
                </Tabs>
                {(value === 0 && data.data.length > 0) && (<ReporteSeguimientoVendedorTotal />)}
                {(value === 1 && data.data.length > 0) && (<ReporteSeguimientoVendedorResumen />)}
                {(value === 2 && data.data.length > 0) && (<ReporteSeguimientoVendedorLob />)}
                {(value === 3 && data.data.length > 0) && (<ReporteSeguimientoVendedorPDV />)}
            </div>
        </Paper>
    );
}

export default ReporteSeguimientoVendedorPanel;