import axios from 'axios';
import { push } from 'connected-react-router';
import { closeMessageBox, openMessageBox } from '../../main/actions';
import {
    CHANGE_IMAGE,
    CLEAN_FORM,
    ERROR_MARCAS_LIST,
    RECEIVE_MARCAS_LIST,
    REMOVE_IMAGE,
    REQUEST_MARCAS_LIST,
    RESTART_STATE,
    SET_ENTITY,
    SET_ERROR,
    SET_FORM,
    SET_MASK,
    SET_STATE_FORM,
    UPDATE_FIELD_ENTITY,
    UPDATE_FIELD_FILTER
} from './types';

export const btnDelete = () => (dispatch) => {
    dispatch(openMessageBox({
        button: 'yesno',
        icon: 'question',
        message: 'Se elimanara el registro permanentemente. ¿Desea continuar?',
        callback: btn => dispatch(onDelete(btn))
    }));
};

export const btnExport = () => async (dispatch, getState) => {
    try {
        if(getState().marca.store.data.length === 0) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'warning', 
                message: 'No hay información para exportar.', 
                callback: () => dispatch(closeMessageBox())
            }));
            return;
        }

        dispatch({ type: SET_MASK, loading: true });

        let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/marcas/exportar?`
            + `nombre=${getState().marca.filters.nombre}`;
        let response = await axios.get(encodeURI(url), { responseType: 'arraybuffer' });
        let csvHref = window.URL.createObjectURL(new Blob([response.data]));
        let link = document.createElement('a');

        link.href = csvHref;
        link.setAttribute('download', 'marcas.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'error', 
            message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
            callback: () => dispatch(closeMessageBox())
        }));
    } finally {
        dispatch({ type: SET_MASK, loading: false });
    }
};

export const btnSave = () => (dispatch, getState) => {
    if(getState().marca.entity.nombre === '') {
        dispatch({ type: SET_ERROR, key: 'nombre', error: 'Este campo es requerido' });
    }

    if(getState().marca.errors.nombre !== '') {
        return;
    }

    dispatch(openMessageBox({
        button: 'yesno',
        icon: 'question',
        message: '¿Desea guardar la información?',
        callback: btn => {
            if(getState().marca.entity.marcaId === 0) {
                dispatch(onSave(btn));
            }
            else {
                dispatch(onUpdate(btn));
            }

        }
    }));
};

export const btnSearch = () => async (dispatch, getState) => {
    if(getState().marca.store.source) {
        getState().marca.store.source.cancel();
    }

    let source = axios.CancelToken.source();

    dispatch({ type: REQUEST_MARCAS_LIST, source });

    try {
        let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/marcas?`
            + `nombre=${getState().marca.filters.nombre}`;
        let response = await axios.get(encodeURI(url), { cancelToken: source.token });
        let data = response.data;

        if (data.length === 0) {
            dispatch(openMessageBox({
                button: 'ok',
                icon: 'warning',
                message: "No existe Registro  para esta consulta",
                callback: () => dispatch(closeMessageBox())
            }));
        return;
    }
        dispatch({ type: RECEIVE_MARCAS_LIST, payload: data });
        
    } catch (error) {
        dispatch({ type: ERROR_MARCAS_LIST });
        if (!axios.isCancel(error)) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        }
    }
}

export const cleanForm = () => ({ type: CLEAN_FORM });

export const getMarca = (marcaId) => async (dispatch, getState) => {
    try {
        dispatch({ type: SET_MASK, loading: true });

        let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/marcas/${marcaId}`;
        let response = await axios.get(encodeURI(url));
        let payload = {
            ...response.data
        };
        
        dispatch({ type: SET_ENTITY, payload });
        dispatch({ type: SET_FORM });
    } catch (error) {
        if(error.response.status === 404) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: 'No se encontro el registro solicitado.', 
                callback: () => { 
                    dispatch(closeMessageBox()); 
                    dispatch(push({
                        pathname: `/channelmap/marca`,
                        search: getState().router.location.search,
                        hash: getState().router.location.hash
                    }));
                }
            }));
        }
        else {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        }
    } finally {
        dispatch({ type: SET_MASK, loading: false });
    }
};

export const onDelete = (btn) => async (dispatch, getState) => {
    dispatch(closeMessageBox());

    if(btn === 'yes') {
        try {
            dispatch({ type: SET_MASK, loading: true });

            let marcaId = getState().marca.entity.marcaId;
            let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/marcas/${marcaId}`;
            await axios.delete(encodeURI(url));

            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'info', 
                message: 'El registro se elimino correctamente.', 
                callback: () => { 
                    dispatch(closeMessageBox());
                    dispatch(push({
                        pathname: '/channelmap/marca',
                        search: getState().router.location.search,
                        hash: getState().router.location.hash
                    }));
                    dispatch(btnSearch());
                }
            }));
        } catch (error) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        } finally {
            dispatch({ type: SET_MASK, loading: false });
        }
    }
};

export const onSave = (btn) => async (dispatch, getState) => {
    dispatch(closeMessageBox());

    if(btn === 'yes') {
        try {
            dispatch({ type: SET_MASK, loading: true });

            let formData = new FormData();

            formData.append("imagen", getState().marca.form.archivo);
            formData.append("nombre", getState().marca.entity.nombre);
            formData.append("activo", getState().marca.entity.activo);

            let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/marcas`;
            let response = await axios.post(url, formData, {headers: {'Content-Type': 'multipart/form-data'}});
            let data = response.data;

            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'info', 
                message: 'Registro realizado con exito.', 
                callback: () => { 
                    dispatch(closeMessageBox()); 
                    dispatch(push({
                        pathname: `/channelmap/marca/${data}`,
                        search: getState().router.location.search,
                        hash: getState().router.location.hash
                    }));
                }
            }));
        } catch (error) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        } finally {
            dispatch({ type: SET_MASK, loading: false });
        }
    }
};

export const onUpdate = (btn) => async (dispatch, getState) => {
    dispatch(closeMessageBox());

    if(btn === 'yes') {
        try {
            dispatch({ type: SET_MASK, loading: true });

            let formData = new FormData();

            formData.append("imagen", getState().marca.form.archivo);
            formData.append("marcaId", getState().marca.entity.marcaId);
            formData.append("nombre", getState().marca.entity.nombre);
            formData.append("activo", getState().marca.entity.activo);

            if(getState().marca.entity.imagen !== getState().marca.form.imagen && getState().marca.form.archivo === ''){
                formData.append("quitarImagen", true);
            }

            let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/marcas`;
            let response = await axios.put(url, formData, {headers: {'Content-Type': 'multipart/form-data'}});
            let data = response.data;

            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'info', 
                message: 'Modificación realizado con exito.', 
                callback: () => { 
                    dispatch(closeMessageBox());
                    dispatch(push({
                        pathname: `/channelmap/marca/${data}`,
                        search: getState().router.location.search,
                        hash: getState().router.location.hash
                    }));
                }
            }));
        } catch (error) {
            if(error.response.status === 404) {
                dispatch(openMessageBox({ 
                    button: 'ok', 
                    icon: 'error', 
                    message: 'No se encontro el registro solicitado.', 
                    callback: () => { 
                        dispatch(closeMessageBox()); 
                        dispatch(push({
                            pathname: `/channelmap/marca`,
                            search: getState().router.location.search,
                            hash: getState().router.location.hash
                        }));
                    }
                }));
            }
            else {
                dispatch(openMessageBox({ 
                    button: 'ok', 
                    icon: 'error', 
                    message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                    callback: () => dispatch(closeMessageBox())
                }));
            }
        } finally {
            dispatch({ type: SET_MASK, loading: false });
        }
    }
};

export const onUpdateFieldEntity = (key, value) => (dispatch) => {
    dispatch({ type: UPDATE_FIELD_ENTITY, key, value });

    if(key === 'nombre') {
        dispatch({ type: SET_ERROR, key, error: '' });
    }
};

export const onUpdateFieldFilter = (key, value) => ({ type: UPDATE_FIELD_FILTER, key, value });

export const removeImage = () => ({ type: REMOVE_IMAGE });

export const restartState = () => ({ type: RESTART_STATE });

export const setFormState = (formState, id) => (dispatch, getState) => {
    switch (formState) {
        case 'edit':
            if(id && parseInt(id) !== getState().marca.entity.marcaId) {
                dispatch(getMarca(id));
            }
            break;
        case 'new':
            dispatch(cleanForm());
            break;
        case 'search':
            dispatch(cleanForm());
            break;
        case 'view':
            if(id && id !== getState().marca.entity.marcaId) {
                dispatch(getMarca(id));
            }
            break;
        default:
            break;
    }
    dispatch({ type: SET_STATE_FORM, formState });
};

export const setImage = (file, image) => ({ type: CHANGE_IMAGE, file, image });