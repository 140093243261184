import axios from 'axios';
import { IdentityUrl } from '../../../config';
import { closeAdvancedSearch, closeMessageBox, openAdvancedSearch, openMessageBox } from '../../main/actions';
import {
    RECEIVE_DATA,
    RECEIVE_ANT,
    RESTART_STATE,
    SET_MASK,
    UPDATE_FIELD_FILTER,
    UPDATE_OLA_FIELD_FILTER,
    SET_ORDER,
    SET_SELECTED_ROW,
    UPDATE_FIELD_DATA,
    ACTUALIZA_TIPO_CLIENTE,  
} from './types';

import { exportToSpreadsheet,searchVendedor } from '../../../helpers/commons'

export const onExport = () => async (dispatch, getState) => {
   
    if(getState().reporteseguimientovendedor.store.data.length === 0) {
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'warning', 
            message: 'No hay información para exportar.', 
            callback: () => dispatch(closeMessageBox())
        }));
        return;
    }
try {
    dispatch({ type: SET_MASK, loading: true });

  var aux = [];
  //ESTE CODIGO TE PERMITE MOVER LAS CABECERAS SEGUN LO REQUIERES
//   for (var c in head)
//     {
//         aux[0] = 'ASESOR'; //vendedor
//         aux[1] = 'Object.values(head[1])[1]'; //nit
//         aux[2] = Object.values(head[2])[1];   //nombre comercial
//         aux[3] = Object.values(head[3])[1];   //dire
//         aux[5] = Object.values(head[5])[1];       //estado
//         aux[4] = Object.values(head[6])[1];       //observac
//         aux[6] = Object.values(head[7])[1];   //latitud      
//         aux[7] = Object.values(head[8])[1];       //longitud
//         aux[8] = Object.values(head[4])[1];   //fecha
//       }

//ESTE FOR TE PERMITE ELIMIAR LA 6 POSICION MIENTRAS REALIZA EL LLENADO EN EL GRILL
// for(var i in json) {
//     // eslint-disable-next-line no-new-object
//           let x =   Object.values(json[i]);
//         // x.splice(6, 1);
//         carArray.push(x);
//   }
        aux[0] = 'ASESOR'; //vendedor
        aux[1] = 'NIT'; //nit
        aux[2] = 'NOMBRE COMERCIAL';   //nombre comercial
        aux[3] = 'DIRECCION';   //dire
        aux[4] = 'DEPARTAMENTO';       //estado
        aux[5] = 'ESTADO';  //observac  , 
       // aux[9] = 'TIPO';  //observac  , 
        aux[6] = 'FECHA DE TOMA'; //fecha     
        aux[7] = 'LATITUD';   //latitud      
        aux[8] = 'LONGITUD';       //longitud
        aux[9] = 'OBSERVACION';  //observac  , 
        aux[10] = 'TIPO';  //observac  , 

var carArray =  []; 
carArray.push(aux);
//console.log("con head: "+ carArray)
let json=   getState().reporteseguimientovendedor.store.data;


for(let i in json) {
          let x =   Object.values(json[i]);
        carArray.push(x);
      
}


 exportToSpreadsheet(carArray,  `RptChannelSeguimientoVendedor_${getState().reporteseguimientovendedor.filters.dDistribuidor}`);

} catch (error) {
    dispatch(openMessageBox({ 
        button: 'ok', 
        icon: 'error', 
        message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
        callback: () => dispatch(closeMessageBox())
    }));
} finally {
    dispatch({ type: SET_MASK, loading: false });
}
};

export const onExportResumen = (data) => async (dispatch, getState) => {


    if(getState().reporteseguimientovendedor.store.data.length === 0) {
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'warning', 
            message: 'No hay información para exportar.', 
            callback: () => dispatch(closeMessageBox())
        }));
        return;
    }
    try {
        dispatch({ type: SET_MASK, loading: true });

    let head = getState().reporteseguimientovendedor.table.columns;
    var aux = [];

    for (var c in head)
        {
            aux[c] = 'ASESOR'; //vendedor
            aux[1] = 'PUNTOS EN CARTERA ANT'; //nit
            aux[2] = 'EJECUTADOS A';   //nombre comercial
            aux[3] = 'PENDIENTES A';   //dire
            aux[4] = 'EFECTIVIDAD A';       //observac
            aux[5] = 'PUNTOS EN CARTERA';       //estado
            aux[6] = 'EJECUTADOS';   //latitud      
            aux[7] = 'PENDIENTES';       //longitud
            aux[8] = 'EFECTIVIDAD';   //fecha
        }

    var carArray =  []; 
    carArray.push(aux);

    let json=   data;
    for(let i in json) {
        // eslint-disable-next-line no-new-object
            let x =   Object.values(json[i]);
            x.splice(6, 1);
            carArray.push(x);   
    }

    } catch (error) {
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'error', 
            message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
            callback: () => dispatch(closeMessageBox())
        }));
    } finally {
        dispatch({ type: SET_MASK, loading: false });
    }
};

export const btnSearch = () => async (dispatch, getState) => {
    try {
 dispatch({ type: SET_MASK, loading: true });

        if(getState().reporteseguimientovendedor.filters.olaId === 0){
            dispatch(openMessageBox({
                button: 'ok',
                icon: 'warning',
                message: "Debe seleccionar una Ola..",
                callback: () => dispatch(closeMessageBox())
            }));
            return;
        }

        if (getState().reporteseguimientovendedor.filters.distribuidorId === 0){
            dispatch(openMessageBox({
                button: 'ok',
                icon: 'warning',
                message: "Debe seleccionar un Agente..",
                callback: () => dispatch(closeMessageBox())
            }));
            return;
        }

        let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/reportes/channelmap/reporteseguimientovendedor?`
            + `olaChannelMapId=${getState().reporteseguimientovendedor.filters.olaId}`
            + `&distribuidorId=${getState().reporteseguimientovendedor.filters.distribuidorId}`
            + `&vendedorId=${getState().reporteseguimientovendedor.filters.vendedorId}`
            + `&tipo=${getState().reporteseguimientovendedor.filters.tipo}`
        let response = await axios.get(encodeURI(url));
        let data = response.data.detalle;
        let data2 = response.data.resumen;
       // console.log(data)
       // console.log(data2)
        // if (getState().reporteseguimientovendedor.filters.olaChannelMapId > 1){   
        //     //valida correlativo id de las olas para no saltarse
        //     let au = 0;

        //     for (var i = 0; i < aOlas.length; i++) {
     
        //         if (aOlas[i].olaId === getState().reporteseguimientovendedor.filters.olaChannelMapId){                          
        //             au = parseInt(aOlas[i-1].olaId);
        //             dispatch(onUpdateFielddata('hola', au));
        //             dispatch(onUpdateFielddata('dhola', aOlas[i-1].dOla));
        //         };
        //     }

        // } else {
        //     dispatch(onUpdateFielddata('hola', 1));
        //     dispatch(onUpdateFielddata('dhola', 'sin hola'));
        // }

            if (data.length === 0) {
                dispatch(openMessageBox({
                    button: 'ok',
                    icon: 'warning',
                    message: "No existe Registro  para esta consulta",
                    callback: () => dispatch(closeMessageBox())
                }));
            return;
        }

        let columns = getState().reporteseguimientovendedor.table.columns;
        
        dispatch({ type: RECEIVE_DATA,  columns: columns, payload: data });// payload: data });
        dispatch({ type: RECEIVE_ANT,  payload: data2 });// payload: data });

    } catch (error) {
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'error', 
            message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
            callback: () => dispatch(closeMessageBox())
        }));
    } finally {
        dispatch({ type: SET_MASK, loading: false });
    }
};

export const onSearchDistribuidorClick = () => async (dispatch) => {
    let fields = [
        { 'dataKey': 'razonSocial', 'label': 'Agente', 'type': 'string', 'width': 225 }
    ];

    dispatch(onUpdateFieldFilter('vendedorId', ''));
    dispatch(onUpdateFieldFilter('dVendedor', ''));
    
    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de agente',
        getData: async (params) => {
            let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`
            let response = await axios.get(encodeURI(url), { params: params });

            return response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldFilter('distribuidorId', result[0].distribuidorId));
                dispatch(onUpdateFieldFilter('dDistribuidor', `${result[0].razonSocial}`));
                dispatch(onUpdateFieldFilter('vendedorId', ''));
                dispatch(onUpdateFieldFilter('dVendedor', ''));
                dispatch(onUpdateFielddata('data', []));
            }
        }
    }));
};

// export const onSearchOlaChannelMapClick = () => async (dispatch) => {
//     let fields = [
//         { 'dataKey': 'dOla', 'label': 'Ola', 'type': 'string', 'width': 250 },
//         { 'dataKey': 'desde', 'label': 'Dede', 'type': 'string', 'width': 110 },
//         { 'dataKey': 'hasta', 'label': 'Hasta', 'type': 'string', 'width': 110 }
//     ];

//     let data =  aOlas;

//     try {
//         dispatch(openAdvancedSearch({
//             autoLoad: true,
//             data: data,
//             fields: fields,
//             paginated: false,
//             remote: false,
//             title: 'Selección de Ola Channel Map',
//             callback: (btn, result) => {
//                 dispatch(closeAdvancedSearch());
//                 if(btn === 'yes') {
//                     dispatch(onUpdateFieldFilter('olaId', result[0].olaId));
//                     dispatch(onUpdateFieldFilter('dOla', result[0].dOla));
//                     dispatch(onUpdateFielddata('data', []));
//                 }
//             }
//         }));
//     } catch (error) {
//         if(error.response) {
//             dispatch(openMessageBox({ 
//                 button: 'ok', 
//                 icon: 'error', 
//                 message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
//                 callback: () => dispatch(closeMessageBox())
//             }));
//         }
//     }
// };
export const onSearchOlaChannelMapClick = () => async (dispatch, getState) => {
    let fields = [
      { dataKey: "nombre", label: "Ola", type: "string", width: 250 },
      { dataKey: "desde", label: "Desde", type: "string", width: 110 },
      { dataKey: "hasta", label: "Hasta", type: "string", width: 110 },
    ];
  
    
    try {
      dispatch(
        openAdvancedSearch({
          autoLoad: true,
          data: [],
          fields: fields,
          paginated: true,
          remote: true,
          title: "Selección de Ola Channel Map",
          getData: async (params) => {
            let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/OlasChannelMap/olas`;
            let response = await axios.get(encodeURI(url), { params: params });
            response = {
              data: {
                start: 0,
                pageSize: 50,
                total: response.data.length,
                data: response.data,
              },
            };
    
            return response.data;
          },
          callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if (btn === "yes") {
              dispatch(onUpdateFieldFilter("olaId", result[0].olaChannelMapId));
              dispatch(onUpdateFieldFilter("dOla", result[0].nombre));
              dispatch(onUpdateFieldFilter("distribuidorId", 0));
              dispatch(onUpdateFieldFilter("vendedorId", ""));
              dispatch(onUpdateOlaFieldFilter);
            }
          },
        })
      );
    } catch (error) {
      if (error.response) {
        dispatch(
          openMessageBox({
            button: "ok",
            icon: "error",
            message:
              (error.response.data.eventLogId === 0
                ? ""
                : `EventoId: ${error.response.data.eventLogId}. `) +
              error.response.data.message,
            callback: () => dispatch(closeMessageBox()),
          })
        );
      }
    }
  };


/*------- revisar no se usa */
export const onSearchUsuarioClick = () => async (dispatch) => {
    let fields = [
        { 'dataKey': 'nombreUsuario', 'label': 'Usuario', 'type': 'string', 'width': 110 },
        { 'dataKey': 'nombre', 'label': 'Nombres', 'search': true, 'type': 'string', 'width': 175 },
        { 'dataKey': 'correo', 'label': 'Correo', 'type': 'string', 'width': 120 },
    ];
    dispatch(onUpdateFieldFilter('usuarioId', ''));
    dispatch(onUpdateFieldFilter('dUsuario', ''));

    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección un usuario',
        getData: async (params) => {
            let url = `${IdentityUrl}/api/v1/usuarios/porsupervisor?`
            let response = await axios.get(encodeURI(url), { params: params });

            return response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldFilter('usuarioId', result[0].usuarioId));
                dispatch(onUpdateFieldFilter('dUsuario', `${result[0].nombreUsuario} - ${result[0].nombre}`));
                dispatch(onUpdateFielddata('data', []));
            }
        }
    }));
};

export const    onSearchVendedorClick = () => async (dispatch, getState) => {
    let fields = [
        { 'dataKey': 'nombre','label': 'Nombre','type': 'string','search': true,'width': 225},
        //{ 'dataKey': 'nombreUsuario', 'label': 'Usuario', 'type': 'string', 'width': 115 }
    ];

    if(getState().reporteseguimientovendedor.filters.olaId === 0){
        dispatch(openMessageBox({
            button: 'ok',
            icon: 'warning',
            message: "Debe seleccionar una Ola.",
            callback: () => dispatch(closeMessageBox())
        }));
        return;
    }
    if(getState().reporteseguimientovendedor.filters.distribuidorId === 0){
        dispatch(openMessageBox({
            button: 'ok',
            icon: 'warning',
            message: "Debe seleccionar un Distribuidor.",
            callback: () => dispatch(closeMessageBox())
        }));
        return;
    }
    dispatch(onUpdateFieldFilter('vendedorId', ''));
    dispatch(onUpdateFieldFilter('dVendedor', ''));

    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de vendedor',
        getData: async (params) => {
            //console.log(params.nombre, 'Filtro Vendedor')
            let url = `${getState().main.resources.uriSvcSalesMobilityAPI}/api/v1/Vendedores/listarvendedoresxdistribuidor`
            + `?distribuidorId=${getState().reporteseguimientovendedor.filters.distribuidorId}`               
            let response = await axios.get(encodeURI(url), { params: params });

            return searchVendedor(response, params.nombre); 
        },
        
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldFilter('vendedorId', result[0].usuarioId));
                dispatch(onUpdateFieldFilter('dVendedor', `${result[0].nombre}`));
                dispatch(onUpdateFielddata('data', []));
               
            }
        }
    }));
};

export const onActualizaTipo =  (valor) => (dispatch, getState) => {//em
    dispatch(onUpdateFielddata('data', []));
            dispatch(onUpdateFieldFilter('tipo', valor));
}
            
export const onUpdateColumn = (column) => (dispatch, getState) => {

    getState().reporteseguimientovendedor.table.columns.map(item => {
            if(item.dataKey !== column.dataKey) {
                return item;
            }else{
               return item.width =  column.width;
            }
            
        });
    
};

// export const openDialogClient = (data) => (dispatch, getState) => {
//     try {
  
//     var aux = getState().reporteseguimientovendedor.store.data
//     var storeData = []    
//     for(var i in aux)
//     {
//         if(aux[i].nit === data.nit )
//         {
//             storeData.push(aux[i])
//             break;
//         }
//     }
//       dispatch({ type: SHOW_POPUP_CLIENT, storeData });
//     } catch (error) {
//       dispatch(
//         openMessageBox({
//           button: "ok",
//           icon: "error",
//           message: error,
//           callback: () => dispatch(closeMessageBox()),
//         })
//       );
//     }
// };

//export const closeDialogClient = () => ({ type: HIDE_POPUP_CLIENT });


export const onUpdateFieldFilter = (key, value) => (dispatch, getState)=>{ 
    
    dispatch({type: UPDATE_FIELD_FILTER, key, value}) 
    dispatch({ type: RECEIVE_DATA,columns: [] ,payload: [] })
    dispatch({ type: RECEIVE_ANT,  payload: [] });
}

export const onUpdateFielddata = (key, value) => (dispatch, getState) => {
    dispatch({ type: UPDATE_FIELD_DATA, key, value })
    };

export const setOrder = (order, orderBy) => ({ type: SET_ORDER, order, orderBy });

export const restartState = () => ({ type: RESTART_STATE });

export const setSelectedRow = (selected) => ({ type: SET_SELECTED_ROW, selected});

export const actualizaTipoCliente = (tipo) => ({type: ACTUALIZA_TIPO_CLIENTE, payload:tipo})

export const onUpdateOlaFieldFilter = (dispatch, getState) => {
    dispatch({ type: UPDATE_OLA_FIELD_FILTER });
  };
  