import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
    onUpdateColumn,
    setOrder,
    setSelectedRow,
    onListSucursales
} from "../../../store/fuerzaventas/gestionbonificacion/actions";
import { sort_by } from "../../../helpers/commons";

import { FieldSet, Table } from "@sigeco/tools";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flex: "1 1 auto",
        flexDirection: "column",
    },
    tableWrap: {
        flex: "1 1 auto",
    },
}));

const GestionBonificacionList = () => {
    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();
    const columns = useSelector((state) => state.gestionbonificacion.table.columns);
    const loading = useSelector((state) => state.gestionbonificacion.store.loading);
    const order = useSelector((state) => state.gestionbonificacion.table.order);
    const orderBy = useSelector((state) => state.gestionbonificacion.table.orderBy);
    const store = useSelector((state) => state.gestionbonificacion.store);
    const dispatch = useDispatch();

    const handleDoubleClick = (item) =>  {
        dispatch(saveLimite(item));
        dispatch(onListSucursales(item.BonificacionId));
        history.push({
            hash: location.hash,
            pathname: `/fuerzaventas/gestionbonificacion/${item}`,
            search: location.search,
        });
    };

    const saveLimite = (item) => (dispatch, getState) => {
        getState().gestionbonificacion.store.aux = item;
        //console.log("aux", getState().gestionbonificacion.store.aux);
    };

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === "asc";
        dispatch(setOrder(isAsc ? "desc" : "asc", property));
        let homes = store.data;
        homes.sort(sort_by(property, isAsc, homes[0][property]));
    };

    const handleResizeColumn = (column) => {
        dispatch(onUpdateColumn(column));
    };
    return (
        <FieldSet
            className={classes.root}
            iconCls="fas fa-th-list"
            title="Resultados"
        >
            <div className={classes.tableWrap}>
                <Table
                    columns={columns}
                    count={store.total}
                    loading={loading}
                    onRowClick={(items) => dispatch(setSelectedRow(items))}
                    onRowDoubleClick={(item) => handleDoubleClick(item)}
                    onSort={(datakey) => handleRequestSort(datakey)}
                    onUpdateColumn={handleResizeColumn}
                    rowCount={store.data.length}
                    rowGetter={(index) => store.data[index]}
                    rowsPerPage={store.pageSize}
                    selected={store.selected}
                    sortBy={orderBy}
                    sortDirection={order}
                />
            </div>
        </FieldSet>
    );
};

export default GestionBonificacionList;
