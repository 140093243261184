import React, { useEffect } from "react";
// import { useSelector } from "react-redux";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import './Graphics.css';

am4core.useTheme(am4themes_material);
am4core.useTheme(am4themes_animated);

function Graphics(props) {
    // const data={
    //     labels:["Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto"],
    //     datasets:[
    //         {
    //         label:"Horas de Visualización por Mes",
    //         fill: false,
    //         backgroundColor: 'rgba(73,155,234,1)',
    //         borderColor:'rgba(73,155,234,1)',
    //         pointBorderColor:'rgba(73,155,234,1)',
    //         pointBorderWidth:1,
    //         pointHoverRadius:5,
    //         pointHoverBackgroundColor:'rgba(73,155,234,1)',
    //         pointHoverBorderColor:'rgba(73,155,234,1)',
    //         pointRadius: 1,
    //         pointHitRadius: 10,
    //         data: [0.17, 19, 156, 357, 565, 1149]
    //         }
    //     ]
    // }

    useEffect(() => {
   
        var container = am4core.create("chartdiv", am4core.Container);
    
        container.width = am4core.percent(100);
        container.height = am4core.percent(100);
        container.layout = "vertical";

    
        // Themes begin
    
        createChart(container, "COBERTURA");
    
        return () => {
          container.dispose();
        };
        // eslint-disable-next-line
      }, []);

      const createChart = (container, titleText, x) => {
       
        am4core.useTheme(am4themes_animated);
        // Themes end
  
        // create chart
        var chart = container.createChild(am4charts.XYChart);
      //var chart = am4core.create("chartdiv", am4charts.XYChart);
      chart.scrollbarX = new am4core.Scrollbar();
      
      // Add data
      chart.data = [{
        "country": "ALGER",
        "visits": 3025
      }, {
        "country": "DISTERTOL",
        "visits": 1882
      }, {
        "country": "DISTERPEL",
        "visits": 1809
      }, {
        "country": "LUBRITER",
        "visits": 1322
      }, {
        "country": "LUBRISINU",
        "visits": 1122
      }, {
        "country": "DISTRIMAXTER",
        "visits": 1114
      }, {
        "country": "INC",
        "visits": 984
      }, {
        "country": "Spain",
        "visits": 711
      }, {
        "country": "VICPIMAR",
        "visits": 665
      }];
      
      prepareParetoData();
      
      function prepareParetoData(){
          var total = 0;
      
          for(var i = 0; i < chart.data.length; i++){
              var value = chart.data[i].visits;
              total += value;
          }
      
          var sum = 0;
          for(let i = 0; i < chart.data.length; i++){
              let value = chart.data[i].visits;
              sum += value;   
              chart.data[i].pareto = sum / total * 100;
          }    
      }
      
      // Create axes
      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "country";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 60;
      categoryAxis.tooltip.disabled = true;
      
      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.minWidth = 50;
      valueAxis.min = 0;
      valueAxis.cursorTooltipEnabled = false;
      
      // Create series
      var series = chart.series.push(new am4charts.ColumnSeries());
      series.sequencedInterpolation = true;
      series.dataFields.valueY = "visits";
      series.dataFields.categoryX = "country";
      series.tooltipText = "[{categoryX}: bold]{valueY}[/]";
      series.columns.template.strokeWidth = 0;
      
      series.tooltip.pointerOrientation = "vertical";
      
      series.columns.template.column.cornerRadiusTopLeft = 10;
      series.columns.template.column.cornerRadiusTopRight = 10;
      series.columns.template.column.fillOpacity = 0.8;
      
      // on hover, make corner radiuses bigger
      var hoverState = series.columns.template.column.states.create("hover");
      hoverState.properties.cornerRadiusTopLeft = 0;
      hoverState.properties.cornerRadiusTopRight = 0;
      hoverState.properties.fillOpacity = 1;
      
      series.columns.template.adapter.add("fill", function(fill, target) {
        return chart.colors.getIndex(target.dataItem.index);
      })
      
      
      var paretoValueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      paretoValueAxis.renderer.opposite = true;
      paretoValueAxis.min = 0;
      paretoValueAxis.max = 100;
      paretoValueAxis.strictMinMax = true;
      paretoValueAxis.renderer.grid.template.disabled = true;
      paretoValueAxis.numberFormatter = new am4core.NumberFormatter();
      paretoValueAxis.numberFormatter.numberFormat = "#'%'"
      paretoValueAxis.cursorTooltipEnabled = false;
      
      var paretoSeries = chart.series.push(new am4charts.LineSeries())
      paretoSeries.dataFields.valueY = "pareto";
      paretoSeries.dataFields.categoryX = "country";
      paretoSeries.yAxis = paretoValueAxis;
      paretoSeries.tooltipText = "pareto: {valueY.formatNumber('#.0')}%[/]";
      paretoSeries.bullets.push(new am4charts.CircleBullet());
      paretoSeries.strokeWidth = 2;
      paretoSeries.stroke = new am4core.InterfaceColorSet().getFor("alternativeBackground");
      paretoSeries.strokeOpacity = 0.5;
      
      // Cursor
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.behavior = "panX";
  
    };

    return (
        <div className="containerGrafica">
            <div style={{ height: 200 }} id="chartdiv" />
        </div>
    );
}

export default Graphics;