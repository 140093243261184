import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  onSearchDistribuidorClick,
  onSearchDepartaClick,
  onSearcProvinciaClick,
  onSearcDistritoClick,
  onSearchTipoClick,
  onSearchMarcasIdentificacionClick,
  onUpdateFieldFilter,
} from "../../../store/marketing/reportepromociones/actions";

import { ActionField, FieldSet } from "@sigeco/tools";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { KeyboardDatePicker } from "@material-ui/pickers";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { Icon } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import {getDistribuidorDefault} from '../../../helpers/commons';

const useStyles = makeStyles(() => ({
  content: {
    padding: "0.5rem 0.75rem 0.75rem",
  },
  filterClear: {
    display: "flex",
  },
  actionField: {
    flexDirection: "row",
    position: "relative",
  },
  btnClear: {
    position: "absolute",
    bottom: "1.5",
    right:20,
    maxWidth: "15px",
    maxHeight: "23px",
    minWidth: "15px",
    minHeight: "23px",
  },
  root: {
    flex: "0 0 auto",
    marginBottom: "0.75rem",
    "@media (min-width: 1401px)": {
      marginBottom: "1rem",
    },
  },
}));

const ReportePromocionesFilter = () => {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:701px)");
  const filters = useSelector((state) => state.reportepromociones.filters);
  const dispatch = useDispatch();

  useEffect(() =>{

    getDistribuidorDefault(onUpdateFieldFilter,dispatch);

  },[])

  //Botones de limpiar filtros
  const [, setDistribuidor] = useState("");
  const [btn, setBtn] = useState(true);
  const [, setDepartamento] = useState("");
  const [btn1, setBtn1] = useState(true);
  const [, setProvincia] = useState("");
  const [btn2, setBtn2] = useState(true);
  const [, setDistrito] = useState("");
  const [btn3, setBtn3] = useState(true);
  const [marca, setMarca] = useState("");
  const [btn4, setBtn4] = useState(true);
  const [tipo, setTipo] = useState("");
  const [btn5, setBtn5] = useState(true);
  const [, setNombre] = useState("");
  const [btn6, setBtn6] = useState(true);

  useEffect(() => {
    if (filters.dDistribuidor) {
      setDistribuidor(filters.dDistribuidor);
      setBtn(false);
    } else {
      setBtn(true);
    }
  }, [filters.dDistribuidor]);

  useEffect(() => {
    if (filters.dDepartamento) {
      setDepartamento(filters.dDepartamento);
      setBtn1(false);
    } else {
      setBtn1(true);
    }
  }, [filters.dDepartamento]);

  useEffect(() => {
    if (filters.dProvincia) {
      setProvincia(filters.dProvincia);
      setBtn2(false);
    } else {
      setBtn2(true);
    }
  }, [filters.dProvincia]);

  useEffect(() => {
    if (filters.dDistrito) {
      setDistrito(filters.dDistrito);
      setBtn3(false);
    } else {
      setBtn3(true);
    }
  }, [filters.dDistrito]);

  useEffect(() => {
    if (filters.dMarcaIdentificacion) {
      setMarca(filters.dMarcaIdentificacion);
      setBtn4(false);
    } else {
      setBtn4(true);
    }
  }, [filters.dMarcaIdentificacion]);

  useEffect(() => {
    if (filters.dIdentificacion) {
      setTipo(filters.dIdentificacion);
      setBtn5(false);
    } else {
      setBtn5(true);
    }
  }, [filters.dIdentificacion]);

  useEffect(() => {
    if (filters.nombre) {
      setNombre(filters.nombre);
      setBtn6(false);
    } else {
      setBtn6(true);
    }
  }, [filters.nombre]);


  const handleDist = () => {
    setDistribuidor("");
    setDepartamento("");
    setProvincia("");
    setDistrito("");
    setMarca("");
    setTipo("");
    setNombre("");
    filters.distribuidorId = 0;
    filters.dDistribuidor = "";
    filters.departamentoId = "00";
    filters.dDepartamento = "";
    filters.provinciaId = "00";
    filters.dProvincia = "";
    filters.distritoId = "00";
    filters.dDistrito = "";
    filters.marcaidentificacionId = 0;
    filters.dMarcaIdentificacion = "";
    filters.identificacionId = 0;
    filters.dIdentificacion = "";
    filters.nombre = "";
    setBtn(true);
    setBtn1(true);
    setBtn2(true);
    setBtn3(true);
    setBtn4(true);
    setBtn5(true);
    setBtn6(true);
  };

  const handleDepartamento = () => {
    setDepartamento("");
    setProvincia("");
    setDistrito("");
    filters.departamentoId = "00";
    filters.dDepartamento = "";
    filters.provinciaId = "00";
    filters.dProvincia = "";
    filters.distritoId = "00";
    filters.dDistrito = "";
    setBtn1(true);
    setBtn2(true);
    setBtn3(true);
  };

  const handleProvincia = () => {
    setProvincia("");
    setDistrito("");
    filters.provinciaId = "00";
    filters.dProvincia = "";
    filters.distritoId = "00";
    filters.dDistrito = "";
    setBtn2(true);
    setBtn3(true);
  };

  const handleDistrito = () => {
    setDistrito("");
    filters.distritoId = "00";
    filters.dDistrito = "";
    setBtn3(true);
  };

  const handleMarca = () => {
    setMarca("");
    filters.marcaidentificacionId = 0;
    filters.dMarcaIdentificacion = "";
    setBtn4(true);
  };

  const handleTipo = () => {
    setTipo("");
    filters.identificacionId = 0;
    filters.dIdentificacion = "";
    setBtn5(true);
  };

  const handleNombre = () => {
    setNombre("");
    filters.nombre = "";
    setBtn6(true);
  };

   // TAL VEZ ESTO QUITA EL BUG DE LA LETRA PEGADA
   function onFieldChange(event) {
    dispatch(onUpdateFieldFilter(event.target.name, event.target.value));
  }

  return (
    <div className={classes.root}>
      <FieldSet
        iconCls="fas fa-filter"
        title="Filtros"
        defaultExpanded
        expandable
      >
        <Grid className={classes.content} container spacing={2}>
          <Grid item xs={12} spacing={1} container>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <FormControl className={classes.filterClear}>
                <ActionField
                  className={classes.actionField}
                  label="Agente"
                  placeholder="Enter para buscar agente"
                  value={filters.dDistribuidor}
                  variant={matches ? "outlined" : "standard"}
                  onHandleAction={() => dispatch(onSearchDistribuidorClick())}
                />
                <Button
                  className={classes.btnClear}
                  size="small"
                  startIcon={<Icon className="fa fa-window-close" />}
                  disabled={btn}
                  onClick={handleDist}
                />
              </FormControl>
            </Grid>
            
            <Grid item>
              <KeyboardDatePicker
                autoOk
                disableToolbar
                format="dd/MM/yyyy"
                label="Desde"
                value={filters.desde}
                inputVariant={matches ? "outlined" : "standard"}
                variant={matches ? "inline" : "dialog"}
                onChange={(date) =>
                  dispatch(onUpdateFieldFilter("desde", date))
                }
              />
            </Grid>
            <Grid item>
              <KeyboardDatePicker
                autoOk
                disableToolbar
                format="dd/MM/yyyy"
                label="Hasta"
                value={filters.hasta}
                inputVariant={matches ? "outlined" : "standard"}
                variant={matches ? "inline" : "dialog"}
                onChange={(date) =>
                  dispatch(onUpdateFieldFilter("hasta", date))
                }
              />
            </Grid>

            <Grid item xs={6} sm={3} md={3} lg={5}>
              <FormControl className={classes.filterClear}>
                <TextField
                  className={classes.actionField}
                  autoComplete="off"
                  fullWidth
                  label="Buscar por Cliente o Ruc"
                  name="nombre"
                  value={filters.nombre}
                  variant={matches ? "outlined" : "standard"}
                  onChange={onFieldChange}
                />
                <Button
                    style={{right:0}}
                    className={classes.btnClear}
                    size="small"
                    startIcon={<Icon className="fa fa-window-close" />}
                    disabled={btn6}
                    onClick={handleNombre}
                  />
              </FormControl>
          </Grid>





          </Grid>

          <Grid container item xs={12} spacing={1}>
            

            <Grid item xs={6} sm={3} md={3} lg={2}>
              <FormControl className={classes.filterClear}>
                <ActionField
                  className={classes.actionField}
                  label="Departamento"
                  placeholder="Enter para buscar Departamento"
                  value={filters.dDepartamento}
                  variant={matches ? "outlined" : "standard"}
                  onHandleAction={() => dispatch(onSearchDepartaClick())}
                />
                <Button
                  className={classes.btnClear}
                  size="small"
                  startIcon={<Icon className="fa fa-window-close" />}
                  disabled={btn1}
                  onClick={handleDepartamento}
                />
              </FormControl>
            </Grid>

            <Grid item xs={6} sm={3} md={3} lg={3}>
              <FormControl className={classes.filterClear}>
                <ActionField
                  className={classes.actionField}
                  label="Provincia"
                  placeholder="Enter para buscar Provincia"
                  value={filters.dProvincia}
                  variant={matches ? "outlined" : "standard"}
                  onHandleAction={() => dispatch(onSearcProvinciaClick())}
                />
                <Button
                  className={classes.btnClear}
                  size="small"
                  startIcon={<Icon className="fa fa-window-close" />}
                  disabled={btn2}
                  onClick={handleProvincia}
                />
              </FormControl>
            </Grid>

            <Grid item xs={6} sm={3} md={3} lg={2}>
              <FormControl className={classes.filterClear}>
                <ActionField
                  className={classes.actionField}
                  label="Distrito"
                  placeholder="Enter para buscar Distrito"
                  value={filters.dDistrito}
                  variant={matches ? "outlined" : "standard"}
                  onHandleAction={() => dispatch(onSearcDistritoClick())}
                />
                <Button
                  className={classes.btnClear}
                  size="small"
                  startIcon={<Icon className="fa fa-window-close" />}
                  disabled={btn3}
                  onClick={handleDistrito}
                />
              </FormControl>
            </Grid>

            <Grid item xs={6} sm={3} md={3} lg={3}>
              <FormControl className={classes.filterClear}>
                <ActionField
                  className={classes.actionField}
                  label="Marca"
                  placeholder="Enter para buscar Marca"
                  value={marca}
                  variant={matches ? "outlined" : "standard"}
                  onHandleAction={() =>
                    dispatch(onSearchMarcasIdentificacionClick())
                  }
                />
                <Button
                  className={classes.btnClear}
                  size="small"
                  startIcon={<Icon className="fa fa-window-close" />}
                  disabled={btn4}
                  onClick={handleMarca}
                />
              </FormControl>
            </Grid>

            <Grid item xs={6} sm={3} md={3} lg={2} >
              <FormControl className={classes.filterClear}>
                <ActionField
                  className={classes.actionField}
                  label="Tipo"
                  placeholder="Enter para buscar Tipo"
                  value={tipo}
                  variant={matches ? "outlined" : "standard"}
                  onHandleAction={() => dispatch(onSearchTipoClick())}
                />
                <Button
                  className={classes.btnClear}
                  size="small"
                  startIcon={<Icon className="fa fa-window-close" />}
                  disabled={btn5}
                  onClick={handleTipo}
                />
              </FormControl>
            </Grid>



          </Grid> 

        </Grid>

      </FieldSet>
    </div>
  );
};

export default ReportePromocionesFilter;
