import React, { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_material from "@amcharts/amcharts4/themes/material";

import makeStyles from '@material-ui/core/styles/makeStyles';

am4core.useTheme(am4themes_material);
am4core.useTheme(am4themes_animated);

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        padding: '1rem 0'
    },
    wrap: {
        flex: '1 1 auto'
    }
}));

const ReporteSeguimientoVendedorTotal = () => {
    const classes = useStyles();
    const data = useSelector(state => state.reporteseguimientovendedor.store);
    const dta = useSelector(state => state.reporteseguimientovendedor.store.resumen);
    const agente = useSelector(state => state.reporteseguimientovendedor.filters.dDistribuidor);
    const ola = useSelector(state => state.reporteseguimientovendedor.filters.dOla);
  
    useLayoutEffect(() => {
        var container = am4core.create("chartdiv", am4core.Container);
        
        container.width = am4core.percent(100);
        container.height = am4core.percent(103);
        container.layout = "vertical";

        if(data.length > 0) {
            container.exporting.menu = new am4core.ExportMenu();
            container.exporting.menu.align = "left";
            container.exporting.menu.verticalAlign = "top"
            container.exporting.menu.items = [
                {
                  "label": "...",
                  "menu": [
                    {
                      "label": "Imagen",
                      "menu": [
                        { "type": "png", "label": "PNG" },
                        { "type": "jpg", "label": "JPG" },
                        { "type": "svg", "label": "SVG" },
                        { "type": "pdf", "label": "PDF" }
                      ]
                    },  {
                      "label": "Imprimir", "type": "print"
                    }
                  ]
                }
              ]
        }

        createChart(container, 'RESUMEN', getDataByLob('MCO'));

        return () => {
            container.dispose();
        }
    // eslint-disable-next-line
    }, [data]);

    const resumen = (dta) => {
      let storeData =
      {
         conchannel: _.sumBy(dta, 'conchannel'),
         sinchannel: _.sumBy(dta, 'sinchannel'),
      };
      return storeData
  }
  

const createChart = (container, titleText, data) => {
      let x = resumen(dta)

        am4core.useTheme(am4themes_animated);
        // Themes end
        am4core.useTheme(am4themes_animated);

        var chart = container.createChild(am4charts.PieChart3D);

        //var chart = am4core.create("chartdiv", am4charts.PieChart3D);
        chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

        chart.data = [{
          "category": "Pendientes",
          "value":x.sinchannel

        },  {
          "category": "Ejecutados",
          "value":x.conchannel,
        }];

        var titulo = chart.titles.create();
        titulo.text = "Agente: " + agente+' - Ola: '+ola;
        titulo.fontWeight = 600;
        titulo.fontSize = 22;
        //titulo.align = "center";
        titulo.marginBottom = 40;
        titulo.paddingRight = 180;

        chart.innerRadius = am4core.percent(40);
        chart.depth = 2;
        

        // Add a legend
        chart.legend = new am4charts.Legend();
        chart.legend.position = "right";
        chart.legend.align = "right";
        chart.legend.contentAlign = "right";
        chart.legend.fontSize = 11;
        chart.legend.maxWidth = 200;
        chart.legend.marginRight = 50;
        

        var series = chart.series.push(new am4charts.PieSeries3D());
        series.dataFields.value = "value";
        series.dataFields.depthValue = "value";
        series.dataFields.category = "category";
        series.slices.template.cornerRadius = 5;
        series.colors.step = 4;

        series.labels.template.text = "{value.percent.formatNumber('#.0')}%";
        series.labels.template.radius = 10;
        series.labels.template.align= "center";
        series.labels.template.fill = am4core.color("black");
    
        series.legendSettings.itemValueText = "{valueY.percent}";//em
        

        

        chart.marginRight= 300;
        

};

//Esta función no se usa
    const getDataByLob = (lob) => {
        return _(data)
        .map((item, key) => ({
            'ejecutados': _.countBy(item, i => i.OlaChannelMapId === 1), // === true
        }))
        .value();
    }

    return (
        <div className={classes.root}>
          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 1.5rem', alignItems: 'center' }}>
                <div style={{ fontSize: '1.5rem', fontWeight: 600 }}>General</div>
            </div>
          

             { data.data.length > 0 ?  
            <div style={{ height: 330 , paddingBottom : 10, paddingTop : 1, paddingLeft: 100}} id="chartdiv" />  : null }
                   
            
        </div>
    );
};

export default ReporteSeguimientoVendedorTotal;