import React , { useState } from 'react';
import { useSelector } from 'react-redux';
import { FieldSet } from '@sigeco/tools';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';


const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
     
        marginBottom: '0.75rem',
        '@media (min-width: 1401px)': {
            marginBottom: '1rem',
        }
    }, 
    tableWrap: {
        flex: '1 1 auto'
    },
    
    columna: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, 230px)',
        justifyContent: 'space-around',
        columnGap:"10px " ,
        paddingBottom: '0px',
        
        
    }
    
}));


const ReporteIdentificacionImagenes = ({ onMapOpen }) => {
    const classes = useStyles();
    //const order = useSelector(state => state.reporteidentificacionmercado.table.order);
    //const orderBy = useSelector(state => state.reporteidentificacionmercado.table.orderBy);
    const [open, setOpen] = useState(false);
    const [ax, setAux] = useState();
    const [ax1, setAux1] = useState();
   // const dispatch = useDispatch();
    const data = useSelector(state => state.reporteidentificacionmercado.store.data);

    const imagenes = []

    const a = data.map(dat => dat.UriFoto01)
    const b = data.map(dat => dat.UriFoto02)
    const c = data.map(dat => dat.UriFoto03)
    const d = data.map(dat => dat.urifotofachada)
    const e = data.map(dat => dat.urifotogondola)
    
    const nombre = data.map(dat => dat.NombreComercial)
    const marca = data.map(dat => dat.Marca1)
    const marca2 = data.map(dat => dat.Marca2)
    const marca3 = data.map(dat => dat.Marca3)
    const marcaF = data.map(dat => dat.Fachada)
    const marcaG = data.map(dat => dat.Gondola)
   
    
    const pared1 = a.filter(b => b!=='')
    const pared2 = b.filter(b => b!=='')
    const pared3 = c.filter(b => b!=='')
    const fachada = d.filter(b => b!== '')
    const gondola = e.filter(b => b!== '')

    
    imagenes.push(fachada)
    imagenes.push(pared1)
    imagenes.push(pared2)
    imagenes.push(pared3)
    imagenes.push(gondola)
    
    const handleClose = () => {
        setOpen(false);
    };
    
    const clicked = (x,y)=>{
        
        setAux(x);
        setAux1(y);
        setOpen(true);
    }
    
     return (     
         
        <div className={classes.root}  style ={{width:"100%"}}>
        <FieldSet className={classes.root}   >
            
            <div className={classes.tableWrap}  style ={{width:"100%"}}>
                
                <div className={classes.columna} style ={{width:"100%"}}>
                
                { imagenes.map((image, index)=>( image.map((i,x) =>
                        <div >    
                        <div id='employee'  style={{ display: 'inline-block', border: "7px solid rgba(224, 224, 224, 1)", borderRadius:'5px',backgroundColor:"rgba(224, 224, 224, 1)"}}>
                         <img  alt = 'uno' onClick={()=>clicked(i,x)} key={x} src={i} 
                         style= {{ paddingLeft:"-1px", paddingRight:"100px" ,paddingBottom:"1px",maxHeight:"150px", height:"auto" ,width:"100%"}} />
                         {fachada[x] === i ?
                         <p  style={{marginTop:"-1px",fontSize: "7px", color:"#828282", marginLeft:"1px"}} >CLIENTE:&nbsp;{ `${nombre[x].toUpperCase()}`}&nbsp;TIPO:FACHADA</p>
                         : gondola[x] === i ?
                         <p  style={{marginTop:"-1px",fontSize: "7px", color:"#828282", marginLeft:"1px"}} >CLIENTE:&nbsp;{ `${nombre[x].toUpperCase()}`}&nbsp;TIPO:GÓNDOLA</p>
                          : 
                         <p  style={{marginTop:"-1px",fontSize: "7px", color:"#828282", marginLeft:"1px"}} >CLIENTE:&nbsp;{ `${nombre[x].toUpperCase()}`}&nbsp;TIPO:PARED</p>
                          }
                         </div>
                         <p></p>
                         </div> 
                        )
                    ))}
                
                         {open  ?  
                         
                        <Dialog fullWidth maxWidth="sm" style={{}} open={open} onClose={handleClose} >
                            
                            <div>
                                {  
                                     fachada[ax1] === ax?
                                     <div>
                                    <p  style={{marginTop:"15px",fontSize: "max(1vw, 5px)"}}>&nbsp;&nbsp;&nbsp;&nbsp;<b>Cliente:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b><span style={{color:"#828282"}}>&nbsp;{ `${nombre[ax1].toUpperCase()}`}</span></p>
                                    <p style={{marginTop:"-6px",fontSize: "max(1vw, 5px)"}}>&nbsp;&nbsp;&nbsp;&nbsp;<b>Marca-Fachada:</b><span style={{color:"#828282"}}>&nbsp;&nbsp;&nbsp;{ `${marcaF[ax1]}`}</span></p>
                                    <p  style={{marginTop:"-6px",fontSize: "max(1vw, 5px)"}}>&nbsp;&nbsp;&nbsp;&nbsp;<b>Tipo:</b><span style={{color:"#828282"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; FACHADA </span> </p>
                                    </div>
                                    :  gondola[ax1] === ax ? 
                                    <div>
                                    <p  style={{marginTop:"15px",fontSize: "max(1vw, 5px)"}}>&nbsp;&nbsp;&nbsp;&nbsp;<b>Cliente:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b><span style={{color:"#828282"}}>&nbsp;{ `${nombre[ax1].toUpperCase()}`}</span></p>
                                    <p  style={{marginTop:"-6px",fontSize: "max(1vw, 5px)"}}>&nbsp;&nbsp;&nbsp;&nbsp;<b>Marca-Mobil: </b> &nbsp;<span style={{color:"#828282"}}>{ `${marcaG[ax1]}`}</span></p>
                                    <p  style={{marginTop:"-6px",fontSize: "max(1vw, 5px)"}}>&nbsp;&nbsp;&nbsp;&nbsp;<b>Tipo:</b><span style={{color:"#828282"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;GONDOLA</span></p>
                                    </div>
                                    : 
                                    <div>
                                    <p  style={{marginTop:"15px",fontSize: "max(1vw, 5px)"}}>&nbsp;&nbsp;&nbsp;&nbsp;<b>Cliente:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b><span style={{color:"#828282"}}>&nbsp;{ `${nombre[ax1].toUpperCase()}`}</span></p>
                                    <p  style={{marginTop:"-9px",fontSize: "max(1vw, 5px)"}}>&nbsp;&nbsp;&nbsp;&nbsp;<b>Marca-Pared 1: </b><span style={{color:"#828282"}}> &nbsp;&nbsp;{ `${marca[ax1]}`}</span></p>
                                    <p  style={{marginTop:"-6px",fontSize: "max(1vw, 5px)"}}>&nbsp;&nbsp;&nbsp;&nbsp;<b>Marca-Pared 2: </b> &nbsp;<span style={{color:"#828282"}}>&nbsp;{ `${marca2[ax1]}`}</span></p>
                                    <p  style={{marginTop:"-6px",fontSize: "max(1vw, 5px)"}}>&nbsp;&nbsp;&nbsp;&nbsp;<b>Marca-Pared 3: </b> &nbsp;<span style={{color:"#828282"}}>&nbsp;{ `${marca3[ax1]}`}</span></p>
                                    <p  style={{marginTop:"-6px",fontSize: "max(1vw, 5px)"}}>&nbsp;&nbsp;&nbsp;&nbsp;<b>Tipo:</b><span style={{color:"#828282"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;PARED</span></p>
                                    </div>
                                    
                                    
                                } 
                               
                            </div>
                            
                            <center style={{paddingRight:"80px", marginLeft:"80px"}}> 
                                                   
                                <img key={ax} alt="detail" src={ax} style= {{display: 'inline-block',border: "10px solid rgba(224, 224, 224, 1)", borderRadius:"5px",paddingLeft:"0px",paddingRight:"0px", paddingBottom:"1px", paddingTop:"-3px", width:"70%", height:"10%", maxHeight:"200px"}} /> 
                                                            
                            </center>
                           
                            <DialogActions>
                                <div>
                                    <Button onClick={handleClose} style={{marginTop:"-10px",fontSize: "11px", justifyContent: "flex-end"}} variant="contained" color="primary" > 
                                        Cerrar
                                    </Button>
                                </div>
                                
                                
                            </DialogActions>
                        </Dialog> : null}
                        
                        

                        
                    
                </div>

                            
            </div>
        </FieldSet>        
       </div>
        
    )
};

export default ReporteIdentificacionImagenes;