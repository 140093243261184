import React from "react";
import { useSelector } from "react-redux";

import ReactHTMLTableToExcel from "react-html-table-to-excel";
import _ from "lodash";
import { FieldSet } from "@sigeco/tools";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",

    marginBottom: "0.75rem",
    "@media (min-width: 1401px)": {
      marginBottom: "1rem",
    },
  },
  tableWrap: {
    flex: "1 1 auto",
  },
}));

const AvanceIdentificacionList = ({ onMapOpen }) => {
  const classes = useStyles();
  // const columns = useSelector(
  //   (state) => state.avanceidentificacionmercado.table.columns
  // );
  // const loading = useSelector(
  //   (state) => state.avanceidentificacionmercado.loadingStore
  // );
  // const order = useSelector(
  //   (state) => state.avanceidentificacionmercado.table.order
  // );
  // const orderBy = useSelector(
  //   (state) => state.avanceidentificacionmercado.table.orderBy
  // );
  // const store = useSelector((state) => state.avanceidentificacionmercado.store);

  const tore = useSelector(
    (state) => state.avanceidentificacionmercado.store.data
  );
  //console.log(tore);
  //const dispatch = useDispatch();

  // console.log(store);
  // const handleRequestSort = (property) => {
  //   const isAsc = orderBy === property && order === "asc";
  //   setOrder(isAsc ? "desc" : "asc", property);
  // };

  // const onHandleClick = (item) => {
  //   dispatch(openDialogClient(item));
  // };

  // const onMapClick = (item) => {
  //   onMapOpen(item.dVendedor);
  // };

  const resumen = (dta) => {
    let storeData = {
      ejecutado: _.sumBy(dta, "EJECUTADOS"),
      pendiente: _.sumBy(dta, "PENDIENTES"),
      total: _.sumBy(dta, "TOTAL"),
      porcentaje: _.sumBy(dta, "AVANCE"),
    };
    return storeData;
  };

  const ComponentToPrint = () => {
    return (
      <>
        <div>
          <table style={{border:"none", marginTop:"-40px"}}>
            <thead>
              <tr>
                <th style={{ width: "5%", height: "100%", border: "none" }}></th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <th scope="row"style={{ border: "none" }}>
                  <p style={{ fontSize: "25px" }}>
                    RESÚMEN
                  </p>
                </th>
              </tr>
            </tbody>
          </table>

          <div style={{ display: "none" }}>
          <ReactHTMLTableToExcel
            id="table-xls-button-rpt-avance"
            className=""
            table="table-to-xls"
            filename="AvanceIdentificación"
            sheet="Indicadores"
            buttonText=""
          />
        </div>

          <table id="table-to-xls" style={{ width: "90%", border:"none", marginTop:"-40px", marginBottom:"20px" }}>
            <tbody>
              <tr style={{ height: "10px" }}>
                {/*<th scope="row"></th>

                <th scope="row"></th>*/}
              </tr>

              <tr>
                <th scope="row"style={{ border: "none" }}></th>
                <td
                  style={{
                    border: '1px solid',  
                    borderColor: 'white',  
                    backcolor: '#00FF00', 
                    backgroundColor:  '#23469c', 
                    color: 'white', 
                    textAlign: 'center',
                    //border:"none"
                  }}
                >
                  VENDEDOR
                </td>
                <td
                  style={{
                    border: '1px solid',  borderColor: 'white',  backcolor: '#00FF00', backgroundColor:  '#23469c', color: 'white', textAlign: 'center',
                    //border:"none"
                  }}
                >
                  TOTAL PDV{" "}
                </td>
                <td
                  style={{
                    border: '1px solid',  
                    borderColor: 'white',  
                    backcolor: '#00FF00', 
                    backgroundColor:  '#23469c', 
                    color: 'white', 
                    textAlign: 'center',
                    //border: "none"
                  }}
                >
                  VISITADOS
                </td>
                <td
                  style={{
                    border: '1px solid',  borderColor: 'white',  backcolor: '#00FF00', backgroundColor:  '#23469c', color: 'white', textAlign: 'center',
                    //border:"none"
                  }}
                >
                  PENDIENTES
                </td>
                <td
                  style={{
                    border: '1px solid',  borderColor: 'white',  backcolor: '#00FF00', backgroundColor:  '#23469c', color: 'white', textAlign: 'center',
                    //border:"none"
                  }}
                >
                  CUMPLIMIENTO
                </td>
              </tr>

              {renderBody(tore)}

              {renderTotal(tore)}

              <tr></tr>
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const renderBody = (employees) => {
    return (
      employees &&
      employees.map(
        (
          {
            
            vendedor,
            EJECUTADOS,
            PENDIENTES,
            TOTAL,
            AVANCE
          },
          index
        ) => {
          return (
            <tr style={{ tableLayout: "center"}} key={index}>
              <td
                style={{
                  width: "80px",
                  height: "100%",
                  background: "white",
                  opacity: "0.6",
                  border: "none"
                }}
              ></td>

              <td style={{ width: "300px", height: "100%", background:'#cad1d7', border: "none" }}>{vendedor}</td>
              <td
                style={{
                  width: "170px",
                  textAlign: "center",
                  fontSize: "12px",
                  backgroundColor: index % 2 === 0 ? '#ffffff': '#ffffff',
                  //border: "none"
                }}
              >
                {TOTAL}
              </td>

              <td
                style={{
                  width: "170px",
                  textAlign: "center",
                  fontSize: "12px",
                  backgroundColor: index % 2 === 0 ? '#ffffff': '#ffffff',
                  //border: "none"
                }}
              >
                {EJECUTADOS}
              </td>
              <td
                style={{
                  width: "170px",
                  textAlign: "center",
                  fontSize: "12px",
                  backgroundColor: index % 2 === 0 ? '#ffffff': '#ffffff',
                  //border: "none"
                }}
              >
                {PENDIENTES}
              </td>
              {/*<td style={{ fontSize: '12px', textAlign: 'center', backgroundColor: employees.id % 2 === 0 ? '#cad1d7' : '#d5dbe0' }}> {ejecutado}</td>*/}
              <td
                style={{
                  fontSize: "12px",
                  textAlign: "center",
                  backgroundColor: index % 2 === 0 ? '#ffffff': '#ffffff',
                  fontWeight: "bold",
                  //border: "none",
                  color:
                  parseFloat(AVANCE) < 95
                      ? "red"
                      : parseFloat(AVANCE) >= 95 && parseFloat(AVANCE) < 100
                      ? "orange"
                      : "green",
                }}
              >
                {" "}
                {AVANCE}%
              </td>
            </tr>
          );
        }
      )
    );
  };

  const renderTotal = (employees) => {
    let x = resumen(employees);
    return (
      <tr>
        <td
          style={{
            width: "80px",
            height: "100%",
            background: "white",
            opacity: "0.6",
            border: "none"
            
          }}
        ></td>
        <th scope="row" style={{ border: "none"}} >
          <p
            style={{
              width: '280px', height:'100%' , textAlign: 'right', fontSize: '14px', fontWeight: 'bold',
              marginBottom: "-10px",
              marginTop: "-8px",
              
             
            }}
          >
            Total
          </p>
        </th>
        <td
          style={{
            width : '170px' , textAlign: 'center', fontSize: '12px',  backgroundColor:  '#cad1d7', fontWeight: 'bold'
            
          }}
        >
          {x.total}
        </td>
        <td
          style={{
            width : '170px' , textAlign: 'center', fontSize: '12px',  backgroundColor:  '#cad1d7', fontWeight: 'bold'
          }}
        >
          {x.ejecutado}
        </td>

        <td
          style={{
            width : '170px' , textAlign: 'center', fontSize: '12px',  backgroundColor:  '#cad1d7', fontWeight: 'bold'
          }}
        >
          {x.pendiente}
        </td>
        {/*<td style={{ fontSize: '12px', textAlign: 'center', backgroundColor: '#d5dbe0' }}> {x.pendiente}</td>*/}

        <td
          style={{
            width : '170px' , textAlign: 'center', fontSize: '12px',  backgroundColor:  '#cad1d7', fontWeight: 'bold',
            color:
            parseFloat((x.porcentaje = (x.ejecutado * 100) / x.total)) < 95
                ? "red"
                : parseFloat(
                    (x.porcentaje = (x.ejecutado * 100) / x.total)
                  ) >= 95 &&
                  parseFloat((x.porcentaje = (x.ejecutado * 100) / x.total)) <
                    100
                ? "orange"
                : "green",
          }}
        >
          {parseFloat((x.porcentaje = (x.ejecutado * 100) / x.total)).toFixed(2)}%{" "}
        </td>
      </tr>
    );
  };

  return (
    // <div>
    //   <Grid container spacing={0}>
    //     <Grid item xs={12} md={12}>
    //       <>
    //         <div className={classes.root}>
              <FieldSet
                className={classes.root}
                iconCls="fas fa-th-list"
                title="Resúmen"
              >
                <div className={classes.tableWrap} style={{}}>
                  {tore.length > 0 && <ComponentToPrint />}
                </div>
              </FieldSet>
    //         </div>
    //         {/*<div marginBottom="20px"></div>*/}

    //         <div className={classes.root}></div>
    //       </>
    //     </Grid>
    //   </Grid>
    // </div>
  );
};

export default AvanceIdentificacionList;
