import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import {
     setOrder,
     onUpdateColumn,
     setSelectedMulti,
     setPageSize
} from '../../../store/fuerzaventas/gestionlistadeprecios/actions';
import { sort_by} from '../../../helpers/commons'

import { FieldSet, Table } from '@sigeco/tools';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column'
    }, 
    tableWrap: {
        flex: '1 1 auto'
    }
}));

const GestionListadePreciosList = () => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const columns = useSelector(state => state.gestionlistadeprecios.table.columns);
    const loadingStore = useSelector(state => state.gestionlistadeprecios.store.loading);
    const order = useSelector(state => state.gestionlistadeprecios.table.order);
    const orderBy = useSelector(state => state.gestionlistadeprecios.table.orderBy);
    const store = useSelector(state => state.gestionlistadeprecios.store);
    const formState = useSelector(state => state.gestionlistadeprecios.formState)
    const dispatch = useDispatch();


    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        dispatch(setOrder(isAsc ? 'desc' : 'asc', property));
        let homes = store.data;
        homes.sort(sort_by(property, isAsc, homes[0][property]));
    };

    const handleResizeColumn = (column) => {
        dispatch(onUpdateColumn(column))
    }

    function handleChangeRowsPerPage(event) {
        dispatch(setPageSize(event.target.value));
       
    }

    const handleDoubleClick = (item) => {
        //console.log(item)
       dispatch(saveLista(item));
        history.push({
            hash: location.hash,
            pathname: `/fuerzaventas/gestionlistadeprecios/${item}`,
            search: location.search
        });
    }

    const saveLista = (item)=> (dispatch, getState) => {
        getState().gestionlistadeprecios.store.aux = item
        //console.log(getState().gestionlistadeprecios.store.aux)
     }


    return (
        <FieldSet className={classes.root} iconCls="fas fa-th-list" title="Resultados">
            <div className={classes.tableWrap}>
                <Table
                    multiSelect ={formState === 'new'?  true : false}
                    columns={columns}
                    count={store.total}
                    loading={loadingStore}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    onRowClick={items => dispatch(setSelectedMulti(items))}
                    onRowDoubleClick={items => handleDoubleClick(items) }
                    onSort={datakey => handleRequestSort(datakey)}
                    onUpdateColumn={handleResizeColumn}
                    rowCount={store.data.length}
                    rowGetter={index =>  store.data[index] }
                    rowsPerPage={store.pageSize}
                    selected={store.selected}
                    sortBy={orderBy}
                    sortDirection={order}
                />
            </div>
        </FieldSet>
    )
};

export default GestionListadePreciosList;




