import React from 'react';
import {Card, Typography, CardContent} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

import Graphics from './Graphics';

const useStyles= makeStyles(()=>({
    root:{
        textAlign: 'center',
        backgroundColor: 'rgba(73,155,234,1)'
    },
    texto:{
        fontSize: 18,
        color: 'white'
    },
    titulo:{
        fontWeight: 'bold',
        fontSize: 20,
        color: 'white'
    }
    }));

function CardsG(props) {

    const classes=useStyles();
    return (
        <Card className={classes.root}>
                 {/* <CardHeader
             titleStyle={{ paddingRight: 0, display: 'inline' }}
             title={'' } 

        //   avatar={<Avatar>N</Avatar>}
        //   title="Card Header"
        //   subheader="Card sub heading"
        //   action={
        //     <IconButton>
        //       <MoreVertIcon />
        //     </IconButton>
        //   }
        />*/}
            <CardContent>
            <Typography  className={classes.texto}>
                    {props.texto}
            </Typography>
                    <Graphics />
{/* 
                <Typography  className={classes.texto}>
                {props.texto}
                </Typography>
               
            </CardContent> 
            <CardContent>
            <Typography  className={classes.titulo}>
                            otro
                </Typography>

                <Typography  className={classes.texto}>
               ottro dos
                </Typography> */}
               
            </CardContent> 
            
        </Card>
    );
}

export default CardsG;