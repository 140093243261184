import axios from "axios";
import { push } from "connected-react-router";
import { IdentityUrl } from "../../../config";
import format from "date-fns/format";

import {
  closeAdvancedSearch,
  closeMessageBox,
  openAdvancedSearch,
  openMessageBox,
} from "../../main/actions";
import {
  CLEAN_FORM,
  FILTER_MAP_VENDEDOR,
  HIDE_POPUP_CLIENT,
  RECEIVE_DATA,
  RECEIVE_SUB_DETALLE,
  SET_MASK,
  SET_ORDER,
  SET_PAGE,
  SET_PAGE_SIZE,
  SET_SELECTED_ROW,
  SHOW_POPUP_CLIENT,
  UPDATE_FIELD_FILTER,
  SET_STATE_FORM,
  RESTART_STATE,
  SET_ENTITY,
  UPDATE_FIELD_ENTITYS,
} from "./types";

import { exportToSpreadsheet,cortarArreglo } from "../../../helpers/commons";

export const btnApprove = () => (dispatch) => {

  dispatch(
    openMessageBox({
      button: "yesno",
      icon: "question",
      message: "¿Está seguro de aprobar el Prospecto?",
      callback: (btn) => dispatch(onApprove(btn)),
    })
  );
};

export const onExport = () => async (dispatch, getState) => {

  if (getState().aprobarprospectos.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }
  if (getState().aprobarprospectos.store.data.length === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "No hay información para exportar.",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }
  try {
  dispatch({ type: SET_MASK, loading: true });

    //let response = await axios.get(encodeURI(url)); //, { responseType: 'arraybuffer' });

    let head = getState().aprobarprospectos.table.columns;

    // eslint-disable-next-line no-array-constructor
    // var temp = new Array();
    // for (var c in head) {
    //   let x = Object.values(head[c])[1];
    //   temp.push(x);
    // }
    // eslint-disable-next-line no-array-constructor

    
    //var carArray = new Array();

    //carArray.push(temp);

     let json = getState().aprobarprospectos.store.data;
  //  console.log("HEAD: ", Object.values(head))
  //  console.log("Cantidad HEAD: ", head.length)
  //  console.log(json)

  //   console.log("cabecera: "+ Object.values(head[0])[0])
  //   console.log("cuerpo: "+Object.keys(json[0])[0])
    var p = cortarArreglo(head,json)

    // for (var i in json) {
    //    delete json[i].Clasificacion
    //    delete json[i].JerarquiaId
    //    delete json[i].Telefono
    //    delete json[i].Telefono2
    //    delete json[i].Telefono3
    //    delete json[i].TipoCliente
    //    delete json[i].Ubigeo
    //    delete json[i].UbigeoId

    //   let x = Object.values(json[i]);
    //   // x.splice(6,1);
    //   // x.splice(12,1);
    //   // x.splice(17,1);
    //   // x.splice(19,1);
    //   // x.splice(19,1);
    //   carArray.push(x);
    // }

     exportToSpreadsheet(p, "AprobarProspectos");
    
  } catch (error) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message:
          (error.response.data.eventLogId === 0
            ? ""
            : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    setTimeout(() => {dispatch({ type: SET_MASK, loading: false })}, 1000);
  }
};

export const btnReject = () => (dispatch, getState) => {
  // if (
  //   !getState().desactivacion.entity.motivoRechazo ||
  //   getState().desactivacion.entity.motivoRechazo === ""
  // ) {
  //   dispatch(
  //     openMessageBox({
  //       button: "ok",
  //       icon: "error",
  //       message: "Ingrese un motivo de rechazo",
  //       callback: () => dispatch(closeMessageBox()),
  //     })
  //   );

  //   return;
  // }

  dispatch(
    openMessageBox({
      button: "yesno",
      icon: "question",
      message: "¿Esta seguro de rechazar el registro?",
      callback: (btn) => dispatch(onReject(btn)),
    })
  );
};

export const btnSearch = () => async (dispatch, getState) => {

  
    if (getState().aprobarprospectos.filters.distribuidorId === 0) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "Debe seleccionar un Agente..",
          callback: () => dispatch(closeMessageBox()),
        })
      );
      return;
    }
    dispatch({ type: SET_MASK, loading: true});

    //
 try {
    let url =
      `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Reportes/clientes/listarprospecto?` +
      `distribuidorId=${getState().aprobarprospectos.filters.distribuidorId}` +
      `&estado=${getState().aprobarprospectos.filters.estado !== 'T'? getState().aprobarprospectos.filters.estado : getState().aprobarprospectos.filters.estado='-' }` +
      `&supervisorId=${getState().aprobarprospectos.filters.supervisorId}` +
      `&vendedorId=${getState().aprobarprospectos.filters.vendedorId}` +
      `&soloprospecto=${0}`+
      `&desde=${format( getState().aprobarprospectos.filters.desde,  "yyyy-MM-dd" )}` +
      `&hasta=${format( getState().aprobarprospectos.filters.hasta,  "yyyy-MM-dd" )}`+
      `&todos=${getState().aprobarprospectos.filters.todos === true ? 1 : 0}`;
    let resp = await axios.get(encodeURI(url));
    let data = resp.data;

    if (data.length === 0) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "No existe Registro  para esta consulta",
          callback: () => dispatch(closeMessageBox()),
        })
      );
      return;
    }
    var i;
    var cont = 0;
    for (i = 0; i < data.length; i++) {
      if (data[i].conPedido === 1 || data[i].montoPedido > 0) {
        cont = cont + 1;
      }
    }

    let columns = [
      { dataKey: "Nombre", label: "Vendedor", width: 200 },
      { dataKey: "NIT", label: "NIT/RUC", width: 100 },
      { dataKey: "NombreComercial", label: "Nombre Comercial", width: 250 },
      { dataKey: "DireccionFiscal", label: "Dirección Fiscal", width: 350 },
      { dataKey: "NombreSucursal", label: "Nombre Sucursal", width: 250 },
      { dataKey: "DireccionSucursal", label: "Direcioón Sucursal", width: 350 },
      { dataKey: "suc_num", label: "N. Sucursal", width: 60 },
      { dataKey: "FechaRegistro", label: "Fecha Registro", width: 100 },
      { dataKey: "Canal", label: "Canal", width: 60 },
      { dataKey: "Segmento", label: "Segmento", width: 130 },
      { dataKey: "Categoria", label: "Categoría", width: 150 },
      { dataKey: "Motivo", label: "Motivo", width: 250 },
      { dataKey: "Correo", label: "Correo", width: 200 },
      { dataKey: "Latitud", label: "Latitud", width: 200 },
      { dataKey: "Longitud", label: "Longuitud", width: 200 },
      { dataKey: "Estado", label: "Estado", width: 50 },
      { dataKey: "Contacto", label: "Contacto", width: 200 },
      { dataKey: "Anulado", label: "Anulado", type: "boolean", width: 80 },
      { dataKey: "ProspectoId", label: "ClienteId", width: 90 },
      { dataKey: "Comentario", label: "Comentario", width: 200 },
      { dataKey: "UriFoto01", label: "Foto01", width: 300 },
      { dataKey: "UriFoto02", label: "Foto02", width: 300 },
      { dataKey: "VendedorAsignado", label: "Vendedor Asignado", width: 200 },

      
    ];
    if (getState().aprobarprospectos.filters.distribuidorId === 29){
      columns.push({ dataKey: "VendedorDos", label: "Vendedor Dos", width: 200 })
  }
  
    dispatch({ type: RECEIVE_DATA, columns: columns, payload: data }); // payload: data });
  } catch (error) {
    dispatch({ type: SET_MASK, loading: false });
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message:
          (error.response.data.eventLogId === 0
            ? ""
            : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    dispatch({ type: SET_MASK, loading: false });
  }

  /* ----------------------------------------------------------------------------------*/
  /*
try {
        
    let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/reportes/reportepedidos?` 
    + `&desde=${format(getState().aprobarprospectos.filters.desde, 'yyyy-MM-dd')}`
    + `&hasta=${format(getState().aprobarprospectos.filters.hasta, 'yyyy-MM-dd')}`
        + `&vendedorid=${getState().reportepedidos.filters.vendedorId}`
    
    let response = await axios.get(encodeURI(url));
    let datap = response.data;

    let cuenta = _(datap.data)
    .groupBy('Vendedor')
    .map((item, key) => ({
        'Vendedor': key,
        'proyectados': _.countBy(item, i => i.PedidoId > 0 ),
         }))
    .value();


let storePed = _.map(cuenta, (item) => {
    return {
        Vendedor: item.Vendedor,
        cuentaPuntoVentaE: item.proyectados['true'] ?? 0
    };
});

        dispatch({ type: RECEIVE_OLAS_LIST, pay: datap, storePed });

    } catch (error) {
        let msgError = '';
        dispatch({ type: SET_MASK, loading: false });

        if (error.response) {
        
         if(error.response.status === 400) {
                msgError = (error.response.data.eventLogId === 0 ? 'No existe Registro  para esta fecha ' : `EventoId: ${error.response.data.eventLogId}. `+ error.response.data.message) 
         }else{
            msgError = (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message;
         }
        }
        else {
            msgError = 'Error al conectar con el servidor';
        }

        dispatch(openMessageBox({
            button: 'ok',
            icon: 'error',
            message: msgError,
            callback: () => dispatch(closeMessageBox())
        }));
    }finally{
        dispatch({ type: SET_MASK, loading: false });
    }

    */
};

export const searchSub = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_MASK, loading: true });

    if (getState().aprobarprospectos.filters.distribuidorId === 0) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "Debe seleccionar un Agente..",
          callback: () => dispatch(closeMessageBox()),
        })
      );
      return;
    }

    let url =`${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Prospectos/prospectosucursal/${id}`+
    `?distribuidorId=${getState().aprobarprospectos.filters.distribuidorId}` ;

    let response = await axios.get(encodeURI(url));
    let datad = response;
    console.log(datad)
    dispatch(onUpdateFieldFilter ('sw', datad.data.estado))
    dispatch({ type: RECEIVE_SUB_DETALLE,  columns: [], payload: datad.data.sucursales }); // payload: data });
    

  } catch (error) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message:
          (error.response.data.eventLogId === 0
            ? ""
            : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    dispatch({ type: SET_MASK, loading: false });
  }

};

export const cleanForm = () => ({ type: CLEAN_FORM });

export const getDesactivacion =
  (desactivacionId) => async (dispatch, getState) => {
    try {
      dispatch({ type: SET_MASK, loading: true });

      let url = `${
        getState().main.resources.uriSvcSalesMobilityAPI
      }api/v1/clientes/desactivaciones/${desactivacionId}`;
      let response = await axios.get(encodeURI(url));
      let payload = {
        ...response.data,
      };

      dispatch({ type: SET_ENTITY, payload });
    } catch (error) {
      if (error.response.status === 404) {
        dispatch(
          openMessageBox({
            button: "ok",
            icon: "error",
            message: "No se encontro el registro solicitado.",
            callback: () => {
              dispatch(closeMessageBox());
              dispatch(
                push({
                  pathname: `/clientes/prospectos`,
                  search: getState().router.location.search,
                  hash: getState().router.location.hash,
                })
              );
            },
          })
        );
      } else {
        dispatch(
          openMessageBox({
            button: "ok",
            icon: "error",
            message:
              (error.response.data.eventLogId === 0
                ? ""
                : `EventoId: ${error.response.data.eventLogId}. `) +
              error.response.data.message,
            callback: () => dispatch(closeMessageBox()),
          })
        );
      }
    } finally {
      dispatch({ type: SET_MASK, loading: false });
    }
  };

export const closeDialogClient = () => ({ type: HIDE_POPUP_CLIENT });

export const filterMapByVendedor = (dVendedor) => ({
  type: FILTER_MAP_VENDEDOR,
  dVendedor,
});

export const onSearchDistribuidorClick = () => async (dispatch) => {
  let fields = [
    { dataKey: "razonSocial", label: "Agente", type: "string", width: 225 },
  ];

  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de agente",
      getData: async (params) => {
        let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`;
        let response = await axios.get(encodeURI(url), { params: params });

        return response.data;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(
            onUpdateFieldFilter("distribuidorId", result[0].distribuidorId)
          );
          dispatch(
            onUpdateFieldFilter("dDistribuidor", `${result[0].razonSocial}`)
          );
          dispatch(onUpdateFieldFilter("supervisorId", ""));
          dispatch(onUpdateFieldFilter("dSupervisor", ""));
          dispatch(onUpdateFieldFilter("vendedorId", ""));
          dispatch(onUpdateFieldFilter("dVendedor", ""));
        }
      },
    })
  );
};

export const onSearchRutaXVendedor = () => async (dispatch, getState) => {
  let fields = [
    { dataKey: "razonSocial", label: "Agente", type: "string", width: 225 },
  ];

  // eslint-disable-next-line no-unused-expressions
  const vendedorId = getState().aprobarprospectos.filters.vendedorId;

  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de Agente",
      getData: async (params) => {
        let url = `${IdentityUrl}/api/v1/Rutas/vendedores/${vendedorId}`;
        let response = await axios.get(encodeURI(url), { params: params });
        console.log(response);
        return response.data;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(
            onUpdateFieldFilter("distribuidorId", result[0].distribuidorId)
          );
          dispatch(
            onUpdateFieldFilter("dDistribuidor", `${result[0].razonSocial}`)
          );
          dispatch(onUpdateFieldFilter("supervisorId", ""));
          dispatch(onUpdateFieldFilter("dSupervisor", ""));
          dispatch(onUpdateFieldFilter("vendedorId", ""));
          dispatch(onUpdateFieldFilter("dVendedor", ""));
        }
      },
    })
  );
};

export const onSearchSupervisorClick = () => async (dispatch, getState) => {
  let fields = [{dataKey: "nombre", label: "Nombre", type: "string", search: true, width: 225,},
                { dataKey: "nombreUsuario", label: "Usuario", type: "string", width: 115 }];

  if (getState().aprobarprospectos.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }

  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de Supervisor",
      getData: async (params) => {
        //console.log("Recibe lo del input"+JSON.stringify(params))
        let url = `${IdentityUrl}/api/v1/usuarios?rol=Supervisor`;
        let response = await axios.get(encodeURI(url), { params: params });
        //console.log("Como eres response",response)
        
        
        let distribuidorId = getState().aprobarprospectos.filters.distribuidorId;
        if (distribuidorId > 0) {
          response.data.data = response.data.data.filter(
            (f) => f.distribuidorId === distribuidorId
          );
        }
        //console.log("DDD: ",response.data);
        return response.data;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(onUpdateFieldFilter("supervisorId", result[0].usuarioId));
          dispatch(onUpdateFieldFilter("dSupervisor", `${result[0].nombre}`));
          dispatch(onUpdateFieldFilter("vendedorId", ""));
          dispatch(onUpdateFieldFilter("dVendedor", ""));
        }
      },
    })
  );
};

export const onSearchUbigeoClick = () => async (dispatch, getState) => {
  let fields = [
    { dataKey: "codigo", label: "Código", type: "string", width: 115 },
    {
      dataKey: "dUbigeo",
      label: "Ubigeo",
      type: "string",
      search: true,
      width: 225,
    },
  ];

  dispatch(
    openAdvancedSearch({
      autoLoad: false,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de ubigeo",
      getData: async (params) => {
        let url = `${
          getState().main.resources.uriSvcSalesMobilityAPI
        }api/v1/agentes/ubigeos`;
        let response = await axios.get(encodeURI(url), { params: params });

        return response.data;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(onUpdateFieldFilter("ubigeoId", result[0].ubigeoId));
          dispatch(onUpdateFieldFilter("dUbigeo", `${result[0].dUbigeoId}`));
        }
      },
    })
  );
};

export const onSearchVendedorClick = () => async (dispatch, getState) => {
  let fields = [
    {
      dataKey: "nombre",
      label: "Nombre",
      type: "string",
      search: true,
      width: 225,
    },
    { dataKey: "nombreUsuario", label: "Usuario", type: "string", width: 115 },
  ];

  if (getState().aprobarprospectos.filters.supervisorId === "") {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Supervisor..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }

  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de Vendedor",
      getData: async (params) => {
        let url = `${IdentityUrl}/api/v1/usuarios/porjerarquia?usuarioId=${
          getState().aprobarprospectos.filters.supervisorId
        }`;
        let response = await axios.get(encodeURI(url), { params: params });
        console.log(response.data)
        
        let distribuidorId =
          getState().aprobarprospectos.filters.distribuidorId;

        if (distribuidorId > 0) {
          response.data.data = response.data.data.filter(
            (f) => f.distribuidorId === distribuidorId
          );
        }
        console.log(response.data)
        return response.data;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(onUpdateFieldFilter("vendedorId", result[0].usuarioId));
          dispatch(onUpdateFieldFilter("dVendedor", `${result[0].nombre}`));
          // dispatch({ type: RECEIVE_OLAS_LIST, columns: getState().tracking.table.columns, payload: [] });
        }
      },
    })
  );
};

export const onApprove = (btn) => async (dispatch, getState) => {
   
const ProspectoId = getState().aprobarprospectos.store.detalle.ProspectoId

  dispatch(closeMessageBox());

  if (btn === "yes") {

      if (getState().aprobarprospectos.store.detalle.NIT === "0000000"   || getState().aprobarprospectos.store.detalle.NIT === '' ||
      !getState().aprobarprospectos.store.detalle.NIT  ) {
        dispatch(
          openMessageBox({
            button: "ok",
            icon: "error",
            message: "Prospecto Con error en el Ruc",
            callback: () => dispatch(closeMessageBox()),
          })
        );

        return;
      }

      if (
        getState().aprobarprospectos.store.detalle.RutaId === null ||
        getState().aprobarprospectos.store.detalle.RutaId ||
        getState().aprobarprospectos.store.detalle.RutaId === ""
      ) {
        dispatch(
          openMessageBox({
            button: "ok",
            icon: "error",
            message: "Prospecto Sin Ruta definida",
            callback: () => dispatch(closeMessageBox()),
          })
        );
        return;
      }
      
      dispatch({ type: SET_MASK, loading: true });
      
      try {
        let prospecto = {  ProspectoId: ProspectoId,
           distribuidorId: getState().aprobarprospectos.filters.distribuidorId};
        
        let url = `${
          getState().main.resources.uriSvcSalesMobilityAPI}/api/v1/Prospectos/aprueba`;
        await axios.put(url, prospecto, {
          headers: { "Content-Type": "application/json" },
        });
      
        dispatch(
          openMessageBox({
            button: "ok",
            icon: "info",
            message: "Registro Aprobado con exito.",
            callback: () => {
              dispatch(onUpdateFieldFilter ('sw', 'A'))
              dispatch(closeMessageBox());
            },
          })
        );
    }
    
    catch (error) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "error",
          message:
            (error.response.data.eventLogId === 0
              ? ""
              : `EventoId: ${error.response.data.eventLogId}. `) +
            error.response.data.message,
          callback: () => dispatch(closeMessageBox()),
        })
      );
    } finally {
      dispatch({ type: SET_MASK, loading: false });
    }
  }
};

export const onReject = (btn) => async (dispatch, getState) => {
  const ProspectoId = getState().aprobarprospectos.store.detalle.ProspectoId

  dispatch(closeMessageBox());

  if (btn === "yes") {

    dispatch({ type: SET_MASK, loading: true });
    try {

     
      let rechazo = {  prospectoId: ProspectoId, distribuidorId: getState().aprobarprospectos.filters.distribuidorId,  motivo:getState().aprobarprospectos.entity.motivoRechazo};
  
      let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/prospectos/rechaza`;
         await axios.put(url, rechazo, {headers: { "Content-Type": "application/json" },
      });

        dispatch(
          openMessageBox({
            button: "ok",
            icon: "info",
            message: "Registro rechazado con exito.",
            callback: () => {
              dispatch(onUpdateFieldFilter ('sw', 'R'))
              dispatch(closeMessageBox());  
            },
          })
        );
      
    } catch (error) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "error",
          message:
            (error.response.data.eventLogId === 0
              ? ""
              : `EventoId: ${error.response.data.eventLogId}. `) +
            error.response.data.message,
          callback: () => dispatch(closeMessageBox()),
        })
      );
    } finally {
      dispatch({ type: SET_MASK, loading: false });
    }
  }
};

export const onUpdateColumn = (column) => (dispatch, getState) => {
  getState().aprobarprospectos.table.columns.map((item, i) => {
    if (item.dataKey !== column.dataKey) {
      return item;
    } else {
      item.width = column.width;
    }
    return {
      // ...item,
      //  width: column.width
    };
  });
};

export const onUpdateFieldFilter = (key, value) => (dispatch, getState) => {
  dispatch({ type: UPDATE_FIELD_FILTER, key, value });
 dispatch(onUpdateFielddata());
};


export const onUpdateFieldEntity = ( value) => (dispatch) => {
  dispatch({ type: UPDATE_FIELD_ENTITYS, value });
};

export const onUpdateFielddata = (key, value) => (dispatch, getState) => {
  dispatch({ type: UPDATE_FIELD_FILTER, key, value });

  dispatch({ type: RECEIVE_DATA, columns: [], payload: [] });
};

export const openDialogClient = (data) => (dispatch, getState) => {
  try {
    var storeData = getState().aprobarprospectos.data.filter(
      (f) => f.dVendedor === data.dVendedor
    );

    dispatch({ type: SHOW_POPUP_CLIENT, storeData });
  } catch (error) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message: error,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  }
};

export const restartState = () => ({ type: RESTART_STATE });

export const setFormState = (formState, id) => (dispatch, getState) => {
  switch (formState) {
    case "search":
      dispatch(cleanForm());
      break;
    case "view":
      if (id && id !== getState().aprobarprospectos.entity.desactivacionId) {
      
      }
      break;
    default:
      break;
  }
  dispatch({ type: SET_STATE_FORM, formState });
};

export const setOrder = (order, orderBy) => ({
  type: SET_ORDER,
  order,
  orderBy,
});

export const setSelectedRow = (selected) => (dispatch, getState) => {
  // console.log(selected)
  dispatch({type: SET_SELECTED_ROW,  selected})
  // dispatch({ type: RECEIVE_V_LIST, columns: key, payload: value });
 
};

export const setPage = (page) => ({ type: SET_PAGE, page });

export const setPageSize = (pageSize) => ({ type: SET_PAGE_SIZE, pageSize });
