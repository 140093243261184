import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { FieldSet } from '@sigeco/tools';

import makeStyles from '@material-ui/core/styles/makeStyles';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import CardContent from '@material-ui/core/CardContent';
import Icon from '@material-ui/core/Icon';

const useStyles = makeStyles(() => ({
    content: {
        padding: '0.5rem 0.75rem 0.75rem'
    },
    root: {
        flex: '0 0 auto',
        marginBottom: '0.75rem',
        '@media (min-width: 1401px)': {
            marginBottom: '1rem',
        }
    },
    mime: {
        fontSize: 8,
        "& .MuiFormLabel-root": {
          color: "red"
        }
      }
}));

//const URL = 'https://perlube.com/ini/gigantes-presupuesto.json'

const ResumenPresupuesto = () => {
  
    const classes = useStyles();

    const store = useSelector(state => state.resumen.store);
    const filters = useSelector(state => state.resumen.filters);

    const [data, setData] = useState([])

    //console.log(store.presupuesto);

    useEffect(() => {
        if (store.presupuesto.length ){
            setData(store.presupuesto)
}
        
    }, [data.presupuesto, store.presupuesto])


    const renderHeader = () => {
        let headerElement = ['Nro','Distribuidor', 'Asesor', 'Cumplimiento', 'Pago']

        return headerElement.map((key, index) => {
            return <th key={index} style={{ backgroundColor: 'lightblue' }}>{key.toUpperCase()} </th>
        })
    }

    
    const renderBody = () => {
        var i = 1;
        return data && data.map(({ id, distribuidor, asesor, cumplimiento, pago }) => {
            return (
                <tr style={{ fontSize: '12px',backgroundColor: i%2 === 0 ?'lavender' : 'white' }} key={i}>
                    <td>{i++ }</td>
                    <td>{distribuidor}</td>
                    <td>{asesor}</td>
                    <td style={{textAlign : 'center'}}>{cumplimiento.toFixed(2)}%</td>
                    <td style={{textAlign : 'center'}}><b>${pago}</b></td>
                </tr>
            )
        })
    }

    return (
        <div className={classes.root}  style={{   textAlign : 'center'}} > 
        
           
           <FieldSet iconCls="fas fa-hand-pointer" title="CUMPLIMIENTO PRESUPUESTO " defaultExpanded={true}   expandable>
                   
                   <CardContent style={{ overflow:'hidden' }}>

                           <ListItem   >
                               <ListItemAvatar>
                                 <Avatar>
                                 <Icon className="fas  fa-hand-pointer" ></Icon>
                                 </Avatar>
                               </ListItemAvatar>
                           </ListItem>
                           <ListItemText primary="Activo" secondary= {store.meses[filters.desde.getMonth()] +',  '+ filters.desde.getFullYear()}/> 
                    </CardContent>

                    <div  style={{ flex: 1,   justifyContent: "center",   backgroundColor: "#e5e5e5" }}>
                            -
                        </div>

                        <div style={{display: 'block',  width: '100%', border: '5px', 
                            padding: '10px', justifyContent: "center",}}>
    
                            <FieldSet iconCls="fas fa-hand-pointer" title="Info" 
                            defaultExpanded expandable  style={{ textAlign : 'center', width: '200%'}} > 
                
                                <div  style={{ flex: 1,   justifyContent: "center"}}>
                                        <img alt='logo'  src={ './images/resumen.png'}/>
 
                                        <div  style={{ flex: 1,   justifyContent: "center"}}>
                                        
                                        <CardContent  style={{ border : '36px'}}>              
                                        <table id='employee'  style={{ display: 'inline-block', border: "10px solid rgba(224, 224, 224, 1)", borderRadius:'5px'}}>
                                                <thead>
                                                    <tr>{renderHeader()}</tr>
                                                </thead>
                                                <tbody>
                                                    {renderBody()}
                                                </tbody>
                                        </table>
                                        </CardContent>
                                       
                                        </div>
                                </div>
                            </FieldSet>  
                        </div>      
                    
            </FieldSet>   
     
        </div>
    )
};

export default ResumenPresupuesto;