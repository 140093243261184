import React, { useLayoutEffect } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


import makeStyles from '@material-ui/core/styles/makeStyles';


am4core.useTheme(am4themes_animated);

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column'
    }, 
    tableWrap: {
        flex: '1 1 auto'
    }
}));

const ReporteVisitasGrap = ({ store}) => {
    const classes = useStyles();

    useLayoutEffect(() => {
        am4core.useTheme(am4themes_animated);

        am4core.useTheme(am4themes_animated);

        var data = [{
            "country": "Lithuania",
            "units": 500,
            "pie": [{
                "value": 250,
                "title": "Cat #1"
            }, {
                "value": 150,
                "title": "Cat #2"
            }, {
                "value": 100,
                "title": "Cat #3"
            }],
        
        }];

        let dta = store
  
        if (  dta &&  dta.length > 0){
                    let doubled = dta.map((item, key) => ({
          
                "country": item.dVendedor.substring(0, item.dVendedor.split(" ")[0].length+2),
                "units": item.avance, 
                "pie": [{
                    "value": item.cuentaPuntoVentaP, 
                    "title": "Proyectado"},
                    {
                        "value": item.cuentaPuntoVentaE, 
                        "title": "Ejecutado"
                }]  
            }));
            data = doubled
        }
        
        
        // Create chart instance
        var chart = am4core.create("chartdiv2", am4charts.XYChart);
        chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.behavior = "zoomXY";

        // Add data
        chart.data = data;
        
        // Create axes
        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "country";
        categoryAxis.renderer.grid.template.disabled = true;
        
        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.title.text = "Avance";
        valueAxis.min = 0;
        valueAxis.renderer.baseGrid.disabled = true;
        valueAxis.renderer.grid.template.strokeOpacity = 0.07;
        
        // Create series
        var series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = "units";
        series.dataFields.categoryX = "country";
        series.tooltip.pointerOrientation = "vertical";
        
        
        var columnTemplate = series.columns.template;
        // add tooltip on column, not template, so that slices could also have tooltip
        columnTemplate.column.tooltipText = " Avance{name}\nVendedor: {categoryX}\nValor: {valueY}%";
        columnTemplate.column.tooltipY = 0;
        columnTemplate.column.cornerRadiusTopLeft = 20;
        columnTemplate.column.cornerRadiusTopRight = 20;
        columnTemplate.strokeOpacity = 0;
        
        
        // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
        columnTemplate.adapter.add("fill", (fill, target) => {	
            var color = chart.colors.getIndex(target.dataItem.index * 3);
            return color;
        });
        
        // create pie chart as a column child
        var pieChart = series.columns.template.createChild(am4charts.PieChart);
        pieChart.width = am4core.percent(80);
        pieChart.height = am4core.percent(80);
        pieChart.align = "center";
        pieChart.valign = "middle";
        pieChart.dataFields.data = "pie";
        
        var pieSeries = pieChart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "value";
        pieSeries.dataFields.category = "title";
        pieSeries.labels.template.disabled = true;
        pieSeries.ticks.template.disabled = true;
        pieSeries.slices.template.stroke = am4core.color("#ffffff");
        pieSeries.slices.template.strokeWidth = 1;
        pieSeries.slices.template.strokeOpacity = 0;
        
        pieSeries.slices.template.adapter.add("fill", (fill, target)=>{
          return am4core.color("#ffffff")
        });
        
        pieSeries.slices.template.adapter.add("fillOpacity", (fillOpacity, target)=>{
          return (target.dataItem.index + 1) * 0.2;
        });
        
        pieSeries.hiddenState.properties.startAngle = -90;
        pieSeries.hiddenState.properties.endAngle = 270;

    //Scroll 
        if (chart.data.length < 12) {
            // Scrollbars
            chart.scrollbarX = new am4core.Scrollbar();
            chart.scrollbarY = new am4core.Scrollbar();
        }else{
            // Add scrollbar
            chart.scrollbarX = new am4charts.XYChartScrollbar();
            chart.scrollbarX.series.push(series);
            chart.scrollbarX.scrollbarChart.series.getIndex(0).xAxis.startLocation = 0.5;
            chart.scrollbarX.scrollbarChart.series.getIndex(0).xAxis.endLocation = 0.5;
      
            // Pre-zoom the chart
            categoryAxis.start = 0;
            categoryAxis.end = 9 / chart.data.length;
            categoryAxis.keepSelection = true;
        }

        return () => {
            chart.dispose();
        };


    }, [store]);

    return (
        <div className={classes.root}>
            <div id="chartdiv2" className={classes.tableWrap} style={{ height: 400 }}> 
            </div>
        </div>
    );
};

export default ReporteVisitasGrap;