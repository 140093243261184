import React, { useEffect, useState  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import {
    onUpdateFieldEntity,


} from "../../../store/movilidad/planificarvisita/actions";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { ActionField, FieldSet } from "@sigeco/tools";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FormControl from "@material-ui/core/FormControl";
import TextField from '@material-ui/core/TextField';
import { Icon } from "@material-ui/core";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column'
    }, 
    tableWrap: {
        flex: '1 1 auto'
    },
    filterClear: {
        display: "flex",
      },
    details: {
        display: 'flex',
        padding: '0.5rem 0.75rem 0.75rem'
    },
    actionField: {
        flexDirection: "row",
        position: "relative",
      },
    btnClear: {
        position: "absolute",
        bottom: "1.5",
        right:20,
        maxWidth: "15px",
        maxHeight: "23px",
        minWidth: "15px",
        minHeight: "23px",
    },
}));

const PlanificarVisitaModificar = () => {
    const classes = useStyles();
    const matches = useMediaQuery("(min-width:701px)");
    const entity = useSelector(state => state.planificarvisita.entity);
    const errors = useSelector(state => state.planificarvisita.errors);
    const formState = useSelector(state => state.planificarvisita.formState);
    const dispatch = useDispatch();
    const isView = formState === 'edit';
    const [renderer, setRenderer] = useState(false);
   // console.log(store, 'Store')
   // console.log(columns, 'columnas')
      console.log("aa: ",entity)
   //    console.log('entity: ', entity)
   function onFieldChange(event) {
    dispatch(onUpdateFieldEntity(event.target.name, event.target.value));
   }

   



  

    return (
        <div>
            <FieldSet className={classes.paper} iconCls="fas fa-address-card" title="Editar Planificación de Visitas">
                <div className={classes.details}>
                    <Grid container spacing={2}>


                    <Grid item xs={12} container>
                            <Grid item xs={matches ? 4 : 12}>
                            <TextField
                                fullWidth
                                label="Nombre de Sucursal"
                                value={entity.Dcliente}
                                variant={matches ? "outlined" : "standard"}
                                inputProps={{
                                    readOnly: true // para bloquear  campo, solo vista
                                }}
                            />
                            </Grid>
                    </Grid>

                    <Grid item xs={12} container>
                            <Grid item xs={matches ? 4 : 12}>
                            <TextField
                                fullWidth
                                label="Dirección"
                                value={entity.direccion}
                                variant={matches ? "outlined" : "standard"}
                                inputProps={{
                                    readOnly: true // para bloquear  campo, solo vista
                                }}
                            />
                            </Grid>
                    </Grid>



                    <Grid item xs={12} container>
                            <Grid item xs={matches ? 4 : 12}>
                            <KeyboardDatePicker
                               autoOk
                               disableToolbar
                               format="dd/MM/yyyy"
                               label="Fecha"
                               value={entity.desde}
                               inputVariant={matches ? "outlined" : "standard"}
                               variant={matches ? "inline" : "dialog"}
                               onChange={(date) =>
                                dispatch(onUpdateFieldEntity("desde", date))
                              }                  
                            />
                            </Grid>
                    </Grid>


                      
                       
                  
                                  
                    </Grid>
                </div>
            </FieldSet>
      
        </div>
    )
};

export default PlanificarVisitaModificar;