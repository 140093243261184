import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    btnSearch,
     
} from '../../store/movilidad/listadoglobal/actions';

import { Body, Footer, Page } from '@sigeco/tools';
import AppBar from '@material-ui/core/AppBar';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import ListadoGlobalFilter from '../../components/movilidad/listadoglobal/ListadoGlobalFilter';
import ListadoGlobalMap from  '../../components/movilidad/listadoglobal/ListadoGlobalMap';
import ListarAgentesMap from  '../../components/movilidad/listadoglobal/ListarAgentesMap';

const ListadoGlobal = ({onClose, setTitle}) => {
    const loading = useSelector(state => state.tracking.loading);
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);


    useEffect(() => {
        setTitle('Búsqueda');
        
    // eslint-disable-next-line
    }, []);

    function onBtnClose() {
        onClose();
    }

    function onBtnMap()  {
        /*
        if(store.data.length === 0) {
             dispatch(openMessageBox({ 
                 button: 'ok', 
                 icon: 'warning', 
                 message: 'No hay información para vizualizar.', 
                 callback: () => dispatch(closeMessageBox())
             }));
            
             return;
             
         }
          */
         dispatch(btnSearch());
           setOpen(true);  
      }

    return (
        <Page>
            <Backdrop style={{ position: 'absolute', zIndex: 1200 }} open={loading} transitionDuration={0}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Body>
                <ListadoGlobalFilter />
                <ListadoGlobalMap  />
            </Body>
            <Footer>
               
                <Button
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={() =>  onBtnMap()}
                    startIcon={<Icon className="fas fa-map-marked-alt"/>}
                >
                    Ver Mapa
                </Button>
             
                <Button 
                    color="primary" 
                    size="small" 
                    variant="outlined" 
                    onClick={() => onBtnClose()} 
                    startIcon={<Icon className="fas fa-door-open"/>}
                >
                    Salir
                </Button>
            </Footer>
            <Dialog fullScreen open={open} onClose={() => setOpen(false)}>
                <AppBar>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => setOpen(false)} aria-label="close">
                            <Icon className="fas fa-times" />
                        </IconButton>
                        <Typography variant="h6">
                           Geo State
                        </Typography>
                    </Toolbar>
                </AppBar>
                <ListarAgentesMap  />
            </Dialog>
        </Page>
    )
};

export default ListadoGlobal;