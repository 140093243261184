import React, { useEffect} from "react";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import './Graphics.css';

am4core.useTheme(am4themes_material);
am4core.useTheme(am4themes_animated);


function Gparticipacion(props) {

    useEffect(() => {
   
        var container = am4core.create("chartdiv4", am4core.Container);
    
        container.width = am4core.percent(100);
        container.height = am4core.percent(100);
        container.layout = "vertical";
    
        // Themes begin
    
        createChart(container, "COBERTURA");
    
        return () => {
          container.dispose();
        };
        // eslint-disable-next-line
      }, []);

      const createChart = (container, titleText, x) => {
       
        var chart = am4core.create("chartdiv4", am4charts.XYChart);
        chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

        chart.data = [
          {
            category: "Ola 1",
            value1: 1,
            value2: 5,
            value3: 3
          },
          {
            category: "Ola 2",
            value2: 5,
            value3: 3
          },
          {
            category: "Ola 3",
            value1: 3,
            value2: 5,
            value3: 4
          },
          {
            category: "Ola 4r",
            value1: 4,
            value2: 5,
            value3: 6
          },
          {
            category: "Ola 5",
            value1: 3,
            value2: 5,
            value3: 4
          },
          {
            category: "Ola 6",
            value1: 2,
            value2: 13,
            value3: 1
          }
        ];

        chart.colors.step = 2;
        chart.padding(30, 30, 10, 30);
        chart.legend = new am4charts.Legend();

        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "category";
        categoryAxis.renderer.grid.template.location = 0;

        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.max = 100;
        valueAxis.strictMinMax = true;
        valueAxis.calculateTotals = true;
        valueAxis.renderer.minWidth = 50;


        var series1 = chart.series.push(new am4charts.ColumnSeries());
        series1.columns.template.width = am4core.percent(80);
        series1.columns.template.tooltipText =
          "{name}: {valueY.totalPercent.formatNumber('#.00')}%";
        series1.name = "Mobil";
        series1.dataFields.categoryX = "category";
        series1.dataFields.valueY = "value1";
        series1.dataFields.valueYShow = "totalPercent";
        series1.dataItems.template.locations.categoryX = 0.5;
        series1.stacked = true;
        series1.tooltip.pointerOrientation = "vertical";
        series1.fill = am4core.color("red");

        var bullet1 = series1.bullets.push(new am4charts.LabelBullet());
        bullet1.interactionsEnabled = false;
        bullet1.label.text = "{valueY.totalPercent.formatNumber('#.00')}%";
        bullet1.label.fill = am4core.color("#ffffff");
        bullet1.locationY = 0.5;

        var series2 = chart.series.push(new am4charts.ColumnSeries());
        series2.columns.template.width = am4core.percent(80);
        series2.columns.template.tooltipText =
          "{name}: {valueY.totalPercent.formatNumber('#.00')}%";
        series2.name = "Terpel";
        series2.dataFields.categoryX = "category";
        series2.dataFields.valueY = "value2";
        series2.dataFields.valueYShow = "totalPercent";
        series2.dataItems.template.locations.categoryX = 0.5;
        series2.stacked = true;
        series2.tooltip.pointerOrientation = "vertical";
        series2.fill = am4core.color("#140c64");  

        var bullet2 = series2.bullets.push(new am4charts.LabelBullet());
        bullet2.interactionsEnabled = false;
        bullet2.label.text = "{valueY.totalPercent.formatNumber('#.00')}%";
        bullet2.locationY = 0.5;
        bullet2.label.fill = am4core.color("#ffffff");
        bullet2.label.fontSize = 11;
        

        var series3 = chart.series.push(new am4charts.ColumnSeries());
        series3.columns.template.width = am4core.percent(80);
        series3.columns.template.tooltipText =
          "{name}: {valueY.totalPercent.formatNumber('#.00')}%";
        series3.name = "Otros";
        series3.dataFields.categoryX = "category";
        series3.dataFields.valueY = "value3";
        series3.dataFields.valueYShow = "totalPercent";
        series3.dataItems.template.locations.categoryX = 0.5;
        series3.stacked = true;
        series3.tooltip.pointerOrientation = "vertical";
        series3.fill = am4core.color("grey");

        var bullet3 = series3.bullets.push(new am4charts.LabelBullet());
        bullet3.interactionsEnabled = false;
        bullet3.label.text = "{valueY.totalPercent.formatNumber('#.00')}%";
        bullet3.locationY = 0.5;
        bullet3.label.fill = am4core.color("#ffffff");

        chart.scrollbarX = new am4core.Scrollbar();
      
       };

    return (
        <div className="containerGrafica">
            <div style={{ height: 300 }} id="chartdiv4" />
        </div>
    );
}

export default Gparticipacion;