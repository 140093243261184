import React from 'react';
import clsx from 'clsx';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/profile/actions';

const useStyles = makeStyles(theme => ({
    button: {
        fontSize: '0.75rem',
        marginRight: '0.5rem',
        padding: '0.125rem 0.5rem',
        textTransform: 'none'
    },
    divider: {
        marginTop: '1rem',
        marginBottom: '1rem'
    },
    footer: {
        display: 'flex',
        flex: '0 0 auto',
        justifyContent: 'center',
        '@media (min-width: 701px)': {
            justifyContent: 'flex-start',
        }
    },
    helperText: {
        fontSize: '0.625rem',
        fontWeight: 300,
        marginBottom: '1rem',
        marginTop: '0.5rem'
    },
    image: {
        backgroundRepeat: 'no-repeat', 
        backgroundSize: 'cover',
        border: '3px solid ' + theme.palette.primary.main,
        borderRadius: 3,
        boxShadow: '0 0 13px 0 rgba(0,0,0,.1)',
        color: theme.palette.primary.main,
        fontSize: '6rem',
        height: '6rem',
        textAlign: 'center',
        width: '6rem',
        '@media (min-width: 701px)': {
            fontSize: '7.5rem',
            height: '7.5rem',
            width: '7.5rem'
        }
    },
    imageButton: {
        cursor: 'pointer', 
        display: 'flex', 
        '-webkit-box-align': 'center', 
        alignItems: 'center',
        position: 'absolute', 
        right: -10, 
        top: -10, 
        width: 30, 
        height: 30, 
        borderRadius: '50%', 
        backgroundColor: theme.palette.primary.main, 
        justifyContent: 'center'
    },
    imageClose: {
        cursor: 'pointer', 
        display: 'flex', 
        '-webkit-box-align': 'center', 
        alignItems: 'center',
        position: 'absolute', 
        right: -10, 
        bottom: -10, 
        width: 30, 
        height: 30, 
        borderRadius: '50%', 
        backgroundColor: '#fff', 
        justifyContent: 'center'
    },
    imageIcon: {
        color: theme.palette.primary.contrastText,
        fontSize: '0.9rem'
    },
    imageInput: {
        height: 0, 
        opacity: 0,
        overflow: 'hidden',
        width: 0
    },
    imageWrap: {
        display: 'inline-block',
        position: 'relative'
    },
    link: {
        cursor: 'pointer', 
        fontSize: '0.75rem'
    },
    root: {
        flex: '1 1 auto',
        padding: '1rem'
    },
    title: {
        fontSize: '0.875rem',
        fontWeight: 500,
        
        '&:first-child': {
            marginBottom: '1.5rem',
        }
    }
}));

const ProfileForm = ({ entity, errors, form, btnSave, cancelForm, onUpdateFieldForm, removeImage, setImage }) => {
    const classes = useStyles();
    const matches = useMediaQuery('(min-width:701px)');

    function handleImageChange(e) {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            setImage(file, reader.result);
        }

        reader.readAsDataURL(file);
    }

    function onFieldChange(event) {
        onUpdateFieldForm(event.target.name, event.target.value);
    }

    return (
        <div className={classes.root}>
            <div className={classes.title}>Información</div>
            <div style={{ textAlign: matches ? 'left' : 'center' }}>
                <div className={classes.imageWrap}>
                    {form.imagen === '' ? (<Icon className={clsx("fas fa-user", classes.image)} />) : 
                        (<div className={classes.image} style={{ backgroundImage: `url(${form.imagen})`}}/>)}
                    <label className={classes.imageButton}>
                        <Icon className={clsx("fas fa-pen", classes.imageIcon)} />
                        <input className={classes.imageInput} type="file" name="profile_avatar" accept=".png, .jpg, .jpeg" onChange={handleImageChange} />
                    </label>
                </div>
                {form.imagen !== '' && (<div>
                    <Link className={classes.link} onClick={removeImage}>
                        Quitar
                    </Link>
                </div>)}
                <div className={classes.helperText}>Seleccione una imagen (tamaño máximo de 200 KB).</div>
            </div>
            <Grid container spacing={2} >
                <Grid item xs={12} container>
                    <Grid item xs={matches ? 4 : 12}>
                        <TextField
                            label="Identificador"
                            value={entity.usuarioId}
                            variant={matches ? "outlined" : "standard"}
                            fullWidth
                            inputProps={{
                                readOnly: true
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} container>
                    <Grid item xs={matches ? 4 : 12}>
                        <TextField
                            autoComplete="off"
                            error={errors.nombre !== ''}
                            fullWidth
                            helperText={errors.nombre}
                            label="Nombres"
                            name="nombre"
                            onChange={onFieldChange}
                            required
                            value={form.nombre}
                            variant={matches ? "outlined" : "standard"}
                            inputProps={{
                                maxLength: 50
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} container>
                    <Grid item xs={matches ? 4 : 12}>
                        <TextField
                            autoComplete="off"
                            error={errors.correo !== ''}
                            fullWidth
                            helperText={errors.correo}
                            label="Correo"
                            name="correo"
                            onChange={onFieldChange}
                            required
                            value={form.correo}
                            variant={matches ? "outlined" : "standard"}
                            inputProps={{
                                maxLength: 256
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} container>
                    <Grid item xs={matches ? 4 : 12}>
                        <TextField
                            autoComplete="off"
                            fullWidth
                            label="Documento Identidad"
                            name="documentoIdentidad"
                            onChange={onFieldChange}
                            required
                            value={form.documentoIdentidad}
                            variant={matches ? "outlined" : "standard"}
                            inputProps={{
                                maxLength: 20
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} container>
                    <Grid item xs={matches ? 4 : 12}>
                        <TextField
                            autoComplete="off"
                            fullWidth
                            label="Teléfono"
                            name="telefono"
                            onChange={onFieldChange}
                            required
                            value={form.telefono}
                            variant={matches ? "outlined" : "standard"}
                            inputProps={{
                                maxLength: 16
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Divider className={classes.divider}/>
            <div className={classes.title}>Distribuidores asignados</div>
            <List dense>
                {entity.distribuidores.map((rol, key) => (
                    <ListItem key={key}>
                        <ListItemText primary={rol.razonSocial}/>
                    </ListItem>
                ))}
            </List>
            <Divider className={classes.divider}/>
            <div className={classes.title}>Aplicaciones Permitidas</div>
            <List dense>
                {entity.aplicaciones.map((aplicacion, key) => (
                    <ListItem key={key}>
                        <ListItemText primary={aplicacion.nombre}/>
                    </ListItem>
                ))}
            </List>
            <Divider className={classes.divider}/>
            <div className={classes.footer}>
                <Button className={classes.button} color="primary" size="small" variant="contained" onClick={() => btnSave()}>
                    Actualizar
                </Button>
                <Button className={classes.button} size="small" variant="contained" onClick={() => cancelForm()}>
                    Cancelar
                </Button>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    entity: state.profile.entity,
    errors: state.profile.errors,
    form: state.profile.form
});
  
export default connect(
    mapStateToProps,
    dispatch => bindActionCreators({...actionCreators}, dispatch)
)(ProfileForm);