export const ERROR_R_LIST = 'gigantes/resumen/error-r-list';
export const RECEIVE_R_LIST = 'gigantes/resumen/receive-reportepedidos-list';
export const  RECEIVE_R_DET = 'gigantes/resumen/receive-reportepedidos-det';
export const REQUEST_R_LIST = 'gigantes/resumen/request-reportepedidos-list';
export const RESTART_STATE = 'gigantes/resumen/restart_state';
export const SET_MASK = 'gigantes/resumen/set-mask';
export const SET_ORDER = 'gigantes/resumen/set-order';
export const SET_SELECTED_ROW = 'gigantes/resumen/set-selected-row';
export const UPDATE_FIELD_FILTER = 'gigantes/resumen/update-field-filter';
export const UPDATE_TABLE_COLUMN = 'gigantes/resumen/set-selected-row';
export const UPDATE_FIELD_DATA = 'gigantes/resumen/update-field-data';
