import {
    CLEAN_FORM,
    SET_STATE_FORM,
    UPDATE_FIELD_FILTER,
    SET_MASK,
    RECEIVE_DATA,
    UPDATE_TABLE_COLUMN,
    ERROR_V_LIST,
    RECEIVE_V_LIST,
    RECEIVE_X_LIST,
    RECEIVE_W_LIST,
    REQUEST_V_LIST,
    ON_SUCURSAL,
    REQUEST_X_LIST
} from './types';

const initialState = {
    formState: 'search',
    loading: false,
    entity: {
        vendedorId: 0,
        nombre: ''
    },
    errors: {
        codigo: '',
        usuario: '',
        documentoIdentidad: ''
    },
    filters: {
        nombre: '',
        distribuidorId: 0,
        dDistribuidor: '',
        codigo: '',
        valor: 0
    },
    store: {
        loading: false,
        pageSize: 50,
        start: 0,
        total: 0,
        data: [],
        data2: [],
        data3: [],
        data4: [],// para enviar el json solo los nit en un string
        data5: [],// para data de respuesta 
        request: [],
        selected: [],
        selected5: [],
        sucursales: []
        //source: null
    },
    table: {
        order: 'asc',
        orderBy: '',
        columns: [  
            { dataKey:   "codRuta",  label:  "codRuta" , width: 100 },
            { dataKey:   "nitCliente", label:  "nitCliente" , width: 100 },
            { dataKey:   "resultado" , label:  "resultado"  , width: 200 },
            { dataKey:  "frecuecia" , label:   "frecuencia"  , width: 100 },
            { dataKey:   "vLunes" , label:   "lunes"   , width: 100 },
            { dataKey:   "vMartes"  , label:  "martes"   , width: 100 },
            { dataKey:   "vMiercoles" , label:   "miercoles"   , width: 100 },
            { dataKey:   "vJueves",  label:  "jueves"    , width: 100 },
            { dataKey:   "vViernes" ,   label:  "viernes"  , width: 100 },
            { dataKey:   "vSabado"   ,  label: "sabado"     , width: 100 },
            { dataKey:   "vDomingo"    ,    label: "domingo"  , width: 100 },
        ]
    },
    table2: {
        order: 'asc',
        orderBy: '',
        columns: [  
            // { dataKey:   "row_num"   ,  label: "Item"     , width: 100 },
            { dataKey:   "nit",  label:  "NIT" , width: 80 },
            { dataKey:   "nombreComercial" , label:   "NombreComercial"  , width: 150 },           
            { dataKey:   "nombreSucursal" ,   label:  "nombreSucursal"  , width: 100 },
             { dataKey:   "codSucursal" , label:  "codSucursal"  , width: 80 },
            { dataKey:   "direccion" , label:   "Dirección"   , width: 150 },
            { dataKey:   "fechaRegistro"  , label:  "FechaRegistro"   , width: 100 },
            { dataKey:   "ruta" , label:   "Ruta"   , width: 100 },
            { dataKey:   "vendedor",  label:  "Vendedor"    , width: 150 },
        ]
    }
};

export const cargamasivarutasReducer = (state, action) => {
    state = state || initialState;

    switch(action.type) {
        case CLEAN_FORM:
            return {
                ...state,
                entity: initialState.entity,
                errors: initialState.errors
            };
        case SET_STATE_FORM:
            return {
                ...state,
                formState: action.formState
            };
        case UPDATE_FIELD_FILTER:
            return { 
                ...state,
                filters: {
                    ...state.filters,
                    [action.key]: action.value
                }
            };
            case ERROR_V_LIST:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: false
                }
            };
            case RECEIVE_V_LIST:
                return {
                    ...state,
                    store: {
                        ...state.store,
                        loading: false,
                        total: action.total,
                        data: action.payload,
                       // data2: [],
                        source: null
                    }
                    // ,
                    // table: {
                    //     ...state.table,
                    //     columns: action.columns,
                    // }
                };
                case RECEIVE_X_LIST:
                    return {
                        ...state,
                        store: {
                            ...state.store,
                            loading: false,
                            total: action.total,
                            data3: action.payload,
                            data4: action.key,
                            source: null
                        }
                        // ,
                        // table: {
                        //     ...state.table,
                        //     columns: action.columns,
                        // }
                    };
            case RECEIVE_W_LIST:
                    return {
                        ...state,
                        store: {
                            ...state.store,
                            loading: false,
                            total: action.total,
                            data2: action.payload,
                            source: null
                        }
            
                };
            case REQUEST_V_LIST:
                return {
                    ...state,
                    store: {
                        ...state.store,
                        loading: false,
                        source: action.source
                    }
                };
            case RECEIVE_DATA:
                return {
                    ...state,
                    data: action.payload,
                    store: {
                        ...state.store,
                        data: action.storeData
                    }
                };
                case REQUEST_X_LIST:
                return {
                    ...state,
                    store: {
                        ...state.store,
                        data5: action.payload
                    }
                };
            case UPDATE_TABLE_COLUMN:
                    return {
                        ...state,
                        table: {
                            ...state.table,
                            columns: action.columns
                        }
                    };
            case SET_MASK:
                return {
                    ...state,
                    loading: action.loading
            };
            case ON_SUCURSAL:
                return {
                    ...state,
                    store: {
                        ...state.store,
                        sucursales: action.payload
                        
                    }
                }
        default:
            return state;
    }
};