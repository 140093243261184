import React, { useEffect } from 'react';
import { useDispatch} from 'react-redux';
import {
    //btnExport,
    btnSearch,
    //closeDialogClient,
   /// filterMapByVendedor
} from '../../store/channelmap/reportecobertura/actions';


import { Body, Footer, Page } from '@sigeco/tools';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';


import ReporteCoberturaFilter from '../../components/channelmap/reportecobertura/ReporteCoberturaFilter';
import ReporteCoberturaPanel from '../../components/channelmap/reportecobertura/ReporteCoberturaPanel';

const ReporteCobertura = ({onClose, setTitle}) => {
    //const loading = useSelector(state => state.reporteparticipacion.loading);
    const dispatch = useDispatch();

    useEffect(() => {
        setTitle('Listado de Coberturas');
    // eslint-disable-next-line
    }, []);

    function onBtnClose() {
        onClose();
    }

    return (
        <Page>
            <Backdrop style={{ position: 'absolute', zIndex: 1200 }} open={false} transitionDuration={0}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <Body>
            <ReporteCoberturaFilter />
            <ReporteCoberturaPanel />
            
        </Body>
        <Footer>
            <Button
                color="primary"
                size="small"
                variant="outlined"
                //onClick={() => dispatch(onExport())}>
                startIcon={<Icon className="fas fa-file-excel"/>}
            >
                Exportar
            </Button>
            <Button
                color="primary"
                size="small"
                variant="outlined"
                onClick={() => dispatch(btnSearch())}
                startIcon={<Icon className="fas fa-search"/>}
            >
                Buscar
            </Button>
            <Button 
                color="primary" 
                size="small" 
                variant="outlined" 
                onClick={() => onBtnClose()} 
                startIcon={<Icon className="fas fa-door-open"/>}
            >
                Salir
            </Button>
        </Footer>
        </Page>
    )
};

export default ReporteCobertura;