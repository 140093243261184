import React from 'react';
import {  useSelector } from 'react-redux';

import { FieldSet  } from '@sigeco/tools';
import makeStyles from '@material-ui/core/styles/makeStyles';


const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        overflow: 'hidden'
    }, 
    tableWrap: {
        flex: '1 1 auto',
        overflow: 'hidden'
    },

    columna: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, 230px)',
        justifyContent: 'space-around',
        columnGap:"10px " ,
        paddingBottom: '0px',
        
    }
}));

const ReporteDeIncidenciasListDetail = () => {
    const classes = useStyles(); 
    const store = useSelector(state => state.reportedeincidencias.storeDetalle.data);
    const imagenes = []
    const a = store.map(dat => dat.UriFoto01)
    const b = store.map(dat => dat.UriFoto02)
    const foto1 = a.filter(b => b!=='')
    const foto2 = b.filter(b => b!=='')
    imagenes.push(foto1)
    imagenes.push(foto2)

       
    return (
        <FieldSet className={classes.root} iconCls="fas fa-th-list" title="Resultados">
            
            <div className={classes.tableWrap} >

            <div className={classes.columna} style ={{width:"120%"}}>    

            { imagenes.map((image, index)=>( image.map((i,x) =>

                <div style={{width: "300% !important",}}> {/*style={{width: "50%", paddingRight:"-70px !important"}}*/} {/*marginLeft:"20px",paddingRight:"20px", */}
                    
                    <div id='employee'  style={{ width:"250px",marginLeft:"410px", marginInline:"400px !important",
                            display: 'inline-block', border: "7px solid rgba(224, 224, 224, 1)", borderRadius:'10px',
                            backgroundColor:"rgba(224, 224, 224, 1)", marginTop:"20px", height:"500px"}}>

                        <img  alt = 'uno'  key={x} src={i} style={{width:"95%", paddingLeft:"7px", paddingRight:"85px",height:"450px",maxHeight:"550px", marginTop:"10px" }} />
                            
                            <b><p  style={{marginTop:"10px",fontSize: "20px", color:"black", marginLeft:"80px"}} >FOTO N° { `${index+1}`}</p></b>
                    </div>

                        <p></p>

                </div>               
            )
            ))}

              </div>
            </div>
        </FieldSet>
    )
};

export default ReporteDeIncidenciasListDetail;