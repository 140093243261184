import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import _ from 'lodash';
import {  conCat} from '../../../helpers/commons'

export const ReporteSeguimientoVendedorMap = ({ google }) => {
    const [item, setItem] = useState({});
    const [marker, setMarker] = useState({});
    const [showingInfo, setShowingInfo] = useState(false);
    const coords = { lat: -8.116291741338895, lng: -79.03640628442541 };
    const store = useSelector(state => state.reporteseguimientovendedor.store);   
    var geo =  store.data.filter(number =>  number.Longitud !== "" && number.Latitud !== ""   && number.Longitud !== null && number.Latitud !== null); 

    geo =  geo.filter(number =>  !isNaN( parseFloat(number.longitud))  && !isNaN(number.latitud )); 
    const onMarkerClick = (marker, item) => {
        setItem(item);
        setMarker(marker);
        setShowingInfo(true);
    };

    const getData = () => {
        return _(store.data)
            .orderBy('orden')
            .groupBy('Nombre')
            .map((item, key) => ({
                'Nombre': key
            }))
            .value()
    }

    conCat(getData());
   
    return (
        <Map
            initialCenter={ geo.length > 0 ? { lat: parseFloat(geo[0].latitud), lng: parseFloat(geo[0].longitud) }  :  coords } 
            google={google}
            style={{width: '100%', height: '100%', position: 'relative'}}
            zoom={8}
        >
            {geo.map((item, key) => (       
                <Marker
                    key={key}
                    position={{lat: parseFloat(item.latitud), lng: parseFloat(item.longitud)}}
                    title={item.nombre          }
                    onClick={(props, marker, e) => onMarkerClick(marker, item)}
                    label= {{text:`${ item.tipo === 'CLIENTE' ? 'C' : 'P' }` }}  
                    icon={{
                        url: item.tipo === 'CLIENTE' ? `http://maps.google.com/mapfiles/ms/icons/red.png` : 
                        `http://maps.google.com/mapfiles/ms/icons/orange.png`,
                        scaledSize:   item.tipo === 'CLIENTE'  ?   new google.maps.Size(22, 36):  new google.maps.Size(36, 36) ,
                        labelOrigin: new google.maps.Point(11,10)
                    }}
                    /* 

                    icon={ item.OlaChannelMapId === 0 ? no :cualUrl(item.Vendedor, getData())}
                    label= {{text:`${item.vendedor !== null || item.vendedor !== undefined
                        ? item.vendedor : ''}`, color: "blue", fontsize: '50'}}  
                    */   
                />
            ))}
            <InfoWindow
                marker={marker}
                visible={showingInfo}
            >
                <div>
                    <h3>{item.nombre}</h3>
                    <h3>V-{item.vendedor}</h3>
                </div>
            </InfoWindow>
        </Map>
    )
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyCrwct6vhZk5TTSuht7PpA0kAVSdoWSB7s"
})(ReporteSeguimientoVendedorMap);