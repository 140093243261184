import {
    RESTART_STATE,
    SET_MASK,
    UPDATE_FIELD_FILTER,
    RECEIVE_V_LIST
} from './types';

const initialState = {
    loading: false,
    filters: {
        distribuidorId: 0,
        dDistribuidor: '',
        desde: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        hasta: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        
    },
    store: {
        loading: false,
        pageSize: 50,
        start: 0,
        total: 0,
        data: [],
        selected: [],
        //source: null
    },
    table: {
        order: 'asc',
        orderBy: '',
        columns: [

                  { dataKey: 'Distribuidor', label: 'Distribuidor', width: 140 },
                  { dataKey: 'Codigo', label: 'Codigo de Pedido', width: 370 },
                  { dataKey: 'CodigoOrigen', label: 'Codigo de Origen', width: 140 },
                  { dataKey: 'Nit', label: '[NIT/RUC]', width: 140 },
                  { dataKey: 'CodigoProducto', label: 'Codigo de Producto', width: 140 },
                  { dataKey: 'Producto', label: 'Producto', width: 400 },
                  { dataKey: 'PrecioVenta', label: 'Precio de Venta', width: 140 },
                  { dataKey: 'Bonificacion', label: 'Bonificacion',  width: 140 },
                  { dataKey: 'Vendedor', label: 'Vendedor',  width: 140 },
                  { dataKey: 'Cliente', label: 'Cliente', width: 152, },
                  { dataKey: 'Observacion', label: 'Observacion', width: 140 },
                  { dataKey: 'Direccion', label: 'Direccion', width: 140 },
                  { dataKey: 'Fecha', label: 'Fecha', width: 140 },
        ]
    }
};

export const detallespedidoReducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case RESTART_STATE:
            return {
                ...initialState
            };
        case SET_MASK:
            return {
                ...state,
                loading: action.loading
        };
        case UPDATE_FIELD_FILTER:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.key]: action.value
                }
            };

        case RECEIVE_V_LIST:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: false,
                    total: action.total,
                    data: action.payload,
                    source: null
                },
                table: {
                    ...state.table,
                    columns: action.columns,
                }
            };

        default:
            return state;
    }
}