import React , { useState, useEffect} from 'react'; //useRef, 
import {  useSelector } from 'react-redux';
import  { useReactToPrint } from 'react-to-print';//ReactToPrint,
import PdfPresupuesto from './PdfPresupuesto'
import PdfCobertura from './PdfCobertura'
import PdfEstrategicoSuper   from './PdfEstrategicoSuper'
import PdfEstrategicMonogrado from './PdfEstrategicoMonogrado'
import PdfLineaEstrategica2022 from './2022/PdfLineaEstrategica2022'
import PdfCrecimiento2022   from './2022/PdfCrecimiento2022'
import PdfPresupuesto2022   from './2022/PdfPresupuesto2022'

import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

const PdfComponent = () => {

    const comple = () => {
      if (data.length === 0  && data){
            alert('No existe Data Para descargar')
            return
      }
      setShow(true)
        setTimeout(function(){  }
      , 1000);

      handlePrint();
      setTimeout(function(){  }
    , 1000);
      setShow(false)
    }
        const handlePrint =  useReactToPrint({
            content: () =>   ref.current, 
        });

        
      const ref = React.createRef();

        const store = useSelector(state => state.resumen.store);
        const anio =   useSelector(state => state.resumen.filters.desde.getFullYear());  
 
        const [data, setData] = useState([]);
        const [nuevos, setNuevos] = useState([]);
        const [show, setShow] = useState(false);
     
        useEffect(() => {
            setData(store.presupuesto)
            setNuevos(store.clientesnuevos)
        }, [data, store.clientesnuevos, store.presupuesto], [nuevos, store.clientesnuevos])
    
        return (
          <>     
            <div className="App" style={{height: '0px', display: show ? 'block' : 'none' }}>
            
              <div ref={ref}  style={{display: anio <= 2021 ? 'block' : 'none' }}>
               
                <PdfPresupuesto items={data}  />  
                <PdfCobertura items={nuevos}  />    
                <PdfEstrategicoSuper items={data}  />   
                < PdfEstrategicMonogrado  items={data}  />
              </div>

              <div ref={ref}  style={{display: anio === 2022  ? 'block' : 'none', height: '0px' }}>
              
                <div   style={{    width: "800px",  height: "1300px"   }}  >
                <img alt='logo'  src={ './images/GM2022v4.png'}  style={{width: '1000px', height:'1300px'}}/>
             
                </div>
                <div   style={{    width: "800px",  height: "1300px"   }}  >
                <img alt='logo'  src={ './images/Diapositiva2.PNG'}  style={{width: '1000px', height:'1300px'}}/>
                
                </div>
               
                <PdfPresupuesto2022 items={data} />    
                <PdfLineaEstrategica2022 items={store.estmonogrado } />   
                <PdfCrecimiento2022 />      
              </div>

         

                {/*
                  <Pdf targetRef={ref} filename="winners.pdf"   onComplete={comple()}>
                    {({ toPdf }) => <button onClick={toPdf}>Descargar Pdf</button>}
                  </Pdf>

                */}            
            </div>


                   <Button 
                color="primary" 
                size="small" 
                variant="outlined" 
                onClick={ comple} 
                startIcon={<Icon className="fas fa fa-file-pdf"/>}
                style={{position: 'fixed',
                  bottom: '0',
                  display: 'flex',
                  flexdirection: 'column',
                 }}
            >    
              Descargar pdf
            </Button>

        </>
        );
};
 
export default PdfComponent;