import axios from 'axios';
import { IdentityUrl } from '../../config';

import {
    ADD_OPTION_NAV,
    ADVANCED_SEARCH_CLOSE,
    ADVANCED_SEARCH_OPEN,
    LOAD_FINISHED,
    MESSAGE_BOX_CLOSE,
    MESSAGE_BOX_OPEN,
    RECEIVE_OPTIONS_LIST,
    RECEIVE_RESOURCES_LIST,
    SET_OPEN_MENU,
    SET_OPEN_SUBMENU,
    SET_OPTION
} from './types';


export const addOptionNav = (optionId, text, path, icon, access) => (dispatch, getState) => {
    let navs = getState().main.navs;
    
    if(!navs.filter(n => n.path === path)[0]) {
        dispatch({ type: ADD_OPTION_NAV, option: {
            optionId: optionId,
            name: text,
            path: path,
            icon: icon,
            access: access || []
        }});
    }

    dispatch({ type: SET_OPTION, path });
    dispatch(setTitle());
};

export const closeAdvancedSearch = () => ({ type: ADVANCED_SEARCH_CLOSE });

export const closeMessageBox = () => ({ type: MESSAGE_BOX_CLOSE });

export const getOptions = () => async (dispatch) => {
    try {
        let url = `${IdentityUrl}/api/v1/account/opciones`;
        let response = await axios.get(url);
        let data = response.data;

        dispatch({ type: RECEIVE_OPTIONS_LIST, options: data });
        dispatch(getResources());
    } catch (error) {
        console.log(`Error Opciones: ${error}`);
    }
};

export const getResources = () => async (dispatch) => {
    try {
        let url = `${IdentityUrl}/api/v1/account/recursos`;
        let response = await axios.get(url);
        let data = response.data;

        let recursos = {};

        data.forEach(recurso => {
            recursos = {
                ...recursos,
                [recurso.nombreHost]: recurso.uriHost
            }
        });

        dispatch({ type: RECEIVE_RESOURCES_LIST, payload: recursos });
        dispatch({ type: LOAD_FINISHED });
    } catch (error) {
        console.log(`Error Recursos: ${error}`);
    }
};

export const openAdvancedSearch = (opts) =>(dispatch) => { 
    dispatch( { type: ADVANCED_SEARCH_OPEN , payload : opts })
   
};

export const openMessageBox = (opts) => ({ type: MESSAGE_BOX_OPEN, payload: opts });

export const setOpenMenu = (open) => ({ type: SET_OPEN_MENU, open });

export const setOpenSubmenu = (open) => ({ type: SET_OPEN_SUBMENU, open });

export const setOption = (path) => (dispatch) => {
    dispatch({ type: SET_OPTION, path });
    dispatch(setTitle());
};

export const setTitle = () => (dispatch, getState) => {
    let dView = '';
    let vistas = getState().main.navs.filter(m => m.path === getState().main.path);

    if(vistas.length > 0) {
      dView = vistas[0].name + ' - ';
    }

    document.title = `${dView}Sales Mobility Tool`;
};