import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { useLocation, useRouteMatch } from "react-router-dom";
import {
    btnSearch,
    btnExport,
    setFormState
} from '../../store/fuerzaventas/vendedor/actions';

import { Body, Footer, Page } from '@sigeco/tools';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';

import VendedorFilter from '../../components/fuerzaventas/vendedor/VendedorFilter';
import VendedorList from '../../components/fuerzaventas/vendedor/VendedorList';

const Vendedor = ({onClose, setTitle}) => {
    const location = useLocation();
    const match = useRouteMatch();
    const formState = useSelector(state => state.vendedor.formState);
    const loading = useSelector(state => state.vendedor.loading);
    const vendedorId = useSelector(state => state.vendedor.entity.vendedorId);
    const dispatch = useDispatch();

    useEffect(() => {
        switch(match.path) {
          case '/fuerzaventas/vendedor/crear':
            setTitle('Crear Vendedor');
            if(formState !== 'new') {
                dispatch(setFormState('new'));
            }
            break;
          case '/fuerzaventas/vendedor/:id':
            dispatch(setFormState('view', match.params['id']));
            setTitle('Visualizar Vendedor');
            break;
          case '/fuerzaventas/vendedor/:id/editar':
            dispatch(setFormState('edit', match.params['id']));
            setTitle('Editar Vendedor');
            break;
          default:
            dispatch(setFormState('search'));
            setTitle('Búsqueda');
            break;
        }
      // eslint-disable-next-line
    }, [match.path]);

    function onBtnClose() {
        onClose();
    }

    return (
        <Page>
            <Backdrop style={{ position: 'absolute', zIndex: 1200 }} open={loading} transitionDuration={0}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <Body>
            {formState === 'search' && <VendedorFilter />}
            {formState === 'search' && <VendedorList />}
        </Body>
        <Footer>
            {formState === 'search' && <Button
                color="primary" 
                size="small" 
                variant="outlined" 
                onClick={() => dispatch(btnExport())}
                startIcon={<Icon className="fas fa-file-excel"/>}
            >
                Exportar
            </Button>}
            {formState === 'search' && <Button
                color="primary" 
                size="small" 
                variant="outlined"
                onClick={() => dispatch(btnSearch())}
                startIcon={<Icon className="fas fa-search"/>}
            >
                Buscar
            </Button>}
            { /*
            {(formState === 'search' || formState === 'view') && <LinkContainer to={{ pathname: '/fuerzaventas/vendedor/crear', search: location.search, hash: location.hash }}>
                <Button
                    color="primary" 
                    size="small" 
                    variant="outlined"
                    startIcon={<Icon className="fas fa-file"/>}
                >
                    Crear
                </Button>
            </LinkContainer>}
            */ }
            {formState === 'view' && <LinkContainer to={{ pathname: `/fuerzaventas/vendedor/${vendedorId}/editar`, search: location.search, hash: location.hash }}>
                <Button
                    color="primary" 
                    size="small" 
                    variant="outlined"
                    startIcon={<Icon className="fas fa-edit"/>}
                >
                    Modificar
                </Button>
            </LinkContainer>}
            {formState === 'view' && <Button 
                color="primary" 
                size="small" 
                variant="outlined" 
                //onClick={() => dispatch(btnDelete())}
                startIcon={<Icon className="fas fa-trash"/>}
            >
                Eliminar
            </Button>}
            {formState !== 'search' && formState !== 'view' && <Button
                color="primary" 
                size="small" 
                variant="outlined" 
                //onClick={() => dispatch(btnSave())}
                startIcon={<Icon className="fas fa-save"/>}
            >
                Guardar
            </Button>}
            {formState !== 'search' && <LinkContainer to={{ pathname: '/fuerzaventas/vendedor', search: location.search, hash: location.hash }}>
                <Button
                    color="primary" 
                    size="small" 
                    variant="outlined"
                    startIcon={<Icon className="fas fa-times-circle"/>}
                >
                    Cancelar
                </Button>
            </LinkContainer>}
            <Button 
            color="primary" 
            size="small" 
            variant="outlined" 
            onClick={() => onBtnClose()} 
            startIcon={<Icon className="fas fa-door-open"/>}
            >
            Salir
            </Button>
        </Footer>
        </Page>
    )
};

export default Vendedor