import { config } from 'dotenv';

config({
    path: `../.env.${process.env.NODE_ENV}`
});

export const ClientId = process.env.REACT_APP_CLIENT_ID;
export const IdentityUrl = process.env.REACT_APP_IDENTITY_URL;
export const RedirectPath = process.env.REACT_APP_REDIRECT_PATH;
export const PromoterUrl = process.env.REACT_APP_PROMOTER_URL;
//em config para titulos channel map
export const topTitle = 15;
export const aligTitle = 'center';
export const sizeTitle = 22;
export const topSubTitle = 5;
export const aligSubTitle = 'center';
export const sizeSubTitle = 18;