import React, { useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    btnSearch,
    onImport  
} from '../../store/clientes/cargamasivaclientes/actions';

import { Body, Footer, Page } from '@sigeco/tools';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';

import CargaMasivaClientesFilter from '../../components/clientes/cargamasivaclientes/CargaMasivaClientesFilter';
import CargaMasivaClientesList from '../../components/clientes/cargamasivaclientes/CargaMasivaClientesList'; 

const CargaMasivaClientes = ({onClose, setTitle}) => {
    const loading = useSelector(state => state.listarproductos.loading);
    const dispatch = useDispatch();
    
    useEffect(() => {
        setTitle('Búsqueda');
    // eslint-disable-next-line
    }, []);

    function onBtnClose() {
        onClose();
    }

    return (
        <Page>
            <Backdrop style={{ position: 'absolute', zIndex: 1200 }} open={loading} transitionDuration={0}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Body>
                <CargaMasivaClientesFilter />
                <CargaMasivaClientesList />
            </Body>
            <Footer>
                <Button
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={() => dispatch(onImport())}
                    startIcon={<Icon className="fas fa-file-excel"/>}
                  >
                    Importar Clientes
                </Button>
              
                <Button
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={() => dispatch(btnSearch())}
                    startIcon={<Icon className="fas fa-search"/>}
                >
                    Buscar
                </Button>
                <Button 
                    color="primary" 
                    size="small" 
                    variant="outlined" 
                    onClick={() => onBtnClose()} 
                    startIcon={<Icon className="fas fa-door-open"/>}
                >
                    Salir
                </Button>
            </Footer>
        </Page>
    )
};

export default CargaMasivaClientes;