import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    RECEIVE_V_LIST2,
    SET_MASK,
  } from '../../../store/fuerzaventas/costosproductos/types'

import './table.css'

import { FieldSet} from '@sigeco/tools';
import makeStyles from '@material-ui/core/styles/makeStyles';
import * as XLSX from "xlsx";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        width: '120%'
    },
    progress: {
        height: '2rem',
        width: '2rem',
        '@media (min-width: 701px)': {
            height: '2.5rem',
            width: '2.5rem',
        },
    },
        content: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: 'calc(50% - 20px)',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    tableWrap: {
        flex: '1 1 auto'
    }
}));

const CostoProductosImportar = () => {
    const classes = useStyles();
    const loading = useSelector(state => state.costosproductos.loading);
    const store = useSelector(state => state.costosproductos.stored);

    const dispatch = useDispatch();
      const [data, setData] = useState([]);
      const [file, setFile] = useState([]);
      const [head, setHead] = useState([]);

      console.log(file)

      useEffect(() => {
        setData( store.data)
       //onUpdateFieldUpdate(data, [])
       if (store.data.length > 0 ){
         // dispatch({ type: RECEIVE_W_LIST, columns: [], payload: [] }); 
      }
        return () => {
         // ChatAPI.unsubscribeFromFriendStatus(props.friend.id, handleStatusChange);
             setData( [])
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ store.data]);

      function  readExcel (par)  { //=>

            dispatch({ type: SET_MASK, loading: true });
            console.log(par)
     
            if (par === '' || par === undefined){
                    setFile('')
                    return
            }
       
            dispatch({ type: RECEIVE_V_LIST2, columns: [], payload: [] });
              //const ext = file.split('.').pop();
            const allowedExtensions = /(.xls|.xlsx)$/i;
            if(!allowedExtensions.test(par.name)){
                   alert('USE EL FORMATO CORRECTO PARA LA CARGA');
                   return
            }else{

                const promise = new Promise((resolve, reject) => {
                    const fileReader = new FileReader();
                    fileReader.readAsArrayBuffer(par);

                    fileReader.onload = (e) => {
                    const bufferArray = e.target.result;

                    const wb = XLSX.read(bufferArray, { type: "buffer" });

                    const wsname = wb.SheetNames[0];
                    
                    const ws = wb.Sheets[wsname];
                    console.log(ws)

                    const data = XLSX.utils.sheet_to_json(ws);
                    console.log('----------------')
                    console.log(data)

                    var wbcols = [
                        {wpx:149},
                        {wpx:200}
                    ];
                    
                    // let userStr = JSON.parse(JSON.stringify(data[0])); //DATA Y KEY EN POS 0
                    // console.log(userStr);
                    // KEYS
                   // console.log( Object.keys(data[0]))

                    var local = [];

                    setHead (head.push(Object.keys(data[0])));

                    let leng = head.length -1
 
                    
                    if(head.length > 1){
                        let head2 = []
                        head2.push(head[leng])
                    
                        head2.map((item, i) => {
                            for (var i = 0; i < item.length; i++) {
                                //Para obtener el objeto de tu lista
                                var hotel2 = item[i];
                                local.push(hotel2);
                            }
                        })
                    
                    }else{             
                    

                        // eslint-disable-next-line array-callback-return
                        head.map((item, j)=> {
                            for (var i=0; i< item.length; i++)
                            {
                                //Para obtener el objeto de tu lista
                                var hotel = item[i];
                                local.push(hotel);
                            }
                        })
                    }
                        setHead(local);
                        console.log(head)

                        resolve(data);
                        setFile(data);
                    };

                    fileReader.onerror = (error) => {
                                            reject(error);
                    };
                });
            
                promise.then((d) => {
                //    if (d.length > 0  || d){
                    console.log(d)
                    console.log( Object.keys(d[0]))
                    dispatch({ type: RECEIVE_V_LIST2, columns: Object.keys(d[0]), payload: d });
                    setTimeout(() => {
                        console.log("1 Segundo esperado")
                         dispatch({ type: SET_MASK, loading: false });
                    
                      }, 2000);
                // }
                 
                });
            }    
        };

        // const addHead = (par) => {
        //     setHead([...head, par])
        // }

        function HeadTable() {
            if (head.length > 0 ){

               return head.map((key, index) => {
                    return <th key={index} style={{ backgroundColor: 'lightsteelblue',  height:'100%', textAlign: 'center' , width: '1000px', 
                    wordwrap: 'break-word' }}>{key} </th>
                })
            } else{
                return null
            }
        };


    return (
        <>
         <div  style={{backgroundColor: 'white', width: '120%'}} >
            <span style={{fontSize: '10px' , marginTop: '25px',  marginLeft: '10px'}}> Importar Costos Productos</span>
                < div   style={{display: loading ? 'block': 'none' }}>
                    <div className={classes.content}>
                        {/* <CircularProgress size='' className={classes.progress} color='secondary' /> */}
                    </div>
                </div>

            <Grid item xs  style={{ marginTop: '15px'}}>
            <FormControl className={classes.filterClear}>
            <label style={{marginLeft:'20px' ,background:'#FF1100', color: 'white', borderRadius: '5px', width:'140px', height:'30px', display:false }} disable = 'true'
                > <i className={"fa fa-upload"} style={{marginTop:'8px', width:"20px", height:"20px",marginLeft:'15px', color : 'white' , marginRight: '5px'}} />Subir Archivo
                <input type="file"
                    style={{opacity : 0}}
                    onChange={(e) => {
                        const archivo = e.target.files[0];
                        readExcel(archivo);
                    }}
                />
            </label>
            </FormControl>
          </Grid>
          <span style={{fontSize: '10px' , marginTop: '20px',  marginLeft: '10px'}}> </span>
                    <table >
                    <thead>
                        <tr>
                            <th>-</th>
                              { data.length > 0 &&   < HeadTable/>}
                        </tr>
                    </thead>
                        <tbody>
                        {
                           store.data.length > 0 &&   data.map((rowData,id) => (
                                       <tr key={id}>
                                           <th>{id+1}</th>
                                         {
                                           store.data.length > 0 &&  head.map((key, index) => {
                                               console.log(index)
                                                 return <th key={index} style={{  width: index=== 155 ?  '30000000px' : '',  backgroundColor:  index  === 154 ?   'lightsteelblue' : 'white',   height:'100%',  wordwrap: 'break-word', textAlign: 'center'  }}>
                                                 {rowData[key]}
                                                 </th>
                                             })
                                         }
                                       </tr>
                                     ))
                            //   < BodyTable/>  {/* no borrar*/}
                        }
                        </tbody>
                    </table>

        </div>

        <FieldSet className={classes.root} style={{height: '13px'}} >
            <div className={classes.tableWrap}>
           

            </div>
            
        </FieldSet>


    </>
    )
};

export default CostoProductosImportar;