import axios from 'axios'//;
import { IdentityUrl } from '../../../config';
import { closeMessageBox,  openMessageBox } from '../../main/actions';

import {
    CLEAN_FORM,
    SET_STATE_FORM,
    UPDATE_FIELD_FILTER,
    ERROR_V_LIST,
    RECEIVE_V_LIST,
    REQUEST_V_LIST,
    SET_PAGE,
    SET_PAGE_SIZE,
    SET_SELECTED_ROW,
    SET_MASK,
    SET_ORDER,
    ADVANCED_SEARCH_CLOSE,
    ADVANCED_SEARCH_OPEN,
    UPDATE_FIELD_ENTITY,
    SET_ERROR,
    ADD_OLA,
    ADD_DISTRIBUIDOR,
} from './types';

import { Arol} from "../../../helpers/commons";

export const cleanForm = () => ({ type: CLEAN_FORM });

export const setSelectedRow = (selected) => ({ type: SET_SELECTED_ROW, selected});

export const setFormState = (formState, id) => (dispatch, getState) => {
    switch (formState) {
        case 'edit':
            if(id && id !== getState().vendedor.entity.vendedorId) {
                //dispatch(getUser(id));
            }
            break;
        case 'new':
            dispatch(cleanForm());
            break;
        case 'search':
            dispatch(cleanForm());
            break;
        case 'view':
            if(id && id !== getState().vendedor.entity.vendedorId) {
                //dispatch(getUser(id));
            }
            break;
        default:
            break;
    }
    dispatch({ type: SET_STATE_FORM, formState });
};

export const btnSearch = () => (dispatch) => {
    dispatch(setPage(0));
    dispatch(onUserList());
};

export const onSearchDistribuidorClick = () => async (dispatch) => {
    let fields = [
        { 'dataKey': 'razonSocial', 'label': 'Agente', 'type': 'string', 'width': 225 }
    ];

    //dispatch(onUpdateFieldFilter('distribuidorId', 0));
    //dispatch(onUpdateFieldFilter('dDistribuidor', 'Agente'));
    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        multiSelect: true,
        paginated: true,
        remote: true,
        title: 'Selección de Agente',
        getData: async (params) => {
            let input2 = JSON.stringify(params);
            let input = JSON.parse(input2);
            let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`
            let response1 = await axios.get(encodeURI(url), { params: params });
           
            let response = {
                data: {
                  start: 0,
                  pageSize: 50,
                  total: response1.data.length,
                  data: response1.data,
                },
              };
              

            if (input.distribuidorId) {
                let final = response.data.data.filter((e) =>
                  e.distribuidorId.toLowerCase().includes(input.distribuidorId.toLowerCase())
                );
                response.data.data = final;
                response.data.total = final.length;
            }

            return response.data.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch({
                    type: ADD_DISTRIBUIDOR,
                    payload: result.map((item) => {
                      return {
                        // documentoIdentidad: item.documentoIdentidad,
                        distribuidorId: item.distribuidorId,
                      };
                    }),
                  });
            }
        }
    }));
};

export const onSearchRolClick = () => async (dispatch, getState) => {
    let fields = [
        { 'dataKey': 'nombre', 'label': 'Rol', 'search': true, 'type': 'string', 'width': 175 }
    ];

    let data = Arol; //'Planeación Colombia'

    dispatch(openAdvancedSearch({
        data: data,
        fields: fields,
        title: 'Selección de Rol',
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                //console.log((getState().user.entity.rol))
                //console.log(result[0].nombre)
                if(getState().user.entity.rol !== result[0].nombre) {
                   // dispatch(onUpdateFieldFilter('distribuidorId', result[0].distribuidorId));
                    //dispatch(onUpdateFieldFilter('dDistribuidor', `${result[0].razonSocial}`));
                    dispatch(onUpdateFieldFilter('rol', result[0].nombre));
                    dispatch(onUpdateFieldFilter('entorno', ''));
                }
                
            }
        }
    }));
};

export const onSearchOlaChannelMapClick = () => async (dispatch, getState) => {
    let fields = [
      { dataKey: "nombre", label: "Ola", type: "string", width: 250 },
      { dataKey: "desde", label: "Dede", type: "string", width: 110 },
      { dataKey: "hasta", label: "Hasta", type: "string", width: 110 },
    ];
  
  
    try {
      dispatch(
        openAdvancedSearch({
          autoLoad: true,
          data: [],
          fields: fields,
          multiSelect: true,
          paginated: true,
          remote: true,
          title: "Selección de Ola Channel Map",
          getData: async (params) => {
            let input2 = JSON.stringify(params);
            let input = JSON.parse(input2);
            
            let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/OlasChannelMap/olas`;
            let response1 = await axios.get(encodeURI(url), { params: params });
            let response = {
              data: {
                start: 0,
                pageSize: 50,
                total: response1.data.length,
                data: response1.data,
              },
            };

          if (input.olaChannelMapId) {
            let final = response.data.data.filter((e) =>
              e.olaChannelMapId.toLowerCase().includes(input.olaChannelMapId.toLowerCase())
            );
            response.data.data = final;
            response.data.total = final.length;
          }
          return response.data;
        },
          callback: (btn, result) => {
           // console.log(result);
            dispatch(closeAdvancedSearch());
            if (btn === "yes") {
            //   aux = result[0].olaChannelMapId;  
            //   dispatch(onUpdateFieldFilter("olaId", aux.toString()));
            //   dispatch(onUpdateFieldFilter("dOla", result[0].nombre));
            dispatch({
                type: ADD_OLA,
                payload: result.map((item) => {
                  return {
                    // documentoIdentidad: item.documentoIdentidad,
                    olaChannelMapId: item.olaChannelMapId,
                  };
                }),
              });
            }
          },
        })
      );
    } catch (error) {
      if (error.response) {
        dispatch(
          openMessageBox({
            button: "ok",
            icon: "error",
            message:
              (error.response.data.eventLogId === 0
                ? ""
                : `EventoId: ${error.response.data.eventLogId}. `) +
              error.response.data.message,
            callback: () => dispatch(closeMessageBox()),
          })
        );
      }
    }
};



export const onSearchEntorno = () => async (dispatch, getState) => {
    let fields = [
      { dataKey: "entorno",label: "Entorno",type: "string",search: true,width: 225 }
    ];

    let data =[
        {entorno: "Producción"},
        {entorno: "Desarrollo"}

    ]
  
    if (getState().gestionroles.filters.rol === '') {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "Debe seleccionar un Rol..",
          callback: () => dispatch(closeMessageBox()),
        })
      );
      return;
    }
    dispatch(openAdvancedSearch({
        autoLoad: false,
        data: data,
        fields: fields,
        //paginated: true,
        //remote: true,
        title: "Seleccione el entorno",
        // getData: async (params) => {
        //     //let url = `${IdentityUrl}/api/v1/agentes/departamentos`;     
        //     // let url = `${getState().main.resources.uriSvcSalesMobilityAPI}/api/v1/Vendedores/listarvendedoresxdistribuidor`
        //     // + `?distribuidorId=${getState().clientes.filters.distribuidorId}`               
        //     // let response = await axios.get(encodeURI(url), { params: params });
        //     const  aux = {
        //         data: response.data  ,
        //         pageSize: 50,
        //         start: 0,
        //         total: response.data.length
        //     }
        //     return aux;  //response.data;
        // },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                //console.log( result[0].vendedorId)
                //console.log(result[0].entorno)
                //dispatch(onUpdateFieldFilter('vendedorId', result[0].vendedorId));
                dispatch(onUpdateFieldFilter('entorno', result[0].entorno)); 
                // dispatch({ type: RECEIVE_OLAS_LIST, columns: getState().aprobarpedido.table.columns, payload: [] });
              }
        }
    }));
};

export const onUpdateFieldEntity = (key, value) => (dispatch) => {
    dispatch({ type: UPDATE_FIELD_ENTITY, key, value });

    if(key === 'nombre' || key === 'nombreUsuario' || key === 'correo' || key === 'distribuidor' || key === 'rol') {
        dispatch({ type: SET_ERROR, key, error: '' });
    }
};

export const onUserList = () => async (dispatch, getState) => {
    // if (getState().clientes.filters.distribuidorId   === 0) {
    //     dispatch(openMessageBox({
    //         button: 'ok',
    //         icon: 'warning',
    //         message: "Debe seleccionar un Agente..",
    //         callback: () => dispatch(closeMessageBox())
    //     }));
    // return;
    // }
    if (getState().clientes.store.loading) {
        return;
    }
  
    dispatch({ type: SET_MASK, loading: true});
    //console.log(getState().vendedor);
    //dispatch({ type: RECEIVE_OLAS_LIST, columns: getState().vendedor.table.columns, payload: [] });
    let source = axios.CancelToken.source();

    dispatch({ type: REQUEST_V_LIST , source });

   // console.log(getState().gestionroles.filters.olaId)
    let obj = getState().gestionroles.filters.olaId === ""
        ? ""
        : getState().gestionroles.filters.olaId;

    let obj1 = getState().gestionroles.filters.distribuidorId === ""
        ? ""
        : getState().gestionroles.filters.distribuidorId;
    
    var distribuidores = [];
    
    for (var i in obj1)
    {
        let x = Object.values(obj1[i]);
        distribuidores.push(x);
    }

    //console.log(distribuidores)

    var z = distribuidores.toString()

    var olas = [];
    for (var j in obj)
    {
        let x = Object.values(obj[j]);
        olas.push(x);
    }
    //console.log(olas)
    var x = olas.toString()
    try {
        // let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Clientes/distribuidorvendedor?` 
        // //let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Clientes?`
        // //+ `soloActivos=true` 
        // + `distribuidorId=${getState().clientes.filters.distribuidorId}`
        // + `&vendedorId=${getState().clientes.filters.vendedorId}`
        // + `&nombre=${getState().clientes.filters.nombre}`
        // + `&todosClientes=0`;
        console.log(z)       
        console.log(x)
        if (typeof x === 'string' || x instanceof String) {
            console.log('es stringcito');
        }
        else {
            console.log('no es stringcito');
        }

        console.log(z)
        if (typeof z === 'string' || z instanceof String) {
            console.log('es stringcito');
        }
        else {
            console.log('no es stringcito');
        }

       // let response = await axios.get(encodeURI(url));
        let data = 1;

        if (data.length === 0) {
            dispatch(openMessageBox({
                button: 'ok',
                icon: 'warning',
                message: "No existe Registro  para esta consulta",
                callback: () => dispatch(closeMessageBox())
            }));
        return;
    }
       dispatch({ type: RECEIVE_V_LIST, payload: data });
    } catch (error) {
        let msgError = '';
        dispatch({ type: ERROR_V_LIST });
        dispatch({ type: SET_MASK, loading: false });
   
        if (error.response) {
            msgError = (error.response.data.eventLogId === 0 ? 'No existe Registro  para esta consulta ' : `EventoId: ${error.response.data.eventLogId}. `+ error.response.data.message) 
        }
        else {
            msgError = 'Error al conectar con el servidor';
        }

        dispatch(openMessageBox({
            button: 'ok',
            icon: 'error',
            message: msgError,
            callback: () => dispatch(closeMessageBox())
        }));
    }finally{
        dispatch({ type: SET_MASK, loading: false });
    }
};

export const onUpdateColumn = (column) => (dispatch, getState) => {
    getState().clientes.table.columns.map((item , i)=> {     
       if(item.dataKey !== column.dataKey) {
           return item;
       }else{
               item.width =  column.width;
       }
       return {
          // ...item,
         //  width: column.width
       };
      
   });

   // dispatch({ type: UPDATE_TABLE_COLUMN, columns }); 
};

export const setPage = (page) => ({ type: SET_PAGE, page });

export const setPageSize = (pageSize) => ({ type: SET_PAGE_SIZE, pageSize });

export const onUpdateFieldFilter = (key, value) => (dispatch, getState) =>{ 
       
    dispatch({type: UPDATE_FIELD_FILTER, key, value});
    
    dispatch({ type: RECEIVE_V_LIST, payload: [] });
    
}

export const openAdvancedSearch = (opts) => ({ type: ADVANCED_SEARCH_OPEN, payload: opts });

export const setOrder = (order, orderBy) => ({ type: SET_ORDER, order, orderBy });

export const closeAdvancedSearch = () => ({ type: ADVANCED_SEARCH_CLOSE });


