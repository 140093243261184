import {
    CHANGE_IMAGE,
    CLEAN_FORM,
    ERROR_MARCAS_LIST,
    RECEIVE_LIST,
    REMOVE_IMAGE,
    REQUEST_MARCAS_LIST,
    RESTART_STATE,
    SET_ENTITY,
    SET_ERROR,
    SET_FORM,
    SET_MASK,
    SET_STATE_FORM,
    UPDATE_FIELD_ENTITY,
    UPDATE_FIELD_FILTER,
    ADD_MARCA
} from './types';

const initialState = {
    formState: 'search',
    loading: false,
    entity: {
        distribuidorId: 0,
        dDistribuidor: '',        
        nombre: '',
        marcaDistribuidorId: 0,
        imagen: ''
    },
    form: {
        imagen: '',
        archivo: ''
    },
    errors: {
        nombre: '',
        distribuidor: ''
    },
    filters: {
        nombre: '',
        distribuidorId: 0,
        dDistribuidor: '',
    },
    store: {
        loading: false,
        data: [],
        source: null,
        aMarcas:[],
        aux: [],
        pantalla: false,
    },
    aux: {

        agregar:false,        
        index: 0,
    },
    arreglo:{
        dataMarca:[]
    },

};

export const asignarmarcadistribuidorReducer = (state, action) => {
    state = state || initialState;
    
    switch(action.type) {
        case CHANGE_IMAGE:
            return {
                ...state,
                form: {
                    ...state.form,
                    archivo: action.file,
                    imagen: action.image
                }
            };
        case CLEAN_FORM:
            return {
                ...state,
                entity: initialState.entity,
                errors: initialState.errors
            };
        case ERROR_MARCAS_LIST:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: false,
                    data: [],
                    source: null
                }
            };
        case RECEIVE_LIST:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: false,
                    data: action.payload,
                    source: null
                }
            };
        case REMOVE_IMAGE:
            return {
                ...state,
                form: {
                    ...state.form,
                    archivo: '',
                    imagen: ''
                }
            };
        case REQUEST_MARCAS_LIST:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: true,
                    data: [],
                    source: action.source
                }
            };
        case RESTART_STATE:
            return {
                ...initialState
            };
        case SET_ENTITY:
            return {
                ...state,
                entity: {
                    ...state.entity,                  
                    nombre: action.payload.nombre,
                    marcaDistribuidorId: action.payload.marcaDistribuidorId,
                    imagen: action.payload.imagen
                }
            };

        case ADD_MARCA:
            
            return {
                ...state,
                store: {
                    ...state.store,
                    aMarcas: action.payload
                }
            };  

        case SET_ERROR:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.key]: action.error
                }
            };
        case SET_FORM:
            return {
                ...state,
                form: {
                    imagen: state.entity.imagen,
                    archivo: ''
                }
            };
        case SET_MASK:
            return {
                ...state,
                loading: action.loading
            };
        case SET_STATE_FORM:
            return {
                ...state,
                formState: action.formState
            };
        case UPDATE_FIELD_ENTITY:
            return {
                ...state,
                entity: {
                    ...state.entity,
                    [action.key]: action.value
                }
            };
        case UPDATE_FIELD_FILTER:
            return { 
                ...state,
                filters: {
                    ...state.filters,
                    [action.key]: action.value
                }
            };
        default:
            return state;
    }
};