import {
    CLEAN_FORM,
    SET_STATE_FORM,
    UPDATE_FIELD_FILTER,
    SET_MASK,
    RECEIVE_DATA,
    UPDATE_TABLE_COLUMN,
    ERROR_V_LIST,
    RECEIVE_V_LIST,
    REQUEST_V_LIST,
    SET_ORDER
} from './types';

const initialState = {
    formState: 'search',
    loading: false,
    entity: {
        vendedorId: 0,
        nombre: ''
    },
    errors: {
        codigo: '',
        usuario: '',
        documentoIdentidad: ''
    },
    filters: {
        nombre: '',
        distribuidorId: 0,
        dDistribuidor: ''
    },
    store: {
        loading: false,
        pageSize: 50,
        start: 0,
        total: 0,
        data: [],
        selected: [],
        //source: null
    },
    table: {
        order: 'asc',
        orderBy: '',
        columns: [
            /*
            { dataKey: 'distribuidor', label: 'Distribuidor', width: 200 },
            { dataKey: 'documentoIdentidad', label: 'CEDULA', width: 105 }, 
            { dataKey: 'nombre', label: 'Nombres', width: 275 },
            { dataKey: 'correo', label: 'Correo', width: 200 },
            { dataKey: 'telefono', label: 'Teléfono', width: 105 },
            { type: 'boolean', dataKey: 'activo', label: 'Activo', width: 90 },
            { dataKey: 'codigo', label: 'Código', width: 150 },
            */
           /*
                { dataKey: 'distribuidor', label: 'Distribuidor', width: 200 },
                { dataKey: 'documentoIdentidad', label: 'CEDULA', width: 105 }, 
                { dataKey: 'nombre', label: 'Nombres', width: 275 },
                { dataKey: 'Provincia', label: 'Provincia', width: 275 },
                { dataKey: 'Canton', label: 'Canton', width: 275 },
                { dataKey: 'Zona', label: 'Zona', width: 275 },
                { dataKey: 'jefeZona', label: 'Jefe de Zona', width: 275 },
                { dataKey: 'correo', label: 'Correo', width: 200 },
                { dataKey: 'telefono', label: 'Teléfono', width: 105 },
                { type: 'boolean', dataKey: 'activo', label: 'Activo', width: 90 },
                { dataKey: 'codigo', label: 'Código', width: 150 },
                */
        ]
    }
};

export const vendedorReducer = (state, action) => {
    state = state || initialState;

    switch(action.type) {
        case CLEAN_FORM:
            return {
                ...state,
                entity: initialState.entity,
                errors: initialState.errors
            };
        case SET_STATE_FORM:
            return {
                ...state,
                formState: action.formState
            };
        case UPDATE_FIELD_FILTER:
            return { 
                ...state,
                filters: {
                    ...state.filters,
                    [action.key]: action.value
                }
            };
            case ERROR_V_LIST:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: false
                }
            };
            case RECEIVE_V_LIST:
                return {
                    ...state,
                    store: {
                        ...state.store,
                        loading: false,
                        total: action.total,
                        data: action.payload,
                        source: null
                    },
                    table: {
                        ...state.table,
                        columns: action.columns,
                    }
                };
            case REQUEST_V_LIST:
                return {
                    ...state,
                    store: {
                        ...state.store,
                        loading: false,
                        source: action.source
                    }
                };
            case RECEIVE_DATA:
                return {
                    ...state,
                    data: action.payload,
                    store: {
                        ...state.store,
                        data: action.storeData
                    }
                };
                case UPDATE_TABLE_COLUMN:
                    return {
                        ...state,
                        table: {
                            ...state.table,
                            columns: action.columns
                        }
                    };
            case SET_MASK:
                return {
                    ...state,
                    loading: action.loading
            };
            case SET_ORDER:
                return {
                    ...state,
                    table: {
                        ...state.table,
                        order: action.order,
                        orderBy: action.orderBy
                    }
                };
        default:
            return state;
    }
};