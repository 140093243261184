import axios from 'axios';
import format from "date-fns/format";
import { IdentityUrl } from '../../../config';
import { closeAdvancedSearch, closeMessageBox, openAdvancedSearch, openMessageBox } from '../../main/actions';
import {
    RESTART_STATE,
    SET_MASK,
    UPDATE_FIELD_FILTER,
    RECEIVE_V_LIST,
    SET_ORDER
} from './types';

import {cortarArreglo,exportExcelFecha,exportToSpreadsheet  } from '../../../helpers/commons'

export const btnExport = () => async (dispatch, getState) => {

    try {
        dispatch({ type: SET_MASK, loading: true });

        if (getState().basedatosPrecios.store.loading) {
          return;
          }
        let url = `${getState().main.resources.uriSvcSalesMobilityAPI}/api/v1/Tomas/basedatos/listar?`
        + `distribuidorId=${getState().basedatosPrecios.filters.distribuidorId}`
        + `&supervisorId=${getState().basedatosPrecios.filters.supervisorId}`
        + `&desde=${format(getState().basedatosPrecios.filters.desde, 'yyyy-MM-dd')}`
        + `&hasta=${format(getState().basedatosPrecios.filters.hasta, 'yyyy-MM-dd')}`;
        
        let response = await axios.get(encodeURI(url));       
        var data = response.data;

        if (data.length === 0) {
          dispatch(openMessageBox({
              button: 'ok',
              icon: 'warning',
              message: "No existe Registro  para esta consulta",
              callback: () => dispatch(closeMessageBox())
          }));
      return;
  }
      
                //dettermina el pais
                let uri = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Vendedores/listarpaislogin` 
                response = await axios.get(encodeURI(uri));
               let datas = response.data;
               let columns = []
       
              if  (datas[0].Pais === 'CO' ){
                columns = [
                  { dataKey: 'Distribuidor', label: 'Distribuidor', width: 140 },
                 // { dataKey: 'Cliente', label: 'Cliente', width: 370 },
                  //{ dataKey: 'RUC', label: 'RUC', width: 140 },
                  { dataKey: 'Vendedor', label: 'Vendedor', width: 270 },
                  { dataKey: 'LOB', label: 'LOB', width: 140 },
                  { dataKey: 'Categoria', label: 'Categoria', width: 140 },
                  { dataKey: 'Marca', label: 'Marca', width: 140 },
                  { dataKey: 'DProducto', label: 'Descripción de Producto', width: 400 },
                  { dataKey: 'Presentacion', label: 'Presentacion', width: 140 },
                  { dataKey: 'Viscosidad', label: 'Viscosidad',  width: 140 },
                  { dataKey: 'Certificado', label: 'Certificado',  width: 140 },
                  { dataKey: 'DTipoEstablecimiento', label: 'Tipo de Establecimiento', width: 152, },
                  { dataKey: 'TipoPrecio', label: 'Tipo de Precio', width: 140 },
                  { dataKey: 'PrecioCompra', label: 'Precio de Compra', width: 140 },
                  { dataKey: 'PrecioVenta', label: 'Precio de Venta', width: 140 },
                  { dataKey: 'Departamento', label: 'Departamento', width: 140 },
                  { dataKey: 'Provincia', label: 'Provincia', width: 140 },
                  { dataKey: 'Distrito', label: 'Distrito', width: 140 },
                  { dataKey: 'Fecha', label: 'Fecha', type: 'datetime' ,width: 140 },
                  { dataKey: 'Usuario', label: 'Usuario', width: 140 }
                ]
              
             } else{ //adiciona otros campos para perú   
               columns.splice(1, 0,  { dataKey: 'RUC', label: 'RUC', width: 140 });
               columns.splice(2, 0,   { dataKey: 'Cliente', label: 'Cliente', width: 370 });
               }

      // for(var j in Object.keys(data[0]))
      // {
      //   //console.log("recorrer: ", Object.keys(data[j]))

      //   for(var i in Object.keys(data[j]) )
      //     {
      //       //console.log("pasa: ", Object.keys(data[i])[j])
      //       if(Object.keys(data[i])[j] === "PrecioCompra"){ 
      //         //console.log("recorrer2: ",Object.keys(data[0])[j])
      //         columns.push({ dataKey: 'PrecioCompra', label: 'Precio de Compra', width: 140 })
      //         break;
      //       }
      //       else{
      //       console.log("no tiene")
      //       break;
      //       //console.log(Object.keys(data[0])[j])
      //       }
      //   }
      // }
      
    //   if (data.length > 0) {
    //     const primerObjeto = data[0]; 
    
    //     if (primerObjeto) {
    //         const keys = Object.keys(primerObjeto);
    
    //         if (keys.includes("PrecioCompra")) {
    //             columns.push({ dataKey: 'PrecioCompra', label: 'Precio de Compra', width: 140 });
    //         }
    //     }
    // }

      //let columnas = getState().basedatosPrecios.table.columns;
     
      dispatch({ type: RECEIVE_V_LIST, columns: columns, payload: data});

        let json = getState().basedatosPrecios.store.data;
        console.log("json: ",json)
        console.log("head",columns)

       exportExcelFecha(cortarArreglo(columns,json), 'BaseDatosPrecios','P') ; 
       
     // exportToSpreadsheet(cortarArreglo(head,json), 'BaseDatosPrecios')
    } catch (error) {
        console.log(error)
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'error', 
            message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
            callback: () => dispatch(closeMessageBox())
        }));
    } finally {
        dispatch({ type: SET_MASK, loading: false });
    }
};

export const btnSearch = () => async (dispatch, getState) => {
  try {
      dispatch({ type: SET_MASK, loading: true });

      if (getState().basedatosPrecios.store.loading) {
          return;
      }

      let url = `${getState().main.resources.uriSvcSalesMobilityAPI}/api/v1/Tomas/basedatos/listar?`
          + `distribuidorId=${getState().basedatosPrecios.filters.distribuidorId}`
          + `&supervisorId=${getState().basedatosPrecios.filters.supervisorId}`
          + `&desde=${format(getState().basedatosPrecios.filters.desde, 'yyyy-MM-dd')}`
          + `&hasta=${format(getState().basedatosPrecios.filters.hasta, 'yyyy-MM-dd')}`;
      let response = await axios.get(encodeURI(url));

      var data = response.data;

      //console.log(data)

      if (data.length === 0) {
          dispatch(openMessageBox({
              button: 'ok',
              icon: 'warning',
              message: "No existe Registro  para esta consulta",
              callback: () => dispatch(closeMessageBox())
          }));
      return;
  }
          //dettermina el pais
          let uri = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Vendedores/listarpaislogin` 
           response = await axios.get(encodeURI(uri));
          let datas = response.data;
          let columns = []
  
          if  (datas[0].Pais === 'CO' ){
            columns = [
              { dataKey: 'Distribuidor', label: 'Distribuidor', width: 140 },
              { dataKey: 'Vendedor', label: 'Vendedor', width: 270 },
             // { dataKey: 'Cliente', label: 'Cliente', width: 370 },
              //{ dataKey: 'RUC', label: 'RUC', width: 140 },
              { dataKey: 'LOB', label: 'LOB', width: 140 },
              { dataKey: 'Categoria', label: 'Categoria', width: 140 },
              { dataKey: 'Marca', label: 'Marca', width: 140 },
              { dataKey: 'DProducto', label: 'Descripción de Producto', width: 400 },
              { dataKey: 'Presentacion', label: 'Presentacion', width: 140 },
              { dataKey: 'Viscosidad', label: 'Viscosidad',  width: 140 },
              { dataKey: 'Certificado', label: 'Certificado',  width: 140 },
              { dataKey: 'DTipoEstablecimiento', label: 'Tipo de Establecimiento', width: 152, },
              { dataKey: 'TipoPrecio', label: 'Tipo de Precio', width: 140 },
              { dataKey: 'PrecioCompra', label: 'Precio de Compra', width: 140 },
              { dataKey: 'PrecioVenta', label: 'Precio de Venta', width: 140 },
              { dataKey: 'Departamento', label: 'Departamento', width: 140 },
              { dataKey: 'Provincia', label: 'Provincia', width: 140 },
              { dataKey: 'Distrito', label: 'Distrito', width: 140 },
              { dataKey: 'Fecha', label: 'Fecha', type: 'datetime' ,width: 140 },
              { dataKey: 'Usuario', label: 'Usuario', width: 140 }
            ]
         
        } else{ //adiciona otros campos para perú   
          columns.splice(1, 0,  { dataKey: 'RUC', label: 'RUC', width: 140 });
          columns.splice(2, 0,   { dataKey: 'Cliente', label: 'Cliente', width: 370 });
          }

      // for(var j in Object.keys(data[0]))
      // {
      //   //console.log("recorrer: ", Object.keys(data[j]))

      //     for(var i in Object.keys(data[j]) )
      //     {
      //       //console.log("pasa: ", Object.keys(data[i])[j])
      //       if(Object.keys(data[i])[j] === "PrecioCompra"){ 
      //         //console.log("recorrer2: ",Object.keys(data[0])[j])
      //         columns.push({ dataKey: 'PrecioCompra', label: 'Precio de Compra', width: 140 })
      //         break;
      //       }
      //       else{
      //       console.log("no tiene")
      //       break;
      //     //console.log(Object.keys(data[0])[j])
      //     }
      //   }
      // }

    //   if (data.length > 0) {
    //     const primerObjeto = data[0]; 
    
    //     if (primerObjeto) {
    //         const keys = Object.keys(primerObjeto);
    
    //         if (keys.includes("PrecioCompra")) {
    //             columns.push({ dataKey: 'PrecioCompra', label: 'Precio de Compra', width: 140 });
    //         }
    //     }
    // }

      //let columnas = getState().basedatosPrecios.table.columns;
     
      dispatch({ type: RECEIVE_V_LIST, columns: columns, payload: data});
      
  } catch (error) {
     
      dispatch(openMessageBox({ 
          button: 'ok', 
          icon: 'error', 
          message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
          callback: () => dispatch(closeMessageBox())
      }));
  } finally {
      dispatch({ type: SET_MASK, loading: false });
  }

  
};

export const onSearchDistribuidorClick = () => async (dispatch) => {
    let fields = [
        { 'dataKey': 'razonSocial', 'label': 'Agente', 'type': 'string', 'width': 225 }
    ];

    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de agente',
        getData: async (params) => {
            let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`
            let response = await axios.get(encodeURI(url), { params: params });

            return response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldFilter('distribuidorId', result[0].distribuidorId));
                dispatch(onUpdateFieldFilter('dDistribuidor', `${result[0].razonSocial}`));
                dispatch(onUpdateFieldFilter('supervisorId', ''));
                dispatch(onUpdateFieldFilter('dSupervisor', ''));
            }
        }
    }));
};

export const onSearchSupervisorClick = () => async (dispatch, getState) => {
    let fields = [
      {
        dataKey: "nombre",
        label: "Nombre",
        type: "string",
        search: true,
        width: 225,
      },
      { dataKey: "nombreUsuario", label: "Usuario", type: "string", width: 115 },
    ];
  
    if (getState().basedatosPrecios.filters.distribuidorId === 0) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "Debe seleccionar un Agente..",
          callback: () => dispatch(closeMessageBox()),
        })
      );
      return;
    }
    dispatch(
      openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: "Selección de supervisor",
        getData: async (params) => {
          let url = `${IdentityUrl}/api/v1/usuarios?rol=Supervisor`;
          let response = await axios.get(encodeURI(url), { params: params });
          let distribuidorId = getState().basedatosPrecios.filters.distribuidorId;
  
          if (distribuidorId > 0) {
            response.data.data = response.data.data.filter(
              (f) => f.distribuidorId === distribuidorId
            );
          }
  
          return response.data;
        },
        callback: (btn, result) => {
          dispatch(closeAdvancedSearch());
          if (btn === "yes") {
            dispatch(onUpdateFieldFilter("supervisorId", result[0].usuarioId));
            dispatch(onUpdateFieldFilter("dSupervisor", `${result[0].nombre}`));
          }
        },
      })
    );
  };

  export const onUpdateColumn = (column) => (dispatch, getState) => {
    getState().basedatosPrecios.table.columns.map((item , i)=> {     
       if(item.dataKey !== column.dataKey) {
           return item;
       }else{
               item.width =  column.width;
       }
       return {
          // ...item,
         //  width: column.width
       };
      
   });
};
export const onUpdateFieldFilter = (key, value) => ({ type: UPDATE_FIELD_FILTER, key, value });

export const restartState = () => ({ type: RESTART_STATE });

export const setOrder = (order, orderBy) => ({ type: SET_ORDER, order, orderBy });
