import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import {
    onSearchSucursalClick,
    onSearchProspectoClick,
  } from "../../../store/movilidad/planificarvisita/actions";
import { FieldSet } from '@sigeco/tools';
import { KeyboardDatePicker } from "@material-ui/pickers";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
    columnImage: {
        flex: 1,
        fontSize: '.8125rem',
    },
    columnIndex: {
        fontSize: '.8125rem',
        width: '3.5rem'
    },
    columnText: {
        fontSize: '.8125rem',
        width: '12rem'
    },
    empty: {
        marginTop: '1rem',
        textAlign: 'center'
    },
    image: {
        backgroundColor: '#fff',
        border: 'solid 1px #d3d3d3',
        borderRadius: '50%',
        objectFit: 'contain',
        height: '3.25rem',
        width: '3.25rem'
    },
    root: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        overflowY: 'hidden'
    },
    row: {
        alignItems: 'center',
        borderBottom: 'solid 1px #d3d3d3',
        display: 'flex',
        padding: '.5rem 2rem',
        '&:hover' : {
            backgroundColor : '#f3f3f3'
        },
        '&:first-child': {
            borderTop: 'solid 1px #d3d3d3'
        },
        '&:focus': {
            border: 'solid 1px #006bb6',
            outline: 'none'
        }
    },
    tableWrap: {
        flex: '1 1 auto',
        overflowY: 'auto',
        padding: '1rem 0'
    },
    table1: {
        marginRight:'20px !important',
        marginLeft:'20px !important'
    },
    paper: {
        marginTop:'-40px !important'

    }
}));

const PlanificarVisitaTable = () => {
    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const matches = useMediaQuery("(min-width:701px)");
    const store = useSelector(state => state.planificarvisita.store);
    const arreglo = useSelector(state => state.planificarvisita.arreglo);
    const position = useSelector(state => state.planificarvisita.aux);
    const [mostrarFormulario, setMostrarFormulario] = useState(false);
    const [visitas, setVisitas] = useState([]);
    

    const [visitaAux, setvisitaAux] = useState({
        sucursalId: 0,
        prospectoId: 0,
        dCliente: '',
        direccion: '',
        ipt: '',
        estado: '',
        fecha: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        index: 0,
    }); 

    useEffect(() => {
       
      if(store.datax != undefined){  
        const ax = [...store.datax];
  
        
        if (visitas.length === 0) {
          const updatedVisitas = ax.map(item => ({
            sucursalId: item.SucursalId,
            prospectoId: item.ProspectoId,
            dCliente: item.dCliente,
            direccion:item.Direccion,
            ipt:item.IPT,
            fecha: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
            estado:item.Estado,
          }));
          setVisitas(updatedVisitas);
          position.agregar = true;
        } else {
          const updatedVisitas = visitas.concat(ax.map(item => ({
            sucursalId: item.SucursalId,
            prospectoId: item.ProspectoId,
            dCliente: item.dCliente,
            direccion:item.Direccion,
            ipt:item.IPT,
            fecha: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
            estado:item.Estado,
          })));
          setVisitas(updatedVisitas);
          position.agregar = true;
        }
  
      }
        
  
        //console.log("cambio: ", componente.agregar)
  
    }, [store.datax]);

       //console.log("TTTT", visitas)

      
    useEffect(() => {
        
      // creo que no lo uso (no me acuerdo ni mi codigo xd)
        
        const updatedVisitas = visitas.map((item, i) => {
            if (i === visitaAux.index) {
              return {
                ...item,
                fecha: visitaAux.fecha,
              };
            }
            return item;
        });
      
        setVisitas(updatedVisitas);
      
        //console.log("Objeto: ", objetos);
        //console.log("SI ENVIO: ", componeteAux);
        
        
        //console.log("DATOS LOCALES: ",visitas);
        
        
        //arreglo.dataComp = [...visitas];
    
      
        //console.log("DATOS ENVIANDO: ",arreglo.dataComp);
    
    
    }, [visitaAux]);



    // const handleCellChange = (e, index, field) => {
    //     const updatedVisitas = visitas.map((item, i) => {
    //       if (i === index) {
    //         return {
    //           ...item,
    //           [field]: e.target.value
    //         };
    //       }
    //       return item;
    //     });
    //     setVisitas(updatedVisitas);
    //     //console.log(updatedObjetos);
    //     position.index = index
    //     //console.log("Indice que envio: ",componente.index)
    //     //console.log("Indice local: ", index)
    // };

    const onUpdateFieldFilter2 = (value, index, field) => {
        const updatedVisitas = visitas.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              [field]: value,
            };
          }
          return item;
        });
        setVisitas(updatedVisitas);
        position.index = index;
      };



    const handleMostrarFormulario = () => {
        if(store.pantalla === true){
          setMostrarFormulario(true);
        }else{
          setMostrarFormulario(false);
        } 

    };

    const onDelete = (index) => {
     setVisitas(prevObjetos => prevObjetos.filter((_, i) => i !== index));
    };

    arreglo.dataVisita = [...visitas];

    // console.log("DATOS ENVIANDO: ",arreglo.dataVisita);

    return (


        <>

            <Button
                color="primary" 
                size="small" 
                variant="contained"
                onClick={() => {
                dispatch(onSearchSucursalClick());
                handleMostrarFormulario();
                }}
                style = {{width: "170px",
                            marginLeft: "12px",
                            marginTop: "-45px",
                            height: "25px"}}
                        >
                Agregar Sucursal(es)
            </Button>
            <Button
                color="primary" 
                size="small" 
                variant="contained"
                onClick={() => {
                dispatch(onSearchProspectoClick());
                handleMostrarFormulario();
                }}
                style = {{width: "170px",
                            marginLeft: "210px",
                            marginTop: "-25px",
                            height: "25px"}}
                        >
                Agregar Prospecto(s)
            </Button>
            {mostrarFormulario && (
        
           <div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            
            <FieldSet className={classes.paper} iconCls="fas fa-address-card" title="Asignación de Visitas" >
            <table className={classes.table1}  >
            <thead> 
            <tr>     
                <th style={{textAlign : 'left', width: '2%'}}>  </th>
                <th style={{width: '15%', height:'100%', textAlign : 'center'}}> SucursalId </th>
                <th style={{width: '15%', height:'100%', textAlign : 'center'}}> ProspectoId </th>
                <th style={{width: '15%', height:'100%', textAlign : 'center'}}> Fecha </th>  
                <th style={{width: '15%', height:'100%', textAlign : 'center'}}> Cliente </th>
                <th style={{width: '15%', height:'100%', textAlign : 'center'}}> Direccion  </th>
                <th style={{width: '15%', height:'100%', textAlign : 'center'}}> IPT  </th>
                <th style={{width: '15%', height:'100%', textAlign : 'center'}}> Estado  </th>
                <th style={{textAlign : 'center', width: '10%'  }}></th>
            </tr>
            </thead>

            <tbody>
                {
                        visitas.map((item, i) => (
                            <tr key={i}>
                                <td style={{ width: '2%', height: '100%' }}>{i + 1}</td>
                                <td style={{ width: '10%', height: '100%', textAlign: 'center' }}> 
                                 {item.sucursalId}
                                </td>
                                <td style={{ width: '10%', height: '100%', textAlign: 'center' }}> 
                                 {item.prospectoId}
                                </td>
                                <td style={{width: '15%', height:'100%', textAlign: 'center'}}>
                               
                                <KeyboardDatePicker
                                    autoOk
                                    disableToolbar
                                    format="dd/MM/yyyy"
                                    label="Desde"
                                    value={item.fecha}
                                    inputVariant={matches ? "outlined" : "standard"}
                                    variant={matches ? "inline" : "dialog"}
                                    onChange={(date) => onUpdateFieldFilter2(date, i, "fecha")}
                                />
                                </td>
                                <td style={{ width: '20%', height: '100%', textAlign: 'center' }}> 
                                 {item.cliente}
                                </td>
                                <td style={{ width: '20%', height: '100%', textAlign: 'center' }}> 
                                 {item.direccion}
                                </td>
                                <td style={{ width: '20%', height: '100%', textAlign: 'center' }}> 
                                 {item.ipt}
                                </td>
                                <td style={{ width: '20%', height: '100%', textAlign: 'center' }}> 
                                 {item.estado}
                                </td>
                                
                                <td colSpan="2" style={{width: '10px', height:'100%',    textAlign: 'center' }}>
                                {
                                    
                                            <button
                                              style={{ width: 30, backgroundColor: 'white', borderColor: 'darkgray' }}
                                              className={'btn-success'}
                                              icon={'fa-solid fa-arrow-right-to-bracket'}
                                              onClick={() => onDelete(i)}
                                            >
                                              <i className="fas fa-times"></i>
                                            </button>

                                }
                                </td>
                            </tr>
                        ))
                    }
                </tbody>


            </table>
            </FieldSet>
            </div>
             )
        }
        
        </>

    );
};

export default PlanificarVisitaTable;