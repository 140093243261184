import React, { useLayoutEffect  } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_material from "@amcharts/amcharts4/themes/material";

import Grid from '@material-ui/core/Grid';

am4core.useTheme(am4themes_material);
am4core.useTheme(am4themes_animated);

const ReporteSeguimientoDash = () => {

  
  const getDataByTipo = (lob) => {
   
    return _(data)
      .groupBy('Prospecto')
      .map((item, key) => ({
          'category': key,
          'tipo': _.countBy(item, i => i.Prospecto === 0), //=== true
      }))
      .value();
}
  
    const data = useSelector(state => state.reporteseguimiento.store.data);
    
var  data2 = getDataByTipo();

    useLayoutEffect(() => {
      
      
      if (data.length === 0 && data2.length === 0) {
        return
      }
     
        var container = am4core.create("chartdiv", am4core.Container);
        
        container.width = am4core.percent(100);
        container.height = am4core.percent(100);
        container.layout = "vertical";
      
        if(data.length > 0) {
            container.exporting.menu = new am4core.ExportMenu();
            container.exporting.menu.align = "left";
            container.exporting.menu.verticalAlign = "top"
            container.exporting.menu.items = [
                {
                  "label": "...",
                  "menu": [
                    {
                      "label": "Imagen",
                      "menu": [
                        { "type": "png", "label": "PNG" },
                        { "type": "jpg", "label": "JPG" },
                        { "type": "svg", "label": "SVG" },
                        { "type": "pdf", "label": "PDF" }
                      ]
                    },  {
                      "label": "Imprimir", "type": "print"
                    }
                  ]
                }
              ]
        }


        createChart(container, 'COBERTURA');
        
        return () => {
            container.dispose();
        }
    // eslint-disable-next-line
    }, [data, data2]);
    
    const createChart = (container, titleText) => {


      am4core.useTheme(am4themes_animated);
      // Themes end

      // create chart
      /*  /// otro grafico
        //var chart = am4core.ontainer.createChild("chartdiv", am4charts.PieChart3D);
        var chart = container.createChild(am4charts.PieChart3D);
        chart.Label = 'uno'
        var title = chart.titles.create();
        title.fontSize = 20;
        title.text = 'GESTION';

      chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
      const Dta =   getDataByLob('Nombre')

      chart.data = [];

      Dta.map((item, i) =>
      //markerObjects.push({"lat":parseFloat(item.Latitud),"lng": parseFloat(item.Longitud)})
            chart.data.push({ 'country' : item.Nombre, "litres":  item.tipo.false } )
      );

      chart.innerRadius = am4core.percent(40);
      chart.depth = 120;

      chart.legend = new am4charts.Legend();

      var series = chart.series.push(new am4charts.PieSeries3D());
      series.dataFields.value = "litres";
      series.dataFields.depthValue = "litres";
      series.dataFields.category = "country";
      series.slices.template.cornerRadius = 5;
      series.colors.step = 3;

      */
      //var chart = am4core.create("chartdiv", am4charts.RadarChart);
      var chart = container.createChild(am4charts.RadarChart);
      var title = chart.titles.create();
  
      title.text = 'GESTION';

        const Dta =   getDataByLob('Nombre')
        chart.data = [];
  
        // eslint-disable-next-line array-callback-return
        Dta.map((item, i) =>{
           // var x = (item.Nombre.split(' ')[0].length)
        //markerObjects.push({"lat":parseFloat(item.Latitud),"lng": parseFloat(item.Longitud)})
             // chart.data.push({ 'country' : item.Nombre.split(' ')[0]+" "+item.Nombre.slice( x, -1).charAt(1) , "visits":  item.tipo.false } ) //item.Nombre.split(' ')[0]+
              chart.data.push({ 'country' : item.Nombre.split(' ')[0] , "visits":  item.tipo.false } ) //item.Nombre.split(' ')[0]+
            }
              );

        chart.innerRadius = am4core.percent(40)

        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.dataFields.category = "country";
        categoryAxis.renderer.minGridDistance = 60;
        categoryAxis.renderer.inversed = true;
        categoryAxis.renderer.labels.template.location = 0.5;
        categoryAxis.renderer.grid.template.strokeOpacity = 0.08;

        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.extraMax = 0.1;
        valueAxis.renderer.grid.template.strokeOpacity = 0.08;

        chart.seriesContainer.zIndex = -10;


        var series = chart.series.push(new am4charts.RadarColumnSeries());
        series.dataFields.categoryX = "country";
        series.dataFields.valueY = "visits";
        series.tooltipText = "{valueY.value}"
        series.columns.template.strokeOpacity = 0;
        series.columns.template.radarColumn.cornerRadius = 5;
        series.columns.template.radarColumn.innerCornerRadius = 0;

        chart.zoomOutButton.disabled = true;

        // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
        series.columns.template.adapter.add("fill", (fill, target) => {
        return chart.colors.getIndex(target.dataItem.index);
        });

        setInterval(()=>{
        am4core.array.each(chart.data, (item)=>{
         // item.visits *= Math.random() * 0.5 + 0.5;
         item.visits *=  0.5 + 0.5;
          //item.visits += 10;
        })
        chart.invalidateRawData();
        }, 2000)

        categoryAxis.sortBySeries = series;

        chart.cursor = new am4charts.RadarCursor();
        chart.cursor.behavior = "none";
        chart.cursor.lineX.disabled = true;
        chart.cursor.lineY.disabled = true;
/******************************************************************************************************************* */
   // Create chart instance
   //var chart2 = container.createChild(am4charts.PieChart);
   
   if(data.length === 0) { return}

        var chart2 = am4core.create("chartdiv2", am4charts.PieChart);
        var title2 = chart2.titles.create();
        title2.text = 'COBERTURA';

        // Add data
        chart2.data = [
          { "sector": "Pdv", "size": data2.length >= 1   ? data2[0].tipo.false  : 0  },
          { "sector": "Prospectos", "size": data2.length === 2   ?  data2[1].tipo.false : 0 }
        ];
        
        // Add labelq  
        chart2.innerRadius = 110;
        var label3 = chart2.seriesContainer.createChild(am4core.Label);
        label3.text =   Math.round(data.length).toString();
        label3.horizontalCenter = "middle";
        label3.verticalCenter = "middle";
        label3.fontSize = 50;
        
        // Add and configure Series
        var pieSeries = chart2.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "size";
        pieSeries.dataFields.category = "sector";
        pieSeries.colors.list = [
          
        // new am4core.color('#FBC02D'),
          new am4core.color('#0288D1'),
          //new am4core.color('#F44336'),
          new am4core.color('#8E24AA'),
          new am4core.color('#388E3C'),
      ]
   
   // Animate chart data
   /*
   function getCurrentData() {
     label.text = currentYear;
     var datac = chartData[currentYear];
     currentYear++;
     if (currentYear > 2014)
       currentYear = 1995;
     return datac;
   }
   */
     
  };

const getDataByLob = (lob) => {
  return _(data)
     // .orderBy('ProspectoId')
      .groupBy('Vendedor')
      .map((item, key) => ({
        'Nombre': key,
          'tipo': _.countBy(item, i => i.Prospecto === 0), //=== true
          'Puntos': _.sumBy(item, i => i.Vendedor > 0 ),
          'Puntos2': _.countBy(item, i => i.Prospecto === 0  || i.Prospecto === 1  ),
          'Puntos3': _.countBy(item, i => i.Prospecto  ===  false ),
          'Puntos4': _.countBy(item, i => i.Prospecto  === true  ),
      }))
      .value();
}
  
    return (
        <>
          <Grid container spacing={2}   >
            <>     
              <Grid item xs={6} md={6}>  

                <div style={{ height: 300 }} id="chartdiv" /> 

              </Grid>
              
              <Grid item xs={6} md={6}>|
            
                { data.length > 0 ?  
                    <div style={{ height: 250 }}  id="chartdiv2"  />  : null }  
              </Grid>
            </>                    
          </Grid>
        </>
    );
};

export default ReporteSeguimientoDash;