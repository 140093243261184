import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import * as am4core from "@amcharts/amcharts4/core";

export const ListarAgentesMap = ({ google }) => {
    const [item, setItem] = useState({});
    const [marker, setMarker] = useState({});
    const [showingInfo, setShowingInfo] = useState(false);
    const coords = { lat: 8.741372162617097,  lng: -75.85947477125524 };
    const vred = 'https://maps.google.com/mapfiles/ms/icons/red-dot.png';
    const filters = useSelector(state => state.tracking.filters);

    var colorSet = new am4core.ColorSet();
    var data = []


    if (filters.pais === 'EC'){
         data = [ {
          "title": "LUBRISINU SAS",
          "Latitud": 8.741372162617097,
          "Longitud": -75.85947477125524,
          "color":colorSet.next()
        }, {
          "title": "Distribuidora de Lubricantes Disterpel",
          "Latitud": 4.06261483209733,
          "Longitud": -76.1950312027396,
          "color":colorSet.next()
        }, {
          "title": "Distribuidora Alger",
          "Latitud": 2.92312199378309557,
          "Longitud": -75.29184502280988,
          "color":colorSet.next()
        }, {
          "title": "DISTRIMAXTER",
          "Latitud": 6.245196355841085,
          "Longitud": -75.58122974522483,
          "color":colorSet.next()
        }, {
          "title": "LUBRITER",
          "Latitud": 4.829500663912997,
          "Longitud": -75.79395042875626,
          "color":colorSet.next()
        }, {
          "title": "Distribuciones Vicpimar s.a.s",
          "Latitud": 10.366601520929667,
          "Longitud": -75.5074412985529,
          "color":colorSet.next()
        }, {
          "title": "London",
          "Latitud": 51.5002,
          "Longitud": -0.1262,
          "url": "http://www.google.co.uk",
          "color":colorSet.next()
        }, {
          "title": "Distertol SAS",
          "Latitud": 4.406876827562931,
          "Longitud": -75.16360189449159,
          "color":colorSet.next()
        }, {
          "title": "New Delhi",
          "Latitud": 28.6353,
          "Longitud": 77.2250,
          "color":colorSet.next()
        }, {
          "title": "Tokyo",
          "Latitud": 35.6785,
          "Longitud": 139.6823,
          "url": "http://www.google.co.jp",
          "color":colorSet.next()
        }, {
          "title": "Ankara",
          "Latitud": 39.9439,
          "Longitud": 32.8560,
          "color":colorSet.next()
        }, {
          "title": "Buenos Aires",
          "Latitud": -34.6118,
          "Longitud": -58.4173,
          "color":colorSet.next()
        }, {
          "title": "Brasilia",
          "Latitud": -15.7801,
          "Longitud": -47.9292,
          "color":colorSet.next()
        }, {
          "title": "Ottawa",
          "Latitud": 45.4235,
          "Longitud": -75.6979,
          "color":colorSet.next()
        }, {
          "title": "Washington",
          "Latitud": 38.8921,
          "Longitud": -77.0241,
          "color":colorSet.next()
        }, {
          "title": "Kinshasa",
          "Latitud": -4.3369,
          "Longitud": 15.3271,
          "color":colorSet.next()
        }, {
          "title": "Cairo",
          "Latitud": 30.0571,
          "Longitud": 31.2272,
          "color":colorSet.next()
        }, {
          "title": "Pretoria",
          "Latitud": -25.7463,
          "Longitud": 28.1876,
          "color":colorSet.next()
        } ];
        }
        if (filters.pais === 'CO'){
           data = [ 
            {
            "title": "London",
            "Latitud": 51.5002,
            "Longitud": -0.1262,
            "url": "http://www.google.co.uk",
            "color":colorSet.next()
          }, {
            "title": "Distertol SAS",
            "Latitud": 4.406876827562931,
            "Longitud": -75.16360189449159,
            "color":colorSet.next()
          }, {
            "title": "New Delhi",
            "Latitud": 28.6353,
            "Longitud": 77.2250,
            "color":colorSet.next()
          }, {
            "title": "Tokyo",
            "Latitud": 35.6785,
            "Longitud": 139.6823,
            "url": "http://www.google.co.jp",
            "color":colorSet.next()
          }, {
            "title": "Ankara",
            "Latitud": 39.9439,
            "Longitud": 32.8560,
            "color":colorSet.next()
          }, {
            "title": "Buenos Aires",
            "Latitud": -34.6118,
            "Longitud": -58.4173,
            "color":colorSet.next()
          }, {
            "title": "Brasilia",
            "Latitud": -15.7801,
            "Longitud": -47.9292,
            "color":colorSet.next()
          }, {
            "title": "Ottawa",
            "Latitud": 45.4235,
            "Longitud": -75.6979,
            "color":colorSet.next()
          }, {
            "title": "Washington",
            "Latitud": 38.8921,
            "Longitud": -77.0241,
            "color":colorSet.next()
          }, {
            "title": "Kinshasa",
            "Latitud": -4.3369,
            "Longitud": 15.3271,
            "color":colorSet.next()
          }, {
            "title": "Cairo",
            "Latitud": 30.0571,
            "Longitud": 31.2272,
            "color":colorSet.next()
          }, {
            "title": "Pretoria",
            "Latitud": -25.7463,
            "Longitud": 28.1876,
            "color":colorSet.next()
          } ];
          }


    const onMarkerClick = (marker, item) => {
        setItem(item);
        setMarker(marker);
        setShowingInfo(true);
    };
    
    return (
        <Map
            initialCenter={ coords}
            google={google}
            style={{width: '100%', height: '100%', position: 'relative'}}
            zoom={6}
        >
            {data.map((item, key) => (
                <Marker
                    key={key}
                    position={{lat: parseFloat(item.Latitud), lng: parseFloat(item.Longitud)}}
                    title={item.title}
                    onClick={(props, marker, e) => onMarkerClick(marker, item)}
                    //icon={ item.Anulado === 1 ?  vgreen  :vred}
                    icon = {vred }
                />
            ))}
            <InfoWindow
                marker={marker}
                visible={showingInfo}
            >
                <div>
                    <h3>{item.NombreComercial}</h3>
                    <h3>V-{item.Nombre}</h3>
                </div>
            </InfoWindow>
        </Map>
    )
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyCrwct6vhZk5TTSuht7PpA0kAVSdoWSB7s"
})(ListarAgentesMap);