import React, { useEffect } from "react";
// import { useSelector } from "react-redux";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import './Graphics.css';

am4core.useTheme(am4themes_material);
am4core.useTheme(am4themes_animated);

function AcumuladoNacional(props) {

    useEffect(() => {
   
        var container = am4core.create("chartdivx", am4core.Container);
    
        container.width = am4core.percent(100);
        container.height = am4core.percent(100);
        container.layout = "vertical";

    
        // Themes begin
    
        createChart(container, props.data);
    
        return () => {
          container.dispose();
        };
        // eslint-disable-next-line
      }, []);

   const createChart = (container, data) => {
       
        am4core.useTheme(am4themes_animated);
        // Themes end
  

       // Create chart instance
        var chart = am4core.create("chartdivx", am4charts.PieChart);
      // Add and configure Series
      var pieSeries = chart.series.push(new am4charts.PieSeries());

        pieSeries.dataFields.value = "litres";
        pieSeries.dataFields.category = "country";

        // Let's cut a hole in our Pie chart the size of 30% the radius
        chart.innerRadius = am4core.percent(40);

        // Put a thick white border around each Slice
        pieSeries.slices.template.stroke = am4core.color("#fff");
        pieSeries.slices.template.strokeWidth = 2;
        pieSeries.slices.template.strokeOpacity = 1;
        pieSeries.slices.template
          // change the cursor on hover to make it apparent the object can be interacted with
          .cursorOverStyle = [
            {
              "property": "cursor",
              "value": "pointer"
            }
          ];

        pieSeries.alignLabels = false;
       // pieSeries.labels.template.bent = true;  // semicirculo alrededor
        pieSeries.labels.template.radius = 6;
      
        pieSeries.labels.template.padding(0,0,0,0);
   

        pieSeries.ticks.template.disabled = true;

        // Create a base filter effect (as if it's not there) for the hover to return to
        var shadow = pieSeries.slices.template.filters.push(new am4core.DropShadowFilter());
        shadow.opacity = 0;

        // Create hover state
        var hoverState = pieSeries.slices.template.states.getKey("hover"); // normally we have to create the hover state, in this case it already exists

        // Slightly shift the shadow and make it more prominent on hover
        var hoverShadow = hoverState.filters.push(new am4core.DropShadowFilter());
        hoverShadow.opacity = 0.7;
        hoverShadow.blur = 5;

          //CUSTOMIZAR  em
          pieSeries.slices.template.propertyFields.fill = "color"; //em
          pieSeries.labels.template.text = "{value.percent.formatNumber('#.0')}%";
          pieSeries.labels.template.radius = -20;
          pieSeries.labels.template.align= "center";
          pieSeries.labels.template.fill = am4core.color("white");
        // Add a legend
        chart.legend = new am4charts.Legend();
        chart.legend.position = "right";
        chart.legend.align = "right";
        chart.legend.contentAlign = "right";
        chart.legend.fontSize = 11;
        chart.legend.maxWidth = 200;
        chart.legend.scrollable = true;
        
        var markerTemplate = chart.legend.markers.template;
        markerTemplate.width = 15;
        markerTemplate.height = 15;
        chart.data = data;
    
        // chart.data = [{
        //   "country": "Agentes Distrib Mobil",
        //   "litres":  139.9,
        //   "color": "blue"  //em
        // },{
        //   "country": "Agentes Distrib Terpel",
        //   "litres": 165.8,
        //   "color": "red"
        // }, {
        //   "country": "Otros",
        //   "litres":501.9,
        //   "color": "grey"
        // }];
  
    };

    return (
        <div className="containerGra">
            <div style={{ height: 200 }} id="chartdivx" />
        </div>
    );
}

export default AcumuladoNacional;