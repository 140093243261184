import clsx from 'clsx';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    onDropDistribuidor,
    onSearchDistribuidoresClick
} from '../../store/user/actions';

import { FieldSet } from '@sigeco/tools';
import Chip from '@material-ui/core/Chip';
import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/core/Icon';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
    chip: {
        margin: '0.375rem 0.375rem 0 0'
    },
    content: {
        padding: '0.5rem 0.75rem 0.75rem'
    },
    icon: {
        fontSize: '1rem'
    },
    root: {
        flex: '0 0 auto',
        marginBottom: '0.75rem',
        '@media (min-width: 1401px)': {
            marginBottom: '1rem',
        }
    }
}));

const UserDistribuidor = () => {
    const classes = useStyles();
    const distribuidores = useSelector(state => state.user.entity.distribuidores);
    const formState = useSelector(state => state.user.formState);
    const dispatch = useDispatch();

    const handleDelete = distribuidorId => dispatch(onDropDistribuidor(distribuidorId))

    return (
        <div className={classes.root}>
            <FieldSet  iconCls="fas fa-industry" title="Agentes Asignados">
                <div className={classes.content}>
                    {(formState === 'edit' || formState === 'new') && (<div>
                        <Fab aria-label="agregar" color="primary" onClick={() => dispatch(onSearchDistribuidoresClick())} size="small">
                            <Icon className={clsx("fas fa-plus", classes.icon)} style={{ fontSize: '0.875rem'}}/>
                        </Fab>
                    </div>)}
                    {distribuidores.map((item, index) => {
                        return (
                            <div key={index} style={{ display: 'inline-flex' }}>
                                <Chip
                                    className={classes.chip}
                                    color="default"
                                    deleteIcon={<Icon className="fas fa-times-circle" style={{ fontSize: '1rem'}} />}
                                    label={item.razonSocial}
                                    onDelete={ (formState === 'edit' || formState === 'new') ? () => handleDelete(item.distribuidorId) : null}
                                    variant="outlined" 
                                />
                            </div>
                        )
                    })}
                </div>
            </FieldSet>
        </div>
    );

};

export default UserDistribuidor;