import axios from "axios";
import format from "date-fns/format";
import _ from "lodash";
import { IdentityUrl } from "../../../config";
import {
  closeAdvancedSearch,
  closeMessageBox,
  openAdvancedSearch,
  openMessageBox,
} from "../../main/actions";
import {
  FILTER_MAP_VENDEDOR,
  HIDE_POPUP_CLIENT,
  RECEIVE_DATA,
  //RECEIVE_OLAS_LIST, otro campo
  SET_MASK,
  SET_ORDER,
  SET_ORDER_DETAIL,
  SET_SELECTED_ROW,
  SHOW_POPUP_CLIENT,
  UPDATE_FIELD_FILTER,
  //UPDATE_TABLE_COLUMN,
} from "./types";

import { exportToSpreadsheet, cortarArreglo } from "../../../helpers/commons";

export const btnExport = () => async (dispatch, getState) => {
  if (getState().reportevisitas.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }
  if (getState().reportevisitas.store.data.length === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "No hay información para exportar.",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }

  try {
    dispatch({ type: SET_MASK, loading: true });

    let head = getState().reportevisitas.tableExport.columns;
    //let head = getState().reportevisitas.table.columns;
    //console.log (head)
   
     var aux= [];
     for (var c in head){
       aux[c] = Object.values(head[c])[1];
     }

     var carArray = [];
     carArray.push(aux);

    let json = getState().reportevisitas.data;
    //   console.log(json)
     var json1 = json.map(obj =>
       obj.ConLlamada === 1 ? { ...obj, ConLlamada: "Si" } : { ...obj, ConLlamada: "No" }
     );

     for (var i in json1){

      let ax = Object.values(json1[i]);
      
        carArray.push(ax);  
      }
     
    //console.log(carArray);
    //return 0;

   exportToSpreadsheet(carArray, "GestionRutasDetalle") ;

  } catch (error) {
    console.log(error);
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message:
          (error.response.data.eventLogId === 0
            ? ""
            : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    setTimeout(() => {dispatch({ type: SET_MASK, loading: false })}, 1000);
  }
};

export const btnExport1 = () => async (dispatch, getState) => {
  if (getState().reportevisitas.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }
  if (getState().reportevisitas.store.data.length === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "No hay información para exportar.",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }

  try {
    dispatch({ type: SET_MASK, loading: true });

    //let head = getState().reportevisitas.tableExport.columns;
    let head = getState().reportevisitas.table.columns;
    //console.log (head)
   
    // var aux= [];
    // for (var c in head){
    //   aux[c] = Object.values(head[c])[1];
    // }

    // var carArray = [];
    // carArray.push(aux);

    let json = getState().reportevisitas.store.data;
    //   console.log(json)
    // var json1 = json.map(obj =>
    //   obj.ConLlamada === 1 ? { ...obj, ConLlamada: "Si" } : { ...obj, ConLlamada: "No" }
    // );

    //   for (var i in json1){

    //     let ax = Object.values(json1[i]);
      
    //     carArray.push(ax);  
    //   }
     
    //console.log(carArray);
    //return 0;

   exportToSpreadsheet(cortarArreglo(head,json), "GestionRutasResumen") ;

  } catch (error) {
    console.log(error);
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message:
          (error.response.data.eventLogId === 0
            ? ""
            : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    setTimeout(() => {dispatch({ type: SET_MASK, loading: false })}, 1000);
  }
};

export const btnSearch = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_MASK, loading: true });

    if (getState().reportevisitas.filters.distribuidorId === 0) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "Debe seleccionar un Agente..",
          callback: () => dispatch(closeMessageBox()),
        })
      );
      return;
    }
    let url =
      `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/reportes/movilidad/cumplimientovisita?` +
      `distribuidorId=${getState().reportevisitas.filters.distribuidorId}` +
      `&supervisorId=${getState().reportevisitas.filters.supervisorId}` +
      `&vendedorId=${getState().reportevisitas.filters.vendedorId}` +
      //+ `&ubigeoId=${getState().reportevisitas.filters.ubigeoId}`
      `&desde=${format(getState().reportevisitas.filters.desde,  "yyyy-MM-dd" )}` +
      `&hasta=${format(getState().reportevisitas.filters.hasta, "yyyy-MM-dd")}`;
    let response = await axios.get(encodeURI(url));
    let data = response.data;

    if (data.length === 0) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "No existe Registro  para esta consulta",
          callback: () => dispatch(closeMessageBox()),
        })
      );
      return;
    }

    var i;
    var cont = 0;
    for (i = 0; i < data.length; i++) {
      if (data[i].conPedido === 1 || data[i].montoPedido > 0) {
        cont = cont + 1;
      }
    }
    let sumarizado = _(data)
      .groupBy("dVendedor")
      .map((item, key) => ({    
        dVendedor: key,
        proyectados:  _.countBy(item, (i) => i.Proyectado >= 1),
        //_.sumBy(item, "Proyectado"), //=== true
        ejecutados: _.sumBy(item, "Ejecutado"), // === true
        fueraRuta: _.countBy(item, (i) => i.conVisita >= 1),
        conLlamada: _.countBy(item, (i) => i.ConLlamada >= 1),
        //=== true && i.proyectado === false
        fuerar: _.countBy(item, (i) => i.conPedido >= 1),
        pedido: _.countBy(item, (i) => i.conPedido >= 1),
        prospecto: _.countBy(item, (i) => i.EsProspecto >= 1), 
      }))
      .value();

    //'pdv': _.sumBy(item, 'cuentaPDV'),
    let storeData = _.map(sumarizado, (item) => {
      return {
        dVendedor: item.dVendedor,
        cuentaPuntoVentaP: item.proyectados["true"] ?? 0, 
        cuentaPuntoVentaE: item.ejecutados,      
        avance: parseInt((item.ejecutados / item.proyectados["true"]) * 100)
          ? parseInt((item.ejecutados / item.proyectados["true"]) * 100) + "%"  : 0 + "%",
        cuentaFueraRuta: item.fueraRuta["true"] ?? 0,
        cuentaFuera: item.fuerar["true"] ?? 0,
        supavance: !item.proyectados["true"]   ? 0 + "%"
        : item.fueraRuta["true"] > 0 && item.ejecutados > 0
        ? parseInt(
            ((item.ejecutados + item.fueraRuta["true"] ?? 0) *100  /  item.proyectados["true"]   )  ) + "%"
        : parseInt(((item.fueraRuta["true"] ?? 0) / item.proyectados["true"]  ) * 100) +  "%",
        cuentaPedido: item.pedido["true"] ?? 0,
        cuentaProspecto: item.prospecto["true"] ?? 0,
        cuentaConLlamada: item.conLlamada["true"] ?? 0,
        total:  item.ejecutados+    ( item.fueraRuta["true"] ?? 0) + (item.prospecto["true"] ?? 0) + (item.conLlamada["true"] ?? 0)        ,
      };
    });
  
    dispatch({ type: RECEIVE_DATA, payload: data, storeData });
  } catch (error) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message:
          (error.response.data.eventLogId === 0
            ? ""
            : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    dispatch({ type: SET_MASK, loading: false });
  }

  /* ----------------------------------------------------------------------------------*/
  /*
try {
        
    let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/reportes/reportepedidos?` 
    + `&desde=${format(getState().reportevisitas.filters.desde, 'yyyy-MM-dd')}`
    + `&hasta=${format(getState().reportevisitas.filters.hasta, 'yyyy-MM-dd')}`
        + `&vendedorid=${getState().reportepedidos.filters.vendedorId}`
    
    let response = await axios.get(encodeURI(url));
    let datap = response.data;

    let cuenta = _(datap.data)
    .groupBy('Vendedor')
    .map((item, key) => ({
        'Vendedor': key,
        'proyectados': _.countBy(item, i => i.PedidoId > 0 ),
         }))
    .value();


let storePed = _.map(cuenta, (item) => {
    return {
        Vendedor: item.Vendedor,
        cuentaPuntoVentaE: item.proyectados['true'] ?? 0
    };
});

        dispatch({ type: RECEIVE_OLAS_LIST, pay: datap, storePed });

    } catch (error) {
        let msgError = '';
        dispatch({ type: SET_MASK, loading: false });

        if (error.response) {
        
         if(error.response.status === 400) {
                msgError = (error.response.data.eventLogId === 0 ? 'No existe Registro  para esta fecha ' : `EventoId: ${error.response.data.eventLogId}. `+ error.response.data.message) 
         }else{
            msgError = (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message;
         }
        }
        else {
            msgError = 'Error al conectar con el servidor';
        }

        dispatch(openMessageBox({
            button: 'ok',
            icon: 'error',
            message: msgError,
            callback: () => dispatch(closeMessageBox())
        }));
    }finally{
        dispatch({ type: SET_MASK, loading: false });
    }

    */
};

export const closeDialogClient = () => ({ type: HIDE_POPUP_CLIENT });

export const filterMapByVendedor = (dVendedor) => ({
  type: FILTER_MAP_VENDEDOR,
  dVendedor,
});

export const onSearchDistribuidorClick = () => async (dispatch) => {
  let fields = [
    { dataKey: "razonSocial", label: "Agente", type: "string", width: 225 },
  ];

  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de Agente",
      getData: async (params) => {
        let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`;
        let response = await axios.get(encodeURI(url), { params: params });
        //console.log(response.data)
        return response.data;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(onUpdateFieldFilter("distribuidorId", result[0].distribuidorId));
          dispatch(onUpdateFieldFilter("dDistribuidor", `${result[0].razonSocial}`));
          dispatch(onUpdateFieldFilter("supervisorId", ""));
          dispatch(onUpdateFieldFilter("dSupervisor", ""));
          dispatch(onUpdateFieldFilter("vendedorId", ""));
          dispatch(onUpdateFieldFilter("dVendedor", ""));
        }
      },
    })
  );
};

export const onSearchSupervisorClick = () => async (dispatch, getState) => {
  let fields = [
    {
      dataKey: "nombre",
      label: "Nombre",
      type: "string",
      search: true,
      width: 225,
    },
    { dataKey: "nombreUsuario", label: "Usuario", type: "string", width: 115 },
  ];

  if (getState().reportevisitas.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }
  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de supervisor",
      getData: async (params) => {
        let url = `${IdentityUrl}/api/v1/usuarios?rol=Supervisor`;
        let response = await axios.get(encodeURI(url), { params: params });
        let distribuidorId = getState().reportevisitas.filters.distribuidorId;

        if (distribuidorId > 0) {
          response.data.data = response.data.data.filter(
            (f) => f.distribuidorId === distribuidorId
          );
        }

        return response.data;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(onUpdateFieldFilter("supervisorId", result[0].usuarioId));
          dispatch(onUpdateFieldFilter("dSupervisor", `${result[0].nombre}`));
          dispatch(onUpdateFieldFilter("vendedorId", ""));
          dispatch(onUpdateFieldFilter("dVendedor", ""));
        }
      },
    })
  );
};

// export const onSearchUbigeoClick = () => async (dispatch, getState) => {
//     let fields = [
//         { 'dataKey': 'codigo', 'label': 'Código', 'type': 'string', 'width': 115 },
//         { 'dataKey': 'dUbigeo', 'label': 'Ubigeo', 'type': 'string', 'search': true, 'width': 225 }
//     ];

//     dispatch(openAdvancedSearch({
//         autoLoad: false,
//         data: [],
//         fields: fields,
//         paginated: true,
//         remote: true,
//         title: 'Selección de ubigeo',
//         getData: async (params) => {
//             let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/agentes/ubigeos`;
//             let response = await axios.get(encodeURI(url), { params: params });

//             return response.data;
//         },
//         callback: (btn, result) => {
//             dispatch(closeAdvancedSearch());
//             if(btn === 'yes') {
//                 dispatch(onUpdateFieldFilter('ubigeoId', result[0].ubigeoId));
//                 dispatch(onUpdateFieldFilter('dUbigeo', `${result[0].dUbigeoId}`));
//             }
//         }
//     }));
// };

export const onSearchVendedorClick = () => async (dispatch, getState) => {
  let fields = [
    {
      dataKey: "nombre",
      label: "Nombre",
      type: "string",
      search: true,
      width: 225,
    },
    { dataKey: "nombreUsuario", label: "Usuario", type: "string", width: 115 },
  ];
  if (getState().reportevisitas.filters.supervisorId === "") {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Supervisor..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }

  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de Vendedor",
      getData: async (params) => {
        let url = `${IdentityUrl}/api/v1/usuarios/porjerarquia?usuarioId=${
          getState().reportevisitas.filters.supervisorId
        }`;
        let response = await axios.get(encodeURI(url), { params: params });
        let distribuidorId = getState().reportevisitas.filters.distribuidorId;

        if (distribuidorId > 0) {
          response.data.data = response.data.data.filter(
            (f) => f.distribuidorId === distribuidorId
          );
        }

        return response.data;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(onUpdateFieldFilter("vendedorId", result[0].usuarioId));
          dispatch(onUpdateFieldFilter("dVendedor", `${result[0].nombre}`));
        }
      },
    })
  );
};

export const onUpdateColumn = (column) => (dispatch, getState) => {
  if (!column) return;
  getState().reportevisitas.tableClient.columns.map((item , i)=> {
    if(item.dataKey !== column.dataKey) {
        return item;
    }else{
            item.width =  column.width;
    }
    return {
       // ...item,
      //  width: column.width
    };
  });
  // let columns = getState().reportevisitas.table.columns.map((item) => {
  //   if (item.dataKey !== column.dataKey) {
  //     return item;
  //   }

  //   return {
  //     ...item,
  //     width: column.width,
  //   };
  // });
  //   dispatch({ type: UPDATE_TABLE_COLUMN, columns });
};

export const onUpdateFieldFilter = (key, value) => (dispatch, getState) => {
  dispatch({ type: UPDATE_FIELD_FILTER, key, value });

  dispatch({ type: RECEIVE_DATA, payload: [], storeData: [] });
};
export const onUpdateFielddata = (key, value) => (dispatch, getState) => {
  // dispatch({ type: UPDATE_FIELD_DATA, key, value })
  //dispatch({ type: RECEIVE_DATA, columns: [], data: [] })
  //  dispatch({ type: RECEIVE_DATA, payload:[],  store:[] })
  //dispatch({ type: RECEIVE_DATA, payload: data, storeData });
};
export const Loadding = (key) => (dispatch, getState) => {
      dispatch({ type: SET_MASK, loading: key });
};


export const openDialogClient = (data) => (dispatch, getState) => {
  try {
    var storeData = getState().reportevisitas.data.filter(
      (f) => f.dVendedor === data.dVendedor
    );
    //console.log("aqui: " + storeData)

    dispatch({ type: SHOW_POPUP_CLIENT, storeData });
  } catch (error) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message: error,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  }
};

export const setOrder = (order, orderBy) => ({ type: SET_ORDER,   order,   orderBy });

export const setOrderDetail = (order, orderBy) => ({ type: SET_ORDER_DETAIL,   order,   orderBy });

export const setSelectedRow = (selected) => ({ type: SET_SELECTED_ROW,   selected });
