import React, { useLayoutEffect,useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import makeStyles from '@material-ui/core/styles/makeStyles';
import { topTitle , aligTitle , topSubTitle , aligSubTitle, sizeTitle, sizeSubTitle } from '../../../config';


am4core.useTheme(am4themes_animated);

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        padding: "1.5rem ",

    }, 
    wrap: {
        flex: '1 1 auto',
        "@media (max-width: 768px)": {
            width:"290% !important",
            overflowY:"hidden",
        },
        "@media (max-width: 425px)": {
            width:"290% !important",
            overflowY:"hidden",
        },
        "@media (max-width: 375px)": {
            width:"290% !important",
            overflowY:"hidden",
        },
        "@media (max-width: 320px)": {
            width:"330% !important",
            overflowY:"hidden",
        }
    }
}));

const ReporteConstruccionPDV = () => {
    const classes = useStyles();
    const data = useSelector(state => state.reporteconstruccion.data);
    const agente = useSelector(state => state.reporteconstruccion.filters.dDistribuidor);
    const ola = useSelector(state => state.reporteconstruccion.filters.dOla);
  
    const data3 = [...new Set(data)];
    const [suma, setSuma] = useState(0);
    const [suma2, setSuma2] = useState(0);
   //console.log(data)
    useLayoutEffect(() => {
        const getData = () => {
            return _(data)
                .groupBy('dSegmento')
                .map((item, key) => ({
                    'marca': key,
                    'pdv': _.sumBy(item, 'cuentaPDV'),
                    'pdvmercado': _.sumBy(item, 'mercadoPDV')  
                }))
                .value()
        }
        const getSummary = () => {
            const summary = {
                total: _.sumBy(data, 'cuentaPDV') ,
            }
            return summary.total
        }
        const getSummary2 = () => {
            const summary = {
                total2: _.sumBy(data, 'mercadoPDV') ,
            }
            return summary.total2
        }

        let chart = am4core.create("chartdiv", am4charts.PieChart);
        chart.height = 400;

       
       var title2 = chart.titles.create();
       title2.text =  "Porcentaje Puntos Actuales:  " + (Intl.NumberFormat('es-MX').format(suma/suma2)*100).toFixed(2) + " %";
       title2.fontWeight = 600;
       title2.fontSize = sizeSubTitle;
       title2.align =  aligSubTitle;
       title2.marginTop = topSubTitle ;
      // title2.marginTop = 5;

        var title = chart.titles.create();
        title.text =  "Puntos Actuales:  " + Intl.NumberFormat('es-MX').format(suma)+ " Pdv";
        title.fontWeight = 600;
        title.fontSize = sizeTitle;
        title.align = aligTitle;
        title.marginTop = topTitle ;
       // title.marginTop = 5;

       var titulo = chart.titles.create();
       if(agente.length >0)
       {
        titulo.text = "Cobertura por Canal /Agente: " + agente + ' - Ola: ' + ola
       }
       else 
       {
        titulo.text = "Cobertura por Canal /Agente: Todos - Ola: " + ola;
       }
       titulo.fontWeight = 600;
       titulo.fontSize = 22;
       titulo.align = "center";
      // titulo.paddingTop = -50;


        chart.innerRadius = am4core.percent(40);
        // chart.startAngle = 5;
        // chart.endAngle = 270;

        //Add third series
        let pieSeries3 = chart.series.push(new am4charts.PieSeries());
        pieSeries3.dataFields.value = "pdv"; //pdvmercado
        pieSeries3.dataFields.category = "marca";
        pieSeries3.slices.template.stroke = am4core.color("#fff");
        pieSeries3.slices.template.strokeWidth = 2;
        pieSeries3.slices.template.strokeOpacity = 1;
        pieSeries3.slices.template.states.getKey("hover").properties.shiftRadius = 0;
        pieSeries3.slices.template.states.getKey("hover").properties.scale = 1.1;
        pieSeries3.slices.template.propertyFields.fill = "colorHex";

                 //CUSTOMIZAR  em
        pieSeries3.labels.template.text = "{value.percent.formatNumber('#.0')}%";
        pieSeries3.legendSettings.itemValueText = "{valueY.percent}";//em

        // Add a legend
        chart.legend = new am4charts.Legend();
        chart.legend.position = "right";
        chart.legend.align = "right";
        chart.legend.contentAlign = "right";
        chart.legend.fontSize = 11;
        chart.legend.maxWidth = 200;
        chart.legend.scrollable = true;

        //Add data
        chart.data = getData();
        setSuma(0);
        setSuma2(0);

        setSuma(getSummary());
        setSuma2(getSummary2());
        //console.log(getSummary)
        if(data.length > 0) {
            chart.exporting.menu = new am4core.ExportMenu();
            chart.exporting.menu.align = "left";
            chart.exporting.menu.verticalAlign = "top"
            chart.exporting.menu.items = [
                {
                  "label": "...",
                  "menu": [
                    {
                      "label": "Imagen",
                      "menu": [
                        { "type": "png", "label": "PNG" },
                        { "type": "jpg", "label": "JPG" },
                        { "type": "svg", "label": "SVG" },
                        { "type": "pdf", "label": "PDF" }
                      ]
                    },  {
                      "label": "Imprimir", "type": "print"
                    }
                  ]
                }
              ]
        }

        return () => {
            chart.dispose();
        }
}, [data, suma, suma2,ola,agente]);
    
    return (
        <div className={classes.root}>
            <div style={{overflowY: data3.length >=1 ? "hidden" : "none" }}> 
            <div style={{ height: 450 ,fontSize: '1rem' }}  id="chartdiv"  className={classes.wrap}>
            </div>
            </div>
        </div>
    );
};

export default ReporteConstruccionPDV;