const initialState = {
    loadingStore: false,
    filters: {
        desde: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        hasta: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        olaId: 0,
        distribuidorId: 0,
        usuarioId: '',
        dOla: '',
        dDistribuidor: '',
        dUsuario: ''
    },
    store: {
        data: [],
        selected: []
    },
    table: {
        order: 'asc',
        orderBy: '',
        columns: []
    }
};

export const reportecoberturaReducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        default:
            return state;
    }
}