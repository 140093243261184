export const FILTER_MAP_VENDEDOR = 'marketing/reporteidentificacionmercado/filter-map-vendedor';
export const HIDE_POPUP_CLIENT = 'marketing/reporteidentificacionmercado/hide-popup-client';
export const RECEIVE_DATA = 'marketing/reporteidentificacionmercado/receive_data';
export const RECEIVE_V_LIST = 'marketing/reporteidentificacionmercado/receive-v-list';
export const SET_MASK = 'marketing/reporteidentificacionmercado/set-mask';
export const SET_ORDER = 'marketing/reporteidentificacionmercado/set-order';
export const SET_SELECTED_ROW = 'marketing/reporteidentificacionmercado/set-selected-row';
export const SHOW_POPUP_CLIENT = 'marketing/reporteidentificacionmercado/show-popup-client';
export const UPDATE_FIELD_FILTER = 'marketing/reporteidentificacionmercado/update-field-filter';
export const UPDATE_TABLE_COLUMN = 'marketing/reporteidentificacionmercado/update-table-column';
export const RECEIVE_OLAS_LIST = 'marketing/reporteidentificacionmercado/receive_olas_list';
export const UPDATE_DIST_FIELD_FILTER = 'marketing/reporteidentificacionmercado/update-dist-field-filter';
