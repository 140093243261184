import React , {  useState, useEffect} from 'react';
import {  useSelector } from 'react-redux';

const caja = {
  position: 'relative',
  display: 'block',  //inline-block',
}

const p = {
  position: 'absolute',
  top: '25%',
  left: '70%',
  color: 'white',
};

const q = {
  position: 'absolute',
  top: '65%',
  left: '80%',
  color: 'white',
};

// const pageStyle = `
//   @page {
//     size: 80mm 50mm;
//   }

//   @media all {
//     .pagebreak {
//       display: none;
//     }
//   }

//   @media print {
//     .pagebreak {
//       page-break-before: always;
//     }
//   }
// `


const PdfPresupuesto = (items) => {
  const mes =   useSelector(state => state.resumen.filters.desde.getFullYear());  
  const fecha =  useSelector(state => state.resumen.store.meses[state.resumen.filters.desde.getMonth()] +',  '+ mes); 
  const ComponentToPrint  = () => {
    //render() {
      return (
        <>
        <div   style={{    width: "800px",  height: "1200px"   }}  >
        <div style={caja}>  
        {mes <= 2021  &&
                   <>  
          <img alt='logo'  src={ './images/mobil-gigantes.png'}  style={{width: '125%', height:'130px'}}/>
          <div style={p}><h2>Presupuesto</h2></div>   
          <div style={q}><h4>       {fecha}        </h4></div>     
          </>
        }
         {mes=== 2022  &&
                   <>  
          <img alt='logo'  src={ './images/mobil-gigantes2022.png'}  style={{width: '125%', height:'130px'}}/>
          <div style={p}><h2>Presupuesto</h2></div>   
          <div style={q}><h4>       {fecha}        </h4></div>           
          </>
        }
         </div>
        
        <table>
          <thead>
            <tr>
              <th style={{width: '10%', height:'100%'}}></th>   
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">
              </th>
            </tr>
          </tbody>
        </table>
    
        <table>

          <tbody>
            <tr>
              <th scope="row" style={{width: '250px', height:'100%'}}></th>
              <td style={{ border: '1px solid', borderColor: 'white',  backcolor: '#00FF00', backgroundColor: '#0f2462', color: 'white', width: '230px'}}>
                  %Cumplimiento 100% al 102,99%</td>
                  <td style={{ border: '1px solid', borderColor: 'white',  backcolor: '#00FF00', backgroundColor:  '#0f2462', color: 'white', width: '50px'}}>
              Tipo</td>
              <td style={{ border: '1px solid', fontsize: '15px', borderColor: '#0f2462',   textAlign:  'center', color: '#191970', width: '280px'}}>USD $100</td>
            </tr>
           
                {renderBody(items.items.filter(checkAdult)) } 
            <tr style={{height: '30px'}} ></tr>

            <tr>
              <th scope="row"></th>
              <td style={{ border: '1px solid',  borderColor: 'white',  backcolor: '#1d44b8', backgroundColor: '#0f2462', color: 'white'}}>
                % Cumplimiento del 103% al 104,99%</td>
                <td style={{ border: '1px solid', borderColor: 'white',  backcolor: '#00FF00', backgroundColor:  '#0f2462', color: 'white', width: '50px'}}>
              Tipo</td>
              <td style={{ border: '1px solid', fontsize: '15px', borderColor: '#0f2462',   textAlign:  'center', color: '#191970'}}>{mes === 2021 ? 'USD $200' : 'USD $150'}</td>
            </tr>

            {renderBody(items.items.filter(checkCien)) }
            <tr style={{height: '30px'}} ></tr>  

            <tr>
              <th scope="row"></th>
              <td style={{ border: '1px solid', borderColor: 'white',  backcolor: '#1d44b8', backgroundColor: '#0f2462', color: 'white'}}>
                % Cumplimiento Igual o mayor al 105%%</td>
                <td style={{ border: '1px solid', borderColor: 'white',  backcolor: '#00FF00', backgroundColor:  '#0f2462', color: 'white', width: '50px'}}>
              Tipo</td>
              <td style={{ border: '1px solid', fontsize: '15px', borderColor: '#0f2462',   textAlign:  'center', color: '#191970'}}>{mes === 2021 ? 'USD $250' : 'USD $200'}</td>
            </tr>

            {renderBody(items.items.filter(checkDos)) }
            <tr style={{height: '30px'}} ></tr>  

            {mes=== 2022  &&
                <>  
                
            <tr>
              <th scope="row"></th>
              <td style={{ border: '1px solid',  borderColor: 'white',  backcolor: '#00FF00', backgroundColor:  '#0f2462', color: 'white'}}>
               B2B </td>
              <td style={{ border: '1px solid', borderColor: 'white',  backcolor: '#00FF00', backgroundColor:  '#0f2462', color: 'white', width: '130px'}}>
             ASESOR</td>
           
            </tr>
   
               {renderBody(presup) }      
              </>
            }
           
            <tr style={{breakBefore: 'page', breakInside: 'avoid', breakAfter: 'page', position:'relative'}}></tr>
                  
      </tbody>
    </table>
    </div>
    </>
);
    //}
  }

  function checkAdult(age) {     return age.pago === 100;   }
  function checkCien(age) {    
    if (mes <=2021 ){
      return age.pago === 150; 
    }
    if (mes ===2022 ){
      return age.pago === 200; 
    }
  }
  function checkDos(age) { 
    if (mes <=2021 ){
      return age.pago === 200; 
    }
  if (mes === 2022 ){
      return age.pago === 250; 
  }     
  }

    const renderBody = (employees) => {
        return employees && employees.map(({   id, distribuidor, tipo, asesor, cumplimiento, pago }, index) => {
            return (
                 <tr style={{ }} key={index}> 

                  {/*    <td  style={{width: '80px', height:'100%', background: 'white', opacity: '0.6'}}></td>   
                    */                   }  
                    <td  style={{width: '80px', height:'100%'}}></td>   
                    <td style={{ width : '70px' , fontSize: '12px',  backgroundColor: index%2 === 0 ?  '#cad1d7': '#d5dbe0'   }}>{distribuidor}</td>
                    <td style={{fontSize: '12px', backgroundColor: employees.id%2 === 0 ?  '#cad1d7': '#d5dbe0'  }}> {tipo}</td>
                    <td style={{fontSize: '12px' , backgroundColor: index%2 === 0 ?  '#cad1d7': '#d5dbe0' }}> -{asesor }</td>
                </tr> 
            ) 
        })
    }

    const store = useSelector(state => state.resumen.store);
 
    const [presup, setPresup] = useState([]);

    useEffect(() => {
      setPresup(store.clinuevnueve)      
    }, [store.clinuevnueve] )

    return (
      <ComponentToPrint  />
    )
  
  };
  
  export default PdfPresupuesto;