import {
    FILTER_MAP_VENDEDOR,
    HIDE_POPUP_CLIENT,
    RECEIVE_DATA,
    RECEIVE_OLAS_LIST,
    SET_MASK,
    SET_ORDER,
    SET_ORDER_DETAIL,
    SET_SELECTED_ROW,
    SHOW_POPUP_CLIENT,
    UPDATE_FIELD_FILTER,
    UPDATE_TABLE_COLUMN
} from './types';

const initialState = {
    loading: false,
    data: [],
    dVendedor: '',
    openPopup: false,
    filters: {
        distribuidorId: 0,
        supervisorId: '',
        vendedorId: '',
 //       ubigeoId: 0,
        desde: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        hasta: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        dDistribuidor: '',
        dSupervisor: '',
        dVendedor: '',
        //dUbigeo: '',
        sw: true
    },
    store: {
        data: [],
        selected: [],
    },
    storePed: {
        data: [],
        selected: [],
    },
    storeClient: {
        data: [],
        selected: []
    },
    table: {
        order: 'asc',
        orderBy: '',
        columns: [
            { dataKey: 'dVendedor', label: 'Vendedor', width: 225 },
            { dataKey: 'cuentaPuntoVentaP', label: 'PDV Proyectado', width: 150 },
            { dataKey: 'cuentaPuntoVentaE', label: 'PDV Ejecutado', width: 150 },
            { dataKey: 'avance', label: 'Avance', width: 100 },
            { dataKey: 'cuentaFueraRuta', label: 'Fuera de Ruta', width: 100 },
            { dataKey: 'supavance', label: 'Sup Avance', width: 100 },
            { dataKey: 'cuentaPedido', label: 'Pedidos Realizados', width: 100},
            { dataKey: 'cuentaProspecto', label: 'Prospectos', width: 100},
            { dataKey: 'total', label: 'Total Visitas', width: 100 },
            //{ dataKey: 'cuentaFueraRuta', label: 'Pedidos Fuera de Ruta', width: 150 },
        ]
    },
    tableClient: {
        order: 'asc',
        orderBy: '',
        columns: [
            { dataKey: 'dClienteSucursal', label: 'Punto de Venta', width: 350 },
            { dataKey: 'montoPedido', label: 'Monto Pedido', type: 'number', width: 100 },
            { dataKey: 'ConLlamada', label: 'Llamada', type: 'boolean', width: 50 },         
            { dataKey: 'Ejecutado', label: 'Visita', type: 'boolean', width: 35 },
            { dataKey: 'conVisita', label: 'Fuera de Ruta', type: 'boolean', width: 55, backcolor: 'black' },
            { dataKey: 'conPedido', label: 'Pedido', type: 'boolean', width: 45, backcolor: 'black' },
            { dataKey: 'motivoVisita', label: 'Motivo', width: 235 },
            { dataKey: 'motivoNoCompra', label: 'M. No Compra', width: 235 },
            { dataKey: 'EsProspecto', label: 'Prospecto', type: 'boolean', width: 55 },
            { dataKey: 'channelMap', label: 'Channel', type: 'boolean', width: 45 },
            { dataKey: 'fechaVisita', label: 'Fecha', width: 80 },
            { dataKey: 'horaVisita', label: 'Hora', width: 80 }, 
            { dataKey: 'DuracionVisita', label: 'Duración de V.', width: 90 },   
            { dataKey: 'OrdenVisita', label: 'Orden de V.', width: 90 } ,
            { dataKey: 'Observacion', label: 'Observacion', width: 390 } 
        ]
    },

    tableExport: {
        order: 'asc',
        orderBy: '',
        columns: [

            { dataKey: 'Codigo', label: 'Codigo', width: 350 },
            { dataKey: 'Nit', label: 'Nit', width: 350 },
            { dataKey: 'dVendedor', label: 'Vendedor', width: 350 },
            { dataKey: 'dClienteSucursal', label: 'Punto de Venta', width: 350 },
            { dataKey: 'latitud', label: 'Latitud', width: 250 },
            { dataKey: 'longitud', label: 'Longitud', width: 250 },
            { dataKey: 'latituds', label: 'Latituds', width: 350 },
            { dataKey: 'longituds', label: 'Longituds', width: 350 },
            { dataKey: 'Proyectado', label: 'Proyectado', width: 350 },
            { dataKey: 'Ejecutado', label: 'Visita', type: 'boolean', width: 35 },
            { dataKey: 'foto01', label: 'Foto 01', width: 350 },
            { dataKey: 'foto02', label: 'Foto 02', width: 350 },
            { dataKey: 'fechaVisita', label: 'Fecha', width: 80 },
            { dataKey: 'horaVisita', label: 'Hora', width: 80 }, 
            { dataKey: 'montoPedido', label: 'Monto Pedido', type: 'number', width: 100 },
            { dataKey: 'motivoVisita', label: 'Motivo', width: 235 },
            { dataKey: 'motivoNoCompra', label: 'M. No Compra', width: 235 },
            { dataKey: 'channelMap', label: 'Channel', type: 'boolean', width: 45 },
            { dataKey: 'conVisita', label: 'Fuera de Ruta', type: 'boolean', width: 55, backcolor: 'black' },
            { dataKey: 'conPedido', label: 'Pedido', type: 'boolean', width: 45, backcolor: 'black' },
            { dataKey: 'EsProspecto', label: 'Prospecto', type: 'boolean', width: 55 },
            { dataKey: 'Direccion', label: 'Dirección', width: 390 } ,
            { dataKey: 'vendedorId', label: 'Id de Vendedor', width: 390 } ,
            { dataKey: 'clienteSucursalId', label: 'Id de ClienteSucursal', width: 390 } ,
            { dataKey: 'DuracionVisita', label: 'Duración de V.', width: 90 },   
            { dataKey: 'OrdenVisita', label: 'Orden de V.', width: 90 } ,
            { dataKey: 'Observacion', label: 'Observación', width: 390 } ,
            { dataKey: 'ConLlamada', label: 'Llamada', type: 'boolean', width: 50 }        
        ]
    },

    tablePed: {
        order: 'asc',
        orderBy: '',
        columns: [
            { dataKey: 'Vendedor', label: 'Vendedor', width: 225 },
            { dataKey: 'cuentaPuntoVentaE', label: 'Cantidad de Pedidos', width: 150 },

        ]
    },
};

export const reportevisitasReducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case FILTER_MAP_VENDEDOR:
            return {
                ...state,
                dVendedor: action.dVendedor || ''
            };
        case HIDE_POPUP_CLIENT:
            return {
                ...state,
                openPopup: false
            };
        case RECEIVE_DATA:
            return {
                ...state,
                data: action.payload,
                store: {
                    ...state.store,
                    data: action.storeData
                }
            };
            case RECEIVE_OLAS_LIST:

                return {
                    ...state,
                    data: action.pay,
                    storePed: {
                        ...state.store,
                        data: action.storePed
                    }
                };
        case SET_MASK:
            return {
                ...state,
                loading: action.loading
            };
        case SET_ORDER:
            return {
                ...state,
                table: {
                    ...state.table,
                    order: action.order,
                    orderBy: action.orderBy
                }
            };

        case SET_ORDER_DETAIL:
            return {
                ...state,
                tableClient: {
                    ...state.tableClient,
                    order: action.order,
                    orderBy: action.orderBy
                }
            };

        case SET_SELECTED_ROW:
            return {
                ...state,
                store: {
                    ...state.store,
                    selected: action.selected
                }
            };
        case UPDATE_FIELD_FILTER:
            return { 
                ...state,
                filters: {
                    ...state.filters,
                    [action.key]: action.value
                }
            };
        case SHOW_POPUP_CLIENT:
            return {
                ...state,
                openPopup: true,
                storeClient: {
                    ...state.store,
                    data: action.storeData
                }
            };
        case UPDATE_TABLE_COLUMN:
            return {
                ...state,
                table: {
                    ...state.table,
                    columns: action.columns
                }
            };
        default:
            return state;
    }
}