import axios from 'axios';
import format from "date-fns/format";
//import _ from 'lodash';
import { IdentityUrl } from '../../../config';
import { closeAdvancedSearch, closeMessageBox, openAdvancedSearch, openMessageBox } from '../../main/actions';
import {
    FILTER_MAP_VENDEDOR,
    HIDE_POPUP_CLIENT,
    RECEIVE_DATA,
    SET_MASK,
    SET_ORDER,
    SET_SELECTED_ROW,
    SHOW_POPUP_CLIENT,
    UPDATE_FIELD_FILTER,
} from './types';

import { exportToSpreadsheet, cortarArreglo} from '../../../helpers/commons'

export const  onExport  = () => async (dispatch, getState) => {

    if (getState().listarprospectos.filters.distribuidorId   === 0) {
        dispatch(openMessageBox({
            button: 'ok',
            icon: 'warning',
            message: "Debe seleccionar un Agente..",
            callback: () => dispatch(closeMessageBox())
        }));
    return;
    }
    if(getState().listarprospectos.store.data.length === 0) {
        dispatch(openMessageBox({
            button: 'ok',
            icon: 'warning',
            message: 'No hay información para exportar.',
            callback: () => dispatch(closeMessageBox())
        }));
        return;
    }


    try {
        dispatch({ type: SET_MASK, loading: true });

        //let response = await axios.get(encodeURI(url)); //, { responseType: 'arraybuffer' });
/*
         let head = getState().listarprospectos.table.columns;

        // eslint-disable-next-line no-array-constructor
        var temp = new Array();
        for(var c in head) {
            let x =   Object.values(head[c])[1];
            temp.push(x);
        }
        // eslint-disable-next-line no-array-constructor
        var carArray = new Array();

        carArray.push(temp);

         let json=   getState().listarprospectos.store.data;
         //console.log(json)

        for(var i in json) {

            delete json[i].Clasificacion
            delete json[i].JerarquiaId
            delete json[i].Telefono
            delete json[i].Telefono2
            delete json[i].Telefono3
            delete json[i].TipoCliente
            delete json[i].Ubigeo
            delete json[i].UbigeoId
            //delete json[i].Comentario
            let ax = [];
            let x =   Object.values(json[i]);
            // x.splice(6, 1);
            // x.splice(12, 1);
            // x.splice(16, 1);
            // x.splice(17, 1);
            // x.splice(17, 1);
            // x.splice(17, 1);
            // x.splice(17, 1);
            // x.splice(18, 1);
            ax[0] = x[0]
            ax[1] = x[1]
            ax[2] = x[2]
            ax[3] = x[3]
            ax[4] = x[4]
            ax[5] = x[5]
            ax[6] = x[6]
            ax[7] = x[7]
            ax[8] = x[8]
            ax[9] = x[9]
            ax[10] = x[10]
            ax[11] = x[11] 
            ax[12] = x[17]
            ax[13] = x[12]
            ax[14] = x[13]
            ax[15] = x[14]
            ax[16] = x[15]
            ax[17] = x[16]
            ax[18] = x[17]
            carArray.push(ax);
        }
     */
        let head = getState().listarprospectos.table.columns;
        let json = getState().listarprospectos.store.data;

        exportToSpreadsheet(cortarArreglo(head,json),  'ListarProspectos') ;

    } catch (error) {
        console.log(error)
        dispatch(openMessageBox({
            button: 'ok',
            icon: 'error',
            message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message,
            callback: () => dispatch(closeMessageBox())
        }));
    } finally {
        setTimeout(() => {dispatch({ type: SET_MASK, loading: false })}, 1000);
    }
};

export const btnSearch = () => async (dispatch, getState) => {
   
      
        if (getState().listarprospectos.store.loading) {
            return;
        }

        if (getState().listarprospectos.filters.distribuidorId   === 0) {
            dispatch(openMessageBox({
                button: 'ok',
                icon: 'warning',
                message: "Debe seleccionar un Agente..",
                callback: () => dispatch(closeMessageBox())
            }));
            return;
        }
       dispatch({ type: SET_MASK, loading: true });   
 try {
        let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Reportes/clientes/listarprospecto?`
        + `distribuidorId=${getState().listarprospectos.filters.distribuidorId}`
        + `&estado=${getState().listarprospectos.filters.estado}`
        + `&supervisorId=${getState().listarprospectos.filters.supervisorId}`
        + `&vendedorId=${getState().listarprospectos.filters.vendedorId}`
        + `&soloprospecto=${1}`
        + `&desde=${format(getState().listarprospectos.filters.desde, 'yyyy-MM-dd')}`
        + `&hasta=${format(getState().listarprospectos.filters.hasta, 'yyyy-MM-dd')}`
        + `&todos=${getState().listarprospectos.filters.todos === true ? 1 : 0}`;
        let resp = await axios.get(encodeURI(url));
        let data = resp.data;

        //console.log(data)
        if (data.length === 0) {
            dispatch(
              openMessageBox({
                button: "ok",
                icon: "warning",
                message: "No existe Registro  para esta consulta",
                callback: () => dispatch(closeMessageBox()),
              })
            );
            return;
          }
        var i ;
        var cont = 0;
          for(i=0; i < data.length; i++){

            if (data[i].conPedido === 1 || data[i].montoPedido > 0){
                cont= cont+1
            }
          }

          let columns =   [
            { dataKey: 'Nombre' ,  label: 'Vendedor',   width: 200 },
            { dataKey: 'NIT', label: 'NIT/RUC', width: 100 },
            { dataKey: 'NombreComercial', label: 'Nombre Comercial', width: 250 },
            { dataKey: 'DireccionFiscal', label: 'Dirección Fiscal', width: 350 },
            { dataKey: 'NombreSucursal', label: 'Nombre Sucursal', width: 250 },
            { dataKey: 'DireccionSucursal', label: 'Dirección Sucursal', width: 300 },
            { dataKey: 'Ubigeo', label: 'Ubigeo', width: 150 },
            { dataKey: 'Telefono', label: 'Telefono', width: 70 },
            // { dataKey: 'FechaAprobacion', label: 'Fecha de Aprobación', width: 100 },
            { dataKey: "suc_num", label: "N. Sucursal", width: 60 },
            { dataKey: 'FechaRegistro', label: 'Fecha Registro', width: 100 },
            { dataKey: 'Canal', label: 'Canal', width: 60 },
            { dataKey: 'Segmento', label: 'Segmento', width: 130 },
            { dataKey: 'Categoria', label: 'Categoría', width: 150 },
            { dataKey: 'Motivo', label: 'Motivo', width: 250 },
            { dataKey: 'Correo', label: 'Correo', width: 200 },
            { dataKey: 'Latitud', label: 'Latitud', width: 200 },
            { dataKey: 'Longitud', label: 'Longuitud', width: 200 },
            { dataKey: 'Estado' ,  label: 'Estado',   width: 50},
            { dataKey: "Contacto", label: "Contacto", width: 200 },
            { dataKey: 'Anulado' ,  label: 'Anulado',  type: 'boolean',  width: 80 },
            { dataKey: 'ProspectoId', label: 'ClienteId', width: 90 },
            { dataKey: "Comentario", label: "Comentario", width: 200 },
            { dataKey: "UriFoto01", label: "Foto01", width: 300 },
            { dataKey: "UriFoto02", label: "Foto02", width: 300 },
            { dataKey: "VendedorAsignado", label: "Vendedor Asignado", width: 200 },


        ]
        if (getState().listarprospectos.filters.distribuidorId === 29){
            columns.push({ dataKey: "VendedorDos", label: "Vendedor Dos", width: 200 })
        }
            dispatch({ type: RECEIVE_DATA,  columns: columns, payload: data });// payload: data });

    } catch (error) {

        dispatch(openMessageBox({
            button: 'ok',
            icon: 'error',
            message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message,
            callback: () => dispatch(closeMessageBox())
        }));
    } finally {
        dispatch({ type: SET_MASK, loading: false });
    }


/* ----------------------------------------------------------------------------------*/
/*
try {

    let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/reportes/reportepedidos?`
    + `&desde=${format(getState().listarprospectos.filters.desde, 'yyyy-MM-dd')}`
    + `&hasta=${format(getState().listarprospectos.filters.hasta, 'yyyy-MM-dd')}`
        + `&vendedorid=${getState().reportepedidos.filters.vendedorId}`

    let response = await axios.get(encodeURI(url));
    let datap = response.data;

    let cuenta = _(datap.data)
    .groupBy('Vendedor')
    .map((item, key) => ({
        'Vendedor': key,
        'proyectados': _.countBy(item, i => i.PedidoId > 0 ),
         }))
    .value();


let storePed = _.map(cuenta, (item) => {
    return {
        Vendedor: item.Vendedor,
        cuentaPuntoVentaE: item.proyectados['true'] ?? 0
    };
});

        dispatch({ type: RECEIVE_OLAS_LIST, pay: datap, storePed });

    } catch (error) {
        let msgError = '';
        dispatch({ type: SET_MASK, loading: false });

        if (error.response) {

         if(error.response.status === 400) {
                msgError = (error.response.data.eventLogId === 0 ? 'No existe Registro  para esta fecha ' : `EventoId: ${error.response.data.eventLogId}. `+ error.response.data.message)
         }else{
            msgError = (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message;
         }
        }
        else {
            msgError = 'Error al conectar con el servidor';
        }

        dispatch(openMessageBox({
            button: 'ok',
            icon: 'error',
            message: msgError,
            callback: () => dispatch(closeMessageBox())
        }));
    }finally{
        dispatch({ type: SET_MASK, loading: false });
    }

    */

};

export const closeDialogClient = () => ({ type: HIDE_POPUP_CLIENT });

export const filterMapByVendedor = (dVendedor) => ({ type: FILTER_MAP_VENDEDOR, dVendedor });

export const onSearchDistribuidorClick = () => async (dispatch, getState) => {
    let fields = [
        { 'dataKey': 'razonSocial', 'label': 'Agente', 'type': 'string', 'width': 225 }
    ];

    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de agente',
        getData: async (params) => {
            let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`
            let response = await axios.get(encodeURI(url), { params: params });

            return response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldFilter('distribuidorId', result[0].distribuidorId));
                dispatch(onUpdateFieldFilter('dDistribuidor', `${result[0].razonSocial}`));
                dispatch(onUpdateFieldFilter('supervisorId', ''));
                dispatch(onUpdateFieldFilter('dSupervisor', ''));
                dispatch(onUpdateFieldFilter('vendedorId', ''));
                dispatch(onUpdateFieldFilter('dVendedor', ''));
            }
        }
    }));
};

export const onSearchSupervisorClick = () => async (dispatch, getState) => {
    let fields = [
        { 'dataKey': 'nombre', 'label': 'Nombre', 'type': 'string', 'search': true, 'width': 225 },
        { 'dataKey': 'nombreUsuario', 'label': 'Usuario', 'type': 'string', 'width': 115 }
    ];

    if (getState().listarprospectos.filters.distribuidorId   === 0) {
    dispatch(openMessageBox({
        button: 'ok',
        icon: 'warning',
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox())
    }));
return;
}

    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de supervisor',
        getData: async (params) => {
            let url = `${IdentityUrl}/api/v1/usuarios?rol=Supervisor`
            let response = await axios.get(encodeURI(url), { params: params });
            let distribuidorId = getState().listarprospectos.filters.distribuidorId;

            if(distribuidorId > 0) {
                response.data.data = response.data.data.filter(f => f.distribuidorId === distribuidorId);
            }

            return response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldFilter('supervisorId', result[0].usuarioId));
                dispatch(onUpdateFieldFilter('dSupervisor', `${result[0].nombre}`));
                dispatch(onUpdateFieldFilter('vendedorId', ''));
                dispatch(onUpdateFieldFilter('dVendedor', ''));
            }
        }
    }));
};

export const onSearchUbigeoClick = () => async (dispatch, getState) => {
    let fields = [
        { 'dataKey': 'codigo', 'label': 'Código', 'type': 'string', 'width': 115 },
        { 'dataKey': 'dUbigeo', 'label': 'Ubigeo', 'type': 'string', 'search': true, 'width': 225 }
    ];

    dispatch(openAdvancedSearch({
        autoLoad: false,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de ubigeo',
        getData: async (params) => {
            let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/agentes/ubigeos`;
            let response = await axios.get(encodeURI(url), { params: params });

            return response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldFilter('ubigeoId', result[0].ubigeoId));
                dispatch(onUpdateFieldFilter('dUbigeo', `${result[0].dUbigeoId}`));
            }
        }
    }));
};

export const onSearchVendedorClick = () => async (dispatch, getState) => {
    let fields = [
        { 'dataKey': 'nombre', 'label': 'Nombre', 'type': 'string', 'search': true, 'width': 225 },
        { 'dataKey': 'nombreUsuario', 'label': 'Usuario', 'type': 'string', 'width': 115 }
    ];

    if (getState().listarprospectos.filters.supervisorId   === '') {
        dispatch(openMessageBox({
            button: 'ok',
            icon: 'warning',
            message: "Seleccionar un Supervisor..",
            callback: () => dispatch(closeMessageBox())
        }));
    return;
    }

    dispatch(onUpdateFieldFilter('vendedorId',''));
                dispatch(onUpdateFieldFilter('dVendedor', ''));

    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de vendedor',
        getData: async (params) => {
            let url = `${IdentityUrl}/api/v1/usuarios/porjerarquia?usuarioId=${getState().listarprospectos.filters.supervisorId}`
            let response = await axios.get(encodeURI(url), { params: params });
            let distribuidorId = getState().listarprospectos.filters.distribuidorId;

            if(distribuidorId > 0) {
                response.data.data = response.data.data.filter(f => f.distribuidorId === distribuidorId);
            }
            return response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldFilter('vendedorId', result[0].usuarioId));
                dispatch(onUpdateFieldFilter('dVendedor', `${result[0].nombre}`));
               // dispatch({ type: RECEIVE_OLAS_LIST, columns: getState().tracking.table.columns, payload: [] });
            }
        }
    }));
};


export const onUpdateColumn = (column) => (dispatch, getState) => {
    getState().listarprospectos.table.columns.map((item , i)=> {
       if(item.dataKey !== column.dataKey) {
           return item;
       }else{
               item.width =  column.width;
       }
       return {
          // ...item,
         //  width: column.width
       };

   });
};

export const onUpdateFieldFilter = (key, value) => (dispatch, getState) => {
    dispatch({ type: UPDATE_FIELD_FILTER, key, value })

    dispatch({ type: RECEIVE_DATA,  columns: [] , payload: []  });// payload: data });
}
export const onUpdateFielddata = (key, value) => (dispatch, getState) => {

   // dispatch({ type: UPDATE_FIELD_DATA, key, value })

     //dispatch({ type: RECEIVE_DATA, columns: [], data: [] })
  //  dispatch({ type: RECEIVE_DATA, payload:[],  store:[] })
    //dispatch({ type: RECEIVE_DATA, payload: data, storeData });
    };


export const openDialogClient = (data) => (dispatch, getState) => {
    try {
        var storeData = getState().listarprospectos.data.filter(f => f.dVendedor === data.dVendedor);

        dispatch({ type: SHOW_POPUP_CLIENT, storeData });
    } catch (error) {
        dispatch(openMessageBox({
            button: 'ok',
            icon: 'error',
            message: error,
            callback: () => dispatch(closeMessageBox())
        }));
    }
};

export const setOrder = (order, orderBy) => ({ type: SET_ORDER, order, orderBy });

export const setSelectedRow = (selected) => ({ type: SET_SELECTED_ROW, selected});