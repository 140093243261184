import {
  ERROR_OLAS_LIST,
  RECEIVE_OLAS_LIST,
  RECEIVE_PASE_PEDIDO_LIST,
  REQUEST_OLAS_LIST,
  RESTART_STATE,
  SET_MASK,
  SET_ORDER,
  SET_SELECTED_ROW,
  UPDATE_FIELD_FILTER,
  UPDATE_FIELD_FILTERH,
  RECEIVE_OLAS_DET,
  RECEIVE_COBRANZA_DET,
  RECEIVE_HISTORICO_DETAIL,
  RECEIVE_OLAS_PORCOBRAR,
  UPDATE_FIELD_ENTITYS,
  ON_SET_BANDEJA,
  RECEIVE_APROBAR_COBRANZA_LIST,
  HANDLE_SHOW
} from "./types";

const initialState = {
  loading: false,
  edo: "",
  motivoRechazo: "",
  panel: 0,
  show: false,
  filters: {
    clienteId:"",
    bandeja:true,
    ps: 'PS',
    ds: 'DS',
    ac: 'AC',
    dVendedor: "",
    desde: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    hasta: new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()),
    promedio:[],
    pesos:[],
    vendedorId: "",
    estado: "P",
    distribuidorId: 0,  
    dDistribuidor: "",
    nombre:"",
    color : 'cyan'
  },
  filtersH: {
    desdeHistorico: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    hastaHistorico: new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()),
  },
  store: {
    loading: false,
    data: [],
    selected: [],
    dataPase: [],
    selectedPase: [],
    dataAprobarCobranza: [],
    selectedAprobarCobranza: [],
    detalle: [],
    detallecobranza: [],
    detalleaprobarcobranza: [],
    porcobrar: []
  },
  table: {
    order: "asc",
    orderBy: "",
    columns: [],
    columnsexport:[],
  },
  tablePase: {
    order: "asc",
    orderBy: "",
    columns: [],
    columnsexport:[],
  },
  tableAprobarCobranza: {
    order: "asc",
    orderBy: "",
    columns: [],
    columnsexport:[],
  },
};

export const bandejaaprobacionReducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case ERROR_OLAS_LIST:
      return {
        ...state,
        store: {
          ...state.store,
          loading: false,
        },
      };
    case RECEIVE_OLAS_LIST:
      return {
        ...state,
        store: {
          ...state.store,
          loading: false,
          data: action.payload,
          selected: [],
        },
        table: { // [action.key]:{//
          ...state.table, // ...state[action.key],//
          columns: action.columns,
          columnsexport: action.columnsexport,

        },
      };
    case RECEIVE_PASE_PEDIDO_LIST:
      return {
        ...state,
        store: {
          ...state.store,
          loading: false,
          dataPase: action.payload,
          selectedPase: [],
        },
        tablePase: { // [action.key]:{//
          ...state.tablePase, // ...state[action.key],//
          columns: action.columns,
          columnsexport: action.columnsexport,
        },
      };
      case RECEIVE_APROBAR_COBRANZA_LIST:
      return {
        ...state,
        store: {
          ...state.store,
          loading: false,
          dataAprobarCobranza: action.payload,
          selectedAprobarCobranza: [],
        },
        tableAprobarCobranza: { // [action.key]:{//
          ...state.tableAprobarCobranza, // ...state[action.key],//
          columns: action.columns,
          columnsexport: action.columnsexport,
        },
      };
    case RECEIVE_OLAS_DET:
      return {
        ...state,
        store: {
          ...state.store,
          loading: false,
          detalle: action.payload,
        },
      };
      case RECEIVE_COBRANZA_DET:
      return {
        ...state,
        store: {
          ...state.store,
          loading: false,
          detalleaprobarcobranza: action.payload,
        },
      };
    case RECEIVE_HISTORICO_DETAIL:
      return {
        ...state,
        store: {
          ...state.store,
          loading: false,
          detallecobranza: action.payload,
        },
      };
    case RECEIVE_OLAS_PORCOBRAR:
      return {
        ...state,
        store: {
          ...state.store,
          loading: false,
          porcobrar: action.payload,
        },
      };
    case REQUEST_OLAS_LIST:
      return {
        ...state,
        store: {
          ...state.store,
          loading: true,
        },
      };
    case RESTART_STATE:
      return {
        ...initialState,
      };
    case SET_MASK:
      return {
        ...state,
        loading: action.loading,
      };
    case SET_ORDER:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          order: action.order,
          orderBy: action.orderBy,
        },
      };
    case SET_SELECTED_ROW:
      return {
        ...state,
        store: {
          ...state.store,
          [action.key]: action.selected,
        },
      };
    case UPDATE_FIELD_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.key]: action.value,
        },
      };
    case UPDATE_FIELD_FILTERH:
      return {
        ...state,
        filtersH: {
          ...state.filtersH,
          [action.key]: action.value,
        },
      };
    case UPDATE_FIELD_ENTITYS:
      return {
          ...state,
          motivoRechazo: action.value,
        };
    case ON_SET_BANDEJA :
      return {
          ...state,
          panel : action.payload
        };
    case HANDLE_SHOW:
      return {
        ...state,
        show : action.payload,
      };
    default:
      return state;
  }
};
