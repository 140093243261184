export const CHANGE_IMAGE = 'channelmap/asignarmarcadistribuidor/change-image';
export const CLEAN_FORM = 'channelmap/asignarmarcadistribuidor/clean-form';
export const ERROR_MARCAS_LIST = 'channelmap/asignarmarcadistribuidor/error-marcas-list';
export const RECEIVE_LIST = 'channelmap/asignarmarcadistribuidor/receive-marcas-list';
export const REMOVE_IMAGE = 'channelmap/asignarmarcadistribuidor/remove-image';
export const REQUEST_MARCAS_LIST = 'channelmap/asignarmarcadistribuidor/request-marcas-list';
export const RESTART_STATE = 'channelmap/asignarmarcadistribuidor/restart-state';
export const SET_ENTITY = 'channelmap/asignarmarcadistribuidor/set-entity';
export const SET_ERROR = 'channelmap/asignarmarcadistribuidor/set-error';
export const SET_FORM = 'channelmap/asignarmarcadistribuidor/set-form';
export const SET_MASK = 'channelmap/asignarmarcadistribuidor/set-mask';
export const SET_STATE_FORM = 'channelmap/marca/set-state-form';
export const UPDATE_FIELD_ENTITY = 'channelmap/asignarmarcadistribuidor/update-field-entity';
export const UPDATE_FIELD_FILTER = 'channelmap/asignarmarcadistribuidor/update-field-filter';
export const ADD_MARCA = 'channelmap/asignarmarcadistribuidor/set-mask';
export const ADVANCED_SEARCH_CLOSE = 'main/advanced-search-close';
export const ADVANCED_SEARCH_OPEN = 'main/advanced-search-open';