import {
    CLEAN_FORM,
    SET_STATE_FORM,
    UPDATE_FIELD_FILTER,
    SET_MASK,
    SET_ORDER,
    RECEIVE_DATA,
    UPDATE_TABLE_COLUMN,
    ERROR_V_LIST,
    RECEIVE_V_LIST,
    REQUEST_V_LIST,
    RECEIVE_V_LIST2,
    CLEAN_DATA2,
    SET_SELECTED_MULTI,
    SET_PAGE_SIZE,
    CLEAR_SELECT,
    SET_ENTITY,
    UPDATE_FIELD_ENTITY
} from './types';

const initialState = {
    formState: 'search',
    loading: false,
    entity: {
        vendedor: '',
        vendedorId: 0,
        listaPrecioId: 0,
        listaPrecioVendedorId: 0,
        dLista: ''
    },
    errors: {
        codigo: '',
        usuario: '',
        documentoIdentidad: ''
    },
    filters: {
        nombre: '',
        distribuidorId: 0,
        dDistribuidor: '',
        codigo: '',
        dLista: '',
        codigoLista: 0,
        band: false,
        indexPago: [],
        indexCodigo: [],
        dVendedor: '',
        vendedorId: 0,
        listaPrecioId:0
    },
    store: {
        loading: false,
        pageSize: 50,
        start: 0,
        total: 0,
        data: [],
        data2: [],
        aux: [],
        selected: [],
        col: ['Codigo', 'Descripcion', 'UnidadMedida', 'PrecioEstandar', 'PrecioMinimo', 'Descuento', 'Cantidad', 'TipoDePago']
        //source: null
    },
    stored: {
        loading: false,
        pageSize: 50,
        start: 0,
        total: 0,
        data: [],
        selected: [],
        //source: null
    },
    table: {
        order: 'asc',
        orderBy: '',
        columns: [
            //{ dataKey: 'vendedorId', label: 'CódigoVendedor', width: 150 },
            { dataKey: 'vendedor', label: 'Vendedor', width: 300 },
            { dataKey: 'lista', label: 'Lista', width: 300 },
            { dataKey: 'codigo', label: 'Código de Lista', width: 150 },
            
        ],
       
    },
    tablexport: {
        order: 'asc',
        orderBy: '',
        columns: [
            { dataKey: 'vendedorId', label: 'CódigoVendedor', width: 150 },
            { dataKey: 'dVendedor', label: 'NombreVendedor', width: 300 },
            { dataKey: 'listaPrecioId', label: 'CódigoLista', width: 150 },
            { dataKey: 'dLista', label: 'NombreLista', width: 300 },
        ],
    }
};

export const gestionlistadepreciosReducer = (state, action) => {
    state = state || initialState;

    switch(action.type) {
        case CLEAN_FORM:
            //console.log('Paso por el Clean Form')
            return {
                ...state,
                store: initialState.store,
                errors: initialState.errors
            };
        case SET_STATE_FORM:
            return {
                ...state,
                formState: action.formState
            };
        case UPDATE_FIELD_FILTER:
            return { 
                ...state,
                filters: {
                    ...state.filters,
                    [action.key]: action.value
                }
            };

        case ERROR_V_LIST:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: false
                }
            };

        case RECEIVE_V_LIST:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: false,
                    total: action.total,
                    data: action.payload,
                    source: null
                }
        };


        case SET_ENTITY:
            return {
                ...state,
                entity: {
                    ...state.entity,
                    vendedor: action.payload.vendedor,
                    vendedorId: action.payload.vendedorId,                  
                    dLista: action.payload.lista,
                    listaPrecioId: action.payload.listaPrecioId,
                    listaPrecioVendedorId: action.payload.listaPrecioVendedorId                    ,
                }
        };
                
            
        case RECEIVE_V_LIST2:
            return {
                ...state,
                stored: {
                    ...state.store,
                    loading: false,
                    total: action.total,
                    data: action.payload,
                    source: null
                }
            };    

        case REQUEST_V_LIST:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: false,
                    source: action.source
                }
            };

        case RECEIVE_DATA:
            return {
                ...state,
                data: action.payload,
                store: {
                    ...state.store,
                    data: action.storeData
                }
        };

        case UPDATE_FIELD_ENTITY:
            
            return {
                ...state,
                entity: {
                    ...state.entity,
                    [action.key]: action.value
                }
        }; 

        case UPDATE_TABLE_COLUMN:
                 return {
                    ...state,
                    table: {
                        ...state.table,
                        columns: action.columns
                    }
        };

        case SET_MASK:
                return {
                    ...state,
                    loading: action.loading
            };

            case SET_ORDER:
                return {
                    ...state,
                    table: {
                    ...state.table,
                    order: action.order,
                    orderBy: action.orderBy,
                    },
                };
            case CLEAN_DATA2:
                return {
                    ...state,
                    stored: {
                        ...state.stored,
                        data: []
                    }
                };
                case SET_SELECTED_MULTI:
            return {
                ...state,
                store: {
                    ...state.store,
                    selected: action.payload
                }
            };
            case SET_PAGE_SIZE:
        return {
            ...state,
            store: {
                ...state.store,
                pageSize: action.pageSize
            }
        };
        case CLEAR_SELECT: 
        console.log('LLego al select clear')
        return {
            ...state,
            store: {
                ...state.store,
                [action.payload] : []
            }
        };
        default:
            return state;
    }
};