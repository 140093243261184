import React from 'react';
import {useSelector } from "react-redux";
import makeStyles from '@material-ui/core/styles/makeStyles';
import { formatoPeru} from "../../../helpers/commons";

const useStyles = makeStyles(() => ({
  cuadron: {
    marginLeft:'5px',
  },
  resul: {
    fontSize: "10px !important",
    background: "white",
    textAlign: "Left !important",
  },
  resuld: {
    fontSize: "10px !important",
    textAlign: "Right !important",
    background: "white",
  },
  porfa: {
    "@media (max-width: 640px)": {
      height: "305px",
      width: "480px",
      marginLeft: "5px",
      fontSize:'6px',
    },
  },
  a: {
    fontSize: "12px",
  },
  table: {
    highlight: {
      backgroundColor: 'red',
    },
    background: 'rgba(0,102,255,.25)',
    textalign: 'center',
    fontSize: "max(1 vw, 5px)",
    margin: "auto",
    '& th': {
      border: '1px solid rgba(16,76,167,.25)',
      background: 'rgba(0,102,255,.25)',
      textalign: 'center',
    },
    '& td': {
      border: '1px solid rgba(16,76,167,.25)',
    }
  },
}));

const DetallePedido = () => {
  const classes = useStyles();
  const loading = useSelector((state) => state.bandejaaprobacion.loading);
  const datad = useSelector((state) => state.bandejaaprobacion.store.detalle);
  const t = useSelector((state) => state.bandejaaprobacion.store.selectedPase);
  const modalInfo = t[0]
  let total = 0;
  
 return (  
  <>
    {t.length > 0  &&  (
    <div className={classes.porfa} >          
      <h2 style={{ textAlign: "center", fontSize: "12px" }}> Detalle de Pedido Pendiente</h2>

      <div className={classes.cuadrin} style= {{ width: '94%', marginLeft:'4px'}} >
        <table  align="center" style= {{ width: '104%'}} >
          <tbody > 
            <tr>
              <td style={{ width: "80px" , backgroundColor: "#BFD9FF",fontSize: "10px" ,  fontWeight: "bold"}} >CODIGO PEDIDO:</td> 
              <td colSpan="1" style={{ width: "85px",fontSize: "10px"  }}> <b>{modalInfo["Codigo"]} </b></td>
              <td style={{ width: "15px" , backgroundColor: "#BFD9FF",fontSize: "10px",fontWeight: "bold" }}>ESTADO:</td>
              <td colSpan="2"style={{ width: "60px",fontWeight: "bold"  ,fontSize: "10px", color : modalInfo.Estado === 'P' ? 'blue' : modalInfo.Estado ===  'R' ? 'red' :'green' , textAlign:'center'}}>{modalInfo.Estado === 'P' ? 'PENDIENTE' :  modalInfo.Estado === 'R' 
              ? 'RECHAZADO' :  modalInfo.Estado === 'A' ? 'APROBADO' : 'REVISADO' }</td>
              <td colSpan="1" style={{ width: "50px" , backgroundColor: "#BFD9FF" ,fontSize: "10px", fontWeight: "bold"}}>FECHA:</td>
              <td colSpan="4" style={{fontSize: "10px", width: "65px", textAlign:"center" }}>{modalInfo.Fecha}</td>
            </tr>
            {/* <tr style={{ height: "5px" , backgroundColor: "white" , backColor: "white"}} ></tr> */}
            <tr></tr> 

            <tr>
              <td style={{ width: "85px" , backgroundColor: "#BFD9FF",fontSize: "10px" ,  fontWeight: "bold"}} >NIT:</td> 
              <td colSpan="0" style={{ width: "85px",fontSize: "10px"  }}> <b>{modalInfo["NIT/RUC"]} </b></td>
              <td style={{ width: "65px" , backgroundColor: "#BFD9FF",fontSize: "10px",fontWeight: "bold" }}> CLIENTE:</td>
              <td colSpan="2" style={{ width: "105px",fontSize: "10px" }}>{modalInfo.Cliente.toUpperCase()}</td>
              <td style={{ width: "65px" , backgroundColor: "#BFD9FF",fontSize: "10px",fontWeight: "bold" }}> L.CREDITO:</td>
              <td colSpan="1" style={{ width: "75px",fontSize: "10px" }}>{modalInfo.LineaCredito}</td>
              <td style={{ width: "35px" , backgroundColor: "#BFD9FF",fontSize: "10px",fontWeight: "bold" }}>VENDEDOR:</td>
              <td  colSpan="4" style={{ width: "85px",fontSize: "10px"  }}>{modalInfo.Nombre.toUpperCase()}</td>  
            </tr>
            <tr></tr> 
            <tr> 
              <td style={{ width: "85px" , backgroundColor: "#BFD9FF",fontSize: "10px" , fontWeight: "bold" }}> OBSERVACION:</td>
              <td colSpan="9" style={{ width: "85px",fontSize: "10px" }}><b> {modalInfo.Observacion.toUpperCase()} </b></td>
            </tr>
          </tbody>
        </table> 
      </div>
    <br></br>
      <div style={{display:'flex', flex: '1'}}> 
        <div style={{ display: "none" ,border:'none'}}>
          {!loading && datad.map((ite) => (total = total + ite.cantidad * ite.precioFinal))}
        </div>
        
        <div className={classes.cuadron} style={{ width:'98%',  height: datad.length >= 7 ? "135px " : "130px", overflowX: datad.length >= 4 ? "auto" : "hidden", marginTop:"-10px"}}> 
        <table  align="center" className="aprobacion" id="tableMod" style={{borderCollapse:"collapse", width:'100%' }}  >
          <thead >
          {!loading && datad.length > 0 &&<tr>
              <th>Código</th>
              <th >Descripción </th>
              <th >Cant.</th>
              <th >Precio</th>
            </tr>}
          </thead>
          <tbody style={{border: "1px solid #BFD2E9"}}>

            {!loading && datad.map((item, index) => (
                <tr key={index} >
                  <td className={classes.resul}>{item.codigoProducto}</td>
                  <td  className={classes.resul} style={{ fontSize: "10px" }}>{item.dProducto}</td>
                  <td className={classes.resuld}>{item.cantidad}</td>
                  {/* <td className={classes.resuld}> {parseFloat(item.precioFinal).toFixed(2)}</td> */}
                  <td className={classes.resuld}>{formatoPeru('en-US', 'USD', item.precioFinal)} </td>
                </tr>
              ))}
                { datad.length > 0 && !loading &&(
                  <tr key={1000} style={{backgroundColor: "white",  border:'none'}} >
                      <td  className={classes.resuld} colSpan="5" >
                      <b style={{fontSize:"12px"}}>Total: {formatoPeru('en-US', 'USD', total)}</b>
                      </td> 
                  </tr>
                )} 
          </tbody>
          {loading && (
            <tbody>
              <tr >
                <td colSpan="5">
                  <img
                    alt="log"
                    src="https://app.iess.gob.ec/iess-fondo-reserva-afiliado-web/javax.faces.resource/images/load.gif.jsf;jsessionid=8949b76cfea43d283add3bd3dd2d?ln=sentinel-layout"
                    width="40"
                    height="40"
                  />
                </td>
              </tr>
            </tbody>
          )}
        </table>
        </div>
      </div>
      <br></br>
      { datad.length === 0  && ( <div >  No existe Registro       </div> )} 
      <br></br>
    </div>
    )}
  </>
  );
}

export default DetallePedido;
