import React from "react";
import {  useSelector } from "react-redux";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import { FieldSet } from "@sigeco/tools";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

const useStyles = makeStyles(() => ({
  content: {
    padding: "0.5rem 0.75rem 0.75rem",
  },
  map: {
    border: "1px solid #d3d3d3",
    height: 150,
    marginTop: ".5rem",
  },
  paper: {
    marginBottom: "0.75rem",
    "@media (min-width: 1401px)": {
      marginBottom: "1rem",
    },
  },
}));

const ProspectosForm = ({ google }) => {
  const classes = useStyles();
  const big = useMediaQuery("(min-width:1201px)");
  const middle = useMediaQuery("(min-width:701px)");
  const entity = useSelector((state) => state.aprobarprospectos.store.detalle);
  const item  = useSelector((state) => state.aprobarprospectos.store.subdetalle);
  const dist  = useSelector((state) => state.aprobarprospectos.filters.distribuidorId);
  const handleDownLoadFile2 = (item) => {
    if(item && item.UriFoto02 && item.UriFoto02 !== '') {
        let link = document.createElement('a');

        link.href = item.UriFoto02;
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}

const handleDownLoadFile = (item) => {
     if(item && item.UriFoto01 && item.UriFoto01 !== '') {
         let link = document.createElement('a');

         link.href = item.UriFoto01;
         link.setAttribute('target', '_blank');
         document.body.appendChild(link);
         link.click();
         document.body.removeChild(link);
     }
}

  function HeadTable() {
    if (item.length > 0 ){

       return item.map((key, index) => {
            return (
              <div  key = {index}>
             <FieldSet
              className={classes.paper}
              iconCls="fas fa-address-card"
              title = "Datos Sucursal"
            >
                <Grid className={classes.content} container spacing={1}>
                  <Grid container item spacing={1} xs={12}></Grid>

                  <Grid container item spacing={1} xs={12}>
                    <Grid item xs={middle ? 4 : 8}>
                      <TextField
                        fullWidth
                        label="Nombre. Sucursal"
                        value={key.nombreSucursal}
                        variant={middle ? "outlined" : "standard"}
                        inputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={middle ? 2 : 6}>
                      <TextField style ={{backgroundColor: 'red'}}
                        fullWidth
                        label="Estado"
                        value={(key.Estado = "P" ? "Pendiente" : "Aprobado")}
                        variant={middle ? "outlined" : "standard"}
                        inputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>

                  </Grid>

                  <Grid container item spacing={1} xs={12}>
                    <Grid item xs={middle ? 6 : 12}></Grid>
                  </Grid>
                  <Grid container item spacing={1} xs={12}>
                    <Grid item xs={middle ? 4 : 8}>
                      <TextField
                        fullWidth
                        label="Dirección Sucursal"
                        value={key.direccionSucursal}
                        variant={middle ? "outlined" : "standard"}
                        inputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={middle ? 2 : 4}>
                      <TextField
                        fullWidth
                        label="Fecha de Registro"
                        value={key.fecha}
                        variant={middle ? "outlined" : "standard"}
                        inputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item spacing={1} xs={12}>
                    <Grid item xs={middle ? 6 : 12}>
                      <TextField
                        fullWidth
                        label="Ubigeo"
                        value={key.referencia}
                        variant={middle ? "outlined" : "standard"}
                        inputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item spacing={1} xs={12}>
                    <Grid item xs={middle ? 6 : 12}>
                      <TextField
                        fullWidth
                        label="Comentario"
                        value={key.Comentario}
                        variant={middle ? "outlined" : "standard"}
                        inputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                  </Grid>

                <Grid container item spacing={1} xs={12}>
                <Grid item xs={big ? 6 : middle ? 6 : 12}>
                  <InputLabel>Localización</InputLabel>
                  { !isNaN(parseInt(key.latitud))  &&
                    !isNaN(parseInt(key.lucursalLongitud)) !== 0 && (
                      <div className={classes.map}>
                        <Map
                          initialCenter={{
                            lat: parseFloat(key.latitud),
                            lng: parseFloat(key.longitud),
                          }}
                          google={google}
                          containerStyle={{
                            width: "100%",
                            height: "100%",
                            position: "relative",
                          }}
                          zoom={14}
                        >
                          <Marker
                            position={{
                              lat: parseFloat(key.latitud),
                              lng: parseFloat(key.longitud),
                            }}
                            title={key.lCliente}
                          />
                        </Map>
                      </div>
                    )}
                  {isNaN(parseInt(key.latitud))  &&
                    isNaN(parseInt(key.latitud)) && (
                      <div className={classes.map}>No tiene Georeferencia</div>
                    )}
                </Grid>
              </Grid>


              </FieldSet>
              </div>
            )
        })
    } else{
        return null
    }
};

function Details() {
  if (item.length === 0 ){
    return (
        <FieldSet iconCls="fas fa-map-marked-alt" title="Datos sucursal">
        <Grid className={classes.content} container spacing={1}>
          <Grid container item spacing={1} xs={12}></Grid>

          <Grid container item spacing={1} xs={12}>
            <Grid item xs={middle ? 4 : 8}>
              <TextField
                fullWidth
                label="Nombre. Sucursal"
                value={entity.NombreSucursal}
                variant={middle ? "outlined" : "standard"}
                inputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={middle ? 2 : 6}>
              <TextField
                fullWidth
                label="Estado"
                value={(entity.Estado === "P" ? "Pendiente" : entity.Estado === "A" ? "Aprobado": "Rechazado")}
                variant={middle ? "outlined" : "standard"}
                inputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

          </Grid>

          <Grid container item spacing={1} xs={12}>
            <Grid item xs={middle ? 6 : 12}></Grid>
          </Grid>
          <Grid container item spacing={1} xs={12}>
            <Grid item xs={middle ? 4 : 8}>
              <TextField
                fullWidth
                label="Dirección Sucursal"
                value={entity.DireccionSucursal}
                variant={middle ? "outlined" : "standard"}
                inputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={middle ? 2 : 4}>
              <TextField
                fullWidth
                label="Fecha de Registro"
                value={entity.FechaRegistro}
                variant={middle ? "outlined" : "standard"}
                inputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={1} xs={12}>
            <Grid item xs={middle ? 6 : 12}>
              <TextField
                fullWidth
                label="Ubigeo"
                value={entity.Ubigeo}
                variant={middle ? "outlined" : "standard"}
                inputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={1} xs={12}>
            <Grid item xs={big ? 6 : middle ? 6 : 12}>
              <InputLabel>Localización</InputLabel>
              {(  (entity.Latitud !== '' &&  entity.sucursalLongitud !== '') ||
                ( !isNaN(parseInt(entity.Latitud))  && !isNaN(parseInt(entity.sucursalLongitud)) )  ) && (
                  <div className={classes.map}>
                    <Map
                      initialCenter={{
                        lat: parseFloat(entity.Latitud),
                        lng: parseFloat(entity.Longitud),
                      }}
                      google={google}
                      containerStyle={{
                        width: "100%",
                        height: "100%",
                        position: "relative",
                      }}
                      zoom={14}
                    >
                      <Marker
                        position={{
                          lat: parseFloat(entity.Latitud),
                          lng: parseFloat(entity.Longitud),
                        }}
                        title={entity.dCliente}
                      />
                    </Map>
                  </div>
                )}
              {parseInt(entity.Latitud) === 0 &&
                parseInt(entity.Latitud) === 0 && (
                  <div className={classes.map}>No tiene Georeferencia</div>
                )}
            </Grid>
          </Grid>
        </Grid>
      </FieldSet>

      )

  } else{
      return <div></div>
  }
};

  return (
    <div>
      <FieldSet
        className={classes.paper}
        iconCls="fas fa-address-card"
        title="Datos Cliente"
      >
        <Grid className={classes.content} container spacing={1}>
          <Grid container item spacing={1} xs={12}>
            <Grid item xs={big ? 6 : middle ? 6 : 12}>
              <TextField
                fullWidth
                label="Cliente"
                value={entity.NombreComercial}
                variant={middle ? "outlined" : "standard"}
                inputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={1} xs={12}>
            <Grid item xs={middle ? 2 : 4}>
              <TextField
                fullWidth
                label="Cód. Sucursal"
                value={entity.ProspectoId}
                variant={middle ? "outlined" : "standard"}
                inputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={middle ? 4 : 8}>
              <TextField
                fullWidth
                label="Dirección Fiscal"
                value={entity.DireccionFiscal}
                variant={middle ? "outlined" : "standard"}
                inputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>

          <Grid container item spacing={1} xs={12}>
            <Grid item xs={middle ? 2 : 4}>
              <TextField
                fullWidth
                label="RUC/NIT"
                value={entity.NIT}
                variant={middle ? "outlined" : "standard"}
                inputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={middle ? 4 : 8}>
              <TextField
                fullWidth
                label="Correo"
                value={entity.Correo}
                variant={middle ? "outlined" : "standard"}
                inputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          
          </Grid>
          <Grid container item spacing={1} xs={12}>
            <Grid item xs={middle ? 2 : 4}>
              <TextField
                fullWidth
                label="Teléfono"
                value={entity.Telefono}
                variant={middle ? "outlined" : "standard"}
                inputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={middle ? 2 : 4}>
              <TextField
                fullWidth
                label="Teléfono 2"
                value={entity.Telefono2}
                variant={middle ? "outlined" : "standard"}
                inputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={middle ? 2 : 4}>
              <TextField
                fullWidth
                label="Teléfono 3"
                value={entity.Telefono3}
                variant={middle ? "outlined" : "standard"}
                inputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={1} xs={12}>
            <Grid item xs={big ? 6 : middle ? 6 : 12}>
              <TextField
                fullWidth
                label="Vendedor"
                value={entity.Nombre}
                variant={middle ? "outlined" : "standard"}
                inputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
         { dist === 29  &&
              <Grid container item spacing={1} xs={12}>
                <Grid item xs={big ? 6 : middle ? 6 : 12}>
                  <TextField
                    fullWidth
                    label="Vendedor Dos"
                    value={entity.VendedorDos ? entity.VendedorDos : ''}
                    variant={middle ? "outlined" : "standard"}
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
              </Grid>
        }
        
              <Grid container item spacing={1} xs={12}>
                <Grid item xs={big ? 6 : middle ? 6 : 12}>
                  <TextField
                    fullWidth
                    label="Vendedor Asignado"
                    value={entity.VendedorAsignado ? entity.VendedorAsignado : ''}
                    variant={middle ? "outlined" : "standard"}
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
              </Grid>
        
          <Grid container item spacing={1} xs={12}>
            <Grid item xs={middle ? 1 : 1}>
              <TextField
                fullWidth
                label="Canal"
                value={entity.Canal}
                variant={middle ? "outlined" : "standard"}
                inputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={middle ? 2 : 4}>
              <TextField
                fullWidth
                label="Segmento"
                value={entity.Segmento}
                variant={middle ? "outlined" : "standard"}
                inputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={middle ? 2 : 4}>
              <TextField
                fullWidth
                label="Categoría"
                value={entity.Categoria}
                variant={middle ? "outlined" : "standard"}
                inputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={middle ? 1 : 1}>
              <TextField
                fullWidth
                label="Tipo Cliente"
                value={entity.TipoCliente}
                variant={middle ? "outlined" : "standard"}
                inputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            
          </Grid>
          <Grid container item spacing={1} xs={12}>
            <Grid item xs={big ? 6 : middle ? 6 : 12}>
              <TextField
                fullWidth
                label="Persona de Contacto"
                value={entity.Contacto ? entity.Contacto: ''}
                variant={middle ? "outlined" : "standard"}
                inputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={1} xs={12}>
            <Grid item xs={middle ? 4 : 8} style={{paddingLeft: '110px'}}>
              {/* <input
                      color="primary" 
                      type="button"
                      value={   'Foto 1'}
                      style= { {height: '20px', width: 100}}
                      onClick={(props, marker, e) => handleDownLoadFile(entity)}

              /> */}
              {<Button
                
                color="primary" 
                size="medium" 
                variant="outlined" 
                onClick={(props, marker, e) => handleDownLoadFile(entity)}
               startIcon={<Icon className="fa fa-camera-retro"/>}
            >
                Foto 1
            </Button>}
            </Grid>
            <Grid item xs={middle ? 4 : 8}>
            {/* <input
                                                                  type="button"
                                                                  value={   'Foto 2'}
                                                                  style= { {height: '20px', width: 100}}
                                                                  onClick={(props, marker, e) => handleDownLoadFile2(entity)}

                                                          /> */}
              {<Button
                
                color="primary" 
                size="medium" 
                variant="outlined" 
                onClick={(props, marker, e) => handleDownLoadFile2(entity)}
               startIcon={<Icon className="fa fa-camera-retro"/>}
            >
                Foto 2
            </Button>}

            </Grid>
          </Grid>
        </Grid>
      </FieldSet>

{/*-------------------------------------------------------------------------------------------------------------------*/}

      <Details/>
      < HeadTable/>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyCrwct6vhZk5TTSuht7PpA0kAVSdoWSB7s",
})(ProspectosForm);
