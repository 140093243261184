import {
    CLEAN_FORM,
    ERROR_DESACTIVACIONES_LIST,
    RECEIVE_DESACTIVACIONES_LIST,
    REQUEST_DESACTIVACIONES_LIST,
    RESTART_STATE,
    SET_ENTITY,
    SET_MASK,
    SET_ORDER,
    SET_PAGE,
    SET_PAGE_SIZE,
    SET_SELECTED_ROW,
    SET_STATE_FORM,
    UPDATE_FIELD_FILTER,
    UPDATE_FIELD_ENTITY,
    UPDATE_TABLE_COLUMN
} from './types';

const initialState = {
    formState: 'search',
    loading: false,
    entity: {
        desactivacionId: 0,
        codigoSucursal: '',
        comentario: '',
        estado: 'P',
        dCliente: '',
        dDistribuidor: '',
        dEstado: '',
        dMotivoDesactivacion: '',
        fecha: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        foto: '',
        latitud: 0.00,
        longitud: 0.00,
        motivoRechazo: '',
        sucursal: '',
        sucursalLatitud: 0.00,
        sucursalLongitud: 0.00,
        usuarioRegistro: ''
    },
    filters: {
        dCliente: '',
        distribuidorId: 0,
        estado: 'P',
        desde: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        hasta: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        dDistribuidor: ''
    },
    store: {
        loading: false,
        pageSize: 50,
        start: 0,
        total: 0,
        data: [],
        selected: [],
        source: null
    },
    table: {
        order: 'asc',
        orderBy: '',
        columns: [
           
            { dataKey: 'nit', label: 'NIT', width: 110 },
            { dataKey: 'dCliente', label: 'Cliente', width: 210 },
            { dataKey: 'codigoSucursal', label: 'Cód. Sucursal', width: 110 },
            { dataKey: 'sucursal', label: 'Sucursal', width: 220 },
            { dataKey: 'dMotivo', label: 'Motivo', width: 145 },
            { dataKey: 'fecha', label: 'Fecha', width: 115,type: 'datetime' },//type: 'datetime'
            { dataKey: 'estado', label: 'Estado', width: 110 },
            { dataKey: 'usuario', label: 'Usuario', width: 110 },
            { dataKey: 'dDistribuidor', label: 'Agente', width: 160 },
        ]
    }
};

export const desactivacionReducer = (state, action) => {
    state = state || initialState;
    
    switch(action.type) {
        case CLEAN_FORM:
            return {
                ...state,
                entity: initialState.entity
            };
        case ERROR_DESACTIVACIONES_LIST:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: false,
                    start: 0,
                    total: 0,
                    data: [],
                    source: null
                }
            };
        case RECEIVE_DESACTIVACIONES_LIST:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: false,
                    total: action.total,
                    data: action.payload,
                    source: null
                }
            };
        case REQUEST_DESACTIVACIONES_LIST:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: true,
                    source: action.source
                }
            };
        case RESTART_STATE:
            return {
                ...initialState
            };
        case SET_ENTITY:
            return {
                ...state,
                entity: {
                    ...state.entity,
                    desactivacionId: action.payload.desactivacionId,
                    comentario: action.payload.comentario,
                    estado: action.payload.estado,
                    dCliente: action.payload.dCliente,
                    dDistribuidor: action.payload.dDistribuidor,
                    dEstado: action.payload.dEstado,
                    dMotivoDesactivacion: action.payload.dMotivoDesactivacion,
                    codigoSucursal: action.payload.codigoSucursal,
                    fecha: action.payload.fecha,
                    foto: action.payload.foto,
                    latitud: action.payload.latitud || 0.00,
                    longitud: action.payload.longitud || 0.00,
                    motivoRechazo: action.payload.motivoRechazo || '',
                    sucursal: action.payload.sucursal,
                    sucursalLatitud: action.payload.sucursalLatitud || 0.00,
                    sucursalLongitud: action.payload.sucursalLongitud || 0.00,
                    usuarioRegistro: action.payload.usuarioRegistro
                }
            };
        case SET_MASK:
            return {
                ...state,
                loading: action.loading
            };
        case SET_ORDER:
            return {
                ...state,
                table: {
                    ...state.table,
                    order: action.order,
                    orderBy: action.orderBy
                }
            };
        case SET_PAGE:
            return {
                ...state,
                store: {
                    ...state.store,
                    start: state.store.pageSize*action.page
                }
            };
        case SET_PAGE_SIZE:
            return {
                ...state,
                store: {
                    ...state.store,
                    pageSize: action.pageSize
                }
            };
        case SET_SELECTED_ROW:
            return {
                ...state,
                store: {
                    ...state.store,
                    selected: action.selected
                }
            };
        case SET_STATE_FORM:
            return {
                ...state,
                formState: action.formState
            };
        case UPDATE_FIELD_FILTER:
            return { 
                ...state,
                filters: {
                    ...state.filters,
                    [action.key]: action.value
                }
            };
        case UPDATE_FIELD_ENTITY:
            return { 
                ...state,
                entity: {
                    ...state.entity,
                    [action.key]: action.value
                }
            };
        case UPDATE_TABLE_COLUMN:
            return {
                ...state,
                table: {
                    ...state.table,
                    columns: action.columns
                }
            };
        default:
            return state;
    }
};