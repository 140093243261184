import axios from "axios";
import format from "date-fns/format";
import { IdentityUrl } from "../../../config";
import {
  closeAdvancedSearch,
  closeMessageBox,
  openAdvancedSearch,
  openMessageBox,
} from "../../main/actions";
import {
  ERROR_OLAS_LIST,
  RECEIVE_OLAS_LIST,
  RESTART_STATE,
  SET_MASK,
  SET_ORDER,
  SET_SELECTED_ROW,
  UPDATE_FIELD_FILTER,
  RECEIVE_OLAS_DET,
} from "./types";

import { exportToSpreadsheet, searchVendedor } from "../../../helpers/commons";

export const detalleCobranza = (item) => async (dispatch, getState) => {
  console.log(item)
  if (getState().reportecobranza.store.loading) {
    return;
  }

  dispatch({ type: SET_MASK, loading: true });

  try {
    let url =
      `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Reportes/cobranzas/listarcobranzas/detalle?cobranzaId=` +
      `${item["CobranzaId"]}`;
      let response = await axios.get(encodeURI(url));
      let datad = response;
      //console.log(datad)

    dispatch({ type: RECEIVE_OLAS_DET, payload: datad.data });
  
  } catch (error) {
    let msgError = "";
    dispatch({ type: SET_MASK, loading: false });
    dispatch({ type: ERROR_OLAS_LIST });

    if (error.response) {
      if (error.response.status === 400) {
        msgError =
          error.response.data.eventLogId === 0
            ? "No existe Registro  para esta fecha "
            : `EventoId: ${error.response.data.eventLogId}. ` +
              error.response.data.message;
      } else {
        msgError =
          (error.response.data.eventLogId === 0
            ? ""
            : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message;
      }
    } else {
      //console.log(error);
      msgError = "Error al conectar con el servidor....";
    }

    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message: msgError,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    dispatch({ type: SET_MASK, loading: false });
  }
};

export const onExport = () => async (dispatch, getState) => {
  if (getState().reportecobranza.store.data.length === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "No hay información para exportar.",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }
  try {
    dispatch({ type: SET_MASK, loading: true });

    //let response = await axios.get(encodeURI(url)); //, { responseType: 'arraybuffer' });

    let head = getState().reportecobranza.table.columns;

    // eslint-disable-next-line no-array-constructor
    var temp = new Array();
    for (var c in head) {
      let x = Object.values(head[c])[0];
      temp.push(x);
    }
    // eslint-disable-next-line no-array-constructor
    var carArray = new Array();

    carArray.push(temp);

    let json = getState().reportecobranza.store.data;

    console.log(Object.values(json[0]));  //valores en pos 0

    for (var i in json) {
      let y = [];
      let x = Object.values(json[i]);
      //console.log(x);

      y[0]= x[1];
      y[1]= x[2];
      y[2]= x[3];
      y[3]= x[5];
      y[4]= x[6];
      y[5]= x[14];
      y[6]= x[13];
      y[7]= x[23];
      y[8]= x[19];
      y[9]= x[20];
      y[10]= x[12];
      y[11]= x[11];
      y[12]= x[24];
      y[13]= x[25];
      carArray.push(y);
    }

    exportToSpreadsheet(carArray,  `ReporteCobranza_${getState().reportecobranza.filters.dDistribuidor}`);
  } catch (error) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message:
          (error.response.data.eventLogId === 0
            ? ""
            : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    setTimeout(() => {dispatch({ type: SET_MASK, loading: false })}, 1000);
  }
};

export const btnSearch = () => async (dispatch, getState) => {

  if (getState().reportecobranza.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }

  if (getState().reportecobranza.store.loading) {
    return;
  }

  dispatch({ type: SET_MASK, loading: true });

  try {
    let url = `${ getState().main.resources.uriSvcSalesMobilityAPI }/api/v1/Reportes/cobranzas/listarcobranzas?` +
      `distribuidorId=${getState().reportecobranza.filters.distribuidorId}` +
      `&vendedorId=${getState().reportecobranza.filters.vendedorId}`+
      `&nombre=${getState().reportecobranza.filters.nombre}`+
      `&desde=${format( getState().reportecobranza.filters.desde, "yyyy-MM-dd")}` +
      `&hasta=${format( getState().reportecobranza.filters.hasta,"yyyy-MM-dd" )}` ;

    let response = await axios.get(encodeURI(url));
    let data = response.data;
    //console.log(data);

    if (data.length === 0) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "No existe Registro  para esta consulta",
          callback: () => dispatch(closeMessageBox()),
        })
      );
      return;
    }


          let columns = [
              { dataKey: 'NIT', label: 'NIT', width: 90 },
              { dataKey: 'NombreComercial', label: 'Nombre Comercial', width: 250 },
              { dataKey: 'Direccion', label: 'Dirección', width: 300 },
              { dataKey: 'TipoCobro' ,  label: 'Tipo de Cobro',  width: 100 },
              { dataKey: 'Vendedor', label: 'Nombre Vendedor', width: 200 },  
              { dataKey: 'Fecha', label: 'Fecha ', width: 150 , type: 'date',},
              { dataKey: 'NroRecibo' ,  label: 'NroRecibo',   width: 110 },
              { dataKey: 'FechaDocumento' ,  label: 'Fecha de Documento',   width: 110 , type: 'date'}, 
              { dataKey: 'Observacion' ,  label: 'Observación',  width: 220 },
              { dataKey: 'Total', label: 'Total', width: 225, textAlign: 'right' },
              { dataKey: 'CampoEspecial01', label: 'Campo Especial01', width: 86 },
                { dataKey: 'CodigoOrigen', label: 'Código Origen', width: 82 },
                { dataKey: 'Estado', label: 'Estado', width: 82 }, 
                { dataKey: 'Comentario', label: 'Comentario Rechazo', width: 400 }, 
          ]
  
      dispatch({
        type: RECEIVE_OLAS_LIST, columns:  columns, payload: data     });

  } catch (error) {
    let msgError = "";
    console.log(error);
    dispatch({ type: SET_MASK, loading: false });
    dispatch({ type: ERROR_OLAS_LIST });

    if (error.response) {
      if (error.response.status === 400) {
        msgError =
          error.response.data.eventLogId === 0
            ? "No existe Registro  para esta fecha "
            : `EventoId: ${error.response.data.eventLogId}. ` +
              error.response.data.message;
      } else {
        msgError =
          (error.response.data.eventLogId === 0
            ? ""
            : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message;
      }
    } else {
      msgError = "Error al conectar con el servidor";
    }

    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message: msgError,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    dispatch({ type: SET_MASK, loading: false });
  }
};

export const onUpdateColumn = (column) => (dispatch, getState) => {
  getState().reportecobranza.table.columns.map((item, i) => {
    if (item.dataKey !== column.dataKey) {
      return item;
    } else {
      item.width = column.width;
    }
    return {
      // ...item,
      //  width: column.width
    };
  });
};

export const onSearchDistribuidorClick = () => async (dispatch) => {
  let fields = [
    { dataKey: "razonSocial", label: "Agente", type: "string", width: 225 },
  ];

  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de Agente",
      getData: async (params) => {
        let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`;
        let response = await axios.get(encodeURI(url), { params: params });

        return response.data;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(onUpdateFieldFilter("distribuidorId", result[0].distribuidorId));
          dispatch(onUpdateFieldFilter("dDistribuidor", `${result[0].razonSocial}`));
          dispatch(onUpdateFieldFilter("supervisorId", ""));
          dispatch(onUpdateFieldFilter("dSupervisor", ""));
          dispatch(onUpdateFieldFilter("vendedorId", ""));
          dispatch(onUpdateFieldFilter("dVendedor", ""));
          dispatch(onUpdateFieldFilter("nombre", ""));
        }
      },
    })
  );
};

export const onSearchVendedorClick = () => async (dispatch, getState) => {
  let fields = [
    {
      dataKey: "nombre",
      label: "Nombre",
      type: "string",
      search: true,
      width: 225,
    },
    { dataKey: "nombreUsuario", label: "Usuario", type: "string", width: 115 },
  ];
  if (getState().reportecobranza.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }

  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de Vendedor",
      getData: async (params) => {
        let url = `${getState().main.resources.uriSvcSalesMobilityAPI}/api/v1/Vendedores/listarvendedoresxdistribuidor`
        + `?distribuidorId=${getState().reportecobranza.filters.distribuidorId}`               
        let response = await axios.get(encodeURI(url), { params: params });
        
        return searchVendedor(response, params.nombre); 
    },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(onUpdateFieldFilter("vendedorId", result[0].usuarioId)); //    usuarioId));
          dispatch(onUpdateFieldFilter("dVendedor", `${result[0].nombre}`));
        }
      },
    })
  );
};



export const onUpdateFieldFilter = (key, value) => (dispatch, getState) => {
  //({ type: UPDATE_FIELD_FILTER, key, value });
  dispatch({ type: UPDATE_FIELD_FILTER, key, value });
  dispatch({ type: RECEIVE_OLAS_LIST, columns: [], payload: [] });
};

export const onUpdateFieldFilter2 = (key, value) => (dispatch, getState) => {
  //({ type: UPDATE_FIELD_FILTER, key, value });
  dispatch({ type: UPDATE_FIELD_FILTER, key, value });
};

export const restartState = () => ({ type: RESTART_STATE });

export const setOrder = (order, orderBy) => ({
  type: SET_ORDER,  order,   orderBy,
});

export const setSelectedRow = (selected) => ({
  type: SET_SELECTED_ROW,   selected,
});
