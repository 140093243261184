import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { onSearchRolClick,
  onSearchEntorno,
  onSearchOlaChannelMapClick,
  onSearchDistribuidorClick
} from "../../../store/fuerzaventas/gestionroles/actions";

import { ActionField, FieldSet } from "@sigeco/tools";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import FormControl from "@material-ui/core/FormControl";
import { Icon } from "@material-ui/core";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(() => ({
  content: {
    padding: "0.5rem 0.75rem 0.75rem",
  },
  filterClear: {
    display: "flex",
  },
  actionField: {
    flexDirection: "row",
    position: "relative",
  },
  btnClear: {
    position: "absolute",
    bottom: "1.5",
    right:20,
    maxWidth: "15px",
    maxHeight: "23px",
    minWidth: "15px",
    minHeight: "23px",
  },
  root: {
    flex: "0 0 auto",
    marginBottom: "0.75rem",
    "@media (min-width: 1401px)": {
      marginBottom: "1rem",
    },
  },
}));

const GestionRoleslFilter = () => {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:701px)");
  const filters = useSelector((state) => state.gestionroles.filters);
  const dispatch = useDispatch();

  //Botones de Limpiar filtros
  const [, setRol] = useState("");
  const [btn, setBtn] = useState(true);
  const [, setEntorno] = useState("");
  const [btn1, setBtn1] = useState(true);
  const [olaId, setOla] = useState("");
  const [btn2, setBtn2] = useState(true);
  const [btn3, setBtn3] = useState(true);
  const [distribuidorId, setDistribuidor] = useState("");

  useEffect(() => {
    if (filters.rol) {
      setRol(filters.rol);
      setBtn(false);
    } else {
      setBtn(true);
    }
  }, [filters.rol]);

  useEffect(() => {
    if (filters.entorno) {
      setEntorno(filters.entorno);
      setBtn1(false);
    } else {
      setBtn1(true);
    }
  }, [filters.entorno]);

  // useEffect(() => {
  //   if (filters.olaId) {
  //     setOla(filters.olaId);
  //     setOla("");
  //     setBtn2(true);
  //   } else {
  //     setBtn(true);
  //   }
  // }, [filters.olaId]);

  // useEffect(() => {
  //   if (filters.dDistribuidor) {
  //     setDistribuidor(filters.dDistribuidor);
  //     setDistribuidor("");
  //     setBtn3(true);
  //   } else {
  //     setBtn(true);
  //   }
  // }, [filters.dDistribuidor]);

  useEffect(() => {
    console.log(filters.distribuidorId.length)
    if (filters.distribuidorId.length !== 0) {
      setDistribuidor("Filtro Seleccionado");
      setBtn3(false);
    } else {
      setBtn3(true);
    }
  }, [filters.distribuidorId]);

  const handleRol = () => {
    setRol("");
    //filters.distribuidorId = 0;
    filters.rol = "";
    filters.entorno = "";
    setBtn(true);
  };

  const handleEntorno = () => {
    setEntorno("");
    //filters.vendedorId = '';
    filters.entorno = "";
    setBtn1(true);
  };

  useEffect(() => {
    console.log(filters.olaId.length)
    if (filters.olaId.length !== 0) {
      setOla("Filtro Seleccionado");
      setBtn2(false);
    } else {
      setBtn2(true);
    }
  }, [filters.olaId]);

  const handleOla = () => {
    setOla("");
    filters.olaId = 0;
    filters.dOla = "";
    setBtn(true)
  }

  const handleDist = () => {
    setDistribuidor("");
    filters.distribuidorId = 0;
    filters.dDistribuidor = "";
    //setBtn(true)
    //setBtn2(true);
    setBtn3(true);
  }

  return (
    <div className={classes.root}>
      <FieldSet
        iconCls="fas fa-filter"
        title="Filtros"
        defaultExpanded
        expandable
      >
        <Grid className={classes.content} container spacing={2}>
          <Grid  item xs={12} container>
                <Grid item xs={matches ? 4 : 12}>
                    <FormControl className={classes.filterClear}>
                        <ActionField
                        className={classes.actionField}
                        label="Roles"
                        placeholder="Enter para buscar Roles"
                        value={filters.rol}
                        variant={matches ? "outlined" : "standard"}
                        onHandleAction={() => dispatch(onSearchRolClick())}
                        />
                        <Button
                        className={classes.btnClear}
                        size="small"
                        startIcon={<Icon className="fa fa-window-close" />}
                        disabled={btn}
                        onClick={handleRol}
                        />
                    </FormControl>
                </Grid>
            </Grid>

                <Grid  item xs={12} container>
                    <Grid item xs={matches ? 4 : 12}>
                        <FormControl className={classes.filterClear}>
                            <ActionField
                            className={classes.actionField}
                            label="Entorno"
                            placeholder="Enter para elegir sesion"
                            value={filters.entorno}
                            variant={matches ? "outlined" : "standard"}
                            onHandleAction={() => dispatch(  onSearchEntorno ())}
                            />
                            <Button
                            className={classes.btnClear}
                            size="small"
                            startIcon={<Icon className="fa fa-window-close" />}
                            disabled={btn1}
                            onClick={handleEntorno}
                            />
                        </FormControl>
                    </Grid>
            </Grid>

            <Grid item xs={matches ? 3 : 12}>
              <FormControl className={classes.filterClear}>
                <ActionField
                  className={classes.actionField}
                  label="Selecciona Ola(s) Channel Map"
                  placeholder="Enter para buscar ola channel map"
                  value={olaId}
                  variant={matches ? "outlined" : "standard"}
                  onHandleAction={() => dispatch(onSearchOlaChannelMapClick())}
                />
                <Button
                  className={classes.btnClear}
                  size="small"
                  startIcon={<Icon className="fa fa-window-close" />}
                  disabled={btn2}
                  onClick={handleOla}
                />
              </FormControl>
            </Grid>

            <Grid item xs={matches ? 3 : 12}>
              <FormControl className={classes.filterClear}>
                <ActionField
                  className={classes.actionField}
                  label="Agente"
                  placeholder="Enter para buscar Agente"
                  value={distribuidorId}
                  variant={matches ? "outlined" : "standard"}
                  onHandleAction={() => dispatch(onSearchDistribuidorClick())}
                />
                <Button
                  className={classes.btnClear}
                  size="small"
                  startIcon={<Icon className="fa fa-window-close" />}
                  disabled={btn3}
                  onClick={handleDist}
                />
              </FormControl>
            </Grid>

            {/* <Grid item xs={matches ? 3 : 12}>
              <FormControl className={classes.filterClear}>
                <ActionField
                  className={classes.actionField}
                  label="Hasta Ola Channel Map"
                  placeholder="Enter para buscar ola channel map"
                  value={filters.dOla}
                  variant={matches ? "outlined" : "standard"}
                  onHandleAction={() => dispatch(onSearchOlaChannelMapClick())}
                />
                <Button
                  className={classes.btnClear}
                  size="small"
                  startIcon={<Icon className="fa fa-window-close" />}
                  disabled={btn}
                  //onClick={handleOla}
                />
              </FormControl>
            </Grid> */}
          
        </Grid>
      </FieldSet>
    </div>
  );
};

export default GestionRoleslFilter;
