import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
     onUpdateColumn,
     setOrder,
     onExport
 } from '../../../store/channelmap/reporteseguimientovendedor/actions';

import {sort_by} from '../../../helpers/commons'
import { FieldSet, Table } from '@sigeco/tools';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column'
    }, 
    tableWrap: {
        flex: '1 1 auto'
    }
}));

const ReporteSeguimientoVendedorPDV = () => {
     
    const classes = useStyles();
    const columns = useSelector(state => state.reporteseguimientovendedor.store.columns);
    const loading = useSelector(state => state.reporteseguimientovendedor.loading);
    const order = useSelector(state => state.reporteseguimientovendedor.order);
    const orderBy = useSelector(state => state.reporteseguimientovendedor.orderBy);
    const store = useSelector(state => state.reporteseguimientovendedor.store);
    
    const dispatch = useDispatch();

    const handleResizeColumn = (column) => {
        dispatch(onUpdateColumn(column))
    };

    const handleRequestSort = (property) => {
      const isAsc = orderBy === property && order === 'asc';
      dispatch(setOrder(isAsc ? 'desc' : 'asc', property));
      let homes = store.data;
      homes.sort(sort_by(property, isAsc, homes[0][property]));
    };

    const handleDownLoadFile = (item) => {
        if(item && item.uriFoto && item.uriFoto !== '') {
            let link = document.createElement('a');
            //console.log("paso: "+item.uriFoto)
            link.href = item.uriFoto;
            link.setAttribute('target', '_blank');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    
    }
    return (
        <>
        <FieldSet className={classes.root} iconCls="fas fa-th-list" title="Resultados">
            <div className={classes.tableWrap}>
                <Table
                columns={columns}
                loading={loading}
                onRowClick={() => {}} //items => dispatch(setSelectedRow(items))
                onRowDoubleClick = {() =>{}}
                onSort={datakey => handleRequestSort(datakey)}
                onUpdateColumn={handleResizeColumn}
                rowCount={store.data.length}
                rowGetter={index => store.data[index]}
                selected={store.selected}
                sortBy={orderBy}
                sortDirection={order}
                actions={[
                    {
                        iconCls: 'fas fa-file-download',
                        tooltip: 'Imagen Channel',
                        onClick: handleDownLoadFile
                    }
                ]}
                />
            </div>
        </FieldSet>
            <div className="App" style={{height: '0px'}}>          
            <Button 
                color="primary" 
                size="small" 
                variant="outlined" 
                onClick={() => dispatch(onExport())}
                startIcon={<Icon className="fas fa-file-excel"/>}
                style={{position: 'absolute',
                  bottom: '0',
                  display: 'flex',
                  flexdirection: 'column',
                  left : '0px'
                 }}
            >    
              Exportar
            </Button>
            </div>
        </>
    )
};

export default ReporteSeguimientoVendedorPDV;