import {
    ADD_VENDEDOR,
    RECEIVE_DATA,
    RESTART_STATE,
    SET_MASK,
    UPDATE_FIELD_FILTER,
    UPDATE_OLA_FIELD_FILTER,
    UPDATE_DIST_FIELD_FILTER,
} from './types';

const initialState = {
    data: [],
    loading: false,
    filters: {
        olaId: 0,
        distribuidorId: 0,
        usuarioId: '',
        dOla: '',
        dDistribuidor: '',
        dUsuario: '',
        vendedor: "",
        documentoIdentidad: '',
        dCliente: '',
        nit : '',
        departamentoId: '',
        dDepartamento: '',
        municipioId: '',
        dMunicipio: '',
        marcaId: 0,
        dMarca: '',
        lobId: '',
        dLob: '',
        canalId: '',
        dCanal: ''
    }
};

export const reporteparticipacionReducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case RECEIVE_DATA:
            return {
                ...state,
                data: action.payload
            };
        case RESTART_STATE:
            return {
                ...initialState
            };
        case SET_MASK:
            return {
                ...state,
                loading: action.loading
        };
        case UPDATE_FIELD_FILTER:
            return { 
                ...state,
                filters: {
                    ...state.filters,
                    [action.key]: action.value
                }
            };
        case UPDATE_OLA_FIELD_FILTER:
            return {
                ...state,
                filters: {
                ...state.filters,
                dCliente: "",
                nit: "",
                vendedor:"",
                dDepartamento: "",
                dMunicipio: "",
                dMarca: "",
                dLob: "",
                dCanal: "",
                },
        };
        case UPDATE_DIST_FIELD_FILTER:
        return {
            ...state,
            filters: {
            ...state.filters,
            dCliente: "",
            nit: "",
            vendedor: "",
            dDepartamento: "",
            dMunicipio: "",
            dMarca: "",
            dLob: "",
            dCanal: "",
            },
        };
        case ADD_VENDEDOR:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    vendedor: action.payload
                }
            };
        default:
            return state;
    }
}
                    // vendedor: action.payload.map(item => {
                    //     //return {nombre: item.nombre}
                    //     return {documentoIdentidad: item.documentoIdentidad}
                    //   }),
                  