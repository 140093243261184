import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    onUpdateColumn,
    setOrderDetail,
    Loadding
} from '../../../store/movilidad/reportevisitas/actions';

import { FieldSet, Table } from '@sigeco/tools';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { sort_by } from '../../../helpers/commons'

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        overflow: 'hidden'
    }, 
    tableWrap: {
        flex: '1 1 auto',
        overflow: 'hidden'
    }
}));

const ReporteVisitasListDetail = () => {
    const classes = useStyles();
    const columns = useSelector(state => state.reportevisitas.tableClient.columns);
    const order = useSelector(state => state.reportevisitas.tableClient.order);
    const orderBy = useSelector(state => state.reportevisitas.tableClient.orderBy);
    const store = useSelector(state => state.reportevisitas.storeClient);
    const loading = useSelector(state => state.reportevisitas.loading);

    const dispatch = useDispatch();

    console.log(store.data)

    const handleDownLoadFile = (item) => {
        if(item && item.foto01 && item.foto01 !== '') {
            let link = document.createElement('a');

            link.href = item.foto01;
            link.setAttribute('target', '_blank');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

        if(item && item.foto02 && item.foto02 !== '') {
            let link = document.createElement('a');

            link.href = item.foto02;
            link.setAttribute('target', '_blank');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    // const handleRequestSort = (property) => {
    //     dispatch( Loadding( true ));
    //     const isAsc = orderBy === property && order === 'asc';
    //     dispatch(setOrder(isAsc ? 'desc' : 'asc', property));
    //     let homes = store.data;
        
    //     homes.sort(sort_by(property, isAsc, homes[0][property]));
    //     dispatch( Loadding( false ));
    // };
    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        dispatch(setOrderDetail(isAsc ? 'desc' : 'asc', property));
        let homes = store.data;
        homes.sort(sort_by(property, isAsc, homes[0][property]));
    };

    const handleResizeColumn = (column) => {
        dispatch(onUpdateColumn(column))
    };

       
    return (
        <FieldSet className={classes.root} iconCls="fas fa-th-list" title="Resultados">
            <div className={classes.tableWrap} >
                <Table
                    columns={columns}
                    count={store.total}
                    loading={loading}
                    onRowClick={() => /*{alert()}*/() => {}}
                    onRowDoubleClick={() => {}}
                    onSort={datakey => handleRequestSort(datakey)}
                    onUpdateColumn={handleResizeColumn}
                    rowCount={store.data.length}
                    rowGetter={index => store.data[index]}
                    selected={store.selected}
                    sortBy={orderBy}
                    sortDirection={order}
                    actions={[
                        {
                            iconCls: 'fas fa-file-download',
                            tooltip: 'Decargar captura',
                            onClick: handleDownLoadFile
                        }
                    ]}
                />
            </div>
        </FieldSet>
    )
};

export default ReporteVisitasListDetail;