import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  onSearchDistribuidorClick,
  onSearchOlaChannelMapClick,
  onSearchVendedorClick,
  onActualizaTipo,
  onUpdateOlaFieldFilter ,
} from "../../../store/channelmap/reporteseguimientovendedor/actions";

import { ActionField, FieldSet } from "@sigeco/tools";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FormControl from "@material-ui/core/FormControl";
import { Icon } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles(() => ({
  content: {
    padding: "0.5rem 0.75rem 0.75rem",
  },
  filterClear: {
    display: "flex",
  },
  actionField: {
    flexDirection: "row",
    position: "relative",
  },
  btnClear: {
    position: "absolute",
    bottom: "1.5",
    right:20,
    maxWidth: "15px",
    maxHeight: "23px",
    minWidth: "15px",
    minHeight: "23px",
  },
  root: {
    flex: "0 0 auto",
    marginBottom: "0.75rem",
    "@media (min-width: 1401px)": {
      marginBottom: "1rem",
    },
  },
}));

const ReporteSeguimientoVendedorFilter = () => {
  const classes = useStyles();
  const middle = useMediaQuery("(min-width:701px)");
  const filters = useSelector(
    (state) => state.reporteseguimientovendedor.filters
  );
  const dispatch = useDispatch();
  const [estado, setEstado] = useState(0);

  //Botón de limpiar filtro
  const [, setOla] = useState("");
  const [btn, setBtn] = useState(true);
  const [, setDistribuidor] = useState("");
  const [btn1, setBtn1] = useState(true);
  const [, setVendedor] = useState("");
  const [btn2, setBtn2] = useState(true);

  useEffect(() => {
    if (filters.dOla) {
      setOla(filters.dOla);
      setBtn(false);
    } else {
      setBtn(true);
    }
  }, [filters.dOla]);

  useEffect(() => {
    if (filters.dDistribuidor) {
      setDistribuidor(filters.dDistribuidor);
      setBtn1(false);
    } else {
      setBtn1(true);
    }
  }, [filters.dDistribuidor]);

  useEffect(() => {
    setVendedor('');
    if (filters.dVendedor) {
      setVendedor(filters.dVendedor);
      setBtn2(false);
    } else {
      setBtn2(true);
    }
  }, [filters.dVendedor]);

  const handleOla = () => {
    setOla("");
    setDistribuidor("");
    setVendedor("");

    filters.olaId = 0;
    filters.dOla = "";
    filters.distribuidorId = 0;
    filters.dDistribuidor = "";
    filters.vendedorId = "";
    filters.dVendedor = "";
    dispatch(onUpdateOlaFieldFilter);
    setBtn(true);
    setBtn1(true);
    setBtn2(true);
  };

  const handleDist = () => {
    setDistribuidor("");
    setVendedor("");
    filters.distribuidorId = 0;
    filters.dDistribuidor = "";
    filters.vendedorId = "";
    filters.dVendedor = "";
    setBtn1(true);
    setBtn2(true);
  };

  const handleVendedor = () => {
    setVendedor("");
    filters.vendedorId = "";
    filters.dVendedor = "";
    setBtn2(true);
  
  };

 const handleTipoCliente = (event) =>{
  setEstado(event.target.value);
  dispatch(onActualizaTipo (event.target.value));

 }
  return (
    <div className={classes.root}>
      <FieldSet
        iconCls="fas fa-filter"
        title="Filtros"
        defaultExpanded
        expandable
      >
        <Grid className={classes.content} container spacing={2}>
          <Grid container item spacing={2} xs={12}>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <FormControl className={classes.filterClear}>
                <ActionField
                  className={classes.actionField}
                  label="Ola Channel Map"
                  placeholder="Enter para buscar ola channel map"
                  value={filters.dOla}
                  variant={middle ? "outlined" : "standard"}
                  onHandleAction={() => dispatch(onSearchOlaChannelMapClick())}
                />
                <Button
                  className={classes.btnClear}
                  size="small"
                  startIcon={<Icon className="fa fa-window-close" />}
                  disabled={btn}
                  onClick={handleOla}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <FormControl className={classes.filterClear}>
                <ActionField
                  className={classes.actionField}
                  label="Agente"
                  placeholder="Enter para buscar Agente"
                  value={filters.dDistribuidor}
                  variant={middle ? "outlined" : "standard"}
                  onHandleAction={() => dispatch(onSearchDistribuidorClick())}
                />
                <Button
                  className={classes.btnClear}
                  size="small"
                  startIcon={<Icon className="fa fa-window-close" />}
                  disabled={btn1}
                  onClick={handleDist}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <FormControl className={classes.filterClear}>
                <ActionField
                  className={classes.actionField}
                  label="Vendedor"
                  placeholder="Enter para buscar Vendedor"
                  value={filters.dVendedor}
                  variant={middle ? "outlined" : "standard"}
                  onHandleAction={() => dispatch(onSearchVendedorClick())}
                />
                <Button
                  className={classes.btnClear}
                  size="small"
                  startIcon={<Icon className="fa fa-window-close" />}
                  disabled={btn2}
                  onClick={handleVendedor}
                />
              </FormControl>
              </Grid>

            <Grid item  xs={middle ? 2 : 6}>
              <FormControl fullWidth variant={middle ? "outlined" : "standard"} style={{width:"129%"}}>
                <Select
                  id="lang"
                  value={estado}
                  onChange={handleTipoCliente}
                  style={{width:"70%"}}
                >
                  <MenuItem value={"0"}>Todos</MenuItem>
                  <MenuItem value={"1"}>Cliente</MenuItem>
                  <MenuItem value={"2"}>Prospecto</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </FieldSet>
    </div>
  );
};

export default ReporteSeguimientoVendedorFilter;
