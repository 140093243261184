import React, { useLayoutEffect , useState} from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_material from "@amcharts/amcharts4/themes/material";

import makeStyles from '@material-ui/core/styles/makeStyles';

am4core.useTheme(am4themes_material);
am4core.useTheme(am4themes_animated);

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        padding: '2rem 0'
    }
}));

const ListarProspectosGraph = () => {
    const classes = useStyles();
    const data = useSelector(state => state.listarprospectos.store.data);
    const [total, setTotal] = useState(0)
    useLayoutEffect(() => {
      
        var container = am4core.create("chartdiv", am4core.Container);
        
        container.width = am4core.percent(100);
        container.height = am4core.percent(100);
        container.layout = "vertical";

        if(data.length > 0) {
            container.exporting.menu = new am4core.ExportMenu();
            container.exporting.menu.align = "left";
            container.exporting.menu.verticalAlign = "top"
            container.exporting.menu.items = [
                {
                  "label": "...",
                  "menu": [
                    {
                      "label": "Imagen",
                      "menu": [
                        { "type": "png", "label": "PNG" },
                        { "type": "jpg", "label": "JPG" },
                        { "type": "svg", "label": "SVG" },
                        { "type": "pdf", "label": "PDF" }
                      ]
                    },  {
                      "label": "Imprimir", "type": "print"
                    }
                  ]
                }
              ]
        }
        getDataByLob('Nombre');
        createChart(container, 'Prospectos', getDataByLob('Nombre'));

     
        return () => {
            container.dispose();
        }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const createChart = (container, titleText, data) => {
        am4core.useTheme(am4themes_animated);
        
        var chart = container.createChild(am4charts.PieChart3D);
        var title = chart.titles.create();
        title.text = titleText;
        title.fontSize = 20;

        chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

        chart.legend = new am4charts.Legend();
        chart.data = [
          {
            country: "Lithuania",
            litres: 501.9
          }
        ];

        chart.data = [];
        let  t = 0;
        data.map((item)=>{
              chart.data.push({ 'Puntos' : item['Puntos']['false']  , "Nombre":  item.Nombre} ) //item.Nombre.split(' ')[0]+
               t += item['Puntos']['false'] ;
               setTotal(t)
               return ''
            }
        );
            
        var series = chart.series.push(new am4charts.PieSeries3D());
        series.dataFields.value = "Puntos";
        series.dataFields.category = "Nombre";
  };

    const getDataByLob = (lob) => {
        return _(data)
            .groupBy('Nombre')
            .map((item, key) => ({
                'Nombre': key,
                'Puntos': _.countBy(item, i => i.NombreComercial === true ),
            }))
            .value()
    }

  //   const getSummary = () => {
  //     const summary = {
  //         total:  _.sumBy(data, i => i.VendedorId > 0 ),
  //     }
  //     console.log(summary.total)
  //     return summary.total
  // }
  
    return (
      <>
         
      <div className={classes.root}>
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0.5rem 1.5rem', alignItems: 'center' }}>
              <div style={{ fontSize: '1.5rem', fontWeight: 600 }}>TAMAÑO DEL MERCADO</div>
              <div style={{ height: 60, width: 120, backgroundColor: '#8fb4e3', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%', borderWidth: 2, borderStyle: 'solid', borderColor: '#b3cde3' }}>
              {  Intl.NumberFormat('es-MX').format(total)} /Prospectos</div>
                  
          </div>

           <div style={{ height: 500 }} id="chartdiv" />        
          </div>
      </>
    );
};

export default ListarProspectosGraph ;