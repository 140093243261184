import React, { useEffect, useState }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NativeSelect from '@material-ui/core/NativeSelect';
import {
    onSearchDistribuidorClick,
    onSearchSupervisorClick,
    onSearchVendedorClick,
    onUpdateFieldFilter,
    UpdateField,
    onUpdateBlank
} from '../../../store/channelmap/avanceventasgalones/actions';

import { ActionField, FieldSet } from '@sigeco/tools';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { KeyboardDatePicker } from '@material-ui/pickers';

import Box from '@material-ui/core/Box';
import FormControl from "@material-ui/core/FormControl";
import { Icon } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import {getDistribuidorDefault} from '../../../helpers/commons';

const useStyles = makeStyles(() => ({
    content: {
        padding: '0.5rem 0.75rem 0.75rem'
    },
    filterClear: {
        display: "flex",
      },
      actionField: {
        flexDirection: "row",
        position: "relative",
      },
      btnClear: {
        position: "absolute",
        bottom: "1.5",
        right:20,
        maxWidth: "15px",
    maxHeight: "23px",
    minWidth: "15px",
    minHeight: "23px",
      },
    root: {
        flex: '0 0 auto',
        marginBottom: '0.75rem',
        '@media (min-width: 1401px)': {
            marginBottom: '1rem',
        }
    }
}));

const AvanceVentasGalonesFilter = () => {
    const classes = useStyles();
    const matches = useMediaQuery('(min-width:701px)');
    const filters = useSelector(state => state.avanceventagalones.filters);
    const dispatch = useDispatch();

    useEffect(() =>{

      getDistribuidorDefault(onUpdateFieldFilter,dispatch);
  
    },[])

    //Botones de Limpiar filtros
    const [, setDistribuidor] = useState("");
    const [btn, setBtn] = useState(true);
    const [, setSupervisor] = useState("");
    const [btn1, setBtn1] = useState(true);
    const [, setVendedor] = useState("");
    const [btn2, setBtn2] = useState(true);
  
    useEffect(() => {
      if (filters.dDistribuidor) {
        setDistribuidor(filters.dDistribuidor);
        setBtn(false);
      }else {
        setBtn(true);
      }
      
    }, [filters.dDistribuidor]);
  
    useEffect(() => {
      if (filters.dSupervisor) {
        setSupervisor(filters.dSupervisor);
        setBtn1(false);
      } else {
        setBtn1(true);
      }
    }, [filters.dSupervisor]);
  
    useEffect(() => {
      if (filters.dVendedor) {
        setVendedor(filters.dVendedor);
        setBtn2(false);
      } else {
        setBtn2(true);
      }
    }, [filters.dVendedor]);
  
    const handleDist = () => {
      setDistribuidor("");
      setSupervisor("");
      setVendedor("");
      filters.distribuidorId = 0
      filters.dDistribuidor = ''
      filters.supervisorId = ''
      filters.dSupervisor = ''
      filters.vendedorId= '';
      filters.dVendedor= '';
      setBtn(true);
      setBtn1(true);
      setBtn2(true);
    };
    const handleSupervisor = () => {
      setSupervisor("");
      setVendedor("");
      filters.supervisorId = '';
      filters.dSupervisor = '';
      filters.vendedorId= '';
      filters.dVendedor= '';
      setBtn1(true);
      setBtn2(true);
    };
    const handleVendedor = () => {
      setVendedor("");
      filters.vendedorId= '';
      filters.dVendedor= '';
      setBtn2(true);
    };
  
    function onChange(event) {
         dispatch(onUpdateBlank());
         dispatch(UpdateField('track', event.target.value ))
      }

    return (
        <div className={classes.root}>
            <FieldSet iconCls="fas fa-filter" title="Filtros" defaultExpanded expandable>
                <Grid className={classes.content} container spacing={2}>
                    <Grid item xs={12} container>
                        <Grid item xs={matches ? 4 : 12}>
                        <FormControl className={classes.filterClear}>
                            <ActionField
                            className={classes.actionField}
                                label="Agente"
                                placeholder="Enter para buscar Agente"
                                value={filters.dDistribuidor}
                                variant={matches ? "outlined" : "standard"}
                                onHandleAction={() => dispatch(onSearchDistribuidorClick())}
                            />
                            <Button
                  className={classes.btnClear}
                  size="small"
                  startIcon={<Icon className="fa fa-window-close"  />}
                  disabled={btn}
                  onClick={handleDist}
                  />
                   </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container>
                        <Grid item xs={matches ? 4 : 12}>
                        <FormControl className={classes.filterClear}>
                            <ActionField
                            className={classes.actionField}
                                label="Supervisor"
                                placeholder="Enter para buscar supervisor"
                                value={filters.dSupervisor}
                                variant={matches ? "outlined" : "standard"}
                                onHandleAction={() => dispatch(onSearchSupervisorClick())}
                            />
                             <Button
                  className={classes.btnClear}
                  size="small"
                  startIcon={<Icon className="fa fa-window-close" />}
                  disabled={btn1}
                  onClick={handleSupervisor}
                />
              </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container>
                        <Grid item xs={matches ? 4 : 12}>
                        <FormControl className={classes.filterClear}>
                            <ActionField
                                label="Vendedor"
                                placeholder="Enter para buscar vendedor"
                                value={filters.dVendedor}
                                variant={matches ? "outlined" : "standard"}
                                onHandleAction={() => dispatch(onSearchVendedorClick())}
                            />
                            <Button
                className={classes.btnClear}
                size="small"
                startIcon={<Icon className="fa fa-window-close" />}
                disabled={btn2}
                onClick={handleVendedor}
              />
            </FormControl>
                        </Grid>
                    </Grid>
            
                    <Grid container item xs={3} style= {{display : filters.sw ? 'none': 'block'}}>
                    <Grid item>
                            <KeyboardDatePicker
                                autoOk
                                disableToolbar
                                format="MM/yyyy"
                                label="Período"
                                views={["year", "month"]}
                                value={filters.desde}
                                inputVariant={matches ? "outlined" : "standard"}
                                helperText="Inicie seleccionado el año, luego el mes"
                                variant={matches ? "inline" : "dialog"}
                                onChange={date => dispatch(onUpdateFieldFilter('desde', date))}
                            />
                        </Grid>
                    </Grid>
      
                </Grid>
            </FieldSet>
        </div>
    )
};

export default AvanceVentasGalonesFilter;