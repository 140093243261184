/* eslint-disable array-callback-return */
import React, { useState , useEffect} from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import { Map, InfoWindow, Marker, GoogleApiWrapper, Polyline  } from "google-maps-react";
//import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  btnSearch2,
} from '../../../store/movilidad/tracking/actions';
var markerObjects=  [];

// const useStyles = makeStyles(() => ({
//   content: {
//       padding: '0.5rem 0.75rem 0.75rem'
//   },
//   root: {
//     background: 'gold',
//     opacity: 0.7,
//     left: '80%',
//     top: '65px',
//     right: '300px',
//     bottom: '0',
//     margintop: '65px',
//     marginright: '0.8rem',
//     position: 'absolute',
//     height: '12px',
//     width: '470px'
//   }
// }));



function MapDirectionsRenderer(props) {
  
        const [error, ] = useState(null);
        const { pos } = props;
        const { places } = props;  //lat y lng
        const { map } = props;
        const {sw } = props
        

        var service_callback = function(response, status) {
          console.log(sw)

          if (status !== 'OK') {
              return;
          }
          var pOptions = {
            strokeColor:  sw ? '#FFFFFF' : '#4030FF'   ,
            strokeOpacity:  sw ? 1  : 0.9,
            strokeWeight:  sw ? 1 :  1,
            icons: [{
              offset: '0',
              repeat: '10px'
          }],
           };
          const renderer = new props.google.maps.DirectionsRenderer();
          renderer.setMap(map);
          renderer.setOptions({ suppressMarkers: true, preserveViewport: true, polylineOptions:  pOptions });
          //renderer.setOptions({ suppressMarkers: false, preserveViewport: false, polylineOptions:  pOptions });
          renderer.setDirections(response);
        };

        useEffect(() => {

          if (pos <= (places.length - 2)){
            return}
       

          if (map === 'undefined'){ return;}
          if (places === 'undefined'){ return;}
          if (sw){
            const renderer = new props.google.maps.DirectionsRenderer();
            renderer.setMap(null);
  
          }

          // Divide route to several parts because max stations limit is 25 (23 waypoints + 1 origin + 1 destination)

          for (var i = 0, parts = [], max = 25 - 1; i < places.length; i = i + max){
              parts.push(places.slice(i, i + max + 1));
          }
          //handleShow(parts);

          const service = new props.google.maps.DirectionsService();
          // Send requests to service to get route (for stations count <= 25 only one request will be sent)
          for (var x = 0; x < parts.length; x++) {   //
            // Waypoints does not include first station (origin) and last station (destination)
            var waypoints = [];
            for (var j = 1; j < parts[x].length - 1; j++)
                waypoints.push({location: parts[x][j], stopover: true});

            // Service options
            var service_options = {
                origin:  parts[x][0],
                destination:  parts[x][parts[x].length - 1],
                waypoints: waypoints,
                travelMode: 'WALKING'
            };
            // Send request
           
          }
            service.route(service_options, service_callback);

          
        });

            if (error === null) {
                return null;
              }
          if (error) {
            return <h1>state.error</h1>;
          }

}

function MapDirectionsTrack(props) {
               const { pos } = props;
               const { places } = props;
               const { map } = props;
               const [toggled, ] = useState(true);
               const dispatch = useDispatch();

          useEffect(() => {
            if (pos <= (places.length - 2)){
              return}

            if (map === 'undefined'){ return;}
            if (places === 'undefined'){ return;}
             setInterval(function() {
              if (toggled){
                dispatch(btnSearch2())
              }

          }, 12000 );

        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

                return (
                  <Marker
                  key={1}
                  position={{lat:0, lng: 0}}
                  placeIndex={1}
                  />
              );

    }
// -------------------------------------------------------------------------------------------------------------------------------------------
export const TrackingMap = ({ google }) => {
      const [item, setItem] = useState({});
      const [marker, setMarker] = useState({});
      const [showingInfo, setShowingInfo] = useState(true);
      const [sw, setSw] = useState(true);
      const store = useSelector(state => state.tracking.store);

      const lineSymbol = {
        path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
      };

      var Dta =  store.data.filter(number =>  number.Longitud !== "" && number.Latitud !== "");
      Dta =  Dta.filter(number =>  !isNaN( parseFloat(number.Longitud))  && !isNaN(number.Latitud ));
       markerObjects=  [];
    ;

      Dta.map((item, i) =>
      //markerObjects.push({"lat":  parseFloat(item.Latitud),"lng": parseFloat(item.Longitud), "orden": item.Orden, "Nombre": item.DVendedor } )
      markerObjects.push({"lat":  parseFloat(item.Latitud),"lng": parseFloat(item.Longitud) } )
      );
    //no borrar
    //  const waypoints = markerObjects.map(p => ({
    //   location: { lat: p.lat, lng: p.lng }
    // }));

    
       var fin = markerObjects.length;
       //var iconUrl= "http://maps.google.com/mapfiles/kml/shapes/schools.png";
       var iconSize = new google.maps.Size(64, 64)
      var zoom = 12;

      const fil =  useSelector(state => state.tracking.filters.track);
      const vendedorId =  useSelector(state => state.tracking.filters.vendedorId);


      const onMarkerClick = (marker, item) => {
          setItem(item);
          setMarker(marker);
          setShowingInfo(true);
      };

      const onVendorClick = (marker, item) => {
        markerObjects[0]  = {
          lat: item.Latitud,
          lng: item.Longitud
        }
        zoom = 8;
    };
    const handleBoundsChanged = () => {

    };

     const handleRoad = () => {
          if (sw) {
            setSw(false);
        } else {

            setSw(true);
        }
    };


      // const renderChips = () =>

      // //Array(
      //   store.data.map((item, i) => (
      //       <div style={{ background: 'gold',
      //           opacity: 0.7,
      //           left: '30%',
      //           top: '10px',
      //           right: '300px',
      //           bottom: '0',
      //           margintop: '65px',
      //           marginright: '0.8rem',
      //           position: 'absolute',
      //           height: '12px',
      //           width: '470px'}}>
      //             <input
      //                     type="button"
      //                     value={item.DVendedor}
      //                     style= { {height: '40px'}}
      //             />
      //         </div>

      //   ));



        if (fil === 'U'  && vendedorId ===''){
           // iconUrl= "http://maps.google.com/mapfiles/ms/icons/motorcycling.png";
            zoom = 8;
        }
        return(
          <>
            <h3>la   </h3>
            <div  >
            <Map
                  google={google}
                  initialCenter={ fil === 'U' ?   markerObjects[fin-1] : markerObjects[0] }
                  style={{width: '100%', height: '100%', position: 'relative'}}
                  zoom={fil === 'T' ?   12 : zoom }
                  onBoundsChanged={handleBoundsChanged}
              >
                          { sw  &&  fil==='T'  &&   vendedorId !=='' &&
                                <Polyline
                                //path={[{ lat: -12.07337791, lng: -76.99332903 }, { lat: -12.06316024, lng: -76.99901822 }]}
                                      path= {markerObjects}                             
                                                label={"Test"}
                                                clickable
                                                visible
                                                strokeColor=  '#FF0000'
                                                strokeOpacity = {1.0}
                                                strokeWeight =  {2}
                                                geodesic= {true}
                                                fillOpacity= {0.35}
                                                stroke="#FFFFFF"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                icons= {[
                                                  {
                                                    icon: lineSymbol,
                                                    offset: "100%",
                                                    repeat: '20px'
                                                  },
                                                ]}
                                        /> 
                            }  

                            {  fil==='T'  &&   vendedorId !=='' &&    
                                                <div style={{ background: 'gold',
                                                          opacity: 0.7,
                                                          left: '30%',
                                                          top: '10px',
                                                          right: '300px',
                                                          bottom: '0',
                                                          margintop: '65px',
                                                          marginright: '0.8rem',
                                                          position: 'absolute',
                                                          height: '12px',
                                                          width: '470px'}}>
                                                          <input
                                                                  type="button"
                                                                  value={  sw ? 'Ocultar Autopista' : 'Monstrar Autopista'}
                                                                  style= { {height: '40px'}}
                                                                  onClick={(props, marker, e) => handleRoad()}

                                                          />
                                                </div>              
                              }              

                    {store.data.map((item, i) => {
                      if (fil==='T'  &&   vendedorId !=='' ) {
                          return (
                              <Marker
                              key={`sin-${i}` }
                              position={{lat: parseFloat(item.Latitud), lng: parseFloat(item.Longitud)}}
                              placeIndex={i.id}
                              title={item.DCliente}
                              name={item.name}
                              label= {item.Orden.toString()}
                              onClick={(props, marker) => onMarkerClick(marker, item, i)}
                              />
                          );
                      }
                    })}

                  {/* {store.data.map((item, i) => {
                  if (fil==='T'  &&   vendedorId !=='') {
                    return (
                      <>
                        {/* <Marker
                        key={i}
                        position={{lat: parseFloat(item.Latitud), lng: parseFloat(item.Longitud)}}
                        placeIndex={i.id}
                        title={item.DCliente}
                        name={item.name}
                        label= {item.Orden.toString()}
                        onClick={(props, marker) => onMarkerClick(marker, item, i)}
                        /> *


                    </>
                    );
                  }
                })} */}

              {store.data.map((item, i) => {
                  if (fil==='U'    &&  (vendedorId ===''  || vendedorId !=='' ) ) {
                    var icono='';
                    switch(item.Tipo )
                    {
                      case 'A':
                      icono='http://maps.google.com/mapfiles/ms/icons/green-dot.png'
                    break;
                    case 'C':
                      icono = 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png';
                    break;
                    case 'M':
                      icono = 'http://maps.google.com/mapfiles/ms/icons/motorcycling.png';
                    break;
                    default:
                    //default statement or expression;
                    }

                    let labelx= {
                      text: item.DVendedor,
                      color: "#061929",
                      fontSize: "18px",
                      fontWeight: "bold",
                      strokeWeight: 1.0,
                      strokeColor: '#01579B',
                          backgroundColor: 'red',
                          borderRadius: 10,
                          padding: 10,
                          backgroundStrokeColor: '#01579B',
                          backgroundStrokeWeight: 3
                    }
                    return (
                        <Marker
                        key={`mark-${i}` }
                        position={{lat: parseFloat(item.Latitud), lng: parseFloat(item.Longitud)}}
                        placeIndex={i.id}
                        title={item.DCliente}
                        name={item.name}
                        label= {labelx}
                        icon={{ url: icono , scaledSize: iconSize }}
                        onClick={(props, marker, e) => onMarkerClick(marker, item, i)}
                        />
                    );
                  }
                })}

                {/*   ultimo de todos los vendedores   */  }
              {store.data.map((item, i) => {
                  if (fil==='U'    &&  vendedorId ==='') {
                    return (
                      <div style={{ background: 'gold',
                                opacity: 0.7,
                                left: '30%',
                                top: '10px',
                                right: '300px',
                                bottom: '0',
                                margintop: '65px',
                                marginright: '0.8rem',
                                position: 'absolute',
                                height: '12px',
                                width: '470px'}}>

                                <input
                                        type="button"
                                        value={item.DVendedor}
                                        style= { {height: '40px'}}
                                        onClick={(props, marker, e) => onVendorClick(marker, item, i)}
                                />
                            </div>
                    );
                  }
                })}

                {store.data.map((item, i) => {
                  if (fil==='T'  &&   vendedorId !=='') {
                  // return (
                  //     <Marker
                  //     key={i}
                  //     position={(markerObjects[fin-1])}
                  //     placeIndex={i.id}
                  //     title={item.DCliente}
                  //     name={item.name}
                  //     label= {fin.toString()}
                  //     icon={{ url: iconUrl , scaledSize: iconSize }}
                  //     onClick={(props, marker, e) => onMarkerClick(marker, item, i)}
                  //     />
                  // );
                }
                })}

                { showingInfo  &&
                  <InfoWindow
                      key={`sho-${item.id}` }
                      marker={marker}
                      visible={true}
                      title ={"hola"}
                  >
                      <div>
                          <h5>{item.Hora}</h5>
                          <h3> {item.DVendedor}</h3>
                      </div>
                  </InfoWindow>
                  }
                  <InfoWindow
                  >
                      <div>
                          <h5>{item.Hora}</h5>
                          <h3> {item.DVendedor}</h3>
                      </div>
                  </InfoWindow>

                {store.data.map((item, i) => {

                  if (fil==='T' ) {
                       return (
                       <MapDirectionsRenderer
                          key={`ren-${i}` }
                           places={markerObjects}
                           travelMode={google.maps.TravelMode.AIRWAYS}
                           pos = {i}
                           store = {store}
                           google = {google}
                           sw = {sw}
                       />
                       );

                  }

                  if ( fil === 'U') {
                    return (
                    <MapDirectionsTrack
                       key={`track-${i}` }
                        places={markerObjects}
                        travelMode={google.maps.TravelMode.DRIVING}
                        pos = {i}
                        store = {store}
                        //google = {google}
                    />
                    );
                  }
              })}
{/* 
              
           {bata.map((item, i) => {
                  if (fil==='T'  &&   vendedorId !=='' && markerObjects) {
                    return (
                      <MapDrawLines
                       key={i}
                        places={markerObjects}
                        travelMode={google.maps.TravelMode.DRIVING}
                        pos = {i}
                        store = {store}
                        //google = {google}
                    />
                    );
                  }
                })}
 */}
           </Map>
          </div>
          </>
        );
     };

export default GoogleApiWrapper({
  apiKey: "AIzaSyCrwct6vhZk5TTSuht7PpA0kAVSdoWSB7s"
})(TrackingMap);