import React, {useEffect} from "react";
import {useDispatch, useSelector } from "react-redux";
import {
    btnSearch,
    btnExport
} from '../../store/marketing/avanceidentificacion/actions';

import { Body, Footer, Page } from '@sigeco/tools';
import { Button, Icon, makeStyles } from "@material-ui/core";
import AvanceIdentificacionFilter from "../../components/marketing/avanceidentificacion/AvanceIdentificacionFilter";
import AvanceIdentificacionList from "../../components/marketing/avanceidentificacion/AvanceIdentificacionList";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

// import Backdrop from '@material-ui/core/Backdrop';


const useStyles = makeStyles(() => ({
    content: {
        padding: '0.5rem 0.75rem 0.75rem'
    },
    root: {
        flex: '0 0 auto',
        marginBottom: '0.75rem',
        '@media (min-width: 1401px)': {
            marginBottom: '1rem',
        }
    }
}));
const AvanceIdentificacionMercado = ({onClose, setTitle}) => {
    const classes = useStyles();
    const loading = useSelector(state => state.avanceidentificacionmercado.loading);
    const dispatch = useDispatch();

    useEffect(() => {
        setTitle('Total');
    // eslint-disable-next-line
    }, []);

    function onBtnClose() {
        onClose();
    }
    return(
        <Page className={classes.root}>
            <Backdrop style={{ position: 'absolute', zIndex: 1200 }} open={loading} transitionDuration={0}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Body>
            <AvanceIdentificacionFilter/>
            <AvanceIdentificacionList/>


            </Body>
            <Footer>
                <Button
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={() => dispatch(btnExport())}
                    startIcon={<Icon className="fas fa-file-excel"/>}
                >
                    Exportar
                </Button>
                <Button
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={() => dispatch(btnSearch())}
                    startIcon={<Icon className="fas fa-search"/>}
                >
                    Buscar
                </Button>
                
                <Button 
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={() => onBtnClose()}
                    startIcon={<Icon className="fas fa-door-open"/>}
                >
                    Salir
                </Button>
            </Footer>
        </Page>
    )
}

export default AvanceIdentificacionMercado;