import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    content: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: 'calc(50% - 20px)',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    image: {
        marginTop: '1rem',
        width: '15rem',
        '@media (min-width: 701px)': {
          width: '22rem'
        },
        '@media (min-width: 1401px)': {
          width: '26rem'
        }
    },
    imageMobil: {
        marginTop: '1rem',
        width: '10rem',
        '@media (min-width: 701px)': {
          width: '16rem'
        },
        '@media (min-width: 1401px)': {
          width: '20rem'
        }
    },
    progress: {
        height: '2rem',
        width: '2rem',
        '@media (min-width: 701px)': {
            height: '2.5rem',
            width: '2.5rem',
        },
    },
    root: {
        backgroundColor: theme.palette.background.default,
        position: 'fixed',
        height: '100%',
        width: '100%'
    }
  }));

const Loading = ({ pais }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <CircularProgress size='' className={classes.progress} color='secondary' />
                <img alt='logo-mobil' className={pais === 'CO' ? classes.image : classes.imageMobil} src={pais === 'CO' ? './images/logo-cobranding.png' : './images/Mobil_R-TM.png' }></img>
            </div>
        </div>
    );
}

export default Loading;