import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    onUpdateColumn,
    setOrder,
    setPage,
    setPageSize,
} from '../../../store/clientes/clientesvendedor/actions';

import { FieldSet, Table } from '@sigeco/tools';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {sort_by} from '../../../helpers/commons'

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column'
    }, 
    tableWrap: {
        flex: '1 1 auto'
    }
}));

const ClientesVendedorList = () => {
    const classes = useStyles();
    const columns = useSelector(state => state.clientesvendedor.table.columns);
    const loading = useSelector(state => state.clientesvendedor.loading);
    const order = useSelector(state => state.clientesvendedor.table.order);
    const orderBy = useSelector(state => state.clientesvendedor.table.orderBy);
    const store = useSelector(state => state.clientesvendedor.store);
    const dispatch = useDispatch();

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        dispatch(setOrder(isAsc ? 'desc' : 'asc', property));
        let homes = store.data;
        homes.sort(sort_by(property, isAsc, homes[0][property]));
    };

    function handleChangePage(newPage) {
        dispatch(setPage(newPage));
    }
    
    function handleChangeRowsPerPage(event) {
        dispatch(setPageSize(event.target.value));
        dispatch(setPage(0));
    }

    const handleResizeColumn = (column) => {
        dispatch(onUpdateColumn(column))
    }

    const clickRow = (item) => {
        return;
    }
    
    return (
        <FieldSet className={classes.root} iconCls="fas fa-th-list" title="Resultados">
            <div className={classes.tableWrap}>
                <Table
                    columns={columns}
                    count={store.total}
                    loading={loading}
                    onRowClick={items => (clickRow(items))}
                    onRowDoubleClick={() => {}}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    onSort={datakey => handleRequestSort(datakey)}
                    onUpdateColumn={handleResizeColumn}
                    page={store.start/store.pageSize}
                    paginate
                    rowCount={store.data.length}
                    rowGetter={index =>  store.data[index] }
                    rowsPerPage={store.pageSize}
                    selected={store.selected}
                    sortBy={orderBy}
                    sortDirection={order}
                />
            </div>
        </FieldSet>
    )
};

export default ClientesVendedorList;