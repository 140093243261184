import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    openDialogClient,
    onUpdateColumn,
    setOrder,
    setSelectedRow
} from '../../../store/movilidad/reportevisitas/actions';

import { sort_by} from '../../../helpers/commons'
import ReporteVisitasPedidoList from './ReporteVisitasPedidoList';
import ReporteVisitasGrap from './ReporteVisitasGrap';

import { FieldSet, Table } from '@sigeco/tools';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
//import ReporteVisitasGrafo from './ReporteVisitasGrafo';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
     
        marginBottom: '0.75rem',
        '@media (min-width: 1401px)': {
            marginBottom: '1rem',
        }
    }, 
    tableWrap: {
        flex: '1 1 auto'
    }
}));

const ReporteVisitasList = ({ onMapOpen }) => {
    const classes = useStyles();
    const columns = useSelector(state => state.reportevisitas.table.columns);
    //const loading = useSelector(state => state.reportevisitas.loading); //loadingStore
    const order = useSelector(state => state.reportevisitas.table.order);
    const orderBy = useSelector(state => state.reportevisitas.table.orderBy);
    const store = useSelector(state => state.reportevisitas.store);
    const dispatch = useDispatch();

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        dispatch(setOrder(isAsc ? 'desc' : 'asc', property));
        let homes = store.data;
        homes.sort(sort_by(property, isAsc, homes[0][property]));
    };

    const onHandleClick = (item) => {
        dispatch(openDialogClient(item));
    }

    const onMapClick = (item) => {
        onMapOpen(item.dVendedor)
    }
    
    return ( 
        <>
        <div className={classes.root}>
        <FieldSet className={classes.root} iconCls="fas fa-th-list" title="Resultados"   >
            <div className={classes.tableWrap} style={{  height: store.data.length >= 1 && store.data?  (store.data.length > 6 ?store.data.length* 30 : ( store.data.length < 2 ?store.data.length* 60.5 : store.data.length+store.data.length* +45.5 )) : 0}}>
                <Table                      
                    columns={columns}
                    count={store.total}
                    //loading={loading}
                    onRowClick={items => dispatch(setSelectedRow(items))}
                    onRowDoubleClick={() => {}}
                    onSort={datakey => handleRequestSort(datakey)}
                    onUpdateColumn={() => dispatch(onUpdateColumn())}
                    rowCount={store.data.length}
                    rowGetter={index => store.data[index]}
                    selected={store.selected}
                    sortBy={orderBy}
                    sortDirection={order}
                    actions={[
                        {
                            iconCls: 'far fa-eye',
                            tooltip: 'Ver detalle',
                            onClick: onHandleClick
                        },
                        {
                            iconCls: 'fas fa-map',
                            tooltip: 'Ver mapa',
                            onClick: onMapClick
                        }
                    ]}
                />
            </div>
        </FieldSet>
        </div>

        <div className={classes.root}>
        <FieldSet iconCls="fas fa-chart-line" title="Gráfico de Pedidos"  expandable>
        <Grid  container spacing={2}>
            <Grid container item xs={12} spacing={1}>  
                        
            <Grid item>
            
            </Grid>
            <Grid item style={{display: 'block'}}> 
                
            </Grid>
                
                <Grid item>
                </Grid>
                {store.data.length > 0  && <ReporteVisitasPedidoList store={store.data}/>  }                  
            </Grid>
        
        </Grid>

        </FieldSet>

        <FieldSet iconCls="fas fa-chart-pie" title="Gráfico de Visitas"  expandable>
        <Grid  container spacing={2}>
            <Grid container item xs={12} spacing={1}>  
                        
            <Grid item>
            
            </Grid>
            <Grid item style={{display: 'block'}}> 
                
            </Grid>
                
                <Grid item>
                
                </Grid>
                {store.data.length > 0  &&  <ReporteVisitasGrap store={store.data}/>  }
            
            </Grid>
        
        </Grid>
        </FieldSet>
       </div>
        </>
    )
};

export default ReporteVisitasList;