import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import { 
    btnMarca
  } from "../../../store/channelmap/asignarmarcadistribuidor/actions";
import { FieldSet } from '@sigeco/tools';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
    columnImage: {
        flex: 1,
        fontSize: '.8125rem',
    },
    columnIndex: {
        fontSize: '.8125rem',
        width: '3.5rem'
    },
    columnText: {
        fontSize: '.8125rem',
        width: '12rem'
    },
    empty: {
        marginTop: '1rem',
        textAlign: 'center'
    },
    image: {
        backgroundColor: '#fff',
        border: 'solid 1px #d3d3d3',
        borderRadius: '50%',
        objectFit: 'contain',
        height: '3.25rem',
        width: '3.25rem'
    },
    root: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        overflowY: 'hidden'
    },
    row: {
        alignItems: 'center',
        borderBottom: 'solid 1px #d3d3d3',
        display: 'flex',
        padding: '.5rem 2rem',
        '&:hover' : {
            backgroundColor : '#f3f3f3'
        },
        '&:first-child': {
            borderTop: 'solid 1px #d3d3d3'
        },
        '&:focus': {
            border: 'solid 1px #006bb6',
            outline: 'none'
        }
    },
    tableWrap: {
        flex: '1 1 auto',
        overflowY: 'auto',
        padding: '1rem 0'
    },
    table1: {
        marginLeft:'150px !important'
    }
}));

const AsignarMarcaDistribuidorTable = () => {
    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const store = useSelector(state => state.asignarmarcadistribuidor.store);
    const arreglo = useSelector(state => state.asignarmarcadistribuidor.arreglo);
    const position = useSelector(state => state.asignarmarcadistribuidor.aux);
    const [mostrarFormulario, setMostrarFormulario] = useState(false);
    const [marcas, setMarcas] = useState([]);
    

    //console.log("SSSS", store.aMarcas)

    const [marcaAux, setmarcaAux] = useState({
        marca: '',
        imagen: '',
        orden:'',
        index: 0,
    }); 

    useEffect(() => {
       
        if(store.aMarcas != undefined){
        const ax = [...store.aMarcas];
  
        if (marcas.length === 0) {
          const updatedMarcas = ax.map(item => ({
            marca: item.marca,
            marcaId: item.marcaId,
            imagen: item.imagen,
            orden:'',
          }));
          setMarcas(updatedMarcas);
          position.agregar = true;
        } else {
          const updatedMarcas = marcas.concat(ax.map(item => ({
            marca: item.marca,
            imagen: item.imagen,
            orden: '',
          })));
          setMarcas(updatedMarcas);
          position.agregar = true;
        }
  
  
        }
  
        //console.log("cambio: ", componente.agregar)
  
        //console.log("sistemaid: ",objetos.sistemaId)
    }, [store.aMarcas]);

       //console.log("TTTT", marcas)


    useEffect(() => {
        //console.log("EEEE", entity.dMarca2, entity.marca2Id);
      
        
        const updatedMarcas = marcas.map((item, i) => {
            if (i === marcaAux.index) {
              return {
                ...item,
                orden: marcaAux.orden,
              };
            }
            return item;
        });
      
        setMarcas(updatedMarcas);
      
        //console.log("Objeto: ", objetos);
        //console.log("SI ENVIO: ", componeteAux);
        
        
        //console.log("DATOS LOCALES: ",marcas);
        
        
        arreglo.dataComp = [...marcas];
    
      
        //console.log("DATOS ENVIANDO: ",arreglo.dataComp);
    
    
    }, [marcaAux]);



    const handleCellChange = (e, index, field) => {
        const updatedMarcas = marcas.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              [field]: e.target.value
            };
          }
          return item;
        });
        setMarcas(updatedMarcas);
        //console.log(updatedObjetos);
        position.index = index
        //console.log("Indice que envio: ",componente.index)
        //console.log("Indice local: ", index)
    };


    const handleMostrarFormulario = () => {
        if(store.pantalla === true){
          setMostrarFormulario(true);
        }else{
          setMostrarFormulario(false);
        } 
    };

    const onDelete = (index) => {
     setMarcas(prevObjetos => prevObjetos.filter((_, i) => i !== index));
    };
    arreglo.dataMarca = [...marcas];
    //console.log("DATOS ENVIANDO: ",arreglo.dataMarca);

    return (


        <>

            <Button
                color="primary" 
                size="small" 
                variant="contained"
                onClick={() => {
                dispatch(btnMarca());
                handleMostrarFormulario();
                }}
                style = {{width: "170px",
                            marginLeft: "12px",
                            marginTop: "-45px",
                            height: "25px"}}
                        >
                Agregar Marca(s)
            </Button>
            {mostrarFormulario && (
        
           <div>
            <br></br>
            <br></br>
            <FieldSet className={classes.paper} iconCls="fas fa-address-card" title="Asignación de Marca(s)" >
            <table className={classes.table1}  >
            <thead> 
            <tr>     
                <th style={{textAlign : 'left', width: '2%'}}>  </th>
                <th style={{width: '25%', height:'100%', textAlign : 'center'}}> Marca </th>
                <th style={{width: '20%', height:'100%', textAlign : 'center'}}> Imagen </th> 
                <th style={{width: '15%', height:'100%', textAlign : 'center'}}> Orden </th>
                <th style={{textAlign : 'center', width: '10%'  }}></th>
            </tr>
            </thead>

            <tbody>
                {
                        marcas.map((item, i) => (
                            <tr key={i}>
                                <td style={{ width: '2%', height: '100%' }}>{i + 1}</td>
                                <td style={{ width: '20%', height: '100%', textAlign: 'center' }}> 
                                 {item.marca}
                                </td>
                                <td style={{ width: '20%', height: '100%', textAlign: 'center' }}>
                                <img className={classes.image} alt={item.imagen} src={item.imagen || './images/imagendefecto.png'} />
                                </td>
                                <td style={{ width: '10%', height: '100%', textAlign: 'center' }}> 
                                 <TextField name="orden" type="number" value={item.orden} onChange={(e) => handleCellChange(e, i, "orden")} /> 
                                </td>
                                
                                <td colSpan="2" style={{width: '10px', height:'100%',    textAlign: 'center' }}>
                                {
                                    
                                            <button
                                              style={{ width: 30, backgroundColor: 'white', borderColor: 'darkgray' }}
                                              className={'btn-success'}
                                              icon={'fa-solid fa-arrow-right-to-bracket'}
                                              onClick={() => onDelete(i)}
                                            >
                                              <i className="fas fa-times"></i>
                                            </button>

                                }
                                </td>
                            </tr>
                        ))
                    }
                </tbody>


            </table>
            </FieldSet>
            </div>
             )
        }
        
        </>

    );
};

export default AsignarMarcaDistribuidorTable;