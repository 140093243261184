import {
    ERROR_EFE_LIST,
    RECEIVE_EFE_LIST,
    REQUEST_OLAS_LIST,
    RESTART_STATE,
    SET_MASK,
    SET_ORDER,
    SET_SELECTED_ROW,
    UPDATE_FIELD_FILTER,
    //UPDATE_TABLE_COLUMN
} from './types';

const initialState = {
    loadingStore: false,
    filters: {
         dVendedor: '',
         olaId: 0,
         distribuidorId: 0,
         dOla: '',
        dDistribuidor: '',
        desde: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        hasta: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
    },
    store: {
        data: [],
        selected: [],
        loading : false,

    },
    table: {
        order: 'asc',
        orderBy: '',
        columns: []
        
    }
};

export const ventaperdidaReducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case ERROR_EFE_LIST:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: false
                }
            };
        case RECEIVE_EFE_LIST:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: false,
                    data: action.payload,
                    selected: []
                },
                table: {
                    ...state.table,
                    columns: action.columns,
                }
            };
        case REQUEST_OLAS_LIST:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: true
                }
            };
        case RESTART_STATE:
            return {
                ...initialState
            };
        case SET_MASK:
            return {
                ...state,
                store: {
                ...state.store,
                loading: action.loading
                }
        };
        case SET_ORDER:
            return {
                ...state,
                table: {
                    ...state.table,
                    order: action.order,
                    orderBy: action.orderBy
                }
            };
        case SET_SELECTED_ROW:
            return {
                ...state,
                store: {
                    ...state.store,
                    selected: action.selected
                }
            };
        case UPDATE_FIELD_FILTER:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.key]: action.value
                }
            };
      
        default:
            return state;
    }
}