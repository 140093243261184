import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Icon from '@material-ui/core/Icon';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    actions: {
        backgroundColor: "#ededed", 
        justifyContent: 'center',
        padding: '0.3rem',
        '@media (min-width: 701px)': {
          padding: '0.375rem'
        }
      },
      button: {
        fontSize: '0.75rem',
        minWidth: '3.6rem', 
        padding: '0.1rem 0.5rem', 
        textTransform: 'none',
        '@media (min-width: 701px)': {
          fontSize: '0.8125rem',
          minWidth: '4.5rem',
          padding: '0.125rem 0.625rem', 
        }
      },
      content: {
        display: 'flex',
        padding: '0.5rem',
        '@media (min-width: 701px)': {
          padding: '0.4rem'
        }
      },
      dialog: {
        borderColor: theme.palette.primary.main, 
        borderStyle: 'solid', 
        borderWidth: '0.1rem',
        '@media (min-width: 701px)': {
          borderWidth: '0.125rem'
        }
      },
      icon: {
        fontSize: '2rem', 
        width: 'auto',
        '@media (min-width: 701px)': {
          fontSize: '2.5rem'
        }
      },
      text: {
        alignSelf: 'center',
        fontSize: '0.625rem',
        marginLeft: '0.4rem',
        '@media (min-width: 701px)': {
          fontSize: '0.75rem',
          marginLeft: '0.5rem'
        }
      },
      title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        fontSize: '0.8125rem',
        padding: '0.4rem',
        '@media (min-width: 701px)': {
          fontSize: '0.875rem',
          padding: '0.5rem'
        }
      }
}));

const MessageBox = props => {
    const classes = useStyles();
    const userCallback = props.fnCallback || function() { return undefined; };

    function onHandleClick(text) {
        userCallback(text);
    }

    return(
        <Dialog
            maxWidth="xs"
            open={props.open}
            classes={{
              paper: classes.dialog
            }}
          >
            <DialogTitle disableTypography className={classes.title}>{props.title}</DialogTitle>
            <DialogContent className={classes.content}>
              {props.icon === 'error' && (<Icon className={clsx("fas fa-exclamation-circle", classes.icon)} style={{ color: 'red' }} />)}
              {props.icon === 'info' && (<Icon className={clsx("fas fa-info-circle", classes.icon)} style={{ color: '#7dccfc' }} />)}
              {props.icon === 'question' && (<Icon className={clsx("fas fa-question-circle", classes.icon)} style={{ color: 'gray' }} />)}
              {props.icon === 'warning' && (<Icon className={clsx("fas fa-exclamation-triangle", classes.icon)} style={{ color: 'yellow' }} />)}
              <span className={classes.text}>{props.message}</span>
            </DialogContent>
            <DialogActions className={classes.actions}>
              {props.button === 'ok' && (
                <Button className={classes.button} color="primary" size="small" variant="contained" onClick={() => onHandleClick('ok')}>
                  Aceptar
                </Button>)}
              {(props.button === 'yesno' || props.button === 'yesnocancel') && (
                <Button className={classes.button} color="primary" size="small" variant="contained" onClick={() => onHandleClick('yes')}>
                  Si
                </Button>)}
              {(props.button === 'yesno' || props.button === 'yesnocancel') && (
                <Button className={classes.button} color="primary" size="small" variant="contained" onClick={() => onHandleClick('no')}>
                  No
                </Button>)}
              {props.button === 'yesnocancel' && (
                <Button className={classes.button} color="primary" size="small" variant="contained" onClick={() => onHandleClick('cancel')}>
                  Cancelar
                </Button>)}
            </DialogActions>
          </Dialog>
    );
}

MessageBox.propTypes = {
  button: PropTypes.oneOf(['ok', 'yesno', 'yesnocancel']),
  icon: PropTypes.oneOf(['error', 'info', 'question', 'warning']),
  message: PropTypes.string,
  title: PropTypes.string
}

export default MessageBox;