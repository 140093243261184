import {
    CLEAN_FORM,
    SET_STATE_FORM,
    UPDATE_FIELD_FILTER,
    SET_MASK,
    SET_ORDER,
    RECEIVE_DATA,
    UPDATE_TABLE_COLUMN,
    ERROR_V_LIST,
    RECEIVE_V_LIST,
    REQUEST_V_LIST,
    RECEIVE_V_LIST2,

} from './types';

const initialState = {
    formState: 'search',
    loading: false,
    entity: {
        vendedorId: 0,
        nombre: ''
    },
    errors: {
        codigo: '',
        usuario: '',
        documentoIdentidad: ''
    },
    filters: {
        nombre: '',
        distribuidorId: 0,
        dDistribuidor: '',
        codigo: '',
        dLista: '',
        codigoLista: 0,
        band: false,
        valor: '',
    },
    store: {
        loading: false,
        pageSize: 50,
        start: 0,
        total: 0,
        data: [],
        selected: [],
        col : ['Nit_Ruc','CodigoCliente','Cliente','CodigoProducto','Producto','PrecioEspecial','PrecioMinimo','Descuento','Cantidad']
        //source: null
    },
    stored: {
        loading: false,
        pageSize: 50,
        start: 0,
        total: 0,
        data: [],
        selected: [],
        //source: null
    },
    table: {
        order: 'asc',
        orderBy: '',
        columns: [
            { dataKey: 'NIT', label: 'NIT/RUC', width: 90 },
            { dataKey: 'CodigoCliente', label: 'Código Cliente', width: 90 },
            { dataKey: 'NombreCliente', label: 'Cliente', width: 350 },
            { dataKey: 'CodigoProducto', label: 'Código Producto', width: 90 },
            { dataKey: 'NombreProducto', label: 'Producto', width: 310 },
            { dataKey: 'PrecioEspecial', label: 'Precio Especial', width: 90 },
            { dataKey: 'PrecioMinimo', label: 'Precio Mínimo', width: 90 },
            { dataKey: 'Descuento', label: 'Descuento', width: 90 },
            { dataKey: 'Cantidad', label: 'Cantidad', width: 90 },
            { dataKey: 'UsuarioRegistro', label: 'Usuario Registro', width: 90 },
            { dataKey: 'FechaRegistro', label: 'Fecha Registro', width: 120},
            
        ]
    },
    tablexport: {
        order: 'asc',
        orderBy: '',
        columns: [
            { dataKey: 'NIT', label: 'Nit_Ruc', width: 90 },
            { dataKey: 'CodigoCliente', label: 'CodigoCliente', width: 90 },
            { dataKey: 'NombreCliente', label: 'Cliente', width: 350 },
            { dataKey: 'CodigoProducto', label: 'CodigoProducto', width: 90 },
            { dataKey: 'NombreProducto', label: 'Producto', width: 310 },
            { dataKey: 'PrecioEspecial', label: 'PrecioEspecial', width: 90 },
            { dataKey: 'PrecioMinimo', label: 'PrecioMinimo', width: 90 },
            { dataKey: 'Descuento', label: 'Descuento', width: 90 },
            { dataKey: 'Cantidad', label: 'Cantidad', width: 90 },
            
        ]
    }
};

export const gestionpreciosespecialesReducer = (state, action) => {
    state = state || initialState;

    switch(action.type) {
        case CLEAN_FORM:
            return {
                ...state,
                entity: initialState.entity,
                errors: initialState.errors
            };
        case SET_STATE_FORM:
            return {
                ...state,
                formState: action.formState
            };
        case UPDATE_FIELD_FILTER:
            return { 
                ...state,
                filters: {
                    ...state.filters,
                    [action.key]: action.value
                }
            };
            case ERROR_V_LIST:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: false
                }
            };
            case RECEIVE_V_LIST:
                return {
                    ...state,
                    store: {
                        ...state.store,
                        loading: false,
                        total: action.total,
                        data: action.payload,
                        source: null
                    }
                };
            
            case RECEIVE_V_LIST2:
                return {
                    ...state,
                    stored: {
                        ...state.store,
                        loading: false,
                        total: action.total,
                        data: action.payload,
                        source: null
                    }
                };    

            case REQUEST_V_LIST:
                return {
                    ...state,
                    store: {
                        ...state.store,
                        loading: false,
                        source: action.source
                    }
                };
            case RECEIVE_DATA:
                return {
                    ...state,
                    data: action.payload,
                    store: {
                        ...state.store,
                        data: action.storeData
                    }
                };
                case UPDATE_TABLE_COLUMN:
                    return {
                        ...state,
                        table: {
                            ...state.table,
                            columns: action.columns
                        }
                    };
            case SET_MASK:
                return {
                    ...state,
                    loading: action.loading
            };

            case SET_ORDER:
                return {
                    ...state,
                    table: {
                    ...state.table,
                    order: action.order,
                    orderBy: action.orderBy,
                    },
                };

        default:
            return state;
    }
};