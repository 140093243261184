import React , {  useState, useEffect} from 'react';
import {  useSelector } from 'react-redux';

const caja = {
  position: 'relative',
  display: 'inline-block'
}

const p = {
  position: 'absolute',
  top: '25%',
  left: '70%',
  color: 'white',
};

const q = {
  position: 'absolute',
  top: '65%',
  left: '80%',
  color: 'white',
};


const PdfCrecimiento2022 = () => {
  const mes =   useSelector(state => state.resumen.filters.desde.getFullYear());  
  const fecha =  useSelector(state => state.resumen.store.meses[state.resumen.filters.desde.getMonth()] +',  '+ mes); 

  const  ComponentToPrint = () => {
   
      return (
        <>

        <div style={caja}>  
          <img alt='logo'  src={ './images/mobil-gigantes2022.png'}  style={{width: '125%', height:'130px'}}/>
          <div style={p}><h2>Crecimiento</h2></div>   
          <div style={q}><h4>       {fecha}        </h4></div>  
         </div>
        
        <table>
          <thead>
            <tr>
              <th style={{width: '10%', height:'100%'}}></th>   
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">
              </th>
            </tr>
          </tbody>
        </table>
    
        <table>

          <tbody>
            <tr>
              <th scope="row" style={{width: '100px', height:'100%'}}></th>
              <td style={{ border: '1px solid', borderColor: 'white',  backcolor: '#00FF00', backgroundColor:  '#0f2462', color: 'white', width: '200px'}}>
              por cada cliente con RUC</td>
              <td style={{ border: '1px solid', borderColor: 'white',  backcolor: '#00FF00', backgroundColor:  '#0f2462', color: 'white', width: '50px'}}>
              Tipo</td>
              <td style={{ border: '1px solid', borderColor: 'white',  backcolor: '#00FF00', backgroundColor:  '#0f2462', color: 'white', width: '130px'}}>
             ASESOR</td>
              <td style={{ border: '1px solid', borderColor: 'white',  backcolor: '#00FF00', backgroundColor:  '#0f2462', color: 'white', width: '100px'}}>
              CUMPLIMIENTO</td>
              <td style={{ border: '1px solid', fontsize: '25px', fontFamily: 'sans-serif-medium' , borderColor:  '#0f2462',   textAlign:  'center', color: '#191970', width: '80px'}}><h1>USD </h1></td>
            </tr>
         
            {renderBody(nuevos) } 
            <tr style={{height: '30px'}} ></tr>
      
            <tr>
              <th scope="row"></th>
              <td style={{ border: '1px solid',  borderColor: 'white',  backcolor: '#00FF00', backgroundColor:  '#0f2462', color: 'white'}}>
              por cada cliente B2B con RUC</td>
              <td style={{ border: '1px solid', borderColor: 'white',  backcolor: '#00FF00', backgroundColor:  '#0f2462', color: 'white', width: '50px'}}>
              Tipo</td>
              <td style={{ border: '1px solid', borderColor: 'white',  backcolor: '#00FF00', backgroundColor:  '#0f2462', color: 'white', width: '130px'}}>
             ASESOR</td>
              <td style={{ border: '1px solid', borderColor: 'white',  backcolor: '#00FF00', backgroundColor:  '#0f2462', color: 'white', width: '130px'}}>
              CUMPLIMIENTO</td>
                <td style={{ border: '1px solid', fontsize: '25px', fontFamily: 'sans-serif-medium' , borderColor:  '#0f2462',   textAlign:  'center', color: '#191970', width: '180px'}}><h1>USD </h1></td>
            </tr>
   
               {renderBody(cobertura2) }
                <tr style={{breakBefore: 'page !important', breakInside: 'avoid', breakAfter: 'page !important', position:'relative'}}></tr>
      
           
          </tbody>
        </table>

        </>
    );
    
  }

    const renderBody = (employees) => {
        return employees && employees.map(({   id, distribuidor, tipo, asesor, cumplimiento, pago }, i) => {
            return (
               
              <tr style={{ }} key={i}>  
                    <td></td>
                    <td style={{ width : '70px',  fontSize: '12px',  backgroundColor: i%2 === 0 ?  '#cad1d7': '#9daab6'  }}>{distribuidor}</td>
                    <td style={{ fontSize: '12px',  backgroundColor: i%2 === 0 ?  '#cad1d7': '#9daab6'  }}> {tipo}</td>
                     <td style={{ fontSize: '12px',  backgroundColor: i%2 === 0 ?  '#cad1d7': '#9daab6'  }}> -{asesor}</td>
                    <td style={{ textAlign : 'right',   fontSize: '12px',  backgroundColor: i%2 === 0 ?  '#cad1d7': '#9daab6' }}>{cumplimiento | 0}</td>
                    <td style={{ fontSize: '12px', textAlign : 'center',  backgroundColor: i%2 === 0 ?  '#cad1d7': '#9daab6'  }}> {pago}</td>
                </tr> 
            ) 
        })
    }

    const store = useSelector(state => state.resumen.store);

    const [nuevos, setNuevos] = useState([]);
    const [cobertura2, setCobertura2] = useState([]);

    useEffect(() => {
        setNuevos(store.clientesnuevos)
        setCobertura2(store.clinuevseisocho)
   
        //if (store.presupuesto.length > 0 ) {setData(store.presupuesto) }        
    }, [cobertura2, store.clientesnuevos, store.clinuevseisocho] )

    return (
      <>
      <div className="contacts">
         <style>
           {`@media print {.contacts{ page-break-before: always;}}`
           }
         </style>
         <ComponentToPrint />
       </div>
       </>
    )
  
  };
  
  export default PdfCrecimiento2022;