import axios from "axios";
import format from "date-fns/format";
import { IdentityUrl } from "../../../config";
import {
  closeAdvancedSearch,
  closeMessageBox,
  openAdvancedSearch,
  openMessageBox,
} from "../../main/actions";
import {
  ERROR_AVANCE_LIST,
  RECEIVE_AVANCE_LIST,
  RESTART_STATE,
  SET_MASK,
  SET_ORDER,
  SET_SELECTED_ROW,
  UPDATE_FIELD_FILTER
} from "./types";

import { exportToSpreadsheet, cortarArreglo } from "../../../helpers/commons";


export const onExport = () => async (dispatch, getState) => {

  if (getState().avanceventagalones.filters.distribuidorId === 0) {
   
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }

  if (getState().avanceventagalones.store.data.length === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "No hay información para exportar.",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }

  try {
    dispatch({ type: SET_MASK, loading: true });
    let head = getState().avanceventagalones.table.columns;
    let json = getState().avanceventagalones.store.data;


    exportToSpreadsheet(cortarArreglo(head,json), "AvanceventasGalones_"+
    getState().avanceventagalones.filters.dDistribuidor);
  } catch (error) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message:
          (error.response.data.eventLogId === 0
            ? ""
            : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    setTimeout(()=>{dispatch({ type: SET_MASK, loading: false })}, 1000)
  }

};

export const btnSearch = () => async (dispatch, getState) => {
  if (getState().avanceventagalones.store.loading) {
    return;
  }

  const mes = getState().avanceventagalones.filters.desde.getMonth()+1;
    const anio = getState().avanceventagalones.filters.desde.getFullYear();

  if (getState().avanceventagalones.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }
  dispatch({ type: SET_MASK, loading: true });

  try {
    let url =`${ getState().main.resources.uriSvcSalesMobilityAPI}api/v1/reportes/channelmap/avanceventagalones?` +
    `distribuidorId=${getState().avanceventagalones.filters.distribuidorId}` +  
    `&supervisorId=${getState().avanceventagalones.filters.supervisorId}` +
      `&vendedorId=${getState().avanceventagalones.filters.vendedorId}` +
       `&Mes=${mes}`+
       `&Anio=${anio}`;

    let response = await axios.get(encodeURI(url));
    //console.log(encodeURI(url));
    let data = response.data;

    if (data.length === 0) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "No existe Registro  para esta consulta",
          callback: () => dispatch(closeMessageBox()),
        })
      );
      return;
    }

    //let columnas = getState().avanceventagalones ;//.table.columns;

  dispatch({type: RECEIVE_AVANCE_LIST,  payload: data });
  
  } catch (error) {
    let msgError = "";
    dispatch({ type: SET_MASK, loading: false });
    dispatch({ type: ERROR_AVANCE_LIST });

    if (error.response) {
      if (error.response.status === 400) {
        msgError =
          error.response.data.eventLogId === 0
            ? "No existe Registro  para esta \nfecha  "
            : `EventoId: ${error.response.data.eventLogId}. ` +
              error.response.data.message;
      } else {
        msgError =
          (error.response.data.eventLogId === 0
            ? ""
            : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message;
      }
    } else {
      msgError = "Error al conectar con el servidor";
    }

    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message: msgError,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    dispatch({ type: SET_MASK, loading: false });
  }
};

export const onUpdateColumn = (column) => (dispatch, getState) => {
  getState().avanceventagalones.table.columns.map((item , i)=> {     
     if(item.dataKey !== column.dataKey) {
         return item;
     }else{
             item.width =  column.width;
     }
     return {
        // ...item,
       //  width: column.width
     };
    
 });
};

export const onSearchDistribuidorClick = () => async (dispatch) => {
  let fields = [
    { dataKey: "razonSocial", label: "Agente", type: "string", width: 225 },
  ];

  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de Agente",
      getData: async (params) => {
        let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`;
        let response = await axios.get(encodeURI(url), { params: params });

        return response.data;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(onUpdateFieldFilter("distribuidorId", result[0].distribuidorId));
          dispatch(onUpdateFieldFilter("dDistribuidor", `${result[0].razonSocial}`));
          dispatch(onUpdateFieldFilter("supervisorId", ""));
          dispatch(onUpdateFieldFilter("dSupervisor", ""));
          dispatch(onUpdateFieldFilter("vendedorId", ""));
          dispatch(onUpdateFieldFilter("dVendedor", ""));
        }
      },
    })
  );
};

export const onSearchSupervisorClick = () => async (dispatch, getState) => {
  let fields = [{dataKey: "nombre", label: "Nombre", type: "string", search: true, width: 225 },
    { dataKey: "nombreUsuario", label: "Usuario", type: "string", width: 115 }];

  if (getState().avanceventagalones.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }

  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de Supervisor",
      getData: async (params) => {
        let url = `${IdentityUrl}/api/v1/usuarios?rol=Supervisor`;
        let response = await axios.get(encodeURI(url), { params: params });
        let distribuidorId = getState().avanceventagalones.filters.distribuidorId;

        if (distribuidorId > 0) {
          response.data.data = response.data.data.filter(
            (f) => f.distribuidorId === distribuidorId
          );
        }

        return response.data;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(onUpdateFieldFilter("supervisorId", result[0].usuarioId));
          dispatch(onUpdateFieldFilter("dSupervisor", `${result[0].nombre}`));
          dispatch(onUpdateFieldFilter("vendedorId", ""));
          dispatch(onUpdateFieldFilter("dVendedor", ""));
        }
      },
    })
  );
};

export const onSearchUbigeoClick = () => async (dispatch, getState) => {
  let fields = [
    { dataKey: "codigo", label: "Código", type: "string", width: 115 },
    {
      dataKey: "dUbigeo",
      label: "Ubigeo",
      type: "string",
      search: true,
      width: 225,
    },
  ];

  dispatch(
    openAdvancedSearch({
      autoLoad: false,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de ubigeo",
      getData: async (params) => {
        let url = `${
          getState().main.resources.uriSvcSalesMobilityAPI
        }api/v1/agentes/ubigeos`;
        let response = await axios.get(encodeURI(url), { params: params });
        return response.data;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          //console.log(result[0])
          dispatch(onUpdateFieldFilter("ubigeoId", result[0].ubigeoId));
          dispatch(onUpdateFieldFilter("dUbigeo", `${result[0].dUbigeo}`));
        }
      },
    })
  );
};

export const onSearchVendedorClick = () => async (dispatch, getState) => {
  let fields = [
    {
      dataKey: "nombre",
      label: "Nombre",
      type: "string",
      search: true,
      width: 225,
    },
    { dataKey: "nombreUsuario", label: "Usuario", type: "string", width: 115 },
  ];

  if (getState().avanceventagalones.filters.supervisorId === "") {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Supervisor..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }

  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de vendedor",
      getData: async (params) => {
        let url = `${IdentityUrl}/api/v1/usuarios/porjerarquia?usuarioId=${
          getState().avanceventagalones.filters.supervisorId
        }`;
        let response = await axios.get(encodeURI(url), { params: params });
        let distribuidorId = getState().avanceventagalones.filters.distribuidorId;

        if (distribuidorId > 0) {
          response.data.data = response.data.data.filter(
            (f) => f.distribuidorId === distribuidorId
          );
        }
        response.data.data.push({
          activo: true,
          usuarioId: "",
          ruc: "",
          nombre: "TODOS",
          nombreusuario: "",
        });
        response.data.data.sort((a, b) => (a.usuarioId > b.usuarioId ? 1 : -1));
        return response.data;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(onUpdateFieldFilter("vendedorId", result[0].usuarioId));
          dispatch(onUpdateFieldFilter("dVendedor", `${result[0].nombre}`));
          dispatch({
            type: RECEIVE_AVANCE_LIST,
            columns: getState().avanceventagalones.table.columns,
            payload: [],
          });
          if (result[0].usuarioId === "") {
            dispatch(onUpdateFieldFilter("sw", true));
            dispatch(onUpdateFieldFilter("track", "U"));
          } else {
            dispatch(onUpdateFieldFilter("sw", false));
          }
        }
      },
    })
  );
};



export const onUpdateFieldFilter = (key, value) => (dispatch, getState) => {
  //({ type: UPDATE_FIELD_FILTER, key, value });
  dispatch({ type: UPDATE_FIELD_FILTER, key, value });
  dispatch({ type: RECEIVE_AVANCE_LIST, columns: [], payload: [] });
};

export const onUpdateBlank = (key, value) => (dispatch, getState) => {
  dispatch({ type: RECEIVE_AVANCE_LIST, columns: [], payload: [] });
};

export const UpdateField = (key, value) => (dispatch, getState) => {
  dispatch({ type: UPDATE_FIELD_FILTER, key, value });
  //dispatch({ type: RECEIVE_AVANCE_LIST, columns:[], payload: [] })
};

export const restartState = () => ({ type: RESTART_STATE });

export const setOrder = (order, orderBy) => ({
  type: SET_ORDER,
  order,
  orderBy,
});

export const setSelectedRow = (selected) => ({
  type: SET_SELECTED_ROW,
  selected,
});
