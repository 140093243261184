import React, { useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    btnSearch,
    onExport,
    onImport
} from '../../store/fuerzaventas/gestionprecios/actions';

import { Body, Footer, Page } from '@sigeco/tools';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';




import GestionPreciosFilter from '../../components/fuerzaventas/gestionprecios/GestionPreciosFilter';
import GestionPreciosPanel from '../../components/fuerzaventas/gestionprecios/GestionPreciosPanel';

const GestionPrecios = ({onClose, setTitle}) => {
    const loading = useSelector(state => state.gestionprecios.loading);
    const store = useSelector(state => state.gestionprecios.filters.band);

    const dispatch = useDispatch();
    
    useEffect(() => {
        setTitle('Búsqueda');
    // eslint-disable-next-line
    }, []);

   
    function onBtnClose() {
        onClose();
    }

    return (
        <Page>
            <Backdrop style={{ position: 'absolute', zIndex: 1200 }} open={loading} transitionDuration={0}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Body>
                <GestionPreciosFilter />
                {/* <GestionPreciosList /> */}
                <GestionPreciosPanel/>
            </Body>
            <Footer>
                { store === true && <Button
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={() => dispatch(onExport())}
                    startIcon={<Icon className="fas fa-file-excel"/>}
                  >
                    Exportar
                </Button>
                }
                { store === true &&
                <Button
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={() => dispatch(btnSearch())}
                    startIcon={<Icon className="fas fa-search"/>}
                >
                    Buscar
                </Button>}
                { store === false &&
                <Button
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={() => dispatch(onImport())}
                    startIcon={<Icon className="fas fa-file-excel"/>}
                  >
                    Importar Precios
                </Button>}
                <Button 
                    color="primary" 
                    size="small" 
                    variant="outlined" 
                    onClick={() => onBtnClose()} 
                    startIcon={<Icon className="fas fa-door-open"/>}
                >
                    Salir
                </Button>
            </Footer>
        </Page>
    )
};

export default GestionPrecios;