import AvanceIdentificacionMercado from '../pages/marketing/AvanceIdentificacionMercado';
import AprobarPedido from '../pages/movilidad/AprobarPedido';
import BaseClientes from '../pages/clientes/BaseClientes';
import BaseDatos from '../pages/channelmap/BaseDatos';
import CargaMasiva from '../pages/channelmap/CargaMasiva';
import Clientes from '../pages/clientes/Clientes';
import Desactivacion from '../pages/channelmap/Desactivacion';
import Marca from '../pages/channelmap/Marca';
import Metas from '../pages/fuerzaventas/Metas';
import Olas from '../pages/channelmap/Olas';
import Prospecto from '../pages/clientes/Prospecto';
import ReporteCobertura from '../pages/channelmap/ReporteCobertura';
import ReporteConstruccion from '../pages/channelmap/ReporteConstruccion';
import ReporteParticipacion from '../pages/channelmap/ReporteParticipacion';
import ReportePedidos from '../pages/movilidad/ReportePedidos';
import Tracking from '../pages/movilidad/Tracking';
import ReporteSeguimiento from '../pages/channelmap/ReporteSeguimiento';
import ReporteVisitas from '../pages/movilidad/ReporteVisitas';
import Rutas from '../pages/fuerzaventas/Rutas';
import User from '../pages/User';
import Vendedor from '../pages/fuerzaventas/Vendedor';
import Productos from '../pages/precios/Productos'; 
import BaseD from '../pages/precios/BaseD'; 
import ReportEfectividad from '../pages/channelmap/ReportEfectividad';
import ListadoGlobal from '../pages/movilidad/ListadoGlobal';
import Resumen from '../pages/gigantes/Resumen';
import BaseDatosMarketing from '../pages/marketing/BaseDatosMarketing';
import IdentificacionMercado from '../pages/marketing/ReporteIdentificacionMercado';
import ReporteSeguimientoVendedor from '../pages/channelmap/ReporteSeguimientoVendedor';
import ListarProspectos from  '../pages/clientes/ListarProspectos';
import CarteraVendedor from '../pages/movilidad/CarteraVendedor';
import ListarProductos from '../pages/fuerzaventas/ListarProductos';
import CostosProductos from '../pages/fuerzaventas/CostosProductos';
import VentaPerdida from '../pages/movilidad/VentaPerdida';
import PrecioStock from '../pages/fuerzaventas/PrecioStock';
import ReporteIdentificacionMercado from '../pages/marketing/ReporteIdentificacionMercado';
import ReportePromociones from '../pages/marketing/ReportePromociones';
import ReporteSeguimientoPrecios from '../pages/precios/ReporteSeguimientoPrecios'
import CargaMasivaClientes    from  '../pages/clientes/CargaMasivaClientes';
import ReporteCobranza from '../pages/movilidad/ReporteCobranza';
import ClientesSinRutas from '../pages/clientes/ClientesSinRutas';
import ClientesVendedor from '../pages/clientes/ClientesVendedor';
import CargaMasivaRutas from '../pages/fuerzaventas/CargaMasivaRutas';
import GestionRoles from '../pages/fuerzaventas/GestionRoles';//ojo con este
import BandejAprobacion from '../pages/movilidad/BandejAprobacion';
import ReporteFrecuenciaVisita from '../pages/movilidad/ReporteFrecuenciaVisita'; 
import PlanificarVisita from  '../pages/movilidad/PlanificarVisita';
import ReporteDeIncidencias from '../pages/movilidad/ReporteDeIncidencias';
import GestionPrecios from '../pages/fuerzaventas/GestionPrecios';
import GestionPreciosEspeciales from '../pages/fuerzaventas/GestionPreciosEspeciales';
import GestionBonificacion from "../pages/fuerzaventas/GestionBonificacion";
import GestionListadePrecios from '../pages/fuerzaventas/GestionListadePrecios';
import ReporteIndicadores from '../pages/precios/ReporteIndicadores';
import AsignarMarcaDistribuidor from '../pages/channelmap/AsignarMarcaDistribuidor';
import ReporteAvanceVentasGalones from '../pages/channelmap/ReporteAvanceVentasGalones';

import CargarMiEspacio from '../pages/fuerzaventas/CargarMiEspacio';
import Precios from '../pages/precios/Precios';

import DetallesPedido from '../pages/movilidad/DetallesPedido';


const routes = {
    BaseClientes: {
        component: BaseClientes,
        viewType: "/clientes/base",
        paths: ["/clientes/base"],
    },
    BaseDatos: {
        component: BaseDatos,
        viewType: "/channelmap/basedatos",
        paths: ["/channelmap/basedatos"],
    },
    CargaMasiva: {
        component: CargaMasiva,
        viewType: "/channelmap/cargamasiva",
        paths: ["/channelmap/cargamasiva"],
    },
    Clientes: {
        component: Clientes,
        viewType: "/clientes/clientes",
        paths: [
            "/clientes/clientes",
            "/clientes/clientes/crear",
            "/clientes/clientes/:id",
            "/clientes/clientes/:id/editar",
        ],
    },
    Desactivacion: {
        component: Desactivacion,
        viewType: "/channelmap/desactivaciones",
        paths: [
            "/channelmap/desactivaciones",
            "/channelmap/desactivaciones/:id",
        ],
    },
    Marca: {
        component: Marca,
        viewType: "/channelmap/marca",
        paths: [
            "/channelmap/marca",
            "/channelmap/marca/crear",
            "/channelmap/marca/:id",
            "/channelmap/marca/:id/editar",
        ],
    },
    Metas: {
        component: Metas,
        viewType: "/fuerzaventas/metas",
        paths: ["/fuerzaventas/metas"],
    },
    Olas: {
        component: Olas,
        viewType: "/channelmap/olas",
        paths: ["/channelmap/olas"],
    },
    Prospecto: {
        component: Prospecto,
        viewType: "/clientes/prospectos",
        paths: [
            "/clientes/prospectos",
            "/clientes/prospectos/:id",
            //'/channelmap/desactivaciones',
            //'/channelmap/desactivaciones/:id'
        ],
    },
    ReporteCobertura: {
        component: ReporteCobertura,
        viewType: "/channelmap/reportecobertura",
        paths: ["/channelmap/reportecobertura"],
    },
    ReporteConstruccion: {
        component: ReporteConstruccion,
        viewType: "/channelmap/reporteconstruccion",
        paths: ["/channelmap/reporteconstruccion"],
    },
    ReporteParticipacion: {
        component: ReporteParticipacion,
        viewType: "/channelmap/reporteparticipacion",
        paths: ["/channelmap/reporteparticipacion"],
    },
    ReporteSeguimientoVendedor: {
        component: ReporteSeguimientoVendedor,
        viewType: "/channelmap/reporteseguimientovendedor",
        paths: ["/channelmap/reporteseguimientovendedor"],
    },
    ReportePedidos: {
        component: ReportePedidos,
        viewType: "/movilidad/reportepedidos",
        paths: ["/movilidad/reportepedidos"],
    },
    Tracking: {
        component: Tracking,
        viewType: "/movilidad/tracking",
        paths: ["/movilidad/tracking"],
    },
    AprobarPedido: {
        component: AprobarPedido,
        viewType: "/movilidad/aprobarpedido",
        paths: ["/movilidad/aprobarpedido"],
    },
    ListadoGlobal: {
        component: ListadoGlobal,
        viewType: "/movilidad/listadoglobal",
        paths: ["/movilidad/listadoglobal"],
    },
    CarteraVendedor: {
        component: CarteraVendedor,
        viewType: "/movilidad/carteravendedor",
        paths: ["/movilidad/carteravendedor"],
    },
    VentaPerdida: {
        component: VentaPerdida,
        viewType: "/movilidad/ventaperdida",
        paths: ["/movilidad/ventaperdida"],
    },
    ReporteVisitas: {
        component: ReporteVisitas,
        viewType: "/movilidad/reportevisitas",
        paths: ["/movilidad/reportevisitas"],
    },
    BandejAprobacion: {
        component: BandejAprobacion,
        viewType: "/movilidad/bandejaprobacion",
        paths: ["/movilidad/bandejaprobacion"],
    },
    ReporteFrecuenciaVisita: {
        component: ReporteFrecuenciaVisita,
        viewType: "/movilidad/reportefrecuenciavisita",
        paths: ["/movilidad/reportefrecuenciavisita"],
    },
    ReporteSeguimiento: {
        component: ReporteSeguimiento,
        viewType: "/channelmap/reporteseguimiento",
        paths: ["/channelmap/reporteseguimiento"],
    },

    PlanificarVisita: {
        component: PlanificarVisita,
        viewType: "/movilidad/planificarvisita",
        paths: [
            "/movilidad/planificarvisita",
            "/movilidad/planificarvisita/crear",
            "/movilidad/planificarvisita/:id",
            "/movilidad/planificarvisita/:id/editar",
        ],
    },

    Rutas: {
        component: Rutas,
        viewType: "/fuerzaventas/rutas",
        paths: [
            "/fuerzaventas/rutas",
            "/fuerzaventas/rutas/crear",
            "/fuerzaventas/rutas/:id",
            "/fuerzaventas/rutas/:id/editar",
        ],
    },
    User: {
        component: User,
        viewType: "/usuarios",
        paths: [
            "/usuarios",
            "/usuarios/crear",
            "/usuarios/:id",
            "/usuarios/:id/editar",
            "/usuarios/:id/resetear",
        ],
    },
    Vendedor: {
        component: Vendedor,
        viewType: "/fuerzaventas/vendedor",
        paths: [
            "/fuerzaventas/vendedor",
            "/fuerzaventas/vendedor/crear",
            "/fuerzaventas/vendedor/:id",
            "/fuerzaventas/vendedor/:id/editar",
        ],
    },
    Productos: {
        component: Productos,
        viewType: "/precios/productos",
        paths: [
            "/precios/productos",
            "/precios/productos/crear",
            "/precios/productos/:id",
            "/precios/productos/:id/editar",
        ],
    },
    BaseD: {
        component: BaseD,
        viewType: "/precios/BaseD",
        paths: ["/precios/BaseD"],
    },
    ReportEfectividad: {
        component: ReportEfectividad,
        viewType: "/channelmap/reportefectividad",
        paths: ["/channelmap/reportefectividad"],
    },

    Resumen: {
        component: Resumen,
        viewType: "/gigantes/Resumen",
        paths: ["/gigantes/Resumen"],
    },

    BaseDatosMarketing: {
        component: BaseDatosMarketing,
        viewType: "/marketing/basedatosmarketing",
        paths: ["/marketing/basedatosmarketing"],
    },

    IdentificacionMercado: {
        component: IdentificacionMercado,
        viewType: "/marketing/identificacionmercado",
        paths: ["/marketing/identificacionmercado"],
    },
    AvanceIdentificacionMercado: {
        component: AvanceIdentificacionMercado,
        viewType: "/marketing/avanceidentificacionmercado",
        paths: ["/marketing/avanceidentificacionmercado"],
    },

    ListarProspectos: {
        component: ListarProspectos,
        viewType: "/clientes/listarprospectos",
        paths: ["/clientes/listarprospectos"],
    },
    ListarProductos: {
        component: ListarProductos,
        viewType: "/fuerzaventas/listarproductos",
        paths: ["/fuerzaventas/listarproductos"],
    },
    PrecioStock: {
        component: PrecioStock,
        viewType: "/fuerzaventas/preciostock",
        paths: ["/fuerzaventas/preciostock"],
    },
    CostosProductos: {
        component: CostosProductos,
        viewType: "/fuerzaventas/costosproductos",
        paths: ["/fuerzaventas/costosproductos"],
    },

    ReporteIdentificacionMercado: {
        component: ReporteIdentificacionMercado,
        viewType: "/marketing/reporteidentificacionmercado",
        paths: ["/marketing/reporteidentificacionmercado"],
    },

    ReportePromociones: {
        component: ReportePromociones,
        viewType: "/marketing/reportepromociones",
        paths: ["/marketing/reportepromociones"],
    },

    ReporteSeguimientoPrecios: {
        component: ReporteSeguimientoPrecios,
        viewType: "/precios/reporteseguimientoprecios",
        paths: ["/precios/reporteseguimientoprecios"],
    },

    CargaMasivaClientes: {
        component: CargaMasivaClientes,
        viewType: "/clientes/cargamasivaclientes",
        paths: ["/clientes/cargamasivaclientes"],
    },

    ReporteCobranza: {
        component: ReporteCobranza,
        viewType: "/movilidad/reportecobranza",
        paths: ["/movilidad/reportecobranza"],
    },

    ClientesSinRutas: {
        component: ClientesSinRutas,
        viewType: "/clientes/clientessinrutas",
        paths: ["/clientes/clientessinrutas"],
    },
    ClientesVendedor: {
        component: ClientesVendedor,
        viewType: "/clientes/clientesvendedor",
        paths: ["/clientes/clientesvendedor"],
    },
    CargaMasivaRutas: {
        component: CargaMasivaRutas,
        viewType: "/fuerzaventas/cargamasivarutas",
        paths: ["/fuerzaventas/cargamasivarutas"],
    },

    GestionRoles: {
        component: GestionRoles,
        viewType: "/fuerzaventas/gestionroles",
        paths: ["/fuerzaventas/gestionroles"],
    },

    ReporteDeIncidencias: {
        component: ReporteDeIncidencias,
        viewType: "/movilidad/reportedeincidencias",
        paths: ["/movilidad/reportedeincidencias"],
    },

    GestionPrecios: {
        component: GestionPrecios,
        viewType: "/fuerzaventas/gestionprecios",
        paths: ["/fuerzaventas/gestionprecios"],
    },
    
    GestionPreciosEspeciales: {
        component: GestionPreciosEspeciales,
        viewType: "/fuerzaventas/gestionpreciosespeciales",
        paths: ["/fuerzaventas/gestionpreciosespeciales"],
    },

    
    GestionListadePrecios: {
        component: GestionListadePrecios,
        viewType: "/fuerzaventas/gestionlistadeprecios",
        paths: [
                "/fuerzaventas/gestionlistadeprecios",
                "/fuerzaventas/gestionlistadeprecios/:id",
                "/fuerzaventas/gestionlistadeprecios/:id/editar",
            ],
    },


    ReporteIndicadores: {
        component: ReporteIndicadores,
        viewType: '/precios/reporteindicadores',
        paths:[
            '/precios/reporteindicadores',
        ]
    },

    GestionBonificacion: {
        component: GestionBonificacion,
        viewType: "/fuerzaventas/gestionbonificacion",
        paths: [
            "/fuerzaventas/gestionbonificacion",
            "/fuerzaventas/gestionbonificacion/:id",
            "/fuerzaventas/gestionbonificacion/:id/editar",
        ],
    },

    AsignarMarcaDistribuidor: {
        component: AsignarMarcaDistribuidor,
        viewType: "/channelmap/asignarmarcadistribuidor",
        paths: [
            "/channelmap/asignarmarcadistribuidor",
            "/channelmap/asignarmarcadistribuidor/crear",
            "/channelmap/asignarmarcadistribuidor/:id",
            "/channelmap/asignarmarcadistribuidor/:id/editar",
        ],
    },
    ReporteAvanceVentasGalones: {
        component: ReporteAvanceVentasGalones,
        viewType: "/channelmap/reporteavanceventasgalones",
        paths: ["/channelmap/reporteavanceventasgalones"],
    },

    CargarMiEspacio : {
        component: CargarMiEspacio,
        viewType: "/fuerzaventas/cargarmiespacio",
        paths: [
            "/fuerzaventas/cargarmiespacio",
        ],

    },

    Precios : {
        component: Precios,
        viewType: "/precios/precios",
        paths: [
            "/precios/precios",
            "/precios/precios/crear"
        ],

    },

    DetallesPedido : {
        component: DetallesPedido,
        viewType: "/movilidad/detallespedido",
        paths: [
            "/movilidad/detallespedido",
        ],

    }


};

export default routes;
