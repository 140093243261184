export const ERROR_OLAS_LIST = 'movilidad/aprobarpedido/error-olas-list';
export const RECEIVE_OLAS_LIST = 'movilidad/aprobarpedido/receive-olas-list';
export const  RECEIVE_OLAS_DET = 'movilidad/aprobarpedido/receive-olas-det';
export const REQUEST_OLAS_LIST = 'movilidad/aprobarpedido/request-olas-list';
export const RESTART_STATE = 'movilidad/aprobarpedido/restart_state';
export const SET_MASK = 'movilidad/aprobarpedido/set-mask';
export const SET_ORDER = 'movilidad/aprobarpedido/set-order';
export const SET_SELECTED_ROW = 'movilidad/aprobarpedido/set-selected-row';
export const UPDATE_FIELD_FILTER = 'movilidad/aprobarpedido/update-field-filter';
export const UPDATE_TABLE_COLUMN = 'movilidad/aprobarpedido/update-table-column';
export const UPDATE_FIELD_ENTITYS = "clientes/aprobarpedido/update-field-entitys";
export const HANDLE_SHOW = "movilidad/aprobarpedido/handle-show";