import React, { useEffect} from 'react';//, useState 
import { useDispatch } from 'react-redux';//, useSelector
//import { closeMessageBox,  openMessageBox } from  '../../store/main/actions';

import {
    btnSearch,
    onExport 
} from '../../store/movilidad/reportepedidos/actions';

import { Body, Footer, Page } from '@sigeco/tools';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';

import ReportePedidosFilter from '../../components/movilidad/reportepedidos/ReportePedidosFilter';
import ReportePedidosList from '../../components/movilidad/reportepedidos/ReportePedidosList';

const ReportePedidos = ({onClose, setTitle}) => {
    //const loading = useSelector(state => state.reportepedidos.loading);
    const dispatch = useDispatch();
    //const [open, setOpen] = useState(false);
   // const store = useSelector(state => state.reportepedidos.store);

    useEffect(() => {
        setTitle('Lista de Pedidos');
    // eslint-disable-next-line
    }, []);

    function onBtnClose() {
        onClose();
    }

    return (
        <Page>
            <Backdrop style={{ position: 'absolute', zIndex: 1200 }} open={false} transitionDuration={0}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <Body >
            <ReportePedidosFilter />
            <ReportePedidosList />
        </Body>
        <Footer>
            <Button
                color="primary"
                size="small"
                variant="outlined"
                onClick={() => dispatch(onExport())}
                startIcon={<Icon className="fas fa-file-excel"/>}
            >
                Exportar
            </Button>
            <Button
                color="primary"
                size="small"
                variant="outlined"
                onClick={() => dispatch(btnSearch())}
                    startIcon={<Icon className="fas fa-search"/>}
                >
                Buscar
            </Button>
            <Button 
                color="primary" 
                size="small" 
                variant="outlined" 
                onClick={() => onBtnClose()} 
                startIcon={<Icon className="fas fa-door-open"/>}
            >
                Salir
            </Button>
        </Footer>
        </Page>
    )
};

export default ReportePedidos;