export const CLEAN_FORM = 'precios/precios/clean-form';
export const ERROR_OLAS_LIST = 'precios/precios/error-olas-list';
export const RECEIVE_OLAS_LIST = 'precios/precios/receive-reportepedidos-list';
export const REQUEST_OLAS_LIST = 'precios/precios/request-reportepedidos-list';
export const SET_STATE_FORM = 'precios/precios/set-state-form';
export const RESTART_STATE = 'precios/precios/restart_state';
export const SET_ENTITY = 'precios/precios/set-entity';
export const SET_ERROR = 'precios/precios/set-error';
export const SET_MASK = 'precios/precios/set-mask';
export const SET_ORDER = 'precios/precios/set-order';
export const SET_SELECTED_ROW = 'precios/precios/set-selected-row';
export const UPDATE_FIELD_FILTER = 'precios/precios/update-field-filter';
export const UPDATE_TABLE_COLUMN = 'precios/precios/set-selected-row';
export const UPDATE_FIELD_ENTITY = 'precios/precios/update-field-entity';
export const RECEIVE_V_LIST = 'precios/precios/receive-v-list';
export const ADD_PRESTACION = 'precios/precios/add_prestacion';
export const SET_ARREGLO = 'precios/precios/set-arreglo';

