import React, { useState } from 'react';
import {useDispatch,useSelector } from "react-redux";

import Icon from '@material-ui/core/Icon';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import BandejAprobacionDescuentoList from './BandejAprobacionDescuentoList';
import BandejAprobacionPasePedidoList from './BandejAprobacionPasePedidoList';
import BandejAprobacionCobranzaList from './BandejAprobacionCobranzaList';
import {onSetBandeja} from '../../../store/movilidad/bandejaprobacion/actions';

const useStyles = makeStyles(theme => ({
    paper: {
        flex: 1
    },
    tabsHorizontal: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    tabsVertical: {
        borderRight: `1px solid ${theme.palette.divider}`,
    }
}));

const BandejAprobacionPanel = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const big = useMediaQuery('(min-width:1201px)');
    const [value, setValue] = useState(0);
    const store = useSelector((state) => state.bandejaaprobacion.store);
        
    const handleChange = (event,  newValue) => {
        console.log(newValue)
        setValue(newValue);
        dispatch(onSetBandeja(newValue))
    };

    console.log(store.dataPase)
  return (
        <Paper className={classes.paper}>
            <div style={{ display: 'flex', flexDirection: big ? "row" : "column", height: '100%' }}>
                 <Tabs
                    orientation={big ? "vertical" : "horizontal"}
                    variant="fullWidth"
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="icon label tabs example"
                    className={big ? classes.tabsVertical : classes.tabsHorizontal}
                >
                    <Tab icon={<Icon className="fas fa-umbrella" />} 
                        label={`Pendiente de Pago  ${store.dataPase.length > 0 ? store.dataPase.length : ''}`} 
                        style={{ textTransform: "none",  backgroundColor: value === 0 ? "#C9C9C9" : "" }}/>
                    <Tab icon={<Icon className="fas fa-cash-register" />} 
                        label= {`Descuento ${store.data.length > 0 ? store.data.length : ''}`} 
                        style={{ textTransform: "none",  backgroundColor: value === 1 ? "#C9C9C9" : ""}}/>
                    <Tab icon={<Icon className="fas fa-cash-register" />} 
                        label= {`Aprobar Cobranza ${store.dataAprobarCobranza.length > 0 ? store.dataAprobarCobranza.length : ''}`} 
                        style={{ textTransform: "none",  backgroundColor: value === 2 ? "#C9C9C9" : ""}}/>
                </Tabs>
                {value === 0 && (<BandejAprobacionPasePedidoList />)}
                {value === 1 && (<BandejAprobacionDescuentoList />)}  
                {value === 2 && (<BandejAprobacionCobranzaList />)}  

            </div>
        </Paper>
    );
}

export default BandejAprobacionPanel ;

