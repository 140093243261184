export const FILTER_MAP_VENDEDOR = 'marketing/avanceidentificacionmercado/filter-map-vendedor';
export const HIDE_POPUP_CLIENT = 'marketing/avanceidentificacionmercado/hide-popup-client';
export const RECEIVE_DATA = 'marketing/avanceidentificacionmercado/receive_data';
export const RECEIVE_V_LIST = 'marketing/avanceidentificacionmercado/receive-v-list';
export const SET_MASK = 'marketing/avanceidentificacionmercado/set-mask';
export const SET_ORDER = 'marketing/avanceidentificacionmercado/set-order';
export const SET_SELECTED_ROW = 'marketing/avanceidentificacionmercado/set-selected-row';
export const SHOW_POPUP_CLIENT = 'marketing/avanceidentificacionmercado/show-popup-client';
export const UPDATE_FIELD_FILTER = 'marketing/avanceidentificacionmercado/update-field-filter';
export const UPDATE_TABLE_COLUMN = 'marketing/avanceidentificacionmercado/update-table-column';
export const   RECEIVE_OLAS_LIST = 'marketing/avanceidentificacionmercado/receive_olas_list';