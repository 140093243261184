import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { useLocation, useRouteMatch } from "react-router-dom";
import {
    btnDelete,
    btnExport,
    btnSave,
    btnSearch,
    restartState,
    setFormState
} from '../../store/channelmap/asignarmarcadistribuidor/actions';

import { Body, Footer, Page } from '@sigeco/tools';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';

import AsignarMarcaDistribuidorFilter from '../../components/channelmap/asignarmarcadistribuidor/AsignarMarcaDistribuidorFilter';
import AsignarMarcaDistribuidorCreate from '../../components/channelmap/asignarmarcadistribuidor/AsignarMarcaDistribuidorCreate';
import AsignarMarcaDistribuidorList from '../../components/channelmap/asignarmarcadistribuidor/AsignarMarcaDistribuidorList';
import AsignarMarcaDistribuidorTable from '../../components/channelmap/asignarmarcadistribuidor/AsignarMarcaDistribuidorTable';
import AsignarMarcaDistribuidorForm from '../../components/channelmap/asignarmarcadistribuidor/AsignarMarcaDistribuidorForm';

const AsignaMarcaDistribuidor = ({onClose, setTitle}) => {
    const location = useLocation();
    const match = useRouteMatch();
    const formState = useSelector(state => state.marca.formState);
    const loading = useSelector(state => state.marca.loading);
    const marcaId = useSelector(state => state.marca.entity.marcaId);
    const dispatch = useDispatch();

    
    useEffect(() => {
        switch(match.path) {
          case '/channelmap/asignarmarcadistribuidor/crear':
            setTitle('Crear Marca');
            dispatch(setFormState('new'))
            if(formState !== 'new') {
                dispatch(setFormState('new'));
            }
            break;
          case '/channelmap/asignarmarcadistribuidor/:id':
            dispatch(setFormState('view', match.params['id']));
            setTitle('Visualizar Marca');
            break;
          case '/channelmap/asignarmarcadistribuidor/:id/editar':
            dispatch(setFormState('edit', match.params['id']));
            setTitle('Editar Marca');
            break;
          default:
            dispatch(setFormState('search'));
            setTitle('Búsqueda');
            break;
        }
      // eslint-disable-next-line
    }, [match.path]);

    function onBtnClose() {
        dispatch(restartState());
        onClose();
    }

    return (
        <Page>
            <Backdrop style={{ position: 'absolute', zIndex: 1200 }} open={loading} transitionDuration={0}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <Body>
            {formState === 'search' && <AsignarMarcaDistribuidorFilter />}
            {formState === 'search' && <AsignarMarcaDistribuidorList />}
            {formState !== 'search' && formState!=='view' &&  <AsignarMarcaDistribuidorCreate />}
            {formState !== 'search' && formState!=='view' && <AsignarMarcaDistribuidorTable />}
            {formState === 'view' && <AsignarMarcaDistribuidorForm />}
        </Body>
        <Footer>
        {/* {formState === 'search' && <Button
                color="primary" 
                size="small" 
                variant="outlined" 
                onClick={() => dispatch(btnExport())}
                startIcon={<Icon className="fas fa-file-excel"/>}
            >
                Exportar
            </Button>} */}
            {formState === 'search' && <Button
                color="primary" 
                size="small" 
                variant="outlined"
                onClick={() => dispatch(btnSearch())}
                startIcon={<Icon className="fas fa-search"/>}
            >
                Buscar
            </Button>}
            {(formState === 'search') && <LinkContainer to={{ pathname: '/channelmap/asignarmarcadistribuidor/crear', search: location.search, hash: location.hash }}>
                <Button
                    color="primary" 
                    size="small" 
                    variant="outlined"
                    startIcon={<Icon className="fas fa-file"/>}
                >
                    Asignar
                </Button>
            </LinkContainer>}
          
            {formState === 'view' && <Button 
                color="primary" 
                size="small" 
                variant="outlined" 
                onClick={() => dispatch(btnDelete())}
                startIcon={<Icon className="fas fa-trash"/>}
            >
                Eliminar
            </Button>}
            {formState !== 'search' && formState !== 'view' && <Button
                color="primary" 
                size="small" 
                variant="outlined" 
                onClick={() => dispatch(btnSave())}
                startIcon={<Icon className="fas fa-save"/>}
            >
                Guardar
            </Button>}
            {formState !== 'search' && <LinkContainer to={{ pathname: '/channelmap/asignarmarcadistribuidor', search: location.search, hash: location.hash }}>
                <Button
                    color="primary" 
                    size="small" 
                    variant="outlined"
                    startIcon={<Icon className="fas fa-times-circle"/>}
                >
                    Cancelar
                </Button>
            </LinkContainer>}
            <Button 
                color="primary" 
                size="small" 
                variant="outlined" 
                onClick={() => onBtnClose()} 
                startIcon={<Icon className="fas fa-door-open"/>}
            >
            Salir
            </Button>
        </Footer>
        </Page>
    )
};

export default AsignaMarcaDistribuidor;