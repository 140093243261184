import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  onSearchDistribuidorClick,
  onUpdateFieldFilter,
} from "../../../store/clientes/cargamasivaclientes/actions";
import {
  RECEIVE_V_LIST,
} from "../../../store/clientes/cargamasivaclientes/types";

import { ActionField, FieldSet } from "@sigeco/tools";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FormControl from "@material-ui/core/FormControl";
import { Icon } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import * as XLSX from "xlsx";

import {getDistribuidorDefault} from '../../../helpers/commons';


const useStyles = makeStyles(() => ({
  content: {
    padding: "0.5rem 0.75rem 0.75rem",
  },
  filterClear: {
    display: "flex",
  },
  actionField: {
    flexDirection: "row",
    position: "relative",
  },
  btnClear: {
    position: "absolute",
    bottom: "1.5",
    right:20,
    maxWidth: "15px",
    maxHeight: "23px",
    minWidth: "15px",
    minHeight: "23px",
  },
  root: {
    flex: "0 0 auto",
    marginBottom: "0.75rem",
    "@media (min-width: 1401px)": {
      marginBottom: "1rem",
    },
  },
}));

const CargaMasivaClientesFilter = () => {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:701px)");
  const filters = useSelector((state) => state.cargamasivaclientes.filters);
  const dispatch = useDispatch();

  useEffect(() =>{

    getDistribuidorDefault(onUpdateFieldFilter,dispatch);

  },[])

  //Botones de limpiar filtros
  const [, setDistribuidor] = useState("");
  const [btn, setBtn] = useState(true);
  const [, setFile] = useState([]);

  useEffect(() => {
    if (filters.dDistribuidor) {
      setDistribuidor(filters.dDistribuidor);
      setBtn(false);
    } else {
      setBtn(true);
    }
  }, [filters.dDistribuidor]);

  const handleDist = () => {
    setDistribuidor("");
    filters.distribuidorId = 0;
    filters.dDistribuidor = "";
    setBtn(true);
  };

  // function onFieldChange(event) {
  //   dispatch(onUpdateFieldFilter(event.target.name, event.target.value));
  // }

   function  readExcel (par)  { //=>
   

    if (par === '' || par === undefined){
      setFile('')
      return
    }

        const promise = new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsArrayBuffer(par);
    
            fileReader.onload = (e) => {
              const bufferArray = e.target.result;
      
              const wb = XLSX.read(bufferArray, { type: "buffer" });
      
              const wsname = wb.SheetNames[0];
      
              const ws = wb.Sheets[wsname];
      
              const data = XLSX.utils.sheet_to_json(ws);
      
              resolve(data);

              setFile(data)
   
            };
    
            fileReader.onerror = (error) => {
              reject(error);
            };
        });
    
        promise.then((d) => {
           if (d.length > 0  || d){
            dispatch({ type: RECEIVE_V_LIST, columns: Object.keys(d[0]), payload: d });
           }
          // dispatch({ type: SET_MASK, loading: false });
        });
    };
 
  return (
    <div className={classes.root}>
      <FieldSet iconCls="fas fa-filter" title="Filtros" defaultExpanded>
        <Grid className={classes.content} container spacing={2}>
          <Grid item xs={12} container>
            <Grid item xs={matches ? 4 : 6}>
              <FormControl className={classes.filterClear}>
                <ActionField
                  className={classes.actionField}
                  label="Agente"
                  placeholder="Enter para buscar agente"
                  value={filters.dDistribuidor}
                  variant={matches ? "outlined" : "standard"}
                  onHandleAction={() => dispatch(onSearchDistribuidorClick())}
                />
                <Button
                  className={classes.btnClear}
                  size="small"
                  startIcon={<Icon className="fa fa-window-close" />}
                  disabled={btn}
                  onClick={handleDist}
                />
              </FormControl>
            </Grid>
          </Grid>
  

          <Grid item xs>
            <FormControl className={classes.filterClear}>
              <label style={{marginLeft:'20px' ,background:'#FF1100', color: 'white', borderRadius: '5px', width:'140px', height:'30px', display:false }} disable = 'true'
                > <i className={"fa fa-upload"} style={{marginTop:'8px', width:"20px", height:"20px",marginLeft:'15px', color : 'white' , marginRight: '5px'}} />Subir Archivo
                <input type="file"
                    style={{opacity : 0}}
                    onChange={(e) => {
                        const archivo = e.target.files[0];
                        readExcel(archivo);
                    }}
                />
              </label>
            </FormControl>
          </Grid>


        </Grid>
      </FieldSet>
    </div>
  );
};

export default CargaMasivaClientesFilter;
