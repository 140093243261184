import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { useLocation, useRouteMatch } from "react-router-dom";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  btnApprove,
  onExport,
  btnReject,
  btnSearch,
  onUpdateFieldEntity,
  restartState,
  setFormState,
} from "../../store/clientes/prospectos/actions";

import { Body, Footer, Page } from "@sigeco/tools";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Icon from "@material-ui/core/Icon";
import TextField from "@material-ui/core/TextField";

import ProspectosFilter from "../../components/clientes/prospectos/ProspectosFilter";

import ProspectosList from "../../components/clientes/prospectos/ProspectosList";
import ProspectosForm from "../../components/clientes/prospectos/ProspectosForm";

const Prospecto = ({ onClose, setTitle }) => {
  const location = useLocation();
  const match = useRouteMatch();
  const [open, setOpen] = useState(false);
  const loading = useSelector(state => state.aprobarprospectos.loading);
  const formState = useSelector((state) => state.aprobarprospectos.formState);

  const sw = useSelector((state) => state.aprobarprospectos.filters.sw );  

  const [rechazo, setRechazo] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    setTitle("Búsqueda");
    switch (match.path) {
      case "/clientes/prospectos/:id":
        dispatch(setFormState("view", match.params["id"]));
        setTitle("Visualizar Aprobación");
        break;
      default:
        dispatch(setFormState("search"));
        setTitle("Aprobar Prospectos");
        break;
    }
    // eslint-disable-next-line
  }, [match.path]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    alert()
    dispatch(btnSearch());
    setOpen(false);
    setRechazo("");
  
  };

  function onBtnClose() {
    dispatch(restartState());
    onClose();
  }
  function onBtnClose2() {
    dispatch(btnSearch());
  }

  function handleSave(e) {
    dispatch(onUpdateFieldEntity(rechazo));
    dispatch(btnReject());
    setOpen(false);
    setRechazo("");
  }

  return (
    <Page>
          <Backdrop style={{ position: 'absolute', zIndex: 1200 }} open={loading} transitionDuration={0}>
          <CircularProgress color="inherit" />
          </Backdrop>
      <Body>
        {formState === "search" && <ProspectosFilter/>}
        {formState === "search" && <ProspectosList/>}
        {formState !== "search" && <ProspectosForm />}
      </Body>
      <Footer>
        {formState === "search" && (
          <Button
            color="primary"
            size="small"
            variant="outlined"
            onClick={() => dispatch(onExport())}
            startIcon={<Icon className="fas fa-file-excel" />}
          >
            Exportar
          </Button>
        )}
        {formState === "search" && (
          <Button
            color="primary"
            size="small"
            variant="outlined"
            onClick={() => dispatch(btnSearch())}
            startIcon={<Icon className="fas fa-search" />}
          >
            Buscar
          </Button>
        )}
        {formState !== "search" && sw === "P" && (
          <Button
            color="primary"
            size="small"
            variant="outlined"
            onClick={() => dispatch(btnApprove())}
            startIcon={<Icon className="fas fa-thumbs-up" />}
          >
            Aprobar
          </Button>
        )}
        {formState !== "search" && sw === "P" && (
          <Button
            color="primary"
            size="small"
            variant="outlined"
            onClick={handleClickOpen}
            startIcon={<Icon className="fas fa-thumbs-down" />}
          >
            Rechazar
          </Button>
        )}
        {formState !== "search" && (
          <LinkContainer
            to={{
              pathname: "/clientes/prospectos",
              search: location.search,
              hash: location.hash,
            }}
          >
            <Button
              color="primary"
              size="small"
              variant="outlined"
              startIcon={<Icon className="fas fa-times-circle" />}
              onClick={() => onBtnClose2()}
            >
              Cancelar
            </Button>
          </LinkContainer>
        )}
        <Button
          color="primary"
          size="small"
          variant="outlined"
          onClick={() => onBtnClose()}
          startIcon={<Icon className="fas fa-door-open" />}
        >
          Salir
        </Button>
      </Footer>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Rechazar</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Motivo rechazo"
            multiline
            rows={2}
            fullWidth
            value={rechazo}
            onChange={(event) => setRechazo(event.target.value)}
            inputProps={{
              maxLength: 200,
              Size: 200,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button 
           onClick={handleClose} variant="contained"
           color="primary">
            Cancelar
          </Button>
          <Button
            color="primary"
            onClick={() => {handleSave();}}
            variant="contained"
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};

export default Prospecto;
