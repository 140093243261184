import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FieldSet } from '@sigeco/tools';

import makeStyles from '@material-ui/core/styles/makeStyles';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import CardContent from '@material-ui/core/CardContent';
import Icon from '@material-ui/core/Icon';


const useStyles = makeStyles(() => ({
    content: {
        padding: '0.5rem 0.75rem 0.75rem'
    },
    root: {
        flex: '0 0 auto',
        marginBottom: '0.75rem',
        '@media (min-width: 1401px)': {
            marginBottom: '1rem',
        }
    },
    mime: {
        fontSize: 8,
        "& .MuiFormLabel-root": {
          color: "red"
        }
      }
}));

const ResumenCliNuevNueve = () => {
  
    const classes = useStyles();
    const store = useSelector(state => state.resumen.store);
    const filters = useSelector(state => state.resumen.filters);

    const [employees, setEmployees] = useState([])

    useEffect(() => {
        if (store.clinuevnueve.length ){
                       setEmployees(store.clinuevnueve)
        }
    }, [store.clinuevnueve])

    const renderHeader = () => {
        let headerElement = ['Nro','Distribuidor', 'Asesor', 'Cumplimiento', 'Pago']

        return headerElement.map((key, index) => {
            return <th key={index} style={{ backgroundColor: 'lightsteelblue' }}>{key.toUpperCase()} </th>
        })
    }

    const renderBody = () => {
        var i = 1;
        return employees && employees.map(({ id, distribuidor, asesor, cumplimiento, pago }) => {
            return (
                <tr style={{ fontSize: '12px', backgroundColor: i%2 === 0 ?'Gainsboro' : 'lavander' }} key={i}> 
                <td>{i++ }</td>
                    <td>{distribuidor}</td>
                    <td>{asesor}</td>
                    <td style={{textAlign : 'right'}}>{cumplimiento | 0}</td>
                    <td style={{textAlign : 'center'}}>${pago}</td>
                </tr>
            )
        })
    }

    return (

        <div className={classes.root}  style={{   textAlign : 'center'}} > 
                    
                <FieldSet iconCls="fas fa-hand-paper" title="CLIENTES NUEVOS DE 9 EN ADELANTE" defaultExpanded expandable>
                    
                 <CardContent style={{ overflow:'hidden' }}>
                           <ListItem>
                               <ListItemAvatar>
                                 <Avatar>
                                 <Icon className="fas fa-hand-paper" ></Icon>
                                 </Avatar>
                               </ListItemAvatar>
                           </ListItem>
                           <ListItemText primary="Activo" secondary= {store.meses[filters.desde.getMonth()] +',  '+ filters.desde.getFullYear()}/>     
                   </CardContent>
                    
                        <div  style={{ flex: 1,   justifyContent: "center",   backgroundColor: "#e5e5e5" }}>
                            -
                        </div>

                        <div style={{display: 'block',  width: '100%', border: '5px', 
                            padding: '10px', justifyContent: "center",}}>

                            <FieldSet iconCls="fas fa-hand-pointer" title="Info" 
                            defaultExpanded expandable  style={{ textAlign : 'center', width: '200%'}} > 
                
                                <div  style={{ flex: 1,   justifyContent: "center"}}>
                                        <img alt='logo'  src={ './images/resumen.png'}/>

                                        <div  style={{ flex: 1,   justifyContent: "center"}}>
                                        
                                        <CardContent  style={{ border : '36px'}}>              
                                        <table id='employee'  style={{ display: 'inline-block', border: "10px solid rgba(183, 193, 201, 1)", borderRadius:'5px'}}>
                                                <thead>
                                                    <tr>{renderHeader()}</tr>
                                                </thead>
                                                <tbody>
                                                    {renderBody()}
                                                </tbody>
                                        </table>
                                        </CardContent>
                                        
                                        </div>
                                </div>
                            </FieldSet>  
                        </div>      
                    
            </FieldSet>   

            </div>
    )
};

export default ResumenCliNuevNueve;