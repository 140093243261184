import axios from "axios";
import format from "date-fns/format";
import { IdentityUrl } from "../../../config";
import {
  closeAdvancedSearch,
  closeMessageBox,
  openAdvancedSearch,
  openMessageBox,
} from "../../main/actions";
import {
  ERROR_OLAS_LIST,
  RECEIVE_OLAS_LIST,
  RESTART_STATE,
  SET_MASK,
  SET_ORDER,
  SET_SELECTED_ROW,
  UPDATE_FIELD_FILTER,
  UPDATE_FIELD_ENTITYS,
  RECEIVE_OLAS_DET,
  HANDLE_SHOW
} from "./types";

import { exportToSpreadsheet, searchVendedor } from "../../../helpers/commons";

export const detallePedido = (item) => async (dispatch, getState) => {
  if (getState().aprobarpedido.store.loading) {
    return;
  }

  dispatch({ type: SET_MASK, loading: true });

  try {
    let url =
      `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Pedidos/` +
      `${item["PedidoId"]}`;

    let response = await axios.get(encodeURI(url));
    let datad = response;

    dispatch({ type: RECEIVE_OLAS_DET, payload: datad.data.detalle });
  } catch (error) {
    let msgError = "";
    dispatch({ type: SET_MASK, loading: false });
    dispatch({ type: ERROR_OLAS_LIST });

    if (error.response) {
      if (error.response.status === 400) {
        msgError =
          error.response.data.eventLogId === 0
            ? "No existe Registro  para esta fecha "
            : `EventoId: ${error.response.data.eventLogId}. ` +
              error.response.data.message;
      } else {
        msgError =
          (error.response.data.eventLogId === 0
            ? ""
            : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message;
      }
    } else {
      msgError = "Error al conectar con el servidor....";
    }

    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message: msgError,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    dispatch({ type: SET_MASK, loading: false });
  }
};
// Para el mensale del Aprobar Si p No

  
  export const onApprove = (btn) => async (dispatch, getState)  =>{
    
    dispatch(closeMessageBox());
    
    if(btn === "yes" ){
      dispatch({ type: SET_MASK, loading: true });
      try {
        let head = {
          pedidoId: getState().aprobarpedido.store.detalle[0].pedidoId,
          estado: "A",
          motivoRechazo: "",
        };
        
        
        let url = `${ getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Pedidos/estado`;
        await axios.put(url, head, {
          headers: { "Content-Type": "application/json" },
        });
        
        dispatch({type: HANDLE_SHOW, payload: false})
        dispatch(
          openMessageBox({
            button: "ok",
            icon: "info",
            message: "Registro Aprobado con Exito..",
            callback: () => dispatch(closeMessageBox())
          })
        );
      } catch (error) {
        let msgError = "";
        dispatch({ type: SET_MASK, loading: false });
        dispatch({ type: ERROR_OLAS_LIST });
    
        if (error.response) {
          if (error.response.status === 400) {
            msgError =
              error.response.data.eventLogId === 0
                ? error.response.data.message
                : `EventoId: ${error.response.data.eventLogId}. ` +
                  error.response.data.message;
          } else {
            msgError =
              (error.response.data.eventLogId === 0
                ? ""
                : `EventoId: ${error.response.data.eventLogId}. `) +
              error.response.data.message;
          }
        } else {
          msgError = "Error al conectar con el servidor";
        }
    
        dispatch(
          openMessageBox({
            button: "ok",
            icon: "error",
            message: msgError,
            callback: () => dispatch(closeMessageBox()),
          })
        );
      } finally {
        dispatch({ type: SET_MASK, loading: false });
        // dispatch(
        //   openMessageBox({
        //     button: "ok",
        //     icon: "info",
        //     message: "Registro Guardado con Exito..",
        //     callback: () => dispatch(closeMessageBox()),
        //   })
        // );
      }
    }else{
      //console.log('Aqui cuando cierra')
      dispatch(closeMessageBox())
      return
    }
};


export const actualiza = (a, b, c) => async (dispatch, getState) => {
  // console.log(a)
  // console.log(b)
  // console.log(c)

  dispatch({ type: SET_MASK, loading: true });
  try {
    let head = {
      pedidoId: a,
      estado: b,
      motivoRechazo: c,
    };

    let url = `${ getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Pedidos/estado`;
    let response = await axios.put(url, head, {
      headers: { "Content-Type": "application/json" },
    });

    let xdata = response.data;
    //console.log("dat   " + xdata);

  } catch (error) {
    let msgError = "";
    dispatch({ type: SET_MASK, loading: false });
    dispatch({ type: ERROR_OLAS_LIST });

    if (error.response) {
      if (error.response.status === 400) {
        msgError =
          error.response.data.eventLogId === 0
            ? error.response.data.message
            : `EventoId: ${error.response.data.eventLogId}. ` +
              error.response.data.message;
      } else {
        msgError =
          (error.response.data.eventLogId === 0
            ? ""
            : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message;
      }
    } else {
      msgError = "Error al conectar con el servidor";
    }

    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message: msgError,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    dispatch({ type: SET_MASK, loading: false });
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "info",
        message: "Registro Guardado con Exito..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
  }
};

export const onExport = () => async (dispatch, getState) => {
  if (getState().aprobarpedido.filters.distribuidorId   === 0) {
    dispatch(openMessageBox({
        button: 'ok',
        icon: 'warning',
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox())
    }));
  return;
  }

  if (getState().aprobarpedido.store.data.length === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "No hay información para exportar.",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }
  try {
    dispatch({ type: SET_MASK, loading: true });

    //let response = await axios.get(encodeURI(url)); //, { responseType: 'arraybuffer' });

    let head = getState().aprobarpedido.table.columns;

    // eslint-disable-next-line no-array-constructor
    var temp = new Array();
    for (var c in head) {
      let x = Object.values(head[c])[0];
      temp.push(x);
    }
    // eslint-disable-next-line no-array-constructor
    var carArray = new Array();

    carArray.push(temp);

    let json = getState().aprobarpedido.store.data;

    for (var i in json) {
      let x = Object.values(json[i]);
      carArray.push(x);
    }

    exportToSpreadsheet(carArray, "aprobarPedidos");
  } catch (error) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message:
          (error.response.data.eventLogId === 0
            ? ""
            : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    setTimeout(() => {dispatch({ type: SET_MASK, loading: false })}, 1000);
  }
};
export const btnApprove = () =>(dispatch) =>{

  dispatch(
    openMessageBox({
      button: "yesno",
      icon: "question",
      message: "¿Está seguro de aprobar el Pedido?",
      callback: (btn) => dispatch(onApprove(btn)),
    })
  );
};

export const btnSearch = () => async (dispatch, getState) => {
  if (getState().aprobarpedido.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }

  if (getState().aprobarpedido.store.loading) {
    return;
  }

  dispatch({ type: SET_MASK, loading: true });

  try {
    let url = `${ getState().main.resources.uriSvcSalesMobilityAPI }api/v1/reportes/reporteaprobacionpedidos?` +
      `distribuidorId=${getState().aprobarpedido.filters.distribuidorId}` +
      `&desde=${format(getState().aprobarpedido.filters.desde, "yyyy-MM-dd")}` +
      `&hasta=${format(getState().aprobarpedido.filters.hasta, "yyyy-MM-dd")}` +
      `&vendedorid=${getState().aprobarpedido.filters.vendedorId}`;

    let response = await axios.get(encodeURI(url));
    let data = response.data;
    //console.log(data)
    
    if (data.length === 0) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "No existe Registro  para esta consulta",
          callback: () => dispatch(closeMessageBox()),
        })
      );
      return;
    }

    if (data.data.length > 0) {
      //console.log('Type Aly ')
      data.columns[0]["type"] = "text";
      data.columns[3]["width"] = 100;
      data.columns[4]["width"] = 250;
      data.columns[5]["width"] = 200;
      data.columns[6]["width"] = 120;
      data.columns[10]["type"] = "hidden";
    }

    
    if (getState().aprobarpedido.filters.estado !== "T") {
      let x = data.data.filter(
        (e) => e.Estado === getState().aprobarpedido.filters.estado
      );

      data.columns[10]["width"] = 160;
      data.columns[10]["type"] = "hidden";
      data.columns[0]["type"] = "text";
      dispatch({ type: RECEIVE_OLAS_LIST, columns: data.columns, payload: x });
    } else {
      dispatch({
        type: RECEIVE_OLAS_LIST,
        columns: data.columns,
        payload: data.data,
      });
    }
  } catch (error) {
    let msgError = "";
    dispatch({ type: SET_MASK, loading: false });
    dispatch({ type: ERROR_OLAS_LIST });

    if (error.response) {
      if (error.response.status === 400) {
        msgError =
          error.response.data.eventLogId === 0
            ? "No existe Registro  para esta fecha "
            : `EventoId: ${error.response.data.eventLogId}. ` +
              error.response.data.message;
      } else {
        msgError =
          (error.response.data.eventLogId === 0
            ? ""
            : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message;
      }
    } else {
      msgError = "Error al conectar con el servidor";
    }

    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message: msgError,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    dispatch({ type: SET_MASK, loading: false });
  }
};

export const onUpdateColumn = (column) => (dispatch, getState) => {
  getState().aprobarpedido.table.columns.map((item, i) => {
    if (item.dataKey !== column.dataKey) {
      return item;
    } else {
      item.width = column.width;
    }
    return {
      // ...item,
      //  width: column.width
    };
  });
};

export const onSearchDistribuidorClick = () => async (dispatch) => {
  let fields = [
    { dataKey: "razonSocial", label: "Agente", type: "string", width: 225 },
  ];

  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de Agente",
      getData: async (params) => {
        let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`;
        let response = await axios.get(encodeURI(url), { params: params });

        return response.data;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(onUpdateFieldFilter("distribuidorId", result[0].distribuidorId));
          dispatch(onUpdateFieldFilter("dDistribuidor", `${result[0].razonSocial}`));
          dispatch(onUpdateFieldFilter("supervisorId", ""));
          dispatch(onUpdateFieldFilter("dSupervisor", ""));
          dispatch(onUpdateFieldFilter("vendedorId", ""));
          dispatch(onUpdateFieldFilter("dVendedor", ""));
        }
      },
    })
  );
};

export const onSearchVendedorClick = () => async (dispatch, getState) => {
  let fields = [
    {
      dataKey: "nombre",
      label: "Nombre",
      type: "string",
      search: true,
      width: 225,
    },
    { dataKey: "nombreUsuario", label: "Usuario", type: "string", width: 115 },
  ];

  if (getState().aprobarpedido.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }
  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de Vendedor",
      getData: async (params) => {
        let url = `${getState().main.resources.uriSvcSalesMobilityAPI}/api/v1/Vendedores/listarvendedoresxdistribuidor`
        + `?distribuidorId=${getState().aprobarpedido.filters.distribuidorId}`               
        let response = await axios.get(encodeURI(url), { params: params });
        
        return searchVendedor(response, params.nombre);  
    },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(onUpdateFieldFilter("vendedorId", result[0].usuarioId)); //    usuarioId));
          dispatch(onUpdateFieldFilter("dVendedor", `${result[0].nombre}`));
          // dispatch({ type: RECEIVE_OLAS_LIST, columns: getState().aprobarpedido.table.columns, payload: [] });
        }
      },
    })
  );
};

/*
export const openDialogClient = (data) => (dispatch, getState) => {
    try {
        var storeData = getState().reportevisitas.data.filter(f => f.dVendedor === data.dVendedor);
        
        dispatch({ type: SHOW_POPUP_CLIENT, storeData });
    } catch (error) {
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'error', 
            message: error, 
            callback: () => dispatch(closeMessageBox())
        }));
    }
};
*/

export const onUpdateFieldFilter = (key, value) => (dispatch, getState) => {
  // console.log(key, value);
  //({ type: UPDATE_FIELD_FILTER, key, value });
  dispatch({ type: UPDATE_FIELD_FILTER, key, value });
  dispatch({ type: RECEIVE_OLAS_LIST, columns: [], payload: [] });
};

export const restartState = () => ({ type: RESTART_STATE });

export const setOrder = (order, orderBy) => ({
  type: SET_ORDER,
  order,
  orderBy,
});

export const setSelectedRow = (selected) => ({
  type: SET_SELECTED_ROW,
  selected,
});

export const onUpdateFieldEntity = ( value) => (dispatch) => {

  dispatch({ type: UPDATE_FIELD_ENTITYS, value });
};

export const HandleShows = (value) =>(dispatch)=>{
  //console.log('Pase Shows', value)
  dispatch({type: HANDLE_SHOW, payload: value})

    
}
