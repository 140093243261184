import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { FieldSet, Table } from "@sigeco/tools";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  detalleCobranza,
  setOrder,
  onUpdateColumn,
} from "../../../store/movilidad/reportecobranza/actions";

import { sort_by } from "../../../helpers/commons";

import Modal from "@material-ui/core/Modal";

import Button from "@material-ui/core/Button";

import { formatoPeru} from "../../../helpers/commons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
  },
  tableWrap: {
    flex: "1 1 auto",
  },
  modal: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    height: "100%",
    maxHeight: 500,
    maxWidth: 800,
    width: "100%",
    display: "block",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1, 0, 1, 5),
    alignItems: "center",
    justifyContent: "center",
    fontSize: "max(1 vw, 5px)",
    "@media (max-width: 780px)": {
      width: "80%",
      fontSize: "max(1.5vw, 5px)",
    },
    "@media (max-width: 865px)": {
      width: "80%",
      fontSize: "max(1.5vw, 5px)",
    },

    borderRadius: "15px",
  },
  resuld: {
    fontSize: "10px !important",
    textAlign: "Right !important", 
    background: "white",  
  },
  bot: {
    left: "90%", 
    bottom: "5%",
    fontSize: "max(3vw, 5px)",
    marginTop: "60px",
    "@media (min-width: 480px)": {
      fontSize: "12px",
    },
    "@media (max-width: 1024px)": {
      marginLeft:"5px !important",
      marginTop:"30px !important",
      fontSize:"10px"
    },
    "@media (max-width: 865px)": {
      marginLeft:"-15px !important",
      marginTop:"20px !important",
      fontSize:"10px"
    }, 
    "@media (max-width: 768px)": {
      marginLeft:"-15px !important",
      marginTop:"20px !important",
      fontSize:"10px"
    }, 
    "@media (max-width: 553px)":{
      marginLeft:"-25px !important",
      marginTop:"25px !important"
    },
    "@media (max-width: 526px)":{
      marginLeft:"-25px !important",
      marginTop:"70px !important"
    },
    "@media (max-width: 456px)":{
      marginLeft:"-30px !important",
      marginTop:"70px !important"
    
    },
    "@media (max-width: 446px)":{
      marginLeft:"-30px !important",
      marginTop:"60px !important"
    
    },
    "@media (max-width: 437px)":{
      marginLeft:"-30px !important",
      marginTop:"60px !important"
    
    },
    "@media (max-width: 425px)": {
      marginLeft:"-35px !important",
      marginTop:"-2px !important",
      fontSize:"9px"
    }, 
    "@media (max-width: 375px)": {
      marginLeft:"-38px !important",
      marginTop:"7px !important"
    }, 
    "@media (max-width: 320px)": {
      marginLeft:"-40px !important",
      marginTop:"8px !important"
    }
  },
   outer: {
    fontSize: "max(3vw, 5px)",
    "@media (min-width: 780px)": {
      width: "50%",
      fontSize: "max(1vw, 5px)",
    },
  },
  cuadrin: {
    marginLeft:"-20px",
    "@media (max-width: 425px)":{
      width:"80% !important",
      marginLeft:"-10px"
    },
    "@media (max-width: 375px)":{
      width:"80% !important",
      marginLeft:"-24px"
    },
    "@media (max-width: 348px)":{
      width:"80% !important",
      marginLeft:"-23px"
    },
    "@media (max-width: 320px)":{
      width:"50% !important",
      marginLeft:"-28px",
    }
    

  },
  cua: {
    //width: '85%',
  },
  cuadro: {
    overflow: "auto",
    height: "280px",
    width: "890% !important",
    maxWidth: "750px",
    marginLeft:"30px",
    "@media (max-width: 865px)": {
      width: "105%",
      marginLeft:"-35px"
    },
    "@media (max-width: 815px)": {
      width: "105%",
      marginLeft:"-35px"
    },
    "@media (max-width: 795px)": {
      width: "105%",
      marginLeft:"-30px"
    },
    "@media (max-width: 768px)": {
      width: "110%",
      marginLeft: "-85px",
    },
    "@media (max-width: 737px)": {
      width: "110%",
      marginLeft: "-95px",
    },
    "@media (max-width: 699px)":{
      width: "103% !important",
      marginLeft:"-30px"
    },
    "@media (max-width: 553px)": {
      width: "98% !important",
      marginLeft:"-3px !important",

    },
    "@media (max-width: 425px)": {
      width: "105% !important",
      marginLeft: "-26px !important",
      marginTop:"15px",
      paddingBottom:"10px",
      fontsize:"20px !important"
    },
    "@media (max-width: 375px)": {
      width: "110%",
      marginLeft: "-33px",
      marginTop:"15px",
      paddingBottom:"10px",
      fontsize:"20px !important"
    },
    
    "@media (max-width: 320px)": {
      width: "110%",
      marginLeft: "-33px",
      marginTop:"15px",
      paddingBottom:"10px",
      fontsize:"20px !important"
    },
  },
}));

function ReporteCobranzaList(props) {
  const classes = useStyles();
  const [modalInfo, setModalInfo] = React.useState([]);
  const [, setShowModal] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const HandleClose = () => setShow(false);
  const HandleShow = () => setShow(true);
  const columns = useSelector((state) => state.reportecobranza.table.columns);
  const loading = useSelector((state) => state.reportecobranza.loading);
  const order = useSelector((state) => state.reportecobranza.table.order);
  const orderBy = useSelector((state) => state.reportecobranza.table.orderBy);
  const store = useSelector((state) => state.reportecobranza.store);
  const dispatch = useDispatch();

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    dispatch(setOrder(isAsc ? "desc" : "asc", property));
    let homes = store.data;
    homes.sort(sort_by(property, isAsc, homes[0][property]));
  };


  //--------------------------------------------------------------------------------------------------------------------------------
  const Clicke = (item) => {
    let items= []
    items.push(item)
    dispatch(detalleCobranza(items[0]));
    setModalInfo(items[0]);
    setShowModal(HandleShow);
  };
  //--------------------------------------------------------

  const handleResizeColumn = (column) => {
    dispatch(onUpdateColumn(column));
  };

  const ModalContent = () => {
    const datad = useSelector((state) => state.reportecobranza.store.detalle);
    let total = 0;
    return (
      <div>
        <Modal
          fullscreen={"xxl"}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={show}
          onClose={HandleClose}
        >
          <div style={{ display: 'flex', flex: '1', maxHeight: '370px' }} >
            <div
              className={classes.modal}
              style={{ height: datad.length === 1 ? '310px' : (datad.length === 2 || datad.length === 3) ? '330px' : (datad.length === 3 || datad.length === 4) ? '330px' : '350px' }}
              // style={{
              //   height:
              //     datad.length === 1
              //       ? "57%"
              //       : datad.length === 2
              //       ? "56%"
              //       : datad.length === 3 || datad.length === 4
              //       ? "62%"
              //       : datad.length > 4 && datad.length < 7
              //       ? "65%"
              //       : "77%",
              // }}
            >
              <h2 style={{ textAlign: "center", fontSize: "18px" }}>
                Detalle de Cobranza
              </h2>
                     
              <div className={classes.cuadrin} style= {{ /*width: '95%'*/}} >
                <table className={classes.cua}  align="center" style= {{width:"100%", marginLeft:'-10px' }} >
                <tbody > 
                      <tr>
                          <td  style={{ width: "85px" , backgroundColor: "#BFD9FF",fontSize: "10px" ,  fontWeight: "bold"}} >NIT:</td> 
                        <td colSpan="2" style={{ width: "85px",fontSize: "10px"  }}> <b>{modalInfo["NIT"]} </b></td>
                        <td style={{ width: "50px" , backgroundColor: "#BFD9FF" ,fontSize: "10px", fontWeight: "bold"}}>FECHA:</td>
                        <td colSpan="2" style={{fontSize: "10px", width: "65px" }}>{modalInfo.Fecha}</td>
                      </tr>

                      <tr>
                        <td style={{ width: "85px" , backgroundColor: "#BFD9FF",fontSize: "10px",fontWeight: "bold" }}> CLIENTE:</td>
                        <td colSpan="2" style={{ width: "185px",fontSize: "10px" }}>{modalInfo.NombreComercial.toUpperCase()}</td>
                        <td style={{ width: "35px" , backgroundColor: "#BFD9FF",fontSize: "10px",fontWeight: "bold" }}>VENDEDOR:</td>
                        <td  colSpan="3" style={{ width: "85px",fontSize: "10px"  }}>{modalInfo.Vendedor.toUpperCase()}</td>  
                      </tr>
                     
                      <tr> 
                        <td style={{ width: "85px" , backgroundColor: "#BFD9FF",fontSize: "10px" , fontWeight: "bold" }}> OBSERVACIÓN:</td>
                        <td colSpan="6" style={{ width: "85px",fontSize: "10px" }}><b> {modalInfo.Observacion.toUpperCase()} </b></td>
              
                      </tr>
                  </tbody>
                </table> 
              </div>
              
              
              <div>
                <div style={{ display: "none" }}>
                  {!loading && datad.map((ite) => (total = total + ite.Monto))}
                </div>
                <div style={{marginLeft: '-30px', width: '102%', height: datad.length >= 5 ? "145px " : "145px", overflowX: datad.length >= 5 ? "auto" : "hidden", marginTop:"15px"}}
                 >

                <table
                  className="aprobacion"
                  id="tableMod"
                  style={{ borderCollapse:"collapse", width: "100%" }}
                >
                  
                    <thead style={{border: "1px solid #BFD2E9"}}>
                      <tr>
                        <th>
                          Nro. Documento
                        </th>
                        <th>
                          Fecha
                        </th>
                        <th >
                          Saldo
                        </th>
                        <th >
                          Monto
                        </th>
                        <th>
                          Fecha Venta
                        </th>
                      </tr>
                    </thead>
                    <tbody style={{border: "1px solid #BFD2E9"}}>
                      {!loading &&
                        datad.map((item, index) => (
                          <tr key={index}>
                            <td
                              style={{ fontSize: "12px", background: "white" }}
                            >
                              {item.NroDocumento}
                            </td>
                            <td
                              style={{ fontSize: "12px", background: "white" }}
                            >
                              {item.Fecha}
                            </td>
                            <td
                              style={{ fontSize: "12px", background: "white" }}
                            >
                              {formatoPeru('en-US', 'USD',item.Saldo)}
                            </td>
                            <td
                              style={{ fontSize: "12px", background: "white" }}
                            >
                              {formatoPeru('en-US', 'USD',item.Monto)}
                            </td>
                            <td
                              style={{ fontSize: "12px", background: "white" }}
                            >
                              {item.FechaVence}
                            </td>
                          </tr>
                        ))}
                         { datad.length > 0   &&  !loading &&(
                                <tr   key={1000}  style={{backgroundColor: "white",  border:'none'}} >
                                    <td  className={classes.resuld} colSpan="5" >
                                    <b style={{fontSize:"11px"}}>Total: {formatoPeru('en-US', 'USD', total)}</b>
                                    </td> 
                                </tr>
                       )} 
                      {datad.length === 0 && (
                        <tr style={{ backgroundColor: "Gainsboro" }} key={1}>
                          <td
                            colSpan="5"
                            style={{ fontSize: "12px", background: "white" }}
                          >
                            No existe Registro
                          </td>
                        </tr>
                      )}
                    </tbody>
                    {loading && (
                      <tbody>
                        <tr style={{  }}>
                          <td colSpan="5">
                            <img
                              alt="log"
                              src="https://app.iess.gob.ec/iess-fondo-reserva-afiliado-web/javax.faces.resource/images/load.gif.jsf;jsessionid=8949b76cfea43d283add3bd3dd2d?ln=sentinel-layout"
                              width="40"
                              height="40"
                            />
                          </td>
                        </tr>
                      </tbody>
                    )}
                 
                </table>
                </div>
              </div>
              <br></br>
              <br></br>
              {!loading && <></>}
              <Button
                variant="contained"
                color="primary"
                onClick={HandleClose}
                className={classes.bot}
                style={{marginTop : '-1px'}}
              >
                Cerrar
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }; /* modal content */

  return (
    <FieldSet
      className={classes.root}
      iconCls="fas fa-th-list"
      title="Resultados"
    >
      <div style={{ display: "none" }}>
        <ReactHTMLTableToExcel
          id="table-xls-button-rpt-pedidos"
          className=""
          table="table-to-xls"
          filename="Rpt_pedidos"
          sheet="Pedidos"
          buttonText=""
        />
      </div>

      <div className={classes.tableWrap}>
        <Table
          columns={columns}
          count={store.total}
          loading={loading}
          onRowClick={(items) => {}}
          onRowDoubleClick={(items) => Clicke(items)}
          onSort={(datakey) => handleRequestSort(datakey)}
          onUpdateColumn={handleResizeColumn}
          rowCount={store.data.length}
          rowGetter={(index) => store.data[index]}
          selected={store.selected}
          sortBy={orderBy}
          sortDirection={order}
          noRowsRenderer={() => <div>hola</div>}
        />
      </div>
      <div className="App">{show ? <ModalContent /> : null}</div>
    </FieldSet>
  );
} /* aprobar pedido */

export default ReporteCobranzaList;
