import {
    CLEAN_FORM,
    ERROR_OLAS_LIST,
    RECEIVE_OLAS_LIST,
    REQUEST_OLAS_LIST,
    SET_ENTITY,
    SET_MASK,
    SET_ORDER,
    SET_ERROR,
    SET_SELECTED_ROW,
    UPDATE_FIELD_FILTER,
    UPDATE_TABLE_COLUMN,
    SET_STATE_FORM,
    RESTART_STATE,
    UPDATE_FIELD_ENTITY,
    RECEIVE_V_LIST,
    ADD_PRESTACION,
    SET_ARREGLO
} from './types';

const initialState = {
    formState: 'search',
    loading: false,
    clearArreglo: false,
    data: [],
    openPopup: false,
    entity: {
        productoId: 0,
        lobId: 0,
        marcaId: 0,
        usoAceiteId: 0,
        tipoAceiteId : 0,
        nombre: '',
        viscosidad: '',
        lineaId: 0,
        certificado: '',
        dCanal: "",
        dMarca: '',
        dTipoAceite: "",
        dUsoAceite: "",
        dLinea: '',
        activo: true,
        tipoEstablecimientoId: 0,
        dTipoEstablecimiento: "",
        departamentoId:'00',
        provinciaId:'00',
        dDepartamento: '',
        dProvincia:'',
        dDistrito:'',
        distritoId:'00',
        dProducto: '',
    },
    errors: {
        nombre: '', 
        viscosidad: '',
        //lobId: '',
        marcaId: '',
        tipoAceiteId: '',
        usoAceiteId: '',
        certificado: '',
        lineaId: '',
        canalId: '',
        sw: '',
        dCanal: '',
        tipoEstablecimientoId: '',
        departamentoId:'',
        provinciaId:'',
        distritoId:'',
        productoId:'', 
        dProducto: '',   
        ubigeoId: 0
    },
    filters: {
         fecha: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
         nombre: '',
         dCanal: "",
         canalId:0,
         dLinea: "",
         lineaId:0,
         dMarca: "",
         marcaId:0,
    },
    store: {
        data: [],
        selected: [],
        aPrestaciones: [],
        pantalla: false,
        loading: false
    },
    aux: {

        agregar:false,        
        index: 0,
    },
    arreglo:{
        dataPresentacion:[]
    },

    table: {
        order: 'asc',
        orderBy: '',
        columns: [

            { dataKey: 'dTipoEstablecimiento', label: 'Establecimiento', width: 300 },
            { dataKey: 'marca', label: 'Marca', width: 100 },
            { dataKey: 'lob', label: 'LOB', width: 60 },
            { dataKey: 'producto', label: 'Producto', width: 300 },
            { dataKey: 'dUbigeo', label: 'Ubigeo', width: 300 },
            { dataKey: 'fecha', label: 'Fecha de Registro', width: 122}, 
            { dataKey: 'hora', label: 'Hora', width: 175}, 
            
        ]
    }
};

export const preciosReducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case CLEAN_FORM:
            return {
                ...state,
                entity: initialState.entity,
                errors: initialState.errors
            };
        case ERROR_OLAS_LIST:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: false
                }
            };
        case RECEIVE_OLAS_LIST:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: false,
                    data: action.payload,
                    selected: []
                },
                table: {
                    ...state.table,
                    columns: action.columns,
                }
            };
        case REQUEST_OLAS_LIST:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: true
                }
            };
        case SET_MASK:
            return {
                ...state,
                loading: action.loading
        };
        case SET_ARREGLO:
            return {
                ...state,
                clearArreglo: action.clearArreglo
        };
        case SET_ORDER:
            return {
                ...state,
                table: {
                    ...state.table,
                    order: action.order,
                    orderBy: action.orderBy
                }
            };
            case SET_ENTITY:
                return {
                    ...state,
                    entity: {
                        ...state.entity,
                        productoId: action.payload.productoId,
                        canalId: action.payload.canalId,
                        lineaId:    action.payload.lineaId,
                        lobId: 1,
                        marcaId: action.payload.marcaId,
                        usoAceiteId: action.payload.usoAceiteId ,
                        tipoAceiteId : action.payload.tipoAceiteId ,
                        nombre: action.payload.nombre ,
                        viscosidad: action.payload.viscosidad ,              
                        certificado: action.payload.certificado || '',
                        dCanal:  action.payload.dCanal,
                        dLinea: action.payload.dLinea ,
                        dMarca: action.payload.dMarca ,
                        dTipoAceite: action.payload.dTipoAceite ,
                        dUsoAceite: action.payload.dUsoAceite ,
                        //dCategoria: action.payload.dTipoAceite ,
                        //dLinea: action.payload.
                        activo: action.payload.activo,
                    }
                };
        case SET_ERROR:
                    return {
                        ...state,
                        errors: {
                            ...state.errors,
                            [action.key]: action.error
                        }
                    }
        case SET_SELECTED_ROW:
            return {
                ...state,
                store: {
                    ...state.store,
                    selected: action.selected
                }
            };
        case UPDATE_FIELD_FILTER:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.key]: action.value
                }
            };
        case UPDATE_TABLE_COLUMN:
            return {
                ...state,
                table: {
                    ...state.table,
                    columns: action.columns
                }
            };
            case SET_STATE_FORM:
                return {
                    ...state,
                    formState: action.formState
                };
            case RESTART_STATE:
                    return {
                        ...initialState
                    };
            case UPDATE_FIELD_ENTITY:
                        return { 
                            ...state,
                            entity: {
                                ...state.entity,
                                [action.key]: action.value
                            }
                        };
            case RECEIVE_V_LIST:
                        return {
                            ...state,
                            store: {
                                ...state.store,
                                loading: false,
                                total: action.total,
                                data: action.payload,
                                source: null
                            }    
                        }; 
            
            case ADD_PRESTACION:
                        console.log(action.payload)
                        return {
                            ...state,
                            store: {
                                ...state.store,
                                aPrestaciones: action.payload
                            }
                        };

        default:
            return state;
    }
}