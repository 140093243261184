import React from 'react';
import { useSelector } from "react-redux";
import { formatoPeru } from "../../../helpers/commons";
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({

  cuadron: {
    marginLeft: '5px',
  },
  resul: {
    fontSize: "10px !important",
    background: "white",
    textAlign: "Left !important",
  },
  resuld: {
    fontSize: "10px !important",
    textAlign: "Right !important",
    background: "white",
  },
  porfa: {
    "@media (max-width: 767px)": {
      height: "315px",
      width: "570px"
    },
  },
  a: {
    fontSize: "12px",
  },
  table: {

    highlight: {
      backgroundColor: 'red',
    },
    background: 'rgba(0,102,255,.25)',
    textalign: 'center',
    fontSize: "max(1 vw, 5px)",
    margin: "auto",

    '& th': {
      border: '1px solid rgba(16,76,167,.25)',
      background: 'rgba(0,102,255,.25)',
      textalign: 'center',
    },
    '& td': {
      border: '1px solid rgba(16,76,167,.25)',
    }
  },
}));

const EstadodeCuenta = () => {

  const classes = useStyles();
  const loading = useSelector((state) => state.bandejaaprobacion.loading);
  const porcobrar = useSelector((state) => state.bandejaaprobacion.store.porcobrar);
  const t = useSelector((state) => state.bandejaaprobacion.store.selectedPase);
  const modalInfo = t[0]

  let total = 0;
  const d1 = new Date();
  console.log(d1)

  return (
    <>
      {t.length > 0 && (
        <div className={classes.porfa} >
          <h2 style={{ textAlign: "center", fontSize: "12px" }}>
            Estado Cuenta Cliente
          </h2>
          <div className={classes.cuadrin} style={{ width: '95%' }} >
            <table className={classes.cua} align="center" style={{ width: '104%' }} >
              <tbody >
                <tr>
                  <td colSpan="2"  style={{ width: "10%", backgroundColor: "#BFD9FF", fontSize: "10px", fontWeight: "bold" }} >CODIGO PEDIDO:</td>
                  <td colSpan="2" style={{ width: "100%", fontSize: "10px" }}> <b>{modalInfo["Codigo"]} </b></td>
                </tr>
                <tr style={{ height: "5px", backgroundColor: "white", backColor: "white" }} ></tr>
                <tr>
                  <td style={{ width: "85px", backgroundColor: "#BFD9FF", fontSize: "10px", fontWeight: "bold" }} >NIT:</td>
                  <td colSpan="2" style={{ width: "100%", fontSize: "10px" }}> <b>{modalInfo["NIT/RUC"]} </b></td>
                </tr>
                <tr style={{ height: "5px", backgroundColor: "white", backColor: "white" }} ></tr>
                <tr>
                  <td style={{ width: "85px", backgroundColor: "#BFD9FF", fontSize: "10px", fontWeight: "bold" }}> CLIENTE:</td>
                  <td colSpan="2" style={{ width: "100%", fontSize: "10px" }}>{modalInfo.Cliente.toUpperCase()}</td>
                </tr>
                <tr style={{ height: "5px", backgroundColor: "white", backColor: "white" }} ></tr>
                <tr>
                <td style={{ width: "65px" , backgroundColor: "#BFD9FF",fontSize: "10px",fontWeight: "bold" }}> L.CREDITO:</td>
                <td colSpan="4" style={{ width: "75px",fontSize: "10px" }}>{modalInfo.LineaCredito}</td>
                </tr>
                <tr></tr>
              </tbody>
            </table>
          </div>
          <br></br>

          <div>
            <div style={{ display: "none", border: 'none' }}>
              {!loading && porcobrar.map((ite) => (total = total + ite.Saldo))}
            </div>
            <div style={{ display: 'inlineBlock', zoom: 1, width: '100%' }}>
              <div className={classes.cuadron} style={{ width: '98%', height: porcobrar.length >= 6 && "130px ", overflowX: porcobrar.length >= 6 ? "auto" : "hidden", marginTop: "-10px" }}>
                <table align="center" className="aprobacion" id="tableMod" style={{ borderCollapse: "collapse", width: "100%" }}  >
                  <thead>
                    {!loading && porcobrar.length > 0 && <tr>
                      <th > Nro. Doc</th>
                      <th > Fecha</th>
                      {/* <th > F/Compro</th>
                    <th > Comentario</th> */}
                      <th > Monto</th>
                      <th >Saldo</th>
                      <th > F.Venc</th>
                      <th > Dias Vencidos</th>
                      <th > F.Cob</th>
                      <th > Total/Cob</th>
                      <th > F.Compromiso Pago</th>
                    </tr>}
                  </thead>
                  <tbody style={{ border: "1px solid #BFD9FF" }}>
                    {!loading && porcobrar.map((item, index) => (
                      <tr key={index} style={{ color: item.SaldoVencido !== 0 && 'red' }} >
                        <td className={classes.resul}>{item.NroDocumento}</td>
                        <td className={classes.resul}>{item.Fecha}</td>
                        {/* <td className={classes.resul}>{item.FechaCompromiso}</td>
                        <td style={{width : '250px'}}>{item.Comentario}</td> */}
                        <td className={classes.resuld}>{formatoPeru('en-US', 'USD', item.Total)}</td>
                        <td className={classes.resuld}>{formatoPeru('en-US', 'USD', item.Saldo)}</td>
                        <td className={classes.resul}> {item.FechaVence}</td>
                        <td className={classes.resul}> {item.DiasVencido}</td>
                        <td className={classes.resul}> {item.CobranzaFecha}</td>
                        <td className={classes.resul}> {item.CobranzaTotal}</td>
                        <td className={classes.resul}> {item.CobranzaObservacion}</td>

                      </tr>
                    ))}

                    {porcobrar.length === 0 && (
                      <tr key={1}>
                        <span colSpan="5"  >No existe Registro</span>
                      </tr>
                    )}
                  </tbody>
                  {loading && (
                    <tbody>
                      <tr>
                        <td colSpan="9">
                          <img
                            alt="log"
                            src="https://app.iess.gob.ec/iess-fondo-reserva-afiliado-web/javax.faces.resource/images/load.gif.jsf;jsessionid=8949b76cfea43d283add3bd3dd2d?ln=sentinel-layout"
                            width="40"
                            height="40"
                          />
                        </td>
                        {/* <td colSpan="1">
                          {porcobrar.length === 0 && ( 'No existe Registrooo  '     )}
                        </td> */}
                      </tr>
                    </tbody>
                  )}

                </table>
              </div>
              {porcobrar.length > 0 && !loading && (
                <div style={{ borderCollapse: "collapse", width: "98%", fontSize: '12px', textAlign: 'right', marginTop: '4px' }}>

                  <b style={{ fontSize: "14px" }}> Total: {formatoPeru('en-US', 'USD', total)}</b>

                </div>
              )}
            </div>

          </div>
        </div>
      )}
    </>
  );
}

export default EstadodeCuenta;

