import {
    CLEAN_FORM,
    RECEIVE_RUTAS_LIST,
    SET_MASK,
    SET_ORDER,
    SET_PAGE,
    SET_PAGE_SIZE,
    UPDATE_FIELD_FILTER,
    UPDATE_TABLE_COLUMN

} from './types';

const initialState = {
    formState: 'search',
    loading: false,
    dias: [
        { key: 'vLunes', label: 'Lunes' },
        { key: 'vMartes', label: 'Martes' },
        { key: 'vMiercoles', label: 'Miércoles' },
        { key: 'vJueves', label: 'Jueves' },
        { key: 'vViernes', label: 'Viernes' },
        { key: 'vSabado', label: 'Sábado' },
        { key: 'vDomingo', label: 'Domingo' }, 
    ],
    entity: {
        rutaId: 0,
        ruta: '',
        nombre: '',
        vLunes: false,
        vMartes: false,
        vMiercoles: false,
        vJueves: false,
        vViernes: false,
        vSabado: false,
        vDomingo: false,
        frecuencia: '',
        cliente: true,
        rutaVendedorId: 0,
        vendedorId: 0,
        Vendedor: '',
        rutasCliente: [],
        todos: true,
        dSupervisor: ''
    },
    errors: {
        nombre: '',
        vendedor: '',
    },
    filters: {
        distribuidorId: 0,
        supervisorId: 0,
        dDistribuidor: '',
        dSupervisor: '',
        dVendedor: '',
        nombre: ''
    },
    store: {
        loading: false,
        pageSize: 50,
        start: 0,
        total: 0,
        data: [],
        selected: [],
        source: null
    },
    table: {
        order: 'asc',
        orderBy: '',
        columns: [
            { dataKey: 'Tipo', label: 'Tipo', width: 100 },
            { dataKey: 'Ruta', label: 'Nombre Ruta', width: 100 },
            { dataKey: 'CodigoRuta', label: 'Código Ruta', width: 100 },
            { dataKey: 'Vendedor', label: 'Nombre Vendedor', width: 150 },
            { dataKey: 'NIT', label: 'NIT', width: 100 },
            { dataKey: 'NombreComercial', label: 'Nombre Cliente', width: 250 },
            { dataKey: 'Frecuencia', label: 'Frecuencia', width: 70 },
            { dataKey: 'Lunes', label: 'Lunes', type: 'boolean', width: 55 },
            { dataKey: 'Martes', label: 'Martes', type: 'boolean', width: 55 },
            { dataKey: 'Miercoles', label: 'Miércoles', type: 'boolean', width: 55 },
            { dataKey: 'Jueves', label: 'Jueves', type: 'boolean', width: 55 },
            { dataKey: 'Viernes', label: 'Viernes', type: 'boolean', width: 55 },
            { dataKey: 'Sabado', label: 'Sábado', type: 'boolean', width: 55 },
            
            { dataKey: 'Sucursal', label: 'Sucursal', width: 165 },
            { dataKey: 'Codigo', label: 'Cod Sucursal', width: 85 },
            { dataKey: 'Direccion', label: 'Dirección', width: 365 },
            { dataKey: 'Departamento', label: 'Departamento', width: 265 },
            { dataKey: 'Distrito', label: 'Distrito', width: 265 },
            { dataKey: 'Latitud', label: 'Latitud', width: 110 },
            { dataKey: 'Longitud', label: 'Longitud', width: 110 },
            { dataKey: 'Correo', label: 'Correo', width: 265 },
            { dataKey: 'Telefono', label: 'Teléfono', width: 75 },
            { dataKey: 'Canal', label: 'Canal', width: 65 },
            { dataKey: 'Segmento', label: 'Segmento', width: 210 },
            { dataKey: 'Categoria', label: 'Categoría', width: 210 },
            { dataKey: 'FechaAlta', label: 'FechaAlta', width: 110}
        ]
    }
};

export const clientesvendedorReducer = (state, action) => {
    state = state || initialState;

    switch(action.type) {
        case CLEAN_FORM:
            return {
                ...state,
                entity: initialState.entity,
                errors: initialState.errors
            };
        case RECEIVE_RUTAS_LIST:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: false,
                    total: action.total,
                    data: action.payload,
                    source: null
                }
            };
        case SET_MASK:
            return {
                ...state,
                loading: action.loading
            };
        case SET_ORDER:
            return {
                ...state,
                table: {
                    ...state.table,
                    order: action.order,
                    orderBy: action.orderBy
                }
            };
        case SET_PAGE:
            return {
                ...state,
                store: {
                    ...state.store,
                    start: state.store.pageSize*action.page
                }
            };
        case SET_PAGE_SIZE:
            return {
                ...state,
                store: {
                    ...state.store,
                    pageSize: action.pageSize
                }
            };
        case UPDATE_FIELD_FILTER:
            return { 
                ...state,
                filters: {
                    ...state.filters,
                    [action.key]: action.value
                }
            };
        case UPDATE_TABLE_COLUMN:
            return {
                ...state,
                table: {
                    ...state.table,
                    columns: action.columns
                }
            };
        default:
            return state;
    }
}