import {
    RECEIVE_SESSION,
    SET_AVATAR,
    SET_DATA_USER
} from './types';

const initialState = {
    accessToken: '',
    expiresIn: 0,
    idToken: '',
    tokenType: '',
    user: {
        usuario: '',
        nombre: '',
        avatar: ''
    }
};

export const sessionReducer = (state, action) => {
    state = state || initialState;

    switch(action.type) {
        case RECEIVE_SESSION:
            return {
                ...state,
                accessToken: action.accessToken,
                expiresIn: action.expiresIn,
                idToken: action.id_token,
                tokenType: action.tokenType
            };
        case SET_AVATAR:
            return {
                ...state,
                user: {
                    ...state.user,
                    avatar: action.avatar
                }
            };
        case SET_DATA_USER:
            return {
                ...state,
                user: {
                    ...state.user,
                    usuario: action.usuario,
                    nombre: action.nombre
                }
            };
        default:
            return state;
    }

};