import {
    CLEAN_FORM,
    SET_STATE_FORM,
    UPDATE_FIELD_FILTER,
    SET_MASK,
    RECEIVE_DATA,
    UPDATE_TABLE_COLUMN,
    ERROR_V_LIST,
    RECEIVE_V_LIST,
    REQUEST_V_LIST,
    SET_ORDER
} from './types';

const initialState = {
    formState: 'search',
    loading: false,
    entity: {
        vendedorId: 0,
        nombre: ''
    },
    errors: {
        codigo: '',
        usuario: '',
        documentoIdentidad: ''
    },
    filters: {
        nombre: '',
        distribuidorId: 0,
        dDistribuidor: '',
        codigo: ''
    },
    store: {
        loading: false,
        pageSize: 50,
        start: 0,
        total: 0,
        data: [],
        selected: []
    },
    table: {
        order: 'asc',
        orderBy: '',
        columns: [
      
            { dataKey: 'codigo', label: 'Código', width: 90 },
            { dataKey: 'codigoFabrica', label: 'Código de Fábrica', width: 100 },
            { dataKey: 'dProducto', label: 'Descripción del Producto', width: 340 },
            { dataKey: 'escala', label: 'Escala', width: 60 },
            { dataKey: 'cantidad', label: 'Stock', width: 60 },
            { dataKey: 'cantidadS', label: 'Cantidad/Unidad', width: 100 },
           // { dataKey: 'precioEstandar', label: 'Precio Estandar', width: 100},
           // { dataKey: 'precioVariable' ,  label: 'Precio Variable',  width: 100},
            { dataKey: 'almacen' ,  label: 'Almacén',  width: 145},
          //  { dataKey: 'fechaRegistro', label: 'Fecha de Registro', width: 150 },
           // { dataKey: 'precioId', label: 'PrecioId', width: 150 },
           // { dataKey: 'productoId', label: 'ProductoId', width: 150 },

        ]
    }
};

export const preciostockReducer = (state, action) => {
    state = state || initialState;

    switch(action.type) {
        case CLEAN_FORM:
            return {
                ...state,
                entity: initialState.entity,
                errors: initialState.errors
            };
        case SET_STATE_FORM:
            return {
                ...state,
                formState: action.formState
            };
        case UPDATE_FIELD_FILTER:
            return { 
                ...state,
                filters: {
                    ...state.filters,
                    [action.key]: action.value
                }
            };
            case ERROR_V_LIST:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: false
                }
            };
            case RECEIVE_V_LIST:
                return {
                    ...state,
                    store: {
                        ...state.store,
                        loading: false,
                        total: action.total,
                        data: action.payload,
                        source: null
                    }
                };
            case REQUEST_V_LIST:
                return {
                    ...state,
                    store: {
                        ...state.store,
                        loading: false,
                        source: action.source
                    }
                };
            case RECEIVE_DATA:
                return {
                    ...state,
                    data: action.payload,
                    store: {
                        ...state.store,
                        data: action.storeData
                    }
                };
                case UPDATE_TABLE_COLUMN:
                    return {
                        ...state,
                        table: {
                            ...state.table,
                            columns: action.columns
                        }
                    };
            case SET_MASK:
                return {
                    ...state,
                    loading: action.loading
            };
            case SET_ORDER:
                return {
                    ...state,
                    table: {
                        ...state.table,
                        order: action.order,
                        orderBy: action.orderBy
                    }
                };
        default:
            return state;
    }
};