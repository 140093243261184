import axios from 'axios';
import { IdentityUrl } from '../../../config';
import { closeAdvancedSearch, closeMessageBox, openAdvancedSearch, openMessageBox } from '../../main/actions';
import {
    ERROR_OLAS_LIST,
    RECEIVE_OLAS_LIST,
    //REQUEST_OLAS_LIST,
    RESTART_STATE,
    SET_MASK,
    SET_ORDER,
    SET_SELECTED_ROW,
    UPDATE_FIELD_FILTER,
    UPDATE_TABLE_COLUMN
} from './types';

export const onExport = () => async (dispatch, getState) => {
    try {
      
        if(getState().tracking.store.data.length === 0) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'warning', 
                message: 'No hay información para exportar.', 
                callback: () => dispatch(closeMessageBox())
            }));
            return;
        }

        dispatch({ type: SET_MASK, loading: true });

       const data = getState().tracking.store.data;

       const JSONData = data;
    
         //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
        let arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;

        let  CSV = '';
    //Set Report title in first row or line
        let ReportTitle = "";
        CSV += ReportTitle //+ '\r\n\n';
        var row = "";

        //This loop will extract the label from 1st index of on array
        for (var index in arrData[0]) {

            //Now convert each value to string and comma-seprated
            row += index + ',';
        }

        row = row.slice(0, -1);

        //append Label row with line break
        CSV += row + '\r\n';

         //1st loop is to extract each row
        for (var i = 0; i < arrData.length; i++) {
            let  row = "";

            //2nd loop will extract each column and convert it in string comma-seprated
            for (var index2 in arrData[i]) {
                row += '"' + arrData[i][index2] + '",';
            }

            row.slice(0, row.length - 1);

            //add a line break after each row
            CSV += row + '\r\n';
        }

        //let csvHref = window.URL.createObjectURL(new Blob([jsonArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }) );
        var csvHref = 'data:text/csv;charset=utf-8,' + escape(CSV);   
        let link = document.createElement('a');

        link.href = csvHref;
        link.setAttribute('download', 'tracking.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'error', 
            message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
            callback: () => dispatch(closeMessageBox())
        }));
    } finally {
        setTimeout(() => {dispatch({ type: SET_MASK, loading: false })}, 1000);
    }
};

export const  btnSearch = () => async (dispatch, getState) => {
    if (getState().tracking.store.loading) {
        return;
    }
    
    dispatch({ type: SET_MASK, loading: true });
    try {

     let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Vendedores/listarpaislogin` 
        let response = await axios.get(encodeURI(url));
        let data = response.data;
       //setPais(data[0].Pais)

        dispatch(onUpdateFieldFilter('pais',data[0].Pais));

        // const fil =  getState().tracking.filters.track

   
    } catch (error) {
        let msgError = '';
        dispatch({ type: SET_MASK, loading: false });
        dispatch({ type: ERROR_OLAS_LIST });

        if (error.response) {
        
         if(error.response.status === 400) {
                msgError = (error.response.data.eventLogId === 0 ? 'No existe Registro  para esta \nfecha  ' : `EventoId: ${error.response.data.eventLogId}. `+ error.response.data.message) 
         }else{
            msgError = (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message;
         }
        }
        else {
            msgError = 'Error al conectar con el servidor';
        }

        dispatch(openMessageBox({
            button: 'ok',
            icon: 'error',
            message: msgError,
            callback: () => dispatch(closeMessageBox())
        }));
    }finally{
        dispatch({ type: SET_MASK, loading: false });
    }
};

export const onUpdateColumn = (column) => (dispatch, getState) => {
    let columns = getState().olas.table.columns.map(item => {
        //console.log(item);
        //console.log(column)
        if(item.dataKey !== column.dataKey) {
            return item;
        }

        return {
            ...item,
            width: column.width
        };
    });

    dispatch({ type: UPDATE_TABLE_COLUMN, columns });
};


export const onSearchDistribuidorClick = () => async (dispatch) => {
    let fields = [
        { 'dataKey': 'razonSocial', 'label': 'Agente', 'type': 'string', 'width': 225 }
    ];

    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de agente',
        getData: async (params) => {
            let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`
            let response = await axios.get(encodeURI(url), { params: params });

            return response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldFilter('distribuidorId', result[0].distribuidorId));
                dispatch(onUpdateFieldFilter('dDistribuidor', `${result[0].razonSocial}`));
                dispatch(onUpdateFieldFilter('supervisorId', ''));
                dispatch(onUpdateFieldFilter('dSupervisor', ''));
                dispatch(onUpdateFieldFilter('vendedorId', ''));
                dispatch(onUpdateFieldFilter('dVendedor', ''));
            }
        }
    }));
};

export const onSearchSupervisorClick = () => async (dispatch, getState) => {
    let fields = [
        { 'dataKey': 'nombre', 'label': 'Nombre', 'type': 'string', 'search': true, 'width': 225 },
        { 'dataKey': 'nombreUsuario', 'label': 'Usuario', 'type': 'string', 'width': 115 }
    ];

    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de supervisor',
        getData: async (params) => {
            let url = `${IdentityUrl}/api/v1/usuarios?rol=Supervisor`
            let response = await axios.get(encodeURI(url), { params: params });
            let distribuidorId = getState().tracking.filters.distribuidorId;

            if(distribuidorId > 0) {
                response.data.data = response.data.data.filter(f => f.distribuidorId === distribuidorId);
            }

            return response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldFilter('supervisorId', result[0].usuarioId));
                dispatch(onUpdateFieldFilter('dSupervisor', `${result[0].nombre}`));
                dispatch(onUpdateFieldFilter('vendedorId', ''));
                dispatch(onUpdateFieldFilter('dVendedor', ''));
            }
        }
    }));
};

export const onSearchUbigeoClick = () => async (dispatch, getState) => {
    let fields = [
        { 'dataKey': 'codigo', 'label': 'Código', 'type': 'string', 'width': 115 },
        { 'dataKey': 'dUbigeo', 'label': 'Ubigeo', 'type': 'string', 'search': true, 'width': 225 }
    ];

    dispatch(openAdvancedSearch({
        autoLoad: false,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de ubigeo',
        getData: async (params) => {
            let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/agentes/ubigeos`;
            let response = await axios.get(encodeURI(url), { params: params });
            return response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                //console.log(result[0])
                dispatch(onUpdateFieldFilter('ubigeoId', result[0].ubigeoId));
                dispatch(onUpdateFieldFilter('dUbigeo', `${result[0].dUbigeo}`));
            }
        }
    }));
};

export const onSearchVendedorClick = () => async (dispatch, getState) => {
    let fields = [
        { 'dataKey': 'nombre', 'label': 'Nombre', 'type': 'string', 'search': true, 'width': 225 },
        { 'dataKey': 'nombreUsuario', 'label': 'Usuario', 'type': 'string', 'width': 115 }
    ];

    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de vendedor',
        getData: async (params) => {
            let url = `${IdentityUrl}/api/v1/usuarios/porjerarquia?usuarioId=${getState().tracking.filters.supervisorId}`
            let response = await axios.get(encodeURI(url), { params: params });
            let distribuidorId = getState().tracking.filters.distribuidorId;    

            if(distribuidorId > 0) {
                response.data.data = response.data.data.filter(f => f.distribuidorId === distribuidorId);
            }
            response.data.data.push({
                activo: true,
               usuarioId: '',
                ruc: "",
                nombre: "TODOS",
                nombreusuario: ''})
                response.data.data.sort((a, b) => (a.usuarioId > b.usuarioId) ? 1 : -1)
            return response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
             
                dispatch(onUpdateFieldFilter('vendedorId', result[0].usuarioId));
                dispatch(onUpdateFieldFilter('dVendedor', `${result[0].nombre}`));
                dispatch({ type: RECEIVE_OLAS_LIST, columns: getState().tracking.table.columns, payload: [] });
                if (result[0].usuarioId === ''){
                    dispatch(onUpdateFieldFilter('sw', true));
                    dispatch(onUpdateFieldFilter('track','U'));
                }else{
                    dispatch(onUpdateFieldFilter('sw', false));
                }
            }
        }
    }));
};

/*
export const openDialogClient = (data) => (dispatch, getState) => {
    try {
        var storeData = getState().reportevisitas.data.filter(f => f.dVendedor === data.dVendedor);
        
        dispatch({ type: SHOW_POPUP_CLIENT, storeData });
    } catch (error) {
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'error', 
            message: error, 
            callback: () => dispatch(closeMessageBox())
        }));
    }
};
*/

export const onUpdateFieldFilter = (key, value) => (dispatch, getState) => {
    //({ type: UPDATE_FIELD_FILTER, key, value });
    dispatch({ type: UPDATE_FIELD_FILTER, key, value })
   // dispatch({ type: RECEIVE_OLAS_LIST, columns:[], payload: [] })
    };

    export const onUpdateBlank = (key, value) => (dispatch, getState) => {
        dispatch({ type: RECEIVE_OLAS_LIST, columns:[], payload: [] })
        }

export const UpdateField = (key, value) => (dispatch, getState) => {
        dispatch({ type: UPDATE_FIELD_FILTER, key, value })
        //dispatch({ type: RECEIVE_OLAS_LIST, columns:[], payload: [] })
        };   

export const restartState = () => ({ type: RESTART_STATE });

export const setOrder = (order, orderBy) => ({ type: SET_ORDER, order, orderBy });

export const setSelectedRow = (selected) => ({ type: SET_SELECTED_ROW, selected});
