import axios from "axios";
import format from "date-fns/format";
import { IdentityUrl } from "../../../config";
import { push } from "connected-react-router";
import { closeMessageBox, openMessageBox } from "../../main/actions";

import {
    CLEAN_FORM,
    CLEAN_DATA2,
    UPDATE_FIELD_FILTER,
    ERROR_V_LIST,
    RECEIVE_V_LIST,
    RECEIVE_V_LIST2,
    REQUEST_V_LIST,
    SET_PAGE,
    SET_SELECTED_ROW,
    SET_MASK,
    SET_ORDER,
    SET_STATE_FORM,
    ADVANCED_SEARCH_CLOSE,
    ADVANCED_SEARCH_OPEN,
    RESTART_STATE,
    SET_ENTITY,
    SET_ERROR,
    UPDATE_FIELD_ENTITY,
    RECEIVE_SUB_DETALLE,
} from "./types";

import { exportToSpreadsheet, cortarArreglo } from "../../../helpers/commons";

export const setPage = (page) => ({ type: SET_PAGE, page });

export const cleanForm = () => ({ type: CLEAN_FORM });

export const setSelectedRow = (selected) => ({
    type: SET_SELECTED_ROW,
    selected,
});

export const btnSearch = () => async (dispatch, getState) => {
    if (getState().gestionbonificacion.store.loading) {
        return;
    }

    if (getState().gestionbonificacion.filters.distribuidorId === 0) {
        dispatch(
            openMessageBox({
                button: "ok",
                icon: "warning",
                message: "Debe seleccionar un Agente..",
                callback: () => dispatch(closeMessageBox()),
            })
        );
        return;
    }

    dispatch({ type: SET_MASK, loading: true });

    let source = axios.CancelToken.source();
    dispatch({ type: REQUEST_V_LIST, source });

    try {
        let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Productos/fuerzaventas/listarbonificaciones?` +
            `distribuidorId=${ getState().gestionbonificacion.filters.distribuidorId}` +
            `&desde=${format(getState().gestionbonificacion.filters.desde,"yyyy-MM-dd" )}` +
            `&hasta=${format(getState().gestionbonificacion.filters.hasta,"yyyy-MM-dd")}` +
            `&nombre=${getState().gestionbonificacion.filters.nombre}`;

        let response = await axios.get(encodeURI(url));
        let data = response.data;
        //console.log(data);

        if (data.length === 0) {
            dispatch(
                openMessageBox({
                    button: "ok",
                    icon: "warning",
                    message: "No existe Registro  para esta consulta",
                    callback: () => dispatch(closeMessageBox()),
                })
            );
            return;
        }

        let columnas = getState().gestionbonificacion.table.columns;

        dispatch({ type: RECEIVE_V_LIST, columns: columnas, payload: data });
    } catch (error) {
        let msgError = "";
        dispatch({ type: ERROR_V_LIST });
        dispatch({ type: SET_MASK, loading: false });

        if (error.response) {
            msgError =
                error.response.data.eventLogId === 0
                    ? "No existe Registro  para esta consulta "
                    : `EventoId: ${error.response.data.eventLogId}. ` +
                      error.response.data.message;
        } else {
            msgError = "Error al conectar con el servidor";
        }

        dispatch(
            openMessageBox({
                button: "ok",
                icon: "error",
                message: msgError,
                callback: () => dispatch(closeMessageBox()),
            })
        );
    } finally {
        dispatch({ type: SET_MASK, loading: false });
    }
};

//======================
export const onExport = () => async (dispatch, getState) => {
    // no se usa otra forma de exportar

    if (getState().gestionbonificacion.filters.distribuidorId === 0) {
        dispatch(
            openMessageBox({
                button: "ok",
                icon: "warning",
                message: "Debe seleccionar un Agente.",
                callback: () => dispatch(closeMessageBox()),
            })
        );
        return;
    }

    if (getState().gestionbonificacion.store.data.length === 0) {
        dispatch(
            openMessageBox({
                button: "ok",
                icon: "warning",
                message: "No hay información para exportar.",
                callback: () => dispatch(closeMessageBox()),
            })
        );
        return;
    }

    try {
        dispatch({ type: SET_MASK, loading: true });
        let head = getState().gestionbonificacion.tablexport.columns;
        let json = getState().gestionbonificacion.store.data;

        exportToSpreadsheet(
            cortarArreglo(head, json),
            "Gestion de Bonificacion"
        );
    } catch (error) {
        dispatch(
            openMessageBox({
                button: "ok",
                icon: "error",
                message:
                    (error.response.data.eventLogId === 0
                        ? ""
                        : `EventoId: ${error.response.data.eventLogId}. `) +
                    error.response.data.message,
                callback: () => dispatch(closeMessageBox()),
            })
        );
    } finally {
        setTimeout(() => {
            dispatch({ type: SET_MASK, loading: false });
        }, 1000);
    }
};
//=====================

export const getUser = (bonificacionId) => async (dispatch, getState) => {
    try {
        dispatch({ type: SET_MASK, loading: true });

        let aux = getState().gestionbonificacion.store.aux;
        let payload = {
            ...aux,
        };

       // console.log("Bonificacion aux", aux);

        dispatch({ type: SET_ENTITY, payload });
    } catch (error) {
        if (error.response.status === 404) {
            dispatch(
                openMessageBox({
                    button: "ok",
                    icon: "error",
                    message: "No se encontro el registro solicitado.",
                    callback: () => {
                        dispatch(closeMessageBox());
                        dispatch(
                            push({
                                pathname: `/fuerzaventas/gestionbonificacion`,
                                search: getState().router.location.search,
                                hash: getState().router.location.hash,
                            })
                        );
                    },
                })
            );
        } else {
            dispatch(
                openMessageBox({
                    button: "ok",
                    icon: "error",
                    message:
                        (error.response.data.eventLogId === 0
                            ? ""
                            : `EventoId: ${error.response.data.eventLogId}. `) +
                        error.response.data.message,
                    callback: () => dispatch(closeMessageBox()),
                })
            );
        }
    } finally {
        dispatch({ type: SET_MASK, loading: false });
    }
};

export const setFormState = (formState, id) => (dispatch, getState) => {
    switch (formState) {
        case "search":
            dispatch(cleanForm());
            break;
        case "edit":
            if (
                id &&
                id !== getState().gestionbonificacion.entity.bonificacionId
            ) {
                dispatch(getUser(id));
            }
            break;
        case "view":
            if (
                id &&
                id !== getState().gestionbonificacion.entity.bonificacionId
            ) {
                dispatch(getUser(id));
            }
            break;
        default:
            break;
    }
    dispatch({ type: SET_STATE_FORM, formState });
};

export const onSearchDistribuidorClick = () => async (dispatch) => {
    let fields = [
        { dataKey: "razonSocial", label: "Agente", type: "string", width: 225 },
    ];

    dispatch(
        openAdvancedSearch({
            autoLoad: true,
            data: [],
            fields: fields,
            paginated: true,
            remote: true,
            title: "Selección de agente",
            getData: async (params) => {
                let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`;
                let response = await axios.get(encodeURI(url), {
                    params: params,
                });

                return response.data;
            },
            callback: (btn, result) => {
                dispatch(closeAdvancedSearch());
                if (btn === "yes") {
                    dispatch(
                        onUpdateFieldFilter(
                            "distribuidorId",
                            result[0].distribuidorId
                        )
                    );
                    dispatch(
                        onUpdateFieldFilter(
                            "dDistribuidor",
                            `${result[0].razonSocial}`
                        )
                    );
                    dispatch(onUpdateFieldFilter("codigoLista", 0));
                    dispatch(onUpdateFieldFilter("dLista", ""));
                    dispatch(onUpdateFieldFilter("nombre", ""));
                }
            },
        })
    );
};

export const onImport = () => async (dispatch, getState) => {
    dispatch({ type: SET_MASK, loading: true });

    if (getState().gestionbonificacion.filters.distribuidorId === 0) {
        dispatch(
            openMessageBox({
                button: "ok",
                icon: "warning",
                message: "Debe seleccionar un Distribuidor.",
                callback: () => dispatch(closeMessageBox()),
            })
        );
        dispatch({ type: SET_MASK, loading: false });
        return;
    }

    if (getState().gestionbonificacion.stored.data.length === 0) {
        dispatch(
            openMessageBox({
                button: "ok",
                icon: "warning",
                message: "No hay información para importar.",
                callback: () => dispatch(closeMessageBox()),
            })
        );
        dispatch({ type: SET_MASK, loading: false });
        return;
    }

    let aux = getState().gestionbonificacion.stored.data;


    let col = getState().gestionbonificacion.store.col;
   
 
    for (var c in aux) {
        let aux2 = Object.keys(aux[c]).length;
        if (aux2 < col.length) {
            dispatch(
                openMessageBox({
                    button: "ok",
                    icon: "warning",
                    message:
                        "EL Archivo seleccionado no debe tener espacio en blanco .",
                    callback: () => dispatch(closeMessageBox()),
                })
            );

            dispatch({ type: SET_MASK, loading: false });
            dispatch({ type: RECEIVE_V_LIST2, columns: [], payload: [] });
            dispatch({ type: CLEAN_DATA2 });
            return;
        }
    }

    var recordset = {
        distribuidorId: getState().gestionbonificacion.filters.distribuidorId,
        bonificacionCargaMasiva: [],
    };

    for (var c in aux) {
        let json = {
           // nit: aux[c].nit.toString(),
            codigoSucursal: aux[c].codigoSucursal.toString(),
            codigo: aux[c].codigo.toString(),
            nombre: aux[c].nombre.toString(),
            tipo: aux[c].tipo.toString(),
            codigoProductoCompra: aux[c].codigoProductoCompra.toString(),
            cantidadCompra: aux[c].cantidadCompra.toString(),
            cantidadCompraS: aux[c].cantidadCompraS.toString(),
            codigoProductoRegala: aux[c].codigoProductoRegala.toString(),
            cantidadRegala: aux[c].cantidadRegala.toString(),
            cantidadRegalaS: aux[c].cantidadRegalaS.toString(),
            fechaHasta: aux[c].fechaHasta.toString(),
        };

        recordset.bonificacionCargaMasiva.push(json);
    }


    try {
        
        let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Productos/cargamasivaBonificacion`;
        let response = await axios.post(url, recordset, {headers: { "Content-Type": "application/json" },});
        let data = response.data;


        dispatch(
            openMessageBox({
                button: "ok",
                icon: "info",
                message: "Registro realizado con exito.",
                callback: () => {
                    dispatch(closeMessageBox());
                    dispatch(btnSearch());
                    dispatch(
                        push({
                            pathname: `/fuerzaventas/gestionbonificacion`,
                            search: getState().router.location.search,
                            hash: getState().router.location.hash,
                        })
                    );
                    dispatch({ type: RECEIVE_V_LIST2, columns: [], payload: [] });
                },
            })
        );
        
    } catch (error) {
        dispatch({ type: SET_MASK, loading: false });
        dispatch(
            openMessageBox({
                button: "ok",
                icon: "error",
                message:
                    (error.response.data.eventLogId === 0
                        ? ""
                        : `EventoId: ${error.response.data.eventLogId}. `) +
                    error.response.data.message,
                callback: () => dispatch(closeMessageBox()),
            })
        );
    } finally {
        //

        dispatch(
            push({
                pathname: `/fuerzaventas/gestionbonificacion`,
                search: getState().router.location.search,
                hash: getState().router.location.hash,
            })
        );
        return true;
    }
};

export const onListSucursales = (bonificacionId) => async (dispatch, getState) => {
    try {
      dispatch({ type: SET_MASK, loading: true });
  
      if (getState().gestionbonificacion.filters.distribuidorId === 0) {
        dispatch(
          openMessageBox({
            button: "ok",
            icon: "warning",
            message: "Debe seleccionar un Agente..",
            callback: () => dispatch(closeMessageBox()),
          })
        );
        return;
      }
  
      let url =`${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Productos/fuerzaventas/sucursalesbonificacion?bonificacionId=
      ${bonificacionId}` ;
      //console.log("bonificacionId: "+bonificacionId)
      let response = await axios.get(encodeURI(url));
      let datad = response;
      //console.log("sucursales: "+ datad.data)

      dispatch({ type: RECEIVE_SUB_DETALLE,  columns: [], payload: datad.data });

    } catch (error) {
        let msgError = "";
        dispatch({ type: ERROR_V_LIST });
        dispatch({ type: SET_MASK, loading: false });

        if (error.response) {
            msgError =
                error.response.data.eventLogId === 0
                    ? "No existe Registro  para esta consulta "
                    : `EventoId: ${error.response.data.eventLogId}. ` +
                      error.response.data.message;
        } else {
            msgError = "Error al conectar con el servidor";
        }

        dispatch(
            openMessageBox({
                button: "ok",
                icon: "error",
                message: msgError,
                callback: () => dispatch(closeMessageBox()),
            })
        );
    } finally {
        dispatch({ type: SET_MASK, loading: false });
    }
}
  

export const btnDeactivate = () => (dispatch) => {
    dispatch(
        openMessageBox({
            button: "yesno",
            icon: "question",
            message: "Se desactivará el registro. ¿Desea continuar?",
            callback: (btn) => dispatch(onDeactivate(btn)),
        })
    );
};

export const onDeactivate = (btn) => async (dispatch, getState) => {
    dispatch(closeMessageBox());


    let bonificacionId = getState().gestionbonificacion.entity.bonificacionId;
    //let nit = getState().gestionbonificacion.entity.nit;
    //let codigoSucursal = getState().gestionbonificacion.entity.codigoSucursal;
    //let sucursalId = getState().gestionbonificacion.entity.sucursalId;
    let distribuidorId = getState().gestionbonificacion.filters.distribuidorId;
    let sucursalesStore = getState().gestionbonificacion.store.subdetalle;
    let sucursales = getState().gestionbonificacion.entity.sucursales;
    let band = sucursalesStore.length === sucursales.length ? 1 : 0;
    // if ((nit = "undefined")) {
    //     nit = "0000000000";
    // }
    
    //console.log("bonificacionId: "+ bonificacionId)
    //console.log("distribuidorId: "+ distribuidorId)
    //console.log("store: " + sucursalesStore)
    //console.log("entity: " + sucursales)
    //console.log("band: "+ band)

    if (sucursales.length === 0) {
        dispatch(
          openMessageBox({
            button: "ok",
            icon: "warning",
            message: "Debe seleccionar al menos una sucursal a desactivar..",
            callback: () => dispatch(closeMessageBox()),
          })
        );
        return;
      }

    if (btn === "yes") {
        try {
            dispatch({ type: SET_MASK, loading: true });

            let recordset = {
                bonificacionId: bonificacionId,
                distribuidorId: distribuidorId,
            //    nit: nit.toString(),
            //    codigoSucursal: codigoSucursal.toString(),
            //    sucursalId:sucursalId,
                sucursales: sucursales,
                band: band
            };

            let url = `${
                getState().main.resources.uriSvcSalesMobilityAPI
            }api/v1/Productos/desactivarBonificacion`;
            await axios.delete(url, {
                data: recordset,
                headers: { "Content-Type": "application/json" },
            });

            dispatch(
                openMessageBox({
                    button: "ok",
                    icon: "info",
                    message: "El registro se desactivó correctamente.",
                    callback: () => {
                        dispatch(closeMessageBox());
                        dispatch(
                            push({
                                pathname: `/fuerzaventas/gestionbonificacion`,
                                search: getState().router.location.search,
                                hash: getState().router.location.hash,
                            })
                        );
                        dispatch(btnSearch());
                    },
                })
            );
        } catch (error) {
            dispatch(
                openMessageBox({
                    button: "ok",
                    icon: "error",
                    message:
                        (error.response.data.eventLogId === 0
                            ? ""
                            : `EventoId: ${error.response.data.eventLogId}. `) +
                        error.response.data.message,
                    callback: () => dispatch(closeMessageBox()),
                })
            );
        } finally {
            dispatch({ type: SET_MASK, loading: false });
        }
    }
};

export const btnSave = () => (dispatch, getState) => {
    if (getState().gestionbonificacion.entity.producto === "") {
        dispatch({
            type: SET_ERROR,
            key: "producto",
            error: "Este campo es requerido",
        });
    }

    if (getState().gestionbonificacion.entity.bonificacion === "") {
        dispatch({
            type: SET_ERROR,
            key: "bonificacion",
            error: "Este campo es requerido",
        });
    }

    if (getState().gestionbonificacion.entity.tipo === "") {
        dispatch({
            type: SET_ERROR,
            key: "tipo",
            error: "Este campo es requerido",
        });
    }

    if (getState().gestionbonificacion.entity.desde === "") {
        dispatch({
            type: SET_ERROR,
            key: "desde",
            error: "Este campo es requerido",
        });
    }

    if (getState().gestionbonificacion.entity.hasta === "") {
        dispatch({
            type: SET_ERROR,
            key: "hasta",
            error: "Este campo es requerido",
        });

        if (
            getState().gestionbonificacion.errors.producto !== "" ||
            getState().gestionbonificacion.errors.bonificacion !== "" ||
            getState().gestionbonificacion.errors.tipo !== "" ||
            getState().gestionbonificacion.errors.desde !== "" ||
            getState().gestionbonificacion.errors.hasta !== ""
        ) {
            return;
        }
    }

    dispatch(
        openMessageBox({
            button: "yesno",
            icon: "question",
            message: "¿Desea guardar la información?",
            callback: (btn) => {
                if (
                    getState().gestionbonificacion.entity.bonificacionId === 0
                ) {
                    dispatch(onSave(btn));
                } else {
                    dispatch(onUpdate(btn));
                }
                return;
            },
        })
    );
};

export const onSave = (btn) => async (dispatch, getState) => {
    dispatch(closeMessageBox());

    if (btn === "yes") {
        //console.log("guardar bonificacion");
        // try {
        //     dispatch({ type: SET_MASK, loading: true });

        //     let actualizarBonificacion = {
        //         ...getState().gestionbonificacion.entity,
        //     };

        //     let url = `${
        //         getState().main.resources.uriSvcSalesMobilityAPI
        //     }/api/v1/Tomas/crearproducto`;

        //     let response = await axios.post(url, actualizarBonificacion, {
        //         headers: { "Content-Type": "application/json" },
        //     });
        //     //let data = response.data;

        //     dispatch(
        //         openMessageBox({
        //             button: "ok",
        //             icon: "info",
        //             message: "Registro realizado con éxito.",
        //             callback: () => {
        //                 dispatch(closeMessageBox());
        //                 dispatch(
        //                     push({
        //                         pathname: `/fuerzaventas/gestionbonificacion`,
        //                         search: getState().router.location.search,
        //                         hash: getState().router.location.hash,
        //                     })
        //                 );
        //             },
        //         })
        //     );
        // } catch (error) {
        //     dispatch(
        //         openMessageBox({
        //             button: "ok",
        //             icon: "error",
        //             message:
        //                 (error.response.data.eventLogId === 0
        //                     ? ""
        //                     : `EventoId: ${error.response.data.eventLogId}. `) +
        //                 error.response.data.message,
        //             callback: () => dispatch(closeMessageBox()),
        //         })
        //     );
        // } finally {
        //     dispatch({ type: SET_MASK, loading: false });
        // }
    }
};

export const onUpdate = (btn) => async (dispatch, getState) => {
    dispatch(closeMessageBox());

    if (btn === "yes") {
       // console.log("actualizar bonificacion");
        // try {
        //     dispatch({ type: SET_MASK, loading: true });

        //     let actualizarBonificacion = {
        //         ...getState().gestionbonificacion.entity,
        //     };
        //     let url = `${
        //         getState().main.resources.uriSvcSalesMobilityAPI
        //     }api/v1/Tomas/actualizarproducto`;
        //     let response = await axios.put(url, actualizarBonificacion, {
        //         headers: { "Content-Type": "application/json" },
        //     });
        //     let data = response.data;

        //     dispatch(
        //         openMessageBox({
        //             button: "ok",
        //             icon: "info",
        //             message: "Modificación realizado con éxito.",
        //             callback: () => {
        //                 dispatch(closeMessageBox());
        //                 dispatch(
        //                     push({
        //                         pathname: `/fuerzaventas/gestionbonificacion/${
        //                             getState().gestionbonificacion.entity
        //                                 .bonificacionId
        //                         }`,
        //                         search: getState().router.location.search,
        //                         hash: getState().router.location.hash,
        //                     })
        //                 );
        //                 dispatch(getUser(data));
        //             },
        //         })
        //     );
        // } catch (error) {
        //     if (error.response.status === 404) {
        //         dispatch(
        //             openMessageBox({
        //                 button: "ok",
        //                 icon: "error",
        //                 message: "No se encontró el registro solicitado.",
        //                 callback: () => {
        //                     dispatch(closeMessageBox());
        //                     dispatch(
        //                         push({
        //                             pathname: `/usuarios`,
        //                             search: getState().router.location.search,
        //                             hash: getState().router.location.hash,
        //                         })
        //                     );
        //                 },
        //             })
        //         );
        //     } else {
        //         dispatch(
        //             openMessageBox({
        //                 button: "ok",
        //                 icon: "error",
        //                 message:
        //                     (error.response.data.eventLogId === 0
        //                         ? ""
        //                         : `EventoId: ${error.response.data.eventLogId}. `) +
        //                     error.response.data.message,
        //                 callback: () => dispatch(closeMessageBox()),
        //             })
        //         );
        //     }
        // } finally {
        //     dispatch({ type: SET_MASK, loading: false });
        // }
    }
};

export const onUpdateColumn = (column) => (dispatch, getState) => {
    getState().gestionbonificacion.table.columns.map((item, i) => {
        if (item.dataKey !== column.dataKey) {
            return item;
        } else {
            item.width = column.width;
        }
        return {
            // ...item,
            //  width: column.width
        };
    });
};

export const onUpdateFieldFilter = (key, value) => (dispatch, getState) => {
    dispatch({ type: UPDATE_FIELD_FILTER, key, value });

    if (key !== "nombre") {
        dispatch({ type: RECEIVE_V_LIST, column: [], payload: [] });
    }
};

export const onUpdateFieldEntity = (key, value) => (dispatch) => {
    dispatch({ type: UPDATE_FIELD_ENTITY, key, value });

    if (
        key === "nit" ||
        key === "codigoSucursal" ||
        key === "producto" ||
        key === "bonificacion" ||
        key === "tipo" ||
        key === "desde" ||
        key === "hasta"
    ) {
        dispatch({ type: SET_ERROR, key, error: "" });
    }
};

export const restartState = () => ({ type: RESTART_STATE });

export const openAdvancedSearch = (opts) => ({
    type: ADVANCED_SEARCH_OPEN,
    payload: opts,
});

export const setOrder = (order, orderBy) => ({
    type: SET_ORDER,
    order,
    orderBy,
});

export const closeAdvancedSearch = () => ({ type: ADVANCED_SEARCH_CLOSE });
