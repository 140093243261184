import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import {
    onUpdateColumn,
    onRutasList,
    setOrder,
    setPage,
    setPageSize,
    setSelectedRow
} from '../../../store/fuerzaventas/rutas/actions';
import { sort_by} from '../../../helpers/commons'

import { FieldSet, Table } from '@sigeco/tools';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column'
    }, 
    tableWrap: {
        flex: '1 1 auto'
    }
}));

const RutasList = () => {
    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();
    const columns = useSelector(state => state.rutas.table.columns);
    const loading = useSelector(state => state.rutas.store.loading);
    const order = useSelector(state => state.rutas.table.order);
    const orderBy = useSelector(state => state.rutas.table.orderBy);
    const store = useSelector(state => state.rutas.store);
    const dispatch = useDispatch();

    const handleDoubleClick = (id) => {
        history.push({
            hash: location.hash,
            pathname: `/fuerzaventas/rutas/${id}`,
            search: location.search
        });
    }

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        dispatch(setOrder(isAsc ? 'desc' : 'asc', property));
        let homes = store.data;
        homes.sort(sort_by(property, isAsc, homes[0][property]));
    };

    function handleChangePage(newPage) {
        dispatch(setPage(newPage));
        dispatch(onRutasList());
    }
    
    function handleChangeRowsPerPage(event) {
        dispatch(setPageSize(event.target.value));
        dispatch(setPage(0));
    }
    
    return (
        <FieldSet className={classes.root} iconCls="fas fa-th-list" title="Resultados">
            <div className={classes.tableWrap}>
                <Table
                    columns={columns}
                    count={store.total}
                    loading={loading}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    onRowClick={items => dispatch(setSelectedRow(items))}
                    onRowDoubleClick={item => handleDoubleClick(item.rutaId)}
                    onSort={datakey => handleRequestSort(datakey)}
                    onUpdateColumn={onUpdateColumn}
                    page={store.start/store.pageSize}
                    paginate
                    rowCount={store.data.length}
                    rowGetter={index =>  store.data[index] }
                    rowsPerPage={store.pageSize}
                    selected={store.selected}
                    sortBy={orderBy}
                    sortDirection={order}
                />
            </div>
        </FieldSet>
    )
};

export default RutasList;