import axios from 'axios';
import { IdentityUrl } from '../../../config';
import { exportToSpreadsheet} from "../../../helpers/commons";
import { closeAdvancedSearch, closeMessageBox, openAdvancedSearch, openMessageBox } from '../../main/actions';
import {
    CLEAN_FORM,
    RECEIVE_RUTAS_LIST,
    SET_MASK,
    SET_ORDER,
    SET_PAGE,
    SET_PAGE_SIZE,
    UPDATE_FIELD_FILTER,
    UPDATE_TABLE_COLUMN
} from './types';


// function sayHi(dispatch,  getState) {

//   console.log('Hola funcion');
//   dispatch({ type: SET_MASK, loading: true});
//   console.log(getState().clientesvendedor.store)
// }

//export const  LoadingAsync = (dispatch, getState) => async  (resolve) =>   {
// const LoadingAsync = (dispatch, getState) => async (resolve) =>   {

//   console.log('async function')
//   console.log(getState().clientesvendedor.store)
  
//   await (dispatch({ type: SET_MASK, loading: false }))
//   console.log(getState().clientesvendedor.store)

// };

export const onExport = () =>  async (dispatch, getState) => {

    if (getState().clientesvendedor.store.data.length === 0) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "No hay información para exportar.",
          callback: () => dispatch(closeMessageBox()),
        })
      );
      return;
    }
  
    try {
      dispatch({ type: SET_MASK, loading: true });
      //no modificar
      let head = getState().clientesvendedor.table.columns;
  
      var aux = [];

      for (var c in head){
        aux[c] = Object.values(head[c])[1];
      }
      //console.log(aux)
      
      var carArray = [];
  
      carArray.push(aux);
  
      let json = getState().clientesvendedor.store.data;
      for (var i in json) {
        let ax = [];
        delete json[i].VendedorId
        delete json[i].SucursalId
       // delete json[i].Telefono
        delete json[i].Rubro
        delete json[i].Provincia
        delete json[i].RutaId
        let x = Object.values(json[i]);

        ax[0] = x[0]
        ax[1] = x[6]
        ax[2] = x[7]
        ax[3] = x[1]
        ax[4] = x[2]
        ax[5] = x[3]
        ax[6] = x[14]
        ax[7] = x[8]
        ax[8] = x[9]
        ax[9] = x[10]
        ax[10] = x[11]
        ax[11] = x[12] 
        ax[12] = x[13]
        ax[13] = x[15]
        ax[14] = x[19]
        ax[15] = x[16]  //4
        ax[16] = x[4]
        ax[17] = x[5]
        ax[18] = x[17]
        ax[19] = x[18]
        ax[20] = x[20]
        ax[21] = x[21]
        ax[22] = x[22]
        ax[23] = x[23]
        ax[24] = x[24]
        ax[25] = x[25]
        //ax[26] = x[25]
        carArray.push(ax);
      }

      exportToSpreadsheet(carArray, "ListarRutasVendedorClientes");

    } catch (error) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "error",
          message:
            (error.response.data.eventLogId === 0  ? ""  : `EventoId: ${error.response.data.eventLogId}. `) +
            error.response.data.message,
          callback: () => dispatch(closeMessageBox()),
        })
      );
    } finally {
      setTimeout(() => {dispatch({ type: SET_MASK, loading: false })}, 1000);
    }
  };

export const btnSearch = () => async (dispatch, getState) => {
    try {
      
      dispatch({ type: SET_MASK, loading: true });
  
      if (getState().clientesvendedor.filters.distribuidorId === 0) {
        dispatch(
          openMessageBox({
            button: "ok",
            icon: "warning",
            message: "Debe seleccionar una Distribuidor..",
            callback: () => dispatch(closeMessageBox()),
          })
        );
        return;
      }
   
      let url =`${getState().main.resources.uriSvcSalesMobilityAPI }api/v1/reportes/rutavendedorcliente?` +
        `&distribuidorId=${getState().clientesvendedor.filters.distribuidorId}` +
        `&vendedorId=${getState().clientesvendedor.filters.vendedorId}`+
        `&nombre=${getState().clientesvendedor.filters.nombre}`;
     
      let response = await axios.get(encodeURI(url));
      let data = response.data;

      if (data.length === 0) {
        dispatch(
          openMessageBox({
            button: "ok",
            icon: "warning",
            message: "No hay información para mostrar de esta consulta.",
            callback: () => dispatch(closeMessageBox()),
          })
        );
        return;
      }

      dispatch({ type: RECEIVE_RUTAS_LIST, payload: data });
  
    } catch (error) {
      let msgError = "";
      dispatch({ type: SET_MASK, loading: false });
  
      if (error.response) {
        if (error.response.status === 400) {
          msgError =
            error.response.data.eventLogId === 0
              ? "No existe Registro  para estos Filtros"   : `EventoId: ${error.response.data.eventLogId}. ` +
                error.response.data.message;
        } else {
          msgError =
            (error.response.data.eventLogId === 0 ? ""  : `EventoId: ${error.response.data.eventLogId}. `) +
            error.response.data.message;
        }
        dispatch(
          openMessageBox({
            button: "ok",
            icon: "error",
            message: msgError,
            callback: () => dispatch(closeMessageBox()),
          })
        );
      }
    } finally {
      dispatch({ type: SET_MASK, loading: false });
    }
  };

export const cleanForm = () => ({ type: CLEAN_FORM });

export const onSearchVendedorPorRolClick = () => async (dispatch, getState) => {
        let fields = [
          {
            dataKey: "nombre",
            label: "Nombre",
            type: "string",
            search: true,
            width: 225,
          },
          //{ dataKey: "codigo", label: "Codigo", type: "string", width: 115 },
        ];
      
        if (getState().clientesvendedor.filters.distribuidorId === 0) {
          dispatch(
            openMessageBox({
              button: "ok",
              icon: "warning",
              message: "Debe seleccionar un Agente..",
              callback: () => dispatch(closeMessageBox()),
            })
          );
          return;
        }
        dispatch(openAdvancedSearch({
            autoLoad: true,
            data: [],
            fields: fields,
            paginated: true,
            remote: true,
            title: "Selección de Vendedor",
            getData: async (params) => {
                //let url = `${IdentityUrl}/api/v1/agentes/departamentos`;     
                let url = `${getState().main.resources.uriSvcSalesMobilityAPI}/api/v1/Vendedores/listarvendedoresxdistribuidor`
                + `?distribuidorId=${getState().clientesvendedor.filters.distribuidorId}`               
                let response = await axios.get(encodeURI(url), { params: params });

                if (params.nombre) {
                  let final = response.data.filter((e) =>
                    e.nombre.toLowerCase().includes(params.nombre.toLowerCase()));
                   response.data = final;
                   response.data.length = final.length;
                }

                const  aux = {
                    data: response.data  ,
                    pageSize: 50,
                    start: 0,
                    total: response.data.length
                }

                return aux;  
            },
            callback: (btn, result) => {
                console.log(result)
                dispatch(closeAdvancedSearch());
                if(btn === 'yes') {
                    dispatch(onUpdateFieldFilter('dVendedor', result[0].nombre)); 
                    dispatch(onUpdateFieldFilter('vendedorId', result[0].usuarioId)); 
                    dispatch(onUpdateFieldFilter('nombre', ''));
                  }
 
            }
        }));
};

export const onUpdateColumn = (column) => (dispatch, getState) => {
    let columns = getState().clientesvendedor.table.columns.map(item => {
        if(item.dataKey !== column.dataKey) {
            return item;
        }

        return {
            ...item,
            width: column.width
        }
    });

    dispatch({ type: UPDATE_TABLE_COLUMN, columns });
};

export const onUpdateFieldFilter = (key, value) => (dispatch, getState) => {
    dispatch({ type: UPDATE_FIELD_FILTER, key, value })

    dispatch({ type: RECEIVE_RUTAS_LIST, total: 0, payload: [] });
}

export const setOrder = (order, orderBy) => ({ type: SET_ORDER, order, orderBy });

export const setPage = (page) => ({ type: SET_PAGE, page });

export const setPageSize = (pageSize) => ({ type: SET_PAGE_SIZE, pageSize });

export const onSearchDistribuidorClick = () => async (dispatch) => {
    let fields = [
        { 'dataKey': 'razonSocial', 'label': 'Agente', 'type': 'string', 'width': 225 }
    ];

    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de agente',
        getData: async (params) => {
            let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`
            let response = await axios.get(encodeURI(url), { params: params });
        console.log(response.data)
            return response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldFilter('distribuidorId', result[0].distribuidorId));
                dispatch(onUpdateFieldFilter('dDistribuidor', `${result[0].razonSocial}`));
                dispatch(onUpdateFieldFilter('vendedorId', ''));
                dispatch(onUpdateFieldFilter('dVendedor', ''));
                dispatch(onUpdateFieldFilter('nombre', ''));
            }
        }
    }));
};

