import axios from "axios";
//import format from "date-fns/format";
import { IdentityUrl } from "../../../config";
import {
  closeAdvancedSearch,
  closeMessageBox,
  openAdvancedSearch,
  openMessageBox,
} from "../../main/actions";
import {
  ERROR_BASES_LIST,
  RECEIVE_BASES_LIST,
  REQUEST_BASES_LIST,
  RESTART_STATE,
  SET_MASK,
  SET_ORDER,
  SET_SELECTED_ROW,
  UPDATE_FIELD_FILTER,
  // UPDATE_TABLE_COLUMN
} from "./types";

import { exportToSpreadsheet, arregloExport } from "../../../helpers/commons";

export const onExport = () => async (dispatch, getState) => {
  if (getState().baseclientes.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }
 
    //console.log(getState().baseclientes);
    if (getState().baseclientes.store.data.length === 0) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "No hay información para exportar.",
          callback: () => dispatch(closeMessageBox()),
        })
      );
      return;
    }
    try {
    dispatch({ type: SET_MASK, loading: true });

    let head = getState().baseclientes.table.columns;

    // var aux = [];

    // for (var c in head) {
    //   aux[0] = Object.values(head[0])[1];
    //   //console.log( "a: "+aux[0]);
    //   aux[1] = Object.values(head[1])[1];
    //   //console.log( "a: "+aux[1]);
    //   aux[2] = Object.values(head[2])[1];
    //   //console.log( "a: "+aux[2]);
    //   aux[3] = Object.values(head[3])[1];
    //   //console.log( "a: "+aux[3]);
    //   aux[4] = Object.values(head[4])[1];
    //   //console.log( "a: "+aux[4]);
    //   aux[5] = Object.values(head[5])[1];
    //   //console.log( "a: "+aux[5]);
    //   aux[6] = Object.values(head[6])[1];
    //   //console.log( "a: "+aux[6]);
    //   aux[7] = Object.values(head[7])[1];
    //   //console.log( "a: "+aux[7]);
    //   aux[8] = Object.values(head[8])[1];
    //   //console.log( "a: "+aux[8]);
    //   aux[9] = Object.values(head[9])[1];
    //   //console.log( "a: "+aux[9]);
    // }

    // var carArray = [];

    // carArray.push(aux);

    let json = getState().baseclientes.store.data;

    // for (var i in json) {
    //   let x = Object.values(json[i]);
    //   carArray.push(x);
    // }
    var carArray = arregloExport(head,json) 

    exportToSpreadsheet(carArray, "BaseClientes");
  } catch (error) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message:
          (error.response.data.eventLogId === 0
            ? ""
            : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    setTimeout(() => {dispatch({ type: SET_MASK, loading: false })}, 1000);
  }
};

export const btnSearch = () => async (dispatch, getState) => {
  if (getState().baseclientes.store.loading) {
    return;
  }
  if (getState().baseclientes.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }

  if (getState().baseclientes.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }
  dispatch({ type: REQUEST_BASES_LIST });

  try {
    let url =
      `${
        getState().main.resources.uriSvcSalesMobilityAPI
      }api/v1/Clientes/baseclientes?` +
      `distribuidorid=${getState().baseclientes.filters.distribuidorId}` +
      `&vendedorId=${getState().baseclientes.filters.vendedorId}` +
      `&departamentoId=${getState().baseclientes.filters.dDepartamento}` +
      `&municipioId=${getState().baseclientes.filters.dMunicipio}` +
      `&ubigeoId=${getState().baseclientes.filters.ubigeoId}` +
      `&nombre=${getState().baseclientes.filters.nombre}` +
      `&todosClientes=${1}`;

    let response = await axios.get(encodeURI(url));

    let data = response.data;

    if (data.length === 0) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "No existe Registro  para esta consulta",
          callback: () => dispatch(closeMessageBox()),
        })
      );
      dispatch({ type: RECEIVE_BASES_LIST, columns: [], payload: [] });

      return;
    }
    // data.columns[0]['label'] = '# Pedido';
    //data.columns[1]['label'] = 'Nombre Cliente ';
    data.columns[1]["width"] = 300;
    data.columns[2]["width"] = 300;

    dispatch({
      type: RECEIVE_BASES_LIST,
      columns: data.columns,
      payload: data.data,
    });
  } catch (error) {
    let msgError = "";
    dispatch({ type: ERROR_BASES_LIST });

    if (error.response) {
      msgError =
        (error.response.data.eventLogId === 0
          ? ""
          : `EventoId: ${error.response.data.eventLogId}. `) +
        error.response.data.message;
    } else {
      msgError = "Error al conectar con el servidor";
    }

    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message: msgError,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  }
};
export const onUpdateColumn = (column) => (dispatch, getState) => {
  getState().baseclientes.table.columns.map((item, i) => {
    if (item.dataKey !== column.dataKey) {
      return item;
    } else {
      item.width = column.width;
    }
    return {
      // ...item,
      //  width: column.width
    };
  });

  // dispatch({ type: UPDATE_TABLE_COLUMN, columns });
};

export const onSearchDistribuidorClick = () => async (dispatch) => {
  let fields = [
    { dataKey: "razonSocial", label: "Agente", type: "string", width: 225 },
  ];

  dispatch(onUpdateFieldFilter("distribuidorId", 0));
  dispatch(onUpdateFieldFilter("dDistribuidor", ""));
  dispatch(onUpdateFieldFilter("vendedorId", 0));
  dispatch(onUpdateFieldFilter("dVendedor", ""));
  dispatch(onUpdateFieldFilter("departamentoId", "0"));
  dispatch(onUpdateFieldFilter("dDepartamento", ""));
  dispatch(onUpdateFieldFilter("municipioId", "0"));
  dispatch(onUpdateFieldFilter("dMunicipio", ""));
  dispatch(onUpdateFieldFilter("nombre", ""));

  console.log(IdentityUrl);

  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de agente",
      getData: async (params) => {
        let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`;
        let response = await axios.get(encodeURI(url), { params: params });
        console.log(response);
        return response.data;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(
            onUpdateFieldFilter("distribuidorId", result[0].distribuidorId)
          );
          dispatch(
            onUpdateFieldFilter("dDistribuidor", `${result[0].razonSocial}`)
          );
          dispatch(onUpdateFieldFilter("supervisorId", ""));
          dispatch(onUpdateFieldFilter("dSupervisor", ""));
          dispatch(onUpdateFieldFilter("vendedorId", "0"));
          dispatch(onUpdateFieldFilter("dVendedor", ""));
          dispatch(onUpdateFieldFilter("departamentoId", "0"));
          dispatch(onUpdateFieldFilter("dDepartamento", ""));
          dispatch(onUpdateFieldFilter("municipioId", "0"));
          dispatch(onUpdateFieldFilter("dMunicipio", ""));
          dispatch(onUpdateFieldFilter("nombre", ""));
        }
      },
    })
  );
};

export const onSearchSupervisorClick = () => async (dispatch, getState) => {
  let fields = [
    {
      dataKey: "nombre",
      label: "Nombre",
      type: "string",
      search: true,
      width: 225,
    },
    { dataKey: "nombreUsuario", label: "Usuario", type: "string", width: 115 },
  ];

  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de supervisor",
      getData: async (params) => {
        let url = `${IdentityUrl}/api/v1/usuarios?rol=Supervisor`;
        let response = await axios.get(encodeURI(url), { params: params });
        let distribuidorId = getState().reportevisitas.filters.distribuidorId;

        if (distribuidorId > 0) {
          response.data.data = response.data.data.filter(
            (f) => f.distribuidorId === distribuidorId
          );
        }

        return response.data;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(onUpdateFieldFilter("supervisorId", result[0].usuarioId));
          dispatch(onUpdateFieldFilter("dSupervisor", `${result[0].nombre}`));
          dispatch(onUpdateFieldFilter("vendedorId", ""));
          dispatch(onUpdateFieldFilter("dVendedor", ""));
        }
      },
    })
  );
};


export const onSearchVendedorPorRolClick = () => async (dispatch, getState) => {
  let fields = [
    { dataKey: "nombre",         label: "Nombre",        type: "string",        search: true,        width: 225      },
    { dataKey: "vendedorId", label: "Codigo", type: "string", width: 115 },
  ];

  if (getState().baseclientes.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }
              dispatch(onUpdateFieldFilter("vendedorId", 0));
              dispatch(onUpdateFieldFilter("dVendedor", ""));
              dispatch(onUpdateFieldFilter("departamentoId", "0"));
              dispatch(onUpdateFieldFilter("dDepartamento", ""));
              dispatch(onUpdateFieldFilter("municipioId", "0"));
              dispatch(onUpdateFieldFilter("dMunicipio", ""));
              dispatch(onUpdateFieldFilter("nombre", ""));
              dispatch(openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de Vendedor",
      getData: async (params) => {
          //let url = `${IdentityUrl}/api/v1/agentes/departamentos`;     
          let url = `${getState().main.resources.uriSvcSalesMobilityAPI}/api/v1/Vendedores/listarvendedoresxdistribuidor`
          + `?distribuidorId=${getState().baseclientes.filters.distribuidorId}`               
          let response = await axios.get(encodeURI(url), { params: params });
          
          if (params.nombre) {
            let final = response.data.filter((e) =>
              e.nombre.toLowerCase().includes(params.nombre.toLowerCase()));
             response.data = final;
             response.data.length = final.length;
          }
          
          const  aux = {
              data: response.data  ,
              pageSize: 50,
              start: 0,
              total: response.data.length
          }
          return aux;  //response.data;
      },
      callback: (btn, result) => {
          dispatch(closeAdvancedSearch());
          if(btn === 'yes') {
              console.log( result[0].vendedorId)
              console.log(result[0].nombre)
              dispatch(onUpdateFieldFilter('vendedorId', result[0].vendedorId));
              dispatch(onUpdateFieldFilter('dVendedor', result[0].nombre)); 
              dispatch(onUpdateFieldFilter("departamentoId", "0"));
              dispatch(onUpdateFieldFilter("dDepartamento", ""));
              dispatch(onUpdateFieldFilter("municipioId", "0"));
              dispatch(onUpdateFieldFilter("dMunicipio", ""));
              dispatch(onUpdateFieldFilter("nombre", ""));
              // dispatch({ type: RECEIVE_OLAS_LIST, columns: getState().aprobarpedido.table.columns, payload: [] });
            }
      }
  }));
};




export const onSearchProvinciaClick = () => async (dispatch, getState) => {
  let fields = [
    { dataKey: "nombre", label: "Nombre", type: "string", width: 225 },
  ];

  dispatch(onUpdateFieldFilter("departamentoId", "0"));
  dispatch(onUpdateFieldFilter("dDepartamento", ""));
  dispatch(onUpdateFieldFilter("municipioId", "0"));
  dispatch(onUpdateFieldFilter("dMunicipio", ""));
  dispatch(onUpdateFieldFilter("nombre", ""));

  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de Departamento",
      getData: async (params) => {
        //let url = `${IdentityUrl}/api/v1/agentes/departamentos`;
        let url = `${
          getState().main.resources.uriSvcSalesMobilityAPI
        }api/v1/agentes/departamentos`;
        let response = await axios.get(encodeURI(url), { params: params });

        const aux = {
          data: response.data,
          pageSize: 50,
          start: 0,
          total: response.data.length,
        };
        return aux; //response.data;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(onUpdateFieldFilter("departamentoId", result[0].codigo));
          dispatch(onUpdateFieldFilter("dDepartamento", `${result[0].nombre}`));
          dispatch(onUpdateFieldFilter("municipioId", "0"));
          dispatch(onUpdateFieldFilter("dMunicipio", ""));

          /* dispatch(onUpdateFieldFilter('municipioId', '0'));
                    dispatch(onUpdateFieldFilter('dMunicipio', ''));
                    dispatch(onUpdateFieldFilter('marcaId', '0'));
                    dispatch(onUpdateFieldFilter('dMarca', ''));
                    dispatch(onUpdateFieldFilter('lobId', '0'));
                    dispatch(onUpdateFieldFilter('dLob', ''));
                    dispatch(onUpdateFieldFilter('canalId', '0'));
                    dispatch(onUpdateFieldFilter('dCanal', ''));
                    */
        }
      },
    })
  );
};

export const onSearcMunicipioClick = () => async (dispatch, getState) => {
  let fields = [
    { dataKey: "nombre", label: "Nombre", type: "string", width: 225 },
  ];

  dispatch(onUpdateFieldFilter("municipioId", "0"));
  dispatch(onUpdateFieldFilter("dMunicipio", ""));
  if (getState().baseclientes.filters.departamentoId === '0') {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Departamento..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }

  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de Municipio",
      getData: async (params) => {
        let url =
          `${
            getState().main.resources.uriSvcSalesMobilityAPI
          }api/v1/agentes/provincias?` +
          `codigodepartamento=${
            getState().baseclientes.filters.departamentoId
          }`;
        let response = await axios.get(encodeURI(url), { params: params });
        const aux = {
          data: response.data,
          pageSize: 50,
          start: 0,
          total: response.data.length,
        };
        return aux; //response.data;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(onUpdateFieldFilter("municipioId", result[0].codigo));
          dispatch(onUpdateFieldFilter("dMunicipio", `${result[0].nombre}`));
        }
      },
    })
  );
};

export const onSearchUbigeoClick = () => async (dispatch, getState) => {
  let fields = [
    { dataKey: "codigo", label: "Código", type: "string", width: 115 },
    {
      dataKey: "dUbigeo",
      label: "Ubigeo",
      type: "string",
      search: true,
      width: 225,
    },
  ];

  dispatch(
    openAdvancedSearch({
      autoLoad: false,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de ubigeo",
      getData: async (params) => {
        let url = `${
          getState().main.resources.uriSvcSalesMobilityAPI
        }api/v1/agentes/ubigeos`;
        let response = await axios.get(encodeURI(url), { params: params });
        return response.data;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(onUpdateFieldFilter("ubigeoId", result[0].ubigeoId));
          dispatch(onUpdateFieldFilter("dUbigeo", `${result[0].dUbigeo}`));
        }
      },
    })
  );
};

export const onSearchVendedorClick = () => async (dispatch, getState) => {
  let fields = [
    {
      dataKey: "nombre",
      label: "Nombre",
      type: "string",
      search: true,
      width: 225,
    },
    { dataKey: "nombreUsuario", label: "Usuario", type: "string", width: 115 },
  ];

  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de vendedor",
      getData: async (params) => {
        let url = `${IdentityUrl}/api/v1/usuarios/porjerarquia?usuarioId=${
          getState().reportevisitas.filters.supervisorId
        }`;
        let response = await axios.get(encodeURI(url), { params: params });
        let distribuidorId = getState().reportevisitas.filters.distribuidorId;

        if (distribuidorId > 0) {
          response.data.data = response.data.data.filter(
            (f) => f.distribuidorId === distribuidorId
          );
        }

        return response.data;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(onUpdateFieldFilter("vendedorId", result[0].usuarioId));
          dispatch(onUpdateFieldFilter("dVendedor", `${result[0].nombre}`));
        }
      },
    })
  );
};

/*
export const openDialogClient = (data) => (dispatch, getState) => {
    try {
        var storeData = getState().reportevisitas.data.filter(f => f.dVendedor === data.dVendedor);
        
        dispatch({ type: SHOW_POPUP_CLIENT, storeData });
    } catch (error) {
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'error', 
            message: error, 
            callback: () => dispatch(closeMessageBox())
        }));
    }
};
*/

export const onUpdateFieldFilter = (key, value) => (dispatch, getState) => {
  //({ type: UPDATE_FIELD_FILTER, key, value });
  dispatch({ type: UPDATE_FIELD_FILTER, key, value });
  dispatch({ type: RECEIVE_BASES_LIST, columns: [], payload: [] });
};

export const restartState = () => ({ type: RESTART_STATE });

export const setOrder = (order, orderBy) => ({
  type: SET_ORDER,
  order,
  orderBy,
});

export const setSelectedRow = (selected) => ({
  type: SET_SELECTED_ROW,
  selected,
});
