export const CLEAN_FORM = 'precios/reportedeincidencias/clean-form';
export const ADVANCED_SEARCH_CLOSE = 'main/advanced-search-close';
export const ADVANCED_SEARCH_OPEN = 'main/advanced-search-open';
export const SET_STATE_FORM = 'precios/reportedeincidencias/set-state-form';
export const UPDATE_FIELD_FILTER = 'precios/reportedeincidencias/update-field-filter';
export const RECEIVE_V_LIST = 'precios/reportedeincidencias/receive-v-list';
export const REQUEST_V_LIST = 'precios/reportedeincidencias/request-v-list';
export const ERROR_V_LIST = 'precios/reportedeincidencias/error-v-list';
export const SET_MASK = 'precios/reportedeincidencias/set-mask';
export const SET_ORDER = 'precios/reportedeincidencias/set-order';
export const RECEIVE_DATA= 'precios/reportedeincidencias/receibe-data';
export const UPDATE_TABLE_COLUMN= 'precios/reportedeincidencias/update-table-column';
export const SET_PAGE = 'precios/reportedeincidencias/set-page';
export const SET_SELECTED_ROW = 'precios/reportedeincidencias/set-selected-row';
export const SHOW_POPUP_CLIENT = 'movilidad/reportedeincidencias/show-popup-client';
export const HIDE_POPUP_CLIENT = 'movilidad/reportedeincidencias/hide-popup-client';
