import React, { useEffect } from 'react';
import { useRouteMatch } from "react-router-dom";

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';

import NotFound from './NotFound';
import ProfileDetails from '../components/profile/ProfileDetails';
import ProfileSummary from '../components/profile/ProfileSummary';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../store/profile/actions';

const useStyles = makeStyles(() => ({
    body: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        padding: '0 0.5rem 0.5rem',
        overflowY: 'auto',
        '@media (min-width: 701px)': {
          padding: '0 0.75rem 0.75rem'
        },
        '@media (min-width: 1401px)': {
          padding: '0 1rem 1rem'
        }
    },
    header: {
        padding: '.8125rem .8125rem 0',
        '@media (min-width: 751px)': {
            padding: '1.5rem 1.5rem 0'
        }
    },
    separator: {
        borderBottom: '1px solid #d7d7d7',
        marginBottom: '.625rem',
        '@media (min-width: 751px)': {
            marginBottom: '.875rem'
        }
    },
    title: {
        color: '#3a3a3a', 
        fontSize: '1.25rem',
        fontWeight: 500,
        marginBottom: '.5rem',
        '@media (min-width: 751px)': {
            fontSize: '1.5rem',
            marginBottom: '.75rem',
        }
    },
    root: {
        backgroundColor: '#f8f8f8', 
        boxSizing: 'border-box',
        flexDirection: 'column',
        display: 'flex',
        height: '100%',
        position: 'relative'
    }
}));

const Profile = ({ loading, nombre, notFound, getProfile }) => {
    const classes = useStyles();
    const match = useRouteMatch();

    useEffect(() => {
        getProfile(match.params['id']);
      // eslint-disable-next-line
    }, [match.path]);

    if(notFound) {
        return (
            <NotFound />
        )
    }

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.title}>Perfil</div>
                <div className={classes.separator}></div>
            </div>
            <Backdrop style={{ position: 'absolute', zIndex: 1200 }} open={loading} transitionDuration={0}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className={classes.body}>
                <ProfileSummary />
                <ProfileDetails />
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    loading: state.profile.loading,
    nombre: state.profile.entity.nombre,
    notFound: state.profile.notFound,
});
  
export default connect(
    mapStateToProps,
    dispatch => bindActionCreators({...actionCreators}, dispatch)
)(Profile);