export const CLEAN_FORM = 'precios/reporteindicadores/clean-form';
export const ADVANCED_SEARCH_CLOSE = 'main/advanced-search-close';
export const ADVANCED_SEARCH_OPEN = 'main/advanced-search-open';
export const SET_STATE_FORM = 'precios/reporteindicadores/set-state-form';
export const UPDATE_FIELD_FILTER = 'precios/reporteindicadores/update-field-filter';
export const RECEIVE_V_LIST = 'precios/reporteindicadores/receive-v-list';
export const REQUEST_V_LIST = 'precios/reporteindicadores/request-v-list';
export const ERROR_V_LIST = 'precios/reporteindicadores/error-v-list';
export const SET_MASK = 'precios/reporteindicadores/set-mask';
export const SET_ORDER = 'precios/reporteindicadores/set-order';
export const RECEIVE_DATA= 'precios/reporteindicadores/receibe-data';
export const UPDATE_TABLE_COLUMN= 'precios/reporteindicadores/update-table-column';
export const SET_PAGE = 'precios/reporteindicadores/set-page';
export const SET_SELECTED_ROW = 'precios/reporteindicadores/set-selected-row';
export const ADD_PRODUCTO = 'precios/reporteindicadores/add_producto';
export const ADD_MARCA = 'precios/reporteindicadores/add_marca';
