import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import './table.css'

import { FieldSet} from '@sigeco/tools';
import makeStyles from '@material-ui/core/styles/makeStyles';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        width: '120%'
    }, 
    progress: {
        height: '2rem',
        width: '2rem',
        '@media (min-width: 701px)': {
            height: '2.5rem',
            width: '2.5rem',
        },
    },
        content: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: 'calc(50% - 20px)',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    tableWrap: {
        flex: '1 1 auto'
    }
}));

const CargaMasivaClientesList = () => {
    const classes = useStyles();
    const loading = useSelector(state => state.cargamasivaclientes.loading);
   // const order = useSelector(state => state.cargamasivaclientes.table.order);
   // const orderBy = useSelector(state => state.cargamasivaclientes.table.orderBy);
    const store = useSelector(state => state.cargamasivaclientes.store);
  // const dispatch = useDispatch();

      const [data, setData] = useState([]);

      useEffect(() => {
          setData( store.data)
      }, [store.data]);
  


    // const handleRequestSort = (property) => {
    //     const isAsc = orderBy === property && order === 'asc';
    //     dispatch(setOrder(isAsc ? 'desc' : 'asc', property));
    //     let homes = store.data;
    //     homes.sort(sort_by(property, isAsc, homes[0][property]));
    // };

    // const handleResizeColumn = (column) => {
    //     dispatch(onUpdateColumn(column))
    // }

    // const sort_by = (field, reverse,  tipo) => {
    //     let  primer = null;
    //     if (typeof(tipo) === 'number'){
    //       primer = parseInt;
    //   } else if (typeof(tipo) === 'boolean' ){
    //       primer = null;
    //   }else if (typeof(tipo) === 'string'){
    //       primer = (a) =>  a.toUpperCase();
    //   }else if (typeof(tipo) === 'object'){
    //       primer = null;
    //   }
    //   else {
    //       primer = null; 
    //   }
     
    //     const key = primer ?
    //       function(x) {
    //         return primer(x[field])
    //       } :
    //       function(x) {
    //         return x[field]
    //       };
      
    //     reverse = !reverse ? 1 : -1;
      
    //     return function(a, b) {
    //       return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
    //     }
    //   }

      
    return (
        <>
         <div  style={{backgroundColor: 'white', width: '120%'}} >
            <span style={{fontSize: '10px' , marginTop: '15px', marginLeft: '10px'}}> Resultados </span>
                < div   style={{display: loading ? 'block': 'none' }}>
                    <div className={classes.content}>
                        <CircularProgress size='' className={classes.progress} color='secondary' />
                    </div>
                </div> 
            
            <table >
                <thead> 
                <tr>     
                    <th style={{textAlign : 'left', width: '5%'}}>  </th>
                    <th style={{textAlign : 'left', width: '10%'}}> Codigo</th>
                    <th style={{width: '10%', height:'100%'}}>NIT/RUC</th> 
                    <th style={{width: '20%', height:'100%', textAlign : 'center'}}>NombreComercial</th>
                    <th style={{width: '40%', height:'100%', textAlign : 'center'}}>Dirección</th>
                    <th style={{width: '20%', height:'100%', textAlign : 'center'}}>Canal</th>
                    <th style={{width: '20%', height:'100%', textAlign : 'center'}}>Segmento</th>
                    <th style={{width: '20%', height:'100%', textAlign : 'center'}}>Categoria</th>
                    <th style={{width: '20%', height:'100%', textAlign : 'center'}}>TipoCliente</th>
                    <th style={{width: '20%', height:'100%', textAlign : 'center'}}>Credito</th>
                    <th style={{width: '20%', height:'100%', textAlign : 'center'}}>DiasCredito</th>
                    <th style={{width: '20%', height:'100%', textAlign : 'center'}}>LineaCredito</th>
                    <th style={{width: '20%', height:'100%', textAlign : 'center'}}>NombreSucursal</th>
                    <th style={{width: '20%', height:'100%', textAlign : 'center'}}>CodigoSucursal</th>
                    <th style={{width: '20%', height:'100%', textAlign : 'center'}}>CodigoDepartamento</th>
                    <th style={{width: '20%', height:'100%', textAlign : 'center'}}>CodigoProvincia</th>
                    <th style={{width: '20%', height:'100%', textAlign : 'center'}}>CodigoDistrito</th>
                    <th style={{width: '40%', height:'100%', textAlign : 'center'}}>DireccionSucursal</th>
                    <th style={{width: '10%', height:'100%', textAlign : 'center'}}>latitud</th>
                    <th style={{width: '10%', height:'100%', textAlign : 'center'}}>Longuitud</th>
                </tr>
                </thead>
                <tbody>
                {
                    data.map((item, i) => (
                            <tr key={i}>
                                <td style={{width: '5%', height:'100%'}}>{i+1 }</td>
                                <td style={{width: '10%', height:'100%', textAlign: 'center'}}>{item.Codigo}</td>
                                <td style={{width: '30%', height:'100%'}}>{item.NITRUC}</td>
                                <td style={{width: '30%', height:'100%'}}>{item.NombreComercial}</td>
                                <td style={{width: '40%', height:'100%'}}>{item.Direccion}</td>
                                <td style={{width: '30%', height:'100%'}}>{item.Canal}</td>
                                <td style={{width: '30%', height:'100%'}}>{item.Segmento}</td>
                                <td style={{width: '30%', height:'100%'}}>{item.Categoria}</td>
                                <td style={{width: '30%', height:'100%'}}>{item.TipoCliente}</td>
                                <td style={{width: '30%', height:'100%'}}>{item.Credito}</td>
                                <td style={{width: '30%', height:'100%'}}>{item.DiasCredito}</td>
                                <td style={{width: '30%', height:'100%'}}>{item.LineaCredito}</td>
                                <td style={{width: '30%', height:'100%'}}>{item.NombreSucursal}</td>
                                <td style={{width: '30%', height:'100%'}}>{item.CodigoSucursal}</td>
                                <td style={{width: '30%', height:'100%'}}>{item.CodigoDepartamento}</td>
                                <td style={{width: '30%', height:'100%'}}>{item.CodigoDistrito}</td>
                                <td style={{width: '30%', height:'100%'}}>{item.CodigoProvincia}</td>
                                <td style={{width: '40%', height:'100%'}}>{item.DireccionSucursal}</td>
                                <td style={{width: '10%', height:'100%'}}>{item.Latitud}</td>
                                <td style={{width: '10%', height:'100%'}}>{item.Longitud}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>

        <FieldSet className={classes.root} style={{height: '3px'}} >
            <div className={classes.tableWrap}>
              
            </div>
        </FieldSet>

    </>
    )
};

export default CargaMasivaClientesList;