import React from 'react';
import {  useSelector } from 'react-redux';

const caja = {
  position: 'relative',
  display: 'inline-block'
}

const p = {
  position: 'absolute',
  top: '25%',
  left: '70%',
  color: 'white',
};

const q = {
  position: 'absolute',
  top: '65%',
  left: '80%',
  color: 'white',
};

// const pageStyle = `
//   @page {
//     size: 80mm 50mm;
//   }

//   @media all {
//     .pagebreak {
//       display: none;
//     }
//   }

//   @media print {
//     .pagebreak {
//       page-break-before: always;
//     }
//   }
// `

const PdfLineaEstrategica2022 = (items) => {
  const mes =   useSelector(state => state.resumen.filters.desde.getFullYear());  
 const fecha =  useSelector(state => state.resumen.store.meses[state.resumen.filters.desde.getMonth()] +',  '+ mes); 
  //class ComponentToPrint extends React.Component {

    const ComponentToPrint  = () => {
    //render() {
      return (
        <>
          <div   style={{    width: "800px",  height: "1200px"   }}  >
        <div style={caja}>  
    
         {mes=== 2022  &&
                   <>  
          <img alt='logo'  src={ './images/mobil-gigantes2022.png'}  style={{width: '125%', height:'130px'}}/>
          <div style={p}><h2>Linea Estrategica</h2></div>   
          <div style={q}><h4>       {fecha}        </h4></div>           
          </>
        }
         </div>
        
        <table>
          <thead>
            <tr>
              <th style={{width: '10%', height:'100%'}}></th>   
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">
              </th>
            </tr>
          </tbody>
        </table>
    
        <table>

          <tbody>
            <tr>
              <th scope="row" style={{width: '150px', height:'100%'}}></th>
              <td style={{ border: '1px solid', borderColor: 'white',  backcolor: '#00FF00', backgroundColor: '#0f2462', color: 'white', width: '230px'}}>
              40 a 50 Bbl </td>
              <td style={{ border: '1px solid', borderColor: 'white',  backcolor: '#00FF00', backgroundColor:  '#0f2462', color: 'white', width: '50px'}}>
              Tipo</td>
              <td style={{ border: '1px solid', fontsize: '15px', borderColor: '#0f2462',   textAlign:  'center', color: '#191970', width: '280px'}}>USD $100</td>
            </tr>
           
                {renderBody(items.items.filter(checkAdult)) }
            <tr style={{height: '30px'}} ></tr>

            <tr>
              <th scope="row"></th>
              <td style={{ border: '1px solid',  borderColor: 'white',  backcolor: '#00FF00', backgroundColor: '#0f2462', color: 'white'}}>
              50,01 a 65 Bbl</td>
              <td style={{ border: '1px solid', borderColor: 'white',  backcolor: '#00FF00', backgroundColor:  '#0f2462', color: 'white', width: '50px'}}>
              Tipo</td>
              <td style={{ border: '1px solid', fontsize: '15px', borderColor: '#0f2462',   textAlign:  'center', color: '#191970'}}>USD $120</td>
            </tr>

            {renderBody(items.items.filter(checkCien)) }
            <tr style={{height: '30px'}} ></tr>  

            
            <tr>
              <th scope="row"></th>
              <td style={{ border: '1px solid',  borderColor: 'white',  backcolor: '#00FF00', backgroundColor: '#0f2462', color: 'white'}}>
              65,01 a 75 Bbl</td>
              <td style={{ border: '1px solid', borderColor: 'white',  backcolor: '#00FF00', backgroundColor:  '#0f2462', color: 'white', width: '50px'}}>
              Tipo</td>
              <td style={{ border: '1px solid', fontsize: '15px', borderColor: '#0f2462',   textAlign:  'center', color: '#191970'}}>USD $150</td>
            </tr>

            {renderBody(items.items.filter(checkCiencta)) }
            <tr style={{height: '30px'}} ></tr>  

            <tr>
              <th scope="row"></th>
              <td style={{ border: '1px solid', borderColor: 'white',  backcolor: '#00FF00', backgroundColor: '#0f2462', color: 'white'}}>
              Igual o mayor a 75,1 Bbl</td>
              <td style={{ border: '1px solid', borderColor: 'white',  backcolor: '#00FF00', backgroundColor:  '#0f2462', color: 'white', width: '50px'}}>
              Tipo</td>
              <td style={{ border: '1px solid', fontsize: '15px', borderColor: '#0f2462',   textAlign:  'center', color: '#191970'}}>USD $200</td>
            </tr>

            {renderBody(items.items.filter(checkDos)) }
           
            <tr style={{breakBefore: 'page', breakInside: 'avoid', breakAfter: 'page', position:'relative'}}></tr>
      
          </tbody>
        </table>
        </div>
        </>
    );
    //}
  }

  function checkAdult(age) {     return age.pago === 100;   }
  function checkCien(age) {          return age.pago === 120;   }
  function checkCiencta(age) {       return age.pago === 150;   }
  function checkDos(age) {       return age.pago === 200;   }

    const renderBody = (employees) => {
        return employees && employees.map(({   id, distribuidor, asesor, tipo, cumplimiento, pago }, index) => {
            return (
                 <tr style={{ }} key={index}>   
                    <td ></td>   
                    <td style={{ width : '70px' , fontSize: '12px', backgroundColor: index%2 === 0 ?  '#cad1d7': '#d5dbe0' }}>{distribuidor}</td>
                    <td style={{fontSize: '12px', backgroundColor: index%2 === 0 ?  '#cad1d7': '#d5dbe0'  }}> {tipo}</td>
                    <td style={{fontSize: '12px', backgroundColor: index%2 === 0 ?  '#cad1d7': '#d5dbe0'  }}> -{asesor}</td>
                    
                </tr> 
            ) 
        })
    }

    return (
      <ComponentToPrint />
    )
  
  };
  
  export default PdfLineaEstrategica2022;