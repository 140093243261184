import {
    FILTER_MAP_VENDEDOR,
    HIDE_POPUP_CLIENT,
    RECEIVE_DATA,
    RECEIVE_V_LIST,
    RECEIVE_OLAS_LIST,
    SET_MASK,
    SET_ORDER,
    SET_SELECTED_ROW,
    SHOW_POPUP_CLIENT,
    UPDATE_FIELD_FILTER,
    UPDATE_TABLE_COLUMN
} from './types';

const initialState = {
    loading: false,
    data: [],
    dVendedor: '',
    openPopup: false,
    filters: {
        distribuidorId: 0,
        supervisorId: '',
        ubigeoId: 0,
        vendedorId: '',
        marcasId:0,
        dMarcas:'',
        lugarId:0,
        dLugar:'',
        desde: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        hasta: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        dDistribuidor: '',
        dSupervisor: '',
        dVendedor: '',
        dUbigeo: '',

        tipoId: 0,
        dTipo: '',
    },
    store: {
        loading: false,
        data: [],
        selected: [],
    },
    storePed: {
        data: [],
        selected: [],
    },
    storeClient: {
        data: [],
        selected: []
    },
    table: {
        order: 'asc',
        orderBy: '',
        columns: [
            { dataKey: 'vendedor', label: 'Vendedor', width: 225},
            //{ dataKey: 'PENDIENTES', label: 'PENDIENTES', width: 250 },
            { dataKey: 'EJECUTADOS', label: 'EJECUTADOS', width: 250 },
            { dataKey: 'TOTAL', label: 'TOTAL', width: 300 }
        ]
    },

};

export const avanceidentificacionmercadoReducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case FILTER_MAP_VENDEDOR:
            return {
                ...state,
                dVendedor: action.dVendedor || ''
            };
        case HIDE_POPUP_CLIENT:
            return {
                ...state,
                openPopup: false
            };
        case RECEIVE_DATA:
            return {
                ...state,
                data: action.payload,
                store: {
                    ...state.store,
                    data: action.storeData
                }
            };
        case RECEIVE_V_LIST:
                return {
                    ...state,
                    store: {
                        ...state.store,
                        loading: false,
                        total: action.total,
                        data: action.payload,
                        source: null
                    }
                    // ,
                    // table: {
                    //     ...state.table,
                    //     columns: action.columns,
                    // }
            };    

        case RECEIVE_OLAS_LIST:

            return {
                ...state,
                data: action.pay,
                storePed: {
                    ...state.store,
                    data: action.storePed
                }
            };

        case SET_MASK:
            return {
                ...state,
                loading: action.loading
            };
        case SET_ORDER:
            return {
                ...state,
                table: {
                    ...state.table,
                    order: action.order,
                    orderBy: action.orderBy
                }
            };
        case SET_SELECTED_ROW:
            return {
                ...state,
                store: {
                    ...state.store,
                    selected: action.selected
                }
            };
        case UPDATE_FIELD_FILTER:
            return { 
                ...state,
                filters: {
                    ...state.filters,
                    [action.key]: action.value
                }
            };
        case SHOW_POPUP_CLIENT:
            return {
                ...state,
                openPopup: true,
                storeClient: {
                    ...state.store,
                    data: action.storeData
                }
            };
        case UPDATE_TABLE_COLUMN:
            return {
                ...state,
                table: {
                    ...state.table,
                    columns: action.columns
                }
            };
        default:
            return state;
    }
}