import React , { useState, useEffect} from 'react';
import {  useSelector } from 'react-redux';

const caja = {
  position: 'relative',
  display: 'inline-block'
}

const p = {
  position: 'absolute',
  top: '15%',
  left: '60%',
  color: 'white',
};

const q = {
    position: 'absolute',
    top: '40%',
    left: '60%',
    color: 'white',
  };


const PdfEstrategicoSuper = (items) => {

  class ComponentToPrint extends React.Component {
    render() {
      return (
        <>

        <div style={caja}>  
          <img alt='logo'  src={ './images/mobil-gigantes.png'}  style={{width: '125%', height:'130px'}}/>
          <div style={p}><h2>Productos Estrategicos</h2></div>   
          <div style={q}><h4>Mobil Super 2000</h4></div>  
          
         </div>
        
        <table>
          <thead>
            <tr>
              <th style={{width: '10%', height:'100%'}}></th>   
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">
              </th>
            </tr>
          </tbody>
        </table>
    
        <table>

          <tbody>
            <tr>
              <th scope="row" style={{width: '80px', height:'100%'}}></th>
              <td style={{ border: '1px solid', borderColor: 'white', textAlign: 'center' ,  backcolor: '#00FF00', backgroundColor: '#4169e1', color: 'white', width: '230px'}}>
                  Primer Lugar</td>
              <td style={{ border: '1px solid', fontsize: '15px', fontFamily: 'sans-serif-medium' , borderColor: 'blue',   textAlign:  'center', color: '#191970', width: '280px'}}>
                  <h4>USD 100 GIFT CARD</h4>
              </td>
            </tr>          
                  {renderBody(dat[0]) }
                  <tr style={{height: '30px'}} ></tr>

           <tr>
              <th scope="row" style={{width: '80px', height:'100%'}}></th>
              <td style={{ border: '1px solid', borderColor: 'white', textAlign: 'center' ,  backcolor: '#00FF00', backgroundColor: '#4169e1', color: 'white', width: '230px'}}>
                  Segundo Lugar</td>
              <td style={{ border: '1px solid', fontsize: '15px', fontFamily: 'sans-serif-medium' , borderColor: 'blue',   textAlign:  'center', color: '#191970', width: '280px'}}>
                  <h4>USD 100 GIFT CARD</h4>
              </td>
            </tr>  
                    {renderBody(dat[1]) }
                    <tr style={{height: '30px'}} ></tr>
                    <tr>
              <th scope="row" style={{width: '80px', height:'100%'}}></th>
              <td style={{ border: '1px solid', borderColor: 'white', textAlign: 'center' ,  backcolor: '#00FF00', backgroundColor: '#4169e1', color: 'white', width: '230px'}}>
                  tercer Lugar</td>
              <td style={{ border: '1px solid', fontsize: '15px', fontFamily: 'sans-serif-medium' , borderColor: 'blue',   textAlign:  'center', color: '#191970', width: '280px'}}>
                  <h4>USD 100 GIFT CARD</h4>
              </td>
            </tr>  
                    {renderBody(dat[2]) }
                    <tr style={{breakBefore: 'page', breakInside: 'avoid', breakAfter: 'page', position:'relative'}}></tr>
      

            </tbody>
        </table>
        </>
    );
    }
  }

    const renderBody = (employees) => { 
     
      if (employees && employees !== undefined){
            return (
               
              <tr style={{backgroundColor: employees.id%2 === 0 ?  '#cad1d7': '#9daab6'  }} key={employees.id}>  
               
               <th scope="row" style={{width: '160px', height:'100%', backgroundColor: 'white'}}></th>
                    <td colSpan = '2' style={{  textAlign: 'center'}}>{employees.distribuidor} - {employees.asesor}</td>
                </tr> 
            ) 
       // })
      }  else { return (null)}
    }

    const store = useSelector(state => state.resumen.store);

    const [dat, setDat] = useState([]);
  
    useEffect(() => {
        setDat(store.estsuper)
    }, [store.estsuper])

    return (
      <>

      <div className="contacts">
         <style>
           {`@media print {.contacts{ page-break-before: always;}}`
           }
         </style>
         <ComponentToPrint />
       </div>
       </>
    )
  
  };
  
  export default PdfEstrategicoSuper;