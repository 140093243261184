import axios from "axios"; 
import { IdentityUrl } from "../../../config";
import { push } from 'connected-react-router';
import { closeMessageBox, openMessageBox } from "../../main/actions";

import {
  CLEAN_FORM, 
  UPDATE_FIELD_FILTER,
  ERROR_V_LIST,
  RECEIVE_V_LIST,
  REQUEST_V_LIST,
  SET_PAGE,
  SET_SELECTED_ROW,
  SET_MASK,
  SET_ORDER,
  ADVANCED_SEARCH_CLOSE,
  ADVANCED_SEARCH_OPEN,
  SET_SELECTED_MULTI,
  SET_PAGE_SIZE,
  SET_STATE_FORM,
  CLEAR_SELECT,
  SET_ENTITY,
  UPDATE_FIELD_ENTITY
} from "./types";

import { exportToSpreadsheet, cortarArreglo, searchVendedor} from "../../../helpers/commons";

export const setPage = (page) => ({ type: SET_PAGE, page });

export const cleanForm = () => ({ type: CLEAN_FORM });

export const setSelectedRow = (selected) => ({
  type: SET_SELECTED_ROW,
  selected,
});


//======================
export const onExport = () => async (dispatch, getState) => {
  // no se usa otra forma de exportar

  if (getState().gestionlistadeprecios.filters.distribuidorId   === 0) {
    dispatch(openMessageBox({
        button: 'ok',
        icon: 'warning',
        message: "Debe seleccionar un Agente.",
        callback: () => dispatch(closeMessageBox())
    }));
    return;
  }


  if (getState().gestionlistadeprecios.store.data.length === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "No hay información para exportar.",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }


  try {
    dispatch({ type: SET_MASK, loading: true });
    let head = getState().gestionlistadeprecios.table.columns;
    let json = getState().gestionlistadeprecios.store.data;


    exportToSpreadsheet(cortarArreglo(head,json), "Gestion Lista de Precios");
  } catch (error) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message:
          (error.response.data.eventLogId === 0
            ? ""
            : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    setTimeout(()=>{dispatch({ type: SET_MASK, loading: false })}, 1000)
  }
};
//=====================


export const onSearchListarPreciosClick = () => async (dispatch, getState) => {

  if (getState().gestionlistadeprecios.entity.vendedorId   === 0) {
    dispatch(openMessageBox({
        button: 'ok',
        icon: 'warning',
        message: "Debe seleccionar un Vendedor.",
        callback: () => dispatch(closeMessageBox())
    }));
    return;
  }
  
  let fields = [
    { dataKey: "nombre", label: "Lista Precios", type: "string", width: 225 },
  ];

  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de Lista de Precios",
      getData: async (params) => {
        let url = `${getState().main.resources.uriSvcSalesMobilityAPI}/api/v1/Tomas/listarPrecios?`
        + `distribuidorId=${getState().gestionlistadeprecios.filters.distribuidorId}`;
        let response = await axios.get(encodeURI(url), { params: params });

        //console.log(response)
        if(getState().gestionlistadeprecios.filters.distribuidorId === 27){
      
        //Filtrar LISTA vendedores que se requieren en caso perlube 
        
          let lista = [98, 99,100,102]
          let result =  response.data.filter(objeto => lista.includes(objeto.listaPrecioId));
          //console.log(result)

       var aux = {
          data: result,
          pageSize: 50,
          start: 0,
          total: response.data.length,
        };
      }else {
        aux = {
          data: response.data,
          pageSize: 50,
          start: 0,
          total: response.data.length,
        };
      }
        return aux;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(onUpdateFieldEntity("dLista", result[0].nombre));
          dispatch(onUpdateFieldEntity("listaPrecioId", parseInt(result[0].listaPrecioId)));
      

          //Construye el Json para agregar al list 
          getState().gestionlistadeprecios.store.data.push({dVendedor : getState().gestionlistadeprecios.filters.dVendedor ,
            dLista: getState().gestionlistadeprecios.filters.dLista,
            codigoLista: getState().gestionlistadeprecios.filters.listaPrecioId,
            vendedorId :getState().gestionlistadeprecios.filters.vendedorId, 
          })


          getState().gestionlistadeprecios.store.data2.push({
            listaPrecioId: getState().gestionlistadeprecios.filters.listaPrecioId,
            vendedorId :getState().gestionlistadeprecios.filters.vendedorId, 
          })
        }
      },
    })
  );
}

export const onSearchDistribuidorClick = () => async (dispatch) => {
  let fields = [
    { dataKey: "razonSocial", label: "Agente", type: "string", width: 225 },
  ];

  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de agente",
      getData: async (params) => {
        let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`;
        let response = await axios.get(encodeURI(url), { params: params });

        return response.data;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(onUpdateFieldFilter("distribuidorId", result[0].distribuidorId));
          dispatch(onUpdateFieldFilter("dDistribuidor", `${result[0].razonSocial}`));
          dispatch(onUpdateFieldFilter("vendedorId", '')); 
          dispatch(onUpdateFieldFilter("dVendedor", ''));
          // dispatch(onUpdateFieldFilter("codigoLista", 0));
          // dispatch(onUpdateFieldFilter("dLista", ""));
          // dispatch(onUpdateFieldFilter("dVendedor", ""));
          // dispatch(onUpdateFieldFilter("VendedorId", 0));

        }
      },
    })
  );
};

export const btnSearch = () => async (dispatch, getState) => {
  if (getState().gestionlistadeprecios.store.loading) {
    return;
  }

  if (getState().gestionlistadeprecios.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }

 
  dispatch({ type: SET_MASK, loading: true });
  
  let source = axios.CancelToken.source();
  dispatch({ type: REQUEST_V_LIST, source });

  try {
    let url;

    

    if(getState().gestionlistadeprecios.filters.vendedorId === '')
    {
      url =  `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Productos/gestionlistaprecio?` +
      `distribuidorId=${getState().gestionlistadeprecios.filters.distribuidorId}` +
      `&vendedorId=0`;

    }
    else{
     url =  `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Productos/gestionlistaprecio?` +
      `distribuidorId=${getState().gestionlistadeprecios.filters.distribuidorId}` +
      `&vendedorId=${getState().gestionlistadeprecios.filters.vendedorId}`;
    }

    let response = await axios.get(encodeURI(url));
    let data = response.data;
   
    if (data.length === 0) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "No existe Registro  para esta consulta",
          callback: () => dispatch(closeMessageBox()),
        })
      );
      return;
    }

    let columnas = getState().gestionlistadeprecios.table.columns;
    
    dispatch({ type: RECEIVE_V_LIST, columns: columnas, payload: data });
  } catch (error) {
    let msgError = "";
    dispatch({ type: ERROR_V_LIST });
    dispatch({ type: SET_MASK, loading: false });

    if (error.response) {
      msgError =
        error.response.data.eventLogId === 0
          ? "No existe Registro  para esta consulta "
          : `EventoId: ${error.response.data.eventLogId}. ` +
            error.response.data.message;
    } else {
      msgError = "Error al conectar con el servidor";
    }

    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message: msgError,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    dispatch({ type: SET_MASK, loading: false });
  }
};


export const onUpdateColumn = (column) => (dispatch, getState) => {
  getState().gestionlistadeprecios.table.columns.map((item, i) => {
    if (item.dataKey !== column.dataKey) {
      return item;
    } else {
      item.width = column.width;
    }
    return { };
  });
};

export const onUpdateFieldFilter = (key, value) => (dispatch, getState) => {
  dispatch({ type: UPDATE_FIELD_FILTER, key, value });
  
  // if (key !== 'nombre') {
  //       dispatch({ type: RECEIVE_V_LIST, column: [], payload: [] });
  // }
};

export const openAdvancedSearch = (opts) => ({
  type: ADVANCED_SEARCH_OPEN,
  payload: opts,
});

export const setOrder = (order, orderBy) => ({
  type: SET_ORDER,
  order,
  orderBy,
});

export const closeAdvancedSearch = () => ({ type: ADVANCED_SEARCH_CLOSE });

// export const onSearchVendedorPorRolClick = () => async (dispatch, getState) => {
//   let fields = [
//     { dataKey: "nombre", label: "Nombre",  type: "string",search: true,width: 225 },
//     { dataKey: "vendedorId", label: "Codigo", type: "string", width: 115 },
//   ];

//   if (getState().gestionlistadeprecios.filters.distribuidorId === 0) {
//     dispatch(
//       openMessageBox({
//         button: "ok",
//         icon: "warning",
//         message: "Debe seleccionar un Agente..",
//         callback: () => dispatch(closeMessageBox()),
//       })
//     );
//     return;
//   }
//   dispatch(openAdvancedSearch({
//       autoLoad: true,
//       data: [],
//       fields: fields,
//       paginated: true,
//       remote: true,
//       title: "Selección de Vendedor",
//       getData: async (params) => {
//           let url = `${getState().main.resources.uriSvcSalesMobilityAPI}/api/v1/Vendedores/listarvendedoresxdistribuidor`
//           + `?distribuidorId=${getState().gestionlistadeprecios.filters.distribuidorId}`               
//           let response = await axios.get(encodeURI(url), { params: params });
      
//           if(getState().gestionlistadeprecios.filters.distribuidorId === 27){
//             //Filtrar vendedores que se requieren en caso perlube 
                      
//             let vendor = [642,643,644,645,646]
//             let result2 =  response.data.filter(objeto => vendor.includes(objeto.vendedorId));
//             console.log(result2)
            
//             response.data = result2
//             console.log(response.data)
//       }
          

//          return searchVendedor(response, params.nombre); 
//       },
//       callback: (btn, result) => {
//           dispatch(closeAdvancedSearch());
//           if(btn === 'yes') {
//               dispatch(onUpdateFieldFilter('vendedorId', result[0].vendedorId));
//               dispatch(onUpdateFieldFilter('dVendedor', result[0].nombre)); 
//               dispatch(onUpdateFieldFilter("codigoLista", 0));
//               dispatch(onUpdateFieldFilter("dLista", ""));
//             }
//       }
//   }));
// };

export const onSearchVendedorPorRolClick = () => async (dispatch, getState) => {
  let fields = [
    { dataKey: "nombre", label: "Nombre",        type: "string",        search: true,        width: 225      },
    { dataKey: "vendedorId", label: "Codigo", type: "string", width: 115 },
  ];

  if (getState().gestionlistadeprecios.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }
  dispatch(openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de Vendedor",
      getData: async (params) => {
          let url = `${getState().main.resources.uriSvcSalesMobilityAPI}/api/v1/Vendedores/listarvendedoresxdistribuidor`
          + `?distribuidorId=${getState().gestionlistadeprecios.filters.distribuidorId}`               
          let response = await axios.get(encodeURI(url), { params: params });

          if(getState().gestionlistadeprecios.filters.distribuidorId === 27){
            //Filtrar vendedores que se requieren en caso perlube 
                      
            let vendor = [642,643,644,645,646]
            let result2 =  response.data.filter(objeto => vendor.includes(objeto.vendedorId));
            console.log(result2)
            
            response.data = result2
           // console.log(response.data)
      }
         
         return searchVendedor(response, params.nombre); 
      },
      callback: (btn, result) => {
          dispatch(closeAdvancedSearch());
          if(btn === 'yes') {
              dispatch(onUpdateFieldFilter('vendedorId', result[0].vendedorId));
              dispatch(onUpdateFieldFilter('dVendedor', result[0].nombre)); 
            }
      }
  }));
};

export const setFormState2 = (formState, id) => (dispatch, getState) => {
    
  switch (formState) {
      case 'edit':
          //if(id && parseInt(id) !== getState().planificarvisita.entity.marcaId) {
          dispatch(getListaPrecios());
          //}
          break;
      case 'new':
          dispatch(cleanForm());
          break;
      case 'search':
          dispatch(cleanForm());
          break;
      case 'view':
         // if(id && id !== getState().planificarvisita.entity.marcaId) {
          dispatch(getListaPrecios());
         // }
          break;
      default:
          break;
  }
  dispatch({ type: SET_STATE_FORM, formState });
};

export const getListaPrecios = () => async (dispatch, getState) => {
  try {
      dispatch({ type: SET_MASK, loading: true });

      let ax = getState().gestionlistadeprecios.store.aux;

      //console.log("DDD: ", ax)

      let payload = {
          ...ax
      };
      
      dispatch({ type: SET_ENTITY, payload });
      //dispatch({ type: SET_FORM });
  } catch (error) {
      if(error.response.status === 404) {
          dispatch(openMessageBox({ 
              button: 'ok', 
              icon: 'error', 
              message: 'No se encontro el registro solicitado.', 
              callback: () => { 
                  dispatch(closeMessageBox()); 
                  // dispatch(push({
                  //     pathname: `/channelmap/marca`,
                  //     search: getState().router.location.search,
                  //     hash: getState().router.location.hash
                  // }));
              }
          }));
      }
      else {
          dispatch(openMessageBox({ 
              button: 'ok', 
              icon: 'error', 
              message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
              callback: () => dispatch(closeMessageBox())
          }));
      }
  } finally {
      dispatch({ type: SET_MASK, loading: false });
  }
};


export const onbtnSave = () => (dispatch, getState) => {
  
//************************VALIDACION SI EXISTE REPETIDOS******************* */
  let data = getState().gestionlistadeprecios.store.data
  let Ids = []
        data?.map(e => Ids.push(e['vendedorId']))

        let unicos  =[ ...new Set(Ids)];
        
        //**************SACAR ELEMENTOS DUPLICADOS****** */
        let duplicados = [];
        
        const tempArray = [...Ids].sort();
        
        for (let i = 0; i < tempArray.length; i++) {
          if (tempArray[i + 1] === tempArray[i]) {
            duplicados.push(tempArray[i]);
          }
        }
        console.log(duplicados,"dupli",Ids,'Ids', unicos, 'Unicos')
//********************************************** */

        if(duplicados.length > 0){
            dispatch(
                openMessageBox({
                  button: "ok",
                  icon: "warning",
                  message: "Existe vendedores con Ids repetidos por favor eliminar..", // " `${duplicados}`+" 
                  callback: () => dispatch(closeMessageBox(),dispatch( setFormState('new'))),
                })
              );
              return;
        }


//************************************************************************* */
  if (getState().gestionlistadeprecios.filters.distribuidorId === 0) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "Debe seleccionar un Agente..",
          callback: () => dispatch(closeMessageBox()),
        })
      );
      return;
    }

  dispatch(openMessageBox({
      button: 'yesno',
      icon: 'question',
      message: '¿Desea guardar la información?',
      callback: btn => {
            //  dispatch(onSave(btn));  
      }
  }));
};

export const btnSave = () => (dispatch, getState) => {

  dispatch(openMessageBox({
    button: 'yesno',
    icon: 'question',
    message: '¿Desea guardar la información?',
    callback: btn => {
        if(getState().gestionlistadeprecios.entity.listaPrecioId !== 0) {
            dispatch(onUpdate(btn));
        }
        return
    }
}));
};


export const onUpdate = (btn) => async (dispatch, getState) => {
 

  dispatch(closeMessageBox());

  if(btn === 'yes') {
      try {
          dispatch({ type: SET_MASK, loading: true });
          let distribuidorId = getState().gestionlistadeprecios.filters.distribuidorId
         // let listaspreciosVendedor = getState().gestionlistadeprecios.store.aux

          var recordset = {
              'distribuidorId' : distribuidorId,
              'listaspreciosVendedor' : [], // listaspreciosVendedor ,
          };
    
          let json = {
                  "vendedorId":  getState().gestionlistadeprecios.entity.vendedorId,
                  "listaPrecioId": getState().gestionlistadeprecios.entity.listaPrecioId,
                  "listaPrecioVendedorId": getState().gestionlistadeprecios.entity.listaPrecioVendedorId,
          };

          recordset.listaspreciosVendedor.push(json);
           
          //console.log(recordset)
          let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Vendedores/listapreciovendedor`;
          let response = await axios.post(url, recordset , {headers: {'Content-Type': 'application/json'}});
          let data = response.data;

          
       
                  //dispatch(setFormState('search'));
          dispatch(openMessageBox({ 
                      button: 'ok', 
                      icon: 'info', 
                      message: 'Modificación realizado con exito.', 
                      callback: () => { 
                          dispatch(closeMessageBox()); 
                          dispatch(btnSearch());
                          dispatch({ type: RECEIVE_V_LIST, columns: [], payload: [] });
                          dispatch(push({
                            pathname: `/fuerzaventas/gestionlistadeprecios`,
                            search: getState().router.location.search,
                            hash: getState().router.location.hash
                        }));
                      }
          }));


         
                
      } catch (error) {
          dispatch(openMessageBox({ 
              button: 'ok', 
              icon: 'error', 
              message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
              callback: () => dispatch(closeMessageBox())
          }));
      } finally {
          dispatch({ type: SET_MASK, loading: false });
         // dispatch(btnSearch());  
      }
  }
};

export const onbtnDelete = () => (dispatch, getState) => {

    if (getState().gestionlistadeprecios.store.selected.length === 0) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "No existe registros seleccionados!!!.",
          callback: () => dispatch(closeMessageBox()),
        })
      );
      return;

    } else {
        dispatch(openMessageBox({
          button: 'yesno',
          icon: 'question',
          message: 'Se elimanará el/los registro(s)) permanentemente. ¿Desea continuar?',
          callback: btn => dispatch( onDelete(btn)) 
      }));
    }
};

export const onDelete = (btn) => async (dispatch, getState) => {
  dispatch(closeMessageBox());
 // console.log('Pase a onDelete');
  if(btn === 'yes') {
      try {
//**********************Borrar por selección******* */
        let data = getState().gestionlistadeprecios.store.data 
        let selected = getState().gestionlistadeprecios.store.selected

        for (let i = 0; i < selected.length; i++) {
          const objetoABorrar = selected[i];
          
          const index = data.findIndex(objeto => (
            JSON.stringify(objeto) === JSON.stringify(objetoABorrar)
          ));
        
          if (index !== -1) {
            data.splice(index, 1);
          }
        }

//****************************************************** */
        
        
        dispatch(cleanForm())
        getState().gestionlistadeprecios.store.data =  selected//dataEliminar

          dispatch({ type: SET_MASK, loading: true });
          //dispatch({ type:  RECEIVE_DATA, payload: dataEliminar})

          dispatch(openMessageBox({ 
              button: 'ok', 
              icon: 'info', 
              message: 'El registro se eliminó correctamente.', 
              callback: () => { 
                  dispatch(closeMessageBox());
                 // dispatch(btnSearch());
              }
          }));
      } catch (error) {
          dispatch(openMessageBox({ 
              button: 'ok', 
              icon: 'error', 
              message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
              callback: () => dispatch(closeMessageBox())
          }));
      } finally {
          dispatch({ type: SET_MASK, loading: false });
      }
  }
};

export const setSelectedMulti = (selected) =>(dispatch, getState) => (dispatch ({ type: SET_SELECTED_MULTI, payload: selected }));

export const setPageSize = (pageSize) => ({ type: SET_PAGE_SIZE, pageSize });

export const setFormState = (formState) => (dispatch, getState) => {
  dispatch({ type: SET_STATE_FORM, formState });
};

export const clearSelect = (key) => ({ type: CLEAR_SELECT , key})

export const onUpdateFieldEntity = (key, value) => (dispatch) => { dispatch({ type: UPDATE_FIELD_ENTITY, key, value });};

