import axios from 'axios';
import format from "date-fns/format";
import { IdentityUrl } from '../../../config';
import { closeAdvancedSearch, closeMessageBox, openAdvancedSearch, openMessageBox } from '../../main/actions';
import {

    RECEIVE_DATA,
    RECEIVE_V_LIST, 
    SET_MASK,
    SET_ORDER,
    SET_SELECTED_ROW,
    UPDATE_FIELD_FILTER,
} from './types';

import { exportToSpreadsheet } from '../../../helpers/commons'

export const btnExport = () => async (dispatch, getState) => {
    
    if (getState().reporteseguimientoprecios.filters.distribuidorId   === 0) {
        dispatch(openMessageBox({
            button: 'ok',
            icon: 'warning',
            message: "Debe seleccionar un Agente..",
            callback: () => dispatch(closeMessageBox())
        }));
    return;
    }
    if(getState().reporteseguimientoprecios.store.data.length === 0) {
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'warning', 
            message: 'No hay información para exportar.', 
            callback: () => dispatch(closeMessageBox())
        }));
        return;
    }
    
    try {
        dispatch({ type: SET_MASK, loading: true });

        let head = getState().reporteseguimientoprecios.table.columns;
        var aux = [];
         // eslint-disable-next-line no-redeclare, no-unused-vars
            for (var c in head)
            // eslint-disable-next-line no-new-object
                {
                  aux[0] = 'Nombre'
                  aux[1] = 'Distribuidor';//nit
                  aux[2] = 'Cantidad'; //Object.values(head[2])[1];//nombre
                  aux[3] = 'Rankin'; //Object.values(head[3])[1];//direccion
                }

        var carArray = [];

        carArray.push(aux);

        let json = getState().reporteseguimientoprecios.store.data;
        //console.log(json)

        for(var i in json) {
            let x =   Object.values(json[i]);
          
            
            carArray.push(x);
          }
        
        
          exportToSpreadsheet(carArray,  'ReporteSeguimientoPrecios') ;

    } catch (error) {
        console.log(error)
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'error', 
            message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
            callback: () => dispatch(closeMessageBox())
        }));
    } finally {
        setTimeout(() => {dispatch({ type: SET_MASK, loading: false })}, 1000);
    }
};

export const btnSearch = () => async (dispatch, getState) => {

    // if (getState().reporteseguimientoprecios.filters.distribuidorId   === 0) {
    //     dispatch(openMessageBox({
    //         button: 'ok',
    //         icon: 'warning',
    //         message: "Debe seleccionar un Agente..",
    //         callback: () => dispatch(closeMessageBox())
    //     }));
    // return;
    // }  
    try {
        dispatch({ type: SET_MASK, loading: true });

        if (getState().reporteseguimientoprecios.store.loading) {
            return;
        }
 
        let url = `${getState().main.resources.uriSvcSalesMobilityAPI}/api/v1/Tomas/listartomasprecio?`
            + `distribuidorId=${getState().reporteseguimientoprecios.filters.distribuidorId}`
            + `&supervisorId=${getState().reporteseguimientoprecios.filters.supervisorId}`
            + `&desde=${format(getState().reporteseguimientoprecios.filters.desde, 'yyyy-MM-dd')}`
            + `&hasta=${format(getState().reporteseguimientoprecios.filters.hasta, 'yyyy-MM-dd')}`;
        let response = await axios.get(encodeURI(url));
        let data = response.data;

        if (data.length === 0) {
            dispatch(openMessageBox({
                button: 'ok',
                icon: 'warning',
                message: "No existe Registro  para esta consulta",
                callback: () => dispatch(closeMessageBox())
            }));
        return;
        }
      

        let columnas = getState().reporteseguimientoprecios.table.columns;
       
        dispatch({ type: RECEIVE_V_LIST, columns: columnas, payload: data});
        
    } catch (error) {
       
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'error', 
            message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
            callback: () => dispatch(closeMessageBox())
        }));
    } finally {
        dispatch({ type: SET_MASK, loading: false });
    }

    
};



export const onSearchDistribuidorClick = () => async (dispatch) => {
    let fields = [
        { 'dataKey': 'razonSocial', 'label': 'Agente', 'type': 'string', 'width': 225 }
    ];

    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de agente',
        getData: async (params) => {
            let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`
            let response = await axios.get(encodeURI(url), { params: params });
            
            return response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldFilter('distribuidorId', result[0].distribuidorId));
                dispatch(onUpdateFieldFilter('dDistribuidor', `${result[0].razonSocial}`));
                dispatch(onUpdateFieldFilter('supervisorId', ''));
                dispatch(onUpdateFieldFilter('dSupervisor', ''));
            }
        }
    }));
};

export const onSearchSupervisorClick = () => async (dispatch, getState) => {
    let fields = [
        { 'dataKey': 'nombre', 'label': 'Nombre', 'type': 'string', 'search': true, 'width': 225 },
        { 'dataKey': 'nombreUsuario', 'label': 'Usuario', 'type': 'string', 'width': 115 }
    ];
    
    if (getState().reporteseguimientoprecios.filters.distribuidorId === 0) {
        dispatch(
          openMessageBox({
            button: "ok",
            icon: "warning",
            message: "Debe seleccionar un Agente..",
            callback: () => dispatch(closeMessageBox()),
          })
        );
        return;
      }

    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de supervisor',
        getData: async (params) => {
            let url = `${IdentityUrl}/api/v1/usuarios?rol=Supervisor`
            let response = await axios.get(encodeURI(url), { params: params });
            let distribuidorId = getState().reporteseguimientoprecios.filters.distribuidorId;

            if(distribuidorId > 0) {
                response.data.data = response.data.data.filter(f => f.distribuidorId === distribuidorId);
            }

            return response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldFilter('supervisorId', result[0].usuarioId));
                dispatch(onUpdateFieldFilter('dSupervisor', `${result[0].nombre}`));
            }
        }
    }));
};



export const onUpdateColumn = (column) => (dispatch, getState) => {
    getState().reporteseguimientoprecios.table.columns.map((item , i)=> {     
       if(item.dataKey !== column.dataKey) {
           return item;
       }else{
               item.width =  column.width;
       }
       return {
          // ...item,
         //  width: column.width
       };
      
   });
};

export const onUpdateFieldFilter = (key, value) => (dispatch, getState) => {
    dispatch({ type: UPDATE_FIELD_FILTER, key, value })
    //const  da = []

    dispatch( onUpdateFielddata());
   // dispatch({ type: RECEIVE_DATA, payload:[],  store:[] })
    dispatch({ type: RECEIVE_DATA, payload: [],storeData:[]});
    
}

export const onUpdateFielddata = (key, value) => (dispatch, getState) => {  

   // dispatch({ type: UPDATE_FIELD_DATA, key, value })

     //dispatch({ type: RECEIVE_DATA, columns: [], data: [] })
  //  dispatch({ type: RECEIVE_DATA, payload:[],  store:[] })
    //dispatch({ type: RECEIVE_DATA, payload: data, storeData });
    };





export const setOrder = (order, orderBy) => ({ type: SET_ORDER, order, orderBy });

export const setSelectedRow = (selected) => ({ type: SET_SELECTED_ROW, selected});