import React, { useEffect} from 'react';
import { useDispatch} from 'react-redux';
import {
    btnSearch,
    btnExport 
} from "../../store/movilidad/reportefrecuenciavisita/actions";
// import {
//    CLEAN_FORM
// } from '../../store/movilidad/planificarvisita/types';

import { Body, Footer, Page } from '@sigeco/tools';

import { Button, Icon, makeStyles } from "@material-ui/core";

import ReporteFrecuenciaVisitaFilter from '../../components/movilidad/reportefrecuenciavisita/ReporteFrecuenciaVisitaFilter';
import ReporteFrecuenciaVisitaList from '../../components/movilidad/reportefrecuenciavisita/ReporteFrecuenciaVisitaList';

const useStyles = makeStyles(() => ({
    content: {
        padding: '0.5rem 0.75rem 0.75rem'
    },
    root: {
        flex: '0 0 auto',
        marginBottom: '0.75rem',
        '@media (min-width: 1401px)': {
            marginBottom: '1rem',
        }
    }
}));

const ReporteFrecuenciaVisita= ({onClose, setTitle}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    
    useEffect(() => {
        setTitle('Búsqueda');
    // eslint-disable-next-line
    }, []);

    function onBtnClose() {
        onClose();
    }

    return (
        <Page className={classes.root}>
            
            <Body>
                <ReporteFrecuenciaVisitaFilter />
                <ReporteFrecuenciaVisitaList />
            </Body>

            <Footer>
                <Button
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={() => dispatch(btnExport())}
                    startIcon={<Icon className="fas fa-file-excel"/>}
                  >
                    Exportar
                </Button>
              
                <Button
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={() => dispatch(btnSearch())}
                    startIcon={<Icon className="fas fa-search"/>}
                >
                    Buscar
                </Button>
                <Button 
                    color="primary" 
                    size="small" 
                    variant="outlined" 
                    onClick={() => onBtnClose()} 
                    startIcon={<Icon className="fas fa-door-open"/>}
                >
                    Salir
                </Button>
            </Footer>
        </Page>
    )
};

export default ReporteFrecuenciaVisita;