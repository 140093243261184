import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeMessageBox, openMessageBox } from "../../../store/main/actions";
import {
     onUpdateCosto
} from '../../../store/fuerzaventas/costosproductos/actions';
import './table.css'

import { FieldSet} from '@sigeco/tools';
import makeStyles from '@material-ui/core/styles/makeStyles';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column'
    }, 
    tableWrap: {
        flex: '1 1 auto'
    }
}));

const CostosProductosList = () => {
    const classes = useStyles();
    const store = useSelector(state => state.costosproductos.store);
    const dispatch = useDispatch();


      const [data, setData] = useState([]);

       
      useEffect(() => {
          setData( store.data)
      }, [store.data]);
  
  
      const [inEditMode, setInEditMode] = useState({
          status: false,
          rowKey: null
      });
  
      const [unitPrice, setUnitPrice] = useState(null);
  
      /**
       *
       * @param id - The id of the product
       * @param currentUnitPrice - The current unit price of the product
       */
      const onEdit = ({id, currentUnitPrice}) => {
          setInEditMode({
              status: true,
              rowKey: id
          })
          setUnitPrice(currentUnitPrice);
      }
  
      /**
       *
       * @param id
       * @param newUnitPrice
       */
      const  updateInventory = ({id, newUnitPrice}) => {
              dispatch(onUpdateCosto(id, newUnitPrice));
              onCancel();
      }
  
      /**
       *
       * @param id -The id of the product
       * @param newUnitPrice - The new unit price of the product
       */
      const onSave = ({id, newUnitPrice}) => {
         
          if (  !isNaN(newUnitPrice) ){
                if (newUnitPrice > 0){
                            updateInventory({id, newUnitPrice});
                }else{
                    dispatch(
                        openMessageBox({
                          button: "ok",
                          icon: "error",
                          message: "Escriba un costo válido ",
                          callback: () => dispatch(closeMessageBox()),
                        })
                      );
                    return
                }
          }else{
            dispatch(
                openMessageBox({
                  button: "ok",
                  icon: "error",
                  message: "Escriba un costo válido ",
                  callback: () => dispatch(closeMessageBox()),
                })
              );
          }
      }
  
      const onCancel = () => {
          // reset the inEditMode state value
          setInEditMode({
              status: false,
              rowKey: null
          })
          // reset the unit price state value
          setUnitPrice(null);
      }

    return (
        <>
        
         <div className="container" style={{backgroundColor: 'white', width:'100%'}}>
        <FieldSet className={classes.root} iconCls="fas fa-th-list" title="Resultados"   >
         
            <table >
                <thead> 
                <tr>     
                    <th style={{textAlign : 'left', width: '5%'}}>  </th>
                    <th style={{textAlign : 'left', width: '10%'}}> Código </th>
                    <th style={{width: '30%', height:'100%'}}>Descripción</th> 
                    <th style={{width: '20%', height:'100%', textAlign : 'center'}}>Costo</th>
                    <th >Fecha</th>
                    <th style={{textAlign : 'center', width: '50%'  }}>Acción</th>
                </tr>
                </thead>
                <tbody>
                {
                        data.map((item, i) => (
                            <tr key={i}>
                                <td style={{width: '5%', height:'100%'}}>{i+1 }</td>
                                <td style={{width: '10%', height:'100%', textAlign: 'center'}}>{item.Codigo}</td>
                                <td style={{width: '30%', height:'100%'}}>{item.DProducto}</td>
                                <td style={{width: '20%', height:'100%', textAlign : 'center'}}>
                                    {
                                        inEditMode.status && inEditMode.rowKey === item.ProductoId ? (
                                            <>
                                             
                                            <input type="number" step="1" name="numero" autoFocus = 'true'
                                            value={unitPrice} placeholder={ item.Costo}
                                                onChange={(event) => setUnitPrice(event.target.value)} 
                                            />
                                           
                                            <span className="validity"></span>
                                            </>
                                        ) : (
                                            item.Costo
                                        )
                                    }
                                </td>
                                <td style={{width: '20%', height:'100%', textAlign: 'center'}}>{item.Fecha}</td>
                                <td colSpan="2" style={{width: '400px', height:'100%',    textAlign: 'center' }}>
                                {
                                    inEditMode.status && inEditMode.rowKey === item.ProductoId ? (
                                        <React.Fragment>
                                            <button  style={{ width: 30 , backgroundColor: 'white', borderColor: 'darkgray' }}
                                                className={"btn-success"}                    
                                                icon={'fa-solid fa-arrow-right-to-bracket'}
                                                onClick={() => onSave({id: item.ProductoId, newUnitPrice: unitPrice})}
                                            >                     
                                                        <i className="fas fa-save"></i>
                                            </button>
                                            <i >     </i>
                                            <button style={{ width: 30 , backgroundColor: 'white', borderColor: 'darkgray' }}
                                                className={"btn-secondary"}
                                                onClick={() => onCancel()}
                                            >
                                               
                                                    <i className="fas fa-times"></i>
                                            </button>
                                        </React.Fragment>
                                    ) : (
                                        <button  style={{ width: 30, backgroundColor: 'white', borderColor: 'darkgray' }}
                                            className={"btn-primary"}
                                            onClick={() => onEdit({id: item.ProductoId, currentUnitPrice: item.Costo})}
                                        >
                                       <i className="fas fa-marker"></i>
                                        </button>
                                    )
                                }
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
         </FieldSet>
        </div>

    

        <FieldSet className={classes.root} style={{height: '3px'}} >
            <div className={classes.tableWrap}>
              
            </div>
        </FieldSet>

    </>
    )
};

export default CostosProductosList;