import axios from 'axios';
import format from "date-fns/format";
import { IdentityUrl } from '../../../config';
import { closeAdvancedSearch, closeMessageBox, openAdvancedSearch, openMessageBox } from '../../main/actions';
import {
    RESTART_STATE,
    SET_MASK,
    UPDATE_FIELD_FILTER
} from './types';

//import * as XLSX from "xlsx";
import {  exportChannelDatabaseExcel } from '../../../helpers/commons'

export const btnExport = () => async (dispatch, getState) => {

    if (getState().basedatos.filters.olaId   === 0) {
        dispatch(openMessageBox({
            button: 'ok',
            icon: 'warning',
            message: "Debe seleccionar un Ola..",
            callback: () => dispatch(closeMessageBox())
        }));
    return;
    } 

    if (getState().basedatos.filters.distribuidorId   === 0) {
        dispatch(openMessageBox({
            button: 'ok',
            icon: 'warning',
            message: "Debe seleccionar un Agente..",
            callback: () => dispatch(closeMessageBox())
        }));
    return;
    }       
    try {
        dispatch({ type: SET_MASK, loading: true });

        let url = `${getState().main.resources.uriSvcSalesMobilityAPI}/api/v1/reportes/basedatos/exportar?`
            + `distribuidorId=${getState().basedatos.filters.distribuidorId}`
            + `&olaChannelMapId=${getState().basedatos.filters.olaId}`
            + `&usuarioId=${getState().basedatos.filters.usuarioId}`
            + `&desde=${format(getState().basedatos.filters.desde, 'yyyy-MM-dd')}`
            + `&hasta=${format(getState().basedatos.filters.hasta, 'yyyy-MM-dd')}`;
        console.log(url)
            let response = await axios.get(encodeURI(url)); 
       
       let json = response.data.split('\n'); 
        const JSONData = [];
        var i= 0
        json.forEach(function(item){
           ;
            JSONData.push(item.split(','));
            if (!isNaN(parseFloat(JSONData[i][17] ))){
                        JSONData[i][17] = parseFloat(JSONData[i][17] )
            }          
            i++           
        });

        //exportToSpreadsheet(JSONData, 'basedatosChannelmap') ;
        exportChannelDatabaseExcel(JSONData, 'basedatosChannelmap') ;
 
    } catch (error) {
        let msgError = '';
        dispatch({ type: SET_MASK, loading: false });

        if (error.response) {
        
            if(error.response.status === 400) {
                   msgError = (error.response.data.eventLogId === 0 ? 'No existe Registro  para esta Consulta ' : `EventoId: ${error.response.data.eventLogId}. `+ error.response.data.message) 
            }else{
               msgError = (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message;
            }
           } else {
               // eslint-disable-next-line no-unused-vars
               msgError = 'Error al conectar con el servidor';
           }
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'error', 
            //message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
            message: msgError,
            callback: () => dispatch(closeMessageBox())
        }));
    } finally {
        dispatch({ type: SET_MASK, loading: false });
    }
};

export const onSearchOlaChannelMapClick = () => async (dispatch, getState) => {
    let fields = [
      { dataKey: "nombre", label: "Ola", type: "string", width: 250 },
      { dataKey: "desde", label: "Dede", type: "string", width: 110 },
      { dataKey: "hasta", label: "Hasta", type: "string", width: 110 },
    ];
  
  
    try {
      dispatch(
        openAdvancedSearch({
          autoLoad: true,
          data: [],
          fields: fields,
          paginated: true,
          remote: true,
          title: "Selección de Ola Channel Map",
          getData: async (params) => {
            let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/OlasChannelMap/olas`;
            let response = await axios.get(encodeURI(url), { params: params });
            response = {
              data: {
                start: 0,
                pageSize: 50,
                total: response.data.length,
                data: response.data,
              },
            };
            return response.data;
          },
          callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if (btn === "yes") {
              dispatch(onUpdateFieldFilter("olaId", result[0].olaChannelMapId));
              dispatch(onUpdateFieldFilter("dOla", result[0].nombre));
            }
          },
        })
      );
    } catch (error) {
      if (error.response) {
        dispatch(
          openMessageBox({
            button: "ok",
            icon: "error",
            message:
              (error.response.data.eventLogId === 0
                ? ""
                : `EventoId: ${error.response.data.eventLogId}. `) +
              error.response.data.message,
            callback: () => dispatch(closeMessageBox()),
          })
        );
      }
    }
  };
export const onSearchDistribuidorClick = () => async (dispatch) => {
    let fields = [
        { 'dataKey': 'razonSocial', 'label': 'Agente', 'type': 'string', 'width': 225 }
    ];

    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de agente',
        getData: async (params) => {
            let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`
            let response = await axios.get(encodeURI(url), { params: params });

            return response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldFilter('distribuidorId', result[0].distribuidorId));
                dispatch(onUpdateFieldFilter('dDistribuidor', `${result[0].razonSocial}`));
            }
        }
    }));
};

export const onUpdateColumn = (column) => (dispatch, getState) => {
    // let columns = getState().resumen.table.columns.map(item => {
    //     if (item.dataKey !== column.dataKey) {
    //         return item;
    //     }

    //     return {
    //         ...item,
    //         width: column.width
    //     }
    // });

    // dispatch({ type: UPDATE_TABLE_COLUMN, columns });
};
export const onUpdateFieldFilter = (key, value) => ({ type: UPDATE_FIELD_FILTER, key, value });

export const restartState = () => ({ type: RESTART_STATE });