import {
  ADD_VENDEDOR,
  RECEIVE_DATA,
  RECEIVE_DATA2,
  RESTART_STATE,
  SET_MASK,
  UPDATE_FIELD_FILTER,
  UPDATE_OLA_FIELD_FILTER,
  UPDATE_DIST_FIELD_FILTER,
  ACTUALIZAR_MODAL,
  ACTUALIZAR_SUB_MODAL
} from "./types";

const initialState = {
  data: [],
  detalle: [],
  detalleModal: [],
  detalleModal1: [],

  summary: {
    cuentaPDV: 0,
    mercadoPDV: 0,
    glnpdv: 0,
    glnpdvmercado: 0,
  },
  loading: false,
  filters: {
    suma :0,
    olaId: 0,
    distribuidorId: 0,
    ubigeoId: 0,
    vendedor: "",
    dOla: "",
    dDistribuidor: "",
    dUbigeo: "",
    dCliente: "",
    nit: "",
    departamentoId: "",
    dDepartamento: "",
    municipioId: "",
    dMunicipio: "",
    marcaId: "",
    dMarca: "",
    lobId: "",
    dLob: "",
    canalId: "",
    dCanal: "",
    nombre: "",
  },
};

export const reporteconstruccionReducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case RECEIVE_DATA:
      return {
        ...state,
        data: action.payload,
      };
      case RECEIVE_DATA2:
      return {
        ...state,
        detalle: action.payload,
      };
    case RESTART_STATE:
      return {
        ...initialState,
      };
    case SET_MASK:
      return {
        ...state,
        loading: action.loading,
      };
    case UPDATE_FIELD_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.key]: action.value,
        },
      };
    case UPDATE_OLA_FIELD_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          dCliente: "",
          nit: "",
          vendedor: "",
          dDepartamento: "",
          dMunicipio: "",
          dMarca: "",
          dLob: "",
          dCanal: "",
        },
      };
    case UPDATE_DIST_FIELD_FILTER:
    return {
        ...state,
        filters: {
            ...state.filters,
            dCliente: "",
            nit: "",
            vendedor: "",
            dDepartamento: "",
            dMunicipio: "",
            dMarca: "",
            dLob: "",
            dCanal: "",
        },
    };
    case ADD_VENDEDOR:
      console.log(action.payload)
      return {
          ...state,
          filters: {
              ...state.filters,
              vendedor: action.payload
          }
      };
      case ACTUALIZAR_MODAL:
        console.log(state.detalle)

       //opcion para restituir los dSegmentos que estan en vacio '' (Probablemente eliminar)
        state.detalle.map(f => f.dsegmento === 'Sin Clasificación' ? f.dsegmento ='' : f.dsegmento )

        let filter = state.detalle.filter(f => f.lob === action.payload.dLob && f.segmento ===action.payload.dsegmento)
        console.log(filter );
        return {
          ...state,
          detalleModal: filter,
          detalleModal1: filter

        };
        case ACTUALIZAR_SUB_MODAL:
          return {
            ...state,
            detalleModal : action.payload,
          }

    default:
      return state;
  }
};


