import axios from 'axios';
import format from "date-fns/format";
import { push } from 'connected-react-router';
import { IdentityUrl } from '../../../config';
import { closeAdvancedSearch, closeMessageBox, openAdvancedSearch, openMessageBox } from '../../main/actions';
import {
    //ADD_RUTAS_CLIENTE,
    SET_STATE_FORM,
    RECEIVE_DATA,
    RECEIVE_V_LIST, 
    SET_MASK,
    SET_ORDER,
    SET_SELECTED_ROW,
    UPDATE_FIELD_FILTER,
    CLEAN_FORM,
    SET_SELECTED_MULTI,
    SET_PAGE_SIZE,
    SET_PAGE,
    CLEAR_SELECT,
    RESTART_STATE,
    ADD_VISITA,
    UPDATE_FIELD_ENTITY,
    SET_ENTITY
    
} from './types';

import { exportToSpreadsheet, cortarArreglo } from "../../../helpers/commons";

export const setFormState2 = (formState, id) => (dispatch, getState) => {
    
  switch (formState) {
      case 'edit':
          //if(id && parseInt(id) !== getState().planificarvisita.entity.marcaId) {
              dispatch(getVisita());
          //}
          break;
      case 'new':
          dispatch(cleanForm());
          break;
      case 'search':
          dispatch(cleanForm());
          break;
      case 'view':
         // if(id && id !== getState().planificarvisita.entity.marcaId) {
              dispatch(getVisita());
         // }
          break;
      default:
          break;
  }
  dispatch({ type: SET_STATE_FORM, formState });
};

export const getVisita = () => async (dispatch, getState) => {
  try {
      dispatch({ type: SET_MASK, loading: true });

      let ax = getState().planificarvisita.store.aux;

     // console.log("DDD: ", ax)

      let payload = {
          ...ax
      };
      
      dispatch({ type: SET_ENTITY, payload });
      //dispatch({ type: SET_FORM });
  } catch (error) {
      if(error.response.status === 404) {
          dispatch(openMessageBox({ 
              button: 'ok', 
              icon: 'error', 
              message: 'No se encontro el registro solicitado.', 
              callback: () => { 
                  dispatch(closeMessageBox()); 
                  // dispatch(push({
                  //     pathname: `/channelmap/marca`,
                  //     search: getState().router.location.search,
                  //     hash: getState().router.location.hash
                  // }));
              }
          }));
      }
      else {
          dispatch(openMessageBox({ 
              button: 'ok', 
              icon: 'error', 
              message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
              callback: () => dispatch(closeMessageBox())
          }));
      }
  } finally {
      dispatch({ type: SET_MASK, loading: false });
  }
};


export const btnExport = () => async (dispatch, getState) => {
    
    if (getState().planificarvisita.filters.distribuidorId   === 0) {
        dispatch(openMessageBox({
            button: 'ok',
            icon: 'warning',
            message: "Debe seleccionar un Agente..",
            callback: () => dispatch(closeMessageBox())
        }));
    return;
    }
    if(getState().planificarvisita.store.data.length === 0) {
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'warning', 
            message: 'No hay información para exportar.', 
            callback: () => dispatch(closeMessageBox())
        }));
        return;
    }
    
    try {
        dispatch({ type: SET_MASK, loading: true });
                  const aux =  [
                    { dataKey: 'DCliente', label: 'Nombre Sucursal', width: 370 },
                    { dataKey: 'Direccion', label: 'Dirección', width: 460 },                 
                    { dataKey: 'IPT', label: 'IPT', width: 352 },   
                    // { dataKey: 'SucursalId', label: 'Sucursal Id', width: 80 },
                    { dataKey: 'UsuarioRegistro', label: 'UsuarioRegistro', width: 352}    
                ]
    
    let json = getState().planificarvisita.store.data;


   exportToSpreadsheet(cortarArreglo(aux, json), 'ReportePlanificarVisita') ;

    } catch (error) {
        console.log(error)
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'error', 
            message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
            callback: () => dispatch(closeMessageBox())
        }));
    } finally {
        setTimeout(() => {dispatch({ type: SET_MASK, loading: false })}, 1000);
    }
};

export const btnSearch = () => async (dispatch, getState) => {
    if (getState().planificarvisita.filters.distribuidorId === 0) {
        dispatch(
          openMessageBox({
            button: "ok",
            icon: "warning",
            message: "Debe seleccionar un Agente..",
            callback: () => dispatch(closeMessageBox()),
          })
        );
        return;
      }

    if (getState().planificarvisita.filters.vendedorId === '' || !getState().planificarvisita.filters.vendedorId ) {
        dispatch(
          openMessageBox({
            button: "ok",
            icon: "warning",
            message: "Debe seleccionar un Vendedor..",
            callback: () => dispatch(closeMessageBox()),
          })
        );
        return;
      }

    try {
        dispatch({ type: SET_MASK, loading: true });
        dispatch(clearSelect('data'))


        if (getState().planificarvisita.store.loading) {
            return;
        }
 
        let url = `${getState().main.resources.uriSvcSalesMobilityAPI}/api/v1/Rutas/planificadasweb?`
            + `usuarioId=${getState().planificarvisita.filters.vendedorId}`
            + `&fecha=${format(getState().planificarvisita.filters.desde, 'yyyy-MM-dd')}`;
        let response = await axios.get(encodeURI(url));
        let data = response.data;

        
        // eslint-disable-next-line array-callback-return
        var json = 
          data.map(item => item.ProspectoId === 0 ? {...item, SucursalId:"Si", ProspectoId: "NO" ,Estado: "V. Planificada" }
          : {...item, SucursalId:"No", ProspectoId: "Si" ,Estado: "V. Planificada"});
          //console.log(response, 'que es')


        if (data.length === 0) {
            dispatch(openMessageBox({ 
                button: 'ok',
                icon: 'warning',
                message: "No existe Registro  para esta consulta",
                callback: () => dispatch(closeMessageBox())
            }));
            return;
        }

        let columnas = getState().planificarvisita.table.columns;
       
        dispatch({ type: RECEIVE_V_LIST, columns: columnas, payload: json});
        
    } catch (error) {
       
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'error', 
            message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
            callback: () => dispatch(closeMessageBox())
        }));
    } finally {
        dispatch({ type: SET_MASK, loading: false });
    }
 
};

export const onbtnSave = () => (dispatch, getState) => {

    if (getState().planificarvisita.entity.distribuidorId === 0) {
        dispatch(
          openMessageBox({
            button: "ok",
            icon: "warning",
            message: "Debe seleccionar un Agente..",
            callback: () => dispatch(closeMessageBox()),
          })
        );
        return;
      }

    if(getState().planificarvisita.arreglo.dataVisita.length === 0)
    {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message:
            "No tiene ninguna planificación que enviar.",
          callback: () => dispatch(closeMessageBox())
        })
      );
      return; // Retorna aquí para detener la ejecución de la función


    }

    dispatch(openMessageBox({
        button: 'yesno',
        icon: 'question',
        message: '¿Desea guardar la información?',
        callback: btn => {
                dispatch(onSave(btn));  
        }
    }));
};

export const onSave = (btn) => async (dispatch, getState) => {
  dispatch(closeMessageBox());

  if(btn === 'yes') {
      try {
          dispatch({ type: SET_MASK, loading: true });

          var recordset = {
              'vendedorId' : getState().planificarvisita.entity.vId,
              'rutasPlanificadas': []
          }

            //console.log(getState().planificarvisita.arreglo.dataVisita)

            // eslint-disable-next-line array-callback-return
           
          let aux = getState().planificarvisita.arreglo.dataVisita;

          //Para no enviar la misma planificacion repetida en caso lo seleccionen 2 veces
         // falta implementar en un futuro(por que creo que se puede repetir)
          // let marcasIds = [];
          // let marcasax = [];
          
          // aux.forEach((item) => {
          //   if (!marcasIds.includes(item.marca)) {
          //     marcasIds.push(item.marca);
          //     marcasax.push(item);
          //   }
          // });
         // console.log("DATAA1:", marcasax)


          for (var c in aux) {
            let json = {}
            if(aux[c].sucursalId === 'no')
            {
               json = {
                  //"clienteSucursalId":  aux[c].sucursalId,
                  "prospectoId": aux[c].prospectoId,
                  "fecha":`${format(aux[c].fecha, 'yyyy-MM-dd')}`  
              }
            }else
            {
               json = {
                "clienteSucursalId":  aux[c].sucursalId,
                //"prospectoId": aux[c].prospectoId,
                "fecha":`${format(aux[c].fecha, 'yyyy-MM-dd')}`  
            }

            }
              recordset.rutasPlanificadas.push(json);
           }

          // console.log("DATAA1:", recordset)


            let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/rutas/planificadas`;
            let response = await axios.post(url, recordset , {headers: {'Content-Type': 'application/json'}});
            let data = response.data;
         
         if (data){
                 // dispatch(setFormState('search'));
                      dispatch(openMessageBox({ 
                      button: 'ok', 
                      icon: 'info', 
                      message: 'Registro realizado con exito.', 
                      callback: () => { 
                          dispatch(closeMessageBox()); 
                           getState().planificarvisita.filters.distribuidorId = 0;
                           getState().planificarvisita.filters.vendedorId = 0;
                           getState().planificarvisita.filters.dDistribuidor = '';
                           getState().planificarvisita.filters.dVendedor = '';
                          // dispatch(btnSearch())
                          dispatch({ type: RECEIVE_V_LIST, columns: [], payload: [] });
                          dispatch(push({
                            pathname: `/movilidad/planificarvisita`,
                            search: getState().router.location.search,
                            hash: getState().router.location.hash
                        }));
            
                      }
                  }));
         }
                
      } catch (error) {
          dispatch(openMessageBox({ 
              button: 'ok', 
              icon: 'error', 
              message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
              callback: () => dispatch(closeMessageBox())
          }));
      } finally {
          dispatch({ type: SET_MASK, loading: false });
          //dispatch(btnSearch());  
      }
  }
};

export const onbtnUpdate = () => (dispatch, getState) => {
    
  //console.log("guardar: ",getState().planificarvisita.entity.RutaPlanificadaId)
  dispatch(openMessageBox({
      button: 'yesno',
      icon: 'question',
      message: '¿Desea guardar la información?',
      callback: btn => {
          if(getState().planificarvisita.entity.RutaPlanificadaId !== 0) {
              dispatch(onUpdate(btn));
          }
          return
      }
  }));
  
};

export const onUpdate = (btn) => async (dispatch, getState) => {  
 
  dispatch(closeMessageBox());

  if(btn === 'yes') {
      try {

          dispatch({ type: SET_MASK, loading: true });

          let registro = {
            rutaPlanificadaId: getState().planificarvisita.entity.RutaPlanificadaId,
            fecha:  format(getState().planificarvisita.entity.desde, 'yyyy-MM-dd'),
            usuarioId: getState().planificarvisita.filters.vendedorId,
          };
         
          //console.log("json: ",registro)
          let url = `${getState().main.resources.uriSvcSalesMobilityAPI}/api/v1/Rutas/planificadas/actualizar` 
          let response = await axios.put(url, registro, {headers: {'Content-Type': 'application/json'}});
          let data = response.data;

          //console.log("aa",data)

          dispatch(openMessageBox({ 
              button: 'ok', 
              icon: 'info', 
              message: 'Modificación realizado con exito.', 
              callback: () => { 
                  dispatch(closeMessageBox());
                  getState().planificarvisita.filters.desde = getState().planificarvisita.entity.desde;
                  dispatch(btnSearch());
                  //dispatch(onClear());
                  dispatch({ type: RECEIVE_V_LIST, columns: [], payload: [] });
                  dispatch(push({
                      pathname: `/movilidad/planificarvisita`,
                      search: getState().router.location.search,
                      hash: getState().router.location.hash
                  }));
                 // dispatch(getUser(data));
              }
          }));
      } catch (error) {
          if(error.response.status === 404) {
              dispatch(openMessageBox({ 
                  button: 'ok', 
                  icon: 'error', 
                  message: 'No se encontro el registro solicitado.', 
                  callback: () => { 
                      dispatch(closeMessageBox()); 
                      //dispatch(cleanForm())
                      
                      dispatch(push({
                          pathname: `/movilidad/planificarvisita`,
                          search: getState().router.location.search,
                          hash: getState().router.location.hash
                      }));

                  }
              }));
          }
          else {
              dispatch(openMessageBox({ 
                  button: 'ok', 
                  icon: 'error', 
                  message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                  callback: () => dispatch(closeMessageBox())
              }));
          }
      } finally {
          dispatch({ type: SET_MASK, loading: false });
      }
  }
};

//===============================
/*export const onbtnDelete = () => (dispatch, getState) => {

  if (getState().planificarvisita.filters.distribuidorId === 0) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "Debe seleccionar un Agente..",
          callback: () => dispatch(closeMessageBox()),
        })
      );
      return;
    }

    if (getState().planificarvisita.store.selected.length === 0) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "No existe registros seleccionados!.",
          callback: () => dispatch(closeMessageBox()),
        })
      );
      return;

    } else {
        dispatch(openMessageBox({
          button: 'yesno',
          icon: 'question',
          message: 'Se eliminará el/los registro(s)) permanentemente. ¿Desea continuar?',
          callback: btn => dispatch(onDelete(btn))
      }));
    }
};*/


/*export const onDelete = (btn) => async (dispatch, getState) => {
  dispatch(closeMessageBox());


  
  if(btn === 'yes') {
      try {
          dispatch({ type: SET_MASK, loading: true });
          
          let data1 = getState().planificarvisita.store.selected
          let data = []
         
          data1.filter((e) =>e.RutaId !== 0 && data.push(e['RutaId']))
           
          var recordset = {
            'usuarioId': getState().planificarvisita.filters.vendedorId,
            'rutasplanificadasId' : data
          }
      
     dispatch(clearSelect('selected')) //Elimina lo seleccionado 
     dispatch(clearSelect('data')) // Elimina la cargado en la pantalla

          let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Rutas/planificadas/web`;
          let response = await axios.post(url, recordset, {headers: {'Content-Type': 'application/json'}});
          console.log(response, 'Que trae ')

          dispatch(openMessageBox({ 
              button: 'ok', 
              icon: 'info', 
              message: 'El registro se eliminó correctamente.', 
              callback: () => { 
                  dispatch(closeMessageBox());
                 
                  dispatch(btnSearch());
              }
          }));
      } catch (error) {
          dispatch(openMessageBox({ 
              button: 'ok', 
              icon: 'error', 
              message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
              callback: () => dispatch(closeMessageBox())
          }));
      } finally {
          dispatch({ type: SET_MASK, loading: false });
      }
  }
};*/
//===============================

export const btnDelete = () => (dispatch) => {
  dispatch(openMessageBox({
      button: 'yesno',
      icon: 'question',
      message: 'Se elimanara el registro permanentemente. ¿Desea continuar?',
      callback: btn => dispatch(onDeleteVisita(btn))
  }));
};

export const onDeleteVisita = (btn) => async (dispatch, getState) => {
  dispatch(closeMessageBox());

  if(btn === 'yes') {
      try {
          dispatch({ type: SET_MASK, loading: true });

          let usuarioId = getState().planificarvisita.filters.vendedorId

          let rutaPlanificadaId = getState().planificarvisita.entity.RutaPlanificadaId;
          let url = `${getState().main.resources.uriSvcSalesMobilityAPI}/api/v1/Rutas/planificadas/${rutaPlanificadaId}?`+ `usuarioId=${usuarioId}`;
          await axios.delete(encodeURI(url));


          dispatch(openMessageBox({ 
              button: 'ok', 
              icon: 'info', 
              message: 'El registro se elimino correctamente.', 
              callback: () => { 
                  dispatch(closeMessageBox());
                  //getState().planificarvisita.filters.desde = getState().planificarvisita.entity.desde;
                  dispatch(btnSearch());
                  //dispatch(onClear())
                 // dispatch({ type: RECEIVE_V_LIST, columns: [], payload: [] });
                  dispatch(push({
                      pathname: `/movilidad/planificarvisita`,
                      search: getState().router.location.search,
                      hash: getState().router.location.hash
                  }));
              }
          }));
      } catch (error) {
          dispatch(openMessageBox({ 
              button: 'ok', 
              icon: 'error', 
              message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
              callback: () => dispatch(closeMessageBox())
          }));
      } finally {
          dispatch({ type: SET_MASK, loading: false });
      }
  }
};



export const onSearchDistribuidorClick = () => async (dispatch) => {
    let fields = [
        { 'dataKey': 'razonSocial', 'label': 'Agente', 'type': 'string', 'width': 225 }
    ];

    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de agente',
        getData: async (params) => {
            let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`
            let response = await axios.get(encodeURI(url), { params: params });
            
            return response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldFilter('distribuidorId', result[0].distribuidorId));
                dispatch(onUpdateFieldFilter('dDistribuidor', `${result[0].razonSocial}`));
                dispatch(onUpdateFieldFilter("vendedorId", '')); //    usuarioId));
                dispatch(onUpdateFieldFilter("dVendedor", ''));
                dispatch(onUpdateFieldFilter("vId", 0));
            }
        }
    }));
};

export const btnSearchDistribuidorClick = () => async (dispatch) => {
  let fields = [
      { 'dataKey': 'razonSocial', 'label': 'Agente', 'type': 'string', 'width': 225 }
  ];

  dispatch(openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: 'Selección de agente',
      getData: async (params) => {
          let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`
          let response = await axios.get(encodeURI(url), { params: params });
          
          return response.data;
      },
      callback: (btn, result) => {
          dispatch(closeAdvancedSearch());
          if(btn === 'yes') {
              dispatch(onUpdateFieldEntity('distribuidorId', result[0].distribuidorId));
              dispatch(onUpdateFieldEntity('dDistribuidor', `${result[0].razonSocial}`));
              dispatch(onUpdateFieldEntity("vendedorId", '')); //    usuarioId));
              dispatch(onUpdateFieldEntity("dVendedor", ''));
              dispatch(onUpdateFieldEntity("vId", 0));
          }
      }
  }));
};

export const onSearchVendedorClick = () => async (dispatch, getState) => {
    let fields = [{ dataKey: "nombre",  label: "Nombre", type: "string", search: true, width: 225,}]
                  // { dataKey: "nombreUsuario", label: "Usuario", type: "string", width: 115 }];
    
    if (getState().planificarvisita.filters.distribuidorId === 0) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "Debe seleccionar un Agente..",
          callback: () => dispatch(closeMessageBox()),
        })
      );
      return;
    }
  
    dispatch(
      openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: "Selección de Vendedor",
        getData: async (params) => {

          let input2 = JSON.stringify(params);
          let input = JSON.parse(input2);

          let url = `${getState().main.resources.uriSvcSalesMobilityAPI}/api/v1/Vendedores/listarvendedoresxdistribuidor`
          + `?distribuidorId=${getState().planificarvisita.filters.distribuidorId}`               
          let response = await axios.get(encodeURI(url), { params: params });

          if (input.nombre) {
            let final = response.data.filter((e) =>
              e.nombre.toLowerCase().includes(input.nombre.toLowerCase())
            );
            response.data = final;
          }



          const  aux = {
              data: response.data  ,
              pageSize: 50,
              start: 0,
              total: response.data.length
          }
          return aux;  
      },
        callback: (btn, result) => {
          dispatch(closeAdvancedSearch());
          if (btn === "yes") {
            dispatch(onUpdateFieldFilter("vendedorId", result[0].usuarioId)); //    usuarioId));
            dispatch(onUpdateFieldFilter("dVendedor", `${result[0].nombre}`));
            dispatch(onUpdateFieldFilter("vId", result[0].vendedorId));
          }
        },
      })
    );
};

export const btnSearchVendedorClick = () => async (dispatch, getState) => {
    let fields = [{ dataKey: "nombre",  label: "Nombre", type: "string", search: true, width: 225,}]
                  // { dataKey: "nombreUsuario", label: "Usuario", type: "string", width: 115 }];
    
    if (getState().planificarvisita.entity.distribuidorId === 0) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "Debe seleccionar un Agente..",
          callback: () => dispatch(closeMessageBox()),
        })
      );
      return;
    }
  
    dispatch(
      openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: "Selección de Vendedor",
        getData: async (params) => {

          let input2 = JSON.stringify(params);
          let input = JSON.parse(input2);

          let url = `${getState().main.resources.uriSvcSalesMobilityAPI}/api/v1/Vendedores/listarvendedoresxdistribuidor`
          + `?distribuidorId=${getState().planificarvisita.entity.distribuidorId}`               
          let response = await axios.get(encodeURI(url), { params: params });

          if (input.nombre) {
            let final = response.data.filter((e) =>
              e.nombre.toLowerCase().includes(input.nombre.toLowerCase())
            );
            response.data = final;
          }



          const  aux = {
              data: response.data  ,
              pageSize: 50,
              start: 0,
              total: response.data.length
          }
          return aux;  
      },
        callback: (btn, result) => {
          dispatch(closeAdvancedSearch());
          if (btn === "yes") {
            dispatch(onUpdateFieldEntity("vendedorId", result[0].usuarioId)); //    usuarioId));
            dispatch(onUpdateFieldEntity("dVendedor", `${result[0].nombre}`));
            dispatch(onUpdateFieldEntity("vId", result[0].vendedorId));
          }
        },
      })
    );
};
 

export const onSearchSucursalClick = () => async (dispatch, getState) => {
  
  //console.log(getState().planificarvisita.entity.distribuidorId ) 
  //console.log(getState().planificarvisita.entity.vendedorId ) 

  if (getState().planificarvisita.entity.distribuidorId === 0) {
        dispatch(
          openMessageBox({
            button: "ok",
            icon: "warning",
            message: "Debe seleccionar un Agente..",
            callback: () => dispatch(closeMessageBox()),
          })
        );
        return;
      }

    if (getState().planificarvisita.entity.vendedorId === '') {
    dispatch(
        openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Vendedor..",
        callback: () => dispatch(closeMessageBox()),
        })
    );
    return;
    }

  if(getState().planificarvisita.entity.distribuidorId != 0 && getState().planificarvisita.entity.vendedorId !== ''){

    getState().planificarvisita.store.pantalla = true;

    let fields = [
        { 'dataKey': 'codigoCliente', 'label': 'Código', 'type': 'string', 'width': 105 },
        { 'dataKey': 'uso', 'label': 'Asignado', 'type': 'string', 'width': 50 },
        { 'dataKey': 'dCliente', 'label': 'Cliente', 'type': 'string', 'search': true, 'width': 200 },
        { 'dataKey': 'direccion', 'label': 'Dirección', 'type': 'string', 'width': 300 }
    ];

    try {
  
      dispatch(openAdvancedSearch({
          autoLoad: true,
          data: [],
          fields: fields,
          multiSelect: true,
          paginated: true,
          remote: true,
          title: 'Selección de cliente sucursal',
          getData: async (params) => {
        
                  let  url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/clientes/sucursales/sinruta?rutaId=${1}`
                  + `&distribuidorId=${getState().planificarvisita.entity.distribuidorId}`
                  + `&todos=1`;
        
              let response = await axios.get(encodeURI(url), { params: params });
            //console.log(response.data, 'Cliente Prospecto Aly ')
              return response.data;
          },
          callback: (btn, result) => {
          
              dispatch(closeAdvancedSearch());
              if(btn === 'yes') { //em
              
                  var t = getState().planificarvisita.store.datax;
                  // eslint-disable-next-line array-callback-return
                  // result.map(item => {
                  //     t.push({
                  //         SucursalId: item.sucursalId,
                  //         ProspectoId :'no',
                  //         DCliente: item.dCliente,
                  //         Direccion: item.direccion,
                  //         IPT: '',
                  //         Estado: 'Sin guardar'
                  //     })
                  // })
                  dispatch({
                    type: ADD_VISITA,
                    payload: result.map((item) => {
                      return { 
                        SucursalId: item.sucursalId, 
                        ProspectoId: 'no',
                        imagen: item.imagen,
                        DCliente: item.dCliente,
                        Direccion: item.direccion,
                        IPT: '',
                        Estado: 'Sin guardar'
                      }; 
                    }),
                  });

              }
          }
      }));

    }finally {
        dispatch({ type: SET_MASK, loading: false });
      }
      }else {
    
        getState().planificarvisita.store.pantalla  = false; 
    
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'error', 
            message: 'Debe seleccionar un Distribuidor y un Vendedor.', 
            callback: () => dispatch(closeMessageBox())
        }));
    
      }
    
};
  
export const onSearchProspectoClick = () => async (dispatch, getState) => {
    // console.trace()
    if (getState().planificarvisita.entity.distribuidorId === 0) {
        dispatch(
          openMessageBox({
            button: "ok",
            icon: "warning",
            message: "Debe seleccionar un Agente..",
            callback: () => dispatch(closeMessageBox()),
          })
        );
        return;
      }
      if (getState().planificarvisita.entity.vendedorId === '') {
        dispatch(
          openMessageBox({
            button: "ok",
            icon: "warning",
            message: "Debe seleccionar un Vendedor..",
            callback: () => dispatch(closeMessageBox()),
          })
        );
        return;
      }

    if(getState().planificarvisita.entity.distribuidorId != 0 && getState().planificarvisita.entity.vendedorId !== ''){

      getState().planificarvisita.store.pantalla = true;

    let fields = [
            { 'dataKey': 'prospectoId', 'label': 'Código', 'type': 'string', 'width': 50 },
            { 'dataKey': 'dVendedor', 'label': 'NombreVendedor', 'type': 'string', 'width': 150 },
            { 'dataKey': 'dProspecto', 'label': 'Prospecto', 'type': 'string', 'search': true, 'width': 150 },
            { 'dataKey': 'direccionFiscal', 'label': 'Dirección', 'type': 'string', 'width': 200 },
            { 'dataKey': 'distrito', 'label': 'Distrito', 'type': 'string', 'search': true, 'width': 200 }
        ];
     
    try {     
      dispatch(openAdvancedSearch({
          autoLoad: true,
          data: [],
          fields: fields,
          multiSelect: true,
          paginated: true,
          remote: true,
          title: 'Selección de Prospecto sucursal',
          getData: async (params) => {
          
            let input2 = JSON.stringify(params);
            let input = JSON.parse(input2);
          console.log(input)
              let  url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Prospectos/prospectodistribuidor?`
              + `distribuidorId=${getState().planificarvisita.entity.distribuidorId}`;
          
            
            let response = await axios.get(encodeURI(url), { params: params });
           
         
            if (input.dProspecto) {
              let final = response.data.filter((e) =>{ 
               return ( e.dProspecto.toLowerCase().includes(input.dProspecto.toLowerCase()))
            });
              response.data = final;
            }

            if (input.distrito) {
              let final = response.data.filter((e) =>{ 
               return ( e.distrito.toLowerCase().includes(input.distrito.toLowerCase()))
            });
              response.data = final;
            }


           let  response2 = {
                start: 0,
                pageSize: 50,
                total: response.data.length, 
                data: response.data
            }
         
            return response2;
        },

        callback: (btn, result) => {
           
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') { //em
            
                var p = getState().planificarvisita.store.datax;
              
         // eslint-disable-next-line array-callback-return

                dispatch({
                  type: ADD_VISITA,
                  payload: result.map((item) => {
                    if(item.SucursalId !==0){
                      return { 
                        SucursalId: 'no',
                        ProspectoId :item.prospectoId,
                        DCliente: item.dProspecto,
                        Direccion: item.direccionFiscal,
                        IPT: '['+item.NIT +'] -'+item.dProspecto,
                        Estado: 'Sin guardar'
                      }; 
                    }else {

                      return {
                        SucursalId:item.SucursalId,
                        ProspectoId :0,
                        DCliente: item.NombreComercial           ,
                        Direccion: item.DireccionSucursal,
                        IPT: '',
                        Estado: 'Sin guardar'
                      }

                    }
                  }),
                });


                // result.map(item => {
                //     if(item.SucursalId !== 0){
                //         p.push( {
                //         SucursalId: 'no',
                //         ProspectoId :item.prospectoId,
                //         DCliente: item.dProspecto,
                //         Direccion: item.direccionFiscal,
                //         IPT: '['+item.NIT +'] -'+item.dProspecto,
                //         Estado: 'Sin guardar'
                //     })
                // }else {
                //     p.push({
                //         SucursalId:item.SucursalId,
                //         ProspectoId :0,
                //         DCliente: item.NombreComercial           ,
                //         Direccion: item.DireccionSucursal,
                //         IPT: '',
                //         Estado: 'Sin guardar'
                //     })
                // }
                // })
            }
        }
    }));

  }
  finally {
    dispatch({ type: SET_MASK, loading: false });
  }
  }else {

    getState().planificarvisita.store.pantalla  = false; 

    dispatch(openMessageBox({ 
        button: 'ok', 
        icon: 'error', 
        message: 'Debe seleccionar un Distribuidor y un Vendedor.', 
        callback: () => dispatch(closeMessageBox())
    }));

  }

};

export const onUpdateColumn = (column) => (dispatch, getState) => {
    getState().planificarvisita.table.columns.map((item , i)=> {     
       if(item.dataKey !== column.dataKey) {
           return item;
       }else{
               item.width =  column.width;
       }
       return {
          // ...item,
         //  width: column.width
       };
      
   });
};

export const onUpdateFieldFilter = (key, value) => (dispatch, getState) => {
    dispatch({ type: UPDATE_FIELD_FILTER, key, value })
    //const  da = []

    dispatch( onUpdateFielddata());
   // dispatch({ type: RECEIVE_DATA, payload:[],  store:[] })
    dispatch({ type: RECEIVE_DATA, payload: [],storeData:[]});
    
};


export const onUpdateFieldEntity = (key, value) => (dispatch) => {
  dispatch({ type: UPDATE_FIELD_ENTITY, key, value });
};


export const onClear = () => ({ type: CLEAN_FORM });


export const onUpdateFielddata = (key, value) => (dispatch, getState) => {  

   // dispatch({ type: UPDATE_FIELD_DATA, key, value })

     //dispatch({ type: RECEIVE_DATA, columns: [], data: [] })
  //  dispatch({ type: RECEIVE_DATA, payload:[],  store:[] })
    //dispatch({ type: RECEIVE_DATA, payload: data, storeData });
};

export const setFormState = (formState) => (dispatch, getState) => {
    dispatch({ type: SET_STATE_FORM, formState });
};

export const setOrder = (order, orderBy) => ({ type: SET_ORDER, order, orderBy });

export const setSelectedRow = (selected) => ({ type: SET_SELECTED_ROW, selected});

export const setSelectedMulti = (selected) =>(dispatch, getState) => (dispatch ({ type: SET_SELECTED_MULTI, payload: selected }));//elemento

export const setPageSize = (pageSize) => ({ type: SET_PAGE_SIZE, pageSize });

export const setPage = (page) => ({ type: SET_PAGE, page });

export const clearSelect = (key) => ({ type: CLEAR_SELECT , key})

export const restartState = () => ({ type: RESTART_STATE });

export const cleanForm = () => ({ type: CLEAN_FORM });
