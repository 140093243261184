import axios from "axios";
import format from "date-fns/format";
import { IdentityUrl } from "../../../config";
import {
  closeAdvancedSearch,
  closeMessageBox,
  openAdvancedSearch,
  openMessageBox,
} from "../../main/actions";
import {
  FILTER_MAP_VENDEDOR,
  HIDE_POPUP_CLIENT,
  RECEIVE_DATA,
  RECEIVE_V_LIST,
  SET_MASK,
  SET_ORDER,
  SET_SELECTED_ROW,
  SHOW_POPUP_CLIENT,
  UPDATE_FIELD_FILTER
} from "./types";

import {
  exportExcel,
  lugar,
  marcas,
  tiposIdentificacion,
} from "../../../helpers/commons";

export const btnExport = () => async (dispatch, getState) => {
  if (getState().reportepromociones.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }
  if (getState().reportepromociones.store.data.length === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "No hay información para exportar.",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }

  try {
    dispatch({ type: SET_MASK, loading: true });

    let head = getState().reportepromociones.table.columns;
    var aux = [];

    for (var c in head) {
      aux[c] = Object.values(head[0])[1]; //fecha
      aux[1] = "DNI Vendedor";
      aux[2] = "Vendedor";
      aux[3] = Object.values(head[1])[1]; //nit
      aux[4] = Object.values(head[2])[1]; //nombre
      aux[5] = Object.values(head[4])[1]; //marca 1
      aux[6] = Object.values(head[3])[1]; //tipo
      aux[7] = Object.values(head[5])[1]; //observacion
      aux[8] = Object.values(head[6])[1]; //depa
      aux[9] = Object.values(head[7])[1]; //provincia
      aux[10] = Object.values(head[8])[1]; //distrito

      //aux.push(x);
    }
    var carArray = [];
    carArray.push(aux);

    let json = getState().reportepromociones.store.data;

    for (var i in json) {
      let x = Object.values(json[i]);
      x.splice(0, 1);
      x.splice(6, 1);
      x.splice(8, 1);
      x.splice(8, 1);

      carArray.push(x);
    }
    exportExcel(carArray, "ReportePromociones");
  } catch (error) {
    dispatch({ type: SET_MASK, loading: false })
    console.log(error);
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message:
          (error.response.data.eventLogId === 0
            ? ""
            : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    setTimeout(() => {dispatch({ type: SET_MASK, loading: false })}, 1000);
  }
};

export const btnSearch = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_MASK, loading: true });

    if (getState().reporteidentificacionmercado.store.loading) {
      return;
    }

    if (getState().reportepromociones.filters.distribuidorId === 0) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "Debe seleccionar un Agente..",
          callback: () => dispatch(closeMessageBox()),
        })
      );
      return;
    }
    let url =
      `${
        getState().main.resources.uriSvcSalesMobilityAPI
      }/api/v1/reportes/marketing/reportepromociones?` +
      `distribuidorId=${getState().reportepromociones.filters.distribuidorId}` +
      `&departamentoId=${
        getState().reportepromociones.filters.departamentoId
      }` +
      `&provinciaId=${getState().reportepromociones.filters.provinciaId}` +
      `&distritoId=${getState().reportepromociones.filters.distritoId}` +
      `&marcaId=${
        getState().reportepromociones.filters.marcaidentificacionId
      }` +
      `&dTipo=${getState().reportepromociones.filters.dIdentificacion}` +
      `&nombre=${getState().reportepromociones.filters.nombre}` +
      `&desde=${format(
        getState().reportepromociones.filters.desde,
        "yyyy-MM-dd"
      )}` +
      `&hasta=${format(
        getState().reportepromociones.filters.hasta,
        "yyyy-MM-dd"
      )}`;
    let response = await axios.get(encodeURI(url));
    let data = response.data;

    if (data.length === 0) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "No existe Registro  para esta consulta",
          callback: () => dispatch(closeMessageBox()),
        })
      );
      return;
    }
    let columnas = getState().reportepromociones.table.columns;

    //let data = [0,0,0,0 ,0]
    dispatch({ type: RECEIVE_V_LIST, columns: columnas, payload: data });
  } catch (error) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message:
          (error.response.data.eventLogId === 0
            ? ""
            : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    dispatch({ type: SET_MASK, loading: false });
  }
};

export const onSearchLugarClick = () => async (dispatch, getState) => {
  let fields = [
    { dataKey: "dLugar", label: "Ubicación", type: "string", width: 250 },
  ];

  let data = lugar;

  try {
    dispatch(
      openAdvancedSearch({
        autoLoad: true,
        data: data,
        fields: fields,
        paginated: false,
        remote: false,
        title: "Seleccione una Ubicación",
        callback: (btn, result) => {
          dispatch(closeAdvancedSearch());
          if (btn === "yes") {
            dispatch(onUpdateFieldFilter("lugarId", result[0].lugarId));
            dispatch(onUpdateFieldFilter("dLugar", result[0].dLugar));
            //dispatch(onUpdateFieldFilter('dLugar', ''));
          }
        },
      })
    );
  } catch (error) {
    if (error.response) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "error",
          message:
            (error.response.data.eventLogId === 0
              ? ""
              : `EventoId: ${error.response.data.eventLogId}. `) +
            error.response.data.message,
          callback: () => dispatch(closeMessageBox()),
        })
      );
    }
  }
};

export const onSearchTipoClick = () => async (dispatch, getState) => {
  let fields = [
    { dataKey: "dIdentificacion", label: "Tipo", type: "string", width: 250 },
  ];
  if (getState().reportepromociones.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }

  let data = tiposIdentificacion;

  try {
    dispatch(
      openAdvancedSearch({
        autoLoad: true,
        data: data,
        fields: fields,
        paginated: false,
        remote: false,
        title: "Seleccione un Tipo",
        callback: (btn, result) => {
          dispatch(closeAdvancedSearch());
          if (btn === "yes") {
            dispatch(
              onUpdateFieldFilter(
                "identificacionId",
                result[0].identificacionId
              )
            );
            dispatch(
              onUpdateFieldFilter("dIdentificacion", result[0].dIdentificacion)
            );
            // dispatch(onUpdateFielddata('data', []));
          }
        },
      })
    );
  } catch (error) {
    if (error.response) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "error",
          message:
            (error.response.data.eventLogId === 0
              ? ""
              : `EventoId: ${error.response.data.eventLogId}. `) +
            error.response.data.message,
          callback: () => dispatch(closeMessageBox()),
        })
      );
    }
  }
};

export const onSearchMarcasClick = () => async (dispatch, getState) => {
  let fields = [
    { dataKey: "dMarcas", label: "Marcas", type: "string", width: 250 },
  ];
  if (getState().reportepromociones.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }

  let data = marcas;

  try {
    dispatch(
      openAdvancedSearch({
        autoLoad: true,
        data: data,
        fields: fields,
        paginated: false,
        remote: false,
        title: "Selección de Marca",
        callback: (btn, result) => {
          dispatch(closeAdvancedSearch());
          if (btn === "yes") {
            dispatch(onUpdateFieldFilter("marcasId", result[0].marcasId));
            dispatch(onUpdateFieldFilter("dMarcas", result[0].dMarcas));
            //dispatch(onUpdateFieldFilter('dMarcas', ''));
          }
        },
      })
    );
  } catch (error) {
    if (error.response) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "error",
          message:
            (error.response.data.eventLogId === 0
              ? ""
              : `EventoId: ${error.response.data.eventLogId}. `) +
            error.response.data.message,
          callback: () => dispatch(closeMessageBox()),
        })
      );
    }
  }
};

export const closeDialogClient = () => ({ type: HIDE_POPUP_CLIENT });

export const filterMapByVendedor = (dVendedor) => ({
  type: FILTER_MAP_VENDEDOR,
  dVendedor,
});

export const onSearchDistribuidorClick = () => async (dispatch) => {
  let fields = [
    { dataKey: "razonSocial", label: "Agente", type: "string", width: 225 },
  ];

  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de Agente..",
      getData: async (params) => {
        let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`;
        let response = await axios.get(encodeURI(url), { params: params });
        //console.log(response.data);
        return response.data;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(
            onUpdateFieldFilter("distribuidorId", result[0].distribuidorId)
          );
          dispatch(
            onUpdateFieldFilter("dDistribuidor", `${result[0].razonSocial}`)
          );
          dispatch(onUpdateFieldFilter("departamentoId", "00"));
          dispatch(onUpdateFieldFilter("dDepartamento", ""));
          dispatch(onUpdateFieldFilter("provinciaId", "00"));
          dispatch(onUpdateFieldFilter("dProvincia", ""));
          dispatch(onUpdateFieldFilter("distritoId", "00"));
          dispatch(onUpdateFieldFilter("dDistrito", ""));
          dispatch(onUpdateFieldFilter("dMarcaIdentificacion", ""));
          dispatch(onUpdateFieldFilter("marcaIdentificacionId", 0));
          dispatch(onUpdateFieldFilter("dTipo", ""));
          dispatch(onUpdateFieldFilter("tipoId", 0));
        }
      },
    })
  );
};

export const onSearchSupervisorClick = () => async (dispatch, getState) => {
  let fields = [
    {
      dataKey: "nombre",
      label: "Nombre",
      type: "string",
      search: true,
      width: 225,
    },
    { dataKey: "nombreUsuario", label: "Usuario", type: "string", width: 115 },
  ];

  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de supervisor",
      getData: async (params) => {
        let url = `${IdentityUrl}/api/v1/usuarios?rol=Supervisor`;
        let response = await axios.get(encodeURI(url), { params: params });
        let distribuidorId = getState().reportevisitas.filters.distribuidorId;

        if (distribuidorId > 0) {
          response.data.data = response.data.data.filter(
            (f) => f.distribuidorId === distribuidorId
          );
        }

        return response.data;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(onUpdateFieldFilter("supervisorId", result[0].usuarioId));
          dispatch(onUpdateFieldFilter("dSupervisor", `${result[0].nombre}`));
          dispatch(onUpdateFieldFilter("vendedorId", ""));
          dispatch(onUpdateFieldFilter("dVendedor", ""));
        }
      },
    })
  );
};

export const onSearchMarcasIdentificacionClick =
  () => async (dispatch, getState) => {
    let fields = [
      { dataKey: "nombre", label: "Nombre", type: "string", width: 225 },
    ];
    if (getState().reportepromociones.filters.distribuidorId === 0) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "Debe seleccionar un Agente..",
          callback: () => dispatch(closeMessageBox()),
        })
      );
      return;
    }

    try {
      dispatch(
        openAdvancedSearch({
          autoLoad: true,
          data: [],
          fields: fields,
          paginated: true,
          remote: true,
          title: "Seleccione una Marca",
          getData: async (params) => {
            let url =
              `${
                getState().main.resources.uriSvcSalesMobilityAPI
              }api/v1/Marcas/parapromocion?` + 
              `nombre=`;
            let response = await axios.get(encodeURI(url), { params: params });

            const aux = {
              data: response.data,
              pageSize: 50,
              start: 0,
              total: response.data.length,
            };
            return aux;
          },
          callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if (btn === "yes") {
              dispatch(
                onUpdateFieldFilter("marcaidentificacionId", result[0].marcaId)
              );
              dispatch(
                onUpdateFieldFilter("dMarcaIdentificacion", result[0].nombre)
              );
              //dispatch(onUpdateFieldFilter('dMarcas', ''));
            }
          },
        })
      );
    } catch (error) {
      if (error.response) {
        dispatch(
          openMessageBox({
            button: "ok",
            icon: "error",
            message:
              (error.response.data.eventLogId === 0
                ? ""
                : `EventoId: ${error.response.data.eventLogId}. `) +
              error.response.data.message,
            callback: () => dispatch(closeMessageBox()),
          })
        );
      }
    }
  };

export const onSearchDepartaClick = () => async (dispatch, getState) => {
  let fields = [
    { dataKey: "nombre", label: "Nombre", type: "string", width: 225 },
  ];

  if (getState().reportepromociones.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }
  try {
    dispatch(
      openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: "Seleccione un Departamento..",
        getData: async (params) => {
          let url = `${
            getState().main.resources.uriSvcSalesMobilityAPI
          }api/v1/agentes/departamentos`;
          let response = await axios.get(encodeURI(url), { params: params });

          const aux = {
            data: response.data,
            pageSize: 50,
            start: 0,
            total: response.data.length,
          };
          return aux;
        },
        callback: (btn, result) => {
          dispatch(closeAdvancedSearch());
          if (btn === "yes") {
            dispatch(onUpdateFieldFilter("departamentoId", result[0].codigo));
            dispatch(onUpdateFieldFilter("dDepartamento", `${result[0].nombre}`));
            dispatch(onUpdateFieldFilter("dProvincia", ""));
            dispatch(onUpdateFieldFilter("provinciaId", "00"));
            dispatch(onUpdateFieldFilter("dDistrito", ""));
            dispatch(onUpdateFieldFilter("distritoId", "00"));
          }
        },
      })
    );
  } catch (error) {
    if (error.response) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "error",
          message:
            (error.response.data.eventLogId === 0
              ? ""
              : `EventoId: ${error.response.data.eventLogId}. `) +
            error.response.data.message,
          callback: () => dispatch(closeMessageBox()),
        })
      );
    }
  }
};

export const onSearcProvinciaClick = () => async (dispatch, getState) => {
  let fields = [
    { dataKey: "nombre", label: "Nombre", type: "string", width: 225 },
  ];

  if (getState().reportepromociones.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Departamento..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }

  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Seleccióne una Provincia..",
      getData: async (params) => {
        let url =
          `${
            getState().main.resources.uriSvcSalesMobilityAPI
          }api/v1/agentes/provincias?` +
          `codigodepartamento=${
            getState().reportepromociones.filters.departamentoId
          }`;
        let response = await axios.get(encodeURI(url), { params: params });

        const aux = {
          data: response.data,
          pageSize: 50,
          start: 0,
          total: response.data.length,
        };
        return aux;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(onUpdateFieldFilter("provinciaId", result[0].codigo));
          dispatch(onUpdateFieldFilter("dProvincia", `${result[0].nombre}`));
          dispatch(onUpdateFieldFilter("dDistrito", ""));
          dispatch(onUpdateFieldFilter("distritoId", "00"));
        }
      },
    })
  );
};

export const onSearcDistritoClick = () => async (dispatch, getState) => {
  let fields = [
    { dataKey: "nombre", label: "Nombre", type: "string", width: 225 },
  ];

  if (getState().reportepromociones.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar una Provincia..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }
  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Seleccióne un Distrito..",
      getData: async (params) => {
        let url =
          `${
            getState().main.resources.uriSvcSalesMobilityAPI
          }api/v1/agentes/distritos?` +
          `codigodepartamento=${
            getState().reportepromociones.filters.departamentoId
          }` +
          `&codigoprovincia=${
            getState().reportepromociones.filters.provinciaId
          }`;
        let response = await axios.get(encodeURI(url), { params: params });

        const aux = {
          data: response.data,
          pageSize: 50,
          start: 0,
          total: response.data.length,
        };
        return aux;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(onUpdateFieldFilter("distritoId", result[0].codigo));
          dispatch(onUpdateFieldFilter("dDistrito", `${result[0].nombre}`));
        }
      },
    })
  );
};

export const onUpdateColumn = (column) => (dispatch, getState) => {
  getState().reportepromociones.table.columns.map((item, i) => {
    if (item.dataKey !== column.dataKey) {
      return item;
    } else {
      item.width = column.width;
    }
    return {
      // ...item,
      //  width: column.width
    };
  });
};
export const onUpdateFieldFilter = (key, value) => (dispatch, getState) => {
  dispatch({ type: UPDATE_FIELD_FILTER, key, value });
  //const  da = []

  dispatch(onUpdateFielddata());
  // dispatch({ type: RECEIVE_DATA, payload:[],  store:[] })
  dispatch({ type: RECEIVE_DATA, payload: [], storeData: [] });
};

export const onUpdateFielddata = (key, value) => (dispatch, getState) => {
  // dispatch({ type: UPDATE_FIELD_DATA, key, value })
  //dispatch({ type: RECEIVE_DATA, columns: [], data: [] })
  //  dispatch({ type: RECEIVE_DATA, payload:[],  store:[] })
  //dispatch({ type: RECEIVE_DATA, payload: data, storeData });
};

export const openDialogClient = (data) => (dispatch, getState) => {
  try {
    var storeData = getState().reportevisitas.data.filter(
      (f) => f.dVendedor === data.dVendedor
    );

    dispatch({ type: SHOW_POPUP_CLIENT, storeData });
  } catch (error) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message: error,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  }
};

export const setOrder = (order, orderBy) => ({
  type: SET_ORDER,
  order,
  orderBy,
});

export const setSelectedRow = (selected) => ({
  type: SET_SELECTED_ROW,
  selected,
});
