export const ADD_RUTAS_CLIENTE = 'movilidad/planificarvisita/add-rutas-cliente';
export const CLEAN_FORM = 'movilidad/planificarvisita/clean-form';
export const ADVANCED_SEARCH_CLOSE = 'main/advanced-search-close';
export const ADVANCED_SEARCH_OPEN = 'main/advanced-search-open';
export const SET_STATE_FORM = 'movilidad/planificarvisita/set-state-form';
export const UPDATE_FIELD_FILTER = 'movilidad/planificarvisita/update-field-filter';
export const RECEIVE_V_LIST = 'movilidad/planificarvisita/receive-v-list';
export const REQUEST_V_LIST = 'movilidad/planificarvisita/request-v-list';
export const ERROR_V_LIST = 'movilidad/planificarvisita/error-v-list';
export const SET_MASK = 'movilidad/planificarvisita/set-mask';
export const SET_ORDER = 'movilidad/planificarvisita/set-order';
export const RECEIVE_DATA= 'movilidad/planificarvisita/receibe-data';
export const UPDATE_TABLE_COLUMN= 'movilidad/planificarvisita/update-table-column';
export const SET_PAGE = 'movilidad/planificarvisita/set-page';
export const SET_SELECTED_ROW = 'movilidad/planificarvisita/set-selected-row';
export const SET_SELECTED_MULTI = 'movilidad/planificarvisita/set-selected-multi';
export const SET_PAGE_SIZE = 'movilidad/planificarvisita/set-page-size';
export const CLEAR_SELECT = 'movilidad/planificarvisita/clear-select';
export const RESTART_STATE = 'movilidad/planificarvisita/restart-state';
export const ADD_VISITA = 'movilidad/planificarvisita/set-mask';
export const UPDATE_FIELD_ENTITY = 'movilidad/planificarvisita/update-field-entity';
export const SET_ENTITY = 'movilidad/planificarvisita/set-entity';


