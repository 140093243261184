import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    onSearchDiaRutaClick,
    onSearchVendedorPorRolClick,
    onUpdateFieldEntity
} from '../../../store/fuerzaventas/rutas/actions';

import { ActionField, FieldSet } from '@sigeco/tools';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import Fab from '@material-ui/core/Fab';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import InputLabel from '@material-ui/core/InputLabel';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(() => ({
    checkbox: {
        color: "#9c9c9c",
        padding: 0
    },
    chip: {
        margin: '0.375rem 0.375rem 0 0'
    },
    content: {
        padding: '0.5rem 0.75rem 0.75rem'
    },
    label: {
        alignItems: 'center',
        display: 'flex',
        fontSize: '0.8125rem'
    }
}));

const RutasForm = () => {
    const classes = useStyles();
    const matches = useMediaQuery('(min-width:701px)');
    const entity = useSelector(state => state.rutas.entity);
    const errors = useSelector(state => state.rutas.errors);
    const formState = useSelector(state => state.rutas.formState);
    const dias = useSelector(state => state.rutas.dias);
    const dispatch = useDispatch();
    const isView = formState === 'view';

    const onFieldChange = event => dispatch(onUpdateFieldEntity(event.target.name, event.target.value));

    const handleDelete = key => dispatch(onUpdateFieldEntity(key, false))

    function onCheckBoxChange(event) {  
        dispatch(onUpdateFieldEntity(event.target.name, event.target.checked));
    }

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={matches ? 4 : 12}>
                    <FieldSet iconCls="fas fa-file-alt" title="Información">
                        <Grid className={classes.content} container spacing={1}>
                            <Grid item xs={12}>
                                <TextField
                                    autoComplete="off"
                                    error={errors.nombre !== ''}
                                    fullWidth
                                    helperText={errors.nombre}
                                    label="Nombre de ruta"
                                    name="nombre"
                                    onChange={onFieldChange}
                                    required
                                    value={entity.nombre}
                                    variant={matches ? "outlined" : "standard"}
                                    inputProps={{
                                        maxLength: 50,
                                        readOnly: isView
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    autoComplete="off"
                                    error={errors.codigo !== ''}
                                    fullWidth
                                    helperText={errors.codigo}
                                    label="Codigo de ruta"
                                    name="codigo"
                                    onChange={onFieldChange}
                                    required
                                    value={entity.codigo}
                                    variant={matches ? "outlined" : "standard"}
                                    inputProps={{
                                        maxLength: 50,
                                        readOnly: isView
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ActionField
                                    error={errors.vendedor !== ''}
                                    helperText={errors.vendedor}
                                    hiddenAction={isView}
                                    label="Vendedor"
                                    name='vendedor'//"dVendedor"
                                    required
                                    placeholder="Enter para buscar vendedor"
                                    value={entity.dVendedor}
                                    variant={matches ? "outlined" : "standard"}
                                    onHandleAction={() => dispatch(onSearchVendedorPorRolClick())}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <FormControl fullWidth variant={matches ? "outlined" : "standard"}>
                                    <InputLabel id="lblFrecuencia">Frecuencia</InputLabel>
                            
                                    <Select
                                        labelId="lblFrecuencia"
                                        name='frecuencia'
                                        placeholder="Seleccione"
                                        value={entity.frecuencia.trim()}
                                        onChange={event => dispatch(onUpdateFieldEntity('frecuencia', event.target.value))}
                                    >
                                        <MenuItem value={"S"}>Semanal</MenuItem>
                                        <MenuItem value={"Q"}>Quincenal</MenuItem>    
                                        <MenuItem value={"QP"}>Quincena Sem Par</MenuItem>
                                        <MenuItem value={"QI"}>Quincena Sem Impar</MenuItem>                                      
                                        <MenuItem value={"MS1"}>Mensual Sem 1 </MenuItem>
                                        <MenuItem value={"MS2"}>Mensual Sem 2 </MenuItem>
                                        <MenuItem value={"MS3"}>Mensual Sem 3</MenuItem>
                                        <MenuItem value={"MS4"}>Mensual Sem 4</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4} container>
                                <div className={classes.label}>Activo:</div>
                                <Checkbox
                                    name="activo"
                                    className={classes.checkbox} 
                                    checked={entity.activo} 
                                    color="primary" 
                                    disabled={isView || formState === 'restart'} 
                                    disableRipple
                                    onChange={onCheckBoxChange}
                                    inputProps={{
                                        readOnly: isView
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </FieldSet>
                </Grid>
                <Grid item xs={matches ? 8 : 12}>
                    <FieldSet iconCls="fas fa-calendar-week" title="Semana">
                        <div className={classes.content}>
                            {(formState === 'edit' || formState === 'new') && (<div>
                                <Fab aria-label="agregar_dias" color="primary" onClick={() => dispatch(onSearchDiaRutaClick())} size="small">
                                    <Icon className="fas fa-plus" style={{ fontSize: '0.875rem'}}/>
                                </Fab>
                            </div>)}
                            {dias.filter(f => entity[f.key]).map((item, index) => {
                                return (
                                    <div key={index} style={{ display: 'inline-flex' }}>
                                        <Chip
                                            className={classes.chip}
                                            color="primary"
                                            deleteIcon={<Icon className="fas fa-times-circle" style={{ fontSize: '1rem'}} />}
                                            label={item.label}
                                            onDelete={ (formState === 'edit' || formState === 'new') ? () => handleDelete(item.key) : null}
                                            variant="outlined"
                                            
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </FieldSet>
                    {/* <FieldSet iconCls="fas fa-calendar-week" title="Frecuencia">
                        <div className={classes.content}>
                            {(formState === 'edit' || formState === 'new') && (<div>
                             
                                <Grid item xs={8}>
                                <FormControl fullWidth variant={matches ? "outlined" : "standard"}>
                                
                            
                                    <Select
                                        labelId="lblFrecuencia"
                                        name='frecuencia'
                                        placeholder="Seleccione"
                                        value={entity.frecuencia.trim()}
                                        onChange={event => dispatch(onUpdateFieldEntity('frecuencia', event.target.value))}
                                    >
                                        <MenuItem value={"S"}>Semanal</MenuItem>
                                        <MenuItem value={"Q"}>Quincenal</MenuItem>    
                                        <MenuItem value={"QP"}>Quincena Sem Par</MenuItem>
                                        <MenuItem value={"QI"}>Quincena Sem Impar</MenuItem>                                      
                                        <MenuItem value={"MS1"}>Mensual Sem 1 </MenuItem>
                                        <MenuItem value={"MS2"}>Mensual Sem 2 </MenuItem>
                                        <MenuItem value={"MS3"}>Mensual Sem 3</MenuItem>
                                        <MenuItem value={"MS4"}>Mensual Sem 4</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            </div>)}
                       
                        </div>
                    </FieldSet>  */}
                </Grid>
                <Grid item xs={matches ? 4 : 6}>
                
                </Grid>
            </Grid>
        </div>
    )
};

export default RutasForm;