import React, { useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from "react-router-dom";
import { LinkContainer } from 'react-router-bootstrap';
import {
    onExport,
    onbtnSave,
    onbtnDelete,
    btnSearch,
    setFormState2,
    btnSave
} from '../../store/fuerzaventas/gestionlistadeprecios/actions';


import { Body, Footer, Page } from '@sigeco/tools';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';

import GestionListaPreciosFilter from '../../components/fuerzaventas/gestionlistadeprecios/GestionListadePreciosFilter';
import GestionListaPreciosList from '../../components/fuerzaventas/gestionlistadeprecios/GestionListadePreciosList';
import GestionListaPrecioModificar from '../../components/fuerzaventas/gestionlistadeprecios/GestionListadePreciosModificar';
//import { closeMessageBox, openMessageBox } from "../../store/main/actions";

const GestionListadePrecios = ({onClose, setTitle}) => {
    const loading = useSelector(state => state.gestionlistadeprecios.loading);
    const store = useSelector(state => state.gestionlistadeprecios.filters.band);
    const store2 = useSelector(state => state.gestionlistadeprecios.store);
   // const data = useSelector(state => state.gestionlistadeprecios.store.data);
    const match = useRouteMatch();
    const formState = useSelector(state => state.gestionlistadeprecios.formState)
    const location = useLocation();

    const dispatch = useDispatch();
    
    useEffect(() => {
        switch(match.path) {
          case '/fuerzaventas/gestionlistadeprecios/:id':
            dispatch(setFormState2('view', match.params['id']));
            setTitle('Modificar Lista de Precios');
            break;
          case '/fuerzaventas/gestionlistadeprecios/:id/editar':
            dispatch(setFormState2('edit', match.params['id']));
            setTitle('Modificar Lista de Precios');
            break;
          default:
            dispatch(setFormState2('search'));
            setTitle('Búsqueda');
            break;
        }
      // eslint-disable-next-line
    }, [match.path]);

    // function btnSave() {
 
    //     dispatch(onbtnSave());
    // }

    function onBtnClose() {
        onClose();
    }

    function btnEliminar() {
        dispatch(onbtnDelete());
    }

    return (
        <Page>
            <Backdrop style={{ position: 'absolute', zIndex: 1200 }} open={loading} transitionDuration={0}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Body>
            {formState === 'search' && <GestionListaPreciosFilter />}
            {formState === 'search' && <GestionListaPreciosList/>}
            {formState === 'view'   && <GestionListaPrecioModificar/>}
            </Body>
            <Footer>
                {/* { ( store2.data.length !== 0 )&& <Button
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={() => btnSave()}
                    startIcon={<Icon className="fas fa-file-excel"/>}
                  >
                    Guardar
                </Button>
                } */}
                {/* { store === true && <Button
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={() => dispatch(onExport())}
                    startIcon={<Icon className="fas fa-file-excel"/>}
                  >
                    Exportar
                </Button>
                } */}
                { formState === 'new' &&
                <Button
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={() => btnEliminar()}
                    startIcon={<Icon className="fas fa-search"/>}
                >
                    Eliminar
                </Button>}

                {formState === 'search' &&
                <Button
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={() => dispatch(onExport())}
                    startIcon={<Icon className="fas fa-file-excel"/>}
                  >
                    Exportar
                </Button>}

                
                { formState === 'search' && <Button
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={() => dispatch(btnSearch())}
                    startIcon={<Icon className="fas fa-search"/>}
                >
                    Buscar
                </Button>}

                { formState === 'view'  &&
                    <Button
                        color="primary" 
                        size="small" 
                        variant="outlined"
                        onClick={() => dispatch(btnSave())}
                        startIcon={<Icon className="fas fa-edit"/>}
                    >
                        Modificar
                    </Button>
                }

                {formState !== 'search' && <LinkContainer to={{ pathname: '/fuerzaventas/gestionlistadeprecios', search: location.search, hash: location.hash }}>
                <Button
                    color="primary" 
                    size="small" 
                    variant="outlined"
                    startIcon={<Icon className="fas fa-times-circle"/>}
                >
                    Cancelar
                </Button>
                </LinkContainer>}            

                <Button 
                    color="primary" 
                    size="small" 
                    variant="outlined" 
                    onClick={() => onBtnClose()} 
                    startIcon={<Icon className="fas fa-door-open"/>}
                >
                    Salir
                </Button>
            </Footer>
        </Page>
    )
};

export default GestionListadePrecios;