export const CLEAN_FORM = 'fuerzaventas/cargarmiespacio/clean-form';
export const ADVANCED_SEARCH_CLOSE = 'main/advanced-search-close';
export const ADVANCED_SEARCH_OPEN = 'main/advanced-search-open';
export const SET_STATE_FORM = 'fuerzaventas/cargarmiespacio/set-state-form';
export const UPDATE_FIELD_FILTER = 'fuerzaventas/cargarmiespacio/update-field-filter';
export const UPDATE_FIELD_ENTITY = 'fuerzaventas/cargarmiespacio/update-field-entity';
export const RECEIVE_V_LIST = 'fuerzaventas/cargarmiespacio/receive-v-list';
export const RECEIVE_V_LIST2 = 'fuerzaventas/cargarmiespacio/receive-v-list2';
export const REQUEST_V_LIST = 'fuerzaventas/cargarmiespacio/request-v-list';
export const ERROR_V_LIST = 'fuerzaventas/cargarmiespacio/error-v-list';
export const SET_MASK = 'fuerzaventas/cargarmiespacio/set-mask';
export const SET_ORDER = 'fuerzaventas/cargarmiespacio/set-order';
export const RECEIVE_DATA= 'fuerzaventas/cargarmiespacio/receibe-data';
export const UPDATE_TABLE_COLUMN= 'fuerzaventas/cargarmiespacio/update-table-column';
export const SET_PAGE = 'fuerzaventas/cargarmiespacio/set-page';
export const SET_SELECTED_ROW = 'fuerzaventas/cargarmiespacio/set-selected-row';
export const CHANGE_IMAGE = 'fuerzaventas/cargarmiespacio/change-image';
