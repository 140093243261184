import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import _ from 'lodash';
// import {
//     RECEIVE_V_LIST,
//   } from "../../../store/fuerzaventas/cargamasivarutas/types";

import { onUpdateFieldFilter } from "../../../store/fuerzaventas/cargamasivarutas/actions";

import { handleDownLoadFile } from "../../../helpers/commons";

import "./table.css";

import Icon from "@material-ui/core/Icon";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import CargaMasivaRutasListRutas from "./CargaMasivaRutasListRutas";
import CargaMasivaListRutasClientes from "./CargaMasivaListRutasClientes";
import CargaMasivaSucursales from "./CargaMasivaSucursales";
import CargaMasivaSucursalesResponse from "./CargaMasivaSucursalesResponse";
//import uno  from './formatos/FORMATO-CARGA-MASIVA-RUTAS.xlsx'

const useStyles = makeStyles((theme) => ({
    paper: {
        flex: 1,
    },
    tabsHorizontal: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        minWidth: 750, // a number of your choice
        width: 380, // a number of your choice
    },
    tabsVertical: {
        borderRight: `1px solid ${theme.palette.divider}`,
        minWidth: 180, // a number of your choice
        width: 180, // a number of your choice
    },
}));

const CargaMasivaRutasPanel = () => {
    const classes = useStyles();
    const big = useMediaQuery("(min-width:1201px)");
    const data = useSelector((state) => state.cargamasivarutas.store.data5);

    const dispatch = useDispatch();

    const [value, setValue] = useState(0);

    useEffect(() => {
        dispatch(onUpdateFieldFilter("valor", value));
    }, [dispatch, value]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        // dispatch(    onUpdateFieldFilter("valor", newValue));
        if (newValue === 2) {
            const link = document.createElement("a");
            link.href = "./formatos/FORMATO-CARGA-MASIVA-RUTAS.xlsx";
            link.download = "FORMATO-CARGA-MASIVA-RUTAS.xlsx";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    // const handleDownLoadFile = (item) => {
    //     console.log(item)
    //     let link = document.createElement('a');

    //     link.href = item;
    //     link.setAttribute('target', '_blank');
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    // }

    // const Ondescarga = (event, newValue) => {
    //         alert();
    //    // dispatch(    onUpdateFieldFilter("valor", newValue));

    // };

    return (
        <Paper className={classes.paper}>
            <div
                style={{
                    display: "flex",
                    flexDirection: big ? "row" : "column",
                    height: "100%",
                }}
            >
                <Tabs
                    orientation={big ? "vertical" : "horizontal"}
                    variant="fullWidth"
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="icon label tabs example"
                    className={
                        big ? classes.tabsVertical : classes.tabsHorizontal
                    }
                >
                    <Tab
                        icon={<Icon className="fas fa-project-diagram" />}
                        label="Cargar Rutas "
                        style={{
                            textTransform: "none",
                            backgroundColor: value === 0 ? "#C9C9C9" : "",
                        }}
                    />
                    <Tab
                        icon={<Icon className="fas fa-barcode" />}
                        label="Cargar Clientes "
                        style={{
                            textTransform: "none",
                            backgroundColor: value === 1 ? "#C9C9C9" : "",
                        }}
                    />
                    <Tab
                        icon={<Icon className="fas fa-download" />}
                        label="Bajar Formato "
                        style={{
                            textTransform: "none",
                            backgroundColor: value === 2 ? "#C9C9C9" : "",
                        }}
                    />
                    <Tab
                        icon={<Icon className="fa fa-search-plus" />}
                        label="Buscar Sucursal "
                        style={{
                            textTransform: "none",
                            backgroundColor: value === 3 ? "#C9C9C9" : "",
                        }}
                    />
                </Tabs>
                {
                    //  dispatch(    onUpdateFieldFilter("valor", value) )
                }
                {value === 0 && <CargaMasivaRutasListRutas />}
                {value === 1 && <CargaMasivaListRutasClientes />}
                {/* {value === 32 && Ondescarga} */}
                {value === 3 && <CargaMasivaSucursales />}
                {value === 3 && data.length !== 0 && (
                    <CargaMasivaSucursalesResponse />
                )}
            </div>
        </Paper>
    );
};

export default CargaMasivaRutasPanel;
