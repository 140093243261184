import axios from "axios"; 
import { IdentityUrl } from "../../../config";
import { push } from 'connected-react-router';
import { closeMessageBox, openMessageBox } from "../../main/actions";

import {
  CLEAN_FORM, 
  UPDATE_FIELD_FILTER,
  ERROR_V_LIST,
  RECEIVE_V_LIST,
  REQUEST_V_LIST,
  SET_PAGE,
  SET_SELECTED_ROW,
  SET_MASK,
  SET_ORDER,
  ADVANCED_SEARCH_CLOSE,
  ADVANCED_SEARCH_OPEN,
  UPDATE_FIELD_ENTITY,
  CHANGE_IMAGE
} from "./types";

import { exportToSpreadsheet, cortarArreglo, listaD, tipoFormato } from "../../../helpers/commons";
import { format } from "date-fns";

export const setPage = (page) => ({ type: SET_PAGE, page });

export const cleanForm = () => ({ type: CLEAN_FORM });

export const setSelectedRow = (selected) => ({
  type: SET_SELECTED_ROW,
  selected,
});


//======================
export const onExport = () => async (dispatch, getState) => {
  // no se usa otra forma de exportar

  if (getState().gestionpreciosespeciales.filters.distribuidorId   === 0) {
    dispatch(openMessageBox({
        button: 'ok',
        icon: 'warning',
        message: "Debe seleccionar un Agente.",
        callback: () => dispatch(closeMessageBox())
    }));
    return;
  }


  if (getState().gestionpreciosespeciales.store.data.length === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "No hay información para exportar.",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }


  try {
    dispatch({ type: SET_MASK, loading: true });
    let head = getState().gestionpreciosespeciales.tablexport.columns;
    let json = getState().gestionpreciosespeciales.store.data;


    exportToSpreadsheet(cortarArreglo(head,json), "Gestion Precios Especiales");
  } catch (error) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message:
          (error.response.data.eventLogId === 0
            ? ""
            : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    setTimeout(()=>{dispatch({ type: SET_MASK, loading: false })}, 1000)
  }
};
//=====================



export const onSearchDistribuidorClick = () => async (dispatch) => {
  let fields = [
    { dataKey: "razonSocial", label: "Agente", type: "string", width: 225 },
  ];

  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de agente",
      getData: async (params) => {
        let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`;
        let response = await axios.get(encodeURI(url), { params: params });

        return response.data;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(
            onUpdateFieldFilter("distribuidorId", result[0].distribuidorId)
          );
          dispatch(
            onUpdateFieldFilter("dDistribuidor", `${result[0].razonSocial}`)
          );

          dispatch(onUpdateFieldFilter("nombre", ""));

        }
      },
    })
  );
};

export const onSearchListaClick = () => async (dispatch, getState) => {
    let fields = [
      { dataKey: "nombre", label: "Categoria", type: "string", width: 225 },
    ];
  
    //let data = listaD;

    dispatch(
      openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: "Seleccione una Categoria.",
        getData: async (params) => {
          let url = `${getState().main.resources.uriSvcSalesMobilityAPI}/api/v1/Agentes/listaCategoriaArchivos`;
          let response = await axios.get(encodeURI(url), { params: params });
  
          const aux = {
            data: response.data,
            pageSize: 50,
            start: 0,
            total: response.data.length,
          };
          // return response.data;
          return aux;
        },
        callback: (btn, result) => {
          dispatch(closeAdvancedSearch());
          if (btn === "yes") {
            dispatch(onUpdateFieldFilter("listaId", result[0].categoriaArchivoId));
            dispatch(onUpdateFieldFilter("dLista", `${result[0].nombre}`));
          }
        },
      })
    );
};

export const onSearchTipoFormatoClick = () => async (dispatch) => {
    let fields = [
      { dataKey: "dTipo", label: "Fomratos", type: "string", width: 225 },
    ];
  
    let data = tipoFormato;

    dispatch(
      openAdvancedSearch({
        autoLoad: true,
        data: data,
        fields: fields,
        paginated: false,
        remote: false,
        title: "Selección ",
        // getData: async (params) => {
        //   let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`;
        //   let response = await axios.get(encodeURI(url), { params: params });
  
        //   return response.data;
        // },
        callback: (btn, result) => {
          dispatch(closeAdvancedSearch());
          if (btn === "yes") {
            dispatch(onUpdateFieldFilter("tipoId", result[0].tipoId));
            dispatch(onUpdateFieldFilter("dTipo", `${result[0].dTipo}`));
          }
        },
      })
    );
};

export const btnSearch = () => async (dispatch, getState) => {
  if (getState().gestionpreciosespeciales.store.loading) {
    return;
  }

  if (getState().gestionpreciosespeciales.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }


  dispatch({ type: SET_MASK, loading: true });
  
  let source = axios.CancelToken.source();
  dispatch({ type: REQUEST_V_LIST, source });

  try {
    let url =  `${getState().main.resources.uriSvcSalesMobilityAPI}/api/v1/Reportes/reporteprecioscliente?` +
      `distribuidorId=${getState().gestionpreciosespeciales.filters.distribuidorId}` +
      `&nombre=${getState().gestionpreciosespeciales.filters.nombre}`;


    let response = await axios.get(encodeURI(url));
    let data = response.data; 
    
    if (data.length === 0) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "No existe Registro  para esta consulta",
          callback: () => dispatch(closeMessageBox()),
        })
      );
      return;
    }

    let columnas = getState().gestionpreciosespeciales.table.columns;
    
    dispatch({ type: RECEIVE_V_LIST, columns: columnas, payload: data });
  } catch (error) {
    let msgError = "";
    dispatch({ type: ERROR_V_LIST });
    dispatch({ type: SET_MASK, loading: false });

    if (error.response) {
      msgError =
        error.response.data.eventLogId === 0
          ? "No existe Registro  para esta consulta "
          : `EventoId: ${error.response.data.eventLogId}. ` +
            error.response.data.message;
    } else {
      msgError = "Error al conectar con el servidor";
    }

    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message: msgError,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    dispatch({ type: SET_MASK, loading: false });
  }
};

export const setImage = (file, image) => ({ type: CHANGE_IMAGE, file, image });


export const onImport = () => async (dispatch, getState) => {

  dispatch({ type: SET_MASK, loading: true });
  
  if (getState().cargarmiespacio.filters.distribuidorId === 0) {
    dispatch(openMessageBox({
      button: 'ok',
      icon: 'warning',
      message: "Debe seleccionar un Distribuidor.",
      callback: () => dispatch(closeMessageBox())
    }));
    dispatch({ type: SET_MASK, loading: false });
    return;
    }


    if (getState().cargarmiespacio.filters.listaId === 0) {
        dispatch(openMessageBox({
        button: 'ok',
        icon: 'warning',
        message: "Debe seleccionar un Categoria.",
        callback: () => dispatch(closeMessageBox())
        }));
        dispatch({ type: SET_MASK, loading: false });
        return;
    }

    if (getState().cargarmiespacio.entity.tag === '') {
        dispatch(openMessageBox({
          button: 'ok',
          icon: 'warning',
          message: "Debe escribir un tag.",
          callback: () => dispatch(closeMessageBox())
        }));
        dispatch({ type: SET_MASK, loading: false });
        return;
        }

    // if (getState().cargarmiespacio.filters.tipoId === 0) {
    //     dispatch(openMessageBox({
    //       button: 'ok',
    //       icon: 'warning',
    //       message: "Debe seleccionar un Tipo.",
    //       callback: () => dispatch(closeMessageBox())
    //     }));
    //     dispatch({ type: SET_MASK, loading: false });
    //     return;
    // }

    if (getState().cargarmiespacio.entity.comentario === '') {
        dispatch(openMessageBox({
          button: 'ok',
          icon: 'warning',
          message: "Debe escribir un comentario.",
          callback: () => dispatch(closeMessageBox())
        }));
        dispatch({ type: SET_MASK, loading: false });
        return;
    }

  if (getState().cargarmiespacio.store.subida.length === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "No hay información para importar.",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    dispatch({ type: SET_MASK, loading: false });
    return;
    }

     

    //   var recordset = {
    //     'distribuidorId': getState().gestionpreciosespeciales.filters.distribuidorId,
    //     'data': []
    //   };

    //   for (var c in aux) {
    //     let json = {

    //       "nit": aux[c].Nit_Ruc.toString(),
    //       "codigoCliente": aux[c].CodigoCliente.toString(),
    //       "codigoProducto": aux[c].CodigoProducto.toString(),
    //       "nombreProducto": aux[c].Producto.toString(),
    //       "precioEspecial": aux[c].PrecioEspecial.toString(),
    //       "precioMinimo": aux[c].PrecioMinimo.toString(),
    //       "descuento": aux[c].Descuento.toString(),
    //       "cantidad": aux[c].Cantidad.toString(),
    //   };
     
    //     recordset.data.push(json);

    // }

  try {

    getState().cargarmiespacio.clean.limpiador = true;
   
    let trans = getState().cargarmiespacio.store.subida
    //let formData = new FormData ()
    let distribuidorId = getState().cargarmiespacio.filters.distribuidorId;
    let categoria = getState().cargarmiespacio.filters.listaId;
    //let tipo = getState().cargarmiespacio.filters.dTipo;
    let file = getState().cargarmiespacio.store.subida;
    let names = getState().cargarmiespacio.entity.name;
    let comentario = getState().cargarmiespacio.entity.comentario;
    let tag = getState().cargarmiespacio.entity.tag;
    let compartir = getState().cargarmiespacio.filters.compartir;
    let fecha = '2050-12-12'
    
    //console.log("SIN DATO: ", formData)

    //for (var i=0;i < file.length; i++)
    //{
        //const filex = file[i];
        //formData.append('data[]',file[i])
        //formData.append('names[]',names[i])
        // if(file[i].type.includes('application/pdf'))
        // {
        //     let tipo = 'pdf'
        //     formData.append('tipo', tipo)
        // }
        // if(file[i].type.includes('png'))
        // {
        //     let tipo = 'png'
        //     formData.append('tipo', tipo)
        // }
        // if(file[i].type.includes('mp4'))
        // {
        //     let tipo = 'mp4'
        //     formData.append('tipo', tipo)
        // }
    //}

    //console.log("ARREGLO FINAL: ", formData.getAll('data[]'))
   // console.log("ARREGLO FINAL NOMBRES: ", formData.getAll('names[]'))
    // for(var i =0; i<formData.get('file').length; i++)
    // {
    //     console.log("MUESTRA MRD: ", formData.get('file'))
    // }

    let jsonArray = [];



    trans.forEach((item) => {
        if (item.startsWith('data:image/png;base64,')) {
            let dataValue = item.replace("data:image/png;base64,", "");
            let obj = {
              data: dataValue
            };
            jsonArray.push(obj);
          } else if (item.startsWith('data:video/mp4;base64,')) {
            let dataValue = item.replace("data:video/mp4;base64,", "");
            let obj = {
              data: dataValue
            };
            jsonArray.push(obj);
          }
          else if (item.startsWith('data:application/pdf;base64,')) {
            let dataValue = item.replace("data:application/pdf;base64,", "");
            let obj = {
              data: dataValue
            };
            jsonArray.push(obj);
          }
        
          
        
       
    });
    
    
  

    // let registro = {
    //     distribuidorId: distribuidorId  ,
    //     categoriaId: categoria,
    //     compartir: compartir,
    //     descripcion: comentario,
    //     tag: tag,
    //     hasta: format(getState().cargarmiespacio.filters.hasta, 'yyyy-MM-dd'),
    //     nombre: names,
    //     marcas: jsonArray,
    // };

    let registro = {};
   

    if(getState().cargarmiespacio.filters.promocion === false)
    {
        // formData.append('distribuidorId', distribuidorId);
        // formData.append('categoria', categoria);
        
        // formData.append('comentario', comentario);
        // formData.append('fecha',fecha);
        // formData.append('compartir',compartir);
        //formData.append('file', file)

        // console.log("DATO1: ", formData.get('distribuidorId'))
        // console.log("DATO2: ", formData.get('categoria'))
        //console.log("DATO3: ", formData.get('tipo'))
        // console.log("DATO4: ", formData.get('comentario'))
        // console.log("DATO5: ", formData.get('fecha'))
        //console.log("DATO6: ", formData.get('file'))

        registro = {
            distribuidorId: distribuidorId  ,
            categoriaId: categoria,
            compartir: compartir,
            descripcion: comentario,
            tag: tag,
            hasta: fecha,
            nombres: names,
            archivos: jsonArray,
        };

        
    }else if(getState().cargarmiespacio.filters.promocion === true)
    {

        // formData.append('distribuidorId', distribuidorId);
        // formData.append('categoria', categoria);
        //formData.append('tipo', tipo)
        // formData.append('comentario', comentario);
        // formData.append('fecha',format(getState().cargarmiespacio.filters.hasta, 'yyyy-MM-dd'));
        // formData.append('compartir',compartir);
        //formData.append('file', file)

        // console.log("FECHA ELEJIDA")
        // console.log("DATO1: ", formData.get('distribuidorId'))
        // console.log("DATO2: ", formData.get('categoria'))
        //console.log("DATO3: ", formData.get('tipo'))
        // console.log("DATO4: ", formData.get('comentario'))
        // console.log("DATO5: ", formData.get('fecha'))
        //console.log("DATO6: ", formData.get('file'))

         registro = {
            distribuidorId: distribuidorId  ,
            categoriaId: categoria,
            compartir: compartir,
            descripcion: comentario,
            tag: tag,
            hasta: format(getState().cargarmiespacio.filters.hasta, 'yyyy-MM-dd'),
            nombres: names,
            archivos: jsonArray,
        };

    }
    
    //console.log("JSON: ",registro);

    //return 0;
  
     let url = `${getState().main.resources.uriSvcSalesMobilityAPI}/api/v1/Agentes/subirArchivo`;
     let response = await axios.post(url, registro, { headers: { 'Content-Type': 'application/json' } });

     //let data = response.data;


    dispatch(openMessageBox({ 
      button: 'ok', 
      icon: 'info', 
      message: 'Registro realizado con exito.', 
      callback: () => { 
          dispatch(closeMessageBox());
      }
    }));

    dispatch({ type:   CLEAN_FORM });


  } catch (error) {
    dispatch({ type: SET_MASK, loading: false });
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message:
          (error.response.data.eventLogId === 0
            ? ""
            : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    

        dispatch(push({
          pathname: `/fuerzaventas/cargarmiespacio`,
          search: getState().router.location.search,
          hash: getState().router.location.hash
      }));
      dispatch({ type: SET_MASK, loading: false });

  // dispatch({ type: RECEIVE_V_LIST, column: [], payload: [] });
   // return true
  }
};


export const onUpdateColumn = (column) => (dispatch, getState) => {
  getState().gestionpreciosespeciales.table.columns.map((item, i) => {
    if (item.dataKey !== column.dataKey) {
      return item;
    } else {
      item.width = column.width;
    }
    return {
      // ...item,
      //  width: column.width
    };
  });
};

export const onUpdateFieldFilter = (key, value) => (dispatch, getState) => {
  dispatch({ type: UPDATE_FIELD_FILTER, key, value });
  
  if (key !== 'nombre') {
        dispatch({ type: RECEIVE_V_LIST, column: [], payload: [] });
  }
};

export const onUpdateFieldEntity = (key, value) => (dispatch) => {
    dispatch({ type: UPDATE_FIELD_ENTITY, key,value });
};
  
export const openAdvancedSearch = (opts) => ({
  type: ADVANCED_SEARCH_OPEN,
  payload: opts,
});

export const setOrder = (order, orderBy) => ({
  type: SET_ORDER,
  order,
  orderBy,
});

export const closeAdvancedSearch = () => ({ type: ADVANCED_SEARCH_CLOSE });
