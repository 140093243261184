import {
    CLEAN_FORM,
    SET_STATE_FORM,
    UPDATE_FIELD_FILTER,
    SET_MASK,
    RECEIVE_DATA,
    UPDATE_TABLE_COLUMN,
    ERROR_V_LIST,
    RECEIVE_V_LIST,
    REQUEST_V_LIST,
    SHOW_POPUP_CLIENT,
    HIDE_POPUP_CLIENT,
    SET_ORDER
} from './types';

const initialState = {
    loading: false,
    data:[],
    openPopup: false,
    filters: {
        distribuidorId: 0,
        dDistribuidor: '',
        desde: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        hasta: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
    },
    store: {
        loading: false,
        pageSize: 50,
        start: 0,
        total: 0,
        data: [],
        selected: [],
        //source: null
    },
    storeDetalle: {
        data: [],
        selected: []
    },
    table: {
        order: 'asc',
        orderBy: '',
        columns: [
            { dataKey: 'vendedor', label: 'Vendedor', width: 250 },
            { dataKey: 'Tipo', label: 'Tipo', width: 340 },
            { dataKey: 'Comentario', label: 'Comentario', width: 500 },
            { dataKey: 'Latitud', label: 'Latitud', width: 170 },
            { dataKey: 'Longitud', label: 'Longitud', width: 170 },
            { dataKey: 'FechaRegistro', label: 'FechaRegistro', width: 152, },
           
        ]
    },

    tableExport: {
        order: 'asc',
        orderBy: '',
        columns: [
            { dataKey: 'vendedor', label: 'Vendedor', width: 250 },
            { dataKey: 'Tipo', label: 'Tipo', width: 340 },
            { dataKey: 'Comentario', label: 'Comentario', width: 500 },
            { dataKey: 'Latitud', label: 'Latitud', width: 170 },
            { dataKey: 'Longitud', label: 'Longitud', width: 170 },
            { dataKey: 'FechaRegistro', label: 'FechaRegistro', width: 152, },
            { dataKey: 'UriFoto01', label: 'Foto 1', width: 152, },
            { dataKey: 'UriFoto02', label: 'Foto 2', width: 152, },
        ]
    }    

};

export const reportedeincidenciasReducer = (state, action) => {
    state = state || initialState;

    switch(action.type) {
        case CLEAN_FORM:
            return {
                ...state,
                entity: initialState.entity,
                errors: initialState.errors
            };
        case SET_STATE_FORM:
            return {
                ...state,
                formState: action.formState
            };
        case HIDE_POPUP_CLIENT:
            return {
                ...state,
                openPopup: false
            };
        case UPDATE_FIELD_FILTER:
            return { 
                ...state,
                filters: {
                    ...state.filters,
                    [action.key]: action.value
                }
            };
            case ERROR_V_LIST:
            return {
                ...state,
                store: {
                    ...state.store,
                    loading: false
                }
            };
            case RECEIVE_V_LIST:
                return {
                    ...state,
                    store: {
                        ...state.store,
                        loading: false,
                        total: action.total,
                        data: action.payload,
                        source: null
                    }
                    // ,
                    // table: {
                    //     ...state.table,
                    //     columns: action.columns,
                    // }
                };
            case REQUEST_V_LIST:
                return {
                    ...state,
                    store: {
                        ...state.store,
                        loading: true,
                        source: action.source
                    }
                };
            case RECEIVE_DATA:
                return {
                    ...state,
                    data: action.payload,
                    store: {
                        ...state.store,
                        data: action.storeData
                    }
                };
                case UPDATE_TABLE_COLUMN:
                    return {
                        ...state,
                        table: {
                            ...state.table,
                            columns: action.columns
                        }
                    };
            case SET_MASK:
                return {
                    ...state,
                    loading: action.loading
            };
            case SET_ORDER:
                return {
                    ...state,
                    table: {
                        ...state.table,
                        order: action.order,
                        orderBy: action.orderBy
                    }
                };

            case SHOW_POPUP_CLIENT:
                return {
                    ...state,
                    openPopup: true,
                    storeDetalle: {
                        ...state.store,
                        data: action.storeData
                    }
            };
        default:
            return state;
    }
};