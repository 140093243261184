import React, { useEffect, useState } from 'react';
import { useDispatch} from 'react-redux';

import {
    btnSearch,
    onExport 
} from '../../store/clientes/listarprospectos/actions';

import { Body, Footer, Page } from '@sigeco/tools';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import ListarProspectosFilter from '../../components/clientes/listarprospectos/ListarProspectosFilter';
import ListarProspectosMap from '../../components/clientes/listarprospectos/ListarProspectosMap';

import ListarProspectosPanel from '../../components/clientes/listarprospectos/ListarProspectosPanel';

const ListarProspectos = ({onClose, setTitle}) => {
    //const loading = useSelector(state => state.reportepedidos.loading);
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
   // const store = useSelector(state => state.reportepedidos.store);

    useEffect(() => {
        setTitle('Listar Prospectos');
    // eslint-disable-next-line
    }, []);

    function onBtnClose() {
        onClose();
    }

    return (
        <Page>
            {/* <Backdrop style={{ position: 'absolute', zIndex: 1200 }} open={false} transitionDuration={0}>
            <CircularProgress color="inherit" />
        </Backdrop> */}
        <Body>
            <ListarProspectosFilter />
            <ListarProspectosPanel />

        </Body>
        <Footer>
            <Button
                color="primary"
                size="small"
                variant="outlined"
                onClick={() => dispatch(onExport())}
                startIcon={<Icon className="fas fa-file-excel"/>}
            >
                Exportar
            </Button>
            <Button
                color="primary"
                size="small"
                variant="outlined"
                onClick={() => dispatch(btnSearch())}
                startIcon={<Icon className="fas fa-search"/>}
                >
                Buscar
            </Button>
            <Button 
                color="primary"
                size="small"
                variant="outlined"
                onClick={() => setOpen(true)}
                startIcon={<Icon className="fas fa-map-marked-alt"/>}
            >
                Ver Mapa
            </Button>
            <Button 
                color="primary" 
                size="small" 
                variant="outlined" 
                onClick={() => onBtnClose()} 
                startIcon={<Icon className="fas fa-door-open"/>}
            >
                Salir
            </Button>
        </Footer>
        <Dialog fullScreen open={open} onClose={() => setOpen(false)}>
            <AppBar>
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={() => setOpen(false)} aria-label="close">
                <Icon className="fas fa-times" />
                </IconButton>
                <Typography variant="h6">
                Localización de registros Prospectos
                </Typography>
            </Toolbar>
            </AppBar>
            <ListarProspectosMap />
        </Dialog>
        </Page>
    )
};

export default ListarProspectos;