export const CHANGE_IMAGE = 'channelmap/marca/change-image';
export const CLEAN_FORM = 'channelmap/marca/clean-form';
export const ERROR_MARCAS_LIST = 'channelmap/marca/error-marcas-list';
export const RECEIVE_MARCAS_LIST = 'channelmap/marca/receive-marcas-list';
export const REMOVE_IMAGE = 'channelmap/marca/remove-image';
export const REQUEST_MARCAS_LIST = 'channelmap/marca/request-marcas-list';
export const RESTART_STATE = 'channelmap/marca/restart-state';
export const SET_ENTITY = 'channelmap/marca/set-entity';
export const SET_ERROR = 'channelmap/marca/set-error';
export const SET_FORM = 'channelmap/marca/set-form';
export const SET_MASK = 'channelmap/marca/set-mask';
export const SET_STATE_FORM = 'channelmap/marca/set-state-form';
export const UPDATE_FIELD_ENTITY = 'channelmap/marca/update-field-entity';
export const UPDATE_FIELD_FILTER = 'channelmap/marca/update-field-filter';