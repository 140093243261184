import React, { useEffect, useState} from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";

import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import makeStyles from '@material-ui/core/styles/makeStyles';

am4core.useTheme(am4themes_animated);

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column'
    },
    wrap: {
        flex: '1 1 auto'
    }
}));

const ListadoGlobalMap = () => {
    const classes = useStyles();
    const [pais,] = useState('EC')
  
    
    
    
    useEffect(() => {
      var colorSet = new am4core.ColorSet();
  
      console.log("useEffect executed (component mounted)")
   
     
        // Themes begin
        am4core.useTheme(am4themes_animated);
        // Themes end
        var chart = am4core.create("chartdiv", am4maps.MapChart);

       
        // Set map definition
        chart.geodata = am4geodata_worldLow;

        // Set projection
        chart.projection = new am4maps.projections.Miller();

        // Create map polygon series
        var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());

        // Exclude Antartica
        polygonSeries.exclude = ["AQ"];

        // Make map load polygon (like country names) data from GeoJSON
        polygonSeries.useGeodata = true;

        // Configure series
        var polygonTemplate = polygonSeries.mapPolygons.template;
        polygonTemplate.tooltipText = "{name}";
        polygonTemplate.polygon.fillOpacity = 0.6;


        // Create hover state and set alternative fill color
        var hs = polygonTemplate.states.create("hover");
        hs.properties.fill = chart.colors.getIndex(0);

        // Add image series
        var imageSeries = chart.series.push(new am4maps.MapImageSeries());
        imageSeries.mapImages.template.propertyFields.longitude = "longitude";
        imageSeries.mapImages.template.propertyFields.latitude = "latitude";
        imageSeries.mapImages.template.tooltipText = "{title}";
        imageSeries.mapImages.template.propertyFields.url = "url";

        var circle = imageSeries.mapImages.template.createChild(am4core.Circle);
        circle.radius = 3;
        circle.propertyFields.fill = "color";
        circle.nonScaling = true;

        var circle2 = imageSeries.mapImages.template.createChild(am4core.Circle);
        circle2.radius = 3;
        circle2.propertyFields.fill = "color";


        circle2.events.on("inited", function(event){
          animateBullet(event.target);
        })
        //initial 
        chart.homeZoomLevel = 7;
        chart.homeGeoPoint = {
          latitude: 10.366601520929667,
          longitude: -75.5074412985529
        };

        function animateBullet(circle) {
            var animation = circle.animate([{ property: "scale", from: 1 / chart.zoomLevel, to: 15 / chart.zoomLevel }, { property: "opacity", from: 1, to: 0 }], 1000, am4core.ease.circleOut);
            animation.events.on("animationended", function(event){
              animateBullet(event.target.object);
            })
        }
        
    if (pais === 'EC'){
      const aData = [ {
      "title": "LUBRISINU SAS",
      "latitude": 8.741372162617097,
      "longitude": -75.85947477125524,
      "color":colorSet.next()
    }, {
      "title": "Distribuidora de Lubricantes Disterpel",
      "latitude": 4.06261483209733,
      "longitude": -76.1950312027396,
      "color":colorSet.next()
    }, {
      "title": "Distribuidora Alger",
      "latitude": 2.92312199378309557,
      "longitude": -75.29184502280988,
      "color":colorSet.next()
    }, {
      "title": "DISTRIMAXTER",
      "latitude": 6.245196355841085,
      "longitude": -75.58122974522483,
      "color":colorSet.next()
    }, {
      "title": "LUBRITER",
      "latitude": 4.829500663912997,
      "longitude": -75.79395042875626,
      "color":colorSet.next()
    }, {
      "title": "Distribuciones Vicpimar s.a.s",
      "latitude": 10.366601520929667,
      "longitude": -75.5074412985529,
      "color":colorSet.next()
    }, {
      "title": "London",
      "latitude": 51.5002,
      "longitude": -0.1262,
      "url": "http://www.google.co.uk",
      "color":colorSet.next()
    }, {
      "title": "Distertol SAS",
      "latitude": 4.406876827562931,
      "longitude": -75.16360189449159,
      "color":colorSet.next()
    }, {
      "title": "New Delhi",
      "latitude": 28.6353,
      "longitude": 77.2250,
      "color":colorSet.next()
    }, {
      "title": "Tokyo",
      "latitude": 35.6785,
      "longitude": 139.6823,
      "url": "http://www.google.co.jp",
      "color":colorSet.next()
    }, {
      "title": "Ankara",
      "latitude": 39.9439,
      "longitude": 32.8560,
      "color":colorSet.next()
    }, {
      "title": "Buenos Aires",
      "latitude": -34.6118,
      "longitude": -58.4173,
      "color":colorSet.next()
    }, {
      "title": "Brasilia",
      "latitude": -15.7801,
      "longitude": -47.9292,
      "color":colorSet.next()
    }, {
      "title": "Ottawa",
      "latitude": 45.4235,
      "longitude": -75.6979,
      "color":colorSet.next()
    }, {
      "title": "Washington",
      "latitude": 38.8921,
      "longitude": -77.0241,
      "color":colorSet.next()
    }, {
      "title": "Kinshasa",
      "latitude": -4.3369,
      "longitude": 15.3271,
      "color":colorSet.next()
    }, {
      "title": "Cairo",
      "latitude": 30.0571,
      "longitude": 31.2272,
      "color":colorSet.next()
    }, {
      "title": "Pretoria",
      "latitude": -25.7463,
      "longitude": 28.1876,
      "color":colorSet.next()
    } ];
    
    imageSeries.data = aData;
    }
    if (pais === 'CO'){
       const aData =  [ 
        {
        "title": "London",
        "latitude": 51.5002,
        "longitude": -0.1262,
        "url": "http://www.google.co.uk",
        "color":colorSet.next()
      }, {
        "title": "Distertol SAS",
        "latitude": 4.406876827562931,
        "longitude": -75.16360189449159,
        "color":colorSet.next()
      }, {
        "title": "New Delhi",
        "latitude": 28.6353,
        "longitude": 77.2250,
        "color":colorSet.next()
      }, {
        "title": "Tokyo",
        "latitude": 35.6785,
        "longitude": 139.6823,
        "url": "http://www.google.co.jp",
        "color":colorSet.next()
      }, {
        "title": "Ankara",
        "latitude": 39.9439,
        "longitude": 32.8560,
        "color":colorSet.next()
      }, {
        "title": "Buenos Aires",
        "latitude": -34.6118,
        "longitude": -58.4173,
        "color":colorSet.next()
      }, {
        "title": "Brasilia",
        "latitude": -15.7801,
        "longitude": -47.9292,
        "color":colorSet.next()
      }, {
        "title": "Ottawa",
        "latitude": 45.4235,
        "longitude": -75.6979,
        "color":colorSet.next()
      }, {
        "title": "Washington",
        "latitude": 38.8921,
        "longitude": -77.0241,
        "color":colorSet.next()
      }, {
        "title": "Kinshasa",
        "latitude": -4.3369,
        "longitude": 15.3271,
        "color":colorSet.next()
      }, {
        "title": "Cairo",
        "latitude": 30.0571,
        "longitude": 31.2272,
        "color":colorSet.next()
      }, {
        "title": "Pretoria",
        "latitude": -25.7463,
        "longitude": 28.1876,
        "color":colorSet.next()
      } ];
      imageSeries.data = aData;
      }
    
     
    
        return () => {
            chart.dispose();
        };
  
      
    }, [ pais]);


    return (
        <div className={classes.root}>
            <div id="chartdiv" className={classes.wrap}>
            </div>
        </div>
    );
};


export default ListadoGlobalMap;