import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    onUpdateColumn,
    setOrder,
    setSelectedRow
} from '../../../store/clientes/baseclientes/actions';
import { sort_by} from '../../../helpers/commons'

import { FieldSet, Table } from '@sigeco/tools';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column'
    }, 
    tableWrap: {
        flex: '1 1 auto'
    }
}));

const BaseClientesList = () => {
    const classes = useStyles();
    const columns = useSelector(state => state.baseclientes.table.columns);
    const loading = useSelector(state => state.baseclientes.store.loading);
    const order = useSelector(state => state.baseclientes.table.order);
    const orderBy = useSelector(state => state.baseclientes.table.orderBy);
    const store = useSelector(state => state.baseclientes.store);
    const dispatch = useDispatch();

    const handleDoubleClick = (id) => {
        return;
    }

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        dispatch(setOrder(isAsc ? 'desc' : 'asc', property));
        let homes = store.data;
        homes.sort(sort_by(property, isAsc, homes[0][property]));
        
    };

    const handleResizeColumn = (column) => {
        dispatch(onUpdateColumn(column))
    };
    
    return (
        <FieldSet className={classes.root} iconCls="fas fa-th-list" title="Resultados">
        <div className={classes.tableWrap}>
            <Table
                columns={columns}
                count={store.total}
                loading={loading}
                onRowClick={items => dispatch(setSelectedRow(items))}
                onRowDoubleClick={item => handleDoubleClick(item.usuarioId)}
                onSort={datakey => handleRequestSort(datakey)}
                onUpdateColumn={handleResizeColumn}
                rowCount={store.data.length}
                rowGetter={index => store.data[index]}
                selected={store.selected}
                sortBy={orderBy}
                sortDirection={order}
            />
        </div>
    </FieldSet>
    )
};

export default BaseClientesList;