export const ERROR_OLAS_LIST = 'movilidad/bandejaaprobacion/error-olas-list';
export const RECEIVE_OLAS_LIST = 'movilidad/bandejaaprobacion/receive-reportepedidos-list';
export const  RECEIVE_OLAS_DET = 'movilidad/bandejaaprobacion/receive-reportepedidos-det';
export const  RECEIVE_COBRANZA_DET = 'movilidad/bandejaaprobacion/receive-cobranza-det';
export const  RECEIVE_HISTORICO_DETAIL= 'movilidad/bandejaaprobacion/receive-reportepedidos-detail';
export const  RECEIVE_OLAS_PORCOBRAR= 'movilidad/bandejaaprobacion/receive-reportepedidos-porcobrar';
export const REQUEST_OLAS_LIST = 'movilidad/bandejaaprobacion/request-reportepedidos-list';
export const RESTART_STATE = 'movilidad/bandejaaprobacion/restart_state';
export const SET_MASK = 'movilidad/bandejaaprobacion/set-mask';
export const SET_ORDER = 'movilidad/bandejaaprobacion/set-order';
export const SET_SELECTED_ROW = 'movilidad/bandejaaprobacion/set-selected-row';
export const UPDATE_FIELD_FILTER = 'movilidad/bandejaaprobacion/update-field-filter';
export const UPDATE_FIELD_FILTERH = 'movilidad/bandejaaprobacion/update-field-filterh';
export const UPDATE_TABLE_COLUMN = 'movilidad/bandejaaprobacion/set-selected-row';
export const UPDATE_FIELD_ENTITYS = "movilidad/bandejaaprobacion/update-field-entitys";

export const ON_SET_BANDEJA = "movilidad/bandejaaprobacion/on-set-bandeja";
export const RECEIVE_PASE_PEDIDO_LIST = 'movilidad/bandejaaprobacion/receive-pase-pedido-list';
export const SET_DATA_USERID = 'movilidad/bandjaaprobacion/set-data-userid';
export const RECEIVE_APROBAR_COBRANZA_LIST = 'movilidad/bandejaaprobacion/receive-aprobar-cobranza-list';
export const HANDLE_SHOW = 'movilidad/bandejaaprobacion/handle-show';

