import axios from "axios";
import { IdentityUrl } from "../../../config";
import { closeMessageBox, openMessageBox } from "../../main/actions";

import {
  CLEAN_FORM,
  SET_STATE_FORM,
  UPDATE_FIELD_FILTER,
  ERROR_V_LIST,
  RECEIVE_V_LIST,
  REQUEST_V_LIST,
  SET_PAGE,
  SET_SELECTED_ROW,
  SET_MASK,
  SET_ORDER,
  ADVANCED_SEARCH_CLOSE,
  ADVANCED_SEARCH_OPEN,
} from "./types";

import { exportToSpreadsheet } from "../../../helpers/commons";

export const setPage = (page) => ({ type: SET_PAGE, page });

export const cleanForm = () => ({ type: CLEAN_FORM });

//export const onUpdateFieldFilter = (key, value) => ({ type: UPDATE_FIELD_FILTER, key, value });

export const setSelectedRow = (selected) => ({
  type: SET_SELECTED_ROW,
  selected,
});

export const setFormState = (formState, id) => (dispatch, getState) => {
  switch (formState) {
    case "edit":
      if (id && id !== getState().listarproductos.entity.vendedorId) {
        //dispatch(getUser(id));
      }
      break;
    case "new":
      dispatch(cleanForm());
      break;
    case "search":
      dispatch(cleanForm());
      break;
    case "view":
      if (id && id !== getState().listarproductos.entity.vendedorId) {
        //dispatch(getUser(id));
      }
      break;
    default:
      break;
  }
  dispatch({ type: SET_STATE_FORM, formState });
};


export const onExport = () => async (dispatch, getState) => {
  // no se usa otra forma de exportar
  
  if (getState().listarproductos.filters.distribuidorId   === 0) {
    dispatch(openMessageBox({
      button: 'ok',
      icon: 'warning',
      message: "Debe seleccionar un Agente..",
      callback: () => dispatch(closeMessageBox())
    }));
    return;
    
    }
  if (getState().listarproductos.store.data.length === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "No hay información para exportar.",
        callback: () => dispatch(closeMessageBox()),
      })
      );
      return;
    }
    
  if (getState().listarproductos.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox()),
      })
      );
      return;
    }

    
    try {
      
      dispatch({ type: SET_MASK, loading: true }); 
    let head = getState().listarproductos.table.columns;

    var aux = [];

    for (var c in head) {
      aux[c] = Object.values(head[1])[1];
      aux[1] = Object.values(head[2])[1];
      aux[2] = Object.values(head[0])[1];
      aux[3] = Object.values(head[3])[1];
      aux[4] = Object.values(head[4])[1];
      aux[5] = Object.values(head[5])[1];

      //aux.push(x);
    }

    /*for(var c in head) {
    let x =   Object.values(head[c])[0];
    temp.push(x);
  }  
  // eslint-disable-next-line no-array-constructor
  */
    var carArray = [];

    carArray.push(aux);

    let json = getState().listarproductos.store.data;

    var json1 = json.map(obj =>
      obj.Activo === true ? { ...obj, Activo: "Si" } : { ...obj, Activo: "No" }
    );

    for (var i in json1) {
      let x = Object.values(json1[i]);
      carArray.push(x);
    }

    exportToSpreadsheet(carArray, "ListarProductos");
  } catch (error) {
    dispatch({ type: SET_MASK, loading: false });
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message:
          (error.response.data.eventLogId === 0
            ? ""
            : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    setTimeout(() => {dispatch({ type: SET_MASK, loading: false })}, 1000);
  }
};


export const btnSearch = () => (dispatch) => {
  dispatch(setPage(0));
  dispatch(onUserList());
};

export const onSearchDistribuidorClick = () => async (dispatch) => {
  let fields = [
    { dataKey: "razonSocial", label: "Agente", type: "string", width: 225 },
  ];

  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de agente",
      getData: async (params) => {
        let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`;
        let response = await axios.get(encodeURI(url), { params: params });

        return response.data;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(
            onUpdateFieldFilter("distribuidorId", result[0].distribuidorId)
          );
          dispatch(
            onUpdateFieldFilter("dDistribuidor", `${result[0].razonSocial}`)
          );
          dispatch(onUpdateFieldFilter("nombre", ""));
        }
      },
    })
  );
};

export const onUserList = () => async (dispatch, getState) => {
  if (getState().listarproductos.store.loading) {
    return;
  }

  if (getState().listarproductos.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }
  dispatch({ type: SET_MASK, loading: true });
  //console.log(getState().vendedor);
  //dispatch({ type: RECEIVE_OLAS_LIST, columns: getState().vendedor.table.columns, payload: [] });
  let source = axios.CancelToken.source();
  dispatch({ type: REQUEST_V_LIST, source });

  try {
    let url =
      `${
        getState().main.resources.uriSvcSalesMobilityAPI
      }api/v1/Productos/listar?` +
      `distribuidorId=${getState().listarproductos.filters.distribuidorId}` +
      `&nombre=${getState().listarproductos.filters.nombre}`;

    let response = await axios.get(encodeURI(url));
    let data = response.data;

    if (data.length === 0) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "No existe Registro  para esta consulta",
          callback: () => dispatch(closeMessageBox()),
        })
      );
      return;
    }

    let columnas = getState().listarproductos.table.columns;
    //console.log(columnas);
    dispatch({ type: RECEIVE_V_LIST, columns: columnas, payload: data });
  } catch (error) {
    let msgError = "";
    dispatch({ type: ERROR_V_LIST });
    dispatch({ type: SET_MASK, loading: false });

    if (error.response) {
      msgError =
        error.response.data.eventLogId === 0
          ? "No existe Registro  para esta consulta "
          : `EventoId: ${error.response.data.eventLogId}. ` +
            error.response.data.message;
    } else {
      msgError = "Error al conectar con el servidor";
    }

    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message: msgError,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    dispatch({ type: SET_MASK, loading: false });
  }
};

export const onUpdateColumn = (column) => (dispatch, getState) => {
  getState().listarproductos.table.columns.map((item, i) => {
    if (item.dataKey !== column.dataKey) {
      return item;
    } else {
      item.width = column.width;
    }
    return {
      // ...item,
      //  width: column.width
    };
  });
};

export const onUpdateFieldFilter = (key, value) => (dispatch, getState) => {
  dispatch({ type: UPDATE_FIELD_FILTER, key, value });

  dispatch({ type: RECEIVE_V_LIST, column: [], payload: [] });
};

export const openAdvancedSearch = (opts) => ({
  type: ADVANCED_SEARCH_OPEN,
  payload: opts,
});

export const setOrder = (order, orderBy) => ({
  type: SET_ORDER,
  order,
  orderBy,
});

export const closeAdvancedSearch = () => ({ type: ADVANCED_SEARCH_CLOSE });
