export const CLEAN_FORM = 'clientes/prospectos/clean-form';
export const FILTER_MAP_VENDEDOR = 'clientes/prospectos/filter-map-vendedor';
export const HIDE_POPUP_CLIENT = 'clientes/prospectos/hide-popup-client';
export const RECEIVE_DATA = 'clientes/prospectos/receive_data';
export const RECEIVE_SUB_DETALLE = 'clientes/prospectos/receive_sub_detalle';
export const SET_MASK = 'clientes/prospectos/set-mask';
export const SET_ORDER = 'clientes/prospectos/set-order';
export const SET_SELECTED_ROW = 'clientes/prospectos/set-selected-row';
export const SHOW_POPUP_CLIENT = 'clientes/prospectos/show-popup-client';
export const UPDATE_FIELD_FILTER = 'clientes/prospectos/update-field-filter';
export const UPDATE_TABLE_COLUMN = 'clientes/prospectos/update-table-column';
export const RECEIVE_OLAS_LIST = 'clientes/prospectos/receive_olas_list';
export const SET_STATE_FORM = 'clientes/prospectos/set-state-form';
export const RESTART_STATE = 'clientes/prospectos/restart-state';
export const SET_ENTITY = 'clientes/prospectos/set-entity';
export const UPDATE_FIELD_ENTITY = 'clientes/prospectos/update-field-entity';
export const SET_PAGE = 'clientes/prospectos/set-page';
export const SET_PAGE_SIZE = 'clientes/prospectos/set-page-size';
export const UPDATE_FIELD_ENTITYS = "clientes/prospectos/update-field-entitys";
