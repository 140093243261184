import React from 'react';
import { useSelector } from 'react-redux';

import { KeyboardDatePicker } from '@material-ui/pickers';
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import { FieldSet } from '@sigeco/tools';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(() => ({
    content: {
        padding: '0.5rem 0.75rem 0.75rem'
    },
    map: {
        border: '1px solid #d3d3d3',
        height: 150,
        marginTop: '.5rem'
    },
    paper: {
        marginBottom: '0.75rem',
        '@media (min-width: 1401px)': {
            marginBottom: '1rem'
        }
    }
}));

const DesactivacionForm = ({ google }) => {
    const classes = useStyles();
    const big = useMediaQuery('(min-width:1201px)');
    const middle = useMediaQuery('(min-width:701px)');
    const entity = useSelector(state => state.desactivacion.entity);

    return (
        <div>
            <FieldSet className={classes.paper} iconCls="fas fa-address-card" title="Datos Cliente">
                <Grid className={classes.content} container spacing={1}>
                    <Grid container item spacing={1} xs={12}>
                        <Grid item xs={big ? 6 : (middle ? 6 : 12)}>
                            <TextField
                                fullWidth
                                label="Agente"
                                value={entity.dDistribuidor}
                                variant={middle ? "outlined" : "standard"}
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item spacing={1} xs={12}>
                        <Grid item xs={big ? 6 : (middle ? 6 : 12)}>
                            <TextField
                                fullWidth
                                label="Cliente"
                                value={entity.dCliente}
                                variant={middle ? "outlined" : "standard"}
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item spacing={1} xs={12}>
                        <Grid item xs={middle ? 2 : 4}>
                            <TextField
                                fullWidth
                                label="Cód. Sucursal"
                                value={entity.codigoSucursal}
                                variant={middle ? "outlined" : "standard"}
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={middle ? 4 : 8}>
                            <TextField
                                fullWidth
                                label="Dirección"
                                value={entity.sucursal}
                                variant={middle ? "outlined" : "standard"}
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item spacing={1} xs={12}>
                        <Grid item xs={big ? 6 : (middle ? 6 : 12)}>
                            <InputLabel>Localización</InputLabel>
                            { (parseInt(entity.sucursalLatitud) !== 0  && parseInt(entity.sucursalLongitud) !== 0) 
                            && (<div className={classes.map}>
                                <Map
                                    initialCenter={{ lat: parseFloat(entity.sucursalLatitud), lng: parseFloat(entity.sucursalLongitud) }}
                                    google={google}
                                    containerStyle={{width: '100%', height: '100%', position: 'relative'}}
                                    zoom={14}
                                >
                                    <Marker
                                        position={{lat: parseFloat(entity.sucursalLatitud), lng: parseFloat(entity.sucursalLongitud)}}
                                        title={entity.dCliente}
                                    />
                                </Map>
                            </div>)}
                            { (parseInt(entity.sucursalLatitud) === 0  && parseInt(entity.sucursalLatitud) === 0) 
                            && (<div className={classes.map}>
                                    No tiene Georeferencia
                            </div>)}
                        </Grid>
                    </Grid>
                </Grid>
            </FieldSet>
            <FieldSet iconCls="fas fa-map-marked-alt" title="Datos Desactivación">
                <Grid className={classes.content} container spacing={1}>
                    <Grid container item spacing={1} xs={12}>
                        <Grid item xs={middle ? 6 : 12}>
                            <TextField
                                fullWidth
                                label="Comentario"
                                multiline
                                rows={2}
                                value={entity.comentario}
                                variant={middle ? "outlined" : "standard"}
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item spacing={1} xs={12}>
                        <Grid item xs={middle ? 4 : 8}>
                            <TextField
                                fullWidth
                                label="Usuario Registro"
                                value={entity.usuarioRegistro}
                                variant={middle ? "outlined" : "standard"}
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={middle ? 2 : 4}>
                            <KeyboardDatePicker
                                format="dd/MM/yyyy"
                                label="Fecha"
                                value={entity.fecha}
                                inputVariant={middle ? "outlined" : "standard"}
                                readOnly
                                variant={middle ? "inline" : "dialog"}
                                TextFieldComponent={props => <TextField fullWidth {...props}/>}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item spacing={1} xs={12}>
                        <Grid item xs={middle ? 4 : 8}>
                            <TextField
                                fullWidth
                                label="Motivo Desactivación"
                                value={entity.dMotivoDesactivacion}
                                variant={middle ? "outlined" : "standard"}
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={middle ? 2 : 4}>
                            <TextField
                                fullWidth
                                label="Estado"
                                value={entity.dEstado}
                                variant={middle ? "outlined" : "standard"}
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item spacing={1} xs={12}>
                        <Grid item xs={big ? 6 : (middle ? 6 : 12)}>
                            <InputLabel>Foto</InputLabel>
                            {!entity.foto || entity.foto === '' ? (<Link className={classes.link} >No hay enlace</Link>) :
                                (<Link className={classes.link} href={entity.foto} target="_blank" >Click para descargar foto</Link>)
                            }
                        </Grid>
                    </Grid>
                    <Grid container item spacing={1} xs={12}>
                        <Grid item xs={big ? 6 : (middle ? 6 : 12)}>
                            <InputLabel>Localización</InputLabel>
                            { entity.latitud !== 0 && (<div className={classes.map}>
                                <Map
                                    initialCenter={{ lat: parseFloat(entity.latitud), lng: parseFloat(entity.longitud) }}
                                    google={google}
                                    containerStyle={{width: '100%', height: '100%', position: 'relative'}}
                                    zoom={14}
                                >
                                    <Marker
                                        position={{lat: parseFloat(entity.latitud), lng: parseFloat(entity.longitud)}}
                                        title={entity.usuarioRegistro}
                                    />
                                </Map>
                            </div>)}
                        </Grid>
                    </Grid>
                </Grid>
            </FieldSet>
        </div>
    );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyCrwct6vhZk5TTSuht7PpA0kAVSdoWSB7s"
})(DesactivacionForm);