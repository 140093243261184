import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setOpenMenu
} from '../store/main/actions';

import makeStyles from '@material-ui/core/styles/makeStyles';
import SideBar from '../components/main/SideBar';
import TopBar from '../components/main/TopBar';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(theme => ({
    component: {
        height: 'calc(100% - 3.125rem)',
        paddingLeft: '5rem',
        transition: theme.transitions.create(['width', 'padding'], {
          easing: theme.transitions.easing.sharp,
          duration: 150
        }),
        '@media (min-width: 751px)': {
          height: 'calc(100% - 3.75rem)',
          paddingLeft: '5.625rem'
        },
        '@media (min-width: 1201px)': {
          height: 'calc(100% - 4.375rem)',
          paddingLeft: '6.25rem'
        },
        '@media (min-width: 1451px)': {
          height: 'calc(100% - 5rem)',
          paddingLeft: '6.875rem',
        }
    },
    componentShift: {
        paddingLeft: 0
    },
    content: {
        width: '100%',
        height: '100%'
    },
    root: {
        height: '100%',
        overflow: 'hidden',
        width: '100%'
    },
    toolbar: {
        minHeight: '3.125rem',
        '@media (min-width: 751px)': {
          minHeight: '3.75rem'
        },
        '@media (min-width: 1201px)': {
          minHeight: '4.375rem'
        },
        '@media (min-width: 1451px)': {
          minHeight: '5rem'
        }
    }
}));

const Main =  props => {
  const classes = useStyles();
  const matches = useMediaQuery('(min-width:751px)');
  const openMenu = useSelector(state => state.main.openMenu);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setOpenMenu(matches));
  // eslint-disable-next-line
  }, [matches]);

  return (
    <div className={classes.root}>
      <SideBar view='/usuario'/>
      <TopBar pais={props.pais}/>
      <main className={classes.content}>
        <div className={classes.toolbar} />
          <div className={clsx(classes.component, {
            [classes.componentShift]: !openMenu || !matches 
          })}>
            {props.children}
        </div>
      </main>
    </div>
  );
};

export default Main;