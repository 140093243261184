import axios from 'axios'//;
import { IdentityUrl } from '../../../config';
import { closeMessageBox,  openMessageBox } from '../../main/actions';

import {
    CLEAN_FORM,
    SET_STATE_FORM,
    UPDATE_FIELD_FILTER,
    ERROR_V_LIST,
    RECEIVE_V_LIST,
    REQUEST_V_LIST,
    SET_PAGE,
    SET_PAGE_SIZE,
    SET_SELECTED_ROW,
    SET_MASK,
    SET_ORDER,
    ADVANCED_SEARCH_CLOSE,
    ADVANCED_SEARCH_OPEN,
    UPDATE_FIELD_ENTITY,
    SET_ERROR
} from './types';

import {exportToSpreadsheet,searchVendedor} from '../../../helpers/commons'

export const cleanForm = () => ({ type: CLEAN_FORM });

export const btnExport = () => async (dispatch, getState) => {
   
    if (getState().clientes.filters.distribuidorId   === 0) {
        dispatch(openMessageBox({
            button: 'ok',
            icon: 'warning',
            message: "Debe seleccionar un Agente..",
            callback: () => dispatch(closeMessageBox())
        }));
    return;
    }

    if(getState().clientes.store.data.length === 0) {
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'warning', 
            message: 'No hay información para exportar.', 
            callback: () => dispatch(closeMessageBox())
        }));
        return;
    }

    try {
        dispatch({ type: SET_MASK, loading: true });

        let head = getState().clientes.table.columns;
        var aux = [];

            for (var c in head)
                {
                   aux[c] = Object.values(head[0])[1];//id cliente
                   aux[1] = Object.values(head[2])[1];//nombre cliente
                   aux[2] = Object.values(head[3])[1];//direccion
                   aux[3] = 'Cliente SucursalId'// Object.values(head[6])[1];//activo
                   aux[4] = Object.values(head[4])[1];//codigo
                   aux[5] = Object.values(head[1])[1];//nit
                   aux[6] = Object.values(head[5])[1];//distribuidor

                }
                var carArray = [];
                carArray.push(aux);

                let json = getState().clientes.store.data;
        
                for(var i in json) {
                    let x =   Object.values(json[i]);
                    x.splice(11,1);
                    x.splice(10,1);
                    x.splice(8,1);
                    x.splice(5,1);
                    x.splice(1,1);
                    
                    carArray.push(x);
                  }
        exportToSpreadsheet(carArray, 'Clientes') ;
    } catch (error) {
        dispatch({ type: SET_MASK, loading: false });
        console.log(error)
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'error', 
            message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
            callback: () => dispatch(closeMessageBox())
        }));
    } finally {
        setTimeout(() => {dispatch({ type: SET_MASK, loading: false })}, 1000);
    }
};

export const setSelectedRow = (selected) => ({ type: SET_SELECTED_ROW, selected});

export const setFormState = (formState, id) => (dispatch, getState) => {
    switch (formState) {
        case 'edit':
            if(id && id !== getState().vendedor.entity.vendedorId) {
                //dispatch(getUser(id));
            }
            break;
        case 'new':
            dispatch(cleanForm());
            break;
        case 'search':
            dispatch(cleanForm());
            break;
        case 'view':
            if(id && id !== getState().vendedor.entity.vendedorId) {
                //dispatch(getUser(id));
            }
            break;
        default:
            break;
    }
    dispatch({ type: SET_STATE_FORM, formState });
};

export const btnSearch = () => (dispatch) => {
    dispatch(setPage(0));
    dispatch(onUserList());
};

export const onSearchDistribuidorClick = () => async (dispatch) => {
    let fields = [{ 'dataKey': 'razonSocial', 'label': 'Agente', 'type': 'string', 'width': 225}];

    dispatch(onUpdateFieldFilter('distribuidorId', 0));
    dispatch(onUpdateFieldFilter('dDistribuidor', 'Agente'));
    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de Agente',
        getData: async (params) => {
            let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`
            let response = await axios.get(encodeURI(url), { params: params });
            
            return response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldFilter('distribuidorId', result[0].distribuidorId));
                dispatch(onUpdateFieldFilter('dDistribuidor', `${result[0].razonSocial}`));
                dispatch(onUpdateFieldFilter('vendedorId', 0));
                dispatch(onUpdateFieldFilter('dVendedor', ''));
                dispatch(onUpdateFieldFilter('nombre', ''));
            }
        }
    }));
};

export const onSearchVendedorPorRolClick = () => async (dispatch, getState) => {
    let fields = [
      { dataKey: "nombre", label: "Nombre",        type: "string",        search: true,        width: 225      },
      { dataKey: "vendedorId", label: "Codigo", type: "string", width: 115 },
    ];
  
    if (getState().clientes.filters.distribuidorId === 0) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "Debe seleccionar un Agente..",
          callback: () => dispatch(closeMessageBox()),
        })
      );
      return;
    }
    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: "Selección de Vendedor",
        getData: async (params) => {
            let url = `${getState().main.resources.uriSvcSalesMobilityAPI}/api/v1/Vendedores/listarvendedoresxdistribuidor`
            + `?distribuidorId=${getState().clientes.filters.distribuidorId}`               
            let response = await axios.get(encodeURI(url), { params: params });
           
           return searchVendedor(response, params.nombre); 
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldFilter('vendedorId', result[0].vendedorId));
                dispatch(onUpdateFieldFilter('dVendedor', result[0].nombre)); 
              }
        }
    }));
};

export const onUpdateFieldEntity = (key, value) => (dispatch) => {
    dispatch({ type: UPDATE_FIELD_ENTITY, key, value });

    if(key === 'nombre' || key === 'nombreUsuario' || key === 'correo' || key === 'distribuidor' || key === 'rol') {
        dispatch({ type: SET_ERROR, key, error: '' });
    }
};

export const onUserList = () => async (dispatch, getState) => {
    if (getState().clientes.filters.distribuidorId   === 0) {
        dispatch(openMessageBox({
            button: 'ok',
            icon: 'warning',
            message: "Debe seleccionar un Agente..",
            callback: () => dispatch(closeMessageBox())
        }));
    return;
    }
    if (getState().clientes.store.loading) {
        return;
    }
  
    dispatch({ type: SET_MASK, loading: true});
    let source = axios.CancelToken.source();

    dispatch({ type: REQUEST_V_LIST , source });

    try {
        let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Clientes/distribuidorvendedor?` 
        //let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Clientes?`
        //+ `soloActivos=true` 
        + `distribuidorId=${getState().clientes.filters.distribuidorId}`
        + `&vendedorId=${getState().clientes.filters.vendedorId}`
        + `&nombre=${getState().clientes.filters.nombre}`
        + `&todosClientes=0`;
                  
        let response = await axios.get(encodeURI(url));
        let data = response.data;

        if (data.length === 0) {
            dispatch(openMessageBox({
                button: 'ok',
                icon: 'warning',
                message: "No existe Registro  para esta consulta",
                callback: () => dispatch(closeMessageBox())
            }));
        return;
    }
       dispatch({ type: RECEIVE_V_LIST, payload: data });
    } catch (error) {
        let msgError = '';
        dispatch({ type: ERROR_V_LIST });
        dispatch({ type: SET_MASK, loading: false });
   
        if (error.response) {
            msgError = (error.response.data.eventLogId === 0 ? 'No existe Registro  para esta consulta ' : `EventoId: ${error.response.data.eventLogId}. `+ error.response.data.message) 
        }
        else {
            msgError = 'Error al conectar con el servidor';
        }

        dispatch(openMessageBox({
            button: 'ok',
            icon: 'error',
            message: msgError,
            callback: () => dispatch(closeMessageBox())
        }));
    }finally{
        dispatch({ type: SET_MASK, loading: false });
    }
};

export const onUpdateColumn = (column) => (dispatch, getState) => {
    getState().clientes.table.columns.map((item , i)=> {     
       if(item.dataKey !== column.dataKey) {
           return item;
       }else{
               item.width =  column.width;
       }
       return {
          // ...item,
         //  width: column.width
       };
      
   });

   // dispatch({ type: UPDATE_TABLE_COLUMN, columns }); 
};

export const setPage = (page) => ({ type: SET_PAGE, page });

export const setPageSize = (pageSize) => ({ type: SET_PAGE_SIZE, pageSize });

export const onUpdateFieldFilter = (key, value) => (dispatch, getState) =>{ 
       
    dispatch({type: UPDATE_FIELD_FILTER, key, value});
    
    dispatch({ type: RECEIVE_V_LIST, payload: [] });
    
}

export const openAdvancedSearch = (opts) => ({ type: ADVANCED_SEARCH_OPEN, payload: opts });

export const setOrder = (order, orderBy) => ({ type: SET_ORDER, order, orderBy });

export const closeAdvancedSearch = () => ({ type: ADVANCED_SEARCH_CLOSE });


