export const FILTER_MAP_VENDEDOR = 'movilidad/reportevisitas/filter-map-vendedor';
export const HIDE_POPUP_CLIENT = 'movilidad/reportevisitas/hide-popup-client';
export const RECEIVE_DATA = 'movilidad/reportevisitas/receive_data';
export const SET_MASK = 'movilidad/reportevisitas/set-mask';
export const SET_ORDER = 'movilidad/reportevisitas/set-order';
export const SET_ORDER_DETAIL = 'movilidad/reportevisitas/set-order-detail';
export const SET_SELECTED_ROW = 'movilidad/reportevisitas/set-selected-row';
export const SHOW_POPUP_CLIENT = 'movilidad/reportevisitas/show-popup-client';
export const UPDATE_FIELD_FILTER = 'movilidad/reportevisitas/update-field-filter';
export const UPDATE_TABLE_COLUMN = 'movilidad/reportevisitas/update-table-column';
export const   RECEIVE_OLAS_LIST = 'movilidad/reportevisitas/receive_olas_list';