import React , {  useState, useEffect} from 'react';
import {  useSelector } from 'react-redux';

const caja = {
  position: 'relative',
  display: 'inline-block'
}

const p = {
  position: 'absolute',
  top: '25%',
  left: '70%',
  color: 'white',
};


const PdfCobertura = (items) => {

  const  ComponentToPrint = () => {
   
      return (
        <>

        <div style={caja}>  
          <img alt='logo'  src={ './images/mobil-gigantes.png'}  style={{width: '125%', height:'130px'}}/>
          <div style={p}><h2>Cobertura</h2></div>   
         </div>
        
        <table>
          <thead>
            <tr>
              <th style={{width: '10%', height:'100%'}}></th>   
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">
              </th>
            </tr>
          </tbody>
        </table>
    
        <table>

          <tbody>
            <tr>
              <th scope="row" style={{width: '80px', height:'100%'}}></th>
              <td style={{ border: '1px solid', borderColor: 'white',  backcolor: '#00FF00', backgroundColor: '#4169e1', color: 'white', width: '230px'}}>
                  3 a 5 Nuevos Clientes</td>
              <td style={{ border: '1px solid', fontsize: '25px', fontFamily: 'sans-serif-medium' , borderColor: 'blue',   textAlign:  'center', color: '#191970', width: '280px'}}><h1>USD 50</h1></td>
            </tr>
         
            {renderBody(items.items) } 
            <tr style={{height: '30px'}} ></tr>
      
            <tr>
              <th scope="row"></th>
              <td style={{ border: '1px solid',  borderColor: 'white',  backcolor: '#00FF00', backgroundColor: '#4169e1', color: 'white'}}>
                6 a 8 Nuevos Clientes</td>
                <td style={{ border: '1px solid', fontsize: '25px', fontFamily: 'sans-serif-medium' , borderColor: 'blue',   textAlign:  'center', color: '#191970', width: '280px'}}><h1>USD 75</h1></td>
            </tr>
   
               {renderBody(cobseis) }
               <tr style={{height: '30px'}} ></tr>
            <tr>
              <th scope="row"></th>
              <td style={{ border: '1px solid', borderColor: 'white',  backcolor: '#00FF00', backgroundColor: '#4169e1', color: 'white'}}>
                9 Nuevos Clientes en Adelante</td>
                <td style={{ border: '1px solid', fontsize: '25px', fontFamily: 'sans-serif-medium' , borderColor: 'blue',   textAlign:  'center', color: '#191970', width: '280px'}}><h1>USD 100</h1></td>
              </tr>
         
                {renderBody(cobnueve) } 
                <tr style={{breakBefore: 'page !important', breakInside: 'avoid', breakAfter: 'page !important', position:'relative'}}></tr>
      
           
          </tbody>
        </table>

        </>
    );
    
  }

    const renderBody = (employees) => {
        return employees && employees.map(({   id, distribuidor, asesor, cumplimiento, pago }, i) => {
            return (
               
              <tr style={{backgroundColor: i%2 === 0 ?  '#cad1d7': '#9daab6'  }} key={i}>  
                    <td  style={{width: '80px', height:'100%', background: 'white',  'opacity': '0.9'}}></td>
                    <td style={{ width : '70px',  fontSize: '12px' }}>{distribuidor}</td>
                    <td style={{ fontSize: '12px' }}> -{asesor}</td>
                </tr> 
            ) 
        })
    }

    const store = useSelector(state => state.resumen.store);
    const [cobseis, setSeis] = useState([]);
    const [cobnueve, setNueve] = useState([]);
    const [nuevos, setNuevos] = useState([]);

    useEffect(() => {
        setSeis(store.clinuevseisocho)
        setNueve(store.clinuevnueve)
        setNuevos(store.clinuevnueve)
    }, [store.clinuevnueve, store.clinuevseisocho], [nuevos, store.clientesnuevos])

    return (
      <>
      <div className="contacts">
         <style>
           {`@media print {.contacts{ page-break-before: always;}}`
           }
         </style>
         <ComponentToPrint />
       </div>
       </>
    )
  
  };
  
  export default PdfCobertura;