import React, {  useState } from 'react';
import {useSelector } from 'react-redux';

import { FieldSet } from '@sigeco/tools';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';


const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
     
        marginBottom: '0.75rem',
        '@media (min-width: 1401px)': {
            marginBottom: '1rem',
        }
    }, 
    tableWrap: {
        flex: '1 1 auto'
    },
    
    columna: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, 230px)',
        justifyContent: 'space-around',
        columnGap:"10px " ,
        paddingBottom: '0px',
        
    },
    img: {
        height: 'auto',
        width: 'auto',
        maxHeight: '100px',
        maxWidth: '100px',
    }    

}));

const ReportePromocionesImagenes = ({ onMapOpen }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [ax, setAux] = useState();
    const [ax1, setAux1] = useState();
    const data = useSelector(state => state.reportepromociones.store.data);
    const imagenes = []
    const a = data.map(dat => dat.UriFoto)
    const nombre = data.map(dat => dat.NombreComercial)
    const marca = data.map(dat => dat.Nombre)
    const tipo = data.map(dat => dat.Tipo)
    console.log(nombre)
    const aux = a.filter(b => b !== '')
    imagenes.push(aux)

    const handleClose = () => {
        setOpen(false);
    };
    
    const clicked = (x,y)=>{
        
        setAux(x);
        setOpen(true);
        console.log(x)
        setAux1(y)
    }

     return ( 
        
        <div className={classes.root}  style ={{width:"100%"}}>
        <FieldSet className={classes.root}   >
            
            <div className={classes.tableWrap}  style ={{width:"100%"}}>
                
                <div className={classes.columna} style ={{width:"100%"}}>

                    { imagenes.map((image, index)=>( image.map((i,x) =>
                        <div >    
                        <div id='employee'  style={{ display: 'inline-block', border: "7px solid rgba(224, 224, 224, 1)", borderRadius:'5px',backgroundColor:"rgba(224, 224, 224, 1)"}}>
                        
                          <img  alt = 'uno' onClick={()=>clicked(i,x)} key={x} src={i} 
                         style= {{ paddingLeft:"-1px", paddingRight:"100px" ,paddingBottom:"1px",maxHeight:"150px", height:"auto" ,width:"100%"}} />
                         <p style={{marginTop:"-1px",fontSize: "7px", color:"#828282",marginLeft:"1px"}} >CLIENTE:&nbsp;{ `${nombre[x].toUpperCase()}`}&nbsp;&nbsp; TIPO: &nbsp;{ `${tipo[x]}`}  </p>
                         </div>
                         <p></p>
                         </div> 
                        )
                    ))}
                         
                         {open  ?  
                         
                        <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
                           
                            <div>
                                 <p></p>
                                <p style={{marginTop:"-6px",fontSize: "max(1vw, 2px)"}}>&nbsp;&nbsp;&nbsp;&nbsp;<b>Cliente:</b> <span style={{color:"#828282"}}> &nbsp;{ `${nombre[ax1]}`}</span></p>
                                <p style={{marginTop:"-6px",fontSize: "max(1vw, 5px)"}}>&nbsp;&nbsp;&nbsp;&nbsp;<b>Tipo:</b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{color:"#828282"}}>{ `${tipo[ax1]}`}</span></p>
                                <p style={{marginTop:"-6px",fontSize: "max(1vw, 5px)"}}>&nbsp;&nbsp;&nbsp;&nbsp;<b>Marca: </b> &nbsp;<span style={{color:"#828282"}}>{ `${marca[ax1]}`}</span></p>

                            </div>
                            
                            <center style={{paddingRight:"80px", marginLeft:"80px"}}> 
                                                   
                                                   <img key={ax} alt="detail" src={ax} style= {{display: 'inline-block',border: "10px solid rgba(224, 224, 224, 1)", borderRadius:"5px",paddingLeft:"0px",paddingRight:"0px", paddingBottom:"1px", paddingTop:"-3px", width:"70%", height:"10%", maxHeight:"200px"}} /> 
                                                                               
                                               </center>

                            <DialogActions>
                                <div>
                                    <Button onClick={handleClose} style={{marginTop:"-10px",fontSize: "11px", justifyContent: "flex-end"}} variant="contained" color="primary" >
                                        Cerrar
                                    </Button>
                                </div>
                                
                                
                            </DialogActions>
                        </Dialog> : null}
                </div>
            </div>
        </FieldSet>        
       </div>
        
    )
};

export default ReportePromocionesImagenes;