import clsx from 'clsx';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import {
    setOpenMenu,
    setOpenSubmenu
} from '../../store/main/actions';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Icon from '@material-ui/core/Icon';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(theme => ({
    list: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        listStyle: 'none',
        marginBottom: 0,
        marginTop: 0,
        paddingLeft: 0
    },
    listActive: {
        position: 'relative',
        '&:after': {
            content: '" "',
            background: theme.palette.primary.main,
            borderRadius: '0.625rem',
            position: 'absolute',
            width: '0.375rem',
            height: '4.375rem',
            top: '50%',
            transform: 'translateY(-50%)',
            left: 0
        }
    },
    menuHidden: {
        transform: 'translateX(-120px)',
    },
    menuSubHidden: {
        borderRadius: '0 .75rem .75rem 0'
    },
    menu: {
        backgroundColor: '#fff',
        boxSizing: 'border-box',
        height: 'calc(100% - 3.125rem)',
        left: 0,
        paddingBottom: '.625rem',
        paddingTop: '.625rem',
        position: 'fixed',
        transition: 'transform .3s,-webkit-transform .3s',
        width: '5rem',
        zIndex: 3,
        '@media (min-width: 751px)': {
            height: 'calc(100% - 3.75rem)',
            width: '5.625rem'
        },
        '@media (min-width: 1201px)': {
            height: 'calc(100% - 4.375rem)',
            width: '6.25rem'
        },
        '@media (min-width: 1451px)': {
            height: 'calc(100% - 5rem)',
            width: '6.875rem'
        }
    },
    menuActive: {
        color: theme.palette.primary.main + '!important'
    },
    menuIcon: {
        color: 'inherit',
        fontSize: '1.5rem',
        height: '2.125rem',
        width: 'auto',
        '@media (min-width: 751px)': {
            fontSize: '1.75rem',
            height: '2.375rem',
        }
    },
    menuItem: {
        alignItems: 'center',
        borderBottom: '1px solid #f3f3f3',
        boxSizing: 'border-box',
        color: '#505050',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        fontSize: '0.625rem',
        fontStyle: 'normal',
        fontWeight: 400,
        height: '5rem',
        justifyContent: 'center',
        transition: 'background .3s',
        '&:hover' : {
            color: theme.palette.primary.main
        },
        '@media (min-width: 751px)': {
            fontSize: '0.6875rem',
            height: '5.625rem'
        }
    },
    root: {
        bottom: 0,
        height: 'calc(100% - 3.125rem)',
        paddingTop: '3.125rem',
        position: 'fixed',
        top: 0,
        zIndex: 4,
        transition: 'border-radius .3s',
        '@media (min-width: 751px)': {
            height: 'calc(100% - 3.75rem)',
            paddingTop: '3.75rem'
        },
        '@media (min-width: 1201px)': {
            height: 'calc(100% - 4.375rem)',
            paddingTop: '4.375rem'
        },
        '@media (min-width: 1451px)': {
            height: 'calc(100% - 5rem)',
            paddingTop: '5rem'
        }
    },
    scroll: {
        height: '100%',
        marginRight: 'unset',
        paddingRight: 'unset',
        overflow: 'auto'
    },
    sublist: {
        display: 'block',
        flexWrap: 'wrap',
        listStyle: 'none',
        marginBottom: 0,
        paddingLeft: 0
    },
    sublistActive: {
        position: 'relative',
        '&:after': {
            content: '" "',
            background: theme.palette.primary.main,
            borderRadius: '0.625rem',
            position: 'absolute',
            width: '0.25rem',
            height: '0.25rem',
            top: '50%',
            transform: 'translateY(-50%)',
            left: '-1rem'
        }
    },
    subHiddenMenu: {
        transform: 'translateX(-350px)'
    },
    subHidden: {
        transform: 'translateX(-230px)'
    },
    submenu: {
        backgroundColor: '#fff',
        borderLeft: '1px solid #f3f3f3',
        borderRadius: '0 .75rem .75rem 0',
        boxShadow: '0 3px 30px rgba(0,0,0,.1), 0 3px 20px rgba(0,0,0,.1)',
        boxSizing: 'border-box',
        height: 'calc(100% - 3.125rem)',
        left: '5rem',
        paddingBottom: '0.625rem',
        paddingRight: 0,
        paddingTop: '0.625rem',
        position: 'fixed',
        transition: 'transform .3s,-webkit-transform .3s',
        width: '14.375rem',
        zIndex: 2,
        '@media (min-width: 751px)': {
            height: 'calc(100% - 3.75rem)',
            left: '5.625rem'
        },
        '@media (min-width: 1201px)': {
            height: 'calc(100% - 4.375rem)',
            left: '6.25rem'
        },
        '@media (min-width: 1451px)': {
            height: 'calc(100% - 5rem)',
            left: '6.875rem'
        }
    },
    submenuIcon: {
        color: 'inherit',
        display: 'inline-block',
        fontSize: '1rem',
        marginRight: '0.625rem',
        verticalAlign: 'middle',
        width: 'auto'
    },
    submenuItem: {
        color: '#505050',
        cursor: 'pointer',
        display: 'block',
        fontSize: '0.8125rem',
        marginBottom: '0.625rem',
        padding: '0.5rem 0',
        position: 'relative',
        '&:hover' : {
            color: theme.palette.primary.main
        }
    }
}));

const SideBar = ({view}) => {
    const classes = useStyles();
    const matches = useMediaQuery('(min-width:751px)');
    const [selected, setSelected] = useState(view);
    const openMenu = useSelector(state => state.main.openMenu);
    const openSubmenu = useSelector(state => state.main.openSubmenu);
    const options = useSelector(state => state.main.options)
    const dispatch = useDispatch();

    const handleClickMenu = option => {
        setSelected(option.path);

        if(!option.leaf) {
            dispatch(setOpenSubmenu(true));
        }
        else {
            dispatch(setOpenSubmenu(false));
        }
    };

    const handleClickSubMenu = option => {
        setSelected(option.path);
        dispatch(setOpenSubmenu(false));
    };

    const handleClickAway = e => {
        setSelected(view);
        dispatch(setOpenSubmenu(false));
        
        if(!matches) {
            dispatch(setOpenMenu(false));
        }
    };

    return (
        <ClickAwayListener touchEvent={false} mouseEvent={openMenu ? 'onClick' : false} onClickAway={handleClickAway}>
            <div className={classes.root}>
                <div className={clsx(classes.menu, {[classes.menuHidden]: !openMenu ,[classes.menuSubHidden]: !openSubmenu})}>
                    <div className={classes.scroll}>
                        <ul className={classes.list}>
                            {options.map((option,key) => (
                                <li key={key} className={clsx({[classes.listActive]: selected.startsWith(option.path)})}>
                                    <LinkContainer to={option.leaf ? option.path : '#'}>
                                        <div className={clsx(classes.menuItem, {[classes.menuActive]: selected.startsWith(option.path)})} onClick={() => handleClickMenu(option)}>
                                            <Icon className={clsx(classes.menuIcon, option.iconCls)}/>
                                            <span>{option.name}</span>
                                        </div>
                                    </LinkContainer>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className={clsx(classes.submenu, {[classes.subHidden]: !openSubmenu && openMenu, [classes.subHiddenMenu]: !openMenu})}>
                    <div className={classes.scroll}>
                        <ul className={classes.sublist}>
                            {options.filter(f => selected === f.path).length > 0 && options.filter(f => selected === f.path)[0].children && options.filter(f => selected === f.path)[0].children.map((option, key) => (
                                <li key={key} style={{ marginLeft: 30 }} className={clsx({[classes.sublistActive]: option.path === selected})}>
                                    <LinkContainer to={option.leaf ? option.path : '#'}>
                                        <div className={clsx(classes.submenuItem, {[classes.menuActive]: option.path === selected})} onClick={() => handleClickSubMenu(option)}>
                                            <Icon className={clsx(classes.submenuIcon, option.iconCls)}/>
                                            <span style={{ display: 'inline-block', verticalAlign: 'middle' }}>{option.name}</span>
                                        </div>
                                    </LinkContainer>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </ClickAwayListener>
    );
};

export default SideBar;