import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  onSearchDistribuidorClick,
  onSearchVendedorClick,
  onUpdateFieldFilter, 
  onSearchSucursalClick,
  onSearchProspectoClick,
} from "../../../store/movilidad/planificarvisita/actions";

import { ActionField, FieldSet } from "@sigeco/tools";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { KeyboardDatePicker } from "@material-ui/pickers";
import FormControl from "@material-ui/core/FormControl";
import { Icon } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Fab from '@material-ui/core/Fab';

import {getDistribuidorDefault} from '../../../helpers/commons';

const useStyles = makeStyles(() => ({
  content: {
    padding: "0.5rem 0.75rem 0.75rem",
  },
  filterClear: {
    display: "flex",
  },
  actionField: {
    flexDirection: "row",
    position: "relative",
  },
  btnClear: {
    position: "absolute",
    bottom: "1.5",
    right: 20,
    maxWidth: "15px",
    maxHeight: "23px",
    minWidth: "15px",
    minHeight: "23px",
  },
  root: {
    flex: "0 0 auto",
    marginBottom: "0.75rem",
    "@media (min-width: 1401px)": {
      marginBottom: "1rem",
    },
  },
}));

const PlanificarVisitaFilter = () => {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:701px)");
  const filters = useSelector((state) => state.planificarvisita.filters);
  const formState = useSelector(state => state.planificarvisita.formState);
  const dispatch = useDispatch();

  useEffect(() =>{

    getDistribuidorDefault(onUpdateFieldFilter,dispatch);

  },[])

  //Botones de limpiar filtros
  const [, setDistribuidor] = useState("");
  const [btn, setBtn] = useState(true);
  const [ , setVendedor] = useState("");
  const [btn1, setBtn1] = useState(true);

    //========================================
    const [, setName] = useState("");
   // const [btn4, setBtn4] = useState(true);

  useEffect(() => {
    if (filters.dDistribuidor) {
      setDistribuidor(filters.dDistribuidor);
      //setVendedor('');
     // filters.dVendedor = "";
      setBtn(false);
    } else {
      setBtn(true);
    }
  }, [ filters.dDistribuidor]);

  useEffect(() => {
  
    if (filters.dVendedor) {
      //setVendedor(filters.dVendedor);
      setBtn1(false);
    } else {
      setBtn1(true);
    }
  }, [filters.dVendedor]);

  useEffect(() => {
    if (filters.nombre) {
      setName(filters.nombre);
     
    } else {
    
    }
  }, [filters.nombre]);

  const handleDist = () => {
    setDistribuidor("");
    setVendedor("");
    setName("");
    filters.distribuidorId = 0;
    filters.dDistribuidor = "";
    filters.vendedorId = '';
    filters.dVendedor = "";
    filters.nombre = "";
    setBtn(true);
    setBtn1(true);
  
  };

  const handleVendedor = () => {
    setVendedor("");
    setName("");
    filters.vendedorId = '';
    filters.dVendedor = "";
    filters.nombre = "";
    setBtn1(true); 
  };

   return (
    <div className={classes.root}>
      <FieldSet    iconCls="fas fa-filter"
        title="Filtros" 
        defaultExpanded
        expandable
      >
        <Grid className={classes.content} container spacing={2}>
          <Grid item xs={12} container>
            <Grid item xs={matches ? 4 : 12}>
              <FormControl className={classes.filterClear}>
                <ActionField
                  className={classes.actionField}
                  label="Agente"
                  placeholder="Enter para buscar Agente"
                  value={filters.dDistribuidor}
                  variant={matches ? "outlined" : "standard"}
                  onHandleAction={() => dispatch(onSearchDistribuidorClick())}
                />
                <Button
                  className={classes.btnClear}
                  size="small"
                  startIcon={<Icon className="fa fa-window-close" />}
                  disabled={btn}
                  onClick={handleDist}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid  item xs={12} container>
            <Grid item xs={matches ? 4 : 12}>
              <FormControl className={classes.filterClear}>
                <ActionField
                  label="Vendedor"
                  placeholder="Enter para buscar Vendedor"
                  value={filters.dVendedor}
                  variant={matches ? "outlined" : "standard"}
                  onHandleAction={() => dispatch(onSearchVendedorClick())}
                />
                <Button
                  className={classes.btnClear}
                  size="small"
                  startIcon={<Icon className="fa fa-window-close" />}
                  disabled={btn1}
                  onClick={handleVendedor}
                />
              </FormControl>
            </Grid>            
          </Grid>

          <Grid container item xs={12} spacing={1}>
            <Grid item >
              <KeyboardDatePicker
                autoOk
                disableToolbar
                format="dd/MM/yyyy"
                label="Fecha"
                value={filters.desde}
                inputVariant={matches ? "outlined" : "standard"}
                variant={matches ? "inline" : "dialog"}
                onChange={(date) =>
                  dispatch(onUpdateFieldFilter("desde", date))
                }
                //disableFuture
              />
            </Grid>   
            <Grid item >
                        {(formState !== 'search' ) && (<div>
                                
                              <Fab aria-label="agregar_clientes" color="primary" onClick={() => dispatch(onSearchSucursalClick())} size="small">
                              <Icon className="fas fa-plus" style={{ fontSize: '0.875rem'}}/>
                              
                          </Fab>&nbsp;
                          Agregar sucursales:
                      </div>)}
            </Grid>   
            <Grid item >
              {(formState !== 'search' ) && (<div>
                      
                    <Fab aria-label="agregar_clientes" color="primary" onClick={() => dispatch(onSearchProspectoClick())} size="small">
                    <Icon className="fas fa-plus" style={{ fontSize: '0.875rem'}}/>
                    
                </Fab>&nbsp;
                Agregar Prospectos:
            </div>)}
            
    
  </Grid>    
          </Grid>
       
        </Grid>
      </FieldSet>
    </div>
  );
};

export default PlanificarVisitaFilter;
