import axios from "axios"; //;
import format from "date-fns/format";
import { IdentityUrl } from "../../../config";
import { closeMessageBox, openMessageBox } from "../../main/actions";

import {
    CLEAN_FORM,
    SET_STATE_FORM,
    UPDATE_FIELD_FILTER,
    ERROR_V_LIST,
    RECEIVE_V_LIST,
    REQUEST_V_LIST,
    SET_PAGE,
    SET_SELECTED_ROW,
    SET_ORDER,
    SET_MASK,
    ADVANCED_SEARCH_CLOSE,
    ADVANCED_SEARCH_OPEN,
} from "./types";

import { exportToSpreadsheet, cortarArreglo } from "../../../helpers/commons";

export const setPage = (page) => ({ type: SET_PAGE, page });

export const cleanForm = () => ({ type: CLEAN_FORM });

//export const onUpdateFieldFilter = (key, value) => ({ type: UPDATE_FIELD_FILTER, key, value });

export const setSelectedRow = (selected) => ({
    type: SET_SELECTED_ROW,
    selected,
});

export const setFormState = (formState, id) => (dispatch, getState) => {
    switch (formState) {
        case "edit":
            if (id && id !== getState().metas.entity.vendedorId) {
                //dispatch(getUser(id));
            }
            break;
        case "new":
            dispatch(cleanForm());
            break;
        case "search":
            dispatch(cleanForm());
            break;
        case "view":
            if (id && id !== getState().metas.entity.vendedorId) {
                //dispatch(getUser(id));
            }
            break;
        default:
            break;
    }
    dispatch({ type: SET_STATE_FORM, formState });
};

export const btnSearch = () => (dispatch) => {
    dispatch(setPage(0));
    dispatch(onUserList());
};

export const onSearchDistribuidorClick = () => async (dispatch) => {
    let fields = [
        { dataKey: "razonSocial", label: "Agente", type: "string", width: 225 },
    ];

    dispatch(
        openAdvancedSearch({
            autoLoad: true,
            data: [],
            fields: fields,
            paginated: true,
            remote: true,
            title: "Selección de agente",
            getData: async (params) => {
                let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`;
                let response = await axios.get(encodeURI(url), {
                    params: params,
                });

                return response.data;
            },
            callback: (btn, result) => {
                dispatch(closeAdvancedSearch());
                if (btn === "yes") {
                    dispatch(
                        onUpdateFieldFilter(
                            "distribuidorId",
                            result[0].distribuidorId
                        )
                    );
                    dispatch(
                        onUpdateFieldFilter(
                            "dDistribuidor",
                            `${result[0].razonSocial}`
                        )
                    );
                    dispatch(onUpdateFieldFilter("supervisorId", ""));
                    dispatch(onUpdateFieldFilter("dSupervisor", ""));
                    dispatch(onUpdateFieldFilter("nombre", ""));
                }
            },
        })
    );
};

export const onExport = () => async (dispatch, getState) => {
    // no se usa otra forma de exportar
  
    if (getState().metas.filters.distribuidorId   === 0) {
      dispatch(openMessageBox({
          button: 'ok',
          icon: 'warning',
          message: "Debe seleccionar un Agente.",
          callback: () => dispatch(closeMessageBox())
      }));
      return;
    }
  
  
    if (getState().metas.store.data.length === 0) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "No hay información para exportar.",
          callback: () => dispatch(closeMessageBox()),
        })
      );
      return;
    }
  
  
    try {
      dispatch({ type: SET_MASK, loading: true });
      let head = getState().metas.table.columns;
      let json = getState().metas.store.data;
  
  
      exportToSpreadsheet(cortarArreglo(head,json), "Metas de Ventas");
    } catch (error) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "error",
          message:
            (error.response.data.eventLogId === 0
              ? ""
              : `EventoId: ${error.response.data.eventLogId}. `) +
            error.response.data.message,
          callback: () => dispatch(closeMessageBox()),
        })
      );
    } finally {
      setTimeout(()=>{dispatch({ type: SET_MASK, loading: false })}, 1000)
    }
  };

export const onUserList = () => async (dispatch, getState) => {
    if (getState().metas.store.loading) {
        return;
    }

    if (getState().metas.filters.distribuidorId === 0) {
        dispatch(
            openMessageBox({
                button: "ok",
                icon: "warning",
                message: "Debe seleccionar un Agente..",
                callback: () => dispatch(closeMessageBox()),
            })
        );
        return;
    }
    dispatch({ type: SET_MASK, loading: true });
    //console.log(getState().vendedor);
    //dispatch({ type: RECEIVE_OLAS_LIST, columns: getState().metas.table.columns, payload: [] });
    let source = axios.CancelToken.source();

    dispatch({ type: REQUEST_V_LIST, source });

    try {
        let url =
            `${
                getState().main.resources.uriSvcSalesMobilityAPI
            }api/v1/Vendedores/cuota/listar?` +
            `soloActivos=true` +
            `&desde=${format(getState().metas.filters.desde, "yyyy-MM-dd")}` +
            `&hasta=${format(getState().metas.filters.hasta, "yyyy-MM-dd")}` +
            `&nombre=${getState().metas.filters.nombre}`+
            `&distribuidorId=${getState().metas.filters.distribuidorId}`;

        let response = await axios.get(encodeURI(url));
        let data = response.data;
        //console.log(data.data.length);
        if (data.data.length === 0) {
            dispatch(
                openMessageBox({
                    button: "ok",
                    icon: "warning",
                    message: "No existe Registro para esta consulta",
                    callback: () => dispatch(closeMessageBox()),
                })
            );
            return;
        }

        dispatch({
            type: RECEIVE_V_LIST,
            total: data.total,
            payload: data.data,
        });
    } catch (error) {
        let msgError = "";
        dispatch({ type: ERROR_V_LIST });
        dispatch({ type: SET_MASK, loading: false });
        if (error.response) {
            msgError =
                error.response.data.eventLogId === 0
                    ? "No existe Registro  para esta fecha "
                    : `EventoId: ${error.response.data.eventLogId}. ` +
                      error.response.data.message;
        } else {
            msgError = "Error al conectar con el servidor";
        }

        dispatch(
            openMessageBox({
                button: "ok",
                icon: "error",
                message: msgError,
                callback: () => dispatch(closeMessageBox()),
            })
        );
    } finally {
        dispatch({ type: SET_MASK, loading: false });
    }
};

export const onUpdateFieldFilter = (key, value) => (dispatch, getState) => {
    //console.log(value);
    dispatch({ type: UPDATE_FIELD_FILTER, key, value });

    dispatch({ type: RECEIVE_V_LIST, total: [], payload: [] });
};
export const openAdvancedSearch = (opts) => ({
    type: ADVANCED_SEARCH_OPEN,
    payload: opts,
});

export const setOrder = (order, orderBy) => ({
    type: SET_ORDER,
    order,
    orderBy,
});

export const closeAdvancedSearch = () => ({ type: ADVANCED_SEARCH_CLOSE });

export const onUpdateColumn = (column) => (dispatch, getState) => {
    getState().metas.table.columns.map((item, i) => {
        if (item.dataKey !== column.dataKey) {
            return item;
        } else {
            item.width = column.width;
        }
        return {
            // ...item,
            //  width: column.width
        };
    });
};
