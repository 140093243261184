import axios from 'axios';
import { push } from 'connected-react-router';
import { IdentityUrl } from "../../../config";
import { closeMessageBox, openMessageBox } from '../../main/actions';
import {
    CHANGE_IMAGE,
    CLEAN_FORM,
    ERROR_MARCAS_LIST,
    RECEIVE_LIST,
    REMOVE_IMAGE,
    REQUEST_MARCAS_LIST,
    RESTART_STATE,
    SET_ENTITY,
    SET_ERROR,
    SET_FORM,
    SET_MASK,
    SET_STATE_FORM,
    UPDATE_FIELD_ENTITY,
    UPDATE_FIELD_FILTER,
    ADVANCED_SEARCH_OPEN,
    ADVANCED_SEARCH_CLOSE,
    ADD_MARCA
} from './types';

export const openAdvancedSearch = (opts) => ({
    type: ADVANCED_SEARCH_OPEN,
    payload: opts,
});

export const closeAdvancedSearch = () => ({ 
    type: ADVANCED_SEARCH_CLOSE 
});


export const btnDelete = () => (dispatch) => {
    dispatch(openMessageBox({
        button: 'yesno',
        icon: 'question',
        message: 'Se elimanara el registro permanentemente. ¿Desea continuar?',
        callback: btn => dispatch(onDelete(btn))
    }));
};

export const btnExport = () => async (dispatch, getState) => {
    try {
        if(getState().marca.store.data.length === 0) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'warning', 
                message: 'No hay información para exportar.', 
                callback: () => dispatch(closeMessageBox())
            }));
            return;
        }

        dispatch({ type: SET_MASK, loading: true });

        let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/marcas/exportar?`
            + `nombre=${getState().marca.filters.nombre}`;
        let response = await axios.get(encodeURI(url), { responseType: 'arraybuffer' });
        let csvHref = window.URL.createObjectURL(new Blob([response.data]));
        let link = document.createElement('a');

        link.href = csvHref;
        link.setAttribute('download', 'marcas.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'error', 
            message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
            callback: () => dispatch(closeMessageBox())
        }));
    } finally {
        dispatch({ type: SET_MASK, loading: false });
    }
};

export const btnSave = () => (dispatch, getState) => {
   
    //console.log("ID: ", getState().asignarmarcadistribuidor.entity.distribuidorId )
    for (let i = 0; i < getState().asignarmarcadistribuidor.arreglo.dataMarca.length; i++) {
        const item = getState().asignarmarcadistribuidor.arreglo.dataMarca[i];
        if (

            item.marca === '',
            item.marcaId === 0,
            item.imagen === '',
            item.orden === ''

        ) {
          dispatch(
            openMessageBox({
              button: "ok",
              icon: "error",
              message:
                "Debe completar todo los campos antes de enviar ",
              callback: () => dispatch(closeMessageBox())
            })
          );
          return; // Retorna aquí para detener la ejecución de la función
        }

    }
    
    dispatch(openMessageBox({
        button: 'yesno',
        icon: 'question',
        message: '¿Desea guardar la información?',
        callback: btn => {
            if(getState().asignarmarcadistribuidor.entity.distribuidorId != 0) {
                dispatch(onSave(btn));
            }
        
        }
    }));
};

export const btnSearch = () => async (dispatch, getState) => {

    if (getState().asignarmarcadistribuidor.store.loading) {
        return;
    }

    if(getState().asignarmarcadistribuidor.filters.distribuidorId === 0){
        dispatch(
            openMessageBox({
              button: "ok",
              icon: "warning",
              message: "Debe seleccionar un Agente..",
              callback: () => dispatch(closeMessageBox()),
            })
          );
          return;
    }

    try {
        let url = `${getState().main.resources.uriSvcSalesMobilityAPI}/api/v1/Tomas/channelmap/marcasdistribuidor?`
            + `distribuidorId=${getState().asignarmarcadistribuidor.filters.distribuidorId}`;
        let response = await axios.get(encodeURI(url));
        let data = response.data;

        
        // getState().asignarmarcadistribuidor.entity.distribuidorId = getState().asignarmarcadistribuidor.filters.distribuidorId;
        // getState().asignarmarcadistribuidor.entity.dDistribuidor = getState().asignarmarcadistribuidor.filters.dDistribuidor;


        if (data.length === 0) {
            dispatch(openMessageBox({
                button: 'ok',
                icon: 'warning',
                message: "No existe Registro  para esta consulta",
                callback: () => dispatch(closeMessageBox())
            }));
        return;
    }
        dispatch({ type: RECEIVE_LIST, payload: data });
        
    } catch (error) {
        dispatch({ type: ERROR_MARCAS_LIST });
        if (!axios.isCancel(error)) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        }
    }
}

export const cleanForm = () => ({ type: CLEAN_FORM });

export const getMarca = (marcaId) => async (dispatch, getState) => {
    try {
        dispatch({ type: SET_MASK, loading: true });

        let ax = getState().asignarmarcadistribuidor.store.aux;

        let payload = {
            ...ax
        };
        
        dispatch({ type: SET_ENTITY, payload });
        dispatch({ type: SET_FORM });
    } catch (error) {
        if(error.response.status === 404) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: 'No se encontro el registro solicitado.', 
                callback: () => { 
                    dispatch(closeMessageBox()); 
                    dispatch(push({
                        pathname: `/channelmap/marca`,
                        search: getState().router.location.search,
                        hash: getState().router.location.hash
                    }));
                }
            }));
        }
        else {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        }
    } finally {
        dispatch({ type: SET_MASK, loading: false });
    }
};

export const onDelete = (btn) => async (dispatch, getState) => {
    dispatch(closeMessageBox());

    if(btn === 'yes') {
        try {
            dispatch({ type: SET_MASK, loading: true });

            let marcaDistribuidorId = getState().asignarmarcadistribuidor.entity.marcaDistribuidorId;
            
            let recordset = {
                marcaDistribuidorId: marcaDistribuidorId
            };

            
            let url = `${getState().main.resources.uriSvcSalesMobilityAPI}/api/v1/Tomas/channelmap/desactivarmarcadistribuidor`;
            await axios.delete(url, {
                data: recordset,
                headers: { "Content-Type": "application/json" },
            });

            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'info', 
                message: 'El registro se elimino correctamente.', 
                callback: () => { 
                    dispatch(closeMessageBox());
                    getState().asignarmarcadistribuidor.filters.distribuidorId = getState().asignarmarcadistribuidor.filters.distribuidorId
                    getState().asignarmarcadistribuidor.filters.dDistribuidor =  getState().asignarmarcadistribuidor.filters.dDistribuidor
                    dispatch(btnSearch())
                    dispatch(push({
                        pathname: '/channelmap/asignarmarcadistribuidor',
                        search: getState().router.location.search,
                        hash: getState().router.location.hash
                    }));
                    dispatch(btnSearch());
                }
            }));
        } catch (error) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        } finally {
            dispatch({ type: SET_MASK, loading: false });
        }
    }
};

export const onSave = (btn) => async (dispatch, getState) => {
    dispatch(closeMessageBox());

    

    if(btn === 'yes') {
        try {
            dispatch({ type: SET_MASK, loading: true });

            let marcasX = getState().asignarmarcadistribuidor.arreglo.dataMarca;
            
            //console.log("DATAA1:", marcasX)

            //Para no enviar la misma marca repetida en caso lo seleccionen 2 veces
            let marcasIds = [];
            let marcasax = [];
            
            marcasX.forEach((item) => {
              if (!marcasIds.includes(item.marca)) {
                marcasIds.push(item.marca);
                marcasax.push(item);
              }
            });
            
            let registro = {
              distribuidorId: getState().asignarmarcadistribuidor.entity.distribuidorId,
              marcas: marcasax,
            };

           // console.log("DATAA2:", registro)

            //return 0;
           

            let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Tomas/asignarmarcas`;
            
            let response = await axios.post(url, registro, {headers: {'Content-Type': 'application/json'}});
            let data = response.data;

            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'info', 
                message: 'Registro realizado con exito.', 
                callback: () => { 
                    dispatch(closeMessageBox()); 
                    getState().asignarmarcadistribuidor.filters.distribuidorId = getState().asignarmarcadistribuidor.entity.distribuidorId;
                    getState().asignarmarcadistribuidor.filters.dDistribuidor = getState().asignarmarcadistribuidor.entity.dDistribuidor;
                    dispatch(btnSearch())
                    dispatch(push({
                        pathname: `/channelmap/asignarmarcadistribuidor`,
                        search: getState().router.location.search,
                        hash: getState().router.location.hash
                    }));
                }
            }));
        } catch (error) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        } finally {
            dispatch({ type: SET_MASK, loading: false });
        }
    }
};


export const onUpdateFieldEntity = (key, value) => (dispatch) => {
    dispatch({ type: UPDATE_FIELD_ENTITY, key, value });
};

export const onUpdateFieldFilter = (key, value) => ({ type: UPDATE_FIELD_FILTER, key, value });

export const removeImage = () => ({ type: REMOVE_IMAGE });

export const restartState = () => ({ type: RESTART_STATE });

export const setFormState = (formState, id) => (dispatch, getState) => {
    
    switch (formState) {
        case 'edit':
            if(id && parseInt(id) !== getState().marca.entity.marcaId) {
                dispatch(getMarca(id));
            }
            break;
        case 'new':
            dispatch(cleanForm());
            break;
        case 'search':
            dispatch(cleanForm());
            break;
        case 'view':
            if(id && id !== getState().marca.entity.marcaId) {
                dispatch(getMarca(id));
            }
            break;
        default:
            break;
    }
    dispatch({ type: SET_STATE_FORM, formState });
};

export const setImage = (file, image) => ({ type: CHANGE_IMAGE, file, image });

export const onSearchDistribuidorClick = () => async (dispatch) => {
    let fields = [
      { dataKey: "razonSocial", label: "Agente", type: "string", width: 225 },
    ];
  
    dispatch(
      openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: "Selección de agente",
        getData: async (params) => {
          let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`;
          let response = await axios.get(encodeURI(url), { params: params });
  
          return response.data;
        },
        callback: (btn, result) => {
          dispatch(closeAdvancedSearch());
          if (btn === "yes") {
            dispatch(
              onUpdateFieldFilter("distribuidorId", result[0].distribuidorId)
            );
            dispatch(
              onUpdateFieldFilter("dDistribuidor", `${result[0].razonSocial}`)
            );
           
          }
        },
      })
    );
};

export const onbtnDistribuidorClick = () => async (dispatch) => {
    let fields = [
      { dataKey: "razonSocial", label: "Agente", type: "string", width: 225 },
    ];
  
    dispatch(
      openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: "Selección de agente",
        getData: async (params) => {
          let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`;
          let response = await axios.get(encodeURI(url), { params: params });
  
          return response.data;
        },
        callback: (btn, result) => {
          dispatch(closeAdvancedSearch());
          if (btn === "yes") {
            dispatch(
                onUpdateFieldEntity("distribuidorId", result[0].distribuidorId)
            );
            dispatch(
                onUpdateFieldEntity("dDistribuidor", `${result[0].razonSocial}`)
            );
           
          }
        },
      })
    );
};

export const btnMarca = () => async (dispatch,getState) => {

    if(getState().asignarmarcadistribuidor.entity.distribuidorId!=0)
    {
      
    getState().asignarmarcadistribuidor.store.pantalla = true;   
        
    let fields = [
        { 'dataKey': 'nombre', 'label': 'Marca', 'type': 'string', search: true, 'width': 300 }
    ];

    try {
    
        dispatch(
            openAdvancedSearch({
              autoLoad: true,
              data: [],
              fields: fields,
              multiSelect: true,
              paginated: true,
              remote: true,
              title: "Agregue Marcas",
              getData: async (params) => {
                let input2 = JSON.stringify(params);
                let input = JSON.parse(input2);
                let url = `${getState().main.resources.uriSvcSalesMobilityAPI}/api/v1/Tomas/channelmap/marcasdisponibles?`
                + `distribuidorId=${getState().asignarmarcadistribuidor.entity.distribuidorId} `;
                let response1 = await axios.get(encodeURI(url), { params: params });
                //console.log(response1);
                let response = {
                  data: {
                    start: 0,
                    pageSize: 50,
                    total: response1.data.length,
                    data: response1.data,
                  },
                };
                        
                if (input.nombre) {
                  let final = response.data.data.filter((e) =>
                    e.nombre.toLowerCase().includes(input.nombre.toLowerCase())
                  );
                  response.data.data = final;
                  response.data.total = final.length;
                }
                return response.data;
                
              },
              callback: (btn, result) => {
               
                dispatch(closeAdvancedSearch());
                if (btn === "yes") {
                  dispatch({
                    type: ADD_MARCA,
                     payload: result.map((item) => {
                       return { 
                        marca: item.nombre, 
                        marcaId: item.marcaId,
                        imagen: item.imagen,
                      }; 
                    }),
                  });
                }
              },
            })
          );
        }
    finally {
        dispatch({ type: SET_MASK, loading: false });
      }

    }else {

        getState().asignarmarcadistribuidor.store.pantalla  = false; 

        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'error', 
            message: 'Primero seleccione un Distribuidor.', 
            callback: () => dispatch(closeMessageBox())
        }));

    }


    

}

