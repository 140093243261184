export const RECEIVE_DATA = 'channelmap/reporteseguimientovendedor/receive_data';
export const RECEIVE_ANT = 'channelmap/reporteseguimientovendedor/receive_ant';
export const RESTART_STATE = 'channelmap/reporteseguimientovendedor/restart_state';
export const SET_MASK = 'channelmap/reporteseguimientovendedor/set-mask';
export const UPDATE_FIELD_FILTER = 'channelmap/reporteseguimientovendedor/update-field-filter';
export const SET_ORDER = 'channelmap/reporteseguimientovendedor/set-order';
export const SET_SELECTED_ROW = 'channelmap/reporteseguimientovendedor/set-selected-row';
export const UPDATE_FIELD_DATA = 'channelmap/reporteseguimientovendedor/update-field-data';
export const ACTUALIZA_TIPO_CLIENTE = 'channelmap/reporteseguimientovendedor/actualiza-tipo-cliente';
export const UPDATE_OLA_FIELD_FILTER = 'channelmap/reporteseguimientovendedor/update-ola-field-filter';
export const SHOW_POPUP_CLIENT = 'movilidad/reporteseguimientovendedor/show-popup-client';
export const HIDE_POPUP_CLIENT = 'movilidad/reporteseguimientovendedor/hide-popup-client';
