import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import {
     setOrder,
     setSelectedRow,
     onUpdateColumn
} from '../../../store/fuerzaventas/vendedor/actions';
import { sort_by} from '../../../helpers/commons'

import { FieldSet, Table } from '@sigeco/tools';
import makeStyles from '@material-ui/core/styles/makeStyles';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column'
    }, 
    tableWrap: {
        flex: '1 1 auto'
    }
}));

const VendedorList = () => {
    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();
    const columns = useSelector(state => state.vendedor.table.columns);
    const loadingStore = useSelector(state => state.vendedor.store.loading);
    const order = useSelector(state => state.vendedor.table.order);
    const orderBy = useSelector(state => state.vendedor.table.orderBy);
    const store = useSelector(state => state.vendedor.store);
    const dispatch = useDispatch();

    const handleDoubleClick = (id) => {
        history.push({
            hash: location.hash,
            pathname: `/fuerzaventas/vendedor/${id}`,
            search: location.search
        });
    }

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        dispatch(setOrder(isAsc ? 'desc' : 'asc', property));
        let homes = store.data;
        homes.sort(sort_by(property, isAsc, homes[0][property]));
    };

    const handleResizeColumn = (column) => {
        dispatch(onUpdateColumn(column))
    };

    return (
        <FieldSet className={classes.root} iconCls="fas fa-th-list" title="Resultados">
            <div className={classes.tableWrap}>
                <Table
                    columns={columns}
                    count={store.total}
                    loading={loadingStore}
                    onRowClick={items => dispatch(setSelectedRow(items))}
                    onRowDoubleClick={item => handleDoubleClick(item.usuarioId)}
                    onSort={datakey => handleRequestSort(datakey)}
                    onUpdateColumn={handleResizeColumn}
                    //page={store.start/store.pageSize}
                    //paginate
                    rowCount={store.data.length}
                    rowGetter={index =>  store.data[index] }
                    rowsPerPage={store.pageSize}
                    selected={store.selected}
                    sortBy={orderBy}
                    sortDirection={order}
                />
            </div>
        </FieldSet>
    )
};

export default VendedorList;