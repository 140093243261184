import axios from 'axios';
import { push } from 'connected-react-router';
import { IdentityUrl } from '../../../config';
import { closeAdvancedSearch, closeMessageBox, openAdvancedSearch, openMessageBox } from '../../main/actions';
import {
    CLEAN_FORM,
    ERROR_OLAS_LIST,
    RECEIVE_OLAS_LIST,
    SET_STATE_FORM,
    RESTART_STATE,
    SET_ENTITY,
    SET_ERROR,
    UPDATE_FIELD_ENTITY,
    SET_MASK,
    SET_ORDER,
    SET_SELECTED_ROW,
    UPDATE_FIELD_FILTER,
} from './types';

import { exportToSpreadsheet  } from '../../../helpers/commons'

export const cleanForm = () => ({ type: CLEAN_FORM });

export const getUser = (productoId) => async (dispatch, getState) => {
    try {
        dispatch({ type: SET_MASK, loading: true });

        //let url = `${IdentityUrl}/api/v1/productos/${productoId}`;
        let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Tomas/producto/listarid?` 
        + `productoId=${productoId}`
       // + `productoId`
        
        let response = await axios.get(encodeURI(url));
   
        let payload = {
           ...response.data[0]
           // ...response.data    
            /*,
            aplicaciones: response.data.aplicaciones.map(item => { 
                return {
                    aplicacionClienteId: item.aplicacionClienteId, 
                    nombre: item.nombre
                }
            }),
            distribuidores: response.data.distribuidores.map(item => { 
                return {
                    distribuidorId: item.distribuidorId,
                    razonSocial: item.razonSocial
                }
            })
            */
        };
      
        
        dispatch({ type: SET_ENTITY,  payload });
    } catch (error) {
        if(error.response.status === 404) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: 'No se encontro el registro solicitado.', 
                callback: () => { 
                    dispatch(closeMessageBox()); 
                    dispatch(push({
                        pathname: `/precios/productos`,
                        search: getState().router.location.search,
                        hash: getState().router.location.hash
                    }));
                }
            }));
        }
        else {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        }
    } finally {
        dispatch({ type: SET_MASK, loading: false });
    }
};

export const onExport = () => async (dispatch, getState) => {
    try {
    
        if(getState().productos.store.data.length === 0) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'warning', 
                message: 'No hay información para exportar.', 
                callback: () => dispatch(closeMessageBox())
            }));
            return;
        }

        dispatch({ type: SET_MASK, loading: true });
        
        let head = getState().productos.table.columns;
        var aux = [];

        console.log(head)
        for (var c in head)
            {
              aux[0] = 'Canal'
              aux[1] = 'Linea';
              aux[2] =  'Uso';
              aux[3] =  'Categoria'; 
              aux[4] =  'Marca';
              aux[5] = 'Nombre';
              aux[6] =  'Viscosidad';
              aux[7] =  'Certificado';
              aux[8] =  'Activo'; 
            
            }

    var carArray = [];

    carArray.push(aux);

    let json = getState().productos.store.data;
    console.log(json)       
    // eslint-disable-next-line no-redeclare
    for(var c in json) {
        // eslint-disable-next-line no-new-object
        let y = []
        let x =   Object.values(json[c]);
        x.splice(0,1);
        x.splice(0,1);
        x.splice(1,1);
        x.splice(2,1);
        x.splice(3,1);
        x.splice(9,1);
        x.splice(9,1);
        y[0]= x[8];
        y[1]= x[0];
        y[2]= x[1];
        y[3]= x[2];
        y[4]= x[3];
        y[5]= x[4];
        y[6]= x[5];
        y[7]= x[6];
        y[8]= x[7];
       // console.log(x)
        carArray.push(y);
      }
   

       exportToSpreadsheet(carArray, 'productos') ;
   
    } catch (error) {
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'error', 
            message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
            callback: () => dispatch(closeMessageBox())
        }));
    } finally {
        setTimeout(() => {dispatch({ type: SET_MASK, loading: false })}, 1000);
    }
};

export const btnSearch = () => async (dispatch, getState) => {
    if (getState().productos.store.loading) {
        return;
    }

    dispatch({ type: SET_MASK, loading: true });
    //console.log(getState().tracking);
    //dispatch({ type: RECEIVE_OLAS_LIST, columns: getState().reportepedidos.table.columns, payload: [] });
    //dispatch({ type: REQUEST_OLAS_LIST });
   
    try {

    let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Tomas/producto/listar?` 
        + `nombre=${getState().productos.filters.nombre}`
        + `&Canal_Id=${getState().productos.filters.canalId}`
        + `&Linea_Id=${getState().productos.filters.lineaId}`
        + `&Marca_Id=${getState().productos.filters.marcaId}`;
 
    let response = await axios.get(encodeURI(url));
    let data = response.data;
    //console.log(data)

    let columns =   [
        { dataKey: 'dCanal', label: 'Canal', width: 50 },
        { dataKey: 'dLinea', label: 'Linea', width: 90 },
        { dataKey: 'dMarca', label: 'Marca', width: 200 },
        { dataKey: 'dUsoAceite', label: 'Uso', width: 105 },
        { dataKey: 'nombre', label: 'Nombre', width: 275 },
        { dataKey: 'dTipoAceite', label: 'Categoria', width: 275 },
        { dataKey: 'viscosidad', label: 'Viscosidad', width: 200 },  
        { dataKey: 'certificado', label: 'Certificado', width: 200 }, 
        { dataKey: 'activo', label: 'Activo', width: 60, type: 'boolean' }
    ]
        dispatch({ type: RECEIVE_OLAS_LIST,  columns: columns, payload: data });// payload: data });
        
    } catch (error) {
        let msgError = '';
        dispatch({ type: SET_MASK, loading: false });
        dispatch({ type: ERROR_OLAS_LIST });

        if (error.response) {
        
         if(error.response.status === 400) {
                msgError = (error.response.data.eventLogId === 0 ? 'No existe Registro  para esta fecha ' : `EventoId: ${error.response.data.eventLogId}. `+ error.response.data.message) 
         }else{
            msgError = (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message;
         }
        }
        else {
            msgError = 'Error al conectar con el servidor';
        }

        dispatch(openMessageBox({
            button: 'ok',
            icon: 'error',
            message: msgError,
            callback: () => dispatch(closeMessageBox())
        }));
    }finally{
        dispatch({ type: SET_MASK, loading: false });
    }
};
export const onUpdateColumn = (column) => (dispatch, getState) => {
    getState().productos.table.columns.map((item , i)=> {     
       if(item.dataKey !== column.dataKey) {
           return item;
       }else{
               item.width =  column.width;
       }
       return {
          // ...item,
         //  width: column.width
       };
      
   });
};

export const onSearchCanalClick = () => async (dispatch, getState) => {
    let fields = [
        { 'dataKey': 'canalId', 'label': 'canalId', 'type': 'string', 'search': true, 'width': 115 },
        { 'dataKey': 'nombre', 'label': 'Canal', 'type': 'string', 'width': 225 }
    ];

    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de Canal',
        getData: async (params) => {
 
            let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Clientes/canales` 
        
            let response = await axios.get(encodeURI(url), { params: params });
    
            const  aux = {
                data: response.data  ,
                pageSize: 50,
                start: 0,
                total: response.data.length
            }
            return aux;  //response.data;
        },
        callback: (btn, result) => {    
            dispatch(closeAdvancedSearch());
       
            if(btn === 'yes') {
                dispatch(onUpdateFieldEntity('canalId', result[0].canalId));
                dispatch(onUpdateFieldEntity('dCanal', `${result[0].nombre}`));
                dispatch(onUpdateFieldEntity('lineaId', 0));
                dispatch(onUpdateFieldEntity('dLinea', ''));
               // dispatch({ type: RECEIVE_OLAS_LIST, columns: getState().reportepedidos.table.columns, payload: [] });
            }
        }
    }));
};

export const btnSearchCanalClick = () => async (dispatch, getState) => {
    let fields = [
        { 'dataKey': 'canalId', 'label': 'canalId', 'type': 'string', 'search': true, 'width': 115 },
        { 'dataKey': 'nombre', 'label': 'Canal', 'type': 'string', 'width': 225 }
    ];

    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de Canal',
        getData: async (params) => {
 
            let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Clientes/canales` 
        
            let response = await axios.get(encodeURI(url), { params: params });
    
            const  aux = {
                data: response.data  ,
                pageSize: 50,
                start: 0,
                total: response.data.length
            }
            return aux;  //response.data;
        },
        callback: (btn, result) => {    
            dispatch(closeAdvancedSearch());
       
            if(btn === 'yes') {
                dispatch(onUpdateFieldFilter('canalId', result[0].canalId));
                dispatch(onUpdateFieldFilter('dCanal', `${result[0].nombre}`));
                dispatch(onUpdateFieldFilter('lineaId', 0));
                dispatch(onUpdateFieldFilter('dLinea', ''));
               // dispatch({ type: RECEIVE_OLAS_LIST, columns: getState().reportepedidos.table.columns, payload: [] });
            }
        }
    }));
};



export const onSearchLineaClick = () => async (dispatch, getState) => {
    let fields = [
        { 'dataKey': 'lineaId', 'label': 'Id', 'type': 'string', 'width': 115 },
        { 'dataKey': 'nombre', 'label': 'Nombre', 'Lob': 'string', 'width': 225 }
    ];

if(getState().productos.entity.canalId === 0 || getState().productos.entity.canalId === undefined  ) {
    dispatch(openMessageBox({
        button: 'ok',
        icon: 'warning',
        message: "Debe seleccionar un Canal..",
        callback: () => dispatch(closeMessageBox())
    }));
    return;
}
    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de Linea',
        getData: async (params) => {
   
            let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Tomas/producto/linea?` 
                + `canalId=${getState().productos.entity.canalId}`
            let response = await axios.get(encodeURI(url), { params: params });
    
            const  aux = {
                data: response.data  ,
                pageSize: 50,
                start: 0,
                total: response.data.length
            }
            return aux;  //response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldEntity('lineaId', result[0].lineaId));
                dispatch(onUpdateFieldEntity('dLinea', result[0].nombre));
            }
        }
    }));
};

export const btnSearchLineaClick = () => async (dispatch, getState) => {
    let fields = [
        { 'dataKey': 'lineaId', 'label': 'Id', 'type': 'string', 'width': 115 },
        { 'dataKey': 'nombre', 'label': 'Nombre', 'Lob': 'string', 'width': 225 }
    ];

if(getState().productos.filters.canalId === 0 || getState().productos.filters.canalId === undefined  ) {
    dispatch(openMessageBox({
        button: 'ok',
        icon: 'warning',
        message: "Debe seleccionar un Canal..",
        callback: () => dispatch(closeMessageBox())
    }));
    return;
}
    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de Linea',
        getData: async (params) => {
   
            let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Tomas/producto/linea?` 
                + `canalId=${getState().productos.filters.canalId}`
            let response = await axios.get(encodeURI(url), { params: params });
    
            const  aux = {
                data: response.data  ,
                pageSize: 50,
                start: 0,
                total: response.data.length
            }
            return aux;  //response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldFilter('lineaId', result[0].lineaId));
                dispatch(onUpdateFieldFilter('dLinea', result[0].nombre));
            }
        }
    }));
};

export const onSearchMarcaClick = () => async (dispatch, getState) => {
    let fields = [
        { 'dataKey': 'nombre', 'label': 'Nombre', 'type': 'string', 'search': true, 'width': 225 },
        { 'dataKey': 'marcaId', 'label': 'Marca Id', 'type': 'string', 'width': 115 }
    ];

    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de Marcas',
        getData: async (params) => {
            let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/marcas?` 
            let response = await axios.get(encodeURI(url), { params: params });

         const  aux = {
            data: response.data  ,
            pageSize: 50,
            start: 0,
            total: response.data.length
        }
        return aux;  //response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldEntity('marcaId', result[0].marcaId));
                dispatch(onUpdateFieldEntity('dMarca', `${result[0].nombre}`));
            }
        }
    }));
};

export const btnSearchMarcaClick = () => async (dispatch, getState) => {
    let fields = [
        { 'dataKey': 'nombre', 'label': 'Nombre', 'type': 'string', 'search': true, 'width': 225 },
        { 'dataKey': 'marcaId', 'label': 'Marca Id', 'type': 'string', 'width': 115 }
    ];

    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de Marcas',
        getData: async (params) => {
            let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/marcas?` 
            let response = await axios.get(encodeURI(url), { params: params });

         const  aux = {
            data: response.data  ,
            pageSize: 50,
            start: 0,
            total: response.data.length
        }
        return aux;  //response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldFilter('marcaId', result[0].marcaId));
                dispatch(onUpdateFieldFilter('dMarca', `${result[0].nombre}`));
            }
        }
    }));
};

export const onSearchCategoriaClick = () => async (dispatch, getState) => {
    let fields = [
        { 'dataKey': 'Nombre', 'label': 'Nombre', 'type': 'string', 'search': true, 'width': 225 },
        { 'dataKey': 'TipoAceiteId', 'label': ' Id', 'type': 'string', 'width': 115 }
    ];

    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de Categorias',
        getData: async (params) => {
            let input2 = JSON.stringify(params);
            let input = JSON.parse(input2);                                                     
            let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Tomas/producto/categorias?` 
            let response1 = await axios.get(encodeURI(url), { params: params });
        let response = {
          data: {
            start: 0,
            pageSize: 50,
            total: response1.data.length,
            data: response1.data,
          },
        };
        if (input.Nombre) {
            let final = response.data.data.filter((e) =>
              e.Nombre.toLowerCase().includes(input.Nombre.toLowerCase())
            );
            response.data.data = final;
            response.data.total = final.length;
          }
        return response.data;  //response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldEntity('tipoAceiteId', result[0].TipoAceiteId));
                dispatch(onUpdateFieldEntity('dTipoAceite', `${result[0].Nombre}`));
            }
        }
    }));
};
// no se utiliza
export const onSearchTipoAceiteClick = () => async (dispatch, getState) => {
    let fields = [
        { 'dataKey': 'TipoAceiteId', 'label': 'Id', 'type': 'string', 'search': true, 'width': 225 },
        { 'dataKey': 'Nombre', 'label': 'Nombre', 'type': 'string', 'width': 115 }
    ];

    let data = [
        { 'TipoAceiteId': 1, 'Nombre': 'Aceite Sintético' },
        { 'TipoAceiteId': 2, 'Nombre': 'Aceite Semisintético' },
        { 'TipoAceiteId': 3, 'Nombre': 'Aceite Mineral' },
        { 'TipoAceiteId': 4, 'Nombre': 'Refrigerante' }
    ]

    try {
        dispatch(openAdvancedSearch({
            autoLoad: true,
            data: data,
            fields: fields,
            paginated: false,
            remote: false,
            title: 'Selección de Tipo de Aceite',
            callback: (btn, result) => {
                dispatch(closeAdvancedSearch());
                if(btn === 'yes') {       
                    if(getState().productos.entity.dTipoAceite !== result[0].Nombre) {
                        dispatch(onUpdateFieldEntity('dTipoAceite', result[0].Nombre));
                        dispatch(onUpdateFieldEntity('tipoAceiteid', result[0].TipoAceiteid));
                    }
                }
            }
        }));
    } catch (error) {
        if(error.response) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        }
    }
};

export const onSearchUsoAceiteClick = () => async (dispatch, getState) => {
    let fields = [
        { 'dataKey': 'UsoAceiteId', 'label': 'Id', 'type': 'string', 'search': true, 'width': 225 },
        { 'dataKey': 'Nombre', 'label': 'Nombre', 'type': 'string', 'width': 115 }
    ];
/*
    let data = [
        { 'UsoAceiteId': 1, 'Nombre': 'Engranajes' },
        { 'UsoAceiteId': 2, 'Nombre': 'Motor' },
        { 'UsoAceiteId': 3, 'Nombre': 'Transmisiones y Diferenciales' },
        { 'UsoAceiteId': 4, 'Nombre': 'Refrigerante' },
        { 'UsoAceiteId': 5, 'Nombre': 'SIN Clasificar' }    
    ]
*/
        dispatch(openAdvancedSearch({
            autoLoad: true,
            data: [],
            fields: fields,
            paginated: true,
            remote: true,
            title: 'Selección de Uso de Aceite',
            getData: async (params) => {
                                                                        
                let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Tomas/producto/usoaceite?` 
                let response = await axios.get(encodeURI(url), { params: params });
                    console.log(response)
            const  aux = {
                data: response.data  ,
                pageSize: 50,
                start: 0,
                total: response.data.length
            }
            return aux;  //response.data;
            },
            callback: (btn, result) => {
                dispatch(closeAdvancedSearch());
                if(btn === 'yes') {
                    if(getState().productos.entity.dTipoAceite !== result[0].nombre) {
                        dispatch(onUpdateFieldEntity('dUsoAceite', result[0].Nombre));
                        dispatch(onUpdateFieldEntity('usoAceiteId', result[0].UsoAceiteId));
                    }
                }
            }
        }));
};
    

/*
export const openDialogClient = (data) => (dispatch, getState) => {
    try {
        var storeData = getState().reportevisitas.data.filter(f => f.dVendedor === data.dVendedor);
        
        dispatch({ type: SHOW_POPUP_CLIENT, storeData });
    } catch (error) {
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'error', 
            message: error, 
            callback: () => dispatch(closeMessageBox())
        }));
    }
};
*/

export const restartState = () => ({ type: RESTART_STATE });

export const setFormState = (formState, id) => (dispatch, getState) => {
    switch (formState) {
        case 'edit':
            if(id && id !== getState().productos.entity.productoId) {
                dispatch(getUser(id));
            }
            break;
        case 'new':
            dispatch(cleanForm());
           // dispatch(setPassword(generateRandomCode(8)));
            break;
        case 'restart':
            if(id && id !== getState().productos.entity.productoId) {
                dispatch(getUser(id));
            }
          //  dispatch(setPassword(generateRandomCode(8)));productoId
            break;
        case 'search':
            dispatch(cleanForm());
            break;
        case 'view':
            if(id && id !== getState().productos.entity.productoId) {
               dispatch(getUser(id));
            }
            break;
        default:
            break;
    }
    dispatch({ type: SET_STATE_FORM, formState });
}

export const btnDelete = () => (dispatch) => {
    dispatch(openMessageBox({
        button: 'yesno',
        icon: 'question',
        message: 'Se elimanara el registro permanentemente. ¿Desea continuar?',
        callback: btn => dispatch(onDelete(btn))
    }));
};

export const btnSave = () => (dispatch, getState) => {
    dispatch({ type: SET_ERROR, key: 'sw', error: '0' })
    
    if(getState().productos.entity.nombre === "") {
        dispatch({ type: SET_ERROR, key: 'nombre', error: 'Este campo es requerido' })
        dispatch({ type: SET_ERROR, key: 'sw', error: '1' })
    }

    if(getState().productos.entity.viscosidad === "") {
        dispatch({ type: SET_ERROR, key: 'viscosidad', error: 'Este campo es requerido' })
        dispatch({ type: SET_ERROR, key: 'sw', error: '1' })
    }
   
    if(getState().productos.entity.dCanal === "") {
        dispatch({ type: SET_ERROR, key: 'canalId', error: 'Este campo es requerido' })
        dispatch({ type: SET_ERROR, key: 'sw', error: '1' })
    }

    if(getState().productos.entity.dLinea === "") { 
        dispatch({ type: SET_ERROR, key: 'lineaId', error: 'Este campo es requerido' })
        dispatch({ type: SET_ERROR, key: 'sw', error: '1' })
    }

    if(getState().productos.entity.dMarca === "") {
        dispatch({ type: SET_ERROR, key: 'marcaId', error: 'Este campo es requerido' })
        dispatch({ type: SET_ERROR, key: 'sw', error: '1' })
    }
    
    if(getState().productos.entity.dTipoAceite === "") {
        dispatch({ type: SET_ERROR, key: 'tipoAceiteId', error: 'Este campo es requerido' })
        dispatch({ type: SET_ERROR, key: 'sw', error: '1' })
    }

    if(getState().productos.entity.dUsoAceite === "") {
        dispatch({ type: SET_ERROR, key: 'usoAceiteId', error: 'Este campo es requerido' })
        dispatch({ type: SET_ERROR, key: 'sw', error: '1' })
    }

    if(getState().productos.entity.rol === '') {
        dispatch({ type: SET_ERROR, key: 'rol', error: 'Este campo es requerido' })
    }

    if(getState().productos.errors.sw !== '0' ){
        return
    }
    
    dispatch(openMessageBox({
        button: 'yesno',
        icon: 'question',
        message: '¿Desea guardar la información?',
        callback: btn => {
            if(getState().productos.entity.productoId === 0) {
               dispatch(onSave(btn));
            }
            else {
                dispatch(onUpdate(btn));
            }
            return
        }
    }));
    
};
/*
export const btnSearch = () => (dispatch) => {
    dispatch(setPage(0));
    dispatch(onUserList());
};
*/
export const onDelete = (btn) => async (dispatch, getState) => {
    dispatch(closeMessageBox());

    if(btn === 'yes') {
        try {
            dispatch({ type: SET_MASK, loading: true });

            let usuarioId = getState().productos.entity.productoId;
            let url = `${IdentityUrl}/api/v1/usuarios/${usuarioId}`;
            await axios.delete(encodeURI(url));

            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'info', 
                message: 'El registro se elimino correctamente.', 
                callback: () => { 
                    dispatch(closeMessageBox());
                    dispatch(push({
                        pathname: '/usuarios',
                        search: getState().router.location.search,
                        hash: getState().router.location.hash
                    }));
                    dispatch(btnSearch())
                }
            }));
        } catch (error) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        } finally {
            dispatch({ type: SET_MASK, loading: false });
        }
    }
};

export const onSave = (btn) => async (dispatch, getState) => {

    dispatch(closeMessageBox());

    if(btn === 'yes') {
        try {
            dispatch({ type: SET_MASK, loading: true });

            let registro = {
                ...getState().productos.entity,            
            };

            let url = `${getState().main.resources.uriSvcSalesMobilityAPI}/api/v1/Tomas/crearproducto` 
       
            // eslint-disable-next-line no-unused-vars
            let response =   await axios.post(url, registro, {headers: {'Content-Type': 'application/json'}});
            //let data = response.data;

            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'info', 
                message: 'Registro realizado con exito.', 
                callback: () => { 
                    dispatch(closeMessageBox()); 
                    dispatch(push({
                        pathname: `/precios/productos`,
                        search: getState().router.location.search,
                        hash: getState().router.location.hash
                    }));
                }
            }));
        } catch (error) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        } finally {
            dispatch({ type: SET_MASK, loading: false });
        }
    }
};

export const onUpdate = (btn) => async (dispatch, getState) => {  
    dispatch(closeMessageBox());

    if(btn === 'yes') {
        try {
            dispatch({ type: SET_MASK, loading: true });

            let registro = {
                ...getState().productos.entity,
            };
            let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Tomas/actualizarproducto` 
            let response = await axios.put(url, registro, {headers: {'Content-Type': 'application/json'}});
            let data = response.data;

            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'info', 
                message: 'Modificación realizado con exito.', 
                callback: () => { 
                    dispatch(closeMessageBox());
                    dispatch(push({
                        pathname: `/precios/productos/${getState().productos.entity.productoId}`,
                        //precios/productos/:id
                        search: getState().router.location.search,
                        hash: getState().router.location.hash
                    }));
                    dispatch(getUser(data));
                }
            }));
        } catch (error) {
            if(error.response.status === 404) {
                dispatch(openMessageBox({ 
                    button: 'ok', 
                    icon: 'error', 
                    message: 'No se encontro el registro solicitado.', 
                    callback: () => { 
                        dispatch(closeMessageBox()); 
                        dispatch(push({
                            pathname: `/usuarios`,
                            search: getState().router.location.search,
                            hash: getState().router.location.hash
                        }));
                    }
                }));
            }
            else {
                dispatch(openMessageBox({ 
                    button: 'ok', 
                    icon: 'error', 
                    message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                    callback: () => dispatch(closeMessageBox())
                }));
            }
        } finally {
            dispatch({ type: SET_MASK, loading: false });
        }
    }
};

export const onUpdateFieldFilter = (key, value) => (dispatch, getState) => {
    dispatch({ type: UPDATE_FIELD_FILTER, key, value })
    dispatch({ type: RECEIVE_OLAS_LIST, columns:[], payload: [] })
};

export const onUpdateFieldEntity = (key, value) => (dispatch) => {
        dispatch({ type: UPDATE_FIELD_ENTITY, key, value });
    
        if(key === 'nombre' || key === 'viscosidad' || key === 'canalId' || key === 'lineaId' || key === 'tipoAceiteId'
        ||  key === 'usoAceiteId'  || key === 'marcaId') {
            dispatch({ type: SET_ERROR, key, error: '' });
        }
};

export const setOrder = (order, orderBy) => ({ type: SET_ORDER, order, orderBy });

export const setSelectedRow = (selected) => ({ type: SET_SELECTED_ROW, selected});
