import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  onSearchDistribuidorClick,
  onUpdateFieldFilter,
} from "../../../store/channelmap/desactivacion/actions";

import { ActionField, FieldSet } from "@sigeco/tools";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Icon } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import {getDistribuidorDefault} from '../../../helpers/commons';

const useStyles = makeStyles(() => ({
  content: {
    padding: "0.5rem 0.75rem 0.75rem",
  },
  filterClear: {
    display: "flex",
  },
  actionField: {
    flexDirection: "row",
    position: "relative",
  },
  btnClear: {
    position: "absolute",
    bottom: "1.5",
    right:20,
    maxWidth: "15px",
    maxHeight: "23px",
    minWidth: "15px",
    minHeight: "23px",
  },
  root: {
    flex: "0 0 auto",
    marginBottom: "0.75rem",
    "@media (min-width: 1401px)": {
      marginBottom: "1rem",
    },
  },
}));

const DesactivacionFilter = () => {
  const classes = useStyles();
  const big = useMediaQuery("(min-width:1201px)");
  const middle = useMediaQuery("(min-width:701px)");
  const filters = useSelector((state) => state.desactivacion.filters);
  const dispatch = useDispatch();

  useEffect(() =>{

    getDistribuidorDefault(onUpdateFieldFilter,dispatch);

  },[])

  //Botones de limpiar filtros
  const [, setDistribuidor] = useState("");
  const [btn, setBtn] = useState(true);
  const [, setName] = useState("");
  const [btn1, setBtn1] = useState(true);

  useEffect(() => {
    if (filters.dDistribuidor) {
        setDistribuidor(filters.dDistribuidor);
      setBtn(false);
    } else {
      setBtn(true);
    }
  }, [filters.dDistribuidor]);

  useEffect(() => {
    if (filters.dCliente) {
      setName(filters.dCliente);
      setBtn1(false);
    } else {setBtn1(true);}
  }, [filters.dCliente]);

  const handleDist = () => {
    setDistribuidor("");
    setName("");
    filters.distribuidorId = 0
    filters.dDistribuidor = ''
    filters.dCliente= ''
    setBtn(true);
    setBtn1(true);
  };

  const handleName = () => {setName(""); filters.dCliente= ''; setBtn1(true);};


  function onFieldChange(event) {
    dispatch(onUpdateFieldFilter(event.target.name, event.target.value));
  }

  function onChange(event) {
    dispatch(onUpdateFieldFilter("estado", event.target.value));
  }

  return (
    <div className={classes.root}>
      <FieldSet
        iconCls="fas fa-filter"
        title="Filtros"
        defaultExpanded
        expandable
      >
        <Grid className={classes.content} container spacing={1}>
          <Grid container item spacing={1} xs={12}>
            <Grid item xs={big ? 4 : middle ? 4 : 12}>
            <FormControl className={classes.filterClear}>
              <ActionField
                label="Agente"
                placeholder="Enter para buscar Agente"
                className={classes.actionField}
                value={filters.dDistribuidor}
                variant={middle ? "outlined" : "standard"}
                onHandleAction={() => dispatch(onSearchDistribuidorClick())}
              /> <Button
              className={classes.btnClear}
              size="small"
              startIcon={<Icon className="fa fa-window-close" />}
              disabled={btn}
              onClick={handleDist}
            />
          </FormControl>
            </Grid>
           
          </Grid>
          <Grid container item spacing={1} xs={12}>
            <Grid item >
              <KeyboardDatePicker
                autoOk
                disableToolbar
                //disableFuture
                format="dd/MM/yyyy"
                label="Desde"
                value={filters.desde}
                inputVariant={middle ? "outlined" : "standard"}
                variant={middle ? "inline" : "dialog"}
                // TextFieldComponent={(props) => (
                //   <TextField fullWidth {...props} />
                // )}
                onChange={(date) =>
                  dispatch(onUpdateFieldFilter("desde", date))
                }
              />
            </Grid>
            <Grid item /*xs={big ? 2 : middle ? 3 : 6}*/>
              <KeyboardDatePicker
                autoOk
                disableToolbar
                //disableFuture
                format="dd/MM/yyyy"
                label="Hasta"
                value={filters.hasta}
                inputVariant={middle ? "outlined" : "standard"}
                variant={middle ? "inline" : "dialog"}
                // TextFieldComponent={(props) => (
                //   <TextField fullWidth {...props} />
                // )}
                onChange={(date) =>
                  dispatch(onUpdateFieldFilter("hasta", date))
                }
              />
            </Grid>
          </Grid>
          <Grid container item spacing={1} xs={12}>
            <Grid item xs={big ? 6 : middle ? 6 : 12}>
            <FormControl className={classes.filterClear}>
              <TextField
                autoComplete="off"
                autoFocus
                fullWidth
                label="Cliente"
                name="dCliente"
                placeholder="Ingrese NIT o Razón Social"
                value={ filters.nombre}
                variant={middle ? "outlined" : "standard"}
                onChange={onFieldChange}
              />
               <Button
                style={{right:0}}
                className={classes.btnClear}
                size="small"
                startIcon={<Icon className="fa fa-window-close" />}
                disabled={btn1}
                onClick={handleName}
              />
            </FormControl>
            </Grid>
            <Grid item xs={middle ? 2 : 6}>
              <FormControl fullWidth variant={middle ? "outlined" : "standard"} style={{width:"59%"}}>
                <InputLabel id="lblEstado">Estado</InputLabel>
                <Select
                  labelId="lblEstado"
                  value={filters.estado}
                  onChange={onChange}
                >
                  <MenuItem value={"A"}>Aprobado</MenuItem>
                  <MenuItem value={"P"}>Pendiente</MenuItem>
                  <MenuItem value={"R"}>Rechazado</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          
        </Grid>
      </FieldSet>
    </div>
  );
};

export default DesactivacionFilter;
