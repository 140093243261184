export const CLEAN_FORM = 'fuerzaventas/gestionlistadeprecios/clean-form';
export const ADVANCED_SEARCH_CLOSE = 'main/advanced-search-close';
export const ADVANCED_SEARCH_OPEN = 'main/advanced-search-open';
export const SET_STATE_FORM = 'fuerzaventas/gestionlistadeprecios/set-state-form';
export const UPDATE_FIELD_FILTER = 'fuerzaventas/gestionlistadeprecios/update-field-filter';
export const RECEIVE_V_LIST = 'fuerzaventas/gestionlistadeprecios/receive-v-list';
export const RECEIVE_V_LIST2 = 'fuerzaventas/gestionlistadeprecios/receive-v-list2';
export const REQUEST_V_LIST = 'fuerzaventas/gestionlistadeprecios/request-v-list';
export const ERROR_V_LIST = 'fuerzaventas/gestionlistadeprecios/error-v-list';
export const SET_MASK = 'fuerzaventas/gestionlistadeprecios/set-mask';
export const SET_ORDER = 'fuerzaventas/gestionlistadeprecios/set-order';
export const RECEIVE_DATA= 'fuerzaventas/gestionlistadeprecios/receibe-data';
export const UPDATE_TABLE_COLUMN= 'fuerzaventas/gestionlistadeprecios/update-table-column';
export const SET_PAGE = 'fuerzaventas/gestionlistadeprecios/set-page';
export const SET_SELECTED_ROW = 'fuerzaventas/gestionlistadeprecios/set-selected-row';
export const CLEAN_DATA2 = 'fuerzaventas/gestionlistadeprecios/clean-data2';
export const SET_SELECTED_MULTI = 'fuerzaventas/gestionlistadeprecios/set-selected-multi';
export const SET_PAGE_SIZE = 'movilidad/gestionlistadeprecios/set-page-size';
export const CLEAR_SELECT = 'movilidad/gestionlistadeprecios/clear-select';
export const SET_ENTITY = 'movilidad/gestionlistadeprecios/set-entity';
export const UPDATE_FIELD_ENTITY = 'movilidad/planificarvisita/update-field-entity';
