import axios from 'axios';
import format from "date-fns/format";
import { IdentityUrl } from '../../../config';
import { closeAdvancedSearch, closeMessageBox, openAdvancedSearch, openMessageBox } from '../../main/actions';
import {
    ERROR_EFE_LIST,
    RECEIVE_EFE_LIST,
    RESTART_STATE,
    SET_MASK,
    SET_ORDER,
    SET_SELECTED_ROW,
    UPDATE_FIELD_FILTER,
} from './types';

import { exportToSpreadsheet } from '../../../helpers/commons'

export const btnExport = () => async (dispatch, getState) => {


    if(getState().reportefectividad.store.data.length === 0) {
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'warning', 
            message: 'No hay información para exportar.', 
            callback: () => dispatch(closeMessageBox())
        }));
        return;
    }
   
    try {
        dispatch({ type: SET_MASK, loading: true });

        let button = document.getElementById("table-xls-button-rpt-indicadores");

        button.click();
    } catch (error) {
        console.log(error)
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'error', 
            message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
            callback: () => dispatch(closeMessageBox())
        }));
    } finally {
        dispatch({ type: SET_MASK, loading: false });
    }
};

export const onExport = () => async (dispatch, getState) => { // no se usa otra forma de exportar
    
            //console.log(getState())
            if(getState().reportefectividad.store.data.length === 0) {
                dispatch(openMessageBox({ 
                    button: 'ok', 
                    icon: 'warning', 
                    message: 'No hay información para exportar.', 
                    callback: () => dispatch(closeMessageBox())
                }));
                return;
            }

            if (getState().reportefectividad.filters.distribuidorId   === 0) {
                dispatch(openMessageBox({
                    button: 'ok',
                    icon: 'warning',
                    message: "Debe seleccionar un Agente..",
                    callback: () => dispatch(closeMessageBox())
                }));
            return;
            } 

    try {

        dispatch({ type: SET_MASK, loading: true });
        let head = getState().reportefectividad.table.columns;
 
        // eslint-disable-next-line no-array-constructor
        var temp = new Array(); 
        for(var c in head) {
            let x =   Object.values(head[c])[0];
            temp.push(x);
          }    
          // eslint-disable-next-line no-array-constructor
          var carArray = new Array(); 
        
        carArray.push(temp);
       
        let json=   getState().reportefectividad.store.data;
    
    
        for(var i in json) {
            let x =   Object.values(json[i]);
            carArray.push(x);
          }
    
        exportToSpreadsheet(carArray,  'ReporteEfectividad') ;
    } catch (error) {
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'error', 
            message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
            callback: () => dispatch(closeMessageBox())
        }));
    } finally {
        dispatch({ type: SET_MASK, loading: false });
    }
};

export const btnSearch = () => async (dispatch, getState) => {

    if (getState().reportefectividad.store.loading) {
        return;
    }
  
    if (getState().reportefectividad.filters.distribuidorId   === 0) {
        dispatch(openMessageBox({
            button: 'ok',
            icon: 'warning',
            message: "Debe seleccionar un Agente..",
            callback: () => dispatch(closeMessageBox())
        }));
    return;
    }   

dispatch({ type: SET_MASK, loading: true });
    //dispatch({ type: RECEIVE_OLAS_LIST, columns: getState().reportepedidos.table.columns, payload: [] });
    //dispatch({ type: REQUEST_OLAS_LIST });

    try {

        let url = `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/reportes/channelmap/efectividad?`
        + `distribuidorId=${getState().reportefectividad.filters.distribuidorId}`
        //+ `&olaChannelMapId=${getState().reportefectividad.filters.olaId}`
        //+ `&usuarioId=${getState().reportefectividad.filters.usuarioId}`
        + `&desde=${format(getState().reportefectividad.filters.desde, 'yyyy-MM-dd')}`
        + `&hasta=${format(getState().reportefectividad.filters.hasta, 'yyyy-MM-dd')}`;
    
    let response = await axios.get(encodeURI(url));
    let data = response.data;

    if(data.data.length > 0)
    {
        data.columns[1]['width'] = 150;
    }

        
        dispatch({ type: RECEIVE_EFE_LIST,  columns: data.columns, payload: data.data });
        
    } catch (error) {
        let msgError = '';
        dispatch({ type: SET_MASK, loading: false });
        dispatch({ type: ERROR_EFE_LIST });

        if (error.response) {
        
         if(error.response.status === 400) {
                msgError = (error.response.data.eventLogId === 0 ? 'No existe Registro  para esta fecha ' : `EventoId: ${error.response.data.eventLogId}. `+ error.response.data.message) 
         }else{
            msgError = (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message;
         }
        }
        else {
            msgError = 'Error al conectar con el servidor';
        }

        dispatch(openMessageBox({
            button: 'ok',
            icon: 'error',
            message: msgError,
            callback: () => dispatch(closeMessageBox())
        }));
    }finally{
        dispatch({ type: SET_MASK, loading: false });
    }
};
export const onUpdateColumn = (column) => (dispatch, getState) => {
    getState().reportefectividad.table.columns.map(item => {
       if(item.dataKey !== column.dataKey) {
           return item;
       }
       else {
           return item.width =  column.width;
       }
       /*return {
           ...item,
           width: column.width
       };*/
   });
    //dispatch({ type: UPDATE_TABLE_COLUMN, columns });
};

export const onSearchOlaChannelMapClick = () => async (dispatch) => {
    let fields = [
        { 'dataKey': 'dOla', 'label': 'Ola', 'type': 'string', 'width': 250 },
        { 'dataKey': 'desde', 'label': 'Dede', 'type': 'string', 'width': 110 },
        { 'dataKey': 'hasta', 'label': 'Hasta', 'type': 'string', 'width': 110 }
    ];

    let data = [
        { 'olaId': 1, 'dOla': '2020', 'desde': '01/01/2020', 'hasta': '31/12/2020' },
        { 'olaId': 2, 'dOla': '1Q 2021', 'desde': '01/01/2021', 'hasta': '30/06/2021' },
        { 'olaId': 4, 'dOla': '1Q 2022', 'desde': '01/01/2022', 'hasta': '31/03/2022' }
    ]

    try {
        dispatch(openAdvancedSearch({
            autoLoad: true,
            data: data,
            fields: fields,
            paginated: false,
            remote: false,
            title: 'Selección de Ola Channel Map',
            callback: (btn, result) => {
                dispatch(closeAdvancedSearch());
                if(btn === 'yes') {
                    dispatch(onUpdateFieldFilter('olaId', result[0].olaId));
                    dispatch(onUpdateFieldFilter('dOla', result[0].dOla));
                }
            }
        }));
    } catch (error) {
        if(error.response) {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        }
    }
};

export const onSearchUsuarioClick = () => async (dispatch) => {
    let fields = [
        { 'dataKey': 'nombreUsuario', 'label': 'Usuario', 'type': 'string', 'width': 110 },
        { 'dataKey': 'nombre', 'label': 'Nombres', 'search': true, 'type': 'string', 'width': 175 },
        { 'dataKey': 'correo', 'label': 'Correo', 'type': 'string', 'width': 120 },
    ];

    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de Vendedor',
        getData: async (params) => {
            let url = `${IdentityUrl}/api/v1/usuarios/porsupervisor?`
            let response = await axios.get(encodeURI(url), { params: params });

            return response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldFilter('usuarioId', result[0].usuarioId));
                dispatch(onUpdateFieldFilter('dUsuario', `${result[0].nombreUsuario} - ${result[0].nombre}`));
            }
        }
    }));
};

export const onSearchDistribuidorClick = () => async (dispatch) => {
    let fields = [
        { 'dataKey': 'razonSocial', 'label': 'Agente', 'type': 'string', 'width': 225 }
    ];

    dispatch(openAdvancedSearch({
        autoLoad: true,
        data: [],
        fields: fields,
        paginated: true,
        remote: true,
        title: 'Selección de agente',
        getData: async (params) => {
            let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`
            let response = await axios.get(encodeURI(url), { params: params });

            return response.data;
        },
        callback: (btn, result) => {
            dispatch(closeAdvancedSearch());
            if(btn === 'yes') {
                dispatch(onUpdateFieldFilter('distribuidorId', result[0].distribuidorId));
                dispatch(onUpdateFieldFilter('dDistribuidor', `${result[0].razonSocial}`));
                dispatch(onUpdateFieldFilter('supervisorId', ''));
                dispatch(onUpdateFieldFilter('dSupervisor', ''));
                dispatch(onUpdateFieldFilter('vendedorId', ''));
                dispatch(onUpdateFieldFilter('dVendedor', ''));
            }
        }
    }));
};

/*
export const openDialogClient = (data) => (dispatch, getState) => {
    try {
        var storeData = getState().reportevisitas.data.filter(f => f.dVendedor === data.dVendedor);
        
        dispatch({ type: SHOW_POPUP_CLIENT, storeData });
    } catch (error) {
        dispatch(openMessageBox({ 
            button: 'ok', 
            icon: 'error', 
            message: error, 
            callback: () => dispatch(closeMessageBox())
        }));
    }
};
*/
/*
export const onUpdateFieldFilter = (key, value) => (dispatch, getState) => {
    //({ type: UPDATE_FIELD_FILTER, key, value });
    dispatch({ type: UPDATE_FIELD_FILTER, key, value })
    dispatch({ type: RECEIVE_EFE_LIST, columns:[], payload: [] })
    };
*/
//export const onUpdateFieldFilter = (key, value) => ({ type: UPDATE_FIELD_FILTER, key, value });

export const onUpdateFieldFilter = (key, value) => (dispatch, getState) =>{

    dispatch({ type: UPDATE_FIELD_FILTER, key, value });


    dispatch({ type:  RECEIVE_EFE_LIST,  columns: [], payload: [] });

} 

export const restartState = () => ({ type: RESTART_STATE });

export const setOrder = (order, orderBy) => ({ type: SET_ORDER, order, orderBy });

export const setSelectedRow = (selected) => ({ type: SET_SELECTED_ROW, selected});

