import axios from 'axios';
import { IdentityUrl } from '../../config';
import { closeMessageBox, openMessageBox } from '../main/actions';
import { setAvatar } from '../session/actions';
import {
    CANCEL_TEXT_PASSWORD,
    CHANGE_IMAGE,
    REMOVE_IMAGE,
    SET_ENTITY,
    SET_ERROR_FORM,
    SET_ERROR_PASSWORD,
    SET_FORM,
    SET_MASK,
    SET_NOT_FOUND,
    SHOW_PASSWORD,
    UPDATE_FIELD_FORM,
    UPDATE_FIELD_PASSWORD
} from './types';

export const btnChangePassword = () => (dispatch, getState) => {
    if(getState().profile.passwords.current === '') {
        dispatch({ type: SET_ERROR_PASSWORD, key: 'error', error: 'Este campo es requerido' })
    }

    if(getState().profile.passwords.new === '') {
        dispatch({ type: SET_ERROR_PASSWORD, key: 'errorNew', error: 'Este campo es requerido' })
    }

    if(getState().profile.passwords.verify === '') {
        dispatch({ type: SET_ERROR_PASSWORD, key: 'errorVerify', error: 'Este campo es requerido' })
    }

    if(getState().profile.passwords.error !== '' || getState().profile.passwords.errorNew !== '' || getState().profile.passwords.errorVerify !== '') {
        return;
    }

    dispatch(openMessageBox({
        button: 'yesno',
        icon: 'question',
        message: '¿Esta seguro de actualizar tu contraseña?',
        callback: btn => {
            dispatch(onChangePassword(btn));
        }
    }));
};

export const btnSave = () => (dispatch, getState) => {
    if(getState().profile.form.nombre === '') {
        dispatch({ type: SET_ERROR_FORM, key: 'nombre', error: 'Este campo es requerido' })
    }

    if(getState().profile.form.correo === '') {
        dispatch({ type: SET_ERROR_FORM, key: 'correo', error: 'Este campo es requerido' })
    }

    if(getState().profile.errors.nombre !== '' || getState().profile.errors.correo !== '') {
        return;
    }

    dispatch(openMessageBox({
        button: 'yesno',
        icon: 'question',
        message: '¿Desea guardar la información?',
        callback: btn => {
            dispatch(onUpdate(btn));
        }
    }));
};

export const cancelForm = () => ({ type: SET_FORM });

export const getProfile = (usuarioId) => async (dispatch) => {
    try {
        dispatch({ type: SET_MASK, loading: true });
        dispatch({ type: SET_NOT_FOUND, notFound: false });

        let url = (usuarioId || usuarioId === '' ? `${IdentityUrl}/api/v1/usuarios/${usuarioId}/perfil` : `${IdentityUrl}/api/v1/usuarios/perfil`);
        let response = await axios.get(encodeURI(url));
        let data = response.data;
        
        data.imagenUrl = data.imagen ? `data:image/jpeg;base64,${data.imagen}` : '';

        dispatch(setAvatar(data.imagen));
        dispatch({ type: SET_ENTITY, payload: data });
        dispatch({ type: SET_FORM });
    } catch (error) {
        if(error.response.status === 404) {
            dispatch({ type: SET_NOT_FOUND, notFound: true });
        }
        else {
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'error', 
                message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                callback: () => dispatch(closeMessageBox())
            }));
        }
    } finally {
        dispatch({ type: SET_MASK, loading: false });
    }
};

export const onChangePassword = (btn) => async (dispatch, getState) => {
    dispatch(closeMessageBox());

    if(btn === 'yes') {
        try {
            dispatch({ type: SET_MASK, loading: true });

            let formData = {
                username: getState().profile.entity.nombreUsuario,
                password: getState().profile.passwords.current, 
                newPassword: getState().profile.passwords.new, 
                confirmPassword: getState().profile.passwords.verify
            };
     
            const encodeForm = (data) => {
                return Object.keys(data)
                    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
                    .join('&');
            }
    
            await axios.post(`${IdentityUrl}/changepassword`, encodeForm(formData), {headers: {'Accept': 'application/json'}});
            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'info', 
                message: 'Se cambio la contraseña con exito.', 
                callback: () => {
                    dispatch(closeMessageBox());
                    dispatch(resetTextPassword());
                }
            }));
            
        } catch (error) {
            if(error.response) {
                dispatch(openMessageBox({ 
                    button: 'ok', 
                    icon: 'error', 
                    message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                    callback: () => dispatch(closeMessageBox())
                }));
            }
            
        } finally {
            dispatch({ type: SET_MASK, loading: false });
        }
    }
};

export const onShowPassword = (key) => ({ type: SHOW_PASSWORD, key});

export const onUpdate = (btn) => async (dispatch, getState) => {
    dispatch(closeMessageBox());

    if(btn === 'yes') {
        try {
            dispatch({ type: SET_MASK, loading: true });

            let formData = new FormData();

            formData.append("file", getState().profile.form.archivo);
            formData.append("usuarioId", getState().profile.entity.usuarioId);
            formData.append("correo", getState().profile.form.correo);
            formData.append("nombre", getState().profile.form.nombre);
            formData.append("telefono", getState().profile.form.telefono);
            formData.append("documentoIdentidad", getState().profile.form.documentoIdentidad);

            if(getState().profile.entity.imagen !== getState().profile.form.imagen && getState().profile.form.archivo === ''){
                formData.append("quitarImagen", true);
            }

            let url = `${IdentityUrl}/api/v1/usuarios/perfil`;
            let response = await axios.put(url, formData, {headers: {'Content-Type': 'multipart/form-data'}});
            let data = response.data;

            dispatch(openMessageBox({ 
                button: 'ok', 
                icon: 'info', 
                message: 'Actualización realizada con exito.', 
                callback: () => { 
                    dispatch(closeMessageBox());
                    dispatch(getProfile(data));
                }
            }));
        } catch (error) {
            if(error.response.status === 404) {
                dispatch({ type: SET_NOT_FOUND, notFound: true });
            }
            else {
                dispatch(openMessageBox({ 
                    button: 'ok', 
                    icon: 'error', 
                    message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
                    callback: () => dispatch(closeMessageBox())
                }));
            }
        } finally {
            dispatch({ type: SET_MASK, loading: false });
        }
    }
};

export const onUpdateFieldForm = (key, value) => (dispatch) => {
    dispatch({ type: UPDATE_FIELD_FORM, key, value });

    if(key === 'nombre' || key === 'correo') {
        dispatch({ type: SET_ERROR_FORM, key, error: '' });
    }
};

export const onUpdateFieldPassword = (key, value) => (dispatch) => {
    dispatch({ type: UPDATE_FIELD_PASSWORD, key, value });

    if(key === 'current') {
        dispatch({ type: SET_ERROR_PASSWORD , key: 'error', error: '' });
    }
    if(key === 'new') {
        dispatch({ type: SET_ERROR_PASSWORD , key: 'errorNew', error: '' });
    }
    if(key === 'verify') {
        dispatch({ type: SET_ERROR_PASSWORD , key: 'errorVerify', error: '' });
    }
};

export const removeImage = () => ({ type: REMOVE_IMAGE });

export const resetTextPassword = () => ({ type: CANCEL_TEXT_PASSWORD });

export const setImage = (file, image) => ({ type: CHANGE_IMAGE, file, image });