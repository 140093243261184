import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    btnSearch,
    btnExport,
    restartState
} from '../../store/precios/basedatos/actions';

import { Body, Footer, Page } from '@sigeco/tools';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';

import BaseDatosFilter from '../../components/precios/basedatos/BaseDatosFilter';
import BaseDatosList from '../../components/precios/basedatos/BaseDatosList';

const BaseD = ({onClose, setTitle}) => {
    const loading = useSelector(state => state.basedatos.loading);
    const dispatch = useDispatch();

    useEffect(() => {
        setTitle('Descargar');
    // eslint-disable-next-line
    }, []);

    function onBtnClose() {
        dispatch(restartState());
        onClose();
    }

    return (
        <Page>
            <Backdrop style={{ position: 'absolute', zIndex: 1200 }} open={loading} transitionDuration={0}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <Body>
            <BaseDatosFilter/>
            <BaseDatosList/>
        </Body>
        <Footer>
            <Button
                color="primary"
                size="small"
                variant="outlined"
                onClick={() => dispatch(btnExport())}
                startIcon={<Icon className="fas fa-file-excel"/>}
            >
                Exportar
            </Button> 
            
            <Button
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={() => dispatch(btnSearch())}
                    startIcon={<Icon className="fas fa-search"/>}
                >
                    Buscar
            </Button>

            <Button 
                color="primary" 
                size="small" 
                variant="outlined" 
                onClick={() => onBtnClose()} 
                startIcon={<Icon className="fas fa-door-open"/>}
            >
                Salir
            </Button>
        </Footer>
        </Page>
    )
};

export default BaseD;