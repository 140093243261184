import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  btnSearchCanalClick, 
  btnSearchLineaClick,
  btnSearchMarcaClick,
  onUpdateFieldFilter, //from '../../../store/fuerzaventas/vendedor/actions';
} from "../../../store/precios/productos/actions";

import { ActionField,FieldSet } from "@sigeco/tools";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FormControl from "@material-ui/core/FormControl";
import { Icon } from "@material-ui/core";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(() => ({
  content: {
    padding: "0.5rem 0.75rem 0.75rem",
  },
  filterClear: {
    display: "flex",
  },
  actionField: {
    flexDirection: "row",
    position: "relative",
  },
  btnClear: {
    position: "absolute",
    bottom: "1.5",
    right: 20,
    maxWidth: "15px",
    maxHeight: "23px",
    minWidth: "15px",
    minHeight: "23px",
  },
  root: {
    flex: "0 0 auto",
    marginBottom: "0.75rem",
    "@media (min-width: 1401px)": {
      marginBottom: "1rem",
    },
  },
}));

const VendedorFilter = () => {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:701px)");
  const filters = useSelector((state) => state.productos.filters);
  const dispatch = useDispatch();

  const [, setCanal] = useState("");
  const [btn1, setBtn1] = useState(true);
  const [, setLinea] = useState("");
  const [btn2, setBtn2] = useState(true);
  const [, setMarca] = useState("");
  const [btn3, setBtn3] = useState(true);
  const [, setNombre] = useState("");
  const [btn, setBtn] = useState(true);

  function onFieldChange(event) {
    dispatch(onUpdateFieldFilter(event.target.name, event.target.value));
  }

  useEffect(() => {
    if (filters.dCanal) {
      setCanal(filters.dCanal);
      setBtn1(false);
    } else {
      setBtn1(true);
    }
  }, [filters.dCanal]);

  useEffect(() => {
    if (filters.dLinea) {
      setLinea(filters.dLinea);
      setBtn2(false);
    } else {
      setBtn2(true);
    }
  }, [filters.dLinea]);

  useEffect(() => {
    if (filters.dMarca) {
      setMarca(filters.dMarca);
      setBtn3(false);
    } else {
      setBtn3(true);
    }
  }, [filters.dMarca]);

  useEffect(() => {
    if (filters.nombre) {
      setNombre(filters.nombre);
      setBtn(false);
    } else {
      setBtn(true);
    }
  }, [filters.nombre]);

  const handleCanal = () => {
    setCanal("");
    setLinea("");
    filters.dCanal = "";
    filters.canalId = 0;
    filters.dLinea = "";
    filters.lineaId = 0;
    setBtn1(true);
    setBtn2(true);
  };
  
  const handleLinea = () => {
    setLinea("");
    filters.dLinea = "";
    filters.lineaId = 0;
    setBtn2(true);
  };

  const handleMarca = () => {
    setMarca("");
    filters.dMarca = "";
    filters.marcaId = 0;
    setBtn3(true);
  };
  
  
  const handleNombre = () => {
    setNombre("");
    filters.nombre = "";
    setBtn(true);
  };

  return (
    <div className={classes.root}>
      <FieldSet iconCls="fas fa-filter" title="Filtros" defaultExpanded>
        <Grid className={classes.content} container spacing={2}>


          <Grid item xs={12} container>
                            <Grid item  xs={matches ? 4 : 12}>
                            <FormControl className={classes.filterClear}>
                                <ActionField
                                    className={classes.actionField}
                                    label="Canal"
                                    placeholder="Enter para buscar Canal"
                                    value={filters.dCanal}
                                    variant={matches ? "outlined" : "standard"}
                                    onHandleAction={() => dispatch( btnSearchCanalClick ())}
                                />
                              <Button
                                  style={{ right: 20 }} 
                                  className={classes.btnClear}
                                  size="small"
                                  startIcon={<Icon className="fa fa-window-close" />}
                                  disabled={btn1}
                                  onClick={handleCanal}
                                />
                              </FormControl>
                            </Grid>
          </Grid>

          <Grid item xs={12}  container>
                            <Grid item  xs={matches ? 4 : 12}>
                            <FormControl className={classes.filterClear}>
                                <ActionField
                                    className={classes.actionField}
                                    label="Línea"
                                    placeholder="Enter para buscar Línea"
                                    value={filters.dLinea}
                                    variant={matches ? "outlined" : "standard"}
                                    onHandleAction={() => dispatch( btnSearchLineaClick ())}
                                />
                                <Button
                                  style={{ right: 20 }} 
                                  className={classes.btnClear}
                                  size="small"
                                  startIcon={<Icon className="fa fa-window-close" />}
                                  disabled={btn2}
                                  onClick={handleLinea}
                                />
                              </FormControl>

                            </Grid>
          </Grid>

          <Grid item xs={12}  container>
                            <Grid item  xs={matches ? 4 : 12}>
                            <FormControl className={classes.filterClear}>
                                <ActionField

                                    className={classes.actionField}
                                    label="Marca"
                                    placeholder="Enter para buscar Marca"
                                    value={filters.dMarca}
                                    variant={matches ? "outlined" : "standard"}
                                    onHandleAction={() => dispatch(btnSearchMarcaClick())}
                                />
                              <Button
                                  style={{ right: 20 }} 
                                  className={classes.btnClear}
                                  size="small"
                                  startIcon={<Icon className="fa fa-window-close" />}
                                  disabled={btn3}
                                  onClick={handleMarca}
                                />
                              </FormControl>

                            </Grid>
          </Grid>

          <Grid item xs>
            <FormControl className={classes.filterClear}>
              <TextField
                className={classes.actionField}
                autoComplete="off"
                fullWidth
                label="Buscar por Marca, Nombre o Viscosidad"
                name="nombre"
                value={filters.nombre}
                variant={matches ? "outlined" : "standard"}
                onChange={onFieldChange}
              />
              <Button
                style={{ right: 0 }}
                className={classes.btnClear}
                size="small"
                startIcon={<Icon className="fa fa-window-close" />}
                disabled={btn}
                onClick={handleNombre}
              />
            </FormControl>
          </Grid>



        </Grid>
      </FieldSet>
    </div>
  );
};

export default VendedorFilter;
