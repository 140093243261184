import axios from "axios";
import { IdentityUrl } from "../../../config";
import format from "date-fns/format";
import {
  closeAdvancedSearch,
  closeMessageBox,
  openAdvancedSearch,
  openMessageBox
} from "../../main/actions";
import {
  ERROR_SEGUIMIENTO_LIST,
  RECEIVE_SEGUIMIENTO_LIST,
  REQUEST_SEGUIMIENTO_LIST,
  RESTART_STATE,
  SET_ORDER,
  SET_MASK,
  SET_SELECTED_ROW,
  UPDATE_FIELD_FILTER,
  UPDATE_FIELD_DATA
} from "./types";

import { exportToSpreadsheet, searchVendedor} from '../../../helpers/commons'

export const btnExport = () => async (dispatch, getState) => {
    
  if (getState().carteravendedor.filters.distribuidorId   === 0) {
      dispatch(openMessageBox({
          button: 'ok',
          icon: 'warning',
          message: "Debe seleccionar un Agente..",
          callback: () => dispatch(closeMessageBox())
      }));
  return;
  }
  if(getState().carteravendedor.store.data.length === 0) {
      dispatch(openMessageBox({ 
          button: 'ok', 
          icon: 'warning', 
          message: 'No hay información para exportar.', 
          callback: () => dispatch(closeMessageBox())
      }));
      return;
    }
    
    try {
      dispatch({ type: SET_MASK, loading: true });
      
      let head = getState().carteravendedor.table.columns;
      var aux = [];
      
      //console.log(head)
      
      for (let c in head)
      {
                aux[c] = 'Fecha'
                aux[1] = 'Fecha Vence';
                aux[2] =  'NIT'; 
                aux[3] =  'Nombre Comercial'; 
                aux[4] = 'Nro. Documento'; 
                aux[5] =  'Observación'; 
                aux[6] =  'Saldo '; 
                aux[7] = 'Saldo Vencido'; 
                aux[8] = 'Total'; 
                aux[9] = 'Vendedor'; 
                aux[10] = 'Cuenta CorrienteId'; 


                  //aux.push(x);
              }
      var carArray = [];

      carArray.push(aux);

      let json = getState().carteravendedor.store.data;
      //console.log(json)

      for(var i in json) {
          let y = []
          let x =   Object.values(json[i]);
        
          x.splice(5,1);
          x.splice(5,1);
          x.splice(5,1);

          y[0]= x[1];
          y[1]= x[5];
          y[2]= x[2];
          y[3]= x[3];
          y[4]= x[0];
          y[5]= x[8];
          y[6]= x[7];
          y[7]= x[10];
          y[8]= x[6];
          y[9]= x[4];
          y[10]= x[9];
          //console.log(x)
          carArray.push(y);
        }
      
      
        exportToSpreadsheet(carArray,  'CarteraVendedor') ;

  } catch (error) {
      console.log(error)
      dispatch(openMessageBox({ 
          button: 'ok', 
          icon: 'error', 
          message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
          callback: () => dispatch(closeMessageBox())
      }));
  } finally {
      setTimeout(() => {dispatch({ type: SET_MASK, loading: false })}, 1000);
  }
};

export const btnSearch = () => async (dispatch, getState) => {
  if (getState().carteravendedor.store.loading) {
    return;
  } 
  if (getState().carteravendedor.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }

  dispatch({ type: REQUEST_SEGUIMIENTO_LIST });

  try {
      dispatch({ type: SET_MASK, loading: true });
    let url =
      `${
        getState().main.resources.uriSvcSalesMobilityAPI
      }api/v1/reportes/reporte/carteravendedor?` +
      `distribuidorId=${getState().carteravendedor.filters.distribuidorId}` +
      `&usuarioId=${getState().carteravendedor.filters.usuarioId}`
      + `&desde=${format(getState().carteravendedor.filters.desde, 'yyyy-MM-dd')}`
      + `&hasta=${format(getState().carteravendedor.filters.hasta, 'yyyy-MM-dd')}`;
    let response = await axios.get(encodeURI(url));
    let data = response.data;

    if (data.length === 0) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "No existe Registro  para esta consulta",
          callback: () => dispatch(closeMessageBox()),
        })
      );
      return;
    }
    let columns = [
      { dataKey: "Fecha", label: "Fecha", width: 100 },
      { dataKey: "FechaVence", label: "Fecha Vence", width: 100 },
      { dataKey: "NIT", label: "NIT", width: 120 },
      { dataKey: "Nombrecomercial", label: "Nombre Comercial", width: 300 },
      { dataKey: "NroDocumento", label: "Nro Documento", width: 100 },
      { dataKey: "Observacion", label: "Observacion", width: 300 },
      { dataKey: "Saldo", label: "Saldo", width: 100 },
      { dataKey: "SaldoVencido", label: "Saldo Vencido", width: 100 },
      { dataKey: "Total", label: "Total", width: 100 },
      { dataKey: "Vendedor", label: "Vendedor ", width: 200 },
      { dataKey: "CuentaCorrienteId", label: "CuentaCorrienteId", width: 100 },
    ];

    dispatch({
      type: RECEIVE_SEGUIMIENTO_LIST,
      columns: columns,
      payload: data,
    });
  } catch (error) {
    let msgError = "";
    dispatch({ type: ERROR_SEGUIMIENTO_LIST });
    
    if (error.response) {
      if (error.response.status === 400) {
        msgError =
          error.response.data.eventLogId === 0
            ? "No existe Registro  para esta fecha "
            : `EventoId: ${error.response.data.eventLogId}. ` +
              error.response.data.message;
      } else {
        msgError =
          (error.response.data.eventLogId === 0
            ? ""
            : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message;
      }
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "error",
          message: msgError,
          callback: () => dispatch(closeMessageBox()),
        })
      );
    }
  } finally {
    dispatch({ type: SET_MASK, loading: false });
  }
};

export const onSearchDistribuidorClick = () => async (dispatch) => {
  let fields = [
    { dataKey: "razonSocial", label: "Agente", type: "string", width: 225 },
  ];

  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de Agente",
      getData: async (params) => {
        let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`;
        let response = await axios.get(encodeURI(url), { params: params });

        return response.data;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(onUpdateFieldFilter("distribuidorId", result[0].distribuidorId));
          dispatch(onUpdateFieldFilter("dDistribuidor", `${result[0].razonSocial}`));
          dispatch(onUpdateFieldFilter("usuarioId", ""));
          dispatch(onUpdateFieldFilter("dVendedor", ""));
        }
      },
    })
  );
};

export const onSearchVendedorClick = () => async (dispatch, getState) => {
  let fields = [
    {
      dataKey: "nombre",
      label: "Nombre",
      type: "string",
      search: true,
      width: 225,
    },
    { dataKey: "nombreUsuario", label: "Usuario", type: "string", width: 115 },
  ];
  if (getState().carteravendedor.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }

  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de vendedor",
      getData: async (params) => {
        let url = `${getState().main.resources.uriSvcSalesMobilityAPI}/api/v1/Vendedores/listarvendedoresxdistribuidor`
        + `?distribuidorId=${getState().carteravendedor.filters.distribuidorId}`               
        let response = await axios.get(encodeURI(url), { params: params });

        return searchVendedor(response, params.nombre);
    },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(onUpdateFieldFilter("usuarioId", result[0].usuarioId));
          dispatch(onUpdateFieldFilter("dVendedor", `${result[0].nombre}`));
        }
      },
    })
  );
};

export const onUpdateColumn = (column) => (dispatch, getState) => {
  getState().carteravendedor.table.columns.map((item) => {
    if (item.dataKey !== column.dataKey) {
      return item;
    } else {
     return item.width = column.width;
    }
    /*
        return {
            ...item,
            width: column.width
        };*/
  });

  //dispatch({ type: UPDATE_TABLE_COLUMN, columns });
};

export const onUpdateFieldFilter = (key, value) => (dispatch, getState) => {
  dispatch({ type: UPDATE_FIELD_FILTER, key, value });
  dispatch({ type: RECEIVE_SEGUIMIENTO_LIST, columns: [], payload: [] });
};

export const onUpdateFielddata = (key, value) => (dispatch, getState) => {
  dispatch({ type: UPDATE_FIELD_DATA, key, value });
};

export const restartState = () => ({ type: RESTART_STATE });

export const setOrder = (order, orderBy) => ({
  type: SET_ORDER,
  order,
  orderBy,
});

export const setSelectedRow = (selected) => ({
  type: SET_SELECTED_ROW,
  selected,
});
